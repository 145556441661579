import { Component, Inject, Injector, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { PrestationService } from 'src/app/modules/prestation/services/prestation.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ActionService } from '../../../services/action.service';
import { DemandeService } from '../../../services/demande.service';
import { DocumentService } from '../../../services/document.service';
import { AgenceService } from '../../../services/rsa/agence.service';
import { ActionModel } from '../../../shared/models/action.model';
import { DemandeModel } from '../../../shared/models/demande.model';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { AgenceModel } from '../../../shared/models/rsa/agence.model';
import { ModalSpinnerComponent } from '../../divers/modals/modal-spinner/modal-spinner.component';


export interface DialogData {
  document: DocumentModel;
  result: boolean;
  typeLib: string;
  objectLibelle: string;
}

@Component({
  selector: 'app-modal-afficher-document',
  templateUrl: './modal-afficher-document.component.html',
  styleUrls: ['./modal-afficher-document.component.scss']
})
export class ModalAfficherDocumentComponent implements OnInit {
  @Output() onUpdated = new EventEmitter<boolean>();
  demande: DemandeModel;
  result: boolean;
  @Input() document: DocumentModel;

  private modalsService: ModalsService;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalAfficherDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private demandeService: DemandeService,
    private actionService: ActionService,
    private agenceService: AgenceService,
    injector: Injector,
    private toastr: IntercomToastrService,
    private documentService: DocumentService,
    private prestationService: PrestationService
    ) {
      this.modalsService = injector.get('ModalsService');
    }

  ngOnInit(): void {
    if (this.data.document.typeId !== null) {
      if (this.data.document.type === 'Demande') {
        this.demandeService.get(this.data.document.typeId).subscribe(dem => this.data.objectLibelle = dem.objet);
      } else if (this.data.document.type === 'Action') {
        this.actionService.get(this.data.document.typeId).subscribe(a => this.data.objectLibelle = a.objetAction);
      } else if (this.data.document.type === 'Agence') {
        this.agenceService.get(this.data.document.typeId).subscribe((a: AgenceModel) => this.data.objectLibelle = a.libelle);
      }
    }
  }

  onOpenDemande(): void {
    this.demandeService.get(this.data.document.typeId).subscribe((demande: DemandeModel) => {
      this.modalsService.openDemande(demande).subscribe(result => {
        this.result = result;
        this.onUpdated.emit(true);
      });
    });
  }

  onOpenAction(): void {
    this.actionService.get(this.data.document.typeId).subscribe((action: ActionModel) => {
      this.modalsService.openAction(action).subscribe(result => {
        this.result = result;
        this.onUpdated.emit(true);
      });
    });
  }


  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  updateDocumentAgence(){
    const dialog = this.dialog.open(ModalSpinnerComponent);
    this.prestationService.import(this.data.document.url, '').subscribe((res : any) => {
      this.documentService.put(this.data.document).subscribe((doc: DocumentModel) => {
        this.data.document = doc;
        this.dialogRef.close(doc.id);
        dialog.close();
        this.toastr.success('Le document : ' + this.data.document.libelle + ' a bien été mis à jour', 'Bonne nouvelle !');
      });
    }, error => {
      this.toastr.error('Assurez-vous d\'avoir partagé le document et d\'envoyer l\'url de partage', 'l\'URL est incorrect',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
      dialog.close();
    });
  }

  updateDocument() {
    const dialog = this.dialog.open(ModalSpinnerComponent);
    this.documentService.put(this.data.document).subscribe((doc: DocumentModel) => {
      this.data.document = doc;
      this.dialogRef.close(doc.id);
      dialog.close();
      this.toastr.success('Le document : ' + this.data.document.libelle + ' a bien été mis à jour', 'Bonne nouvelle !');
    }, error => {
      dialog.close();
    });
  }

  onModalConfirm(): void {
    const titleErreur = 'Oups...Erreur';
    // Si tout est OK, création du document...
    if (this.data.document.libelle && this.data.document.type && this.data.document.url) {
      if (this.data.document.typeId !== -1) {
        if(this.data.document.type === 'Agence'){
          this.data.result = true;
          this.updateDocumentAgence();
        }else {
          this.data.result = true;
          this.updateDocument();
        }
        
      } else {
        this.toastr.warning('Le document doit être lié à une demande.', 'Erreur');
      }
    } else {
      // Si non on renvoie une erreur...
      if (!this.data.document.libelle && !this.data.document.type && !this.data.document.url) {
        this.toastr.warning('Le libellé, l\'url sont obligatoires.', 'Impossible de modifier le documennt');
      } else {
        if (this.data.document.libelle && !this.data.document.type && !this.data.document.url) {
          this.toastr.warning('L\'url, et le type sont obligatoires', titleErreur);
        } if (!this.data.document.libelle && !this.data.document.type && this.data.document.url) {
          this.toastr.warning('Le libellé, et le type sont obligatoires', titleErreur);
        } if (!this.data.document.libelle && this.data.document.type && !this.data.document.url) {
          this.toastr.warning('L\'url, et le libellé sont obligatoires', titleErreur);
        } else {
          if (!this.data.document.libelle) {
            this.toastr.warning('Le libellé est obligatoire', titleErreur);
          } if (!this.data.document.type) {
            this.toastr.warning('Le type est obligatoire', titleErreur);
          } if (!this.data.document.url) {
            this.toastr.warning('L\'url est obligatoire.', titleErreur);
          }
        }
      }
    }
  }
}
