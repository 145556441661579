import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { DevisApiService } from '../../devis/services/devis-api.service';
import { DevisBasicService } from './devis-basic.service';
import { DocumentModel } from '../models/document.model';

@Injectable()
export class DocumentService extends DevisBasicService {

  constructor(http: HttpClient,
    devisApiService: DevisApiService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, devisApiService);
      this.serviceUrl = devisApiService.ROUTEDOCUMENT;
}

  getDocDevis(idDevis, filename): Observable<string> {
    return this.http.get(this.apiUrl + this.serviceUrl + 'genUrlGetDevisDoc?idDevis=' + idDevis + '&filename=' + filename,
      { responseType: 'text' });
    
    }

  deleteDocDevis(idDevis): Observable<DocumentModel> {
    return this.http.get(this.apiUrl + this.serviceUrl + 'deleteDocDevis?idDoc=' + idDevis,
      { responseType: 'text' })
      .pipe(switchMap(filedeleteurl => {
        if (filedeleteurl) {
          return this.http.delete(filedeleteurl, { reportProgress: true });
        }
        return of(null);
      }), catchError(err => this.handleError(err)));
  }


  sendDocDevis(filename: string,  idDevis: number, file: File): Observable<HttpEvent<{}>> {
    return this.http.get(this.apiUrl + this.serviceUrl + 'sendDocDevis?idDevis=' + idDevis + '&libelleDoc=' + filename,
      { responseType: 'text' })
      .pipe(first(), switchMap((fileuploadurl: string) => {
        const headers = new HttpHeaders({ 'Content-Type': file.type });
        if (fileuploadurl) {
          const req = new HttpRequest('PUT', fileuploadurl, file, { headers });
          return this.http.request(req);
        }
        return of(null);
      }), catchError(err => {
        return this.handleError(err);
      }));
  }

  getDoc(url: string): Observable<Blob> {
    if(url !== "" && url){
      return this.http.get(this.apiUrl + url,
        { responseType: 'text' })
        .pipe(switchMap(filegeturl => {
          if (filegeturl) {
            return this.http.get(filegeturl, { responseType: 'blob' });
          }
          return of(null);
        }), catchError(err => this.handleError(err)));
    }else {
      return of(null);
    }
    
  }

  getFromDevis(idDevis: number): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(this.apiUrl + this.serviceUrl + 'getFromDevis?idDevis=' + idDevis);
  }

  genUrlGetDevisDoc(idDevis: number, filename: string): Observable<string> {
    return this.http.get<string>(this.apiUrl + this.serviceUrl + 'genUrlGetDevisDoc?idDevis=' + idDevis + '&filename=' + filename);
  }
}
