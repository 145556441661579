import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { BasicService } from './basic-service/basic.service';
import { SessionService } from '../../shared/session.service';
import { CityData } from '../shared/models/city-data.model';

@Injectable()
export class CityDataService extends BasicService {

  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              protected IntercomToastrService: IntercomToastrService) {
                super(http, apiService, sessionService);
                this.serviceUrl = apiService.ROUTEGEOGOUV;
  }

  departements = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "971",
    "972",
    "973",
    "974",
    "976"
  ];

  private CheckDptValid(num: string): boolean {
    if (num.length >= 2 && this.departements.find(x => x === num.slice(0, 2)) || this.departements.find(x => x === num.slice(0, 3))) {
      return (true);
    }
    return (false);
  }

  GetAllInDepartement(num: string): Observable<CityData[]> {
    if (this.CheckDptValid(num)) {
      if (num.startsWith("20")) {
        return forkJoin([
          this.http.get<CityData[]>(this.serviceUrl + 'departements/' + '2A' + '/communes').pipe(catchError(() => {
            this.IntercomToastrService.error("Erreur lors de la récupération des codes postaux, veuillez réessayer."); 
            throw new Error("Erreur lors de la récupération des codes postaux, veuillez réessayer.");
          })),
          this.http.get<CityData[]>(this.serviceUrl + 'departements/' + '2B' + '/communes').pipe(catchError(() => {
            this.IntercomToastrService.error("Erreur lors de la récupération des codes postaux pour le département 20, veuillez réessayer."); 
            throw new Error("Erreur lors de la récupération des codes postaux pour le département 20, veuillez réessayer.");
          })),
        ]).pipe(map(([A, B]) => {
          return (A.concat(B));
        }));
      } else {
        let dptCode = "";
        if (num.length === 2 || this.departements.find(x => x === num.slice(0, 2))) {
          dptCode = num.slice(0, 2);
        } else {
          dptCode = num.slice(0, 3);
        }
        return this.http.get<CityData[]>(this.serviceUrl + 'departements/' + dptCode + '/communes').pipe(catchError(() => {
          this.IntercomToastrService.error("Erreur lors de la récupération des codes postaux du département " + dptCode + ", veuillez réessayer."); 
          throw new Error("Erreur lors de la récupération des codes postaux, veuillez réessayer.");
        }));
      }
    } else return (of([]));
  }

  GetCityByCompleteCode(num: string): Observable<CityData[]> {
    return this.http.get<CityData[]>(this.serviceUrl + 'communes?codePostal=' + num).pipe(catchError(() => {
      this.IntercomToastrService.error("Erreur lors de la récupération des villes depuis un code postal, veuillez réessayer."); 
      throw new Error("Erreur lors de la récupération des villes depuis un code postal, veuillez réessayer.");
    }));
  }

  SearchCityWithCode(num: string): Observable<CityData[]> {
    if (num) {
      if (num.length === 5) {
        return (this.GetCityByCompleteCode(num));
      } else if (num.length >= 2) {
        return (this.GetAllInDepartement(num));
      }
    }
    return (of([]));
  }

  SearchCityWithName(name: string): Observable<CityData[]> {
    if (name) {
      return this.http.get<CityData[]>(this.serviceUrl + 'communes?nom=' + name + '&fields=departement&fields=codesPostaux&boost=population&limit=5').pipe(catchError(() => {
        this.IntercomToastrService.error("Erreur lors de la récupération des villes dont le nom commence par " + name + ", veuillez réessayer."); 
        throw new Error("Erreur lors de la récupération des villes dont le nom commence par " + name + ", veuillez réessayer.");
      }));
    } else {
      return (of([]));
    }
  }
}
