import { Component, Input, EventEmitter, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import { CityData } from 'src/app/modules/core/shared/models/city-data.model';
import { CityDataService } from 'src/app/modules/core/services/cityData.service';

@Component({
  selector: 'app-autocomplete-ville',
  templateUrl: './autocomplete-ville.component.html',
  styleUrls: ['./autocomplete-ville.component.scss']
})
export class AutocompleteVilleComponent {

  @Input() required = false;
  @Input() libelle: string;
  @Input() disable = false;
  @Output() onUpdated = new EventEmitter<CityData>();
  cities: CityData[];
  @Input() selectedCity: CityData = new CityData();
  @ViewChild("villetext") villetext: any;
  citiesListString: string[] = [];
  filteredCities: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  myControl = new UntypedFormControl();


  constructor(private cityDataService: CityDataService) { }

  ngOnInit() {
    if (this.selectedCity && !this.selectedCity.codesPostaux) {
      this.selectedCity.codesPostaux = [];
    }
    this.myControl.valueChanges.subscribe(cityInput => {
      
      this.cityDataService.SearchCityWithName(cityInput).subscribe(cityList => {
        this.citiesListString = [];
        this.cities = cityList;
        for (let i = 0; i < this.cities.length; i++) {
          for (let j = 0; j < this.cities[i].codesPostaux.length; j++) {
            this.citiesListString.push(this.cities[i].nom + ', ' + this.cities[i].codesPostaux[j] + ';' + this.cities[i].departement.nom);
          }  
        }
        this.filteredCities.next(this.citiesListString);       
    });
  });
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes.selectedCity && changes.selectedCity.currentValue) {
    this.myControl.setValue(changes.selectedCity.currentValue.nom);
  }
}

  /* Add with enterKey */
  add(value: string): void {
    if (!value || value === "") {
      var selectedCityData = Object.assign({}, this.selectedCity);
      selectedCityData.nom = null;
      this.myControl.setValue(null);
      this.onUpdated.emit(selectedCityData);
    } else {
      if (value[0].toLowerCase() === value[0]) {
        value = value[0].toUpperCase() + value.substring(1);
      }
      var currentCities = this.cities.filter(city => city.nom.toLowerCase() === value.toLowerCase())
      if (currentCities.length === 1 && currentCities[0].codesPostaux.length === 1) {
        this.selectedCity = currentCities[0];
      }
      var selectedCityData = Object.assign({}, this.selectedCity);
      selectedCityData.nom = value;
      this.myControl.setValue(value);
      this.onUpdated.emit(selectedCityData);
    }
  }

 /* selected event*/
 cityChange(event: MatAutocompleteSelectedEvent) {
  const value = event.option.value;
  var cityNameInput = value.split(",")[0];
  var cityCodePostInput = (value.split(",")[1].trim()).split(";")[0];
  this.selectedCity = this.cities.find(city => city.nom === cityNameInput);
  this.selectedCity.codesPostaux = [cityCodePostInput];
  var selectedCityData = Object.assign({}, this.selectedCity);
  selectedCityData.codesPostaux = Object.assign({}, this.selectedCity.codesPostaux);
  selectedCityData.codesPostaux = [cityCodePostInput];
  this.myControl.setValue(cityNameInput);
  this.onUpdated.emit(selectedCityData);
}

}
