<div class="flex-modal">

  <div class="col-md-12">
    <h3>Fiche interlocuteur</h3>
  </div>

  <div class="full-width">
    <mat-divider></mat-divider>
  </div>

  <div class="col-md-12">
    <mat-form-field class="full-width">
      <mat-label>Fonction</mat-label>
      <input matInput placeholder="Comptable, PDG, Gardien, ..." value="" name="intercom-role-interloc"  [(ngModel)]="data.interloc.role" required [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Civilité</mat-label>
      <mat-select [(ngModel)]="data.interloc.civiliteId">
        <mat-option *ngFor="let civilite of civilites.slice()" [value]="civilite.id">{{civilite.libelle}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Nom</mat-label>
      <input matInput placeholder="" value=""  required [(ngModel)]="data.interloc.nom" name="intercom-lname" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Prénom</mat-label>
      <input matInput placeholder="" value="" [(ngModel)]="data.interloc.prenom" name="intercom-fname" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <app-input-telephone [required]="telRequired" (phoneNumberChange)="emailRequired = !$event || data.tierEntreprise" [(phoneNumber)]="data.interloc.telPrincipal" libelle="Téléphone">
    </app-input-telephone>
  </div>

  <div class="col-md-4">
    <app-input-telephone [(phoneNumber)]="data.interloc.telSecondaire" libelle="Téléphone Secondaire">
    </app-input-telephone>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Email</mat-label>
      <input matInput placeholder="" [required]="emailRequired" (ngModelChange)="telRequired = !$event || data.tierEntreprise" type="email" [(ngModel)]="data.interloc.email" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </div>

  <div class="col-md-6">
    <mat-form-field class="full-width">
      <mat-label>Commentaire</mat-label>
      <textarea matInput placeholder="" rows="4" value="" [(ngModel)]="data.interloc.commentaire" [ngModelOptions]="{standalone: true}"></textarea>
    </mat-form-field>
  </div>

  <div class="col-md-3" style="margin-top: 35px; display: flex; justify-content: center;">
    <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
  </div>

  <div class="col-md-3" style="margin-top: 35px; display: flex; justify-content: center;">
    <button mat-flat-button color="success" (click)="onModalConfirm()">Enregistrer</button>
  </div>
</div>