import { Injectable } from '@angular/core';
import { TierBasicService } from './basic-service/tier-basic.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { FormulairenMock } from '../shared/mocks/formulaire.mock';
import { SessionService } from '../../shared/session.service';

@Injectable({
  providedIn: 'root'
})
export class FormulaireService extends TierBasicService {

  constructor(private mock: FormulairenMock,
    http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEFORMULAIRE;
}

}
