import { Injectable } from '@angular/core';

@Injectable()
export class ColumnService {

    public readonly DEMANDE_COLUMN = 'DEMANDE_COLUMNS';
    
    public mapDefaultDisplayedColumnsLib: Map<string, string[]>;
    public mapTotalColumnsLib: Map<string, string[]> = new Map();
    public mapColumns: Map<string, Map<string, string[]>> = new Map();


    constructor() {
        this.mapColumns = new Map([
            [
                this.DEMANDE_COLUMN,  
                new Map([
                    ['Urgence', ['Urgence']], // 1
                    ['Urgence Colonnes', [ 'envoye', 'planif','synchro', 'demarre', 'realise', 'valide','annule']], // 1
                    ['ID Demande Intercom', ['Id']],
                    ['Numéro', ['NumDemande']],
                    ['Date de création', ['DateCreation']],
                    ['Date d\'échance', ['DateIntervention']],
                    ['Objet', ['Objet']],
                    ['Type', ['TypeDemande']],
                    ['Statut', ['Statut']],
                    ['Activité', ['prestationIds']],
                    ['Tiers DO', ['Tier']],
                    ['IdJasonTier', ['IdJasonTier']],
                    ['Tiers facturé', ['TierFact']],
                    ['ID Jason tiers facturé', ['IdJasonTierFact']],
                    ['Adresse', ['Adresse']],
                    ['CP', ['CodePostalAdresse']],
                    ['Ville', ['VilleAdresse']],
                    ['Contact', ['Contact']],
                    ['Tel Contact', ['NumContact']],
                    ['Mail Contact', ['EmailContact']],
                    ['Gestionnaire', ['Gestionnaire']],
                    ['Agence gestionnaire', ['LibAgenceGest']],
                    ['Code d\'agence gestionnaire', ['CodeAgenceGest']],
                    ['Réalisateur', ['Commercial']],
                    ['Agence réalisatrice', ['LibAgenceComm']],
                    ['Code d\'agence réalisatrice', ['CodeAgenceComm']],
                    ['Créateur', ['Createur']],
                    ['Agence création', ['LibAgenceCrea']],
                    ['Code d\'agence création', ['CodeAgenceCrea']],
                    ['Commentaire', ['Commentaire']]
                ])
            ]
        ]);
        
        this.mapTotalColumnsLib = new Map([
            [
                this.DEMANDE_COLUMN, 
                Array.from(this.mapColumns.get(this.DEMANDE_COLUMN).keys())
            ]
        ]);

        this.mapDefaultDisplayedColumnsLib = new Map([
            [
                this.DEMANDE_COLUMN,  
                [
                    'Urgence Colonnes', 
                    'Numéro', 
                    'Date de création',
                    'Date d\'échance', 
                    'Type', 
                    'Statut', 
                    'Tiers DO', 
                    'Adresse', 
                    'CP', 
                    'Ville', 
                    'Réalisateur', 
                    'Créateur',
                    'Commentaire'
                ]
            ]
        ]);

    }

    getUserColumn(typeColumn: string): string[] {
        var cols = localStorage.getItem(typeColumn);
        if (cols) {
        return cols.split(',').filter(x => this.mapColumns.get(typeColumn).get(x))
        } else {
        return this.getDefaultColumn(typeColumn);
        }
    }

    getUserColumnTech(typeColumn: string): string[] {
        const arr = this.getUserColumn(typeColumn).map(x => {
            if (this.mapColumns.get(typeColumn).get(x)){
                return [...this.mapColumns.get(typeColumn).get(x)];
            } else 
            {
                return [];
            }
        });
        return arr.reduce((accumulator, value) => accumulator.concat(value, []));
    }

    getDefaultColumn(typeColumn: string): string[] {
        var cols = this.mapDefaultDisplayedColumnsLib.get(typeColumn);
        if (cols) {
        return Object.assign([], cols);
        } else {
        return [];
        }
    }

    getTotalColumn(typeColumn: string): string[] {
        var cols = this.mapTotalColumnsLib.get(typeColumn);
        if (cols) {
        return Object.assign([], cols);
        } else {
        return [];
        }
    }

    setUserColumn(typeColumn: string, columns: string[]) {
        localStorage.setItem(typeColumn, columns.join(','));
    }

    resetColumns(typeColumn: string): string[] {
        this.setUserColumn(typeColumn, this.getDefaultColumn(typeColumn))
        return this.getDefaultColumn(typeColumn);
    }

}
