import { TierBasicMock } from './basic-mock/tier-basic.mock';
import { DemandeModel } from '../models/demande.model';
import { Injectable } from '@angular/core';

@Injectable()
export class DemandeMock extends TierBasicMock  {
  constructor() {
    super();
    (<DemandeModel[]> this.objets) = [
    ];
  }
}
