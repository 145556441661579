import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DocsSiteTheme } from '../../../shared/theme-storage.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ThemePalette } from '@angular/material/core';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActiviteModel } from 'src/app/modules/core/shared/models/prestation.model';
import { PrestationModel } from '../../models/prestation.model';
import { PrestationService } from '../../services/prestation.service';
import { ModalInfoOkComponent } from 'src/app/modules/core/components/divers/modals/modal-info-ok.component';
import { Router } from '@angular/router';
import { OffreModalComponent } from '../offre-modal/offre-modal.component';
import { ParametrePrestationComponentService } from '../parametre-prestation-component.service';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-parametre-prestation',
  templateUrl: './parametre-prestation.component.html',
  styleUrls: ['./parametre-prestation.component.scss']
})

export class ParametrePrestationComponent implements OnInit {
  public touchUi = false;
  color = '';
  checked: boolean;
  disabled = false;
  public disabledPicker = false;
  public colorPicker: ThemePalette = 'primary';
  themeChangerButton = 'dark';
  theme: DocsSiteTheme;
  themes: DocsSiteTheme[] = [
    { name: 'light-theme' },
    { name: 'dark-theme' }
  ];
  light = true;


  idAgence: number;
  idActivite: number;
  libelleActivite: string;
  activite: ActiviteModel = new ActiviteModel();
  prestations = [];
  prestation = new PrestationModel();
  displayForm = false;
  form: UntypedFormGroup;

  constructor(
    private toastr: IntercomToastrService,
    private cdr: ChangeDetectorRef,
    private overlay: OverlayContainer,
    private prestationService: PrestationService,
    private router: Router,
    public dialog: MatDialog,
    private prestationComponentService: ParametrePrestationComponentService) {
      const state = this.router.getCurrentNavigation().extras.state;
      this.idActivite = state.idActivite;
      this.idAgence = state.idAgence;
      this.libelleActivite = state.libelle;
    }


  ngOnInit(): void {
    this.getPrestations();
  }

  getPrestations() {
    this.prestationService.getFromActivite(this.idAgence, this.idActivite).subscribe((prestations: PrestationModel[]) => {
      this.prestations = prestations;
    });
  }  

  AfficherFormulaire() {
    this.form = this.prestationComponentService.getCreatePrestationFormGroup();
    this.form.get('idAgence').setValue(this.idAgence);
    this.form.get('idActivite').setValue(this.idActivite);
    this.displayForm = true;
  }

  removeOffre(i) {
    var offres = this.getFormOffres();
    offres.removeAt(i);
  }

  removePrestation() {
    // confirmartion de suppression
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        message: 'Êtes-vous sur de vouloir les supprimer ?',
        color: 'warn'
      }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.prestation.libelle && this.prestation.libelle !== '') {
          this.prestationService.deletePrestation(this.prestation.id, this.idAgence).subscribe((prestation:PrestationModel)=> {
            this.getPrestations();
            this.toastr.success('Suppression de la prestation '+ prestation.libelle +' et ces offres');
          });
        }
        this.displayForm = false;
      }
    });
  }

  addOffre() {
    const form = this.prestationComponentService.getCreateOffreFormGroup();
    const dialogRef = this.dialog.open(OffreModalComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        form: form,
        idAgence: this.idAgence,
        result: false
      }
    });
    dialogRef.afterClosed().subscribe((result: UntypedFormGroup) => {
      if(result) {
        const offres = this.form.get('offres') as UntypedFormArray;
        offres.push(result);
        this.toastr.success('Cration de l\'offre '+  result.get('libelle').value)
      }
    });
  }

  getFormOffres() {
    return this.form.get('offres') as UntypedFormArray;
  }

  editOffre(form: UntypedFormGroup) {
    let objet = form.getRawValue();
    const dialogRef = this.dialog.open(OffreModalComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        form: form,
        idAgence: this.idAgence,
        result: false
      }
    });
    dialogRef.afterClosed().subscribe((result: UntypedFormGroup) => {
      if(result) {
        this.toastr.success('Mis à jour de l\'offre ' + result.get('libelle').value)
      }else {
        form.setValue(objet);
      }
    });
  }

  modify(prestation: PrestationModel) {
    this.prestation = prestation;
    this.form = this.prestationComponentService.getEditPrestationFormGroup(prestation);
    this.form.get('idAgence').setValue(this.idAgence);
    this.form.get('idActivite').setValue(this.idActivite);
    this.displayForm = true;
  }
  
  MasquerFormulaire() {
    this.displayForm = false;
  }

  create() {
    if(this.form.valid) {
      var prestation = this.convertFormInModel(this.form);
      if(prestation.id) {
        this.prestationService.put(prestation).subscribe((res: PrestationModel) => {
          this.getPrestations();
          this.toastr.success('Vous avez réussi à mettre à jour la prestation '+ res.libelle + ' avec ces offres');
        })
      }else {
        this.prestationService.post(prestation).subscribe((res: PrestationModel) => {
          this.getPrestations();
          this.toastr.success('Vous avez réussi à créer la prestation '+ res.libelle + ' avec ces offres')
        });
      }
      this.displayForm = false;
    }else {
      this.displayErrors();
      this.toastr.warning('Libellé de la prestation est obligatoire');
    }
  }

  convertFormInModel(form: UntypedFormGroup): PrestationModel {
    var prestation: PrestationModel = new PrestationModel();
    prestation = form.getRawValue();
    return prestation;
  }

  displayErrors(): void {
    for (const index in this.form.controls) {
      if (index) {
        this.form.controls[index].markAsTouched();
      }
    }
  }

}
