import { Component, OnInit, Inject } from '@angular/core';
import { SiteGeographiqueComponent } from './site-geographique.component';
import { SiteGeoService } from './../../services/site-geo.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { filterTierDTO } from '../../shared/dtos/filterTierDTO';

export interface DialogData {
  site: SiteGeoModel;
  tierId: number;
  result: boolean;
  filter: filterTierDTO;
  libelleTier : string;
}

@Component({
  selector: 'app-modal-ajout-site-geo',
  templateUrl: './modal-ajout-site-geo.component.html'
})


export class ModalAjoutSiteGeoComponent implements OnInit {

  constructor(public dialog: MatDialog
    , public dialogRef: MatDialogRef<SiteGeographiqueComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    ,  private sitegeoService: SiteGeoService
    , private toastr: IntercomToastrService) { }

  ngOnInit() {
    this.dialogRef.updateSize('80%', '50%');
    
    if(this.data.filter){
    const isEmptyAdress = Object.values(this.data.filter.siteGeo).every(x => x === null || x === ''); //check if no address sent from parent 
    if(!isEmptyAdress){
      this.data.site = new SiteGeoModel();
      this.data.site.adresse = this.data.filter.siteGeo.Adresse;
      this.data.site.codePostal = this.data.filter.siteGeo.CodePostal;
      this.data.site.libelle = this.data.filter.siteGeo.Libelle;
      this.data.site.ville = this.data.filter.siteGeo.Ville;
    }else{
      this.data.site = new SiteGeoModel();
     }
    }else{
      this.data.site = new SiteGeoModel();
    }
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
    if (this.data.site.libelle && this.data.site.ville && this.data.site.codePostal && this.data.site.adresse &&  
      this.data.site.libelle.trim().length !== 0 && this.data.site.ville.trim().length !== 0 &&
      this.data.site.codePostal.trim().length !== 0 && this.data.site.adresse.trim().length !== 0) {
      this.data.result = true;
      this.data.site.tierId = this.data.tierId;
      this.sitegeoService.post(this.data.site).subscribe((s: SiteGeoModel) => {
        this.data.site = s;
        this.dialogRef.close(this.data);
        this.toastr.success('', 'Ajout de l\'adresse : ' + this.data.site.libelle );
      });
    } else {
      this.toastr.warning('le libelle, la ville, l\'adresse  et le code postale sont obligatoire.', 'Impossible de créer le site');
    }
  }
}
