import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class FormInfo {
    validators: Validators;
    disabled: boolean;
    constructor(disabled: boolean, validator: Validators) {
      this.disabled = disabled;
      this.validators = validator;
    }
}
export function maxLines(maxLines: number): ValidatorFn {  
    return (control: AbstractControl): ValidationErrors | null =>  {
        let lines = control.value?.split('\n')?.length;
        if (lines > maxLines) {
            return { tooManyLines : lines}
        } else {
            return null;
        }
    }
        
}
export const DESCRIPTIONACTIVITEVALIDATORS = {
    CREATE: new Map<string, FormInfo>([
        ['id', new FormInfo(false, [])],
        ['dateCreation', new FormInfo(false, [])],
        ['createurId', new FormInfo(false, [])],
        ['dateModification', new FormInfo(false, [])],
        ['modifieurId', new FormInfo(false, [])],
        ['dateDesactivation', new FormInfo(false, [])],
        ['desativateurId', new FormInfo(false, [])],
        ['agenceId', new FormInfo(false, [Validators.required])],
        ['activiteId', new FormInfo(false, [Validators.required])],
        ['libelleContratJason',new FormInfo(false, [Validators.maxLength(50)])],
        ['introduction',new FormInfo(false, [])],
        ['descriptionOuvrage',new FormInfo(false, [])],
        ['modalite',new FormInfo(false, [])],
        ['chargeClient',new FormInfo(false, [])],
        ['imageId',new FormInfo(false, [])],
    ]),
    EDIT: new Map<string, FormInfo>([
        ['id', new FormInfo(false, [])],
        ['DateCreation', new FormInfo(false, [])],
        ['CreateurId', new FormInfo(false, [])],
        ['DateModification', new FormInfo(false, [])],
        ['ModifieurId', new FormInfo(false, [])],
        ['DateDesactivation', new FormInfo(false, [])],
        ['DesativateurId', new FormInfo(false, [])],
        ['agenceId', new FormInfo(false, [Validators.required])],
        ['activiteId', new FormInfo(false, [Validators.required])],
        ['libelleContratJason',new FormInfo(false, [Validators.maxLength(50)])],
        ['introduction',new FormInfo(false, [])],
        ['descriptionOuvrage',new FormInfo(false, [])],
        ['modalite',new FormInfo(false, [])],
        ['chargeClient',new FormInfo(false, [])],
        ['imageId',new FormInfo(false, [])],
    ])
}