<div class="menuEntier" [class.FunctionMenu]="mobileQuery.matches">

  <!-------------------------------------------- HEADER ------------------------------------------------------------>
  <header>
    <mat-toolbar class="toolbar" [ngClass]="envName">
      <button mat-icon-button (click)="snav.toggle()" *ngIf="user !== null && user !== undefined">
        <mat-icon>menu</mat-icon>
      </button>
      <a [routerLink]="['']"><img class="logo" src="assets/imgs/logosarpveolia.png" alt="Sarp Veolia Logo"></a>
      <span class="spacer"></span>
      <span class="titreIntercom">Intercom</span>
      <span class="spacer"></span>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleTheme()">
        <mat-icon>opacity</mat-icon>
      </button>
      <button type="button" aria-label="Toggle sidenav" (click)="openQuestionnaire()"mat-icon-button>
        <mat-icon>announcement</mat-icon>
      </button>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button  *ngIf="user !== null && user !== undefined" [matMenuTriggerFor]="aboveMenu">
        <mat-icon aria-label="Side nav toggle icon">account_box</mat-icon>
      </button>
      <h5 *ngIf="user">{{user.nom + ' ' + user.prenom}}</h5>
      <mat-menu type="button" #aboveMenu="matMenu" yPosition="above">
        <button type="button" (click)="parametres_user()" mat-menu-item>Paramètres utilisateur</button> 
        <button *ngIf= "levelDroit === 4" type="button" (click)="parametres_agence()" mat-menu-item>Administration agence</button> 
        <button *ngIf= "levelDroit === 4" type="button" (click)="manager_user()" mat-menu-item>Gestion utilisateur</button> 
        <button type="button" (click)="logout()" mat-menu-item>Déconnexion</button>
      </mat-menu>
    </mat-toolbar>
  </header>

  <mat-sidenav-container class="sidenav-container"  autosize
  [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened="true" #snav 
                 (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" 
                 [mode]="mobileQuery.matches ? 'side' : 'side'" 
                 [fixedInViewport]="mobileQuery.matches" 
                 fixedTopGap="56">
        <div *ngIf="!router.url.includes('/login')">
          <app-menu (Emittertiers)="getTiers($event)" 
                    [showCall]="showCall" 
                    [callNumber]="this.currentCall.callNumber" 
                    [callNumberTo]="this.currentCall.callNumberTo"
                    [isExpanded]="isExpanded" [isShowing]="isShowing" ></app-menu>
        </div>
    </mat-sidenav>
    <mat-sidenav-content >
      <app-splash-screen></app-splash-screen>
      <router-outlet style="margin-bottom: 10px;"></router-outlet>
      <div class="action_element">
        <!-- Card for Ringing Call -->
        <mat-card id="card1"  *ngIf=" this.ringing && this.isButtonVisible && user !== null && user !== undefined" class="example-box">
          <mat-card-header>
            <mat-card-title>   Appel en attente.. </mat-card-title>
            <mat-icon  id="delete" style="justify-content: left;" (click)="this.isButtonVisible = false">clear</mat-icon>
          </mat-card-header>
          <mat-card-content>
            <div *ngIf="calls.length !== 0"> Appelant : {{this.calls[calls.length - 1].callNumber}} <span *ngIf="getTierbyTel(this.calls[calls.length - 1].callNumber) !== null">{{getTierbyTel(this.calls[calls.length - 1].callNumber).libelle}} </span></div>
            <div *ngIf="calls.length !== 0"> Appelé : {{this.calls[calls.length - 1].callNumberTo}} <span *ngIf="getTierbyTel(this.calls[calls.length - 1].callNumberTo) !== null">{{getTierbyTel(this.calls[calls.length - 1].callNumberTo).libelle}} </span></div>

          </mat-card-content>
        </mat-card>

        <!-- Card for connected Call -->
        <mat-card  id="card2" *ngIf="inCall === true && !this.ringing && this.isButtonVisible && user !== null && user !== undefined" class="example-box">
          <mat-card-header>
            <mat-card-title> Appel en cours.. </mat-card-title>
            <mat-icon  id="delete" style="justify-content: left;" (click)="this.isButtonVisible = false">clear</mat-icon>
          </mat-card-header>
          <mat-card-content>
            <div> Appelant : {{this.currentCall.callNumber}}  <span *ngIf="getTierbyTel(this.currentCall.callNumber) !== null">{{getTierbyTel(this.currentCall.callNumber).libelle}} </span></div>
            <div> Appelé : {{this.currentCall.callNumberTo}}  <span *ngIf="getTierbyTel(this.currentCall.callNumberTo) !== null">{{getTierbyTel(this.currentCall.callNumberTo).libelle}} </span></div>
          </mat-card-content>
        </mat-card>

          <!-- Card for No Call -->
        <mat-card id="card3" *ngIf="inCall === false && !this.ringing && this.isButtonVisible && user !== null && user !== undefined" class="example-box">
          <mat-card-header>
              <mat-card-title>Dernier appel pris.</mat-card-title>
              <mat-icon id="delete" style="justify-content: left;" (click)="this.isButtonVisible = false">clear</mat-icon>
            </mat-card-header>
          <mat-card-content>
            <div *ngIf="currentCall.taked !== false"> Appelant : {{this.currentCall.callNumber}} <span *ngIf="getTierbyTel(this.currentCall.callNumber) !== null">{{getTierbyTel(this.currentCall.callNumber).libelle}} </span></div> 
            <div *ngIf="currentCall.taked !== false"> Appelé : {{this.currentCall.callNumberTo}} <span *ngIf="getTierbyTel(this.currentCall.callNumberTo) !== null">{{getTierbyTel(this.currentCall.callNumberTo).libelle}}</span></div>
          </mat-card-content>
        </mat-card>
        
        <div id="contentInside" *ngFor="let container of containers">
          <button mat-raised-button  *ngIf="container.type === 'Demande'" (click)="openDemande(container.demande)">{{container.demande.objet}} <button class="button_opaque" (click)="closeDemande(container.demande)" ><mat-icon>clear</mat-icon></button></button>
          <button mat-raised-button  *ngIf="container.type === 'Action'" (click)="openAction(container.action)">{{container.action.objetAction}} <button class="button_opaque" (click)="closeAction(container.action)" ><mat-icon>clear</mat-icon></button></button>
          <button mat-raised-button  *ngIf="container.type === 'ClosedAction'" (click)="openClosedAction(container.action)">{{container.action.objetAction}} <button class="button_opaque" (click)="closeAction(container.action)" ><mat-icon>clear</mat-icon></button></button>
        </div>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>