import { Component, OnChanges, Output, EventEmitter, SimpleChanges, Input  } from '@angular/core';
import { TrieTabService } from '../../../services/trie-tab.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DemandeDTO } from '../../../shared/dtos/demandeDTO';
import { DemandeModel } from '../../../shared/models/demande.model';
import { SimpleQuery } from '../../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../../shared/simple_query/simpleQueryResponse';
import { Clipboard } from '@angular/cdk/clipboard';

export interface DialogData {

  result: boolean;
}

@Component({
  selector: 'app-type-demande',
  templateUrl: './type-demande.component.html',
  styleUrls: ['./type-demande.component.scss']
})


export class TypeDemandeComponent implements OnChanges {  
  @Input() simpleQueryResponse = new SimpleQueryResponse<DemandeModel>();
  @Input() datasource: DemandeModel[];
  @Input() displayedColumns: string[];
  @Input() length: number;

  @Output() pageChange = new EventEmitter<SimpleQuery<DemandeDTO>>();
  @Output() demandeOpen = new EventEmitter<DemandeModel>();

  pageIndex = 0;
  pageSize = 50;
  pageEvent: PageEvent;
  selectedDemande: DemandeModel;
  sortState: Sort = {active: 'DateCreation', direction: 'desc'};
  simpleQuery = new SimpleQuery<DemandeDTO>();
  matDataSource: MatTableDataSource<DemandeModel>;
  result: boolean;
  
  constructor(private comp: TrieTabService,
     private clipboard: Clipboard) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datasource){
      const data = changes.datasource.currentValue.slice();
      this.matDataSource = new MatTableDataSource(data);
      this.length = this.simpleQueryResponse.count;
      this.pageIndex = this.simpleQueryResponse.pageNumber;
    }
  }

  openEvent(demande: DemandeModel) {
    this.demandeOpen.emit(demande);
  }

  copyclip(res: string, event: Event) {
    event.stopPropagation();
    this.clipboard.copy(res);
  }

  changePage(event?: PageEvent): PageEvent {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    this.simpleQuery.order = this.sortState.active;
    this.simpleQuery.descending = this.sortState.direction === 'desc';
    this.simpleQuery.pageNumber = event.pageIndex;
    this.simpleQuery.pageSize = event.pageSize;
    this.pageChange.emit(this.simpleQuery);
    return event;
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.simpleQuery.order = this.sortState.active;
    this.simpleQuery.descending = this.sortState.direction === 'desc';
    this.pageChange.emit(this.simpleQuery);
  }

}
