import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SiteGeoModel } from 'src/app/modules/core/shared/models/site-geo.model';
import { SiteGeoService } from 'src/app/modules/core/services/site-geo.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { Sort } from '@angular/material/sort';
import { filterTierDTO } from 'src/app/modules/core/shared/dtos/filterTierDTO';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalAjoutSiteGeoComponent } from 'src/app/modules/core/components/site-geographique/modal-ajout-site-geo.component';
import { ModalAfficherSiteGeoComponent } from 'src/app/modules/core/components/site-geographique/modal-afficher-site-geo.component';
import { Router } from '@angular/router';
import { PriseAppelService } from 'src/app/modules/core/services/prise-appel.service';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import * as _ from 'lodash';    
import { Subscription } from 'rxjs';
import { DemandeDTO } from 'src/app/modules/core/shared/dtos/demandeDTO';
import { ModalHistoriqueDemandesComponent } from '../modals/modal-historique-demandes/modal-historique-demandes.component';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { SiteGeoPgResult } from 'src/app/modules/core/shared/dtos/siteGeoPgResult';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';

@Component({
  selector: 'app-tier-adresses',
  templateUrl: './tier-adresses.component.html',
  styleUrls: ['./tier-adresses.component.scss']
})

export class TierAdressesComponent implements OnInit, OnDestroy {

  displayedColumnsAdresse: string[] = ['Adresse', 'CodePostal', 'Ville', 'Libelle', 'Actions'];
  adresses: SiteGeoModel[] = [];  
  matDataSource: MatTableDataSource<SiteGeoPgResult>;

  length: number;
  pageIndex: number;
  pageSize = 10;
  sortState: Sort = {active: 'Similarity', direction: 'desc'};
  subscriptionSiteGeo: Subscription;
  selectedSiteGeoFromParent: SiteGeoModel;
  @Input() selectedAdresseDuplique : SiteGeoModel;
  selectedAdresse:SiteGeoModel = new SiteGeoModel();
  @Input() adresseRequired =false
  @Input() simpleQuerySiteGeo: SimpleQuery<SiteGeoModel>;
  @Input() selectedAdressesTier: TierModel; //Utilisé pour trigger ngOnChanges dans tier-adresses-component
  @Input() tierFilter: filterTierDTO;
  @Input() updateAdresse = false;
  @Input() isInCreateTiersModal : boolean
  @Output() selectAdresseTiers = new EventEmitter<SiteGeoModel>();
  oldTierFiltre : filterTierDTO = new filterTierDTO();
  


  constructor(private siteGeoService: SiteGeoService,
    public dialog: MatDialog,
    private priseAppelService : PriseAppelService,
    private ref: ChangeDetectorRef,
    public router: Router,
    private demandeService: DemandeService,
    private toastrLoadingService: ToastrLoadingService) {}

  ngOnInit(): void {
    this.simpleQuerySiteGeo.pageSize = this.pageSize;
    this.subscriptionSiteGeo = this.priseAppelService.currentselectedTiersAdresse.subscribe(selectedSiteGeoSub => {
      let siteGeo = selectedSiteGeoSub
      if(!_.isEqual(this.selectedSiteGeoFromParent,selectedSiteGeoSub)  && this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
        if(this.selectedAdresse != undefined){
          if(this.selectedAdresse.id != siteGeo.id && this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
            this.selectedAdresse = siteGeo
            this.changeAdresses()
            this.selectAdresseTiers.emit(this.selectedAdresse)
            this.ref.detectChanges();
          }
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedAdressesTier && changes.selectedAdressesTier.currentValue && changes.selectedAdressesTier.currentValue != changes.selectedAdressesTier.previousValue){
    this.simpleQuerySiteGeo.pageNumber = 0;
    this.pageIndex = 0;
    
    if(Object.keys(this.tierFilter.siteGeo).length>0){
      this.oldTierFiltre = Object.assign({},this.tierFilter);
      this.simpleQuerySiteGeo.entry.adresse = this.tierFilter.siteGeo.Adresse ? this.tierFilter.siteGeo.Adresse + " " : ""  + this.tierFilter.siteGeo.Libelle ? this.tierFilter.siteGeo.Libelle : "";
      this.simpleQuerySiteGeo.entry.codePostal = this.tierFilter.siteGeo.CodePostal;
    }
    this.changeAdresses();
    }

  }

  ngOnDestroy(): void {
    this.subscriptionSiteGeo.unsubscribe();
  }

  changePage(event?: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.simpleQuerySiteGeo.pageNumber = event.pageIndex;
    this.simpleQuerySiteGeo.pageSize = event.pageSize;
    this.changeAdresses();
  }

  
  onAddAdresse(){
      const dialogRef = this.dialog.open(ModalAjoutSiteGeoComponent, {disableClose: true,
        width: '700px',
        data: {
          tierId: this.selectedAdressesTier.id,
          filter: this.tierFilter,
          libelleTier : this.selectedAdressesTier.libelle
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          const currentdata = this.matDataSource.data;
          const siteCreated: SiteGeoPgResult = new SiteGeoPgResult();
          siteCreated.histo = false;
          siteCreated.similarity = 0;
          siteCreated.siteGeo = data.site;
          currentdata.unshift(siteCreated);
          this.matDataSource = new MatTableDataSource(currentdata);
          this.matDataSource._updateChangeSubscription();
          this.selectAdresseTiers.emit(data.site)
          this.selectedAdresse=data.site;
          if(this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
            this.priseAppelService.updateselectedTiersAdresse(this.selectedAdresse);
          }
        }
      });
  }

  onEditAdresse(adresse){
    const dialogRef = this.dialog.open(ModalAfficherSiteGeoComponent, {disableClose: true,
      width: '700px',
      data: {
        site: Object.assign({}, adresse.siteGeo)
      }
    });
    dialogRef.afterClosed().subscribe(site => {
      if (site) {
        let currentdata = this.matDataSource.data;
        currentdata.splice(currentdata.findIndex(i => adresse.siteGeo.id == i.siteGeo.id), 1);
        
        const siteCreated: SiteGeoPgResult = new SiteGeoPgResult();
        siteCreated.histo = false;
        siteCreated.similarity = 0;
        siteCreated.siteGeo = site;

        currentdata.unshift(siteCreated);
        this.matDataSource = new MatTableDataSource(currentdata);
        this.matDataSource._updateChangeSubscription();
        this.selectAdresseTiers.emit(site)
          this.selectedAdresse=site;
          if(this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
            this.priseAppelService.updateselectedTiersAdresse(this.selectedAdresse);
          }
      }
    });
}
  trieTab(sort: Sort) {
    this.sortState = sort;
    this.simpleQuerySiteGeo.order = sort.active;
    this.simpleQuerySiteGeo.descending = sort.direction === 'desc';
    this.changeAdresses();
  }

  changeAdresses() {
    let idSearch: number;

    if (this.simpleQuerySiteGeo.entry.tierId) {
      idSearch = this.toastrLoadingService.newSearch();
      this.siteGeoService.GetFromObjetPgWithPagination(this.simpleQuerySiteGeo).subscribe((queryResponse: SimpleQueryResponse<SiteGeoPgResult>) => {
        this.matDataSource = new MatTableDataSource(queryResponse.objets);
        this.length = queryResponse.count;
        this.pageIndex = queryResponse.pageNumber;
        // selected address if duplication
        if(this.selectedAdresseDuplique){
          this.selectedAdresse = this.selectedAdresseDuplique;
        }
        this.toastrLoadingService.endSearch(idSearch);
      }, error => {
        this.toastrLoadingService.endSearch(idSearch);
      });
    }
  }

  selectAdresse(event, row){
    if(this.selectedAdresse && this.selectedAdresse.id == row.id){
        this.selectedAdresse = new SiteGeoModel()
        
    }else{
      this.selectedAdresse=row;
    }
    this.selectAdresseTiers.emit(this.selectedAdresse)

    if(this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
      this.priseAppelService.updateselectedTiersAdresse(this.selectedAdresse);
      }
  }
/*
  openHistoriqueDemandesAdresses(siteGeo: SiteGeoModel) {
    var demandeDTO = new FiltreDemandeDTO();
    demandeDTO.adresse = siteGeo.adresse;
    demandeDTO.codePostal = siteGeo.codePostal;
    demandeDTO.ville = siteGeo.ville;
    this.demandeService.getFiltredDemande(demandeDTO).subscribe(res =>  {
      const dialogRef = this.dialog.open(ModalHistoriqueDemandesComponent, {
        data: {
          title: "Historique des demandes du tiers",
          demandes: res.objets,
        }
      });    
    })   
  }*/
  openHistoriqueDemandesAdresses(siteGeo: SiteGeoModel) {
    var demandeDTO = new DemandeDTO();
    demandeDTO.siteGeoId = siteGeo.id;
    demandeDTO.tierId = siteGeo.tierId;
    const query = new SimpleQuery<DemandeDTO>();
    query.entry = demandeDTO;
    query.order = 'DateCreation';
    query.descending = true;
    query.pageSize = 10;
    this.demandeService.getFromObjet(query).subscribe(res =>  {
      const dialogRef = this.dialog.open(ModalHistoriqueDemandesComponent, {
        data: {
          title: "Historique des demandes du tiers",
          demandes: res.objets,
          query: query
        }
      });
    })
  }
}
