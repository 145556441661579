import { TierBasicMock } from './basic-mock/tier-basic.mock';
import { FormulaireModel } from '../models/formulaire.model';
import { Injectable } from '@angular/core';

@Injectable()
export class FormulairenMock extends TierBasicMock {

    constructor() {
        super();
        (<FormulaireModel[]> this.objets) = [
            {
            id: 1, lien: 'https://docs.google.com/forms/d/e/1FAIpQLSfhxYN03YHkKQn7eQT8Gg1mADYxaApF-pyCdPH5TuPY1NRi3A/viewform?usp=sf_link',
            lienEdit: 'https://docs.google.com/forms/d/1Hdy36eM2unzTEQklHnyflpwj_FSDtE6Dan8-_5Xq720/edit',
            libelle: 'Centinel', commentaire: '14h',tierId: 1
          }
        ];
    }

    getFromObjet(objetRecherche: FormulaireModel): FormulaireModel[] {
        return Object.assign([], this.objets.filter((objTab: FormulaireModel) => this.sameFormulaire(objTab, objetRecherche)));
      }
    
    
      sameFormulaire(objTab: FormulaireModel, objRecherche: FormulaireModel): boolean{
        let same: boolean = undefined;
        let dr: Date;
        let dt: Date;
        
        
        if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
          return false;
        }
        else if (objRecherche.libelle !== undefined && objRecherche.libelle.length !== 0 && objTab.libelle.toLowerCase().indexOf(objRecherche.libelle.toLowerCase()) === -1) {
            return false;
        }
        else if (objRecherche.lien !== undefined && objRecherche.lien.length !== 0 && objTab.lien.toLowerCase().indexOf(objRecherche.lien.toLowerCase()) === -1) {
          return false;
        }
        else if (objRecherche.commentaire !== undefined && objRecherche.commentaire.length !== 0 && objTab.commentaire.toLowerCase().indexOf(objRecherche.commentaire.toLowerCase()) === -1) {
          return false;
        }
    
        return true;
      }
}
