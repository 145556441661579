import { ProtocoleModel } from '../core/shared/models/protocole.model';
import { ActionModel } from '../core/shared/models/action.model';
import { DemandeModel } from '../core/shared/models/demande.model';
import { FormulaireModel } from '../core/shared/models/formulaire.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DemandeService } from '../core/services/demande.service';
import { ActionService } from '../core/services/action.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DocumentModel } from '../core/shared/models/document/document.model';
import { ModalClosedActionComponent } from '../core/components/action/modal-closed-action.component';
import { ModalResumeActionComponent } from '../core/components/action/modal-resume-action.component';
import { ModalAfficherDemandeComponent } from '../core/components/demande/modal-afficher-demande.component';
import { ModalAfficherDocumentComponent } from '../core/components/document/modal-afficher-document/modal-afficher-document.component';
import { ModalAfficherFormulaireComponent } from '../core/components/formulaire/modal-afficher-formulaire.component';
import { ModalAfficherProtocoleComponent } from '../core/components/protocole/modal-afficher-protocole/modal-afficher-protocole.component';
import { ModalAfficherDemandeUrgenceComponent } from '../core/components/demande/modal-afficher-demande-urgence.component';

@Injectable({ providedIn: 'root' })
export class ModalsService {

  private standardWidth = '90%';
  private maxHeight = '99vh';
  private demandeId: number;
  private containers = [];
  private modalID: string;
  private actionId: number;
  private formulaireId: number;
  private protocoleId: number;
  private documentId: number;


  constructor (private dialog: MatDialog, private demandeService: DemandeService, private actionService: ActionService) {}

  openDemande(demande: DemandeModel): Observable<any> {
    let dialogRef;
    if(demande.typeDemandeId !== 1000){
      dialogRef = this.dialog.open(ModalAfficherDemandeComponent,
        {
          disableClose: true,
          width: this.standardWidth,
          maxHeight: this.maxHeight,
          data: {
            demande: demande
        }
      });
    }else{
      dialogRef = this.dialog.open(ModalAfficherDemandeUrgenceComponent,
        {
          disableClose: true,
          width: this.standardWidth,
          maxHeight: this.maxHeight,
          data: {
            demande: demande
        }
      });
    }
    
    this.demandeId = undefined;
    for (const container of this.containers) {
      if (container.type === 'Demande' && container.demande.id === demande.id) {
        this.demandeId = demande.id;
        break;
      }
    }
    if (this.demandeId === undefined) {
      this.containers.push({modalID: dialogRef.id, demande: demande, type: 'Demande', action: undefined});
    }
    return dialogRef.afterClosed();
  }

  closeDemande(demande: DemandeModel) {
    for (let i = 0; i < this.containers.length; i++) {
      if (this.containers[i].type === 'Demande') {
        if (this.containers[i].demande.id === demande.id) {
          this.containers.splice(i, 1);
        }
      }
    }
  }

  openAction(action: ActionModel): Observable<any> {
    const dialogRef = this.dialog.open(ModalResumeActionComponent,
      {
        disableClose: false,
        autoFocus: false,
        data: {
          action: action
      }
    });
    this.actionId = undefined;
    for (const container of this.containers) {
      if (container.type !== 'Demande' && container.action && container.action.id === action.id) {
        this.actionId = action.id;
        break; 
      }
    }
    if (this.actionId === undefined) {
      this.containers.push({modalID: dialogRef.id, demande: undefined, type: 'Action', action: action});
    }

    return dialogRef.afterClosed();
  }

  openClosedAction(action: ActionModel): Observable<any> {
    const dialogRef = this.dialog.open(ModalClosedActionComponent, {
      maxHeight: this.maxHeight,
      data: {
        action: action
      }
    });

    this.actionId = undefined;
    for (const container of this.containers) {
      if (container.type !== 'Demande' && container.action && container.action.id === action.id) {
        this.actionId = action.id;
        break;
      }
    }
    if (this.actionId === undefined) {
      this.containers.push({modalID: dialogRef.id, demande: undefined, type: 'ClosedAction', action: action});
    }

    return dialogRef.afterClosed();
  }

  closeAction(action: ActionModel) {
    for (let i = 0; i < this.containers.length; i++) {
      if (this.containers[i].type !== 'Demande' && this.containers[i].action) {
        if (this.containers[i].action.id === action.id) {
          this.containers.splice(i, 1);
        }
      }
    }
  }

  /* open modal formulaire  */
  openFormulaire(formulaire: FormulaireModel): Observable<any> {
    const dialogRef = this.dialog.open(ModalAfficherFormulaireComponent,
      {
        disableClose: true,
        width: this.standardWidth,
        maxHeight: this.maxHeight,
        data: {
          formulaire: formulaire
      }
    });
    this.formulaireId = undefined;
    for (const container of this.containers) {
      if (container.type !== 'Demande' && container.action?.id === formulaire.id) {
        this.formulaireId = formulaire.id;
        break;
      }
    }
    if (this.formulaireId === undefined) {
      this.containers.push({modalID: dialogRef.id, demande: undefined, type: 'Formulaire', formulaire: formulaire});
    }

    return dialogRef.afterClosed();
  }

  closeFormulaire(formulaire: FormulaireModel) {
    for (let i = 0; i < this.containers.length; i++) {
      if (this.containers[i].type !== 'Demande') {
        if (this.containers[i].formulaire.id === formulaire.id) {
          this.containers.splice(i, 1);
        }
      }
    }
  }


  getContainers() {
    return this.containers;
  }

  getModalID() {
    return this.modalID;
  }

  setModalID(id: string) {
    this.modalID = id;
  }

  openDocument(document: DocumentModel): Observable<any> {
    const dialogRef = this.dialog.open(ModalAfficherDocumentComponent,
      {
        disableClose: false,
        data: {
          document: document
      }
    });
    this.documentId = undefined;

    return dialogRef.afterClosed();
  }


    /* open modal formulaire  */
    openProtocole(protocole: ProtocoleModel): Observable<any> {
      const dialogRef = this.dialog.open(ModalAfficherProtocoleComponent,
        {
          disableClose: true,
          autoFocus: false,
          width: this.standardWidth,
          maxHeight: this.maxHeight,
          data: {
            protocole: protocole
        }
      });
      this.protocoleId = undefined;
      if (this.protocoleId === undefined) {
        this.containers.push({modalID: dialogRef.id, demande: undefined, type: 'Protocole', protocole: protocole});
      }
      return dialogRef.afterClosed();
    }

    /* close modal formulaire  */
    closeProtocole(protocole: ProtocoleModel) {
      for (let i = 0; i < this.containers.length; i++) {
        if (this.containers[i].type !== 'Demande') {
          if (this.containers[i].protocole.id === protocole.id) {
            this.containers.splice(i, 1);
          }
        }
      }
    }

}
