import { TierDTO } from "../shared/dtos/tierDTO";
import { TierModel } from "../shared/models/tier/tier.model";

export class TransformatorTiersModel {


    public convertModelToDto(tiers: TierModel, dto: TierDTO): TierDTO {
        dto.clientNumber = tiers.clientNumber;
        dto.identifiantJason = tiers.identifiantJason;
        dto.typeTier = tiers.typeTier;
        dto.libelle = tiers.libelle;
        dto.natureId = tiers.natureId;
        dto.tva = tiers.tva;
        dto.numCommOblig = tiers.numCommOblig;
        dto.regleSurPlace = tiers.regleSurPlace;
        dto.adresseIdentique = tiers.adresseIdentique;
        dto.adresse = tiers.adresse;
        dto.adresseFact = tiers.adresseFact;
        dto.complementAdresse = tiers.complementAdresse;
        dto.complementAdresseFact = tiers.complementAdresseFact;
        dto.codePostal = tiers.codePostal;
        dto.codePostalFact = tiers.codePostalFact;
        dto.ville = tiers.ville;
        dto.villeFact = tiers.villeFact;
        dto.email = tiers.email;
        dto.telephone = tiers.telephone;
        dto.agenceId = tiers.agenceId;
        dto.commercialId = tiers.commercialId;
        return dto;
    }

    public convertDtoToModel(tiers: TierDTO, objet: TierModel): TierModel {
        objet.codePostal = tiers.codePostal;
        objet.clientNumber = tiers.clientNumber;
        objet.identifiantJason = tiers.identifiantJason;
        objet.typeTier = tiers.typeTier;
        objet.libelle = tiers.libelle;
        objet.natureId = tiers.natureId;
        objet.adresse = tiers.adresse;
        objet.adresseFact = tiers.adresseFact;
        objet.complementAdresse = tiers.complementAdresse;
        objet.complementAdresseFact = tiers.complementAdresseFact;
        objet.codePostal = tiers.codePostal;
        objet.codePostalFact = tiers.codePostalFact;
        objet.ville = tiers.ville;
        objet.villeFact = tiers.villeFact;
        objet.email = tiers.email;
        objet.telephone = tiers.telephone;
        objet.agenceId = tiers.agenceId;
        objet.commercialId = tiers.commercialId;
        return objet;
      }
}