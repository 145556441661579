import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { TierService } from 'src/app/modules/core/services/tier/tier.service';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { SiteGeographiqueComponent } from 'src/app/modules/core/components/site-geographique/site-geographique.component';
import { SiteGeoModel } from 'src/app/modules/core/shared/models/site-geo.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SiteGeoService } from 'src/app/modules/core/services/site-geo.service';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { SiteGeoPgResult } from 'src/app/modules/core/shared/dtos/siteGeoPgResult';


export interface DialogData {
  tier: TierModel;
}

@Component({
  selector: 'app-modal-select-adresse',
  templateUrl: './modal-select-adresse.component.html',
  styleUrls: ['./modal-select-adresse.component.scss']
})
export class ModalSelectAdresseComponent implements OnInit {
  simpleQuerySiteGeo: SimpleQuery<SiteGeoModel> = new SimpleQuery<SiteGeoModel>();
  simpleQueryResponse: SimpleQueryResponse<SiteGeoPgResult>;
  @ViewChild(SiteGeographiqueComponent) sitegeosComp: SiteGeographiqueComponent;
  panelOpenStateSite = true;
  sites: SiteGeoModel[] = [];
  tier: TierModel = new TierModel();
  constructor(private tierService: TierService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private siteGeoService: SiteGeoService,
    private toastrLoadingService: ToastrLoadingService,
    injector: Injector) { 
      this.simpleQuerySiteGeo.entry = new SiteGeoModel();
      this.simpleQuerySiteGeo.entry.tierId = this.data.tier.id;
    }

  ngOnInit(): void {
    this.tier = this.data.tier;
  }

  onAddSite() {
    this.sitegeosComp.onAddSite();
    this.panelOpenStateSite = !this.panelOpenStateSite;
  }

  onUpdateSite(sitegeos: SiteGeoModel[]) {
    this.sites = Object.assign([], sitegeos);
    this.tierService.get(this.tier.id).subscribe((tier: TierModel) => {
      this.tier = tier;
    });
  }

  onSelectSite(sitegeo: SiteGeoModel) {
    this.dialogRef.close(sitegeo);
  }

  rechercheAdresse(){
    let idSearch = this.toastrLoadingService.newSearch();
    this.siteGeoService.GetFromObjetPgWithPagination(this.simpleQuerySiteGeo).subscribe((queryResponse: SimpleQueryResponse<SiteGeoPgResult>) => {
      this.simpleQueryResponse = queryResponse;
      this.toastrLoadingService.endSearch(idSearch);
    }, error => {
      this.toastrLoadingService.endSearch(idSearch);
    });
  }

  onModalCancel(): void {
    this.dialogRef.close(false);
  }
}
