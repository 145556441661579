export class DevisRaisons {
    public static listRaisonRefus = ['Le client souhaite juste un devis', 'Prestation trop chère', 'Projet remis à plus tard', 'Projet en cours d\'élaboration', 'Projet prime rénovation', 'Choix d’un autre prestataire', 'Intervention faite par le client lui même'];
    public static listRaisonAnnulation = ['Le client souhaite juste un devis', 'Prestation trop chère', 'Projet remis à plus tard', 'Projet en cours d\'élaboration', 'Projet prime rénovation', 'Choix d’un autre prestataire', 'Intervention faite par le client lui même'];
    public static DevisStatus = new Map([
        ['À envoyer', 1],
        ['Transmis', 2],
        ['Refusé', 3],
        ['Annulé', 4],
        ['Validé', 5]
    ]);
}




