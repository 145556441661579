import { Injectable } from "@angular/core";
import { SocieteModel } from "../models/rsa/societe.model";
import { AgenceModel } from "../models/rsa/agence.model";
import { CommercialModel } from "../models/users/commercial.model";


@Injectable()
export class FiltreDemandeDTO {
  public tierId: number = null;
  public tierFactureId: number = null;
  public objet: string = "";
  public status: string[];
  public startDCreationDemande: Date = null; // Date de création du
  public endDCreationDemande: Date = null; // Date de création au
  public startDInterventionDemande: Date = null; // Date de création du
  public endDInterventionDemande: Date = null; // Date de création au
  public typeDemandeId: number[] = null;
  public codePostal: string = "";
  public ville: string = "";
  public adresse: string = "";
  public numeroDemande: string = "";
  public commentaire: string = "";
  public commentaireApprox: string = "";
  public pageNumber = 0;
  public pageSize = 50;
  public order = "DateCreation";
  public descending: boolean = true;

  public createurs: CommercialModel[] = [];
  public realisateurs: CommercialModel[] = [];
  public gestionnaires: CommercialModel[] = [];
  
  public societes: SocieteModel[] = [];
  public agences: AgenceModel[] = [];

  public etatUrgence: number[] = null;

  public FAnnulePxoSelected: boolean = false; 
}
