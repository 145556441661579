import { Injectable } from "@angular/core";
import { SocieteModel } from "../models/rsa/societe.model";
import { AgenceModel } from "../models/rsa/agence.model";
import { CommercialModel } from "../models/users/commercial.model";


@Injectable()
export class FiltrePerimetreDTO {

  public id : number;
  public libelle: string;
  public userId: number;

  public createurs: CommercialModel[] = [];
  public realisateurs: CommercialModel[] = [];
  public createurIds: number[] = null;
  public realisateurIds: number[] = null;
  
  public societes: SocieteModel[] = [];
  public societeIds: number[] = null;

  public agences: AgenceModel[] = [];
  public AgencesIds: number[] = null;
}
