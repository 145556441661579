import { TierBasicMock } from './basic-mock/tier-basic.mock';
import { BasicMock } from './basic-mock/basic.mock';
import { SiteGeoModel } from '../models/site-geo.model';
import { Injectable } from "@angular/core";

@Injectable()
export class SiteGeoMock extends TierBasicMock  {
  constructor() {
    super();
    (<SiteGeoModel[]> this.objets) = [
      {
        id: 1, tierId: 1, libelle: 'Siege',
        adresse: '10 rue de la paix', codePostal: '75001', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'best.autos@bestautos.com', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 2, tierId: 2, libelle: 'magasin',
        adresse: '52 av des champs', codePostal: '75002', gps: 'gps1', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'brasserie@lorraine.com', telephone: '06 00 00 01', numSite: ''
      },
      {
        id: 3, tierId: 3, libelle: 'Siege',
        adresse: '35 rue des prés', codePostal: '75003', gps: 'gps2', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'le-beaugency@gmail.com', telephone: '06 00 00 02', numSite: ''
      },
      {
        id: 4, tierId: 4, libelle: 'Siege',
        adresse: '11 route principale', codePostal: '75004', gps: 'gps2', complementAdresse: '', ville: 'paris',
        commentaire: '', email: 'sci.bmb@ysci.fr', telephone: '06 00 00 02', numSite: ''
      },
      {
        id: 5, tierId: 5, libelle: 'Mairie',
        adresse: '15 rue de la vaugirard', codePostal: '75005', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'mairie.5@gouv.fr', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 6, tierId: 6, libelle: 'Mairie',
        adresse: '15 rue de la vaugirard', codePostal: '75006', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'mairie.6@gouv.fr', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 7, tierId: 7, libelle: 'Mairie',
        adresse: '15 rue de la vaugirard', codePostal: '75007', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'mairie.7@gouv.fr', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 8, tierId: 8, libelle: 'Mairie',
        adresse: '15 rue de la vaugirard', codePostal: '75008', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'mairie.8@gouv.fr', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 9, tierId: 9, libelle: 'Domicile',
        adresse: '52 rue clemand', codePostal: '75010', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'cedric.stefani@gmail.com', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 10, tierId: 10, libelle: 'Domicile',
        adresse: '52 rue clemand', codePostal: '75010', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'jjb@gmail.com', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 11, tierId: 11, libelle: 'Domicile',
        adresse: '52 rue clemand', codePostal: '75010', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'jrobin@gmail.com', telephone: '06 00 00 00', numSite: ''
      },
      {
        id: 12, tierId: 12, libelle: 'Domicile',
        adresse: '52 rue clemand', codePostal: '75010', gps: 'gps0', complementAdresse: '', ville: 'Paris',
        commentaire: '', email: 'christine-m@gmail.com', telephone: '06 00 00 00', numSite: ''
      },
    ];
  }
}
