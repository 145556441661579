import { Component, Injectable } from '@angular/core';

@Component({
  selector: 'app-modal-select-tier',
  templateUrl: 'modal-select-tier.html',
})

@Injectable()
export class ModalSelectTier {
    result: string;
    constructor() {}
  }
