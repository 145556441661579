import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../basic-service/basic.service';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionService } from 'src/app/modules/shared/session.service';
import { UserEtiquetteModel } from '../../shared/models/etiquette/userEtiquette.model';
import { ParametreModel } from '../../shared/models/parametre/parametre-utilisateur.model';


@Injectable()
export class ParametreUtilisateurService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEPARAMETREUSER;
    }

    getFromUser(): Observable<ParametreModel[]> {
      return this.http.get<ParametreModel[]>(this.apiUrl + this.serviceUrl + 'GetFromUser/').pipe(tap((parametres: ParametreModel[]) => {
        for (const parametre of parametres) {
          parametre.valeur = JSON.parse(JSON.stringify(parametre.valeur));
          if (parametre.libelle === 'Etiquette') {
            const etiquettes: UserEtiquetteModel[] = JSON.parse(parametre.valeur) as UserEtiquetteModel[];
            parametre.valeur = etiquettes;
          }
        }
      }));
    }

  }
