import { SiteGeoModel } from '../../../core/shared/models/site-geo.model';
import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { GmapsSiteGeoModel } from 'src/app/modules/core/shared/models/gmapsSiteGeo.model';
import { GmapsService } from 'src/app/modules/shared/gapiServices/gmaps.service';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';

@Component({
  selector: 'app-autocomplete-adresse',
  templateUrl: './autocomplete-adresse.component.html',
  styleUrls: ['./autocomplete-adresse.component.scss']
})
export class AutocompleteAdresseComponent implements OnChanges {

  /* Interlocuteur object to relationships */
  @Input() adresse: SiteGeoModel;
  @Input() adresseChangeDisabled = false;

  // focus
  @ViewChild('input') input: ElementRef;

  /*Input pour communiquer avec le component parent*/
  @Input() adresses: SiteGeoModel[] = [];
  adresseControl = new UntypedFormControl();
  adressesString: string[] = [];
  disabledLibelle = false;
  filteredAdresses: string[];
  filteredAdressesStatus: number[];
  @Input() disabledCommentaire: boolean;
  @Input() realisateur: UserModel;
  @Output() selectedAdresse = new EventEmitter<SiteGeoModel>();
  @Output() protocole = new EventEmitter<boolean>();
  @Output() commentaireUpdate = new EventEmitter<string>();
  @Input() modalProtocole: boolean = false;

  fieldStatus = false;

  // Récupération de l'interlocuteur de la demande
  @Input() adresseId: number;
  @Input() libelle = 'Adresse d\'intervention';
  adresseDemande = '';
  label: string;
  timeTravel: string = "";
  distanceTravel: string = "";
  gmapsAdresses: GmapsSiteGeoModel[] = [];

  constructor(
    private gmapsService: GmapsService,
    private agenceService: AgenceService,
    private sessionService: SessionService,
    private IntercomToastrService: IntercomToastrService) {
   }

  ngOnChanges(changes: SimpleChanges): void {
    this.label = this.libelle;
    if (this.libelle === 'Facturation') {
      this.disabledLibelle = true;
    }
    if (this.libelle === 'Facturation tiers' && this.adresse) {
      this.adresseDemande = (this.adresse.adresse + ' ' +  this.adresse.codePostal + ' ' + this.adresse.ville).trim();
    }
    if (this.adresseChangeDisabled !== undefined && this.adresseChangeDisabled === true) {
      this.adresseControl.disable();
      this.fieldStatus = true;
    } else if (this.adresseChangeDisabled !== undefined && this.adresseChangeDisabled === false) {
      this.adresseControl.enable();
      this.fieldStatus = false;
    }
    if (this.adresses) {
      const filteredAdresses: SiteGeoModel[] = [];
      this.adresses.sort((x,y) => (x.agenceId === y.agenceId)? 0 : x.agenceId? -1 : 1);
      this.adresses.forEach((item) => {
        // tslint:disable-next-line: max-line-length
        // suppression des doublons d'adresse /!\ impossible de  différentier les adresses maintenants
        let adresseIndex = filteredAdresses.findIndex(i => (i.adresse + ' ' + i.codePostal + ' ' + i.ville).trim() === (item.adresse + ' ' + item.codePostal + ' ' + item.ville).trim());
        if (item.libelle !== 'Facturation' && adresseIndex === -1) {
          filteredAdresses.push(item);
        }
      });
      this.adresses = filteredAdresses;
      this.adressesString = [];
      // Début de la récupération de l'adresse lié à la demande

      if (this.adresseId) {
        this.adresse = this.adresses.find(i => i.id === this.adresseId);
        this.selectedAdresse.emit(this.adresse);
        this.adresseId = undefined;
        if (this.adresse !== undefined) {
          this.adresseId = this.adresse.id;
          this.fieldStatus = true;
          this.adresseDemande = (this.adresse.adresse + ' ' +  this.adresse.codePostal + ' ' + this.adresse.ville).trim();
        }
      } else if (this.adresses.length > 0) {
        if (!this.adresse) {
          if (!this.modalProtocole) {
            this.protocole.emit(true);
            this.selectedAdresse.emit(this.adresse);
            this.fieldStatus = false;
            this.adresseDemande = '';
          }
        } else if (this.adresse && this.adresse.id !== undefined) {
          this.selectedAdresse.emit(this.adresse);
          this.fieldStatus = true;
          this.adresseDemande = (this.adresse.adresse + ' ' +  this.adresse.codePostal + ' ' + this.adresse.ville).trim();
        }
      } else if (this.adresses.length === 0 && this.adresse && this.adresse.id !== undefined) {
          this.selectedAdresse.emit(this.adresse);
          this.fieldStatus = true;
          this.adresseDemande = (this.adresse.adresse + ' ' +  this.adresse.codePostal + ' ' + this.adresse.ville).trim();
      }
      // Fin de la récupération de l'adresse associé à la demande

      // filter adresse
      for (const adresse of this.adresses) {
        this.adressesString.push((adresse.adresse + ' ' + adresse.codePostal + ' ' + adresse.ville).trim());
      }
      this.adressesString.sort();

      // Adresse de Google
      this.adresseControl.valueChanges.pipe(
        startWith(''),
        switchMap((adresse: string) => {
          let gmapsAdressesString = [];
          const filteredAdresse = adresse != null ? this._filter(adresse) : [];
          if (filteredAdresse.length !== 0) {
            this.gmapsAdresses = [];
            gmapsAdressesString = filteredAdresse;
            return of(gmapsAdressesString);
          } else {
            if (adresse.length > 3) {
              return this.gmapsService.getAutoCompleteResults(adresse).pipe(map((gmapsAdresses: GmapsSiteGeoModel[]) => {
                this.gmapsAdresses = gmapsAdresses;
                for (const gmapsAdresse of gmapsAdresses) {
                  gmapsAdressesString.push(gmapsAdresse.adresse + ' ' + gmapsAdresse.ville);
                }
                return gmapsAdressesString;
              }));
            } else {
              return of(gmapsAdressesString);
            }
          }
        })
      ).subscribe((gmapsAdresses: string[]) => {
        this.filteredAdresses = gmapsAdresses;
      });
    }
    // adresse de facturation envoie d'objet adresse init du champs adresse complete
    if (this.adresse) {
      if (!this.modalProtocole) {
        this.adresseDemande = (this.adresse.adresse + ' ' +  this.adresse.codePostal + ' ' + this.adresse.ville).trim();
        this.adresse.commentaire ?  this.adresse.commentaire : '';
      } else {
        this.adresse = undefined;
        this.adresseDemande = '';
      }
    } else {
      this.adresseDemande = '';
    }
    //GMapTravel
    this.getTime();
  }

  // Filtered adresse
  onFullAutoAdresse(value: string) {
    if (value !== '') {
      const adresse = this.adresses.find(myAdresse => (myAdresse.adresse + ' ' +  myAdresse.codePostal + ' ' + myAdresse.ville).trim() === value);
      if (adresse !== undefined) {
        this.adresse = Object.assign({}, adresse);
        this.selectedAdresse.emit(this.adresse);
      } else {
        this.fieldStatus = false;
        this.adresse = new SiteGeoModel();
        this.adresse.libelle = this.libelle;
        const regexRes = value.match(/[0-9]{5}/);
        const interlocuteurFullName = value.split(/[0-9]{5}/, 2);
        this.adresse.adresse = (typeof interlocuteurFullName[0] !== 'undefined') ? interlocuteurFullName[0].trim() : '';
        this.adresse.ville = (typeof interlocuteurFullName[1] !== 'undefined') ? interlocuteurFullName[1].trim() : '';
        this.adresse.codePostal = (typeof regexRes !== 'undefined' && regexRes) ? regexRes[0].trim() : '';
        this.selectedAdresse.emit(this.adresse);
      }
    } else {
      this.adresse = undefined;
      this.selectedAdresse.emit(this.adresse);
    }
  }

  /* selected event*/
  adresseChange(value: string) {
    if (value !== '') {
      if (this.gmapsAdresses.length === 0 && this.adresses.length !== 0) {
        // tslint:disable-next-line: max-line-length
        const adresseFound = this.adresses.find(adresse => (adresse.adresse + ' ' + adresse.codePostal + ' ' + adresse.ville).trim().localeCompare(value) === 0);
        if (adresseFound) {
          this.adresse = <SiteGeoModel> Object.assign({}, adresseFound);
        } else {
          this.onFullAutoAdresse(value);
        }
        this.selectedAdresse.emit(this.adresse);
        if (this.adresse.id) {
          this.fieldStatus = true;
        }
      } else if (this.gmapsAdresses.length !== 0) {
        this.fieldStatus = false;
        let id = '';
        for (const gmapAdresse of this.gmapsAdresses) {
          if (gmapAdresse.adresse + ' ' + gmapAdresse.ville === value) {
            id = gmapAdresse.placeId;
            break;
          }
        }
        this.gmapsService.getGeoCodeResults(id).subscribe((gmapAdresse: GmapsSiteGeoModel[]) => {
          this.adresse = gmapAdresse[0];
          this.adresse.adresse = this.adresse.adresse.trim();
          if (this.adresse !== undefined) {
            this.adresse.libelle = this.libelle;
            this.adresse.codePostal = this.adresse.codePostal ? this.adresse.codePostal : '00000';
          }
          this.selectedAdresse.emit(this.adresse);
          this.input.nativeElement.blur();
        });
      } else {
        this.fieldStatus = false;
        this.adresse = new SiteGeoModel();
        this.adresse.libelle = this.libelle;
        const regexRes = value.match(/[0-9]{5}/);
        const interlocuteurFullName = value.split(/[0-9]{5}/, 2);
        this.adresse.adresse = (typeof interlocuteurFullName[0] !== 'undefined') ? interlocuteurFullName[0].trim() : '';
        this.adresse.ville = (typeof interlocuteurFullName[1] !== 'undefined') ? interlocuteurFullName[1].trim() : '';
        this.adresse.codePostal = (typeof regexRes !== 'undefined' && regexRes) ? regexRes[0].trim() : '';
        this.selectedAdresse.emit(this.adresse);
      }
    } else {
      this.adresse = undefined;
      this.selectedAdresse.emit(this.adresse);
    }
    this.getTime();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.adressesString.filter(adresse => adresse.toLowerCase().indexOf(filterValue) >= 0);
  }

  openGMap() {
    let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;
   if (this.realisateur?.agenceId){
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {
          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else if (this.sessionService.currentUserValue?.agenceId) {    
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agenceUser: AgenceModel) => {
        if (agenceUser.adresse && agenceUser.codePostal && agenceUser.ville) {
          let src = agenceUser.adresse + ', ' + agenceUser.codePostal + ' ' + agenceUser.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else {
      window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
    }
  }

  getTime() {
    if (!this.adresse?.adresse || !this.adresse.codePostal || !this.adresse.ville) {
      this.timeTravel = "Adresse d'intervention incomplète ou incorrecte";
    } else if (this.realisateur) {
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            this.distanceTravel = results.routes[0].legs[0].distance.text;
            this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Adresse de l'agence du réalisateur incomplète ou incorrecte"; }
      });
    }  else if (this.sessionService.currentUserValue?.agenceId) {
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Erreur : Adresse de votre agence est incomplète ou incorrecte"; }
      });
    } else { this.timeTravel = "Impossible de calculer l'itinéraire"; }
  }
}