import { Component } from '@angular/core';

@Component({
  selector: 'app-four-oh-four',
  templateUrl: './four-oh-four.component.html'
})
export class FourOhFourComponent {



  

}
