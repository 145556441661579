import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { DescriptionActiviteService } from '../../../services/description-activite.service';
import { DocumentService } from '../../../services/document.service';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { ModalAddDocumentComponent } from '../../document/modal-add-document/modal-add-document.component';
import { ModalShowDocumentComponent } from '../../document/modal-show-document/modal-show-document.component';
import { ActiviteComponentService } from '../activite-component.service';
import { DescriptionActiviteModel } from '../../../shared/models/description-activite.model';

@Component({
  selector: 'app-record-activite',
  templateUrl: './record-activite.component.html',
  styleUrls: ['./record-activite.component.scss']
})
export class RecordActiviteComponent implements OnInit {

  idAgence: number;
  idActivite: number;
  libelle: string;
  form: UntypedFormGroup;
  result: boolean;

  constructor(
    public dialog: MatDialog,
    private activiteComponentService: ActiviteComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: IntercomToastrService,
    private descriptionService: DescriptionActiviteService,
    private documentService: DocumentService
  ) 
  { 
    const state = this.router.getCurrentNavigation().extras.state;
    if(state) {
      this.idActivite = state.idActivite;
      this.idAgence = state.idAgence;
      this.libelle = state.libelle;
    } else {
      this.router.navigateByUrl('/parametres-agence');
    }
    
  }

  ngOnInit(): void {
    this.descriptionService.getFromActiviteAgence(this.idAgence, this.idActivite).subscribe(res => {
      this.form = this.activiteComponentService.getCreateDescriptionActiviteFormGroup(res);
    });
  }

  addImage() {
    var id = this.form.value.imageId;
    if(id) {
      this.documentService.get(id).subscribe(res => {
        const dialogRef = this.dialog.open(ModalShowDocumentComponent, {disableClose: true,
          data: {
            type: "Activite",
            typeId: this.form.get("id").value, // modify
            document: res,
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // if res -> delete doc
          if(res) {
            this.form.get("imageId").setValue(null);
          }
        });
      });
    } else {
      const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
        data: {
          type: "Activite",
          typeId: this.form.get("id").value,
          document: new DocumentModel(),
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if(res){
          this.form.get("imageId").setValue(res);
          var model = this.form.value;
          this.descriptionService.put(model).subscribe(res => {
            this.toastr.success('Les modifications ont bien été prit en compte');
          });
        }
      });
    }
  }

  submit() {
    var model = this.form.value;
    if (this.form.valid) {
      this.descriptionService.put(model).subscribe((res: DescriptionActiviteModel) => {
        this.form = this.activiteComponentService.getEditDescriptionActiviteFormGroup(res);
        this.toastr.success('Les modifications ont bien été prit en compte');
      });
    } else {
      this.toastr.warning('Des erreurs empèches l\'enregistrement des informations');
    }
  }

}
