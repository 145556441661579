import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SiteGeoService } from '../site-geo.service';
import { UserService } from '../user/user.service';
import { AgenceService } from '../rsa/agence.service';
import { TierService } from './tier.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { EntrepriseModel } from '../../shared/models/tier/entreprise.model';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { VerifyFields } from '../../shared/verifyFields';
import { EntrepriseDTO } from '../../shared/dtos/entrepriseDTO';
import { DocumentService } from '../document.service';

@Injectable()

export class EntrepriseService extends TierService {

  observer = new BehaviorSubject<EntrepriseModel>(null);
  public subscriber = this.observer.asObservable();

  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              siteGeoService: SiteGeoService,
              agenceService: AgenceService,
              userService: UserService,
              protected IntercomToastrService: IntercomToastrService,
              protected documentService: DocumentService) {
                super(http, apiService, sessionService, siteGeoService, agenceService, userService, IntercomToastrService, documentService);
                this.serviceUrl = apiService.ROUTEENTREPRISE;
  }

  get(objet: number): Observable<EntrepriseModel> {
    return <Observable<EntrepriseModel>> super.get(objet);
  }

  getAll(): Observable<EntrepriseModel[]> {
    return <Observable<EntrepriseModel[]>> super.getAll();
  }

  beforeSave(objet: EntrepriseModel): boolean {
    let success = true;
    if (!VerifyFields.verifyStringExist(objet.raisonSociale)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir une raison sociale.');
    }
    // if (!VerifyFields.verifyStringExist(objet.codePostal)) {
    //   success = false;
    //   this.IntercomToastrService.warning('Veuillez saisir un code postal.');
    // }
    if (!VerifyFields.verifyStringExist(objet.email) && !VerifyFields.verifyStringExist(objet.telephone)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir un email ou un numéro de téléphone.');
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    return success;
  }

  GetFromObjetWithPagination(objet: SimpleQuery<EntrepriseDTO>): Observable<SimpleQueryResponse<EntrepriseModel>> {
    return <Observable<SimpleQueryResponse<EntrepriseModel>>> super.GetFromObjetWithPagination(objet).pipe(tap((simpleQuery: SimpleQueryResponse<EntrepriseModel>) => {
      for (const tier of simpleQuery.objets) {
        
      }
    }));
  }
}