import { BasicMock } from '../basic-mock/basic.mock';
import { AgenceModel } from '../../models/rsa/agence.model';
import { Injectable } from "@angular/core";

@Injectable()
export class AgenceMock extends BasicMock  {
  constructor() {
    super();
    (<AgenceModel[]> this.objets) = [];
  }
}
