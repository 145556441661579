import { Injectable } from "@angular/core";


@Injectable()
export class SiteGeoDTO {
    public Libelle : string;
    public CodePostal : string;
    public Ville : string;
    public Telephone : string;
    public Adresse : string;
    public Commentaire : string;
    public Email : string;
    public Gps: string;
    public TierId: string;
    public TierLibelle: string;
}