import { BasicMock } from '../basic-mock/basic.mock';
import { SocieteModel } from '../../models/rsa/societe.model';
import { Injectable } from "@angular/core";

@Injectable()
export class SocieteMock extends BasicMock  {
  constructor() {
    super();
    (<SocieteModel[]> this.objets) = [];
  }
}
