<div class="col-md-12 header-menuP">
  <h2 [@fadeInOut]="titlePage + ' ' + titleExpension">{{titlePage}}  {{titleExpension}}</h2>
  <div class="header-menuP">
    <button 
      mat-raised-button color="success" class="dashboard-button header-button-menuP"
      [matBadge]="demandes.length"  matBadgeColor="accent" (click)="onShowDemandes()">
      Demandes
    </button>
    <button 
      mat-stroked-button color="info-green" class="dashboard-button header-button-menuP"
      [matBadge]="actions.length"  matBadgeColor="accent" (click)="onShowActions()">
      Tâches
    </button>
  </div>
  <button mat-flat-button class="header-button-filter-member-menuP"[matMenuTriggerFor]="menu" color="default"><mat-icon color="primary" id="icon">filter_list</mat-icon>Membres de votre équipe</button>
</div>

<mat-menu #menu="matMenu" [overlapTrigger]="false" class="overlay-menu">
  <div class="text-center">
    <div class="row user" *ngFor="let selectedUser of selectedUsers; let i = index">
      <div class="min_width" [ngStyle]="setMyStyles(i)">
        <div class="col-sm-9">
          <strong class="text-center" >{{ selectedUser.nom }} {{ selectedUser.prenom }}</strong>
        </div>
        <div class="col-sm-3">
          <button class="btn-remove" (click)="cancelSuivis(selectedUser); $event.stopPropagation();"><mat-icon id="icon">delete</mat-icon></button> 
        </div>
      </div>
    </div>
  </div>
  <div  class="col-sm-12 center">
    <app-autocomplete-users (click)="$event.stopPropagation();" libelle="Ajouter un calendrier" [users]="suivisUser" [selectedusers]="selectedUsers" (onUpdated)="suivisRealisateurChange($event)"> </app-autocomplete-users>
  </div>
</mat-menu>
<div class="calendar col-sm-12">
  <full-calendar
  *ngIf="calendarOptions"
  #fullcalendar
  [options]="calendarOptions">
  </full-calendar>
</div>
