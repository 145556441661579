import { DemandeModel } from "./models/demande.model";
import { StatusList } from "./statusList";

export class VerifyFields {
    public static regVille = RegExp('^[A-ZÀÂÄÇÉÈÊËÌÎÏÒÔÖÙÛÜŸ]|[a-zàâäçéèêëìîïòôöùûüÿ]+(?:(?:\\s+|-)[A-ZÀÂÄÇÉÈÊËÌÎÏÒÔÖÙÛÜŸ]|[a-zàâäçéèêëìîïòôöùûüÿ]+)*$');
    public static regCodePostal = RegExp('^(?:[0-8]\\d|9[0-8])\\d{3}$');
    public static regUrl = RegExp('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?');
    public static regName = RegExp('^[\D]*$', 'g');
    public static regEmail = RegExp("^([äöüéèa-zA-Z0-9!#\$%*/?\|\^\{\}`~&'+\-=_])+(\.([äöüéèa-zA-Z0-9!#\$%*/?\|\^\{\}`~&'+\-=_])+)*@[-!#-'*+\/-9=?^-~]{2,}(?:\.[-!#-'*+\/-9=?^-~]{2,})+$", 'i');

    public static verifyStringExist(string: string): boolean {
        return !(!string  || !string.toString().trim());
    }

    public static verifyEmail(string): boolean {
        if (this.verifyStringExist(string)) {
            let regTest = this.regEmail.test(string);
            let commaTest = !string.includes(",");
            return (regTest && commaTest);
        } else {
            return true;
        }
    }

    public static verifyName(string): boolean {
        if (this.verifyStringExist(string)) {
            return true;
        } else {
            return true;
        }
    }
    public static verifyUrl(string, type): boolean {
        if (this.verifyStringExist(string)) {
            return type ===  'Tiers' || type === 'Demande' || type === 'Protocole'? this.regUrl.test(string) : string;
        } else {
            return true;
        }
    }
    public static verifyCodePostal(string): boolean {
            if (this.verifyStringExist(string)) {
                return this.regCodePostal.test(string);
            } else {
                return true;
            }

    }

    public static verifyVille(string): boolean {
        if (this.verifyStringExist(string)) {
            return this.regVille.test(string);
        } else {
            return true;
        }
    }


    public static verifyDate(date: Date ): boolean {
        if (date) {
            return true;
        }
        return false;
    }

    public static verifyId(id: number): boolean {
        if (id && id > 0) {
            return true;
        }
        return false;
    }

    public static verifyStatut(demande: DemandeModel): boolean {
        if (demande.statut === StatusList.CLOS) {
          if (demande.actions.length > 0) {
            for (const action of demande.actions) {
              if (action.statut !== StatusList.CLOS) {
                return false;
              }
            }
          }
        }
        return true;
      }
}
