import { DevisAgenceBasicModel } from './devis-agence-basic.model';

export class TablePrestationClientModel extends DevisAgenceBasicModel {
    public idDevis: number;
    public titre: string;
    public ht: number = 0;
    public ttc: number = 0;
    public tva: number = 0;
    public condRegl: string;
    public validite: Date;
    public lignes: LigneClient[] = [];
}

export class LigneClient {
    libelle: string;
    qte: number;
    prix: number
    unite: string;
  }


  
 