<mat-form-field class="full-width">
  <mat-label>{{ libelle }}</mat-label>
  <input
    matInput
    placeholder=""
    type="tel"
    [maxlength]="max"
    [minlength]="min"
    [required]="required"
    [formControl]="phoneControl"      
    name="intercomPhone"
  />
  <mat-error *ngIf="phoneControl.hasError('maxlength') || phoneControl.hasError('minlength') || phoneControl.hasError('pattern')">
    Le format est incorrect
  </mat-error>
</mat-form-field>