
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGrigPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router, NavigationEnd } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ActionService } from '../../services/action.service';
import { DemandeService } from '../../services/demande.service';
import { UserService } from '../../services/user/user.service';
import { ActionModel } from '../../shared/models/action.model';
import { DemandeModel } from '../../shared/models/demande.model';
import { UserModel } from '../../shared/models/users/user.model';
import { ModalAjoutActionComponent } from '../action/modal-ajout-action.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, EventInput, EventSourceInput } from '@fullcalendar/core';


export class SavedCalendar {
  dateStart: Date;
  type: string;
  actionSelected: boolean;
}

export const fadeInOutTimeout = 250;
export const fadeInOut = trigger('fadeInOut', [
  transition('void => *', [style({ opacity: '0', transform: 'translateX(-10%)' }), animate(fadeInOutTimeout)]),
  transition('* => void', [animate(fadeInOutTimeout, style({ opacity: '0' }))]),
  transition('* => *', [
style({ opacity: '0', transform: 'translateX(-10%)' }),
animate(fadeInOutTimeout, style({ opacity: '1', transform: 'translateX(0%)' })),
  ]),
]);

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  animations: [ fadeInOut ]
})
export class AgendaComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;

  calendarOptions: any;
  actions: ActionModel[] = [];
  actionsCopy = [];
  demandes: DemandeModel[] = [];
  demandesCopy = [];
  fullcalendarApi: Calendar;
  navigationSubscription;
  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  calendarEventSource: EventSourceInput;
  calendarEvents: EventInput[] = [];
  actionSelected = false;
  users: UserModel[];
  suivisUser: UserModel[];
  selectedUsers: UserModel[];
  localeCultures = allLocales;
  titlePage = 'Agenda';
  titleExpension = '';
  private calendarSaved: SavedCalendar;

  constructor(
    private actionService: ActionService,
    private demandeService: DemandeService,
    private modalsService: ModalsService,
    private sessionService: SessionService,
    private userService: UserService,
    private toastr: IntercomToastrService,
    injector: Injector,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.modalsService = injector.get('ModalsService');
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.userService.getAll().subscribe(async (users: UserModel[]) => {
          this.users = this.onOrderUsers(Object.assign([], users));
          const user = this.sessionService.currentUserValue;
          this.suivisUser = this.onOrderUsers(Object.assign([], users));
          this.selectedUsers = JSON.parse(localStorage.getItem('SuivisReal'));

          if (this.selectedUsers === null || this.selectedUsers === undefined) {
            const index = this.suivisUser.findIndex((o: UserModel) => o.id === user.id);
            if (index > -1) {
              this.suivisUser.splice(index, 1);
            }
            this.selectedUsers = this.onOrderUsers([Object.assign({}, user)]);
          } else {
            for (let i = 0; i < this.selectedUsers.length; i++) {
              const index = this.suivisUser.findIndex((o: UserModel) => o.id === this.selectedUsers[i].id);
              if (index > -1) {
                this.suivisUser.splice(index, 1);
              }
            }
          }
          this.calendarSaved = JSON.parse(localStorage.getItem('calendar'));

          if (this.fullcalendarApi && this.calendarSaved) {
            this.fullcalendarApi.changeView(this.calendarSaved.type);
            this.actionSelected = this.calendarSaved.actionSelected;
          }
          this.titleExpension = this.actionSelected ? '/ tâches' : '/ demandes';
          if (this.actionSelected) {
            this.onLoadAction();
          } else {
            this.onLoadDemande();
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.calendarOptions = {
      editable: this.actionSelected,
      buttonText: {
        today:    'Aujourd\'hui',
        month:    'mois',
        week:     'semaine',
        day:      'jour',
        list:     'Agenda semaine',
        allDayText:   'journée'
      },
      eventSources: [{
        events: this.calendarEvents
      }],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'listWeek,dayGridMonth,timeGridWeek,timeGridDay'
      },
      locales: this.localeCultures,
      locale: 'fr',
      initialView: 'listWeek',
      weekends: this.calendarWeekends,
      aspectRatio: '2.1',
      handleWindowResize: true,
      allDayText: '',
      dayMaxEventRows: true,
      eventDidMount: this.saveCalendar.bind(this),
      eventWillUnmount: this.saveCalendar.bind(this),
      firstDay: 1,
      eventDisplay: 'block',
      noEventsContent: 'Aucun Evenement',
      dateClick: this.handleDateClick.bind(this),
      eventClick: this.eventClick.bind(this),
      eventDrop: this.eventdrop.bind(this),
      eventResize: this.eventresize.bind(this),
      plugins:  [dayGridPlugin, timeGrigPlugin, listPlugin, interactionPlugin]
    };
  }

  ngAfterViewInit(): void {
    this.fullcalendarApi = this.fullcalendar.getApi();
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
       this.navigationSubscription.unsubscribe();
    }
  }

  saveCalendar() {
    this.calendarSaved = new SavedCalendar();
    this.calendarSaved.actionSelected = this.actionSelected;
    this.calendarSaved.type = this.fullcalendarApi.view.type;
    localStorage.setItem('calendar', JSON.stringify(this.calendarSaved));
  }

  handleDateClick(arg) {
    if (this.actionSelected) {
      const dialogRef = this.dialog.open(ModalAjoutActionComponent, {
        disableClose: true,
        width: '700px',
        data: {
          date: arg.date,
          realisateurId: this.sessionService.currentUserValue.id,
          action: new ActionModel()
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result[0]) {
          this.onLoadAction();
        }
      });
    }
  }

  eventClick(arg) {
    let actionEvent;
    let demandeEvent;
    if (this.actionSelected) {
      actionEvent = this.actions.find((action: ActionModel) => action.id === +arg.event.id);
    } else {
      demandeEvent = this.demandes.find((demande: DemandeModel) => demande.id === +arg.event.id);
    }
    if (actionEvent !== undefined) {
      this.modalsService.openAction(actionEvent).subscribe(result => {
        if (result) {
          this.actionService.get(actionEvent.id).subscribe((dem: ActionModel) => {
            actionEvent = dem;
          });
          this.onLoadAction();
        }
      });
    } else if (demandeEvent !== undefined) {
      this.modalsService.openDemande(demandeEvent).subscribe(result => {
        if (result) {
          this.demandeService.get(demandeEvent.id).subscribe((dem: DemandeModel) => {
            demandeEvent = dem;
          });
          this.onLoadDemande();
        }
      });
    }
  }

  eventdrop(dropEvent) {
    const index = this.calendarEvents.findIndex((event: EventInput) => {
      return +event.id === +dropEvent.oldEvent.id;
    });
    this.calendarEvents[index] = dropEvent.event;
    this.actions[index].dateRealisation = dropEvent.event.start;
    if (!dropEvent.event.allDay) {
      this.actions[index].dateFin = dropEvent.event.end;
    } else {
      this.actions[index].dateFin = dropEvent.event.start;
    }
    this.actionService.put(this.actions[index]).subscribe();
    this.actionService.setHeureDuree(this.actions[index]);
  }

  eventresize(resizeEvent) {
    const index = this.calendarEvents.findIndex((event: EventInput) => +event.id === +resizeEvent.oldEvent.id);
    this.calendarEvents[index] = resizeEvent.event;
    this.actions[index].dateRealisation = resizeEvent.event.start;
    if (!resizeEvent.event.allDay) {
      this.actions[index].dateFin = resizeEvent.event.end;
    } else {
      this.actions[index].dateFin = resizeEvent.event.start;
    }
    this.actionService.put(this.actions[index]).subscribe();
    this.actionService.setHeureDuree(this.actions[index]);
  }

  onCreateActionTitle(action: ActionModel): string {
    if (action.tier !== null && action.tier !== undefined) {
      return action.objetAction + ' | ' + action.tier.libelle;
    }
    return action.objetAction;
  }

  onCreateDemandeTitle(demande: DemandeModel): string {
    if (demande.tier !== null && demande.tier !== undefined) {
      return demande.objet + ' | ' + demande.tier.libelle;
    }
    return demande.objet;
  }

  onCreateActionsEvents(usersActions: ActionModel[][]): EventInput[] {
    const eventTab = [];
    this.actions = [];
    let i = 0;
    for (const userActions of usersActions) {
      for (const action of userActions) {
        this.actions.push(action);
        if (action.dateFin !== null) {
          eventTab.push({id: action.id.toString(), start: action.dateRealisation, end: action.dateFin,
            allDay: false, title: this.onCreateActionTitle(action), backgroundColor: this.chooseBackgroundColor(i), editable: true });
        } else if (action.dateRealisation.getHours() !== 0 || action.dateRealisation.getMinutes() !== 0) {
          eventTab.push({id: action.id.toString(), start: action.dateRealisation, allDay: false,
            title: this.onCreateActionTitle(action), backgroundColor: this.chooseBackgroundColor(i), editable: true });
        } else {
          eventTab.push({id: action.id.toString(), start: action.dateRealisation, allDay: true,
            title: this.onCreateActionTitle(action), backgroundColor: this.chooseBackgroundColor(i), editable: true });
        }
      } i++;
    }
    this.actionsCopy = this.actions;
    return eventTab;
  }

  onCreateDemandesEvents(usersDemandes: DemandeModel[][]): EventInput[] {
    const eventTab = [];
    this.demandes = [];
    let i = 0;
    for (const userDemandes of usersDemandes) {
      for (const demande of userDemandes) {
        this.demandes.push(demande);
        eventTab.push({
          id: demande.id.toString(),
          start: demande.dateIntervention,
          allDay: true,
          title: this.onCreateDemandeTitle(demande),
          durationEditable: false,
          editable: false,
          display: 'block',
          backgroundColor: this.chooseBackgroundColor(i)});
      }
      i++;
    }
    this.demandesCopy = this.demandes;
    return eventTab;
  }

  onLoadAction() {
    const ids = [];
    for (let i = 0; i < this.selectedUsers.length; i++) {
      ids.push(this.selectedUsers[i].id);
    }

    this.actionService.getfromUsersActive(this.selectedUsers).subscribe((usersActions: ActionModel[][]) => {
      this.calendarEvents = this.onCreateActionsEvents(usersActions);
      this.fullcalendarApi.removeAllEvents();
      this.fullcalendarApi.addEventSource(this.calendarEvents);
    });
    this.demandeService.getFromUsers(ids).subscribe((demandes: DemandeModel[]) => {
      this.demandes = demandes;
      this.demandesCopy = demandes;
    });
  }

  onLoadDemande() {
    const ids = [];
    for (let i = 0; i < this.selectedUsers.length; i++) {
      ids.push(this.selectedUsers[i].id);
    }
    this.demandeService.getFromUsersActive(this.selectedUsers).subscribe((usersDemandes: DemandeModel[][]) => {
      this.calendarEvents = this.onCreateDemandesEvents(usersDemandes);
      this.calendarEventSource = this.calendarEvents;
      this.fullcalendarApi.removeAllEvents();
      this.fullcalendarApi.addEventSource(this.calendarEvents);
    });

    this.actionService.getFromUsers(ids).subscribe((actions: ActionModel[]) => {
      this.actions = actions;
      this.actionsCopy = actions;
    });
  }

  onShowDemandes() {
    this.titleExpension = ' / demandes';
    this.actionSelected = false;
    this.onLoadDemande();
  }

  onShowActions() {
    this.titleExpension = ' / tâches';
    this.actionSelected = true;
    this.onLoadAction();
  }

  setMyStyles(index: number) {
    const styles = {
      'background-color': this.chooseBackgroundColor(index),
      'border-radius': '5px',
      'color': '#fff',
      'height': 'unset',
      'box-shadow': '3px 3px 3px #000000'
    };
    return styles;
  }

  onOrderUsers(users: UserModel[]): UserModel[] {
    users = users.sort((a, b) => {
      if (a.nom) {
        return a.nom.localeCompare(b.nom);
      } else {
        return -1;
      }
    });
    return users;
  }

  cancelSuivis(user: UserModel) {
    if (this.selectedUsers.length > 1) {
      this.suivisUser.push(Object.assign({}, user));
      this.suivisUser = Object.assign([], this.onOrderUsers(this.suivisUser));
      const index = this.selectedUsers.findIndex((o: UserModel) => o.id === user.id);
      if (index > -1) {
        this.selectedUsers.splice(index, 1);
      }
      if (this.actionSelected) {
        this.onLoadAction();
      } else {
        this.onLoadDemande();
      }
    } else {
      this.toastr.warning('Vous devez avoir au minimum un utilisateur ',
      'Impossible de supprimer la ligne');
    }
    localStorage.setItem('SuivisReal', JSON.stringify(this.selectedUsers));
  }

  suivisRealisateurChange(id: number) {
    const selecteduser = this.users.find(u => u.id === id);
    this.selectedUsers.push(Object.assign({}, selecteduser));
    const index = this.suivisUser.findIndex((o: UserModel) => o.id === selecteduser.id);
    if (index > -1) {
      this.suivisUser.splice(index, 1);
    }
    if (this.actionSelected) {
      this.onLoadAction();
    } else {
      this.onLoadDemande();
    }
    localStorage.setItem('SuivisReal', JSON.stringify(this.selectedUsers));
  }

  chooseBackgroundColor(actionType: number) {
    switch (actionType) {
      case 0: {
        return '#039be5';
      }
      case 1: {
        return '#33b679';
      }
      case 2: {
        return '#B88909';
      }
      case 3: {
        return '#f6bf26';
      }
      case 4: {
        return '#d50000';
      }
      case 5: {
        return '#795548';
      }
      case 6: {
        return '#616161';
      }
      case 7: {
        return '#f4511e';
      }
      case 8: {
        return '#e67c73';
      }
      case 9: {
        return '#3f51b5';
      }
      default: {
        return '#616161';
      }
    }
  }
}
