import { SyndicModel } from '../../shared/models/tier/syndic.model';
import { ParticulierModel } from '../../shared/models/tier/particulier.model';
import { MairieModel } from '../../shared/models/tier/mairie.model';
import { HlmDTO } from '../../shared/dtos/hlmDTO';
import { Component, OnInit, Inject } from '@angular/core';
import { MenuComponent } from './menu.component';
import { ActionService } from '../../services/action.service';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SessionService } from 'src/app/modules/shared/session.service';
import { EntrepriseService } from '../../services/tier/entreprise.service';
import { MairieService } from '../../services/tier/mairie.service';
import { ParticulierService } from '../../services/tier/particulier.service';
import { ModalsTierInfos } from '../../shared/modals-menu-info';
import { ActionModel } from '../../shared/models/action.model';
import { EntrepriseModel } from '../../shared/models/tier/entreprise.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { UserService } from '../../services/user/user.service';
import { UserModel } from '../../shared/models/users/user.model';


export interface DialogData {
  info: ModalsTierInfos;
}

@Component({
  selector: 'app-modal-prise-note',
  templateUrl: './modal-prise-note.component.html'
})
export class ModalPriseNoteComponent implements OnInit {
  mairieDataSource: TierModel[] = [];
  simpleTierDataSource: TierModel[] = [];
  particulierDataSource: TierModel[] = [];
  entrepriseDataSource: TierModel[] = [];
  syndicDataSource: TierModel[] = [];
  hlmDataSource: TierModel[] = [];
  entreprise = new EntrepriseModel();
  syndic = new SyndicModel();
  tier = new TierModel();
  hlm = new HlmDTO();
  mairie = new MairieModel();
  particulier = new ParticulierModel();
  telNote = '';
  strNote = '';
  users: UserModel[];
  user: UserModel;
  realisateur: UserModel;
  commercialId: number;
  previousModalInfo: ModalsTierInfos = null;
  panelOpenState = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private particulierService: ParticulierService,
    private actionService: ActionService,
    private mairieService: MairieService,
    private sessionService: SessionService,
    private toastr: IntercomToastrService,
    private entrepriseService: EntrepriseService,
    private userService: UserService) {

    if (data !== null && data.info != null &&  data.info.modalFields !== null) {
      this.onTierChange(data.info);
    }
  }

  onTierOpen(tier: TierModel) {
    this.router.navigate(['/fiche-contact', tier.id]);
    this.onModalConfirm();
  }

  onTierChange(tier: ModalsTierInfos) {
    this.tier = Object.assign({}, tier.modalFields);
    this.previousModalInfo = tier;
  }

  ngOnInit() {
   this.dialogRef.updateSize('30%', '50%');
   this.userService.getAll().subscribe((usrs: UserModel[]) => {
    this.user = usrs.find(usr => usr.id === this.sessionService.currentUserValue.id);
    this.users = usrs;
    // this.commercialId = this.sessionService.currentUserValue.id;
  });
  }

  onModalCancel(): void {
    this.data.info = this.previousModalInfo;
    this.dialogRef.close(this.data);
  }

  onModalConfirm(): void {
    this.data.info = this.previousModalInfo;
    this.dialogRef.close(this.data);
  }

  onUpdateRealisateurId(id) {
    this.commercialId = id;
    this.realisateur = this.users.find(usr => usr.id === id);
  }


  onNoteAdd(): void {
    if (this.strNote !== '' && this.strNote !== undefined) {
      let note: ActionModel;
      note = new ActionModel();
      note.dateCreation = new Date();
      note.dateCreation.setHours(0, 0, 0, 0);
      note.dateRealisation = new Date();
      note.dateRealisation.setHours(0, 0, 0, 0);
      note.dateFin = null;
      note.createurId = this.sessionService.currentUserValue.id;
      note.objetAction = 'Note ' + this.telNote + ' ' + this.strNote.substr(0, 50).replace(/(\r\n|\n|\r)/gm, ' ');
      note.statut = 'En cours';
      note.realisateurId = this.commercialId;
      note.agenceId = this.realisateur.agenceId;
      note.tierId = undefined;
      note.typeActionId = 6;
      note.commentaire = this.telNote !== undefined && this.telNote !== '' ?  'Téléphone : ' + this.telNote + '\n' : '';
      note.commentaire = 'Commentaire : ' +  this.strNote;
      this.actionService.post(note).subscribe((action: ActionModel) => {
        this.toastr.success('', 'Création de la note réussie');
        this.dialogRef.close(true);
        this.panelOpenState = false;
        if (this.router.url === '/') {
          this.router.navigate(['']);
        }
      });
    } else {
      // entrer le numéro ainsi qu\'
      this.toastr.warning('Veuillez remplir le champ note.',
        'Impossible de créer la note');
    }
  }
}
