import { Component, Input, EventEmitter, Output, SimpleChanges, ViewChild } from '@angular/core';
import {  UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import { CityData } from 'src/app/modules/core/shared/models/city-data.model';
import { CityDataService } from 'src/app/modules/core/services/cityData.service';

@Component({
  selector: 'app-autocomplete-code-postal',
  templateUrl: './autocomplete-code-postal.component.html'
})
export class AutocompleteCodePostalComponent {

  @Input() codePostalInput: string;
  @Input() required = false;
  @Input() libelle: string;
  @Input() disable = false;
  @Output() onUpdated = new EventEmitter<CityData>();
  cities: CityData[];
  @Input() selectedCity: CityData = new CityData();
  @ViewChild("codePostal") codePostal: any;
  citiesListString: string[] = [];
  obsCitiesListString: Subject<string[]> = new Subject<string[]>;
  filteredCities: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  myControl = new UntypedFormControl;


  constructor(private cityDataService: CityDataService) { }

  ngOnInit() {
    if (this.selectedCity && !this.selectedCity.codesPostaux) {
      this.selectedCity.codesPostaux = [];
    }
    this.myControl.valueChanges.subscribe(codeInput => {
      this.cityDataService.SearchCityWithCode(codeInput).subscribe(cityList => {
        this.cities = cityList;
        if (this.cities !== undefined && this.cities !== null) {
            this.fillCitiesListString(this.cities);
        }
        this.filteredCities.next(this.myControl.value ? this._filter(this.myControl.value) : this.citiesListString.slice());

        combineLatest(
          [this.myControl.valueChanges,
            this.obsCitiesListString]
        ).subscribe(([cityCodeAndName, cities]) => {
          this.filteredCities.next(cityCodeAndName ? this._filter(cityCodeAndName) : cities.slice());
        });
        this.obsCitiesListString.next(this.citiesListString);
      });
    });
  }

  fillCitiesListString(cityList: CityData[]) {
    this.citiesListString = [];
    for (let i = 0; i < cityList.length; i++) {
      for (let j = 0; j < cityList[i].codesPostaux.length; j++) {
        this.citiesListString.push(cityList[i].codesPostaux[j] + ' ' + cityList[i].nom);
      }  
    }
    this.citiesListString.sort();
    this.obsCitiesListString.next(this.citiesListString);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCity && changes.selectedCity.currentValue) {
      this.myControl.setValue(changes.selectedCity.currentValue.codesPostaux[0]);
      this.codePostalInput = changes.selectedCity.currentValue.codesPostaux[0];
    } else if (changes.selectedCity) {
      this.codePostalInput = null;
    }
  }

  /* Add with enterKey */
  add(value: string): void {
    if (!value || value === "") {
      var selectedCodeData = Object.assign({}, this.selectedCity);
      selectedCodeData.codesPostaux = Object.assign({}, this.selectedCity.codesPostaux);
      selectedCodeData.codesPostaux = [null];
      this.myControl.setValue(null);
      this.onUpdated.emit(selectedCodeData);
    } else {
      var currentCities = this.cities.filter(city => city.codesPostaux.find(code => code === value))
      if (currentCities.length === 1) {
        this.selectedCity = currentCities[0];
      } 
      var selectedCodeData = Object.assign({}, this.selectedCity);
      selectedCodeData.codesPostaux = Object.assign({}, this.selectedCity.codesPostaux);
      selectedCodeData.codesPostaux = [value];
      this.myControl.setValue(value);
      this.onUpdated.emit(selectedCodeData);
    }
  }

 /* selected event*/
 cityChange(event: MatAutocompleteSelectedEvent) {
  const value = event.option.value;
  var cityNameInput = value.split(" ")[1];
  var cityCodePostInput = value.split(" ")[0];
  this.selectedCity = this.cities.find(city => city.nom === cityNameInput);
  var selectedCodeData = Object.assign({}, this.selectedCity);
  selectedCodeData.codesPostaux = [cityCodePostInput];
  this.myControl.setValue(cityCodePostInput);
  this.onUpdated.emit(selectedCodeData);
}

  private _filter(value: string): string[] {
    const filterValue = value;
    return this.citiesListString.filter(city => city.startsWith(filterValue) === true);
  }

  displayOnlyCode(option: any): string {
    if (option) {
      return (option.substr(0, 5));
    }
    else {
      return ("");
    }
  }

}
