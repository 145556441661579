<div>
  <div class="test" style="text-align: center">
    <div class="col-sm-12">
      <h3>Génération réussi</h3>
    </div>
    <div class="col-sm-12">
      <h5>Il vous faut maintenant envoyer le mail généré</h5>
    </div>
    <div class="col-sm-12">
      <mat-divider></mat-divider>
    </div>    
    <div class="col-sm-12" style="margin-top: 40px;">
      <button mat-flat-button color="success" (click)="onModalConfirm()">Ouvrir le mail pour envoi</button>
    </div>
  </div>
</div>
