import { TierDTO } from './tierDTO';
import { Injectable } from "@angular/core";
import { ParticulierModel } from '../models/tier/particulier.model';

@Injectable()
export class ParticulierDTO extends TierDTO {
  public proprietaire: boolean;
  public civiliteId: number;
  public nom: string;
  public prenom: string;
  public typeTier = 'Particulier';

}
