import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DevisShared } from 'src/app/modules/shared/DevisShared';
import { DevisDTO } from '../../DTOs/devisDTO';
import { DevisService } from '../../services/devis.service';
import { ModalSpinnerComponent } from 'src/app/modules/shared/components/modals/modal-spinner/modal-spinner.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-devis',
  templateUrl: './devis.component.html'
})
export class DevisComponent implements OnChanges {

  @Input() devisShared: DevisShared;
  @Output() devisExist = new EventEmitter<DevisDTO>();
  @Output() closeDemande = new EventEmitter<boolean>();
  agenceId: number;
  exist = false;
  devis: DevisDTO;
  first = true;

  constructor(
    public dialog: MatDialog,
    private devisService: DevisService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.devisShared && this.devisShared.demande.id && this.devisShared.demande.agenceId && this.first) {
      this.first = false;
      const dialogRef = this.dialog.open(ModalSpinnerComponent);
      this.devisService.getFromDemande(this.devisShared.demande.id, this.devisShared.demande.agenceId).subscribe(devis => {
        if (devis) {
          this.devis = devis;
          this.exist = true;
          this.devisExist.emit(devis);
        } else {
          this.exist = false;
        }
        dialogRef.close();
      }, 
      (error) => {
        
      });
    }
  }

  devisChange($event) {
    this.devis = $event;
    this.devisExist.emit(this.devis);
  }
}
