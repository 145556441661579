import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from './../../site-geographique/site-geographique.component';
import { Sort } from '@angular/material/sort';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TierModel } from '../../../shared/models/tier/tier.model';
import { Component, OnInit, SimpleChanges, Inject } from '@angular/core';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { TierService } from '../../../services/tier/tier.service';
import { TrieTabService } from '../../../services/trie-tab.service';
import { MenuComponent } from '../../menu/menu.component';

@Component({
  selector: 'app-modal-liste-tiers',
  templateUrl: './modal-liste-tiers.component.html'
})
export class ModalListeTiersComponent implements OnInit {

  datasource: TierModel[];
  matDataSource: MatTableDataSource<TierModel>;
  pageEvent: PageEvent;
  sortState: Sort = {active: 'libelle', direction: 'asc'};
  length: number;
  pageIndex: number;
  pageSize = 10;
  displayedColumns: string[] = ['typeTier', 'libelle', 'ville', 'agenceP', 'commercialP'];

  constructor(public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<MenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tierService: TierService,
    private comp: TrieTabService,
    private modalsService: ModalsService,
    private serviceTier: TierService) {}


  ngOnInit() {
    this.datasource = this.tierService.tiers;
    const data = this.datasource.slice();
    this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
    this.length = this.datasource.length;
    this.pageIndex = 0;
    this.trieTab(this.sortState);
  }


  ngOnChanges (changes: SimpleChanges): void {
    const data = changes.datasource.currentValue.slice();
    this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
    this.length = this.datasource.length;
    this.pageIndex = 0;
    this.trieTab(this.sortState);
  }

  changePage(event?: PageEvent): PageEvent {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    const data = this.comp.loadPage(this.datasource, this.pageIndex, this.pageSize);
    this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
    return event;
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadPage();
  }

  loadPage() {
    this.matDataSource =  this.comp.sortData(this.sortState, this.datasource, this.matDataSource, this.pageIndex, this.pageSize);
    if (this.matDataSource === undefined) {
        const data = this.datasource.slice();
        this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
    }
  }

  selectTiers(tier: TierModel) {
    if (this.router.url === '/prise-appel') {
      this.tierService.tiers = [tier];
      this.dialogRef.close(this.data);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/prise-appel']);
    });
    } else {
      this.tierService.tiers = [tier];
      this.router.navigate(['/prise-appel'] );
      this.dialogRef.close(this.data);
    }

  }

  onModalCancel(): void {
    let tier: TierModel = new TierModel();
    tier.id = null;
    tier.telephone = this.serviceTier.tiers[0].telephone;
    this.serviceTier.tiers = [tier];
    this.dialogRef.close(this.data);
    if (this.router.url !== '/prise-appel') {
      this.router.navigate(['/prise-appel']);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/prise-appel']);    });
    }

  }
}
