<div [className]="displayForm ? 'col-md-7 no-padding' : 'col-md-12 no-padding'">
  <mat-card
    class="col-md-6 no-padding"
    class="example-card"
  >
    <div class="etiquetteDiv">
      <div style="text-align: center;">
        <h3 class="etiquetteH4">Critères de calcul</h3>
        <a mat-stroked-button color="success" class="row" mat-button (click)="AfficherFormulaire()">
          <mat-icon>add</mat-icon> Ajouter une liste de critères de calcul
        </a>
      </div>
        <!--Début Liste etiquettes-->
        <app-afficher-prestation-offre [listPrestations]="prestations" (selectedPrestation)="modify($event)"></app-afficher-prestation-offre>
        <!--Fin liste étiquettes-->
    </div>
  </mat-card>
</div>


<div *ngIf="displayForm && form" [className]="displayForm ? 'col-sm-5 no-padding' : ''">
  <mat-card class="example-card" style="margin-top: 0px">
    <!-- Call forms to add etiquettes in list etiquettes array -->
    <form [formGroup]="form">
      <div class="col-sm-12 no-padding" style="margin-bottom: 15px !important">
        <h5 class="titleSpacingLeft">Activité</h5>
        <div class="form-group col-sm-12">
          <input type="text" class="form-control" name="activite" [value]="libelleActivite" disabled/>
        </div>
        <h5 class="titleSpacingLeft">Critère de calcul</h5>
        <div class="form-group col-sm-9">
          <textarea  type="text" style="resize: vertical;" rows="5" class="form-control" name="libelle" placeholder="Description de la prestation" formControlName="libelle" required> </textarea>
        </div>
        <div class="form-group col-sm-2">
          <button mat-flat-button color="warn" class="etiquetteDeleteButton" (click)="removePrestation()">  <mat-icon>delete</mat-icon> </button>
        </div>
      </div>

      <div class="etiquetteDivClearBoth"></div>
      <mat-divider></mat-divider>

      <div class="col-sm-12" style="margin-top: 25px !important">
        <h5 class="titleSpacingLeft">Offres associées</h5>
      </div>

      <div formArrayName="offres">
        <div class="etiquettesAssoc"  *ngFor="let offre of getFormOffres().controls; let i = index" [formGroupName]="i">
          <div class="form-group col-sm-6" *ngIf="offre">
            <button mat-flat-button class="form-group col-sm-12 text-center" color="primary" (click)="editOffre(offre)">
              {{offre.get('libelle').value.length > 25 ? offre.get('libelle').value.substring(0,22) + "..." : offre.get('libelle').value}}
            </button>
          </div>

          <div class="form-group col-sm-3" >
            <input type="color" class="form-control" name="color" formControlName="color">
          </div>
  
          <div class="form-group col-sm-2">
            <button mat-flat-button color="warn" class="etiquetteDeleteButton" (click)="removeOffre(i)">  <mat-icon>delete</mat-icon> </button>
          </div>
        </div>
      </div>

      <button mat-flat-button color="success" class="etiquetteAddButton" (click)="addOffre()">  <mat-icon>add</mat-icon> </button>

      <div class="text-center" style="text-align: right !important; margin-top: 5vh">
        <button mat-flat-button class="etiquetteAddButton cancelButton" (click)="MasquerFormulaire()">
          Annuler
        </button>
        <button mat-flat-button color="success" class="etiquetteAddButton" (click)="create()">
          Enregistrer
        </button>
      </div>

    </form>
  </mat-card>
</div>
