import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import { DevisDTO } from '../../DTOs/devisDTO';
import { Component, OnInit, Inject } from '@angular/core';

export interface DialogData {
  devis: DevisDTO
}

@Component({
  selector: 'app-modal-envoi-devis',
  templateUrl: './modal-envoi-devis.component.html'
})

export class ModalEnvoiDevisComponent implements OnInit {
  
  constructor(public dialogRef:MatDialogRef<ModalEnvoiDevisComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }


  envoiAttestation(){this.dialogRef.close(2);}
  envoiSeul(){this.dialogRef.close(1);}
  enregistrerSansEnvoi(){this.dialogRef.close(3);}
  onModalCancel(){this.dialogRef.close(0);}

}
