import { EventEmitter, Component, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { EntrepriseModel } from 'src/app/modules/core/shared/models/tier/entreprise.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html'
})
export class EntrepriseComponent implements OnChanges {
  @Input() tier: EntrepriseModel;
  @Input() required = true;
  @Input() disabled = false;
  @Output() tierChange = new EventEmitter<EntrepriseModel>();
  keyTitleArray = [
    {property: 'raisonSociale', libelle: 'raison sociale'},
    {property: 'marqueEnseigne', libelle: 'marque'},
    {property: 'telephone', libelle: 'téléphone'},
    {property: 'clientNumber', libelle: 'numéro client'},
    {property: 'identifiantJason', libelle: 'identifiant Jason'},
    {property: 'siretSiren', libelle: 'siret/siren'}];


  ngOnChanges(changes: SimpleChanges): void {
    this.tier = Object.assign({}, changes.tier.currentValue);
  }

  changeEvent() {
    this.tier.rechercheTitle =  TierModel.getTitle(this.keyTitleArray, this.tier);
    this.tierChange.emit(this.tier);
  }
}
