import { SyndicModel } from './../../shared/models/tier/syndic.model';
import { MairieModel } from './../../shared/models/tier/mairie.model';
import { HlmModel } from './../../shared/models/tier/hlm.model';
import { EntrepriseModel } from './../../shared/models/tier/entreprise.model';
import { SimpleQueryTiersDTO } from './../../shared/dtos/simpleQueryTiersDTO';
import { ApiService } from '../api.service';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { SiteGeoService } from '../site-geo.service';
import {Injectable} from '@angular/core';
import {BasicService} from '../basic-service/basic.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, filter, switchMap } from 'rxjs/operators';
import { AgenceService } from '../rsa/agence.service';
import { UserService } from '../user/user.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ParticulierModel } from '../../shared/models/tier/particulier.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { VerifyFields } from '../../shared/verifyFields';
import { TierDTO } from '../../shared/dtos/tierDTO';
import { filterTierDTO } from '../../shared/dtos/filterTierDTO';
import { tierPgQueryResponse } from '../../shared/dtos/tierPgQueryResponse';
import { DocumentModel } from '../../shared/models/document/document.model';
import { DocumentService } from '../document.service';

@Injectable()

export class TierService extends BasicService {
  public tiers: TierModel[];

  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              siteGeoService: SiteGeoService,
              agenceService: AgenceService,
              userService: UserService,
              protected IntercomToastrService: IntercomToastrService,
              protected documentService: DocumentService
              ) {
                super(http, apiService, sessionService);
                this.serviceUrl = apiService.ROUTETIER;
  }
  observer = new BehaviorSubject<TierModel>(null);
  public tierSubscriber = this.observer.asObservable();

  createTiersFromModel(tiers: TierModel): TierModel {
    if (tiers && tiers.typeTier) {
      switch (tiers.typeTier) {
        case 'Entreprise' : {
          return new EntrepriseModel(tiers);
        }
        case 'Particulier' : {
          return new ParticulierModel(tiers);
        }
        case 'Mairie' : {
          return new MairieModel(tiers);
        }
        case 'Syndic' : {
          return new SyndicModel(tiers);
        }
        case 'Hlm' : {
          return new HlmModel(tiers);
        }
      }
    }
    return new TierModel();
  }

  getFromNumber(objet: number): Observable<TierModel> {
    return super.get(objet).pipe(tap((tier: TierModel) => {
      // this.loadTier(tier);
      return this.http.get<TierModel[]>(this.apiUrl + this.serviceUrl + 'GetFromNumber/' + objet, {headers: this.header});
    }));
  }

  getAllSimpleTier(): Observable<TierModel[]> {
    return <Observable<TierModel[]>> super.getAll();
  }

  getFastQuery(objet: SimpleQueryTiersDTO): Observable<TierModel[]> {
    return this.http.post<TierModel[]>(this.apiUrl + this.serviceUrl + 'GetFastQuery/',objet);
  }

  getSimpleTier(id: number): Observable<TierModel> {
    return <Observable<TierModel>> super.get(id);
  }

  get(objet: number): Observable<TierModel> {
    return super.get(objet).pipe(tap((tier: TierModel) => {
    }));
  }

  getAll(): Observable<TierModel[]> {
    return super.getAll().pipe(tap((tiers: TierModel[]) => {
      for (const tier of tiers) {
      }
    }));
  }
  
  GetFromObjetWithPagination(objet: SimpleQuery<TierDTO>): Observable<SimpleQueryResponse<TierModel>> {
    return this.http.post<SimpleQueryResponse<TierModel>>(this.apiUrl + this.serviceUrl + 'GetFromObjetWithPagination/', objet).pipe(tap((query: SimpleQueryResponse<TierModel>) => {
      for (const tier of query.objets) {
      }
    }));
  }

  GetFromObjetPgWithPagination(objet: filterTierDTO): Observable<tierPgQueryResponse> {
    return this.http.post<tierPgQueryResponse>(this.apiUrl + this.serviceUrl + 'GetFromObjetPgWithPagination/', objet);
  }


  put(objet: TierModel): Observable<TierModel> {
    const myObj = JSON.parse(JSON.stringify(objet));
    myObj.actions = null;
    myObj.agence = null;
    myObj.commercial = null;
    myObj.demandes = null;
    myObj.interlocuteurs = null;
    myObj.nature = null;
    myObj.siteGeos = null;
    return super.put(myObj).pipe(tap((obj: TierModel) => {
    }));
  }

  post(objet: TierModel): Observable<TierModel> {
    const myObj = JSON.parse(JSON.stringify(objet));
    myObj.actions = null;
    myObj.agence = null;
    myObj.commercial = null;
    myObj.demandes = null;
    myObj.interlocuteurs = null;
    myObj.nature = null;
    myObj.siteGeos = null;
    return super.post(myObj).pipe(tap((obj: TierModel) => {
    }));
  }

  beforeSave(objet: TierModel): boolean {
    let success = true;
    if (!VerifyFields.verifyEmail(objet.email)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un email correct.');
    }
    if (!VerifyFields.verifyCodePostal(objet.codePostal)) {
          success = false;
          this.IntercomToastrService.warning('Veuillez saisir un code postal correct.');
    }
    if (!VerifyFields.verifyCodePostal(objet.codePostalFact)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un code postal de facturation correct.');
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    return success;
  }

  sendDocument(filename: string, typeId: number, file: File): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.documentService.sendDoc(filename, typeId, "TiersS3", file).pipe(filter((doc: HttpEvent<unknown>) => {
      return doc.type === HttpEventType.Response;
    }),
      switchMap((doc: HttpEvent<unknown>) => {
        const document = new DocumentModel();
        document.libelle = filename;
        document.typeId = typeId;
        document.type = "TiersS3";
        return this.documentService.save(document);
      })).subscribe(sub => {
        avancement.next(sub.id);
      }, error => {
        avancement.next(null);
      });
    return avancement.asObservable();
  }

  getDocumentById(idDoc: number): Observable<Blob> {
    return this.documentService.getDoc(idDoc);
  }

  deleteDocument(idDoc: number): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.documentService.delete(idDoc).subscribe(sub => {
      avancement.next(sub.id);
    });
    return avancement.asObservable();
  }
}
