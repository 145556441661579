<div class="header">
  <h5>Document</h5>
  <mat-icon class="close-button" (click)="onModalCancel()">close</mat-icon>
  <mat-divider></mat-divider>
</div>
<div class="mat-dialog-content">

  <div style="margin-top: 20px;">
    <div class="doc" *ngIf="data.document">
      {{data.document.libelle}}
    </div>
    <div class="btn">
      <button mat-flat-button color="primary" (click)="onOpenDoc(data.document)">
        <mat-icon>get_app</mat-icon>
      </button>
    </div>
    <div class="btn">
      <button mat-flat-button color="primary" (click)="visualiseDoc(data.document)">
        <mat-icon>remove_red_eye</mat-icon>
      </button>
    </div>
    <div class="btn">
      <button mat-flat-button color="danger" (click)="onDeleteDoc(data.document)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>