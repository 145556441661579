import { Injectable } from '@angular/core';
import { TierBasicModel } from '../../models/basic-models/tier-basic.model';
import { BasicMock } from './basic.mock';

@Injectable()
export class TierBasicMock extends BasicMock {
protected objets: TierBasicModel[];

    getfromTier(tierId: number): TierBasicModel[] {
        return Object.assign([], this.objets.filter(value => {
            return value.tierId === tierId;
        }));
    }
}
