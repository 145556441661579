 
         <mat-form-field class="full-width">
          <mat-label> Liste des agences</mat-label> 
          <mat-chip-list #chipList aria-label="agence selection">
            <mat-chip
              *ngFor="let agence of selectedAgenceString"
              [selectable]="selectable"
              [removable]="removable"
              [matTooltip]="agence"
              (removed)="remove(agence)">
              <div class="text-overflow">
                {{agence}}
              </div>
              <mat-icon class="fixed-icon" matChipRemove *ngIf="removable">close</mat-icon>
            </mat-chip>
            <input
              #agenceInput
              [formControl]="agenceCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let agence of filteredAgences | async" [value]="agence" matTooltip={{agence}}
              matTooltipPosition="left">
              {{agence}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
 

 
 