import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { PriseAppelDemandeComponent } from './prise-appel-demande/prise-appel-demande.component';
import { TierModel } from '../../shared/models/tier/tier.model';


export interface DialogData {
  tier: TierModel;
  result: boolean;
}

@Component({
  selector: 'app-modal-selection-tiers-facture',
  templateUrl: './modal-selection-tiers-facture.component.html',
  styleUrls: ['./modal-selection-tiers-facture.component.scss']
})

 
export class ModalSelectionTiersFactureComponent implements OnInit {
  
  isInCreateTiersModal : boolean = true;
  selectedTiers : TierModel = new TierModel();
  isTierSelected : boolean = false;

  constructor(
     public dialogRef: MatDialogRef<PriseAppelDemandeComponent>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toastr: IntercomToastrService
  ) { }

  ngOnInit() {
    // this.dialogRef.updateSize('100%');
  }

  onModalCancel(): void {
    this.isInCreateTiersModal = false;
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
    if (this.selectedTiers) {
    this.isInCreateTiersModal = false;

      this.data.result = true;
      this.data.tier = this.selectedTiers;
      this.dialogRef.close(this.data);
      this.toastr.success('', 'Nouveau tiers facturé selectionné' + this.selectedTiers.libelle );
    } else {
      this.isInCreateTiersModal = false;
      this.toastr.warning('La selection du tiers facturé a échoué');
    }
  }

  tierSelected(tier){
    this.selectedTiers = tier;
    this.isTierSelected = true;
  }

}






