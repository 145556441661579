<div>
    <a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon">close</mat-icon></a>
    
    <div class="col-sm-8">
      <h3>Modifier un protocole</h3>
    </div>
   
    <div class="col-sm-12">
      <mat-divider></mat-divider>
    </div>
  
    <mat-card class="info">
        <mat-card-header>
          <mat-card-title>Consignes ajout d'un protocole</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>Si le champs Adresse n'est pas renseigné, le protocole sera généralisé sur toutes les adresses par défaut.</li>
            <li>Si le champs Prestation n'est pas renseigné, le protocole sera généralisé sur toutes les prestations par défaut.</li>
          </ul>
        </mat-card-content>
      </mat-card>
    
  <div class="col-sm-12 body-class">
    <div class="col-sm-6">
      <mat-form-field *ngIf="prestation !== undefined" class="full-width">
        <input type= "text"
        matInput
        [required]="false"
        [(ngModel)]="prestation.libelle"
        placeholder="Selectionner une prestation"
        (change)="add($event.target.value)"
        [formControl]="prestationCtrl"
        [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let prestation of filteredPrestations | async" [value]="prestation">
            {{prestation}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>


    <div class="col-sm-6">
      <app-autocomplete-realisateur [required]="false" libelle="Réalisateur" [users]="users" [user]="user" [commercialId]= "realisateurId" (onUpdated)="onUpdateRealisateurId($event)"> </app-autocomplete-realisateur>
    </div>
  </div>
    <div class="col-sm-12 full-width">
      <app-autocomplete-adresse *ngIf="this.data.protocole.tierId" [realisateur]="realisateur" [adresses]="siteModels" [adresseId]="this.data.protocole.siteGeoId" (selectedAdresse)='onAddAddress($event)'></app-autocomplete-adresse>
    </div>
    <div *ngIf="this.data.protocole.agenceId && !this.data.protocole.tierId" class="col-sm-6" >
      <mat-label>Ville</mat-label>
      <app-autocomplete-ville
        libelle="Ville"
        (onUpdated)="onCityChange($event)"
        [(selectedCity)]="selectedCity"
      >
    </app-autocomplete-ville>   
    
    </div>
    <div *ngIf="this.data.protocole.agenceId && !this.data.protocole.tierId" class="col-sm-6">
      <mat-label>Code postal</mat-label>
      <app-autocomplete-code-postal 
        libelle="Code Postal" 
        (onUpdated)="onCodePostalChange($event)"
        [(selectedCity)]="selectedCity"
      >
      </app-autocomplete-code-postal>
    </div>

  <div class="col-sm-12">
    <mat-form-field class="full-width">
      <mat-label >Protocole</mat-label>
      <textarea matInput placeholder="" style="height: 90px;" value=""
        [(ngModel)]="data.protocole.protocole" required></textarea>
      </mat-form-field>
    </div>
</div>
  
  <div class="col-sm-12 text-center">
    <div class="col-sm-6 text-center" style="margin-top: 24px;">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>
  
    <div class="col-sm-6 text-center" style="margin-top: 24px;">
      <button mat-flat-button color="success" (click)="onModalConfirm()">Modifier</button>
    </div>
  </div>