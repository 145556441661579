  <div class="header-class">
    <h3>Fiche tâche
      <mat-icon data-html="true" #tooltip="matTooltip" 
        [matTooltip]="'Créateur : ' + data.action.createur.nom +' ' + data.action.createur.prenom + '\n' +
        (data.action.dateCreation ? ('Date de création : ' + (data.action.dateCreation.toString() | date:'dd/MM/yyyy HH:mm')) : '') + '\n' +
        (data.action.dateCloture ? ('Date de cloture : ' + (data.action.dateCloture.toString() | date:'dd/MM/yyyy HH:mm')) : '') +' &#013;'"
        matTooltipPosition="left"
        matTooltipClass="mat-tooltip">info</mat-icon>
    </h3>    
    <div>
      <a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon">close</mat-icon></a>
      <a (click)="onModalMinimize()" class="close-button"><mat-icon class="close-icon">remove</mat-icon></a>
    </div>
  </div>
  
  <mat-divider></mat-divider>

  <div class="modal-container">
  <div class="col-md-12 body-class">

    <div class="col-md-8 body-class" *ngIf="hasTiers">
      <div class="header-class">
        <h4>Tiers : </h4>
      </div>

      <div class="col-md-12 data-class body-class element-top-bottom-6">
        <app-resume-tier (tierOpen)="onTierOpen()" [tier]="data.action.tier" ></app-resume-tier>
      </div>
    </div>

    <div class="col-md-4 element-right" *ngIf="hasTiers && data.action.demandeId !== null">
      <div class="header-class">
        <div class="header-description-class">
          <h4>Demande : </h4>
        </div>
      </div>
      <div class="data-class col-md-12 element-top-bottom-6">
        <button *ngIf="data.action.demandeId !== null" (click)="onOpenDemande()" matTooltip="{{'Ouvrir la demande ' + data.demandeLib}}" mat-flat-button color="primary" class="button-demande"> {{data.demandeLib | truncate: 30}} <mat-icon>work</mat-icon></button>
      </div>
    </div>
  </div>

  
  <div class="col-md-12 body-class">
    <!-- Tâches -->
    <div [ngClass]="hasTiers ? 'col-md-8 body-class' : 'col-md-12 body-class' ">
      <div class="header-class">
        <h4>Tâche : </h4>
      </div>
      <div class="col-md-12 data-class body-class">
        <div class="col-md-12">
          <mat-form-field class="full-width" >
            <mat-label>Objet</mat-label>
            <input required matInput placeholder="" value="" [(ngModel)]="data.action.objetAction" name="intercom-objet" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="full-width">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="data.action.typeActionId" (change)="typChange()" required>
              <mat-option *ngFor="let typeA of typeActions.slice()" [value]="typeA.id">{{ typeA.libelle }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="col-md-4">
          <app-autocomplete-realisateur [required]="true" libelle="Réalisateur" [users]="users" [commercialId]="commercialId" [user]="user" (onUpdated)="onUpdateRealisateurId($event)"> </app-autocomplete-realisateur>
        </div>
    
        <div class="col-md-4">
          <mat-form-field class="full-width">
            <mat-label>Statut</mat-label>
            <mat-select required [(ngModel)]="data.action.statut">
              <mat-option value="A traiter">A traiter</mat-option>
              <mat-option value="En cours">En cours</mat-option>
              <mat-option value="Annulé">Annulé</mat-option>
              <mat-option value="Clos">Clos</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="full-width">
            <mat-label>Date d'échéance</mat-label>
            <input required matInput [matDatepicker]="picker" [(ngModel)]="data.action.dateRealisation">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <app-input-heure [libelle]="'Heure (00h00)'" [(value)]="data.action.heureDebut" [required]="false" (valueChange)="data.action.heureDebut"></app-input-heure>
        </div>
    
        <div class="col-md-4">
          <app-input-heure [libelle]="'Durée (00h00)'" [(value)]="data.action.duree" [required]="false" (valueChange)="data.action.duree"></app-input-heure>
        </div>
        
        <div class="col-md-12">
          <mat-form-field class="full-width">
            <mat-label>Commentaire</mat-label>
            <textarea rows="10" matInput placeholder="" value="" [(ngModel)]="data.action.commentaire"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Contact -->
    <div class="col-md-4 element-right" *ngIf="hasTiers">
      <div class="header-class">
        <h4>Contact : </h4>
      </div>
      <div class="col-md-12 data-class element-padding-top-10">
        <div>
          <app-autocomplete-interlocuteur 
            [interlocuteurs]="interlocuteurs"
            (selectedInterlocuteur)='onEditInterlocuteur($event)'
            [action]="data.action">
          </app-autocomplete-interlocuteur>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 element-padding-top-10">
    <mat-divider></mat-divider>
  </div>

  <div class="text-center">
    <div class="col-md-12 flex-container">
      <div class="col-md-4 subDivContainer">
        <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
      </div>
      <div class="col-md-4 subDivContainer">
        <button mat-flat-button color="success" (click)="onModalConfirm()">Enregistrer</button>
      </div>
    </div>
  </div>
</div>  