import { TypeDroit } from './typeDroits';

export class DroitLevels {
    public static TYPEDROITS: TypeDroit[] = [
        { niveau: 1, libelle: 'Lecture'},
        { niveau: 2, libelle: 'Ajout / Modification'},
        { niveau: 3, libelle: 'Suppression'},
        { niveau: 4, libelle: 'Administrateur'}
    ];
    public static READ = 1;
    public static WRITE = 2;
    public static DELETE = 3;
    public static MANAGE = 4;
}
