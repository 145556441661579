import { DevisApiService } from './devis-api.service';
import { DevisBasicService } from './devis-basic.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DevisModel } from '../models/devis.model';
import { DevisDTO } from '../DTOs/devisDTO';
import { GmailDTO } from '../DTOs/gmailDTO';
import { SessionService } from '../../shared/session.service';
import { filter, switchMap } from 'rxjs/operators';
import { DocumentService } from './document.service';
import { DocumentModel } from '../models/document.model';
import { DevisBasicModel } from '../models/devis-basic.model';
import { SimpleQuery } from '../../core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../core/shared/simple_query/simpleQueryResponse';
import { FiltreDevisDTO } from '../../core/shared/dtos/filterDevisDTO';
import { DashboardDTO } from '../../core/shared/dtos/dashboardDTO';
import { FiltrePerimetreDTO } from '../../core/shared/dtos/filterPerimetreDTO';
import { InfoJasonDTO } from '../DTOs/InfoJasonDTO';
import { SocieteModel } from '../../core/shared/models/rsa/societe.model';

@Injectable()
export class DevisService extends DevisBasicService {

  constructor(
    http: HttpClient,
    apiService: DevisApiService,
    protected sessionService: SessionService,
    private documentService: DocumentService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, apiService);
      this.serviceUrl = apiService.ROUTEDEVIS;
  }

  getFromTier(tiersId: number): Observable<DevisModel[]> {
    return this.http.get<DevisModel[]>(this.apiUrl + this.serviceUrl + 'GetFromTier/' + tiersId);
  }

  getFiltredDevis(filterPerimetre: FiltreDevisDTO):  Observable<SimpleQueryResponse<DevisDTO>>{
    var filterPerimetreDevis = new FiltrePerimetreDTO();
    if (filterPerimetre.societes) {
      if (!filterPerimetreDevis.AgencesIds || filterPerimetreDevis.AgencesIds.length == 0){
        var res = filterPerimetre.societes.map((societe: SocieteModel) => {
          let res: number[] = [];
          for (let agence of societe.agences) {
            res.push(agence.id);
          }
          return res;
        });
        filterPerimetreDevis.AgencesIds = ([] as number[]).concat(... res);
      }
    }      
    return this.http.post<SimpleQueryResponse<DevisDTO>>(this.apiUrl+ this.serviceUrl + 'getFiltredData/',filterPerimetre);
  }

  getFromDemande(demandeId: number, idAgence: number): Observable<DevisDTO> {
    return this.http.get<DevisDTO>(this.apiUrl + this.serviceUrl + 'getFromDemande?demandeId=' + demandeId + '&idAgence=' + idAgence);
  }

  create(objet: DevisDTO): Observable<DevisDTO>{
    return this.http.post<DevisDTO>(this.apiUrl + this.serviceUrl + 'create/', objet);
  }

  getDashboard(filterPerimetre:FiltrePerimetreDTO):  Observable<DashboardDTO>{
    var filterPerimetreDevis = new FiltrePerimetreDTO();
    filterPerimetreDevis.AgencesIds = filterPerimetre.AgencesIds;
    filterPerimetreDevis.agences = filterPerimetre.agences;
    filterPerimetreDevis.createurIds = filterPerimetre.createurIds;
    filterPerimetreDevis.createurs = filterPerimetre.createurs;
    filterPerimetreDevis.realisateurIds = filterPerimetre.realisateurIds;
    filterPerimetreDevis.realisateurs = filterPerimetre.realisateurs;
    filterPerimetreDevis.societes = filterPerimetre.societes;
    if (filterPerimetre.societes) {
      if (!filterPerimetreDevis.AgencesIds || filterPerimetreDevis.AgencesIds.length == 0){
        var res = filterPerimetre.societes.map((societe: SocieteModel) => {
          let res: number[] = [];
          for (let agence of societe.agences) {
            res.push(agence.id);
          }
          return res;
        });
        filterPerimetreDevis.AgencesIds = ([] as number[]).concat(... res);
      }
    }      
    return this.http.post<DashboardDTO>(this.apiUrl+ this.serviceUrl + 'getDashboard/', filterPerimetreDevis);
  }

  generateDraft(objet: GmailDTO): Observable<string> {
    objet.tokenGmail = this.sessionService.getRefreshToken().access_token;
    return this.http.post<string>(this.apiUrl + this.serviceUrl + 'generateDraft/', objet);
  }

  sendDocument(filename: string, idDevis: number, file: File): Observable<DevisBasicModel> {
    return this.documentService.sendDocDevis(filename, idDevis, file).pipe(filter((doc: HttpEvent<unknown>) => {
      return doc && doc.type === HttpEventType.Response;
    }),
      switchMap((doc: HttpEvent<unknown>) => {
        const document = new DocumentModel();
        document.libelle = filename;
        document.idDevis = idDevis;
        return this.documentService.post(document);
      }));
  }

  UpdateInfoJason(infoJasonDTO: InfoJasonDTO, idDevis: number) : Observable<DevisModel> {
    infoJasonDTO.id = idDevis;
    return this.http.put<DevisModel>(this.apiUrl + this.serviceUrl + 'UpdateInfoJason/' + infoJasonDTO.id, infoJasonDTO);
  }

  getFromObjet(objet: SimpleQuery<DevisDTO>): Observable<SimpleQueryResponse<DevisDTO>> {
    // tslint:disable-next-line: max-line-length
    /* var filterPerimetreDevis = new DevisDTO();
    filterPerimetreDevis.societe = objet.entry.AgencesIds;
    if (filterPerimetre.societes) {
      if (!filterPerimetreDevis.AgencesIds || filterPerimetreDevis.AgencesIds.length == 0){
        var res = filterPerimetre.societes.map((societe: SocieteModel) => {
          let res: number[] = [];
          for (let agence of societe.agences) {
            res.push(agence.id);
          }
          return res;
        });
        filterPerimetreDevis.AgencesIds = ([] as number[]).concat(... res);
      }
    }*/
    return <Observable<SimpleQueryResponse<DevisDTO>>> this.http.post<SimpleQueryResponse<DevisDTO>>(this.apiUrl + this.serviceUrl + 'GetFromObjetWithPagination/', objet);
  }

  exportDevis(filtre: FiltreDevisDTO) {
    return this.http.post(this.apiUrl + this.serviceUrl + 'exportDevis', filtre, {responseType: 'text'});
  }
}
