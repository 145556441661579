import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()

export class PrestationApiService {

  public URLAPI = environment.API_END_POINT_URL;
  public ROUTEPRESTATION = 'IntercomPrestationApi/Prestation/';
  public ROUTEOFFRE = 'IntercomPrestationApi/Offre/';
  public httpOptions;

  constructor () {
  }

}
