import { EntrepriseModel } from '../models/tier/entreprise.model';
import { TierDTO } from './tierDTO';
import { Injectable } from "@angular/core";

@Injectable()
export class EntrepriseDTO extends TierDTO {
  public raisonSociale: string;
  public siretSiren: string;
  public marqueEnseigne: string;
  public famille: string;
  public codeNaf: string;
  public typeTier = 'Entreprise';
}
