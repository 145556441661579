<div class="wait">
<div class="row">

<div class="col-sm-12">
   <p class="glyphicon glyphicon-cog"></p>
</div>
</div>
<div class="row">
<div class="col-12">
  <mat-divider></mat-divider>
</div>
</div>

<div class="row">

  <div class="test">
    <div class="row text-center">
  
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Nom</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Prénom</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-12">
        <mat-divider></mat-divider>
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Région</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Agence</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-12">
        <mat-divider></mat-divider>
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Adresse</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Ville</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-12">
        <mat-divider></mat-divider>
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Téléphonne</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
      <div class="col-sm-6">
  
        <mat-form-field class="example-full-width">
          <mat-label>Mail </mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
  
      </div>
    </div>
  </div>
</div>
</div>