 <!-- Les adresses du tiers -->
 <div class="col-md-12">
  <mat-icon class="col-md-12" class="close-button" (click)="onModalCancel()">close</mat-icon>
 </div>
 <mat-card class="col-md-12" style="padding: 0;">
  <div class="col-md-12 table" style="padding: 0;">
    <div class="row header" style="margin: 0;">
    <h4 class="col-md-2">
      Adresses
    </h4>
     
    <mat-form-field class="col-md-8">
      <input matInput placeholder="Recherche adresse" [(ngModel)]="this.simpleQuerySiteGeo.entry.adresse" [(ngModel)]="this.simpleQuerySiteGeo.entry.libelle"
           (change)="rechercheAdresse()">
    </mat-form-field>

    <button class="col-md-2"
      matPrefix
      mat-stroked-button
      color="success"
      (click)="onAddSite()"
      matTooltip="Ajouter une adresse"
      matTooltipPosition="below">
    <mat-icon>add</mat-icon> Ajouter un adresse
    </button>
  </div>
  <div class="row">
    <app-site-geographique (onUpdated)="onUpdateSite($event)" (onSelect)="onSelectSite($event)" [simpleQueryResponse]="simpleQueryResponse" [tier]=tier [selectRow]="true"></app-site-geographique>
  </div>
  </div>
 </mat-card>
