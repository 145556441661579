import { Injectable } from "@angular/core";
import { TierPgResult } from "./tierPgResult";


@Injectable()
export class tierPgQueryResponse {
    public tiers : TierPgResult[];
    public pageNumber: number;
    public pageSize : number;
    public count : number;
    public maxSimilarity : number;
    public countAd : number;
    public countInter : number;
    public countPhone: number;
    public countDemande: number;
    public order : string;
}