import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef  } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Component,  Inject } from '@angular/core';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ActionService } from '../../../services/action.service';
import { DemandeService } from '../../../services/demande.service';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss']
})

export class CustomSnackBarComponent {
  id: number;
  type: string;
  title : string;
  body : string;
  constructor(    
  @Inject(MAT_SNACK_BAR_DATA) public data: string,
  public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>,
  private modalsService: ModalsService,
  private demandeService : DemandeService,
  private actionService: ActionService,
  private router: Router) {
      const message = JSON.parse(data);
      this.id = message.data.id;
      this.type = message.data.type
      this.body = message.notification.body;
      this.title = message.notification.title;
   }

  Open() {
    if( this.type === 'demande'){
        this.demandeService.get(this.id).subscribe(demande => this.modalsService.openDemande(demande).subscribe());
    } else {
        this.actionService.get(this.id).subscribe(action => this.modalsService.openAction(action).subscribe());
    }
    this.snackBarRef.dismiss();

  }

  cancel() {
    this.snackBarRef.dismiss();
  }

}

