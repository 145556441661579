<div style="text-align: left" class="text">
    
    <b>N° Devis : </b> {{devis.code}}<br/>
    <b>Statut : </b>
    <strong *ngIf="devis.statut === 'À envoyer'" class="chip info-blue text-center"> {{devis.statut}} </strong>
    <strong *ngIf="devis.statut === 'Annulé'" class="chip info-light-brown text-center"> {{devis.statut}} </strong>
    <strong *ngIf="devis.statut === 'Refusé'" class="chip info-red text-center"> {{devis.statut}} </strong>
    <strong *ngIf="devis.statut === 'Transmis'" class="chip info-dark-blue text-center"> {{devis.statut}} </strong>
    <strong *ngIf="devis.statut === 'Validé'" class="chip info-green text-center"> {{devis.statut}} </strong>
    <strong *ngIf="devis.statut === 'Envoyé à Jason'" class="chip info-green text-center"> {{devis.statut}} </strong>
    <br/>
    <b>Date de modification : </b> {{devis.dModification | date:'short':'+0000':'fr'}}<br/>
    <div *ngIf="devis.dateCloture">
        <b >Date de cloture : </b> {{devis.dateCloture | date:'short':'+0000':'fr'}}<br/>
    </div>
    <b>TVA {{devis.tvaPercent}} % : </b> {{tva.toFixed(2)}} €<br/>
    <b>Montant HT : </b> {{devis.ht}} €<br/>    
    <b>Montant TTC : </b> {{devis.ttc}} €<br/>
    <div *ngIf="devis.statut === 'Refusé' || devis.statut === 'Annulé'"> 
        <b >Motif : </b> {{devis.motif}}<br/>
    </div>
</div>


<!------------------------  STATUT Brouillon   ------------------------->

<div  class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='Brouillon'" style="padding-top: 15px;">
    <div class="col-sm-6 body-class">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="primary" (click)="opendialogrelance()"> Envoyer </button>
    </div>
    
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="danger" (click)="openRefuser()" > Refuser </button>
    </div>
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="warning" (click)="openAnnuler()" > Annuler </button>
    </div>
</div>

<!------------------------  STATUT refusé   ------------------------->
<div class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='Refusé'" style="padding-top: 15px;">
    <div class="col-sm-6" [ngClass]="{'position' : true}">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 30px;" mat-flat-button color="default" (click)="openModifier()"> Modifier </button>
    </div>

</div>

<!------------------------  STATUT Transmis   ------------------------->

<div  class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='Transmis'" style="padding-top: 15px;">
    <div class="col-sm-6 body-class btn-ctn">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="primary" (click)="opendialogrelance()"> Relancer </button>
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="default" (click)="openModifier()"> Modifier </button>
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="success" (click)="opendialogvalider()"> Valider </button>
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="danger" (click)="openRefuser()" > Refuser </button>
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="warn" (click)="openAnnuler()" > Annuler </button>
    </div>
    
</div>

<!------------------------  STATUT À envoyer   ------------------------->

<div  class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='À envoyer'" style="padding-top: 15px;">
    <div class="col-sm-6 body-class">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="default" (click)="openModifier()"> Modifier </button>
    </div>
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="primary" (click)="opendialogrelance()"> Envoyer </button>
    </div>    
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="danger" (click)="openRefuser()" > Refuser </button>
    </div>
    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 20px;" mat-flat-button color="warn" (click)="openAnnuler()" > Annuler </button>
    </div>
</div>

<!------------------------  STATUT validé   ------------------------->

<div  class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='Validé'" style="padding-top: 15px;">
    <div [ngClass]="{'position' : true}">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>
</div>

<!------------------------  STATUT annulé   ------------------------->

<div  class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='Annulé'" style="padding-top: 15px;">
    <div class="col-sm-6 body-class" [ngClass]="{'position' : true}">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>

    <div class="col-sm-6 body-class btn-ctn">
        <button style="margin-left: 30px;" mat-flat-button color="default" (click)="openModifier()"> Modifier </button>
    </div>
</div>

<!------------------------  STATUT Envoyé à Jason   ------------------------->

<div  class="col-sm-12 body-class btn-devis-container" *ngIf="statutValue=='Envoyé à Jason'" style="padding-top: 15px;">
    <div [ngClass]="{'position' : true}">
        <app-pdf-devis [devis]="devis"></app-pdf-devis> <!-- button afficher -->
    </div>
    
</div>





