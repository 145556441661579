import { CiviliteService } from './../../services/civilite.service';
import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, NgZone, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DemandeComponent } from './demande.component';
import { DemandeService } from './../../services/demande.service';
import { TypeDemandeService } from './../../services/type-demande.service';
import { SiteGeoService } from './../../services/site-geo.service';
import { ActiviteService } from '../../services/prestation.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import { SessionService } from 'src/app/modules/shared/session.service';
import { FormulaireService } from '../../services/formulaire.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { UserService } from '../../services/user/user.service';
import { ProtocoleDTO } from '../../shared/dtos/protocoleDTO';
import { CiviliteModel } from '../../shared/models/civilite.model';
import { DemandeModel } from '../../shared/models/demande.model';
import { FormulaireModel } from '../../shared/models/formulaire.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { ActiviteModel } from '../../shared/models/prestation.model';
import { ProtocoleModel } from '../../shared/models/protocole.model';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { TypeDemandeModel } from '../../shared/models/type-demande.model';
import { UserModel } from '../../shared/models/users/user.model';
import { ProtocoleService } from '../../services/protocole.service';


export interface DialogData {
  demande: DemandeModel;
  tierId: number;
  tierFactureId : number;
  realisateurId: number;
  result: boolean;
}
interface Data {
  index: number;
  name: string;
}

export class DemandePrestation {
  prestation: ActiviteModel;
  value: boolean;
}

@Component({
  selector: 'app-modal-ajout-demande',
  templateUrl: './modal-ajout-demande.component.html',
  styleUrls: ['./demande.component.scss']
})
export class ModalAjoutDemandeComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated = new EventEmitter<boolean>();

  typeDemandes: TypeDemandeModel[] = [];
  typeDemande: TypeDemandeModel;
  users: UserModel[] = [];
  user: UserModel;
  siteModels: SiteGeoModel[] = [];
  siteModel: SiteGeoModel;
  adresseId: number;

  prestations: ActiviteModel[] = [];
  prestation: ActiviteModel;
  demandePrestations = [];
  checkboxIds: string[];
  result: boolean;
  // model: Offer;
  objets: any;
  displayedColumns: string[] = ['prestation', 'remove'];
  testTab: string[];
  tabpresta = [];
  tabSplit = [];
  libelleTab = [];
  currentText = '';


  /* ajout des formulaires */
  displayedColumnsFormulaire: string[] = ['libelle', 'modifications'];
  dataSourceFormulaire: FormulaireModel[] = [];
  lengthFormulaire: number;

  dataa: Data[] = [];
  dataSource: Data[];
  matDataSource: MatTableDataSource<DemandeModel>;
  removePrestationId = '';
  id: number;
  pageEvent: PageEvent;
  length: number;
  pageIndex: number;
  pageSize = 5;
  prestationIds: string;
  commercialId: number;
  // Intervention selected
  selectedTypeDemande = 5;

  interlocuteurs: InterlocuteurModel[] = [];
  interlocuteur: InterlocuteurModel;
  interlocuteurId: number;
  // End for autocomplete-interlocuteur component

  datasource: MatTableDataSource<Data>;
  loading = false;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  objetAuto = true;
  civilites: CiviliteModel[] = [];
  commercial: UserModel;
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<DemandeComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private demandeService: DemandeService
    , private typeDemandeService: TypeDemandeService
    , private siteService: SiteGeoService
    , private civiliteService: CiviliteService
    , private userService: UserService
    , private sessionService: SessionService
    , private prestationService: ActiviteService
    , private toastr: IntercomToastrService
    , private formulaireService: FormulaireService
    , private protocoleService: ProtocoleService
    , private interlocuteurService: InterlocuteurService
    , private _ngZone: NgZone
    , private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
    });

    this.typeDemandeService.getAllActive().subscribe((ta: TypeDemandeModel[]) => {
      this.typeDemandes = ta;
    });
    this.siteService.getFromTier(this.data.tierId).subscribe((site: SiteGeoModel[]) => {
      this.siteModels = site;
      if (site !== undefined && site.length > 0) {
        const siteF = this.siteModels.find(u => u.libelle === 'Siege');
        let adresse = new SiteGeoModel();
        if (siteF) {
          adresse = siteF;
        } else {
          adresse = this.siteModels[0];
        }
        if (this.siteModels[0]) {
          this.adresseId = this.siteModels[0].id;
        }
        if (this.interlocuteurs[0]) {
          this.interlocuteurId = this.interlocuteurs[0].id;
        }
        this.data.demande.siteGeoId = adresse.id;
        this.data.demande.libelleAdresse = adresse.libelle;
        this.data.demande.commentaireAdresse = adresse.complementAdresse;
        this.data.demande.adresse = adresse.adresse;
        this.data.demande.villeAdresse = adresse.ville;
        this.data.demande.codePostalAdresse = adresse.codePostal;
        this.data.demande.complementAdresse = adresse.complementAdresse;
        this.data.demande.emailAdresse = adresse.email;
        this.data.demande.telephoneAdresse = adresse.telephone;
        this.data.demande.gpsAdresse = adresse.gps;
      } else {
        this.data.demande.siteGeoId = undefined;
        this.data.demande.libelleAdresse = undefined;
        this.data.demande.commentaireAdresse = undefined;
        this.data.demande.adresse = undefined;
        this.data.demande.villeAdresse = undefined;
        this.data.demande.codePostalAdresse = undefined;
        this.data.demande.complementAdresse = undefined;
        this.data.demande.emailAdresse = undefined;
        this.data.demande.telephoneAdresse = undefined;
        this.data.demande.gpsAdresse = undefined;
      }
    });

    this.formulaireService.getFromTier(this.data.tierId).subscribe((forms: FormulaireModel[]) => {
      this.dataSourceFormulaire = forms;
      this.lengthFormulaire = forms.length;
    });

    this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
      this.prestations = prestas;
    });

    // get interlocuteurs InterlocuteurModel from interlocuteurService
    this.interlocuteurService.getFromTier(this.data.tierId).subscribe((interloc: InterlocuteurModel[]) => {
      this.interlocuteurs = interloc;
      if (this.objetAuto) {
        this.initTitleDemande();
      }
    });

    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.user = usrs.find(usr => usr.id === this.data.demande.commercialId);
      this.users = usrs;
      this.commercialId = this.data.demande.commercialId;
    });
    this.data.demande.tierId = this.data.tierId;
    this.data.demande.tierFactureId = this.data.tierFactureId;
    this.data.demande.createurId = this.sessionService.currentUserValue.id;
    this.data.demande.typeDemandeId = this.selectedTypeDemande;
    this.data.demande.dateIntervention = new Date(new Date().getTime() + (1000 * 60 * 60 * 48));
    this.data.demande.dateCreation = new Date();
    this.data.demande.statut = 'A traiter';
    this.data.demande.prestations = [];
    this.data.demande.interlocuteurId = undefined;
    this.dataSource = [];
    this.datasource = new MatTableDataSource();
    this.datasource.paginator = this.paginator;
    this.prestationIds = '';
  }

  ngAfterViewInit(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
  }

  updateDechetsChilds() {
    if (!this.data.demande.dechets) {
      this.data.demande.bsa = false;
      this.data.demande.bsd = false;
    }
  }


  /* Auto complete Prestations Output */
  onUpdatePrestationsId(prestationIds: string) {
    this.data.demande.prestationIds = prestationIds;
    if (prestationIds !== '' && prestationIds) {
      this.demandePrestations = prestationIds.split(',');
    } else {
      this.demandePrestations = [];
    }
    if (this.objetAuto) {
      this.initTitleDemande();
    }
  }

  onUpdateRealisateurId(realisateurId: number) {
    if (realisateurId === null) {
      this.data.demande.commercialId = -1;
      this.commercial = null;
    } else {
      this.data.demande.commercialId = realisateurId;
      this.commercial = this.users.find(x => x.id === realisateurId);
    }
  }

  getDemandeWithPrestations(demande: DemandeModel): DemandeModel {
    if (demande?.prestationIds && demande.prestationIds.length > 0) {
      demande.prestations = [];
      const arrayPrestas = demande.prestationIds.split(',');
      for (const prestaDemande of arrayPrestas) {
        const foundPresta = this.prestations.find(presta => presta.id === Number.parseInt(prestaDemande, 10));
        if (foundPresta) {
          demande.prestations.push(foundPresta);
        }
      }
    }
    if (demande?.typeDemandeId) {
      const typeDemande = this.typeDemandes.find(ltypeDemande => ltypeDemande.id === demande.typeDemandeId);
      if (typeDemande) {
        demande.typeDemande = typeDemande;
      }
    }
    return demande;
  }

  initTitleDemande() {
    this.getDemandeWithPrestations(this.data.demande);
    this.data.demande.objet = 'Demande devis : ';
    if (this.data.demande.typeDemande) {
      this.data.demande.objet = this.data.demande.typeDemande.libelle ? this.data.demande.typeDemande.libelle + ' : ' : 'Demande devis : ';
    }
    this.data.demande.objet += this.data.demande.tier && this.data.demande.tier.libelle ? this.data.demande.tier.libelle + ' ' : '';
    if (this.data.demande.tier) {
      this.data.demande.objet += this.data.demande.tier.libelle !== this.data.demande.nomInterlocuteur &&
      this.data.demande.tier.libelle !== this.data.demande.nomInterlocuteur + ' ' + this.data.demande.prenomInterlocuteur && 
       (typeof(this.data.demande.nomInterlocuteur) !== 'undefined') ?
      ((typeof(this.data.demande.prenomInterlocuteur) !== 'undefined') ?
        '- ' +  this.data.demande.nomInterlocuteur + ' ' + this.data.demande.prenomInterlocuteur :
        '- ' +  this.data.demande.nomInterlocuteur) :  '';
    } else {
      this.data.demande.objet +=   (typeof(this.data.demande.nomInterlocuteur) !== 'undefined') ?
      ((typeof(this.data.demande.prenomInterlocuteur) !== 'undefined') ?
        '- ' +  this.data.demande.nomInterlocuteur + ' ' + this.data.demande.prenomInterlocuteur :
        '- ' +  this.data.demande.nomInterlocuteur) :  '';
    }
    /// prestations
    if (this.data.demande && this.data.demande.prestations && this.data.demande.prestations.length > 0) {
      this.data.demande.objet += '(';
      for (const presta of this.data.demande.prestations) {
        this.data.demande.objet += presta.libelle + ', ';
      }
      this.data.demande.objet = this.data.demande.objet.substr(0, this.data.demande.objet.length - 2);
      this.data.demande.objet += ')';
      this.data.demande.objet = this.data.demande.objet;
    }
  }

  updateDechets() {
    if (this.data.demande.bsa || this.data.demande.bsd) {
      this.data.demande.dechets = true;
    }
  }

  typeChange() {
    this.typeDemande = Object.create(this.typeDemandes.find(ta => ta.id === this.data.demande.typeDemandeId));
    if (this.objetAuto) {
      this.initTitleDemande();
    }
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(this.data);
  }


  changePage(event?: PageEvent): PageEvent {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    return event;
  }

  createDemande() {
    if (this.data.demande.commercial) {
      this.data.demande.agenceId = this.data.demande.commercial.agenceId;
    }
    this.data.demande.tier = null;
    this.data.demande.typeDemande = null;
    this.data.demande.commercial = null;

    this.demandeService.post(this.data.demande).subscribe((dem: DemandeModel) => {
      this.demandeService.get(dem.id).subscribe(res => {
        this.data.result = true;
        this.data.demande = res;
        this.dialogRef.close(this.data);
        this.toastr.success('La demande : ' + res.objet + ' a bien été créée');
        this.toastr.info('Vous pouvez maintenant ajouter les tâches correspondantes à la demande');
      });
    });
  }

  onModalConfirm() {
    // tslint:disable-next-line: max-line-length
    this.data.demande.createurId = this.sessionService.currentUserValue.id;
    if (this.data.demande.dateIntervention) {
      this.data.demande.dateIntervention.setHours(0, 0, 0, 0);
    }

    if (this.data.demande) {
      if (this.data.demande.libelleAdresse || this.data.demande.adresse) {
        this.saveAddressSaveDemande();
      } else {
        // tslint:disable-next-line: max-line-length
        this.saveDemande();
      }
    }
  }

  parseResponse(response: any, libelle: string): string {
    let res = '________________________________________________ \n ';
    if (response !== null) {
      const array = response.response;
      const date = response.date;
      res = res + 'Formulaire : ' + libelle + '  ' + date + '\n';
      if (array.length !== 0 && array !== null) {
        for (let i = 0; i < array.length; i++) {
          const value = array[i].value.replaceAll('"', '');
          const question = array[i].questionName.replaceAll('?', '');
          if (value.length !== 0) {
            res = res + question + ' : ' + value + ' \n ';
          }
        }
        return res;
      } else { // pas de submit
        return '';
      }
    } else {
      this.toastr.warning('Erreur formulaire', 'Le lien du formulaire n\'est pas valide');
      return ' Collecte des emails non activé pour le formulaire  \n' + libelle;

    }
  }

  getResponses(lien: string, libelle: string): void {
    this.loading = true;
    this.demandeService.getFormulaireFromUrl(lien).subscribe(response => {
      if (this.data.demande.reponseForm !== null && this.data.demande.reponseForm !== undefined) {
        this.data.demande.reponseForm = this.parseResponse(response, libelle) + this.data.demande.reponseForm;
      } else {
        this.data.demande.reponseForm = this.parseResponse(response, libelle);
      }
      this.loading = false;
    });
  }

  onAddInterlocuteur(interlocuteur: InterlocuteurModel) {
    if (interlocuteur && interlocuteur !== undefined) {
      this.data.demande.interlocuteurId = interlocuteur.id;
      this.data.demande.nomInterlocuteur = interlocuteur.nom;
      this.data.demande.prenomInterlocuteur = interlocuteur.prenom;
      this.data.demande.roleInterlocuteur = interlocuteur.role;
      this.data.demande.emailInterlocuteur = interlocuteur.email;
      this.data.demande.telPrincipalInterlocuteur = interlocuteur.telPrincipal;
      this.data.demande.telSecondaireInterlocuteur = interlocuteur.telSecondaire;
      this.data.demande.commentaireInterlocuteur = interlocuteur.commentaire;
      // tslint:disable-next-line: max-line-length
      this.data.demande.civiliteInterlocuteur = interlocuteur.civiliteId ? this.civilites.find(civilite => civilite.id === interlocuteur.civiliteId).libelle : '';
      if (this.data.demande.nomInterlocuteur.trim().length === 0) {
        this.data.demande.nomInterlocuteur = undefined;
        this.data.demande.prenomInterlocuteur = undefined;
        this.data.demande.roleInterlocuteur = undefined;
        this.data.demande.emailInterlocuteur = undefined;
        this.data.demande.telPrincipalInterlocuteur = undefined;
        this.data.demande.telSecondaireInterlocuteur = undefined;
        this.data.demande.commentaireInterlocuteur = undefined;
        this.data.demande.civiliteInterlocuteur = undefined;
      }
    } else {
      this.data.demande.interlocuteurId = undefined;
    }
    if (this.objetAuto) {
      this.initTitleDemande();
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  createInterlocuteur() {
    const interlocuteur = new InterlocuteurModel();
    interlocuteur.id = undefined;
    interlocuteur.tierId = this.data.demande.tierId;
    interlocuteur.nom = this.data.demande.nomInterlocuteur;
    interlocuteur.prenom = this.data.demande.prenomInterlocuteur;
    interlocuteur.role = this.data.demande.roleInterlocuteur;
    interlocuteur.commentaire = this.data.demande.commentaireInterlocuteur;
    interlocuteur.telPrincipal = this.data.demande.telPrincipalInterlocuteur;
    interlocuteur.telSecondaire = this.data.demande.telSecondaireInterlocuteur;
    interlocuteur.email = this.data.demande.emailInterlocuteur;
    let civ: CiviliteModel;
    if (this.data.demande.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.demande.civiliteInterlocuteur.toLowerCase()) ;
        interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      interlocuteur.civiliteId = null;
    }
    interlocuteur.civilite = null;
    this.interlocuteurService.post(interlocuteur).subscribe((interloc: InterlocuteurModel) => {
      this.data.demande.interlocuteurId = interloc.id;
      this.createDemande();
    });
  }

  updateInterlocuteur() {
    const interlocuteurDemande = Object.assign({}, this.interlocuteurs.find(i => i.id === this.data.demande.interlocuteurId));
    interlocuteurDemande.nom = this.data.demande.nomInterlocuteur;
    interlocuteurDemande.prenom = this.data.demande.prenomInterlocuteur;
    interlocuteurDemande.email = this.data.demande.emailInterlocuteur;
    interlocuteurDemande.commentaire = this.data.demande.commentaireInterlocuteur;
    interlocuteurDemande.telPrincipal = this.data.demande.telPrincipalInterlocuteur;
    interlocuteurDemande.telSecondaire = this.data.demande.telSecondaireInterlocuteur;
    interlocuteurDemande.role = this.data.demande.roleInterlocuteur;
    interlocuteurDemande.civiliteId = this.civilites.find(civilite => civilite.libelle === this.data.demande.civiliteInterlocuteur).id;
    interlocuteurDemande.civilite = null;
    this.interlocuteurService.put(interlocuteurDemande).subscribe((interloc: InterlocuteurModel) => {
      this.createDemande();
    });
  }

  saveDemande() {
    if (this.data.demande.nomInterlocuteur) {
      if (this.data.demande.interlocuteurId) {
        this.createDemande();
      } else {
        this.createInterlocuteur();
      }
    } else {
      // Si dans la demande il y a une adresse et pas d'interlocuteur, alors création de la demande.
      this.createDemande();
    }
  }

  onAddAddress(adresse: SiteGeoModel) {
    if (adresse) {
      this.data.demande.siteGeoId = adresse.id;
      this.data.demande.libelleAdresse = adresse.libelle;
      this.data.demande.complementAdresse = adresse.complementAdresse;
      this.data.demande.adresse = adresse.adresse;
      this.data.demande.villeAdresse = adresse.ville;
      this.data.demande.codePostalAdresse = adresse.codePostal;
      this.data.demande.commentaireAdresse = adresse.commentaire;
      this.data.demande.agenceId = adresse.agenceId;
      this.data.demande.emailAdresse = adresse.email;
      this.data.demande.telephoneAdresse = adresse.telephone;
      this.data.demande.gpsAdresse = adresse.gps;
    } else {
      this.data.demande.siteGeoId = undefined;
      this.data.demande.libelleAdresse = undefined;
      this.data.demande.commentaireAdresse = undefined;
      this.data.demande.adresse = undefined;
      this.data.demande.villeAdresse = undefined;
      this.data.demande.codePostalAdresse = undefined;
      this.data.demande.complementAdresse = undefined;
      this.data.demande.emailAdresse = undefined;
      this.data.demande.telephoneAdresse = undefined;
      this.data.demande.gpsAdresse = undefined;    }
  }

  saveAddressSaveDemande() {
    if (!this.data.demande.siteGeoId) {
      const site = new SiteGeoModel();
      site.id = undefined;
      site.tierId = this.data.tierId;
      site.libelle = this.data.demande.libelleAdresse;
      site.adresse = this.data.demande.adresse;
      site.email = this.data.demande.emailAdresse;
      site.ville = this.data.demande.villeAdresse;
      site.codePostal = this.data.demande.codePostalAdresse;
      site.agenceId = this.data.demande.agenceId;
      site.complementAdresse = this.data.demande.complementAdresse;
      site.commentaire = this.data.demande.commentaireAdresse;
      site.telephone = this.data.demande.telephoneAdresse;
      site.gps = this.data.demande.gpsAdresse;
      this.siteService.post(site).subscribe((adresse: SiteGeoModel) => {
        this.data.demande.siteGeoId = adresse.id;
        this.saveDemande();
      });
    } else {
      this.saveDemande();
    }
  }

  /* recherche un protocole avec tiersId et adresse de la demande (siteGeo) et prestations et
         affecte le resultat au commentaire et au realisateur*/
  searchProtocole(): void {
    this.loading = true;
    const protocole = new ProtocoleDTO();
    protocole.tierId = this.data.demande.tierId;
    protocole.agenceId = this.sessionService.currentUserValue.agenceId;
    protocole.siteGeoId = this.data.demande.siteGeoId;
    protocole.ville = this.data.demande.villeAdresse;
    protocole.codePostal = this.data.demande.codePostalAdresse;
    if (this.demandePrestations.length > 0) {
        protocole.prestationId = Number(this.demandePrestations[0]);
    } else {
      protocole.prestationId = null;
    }
    this.protocoleService.GetFromObjet(protocole).subscribe((response: ProtocoleModel[]) => {
      if (protocole && response.length > 0) {
        this.data.demande.commercialId = response[0].realisateurId;
        this.commercialId = response[0].realisateurId;
        if (this.data.demande.commentaire) {
          this.data.demande.commentaire =  this.data.demande.commentaire + '\n' + response[0].protocole;
        } else {
          this.data.demande.commentaire =   response[0].protocole;
        }
        this.toastr.success('Recherche protocole mis à jour.');
      } else {
        this.toastr.warning('Pas de protocole trouvé.', 'Veuillez créer un protocole');
      }
    });

  }
  limitCommentTextArea(evt: KeyboardEvent) {

    let textarea = evt.target as any
    let maxRows: number = 24;

    let newContent: string = (textarea.value as string)
        .split('\n')
        .filter((val, row) => row < maxRows)
        .join('\n');

    textarea.value = newContent;
}

}
