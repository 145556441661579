import { Component, EventEmitter, Injector, OnInit, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActionService } from 'src/app/modules/core/services/action.service';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { FiltreService } from 'src/app/modules/core/services/filter.service';
import { TypeActionService } from 'src/app/modules/core/services/type-action.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { ActionDTO } from 'src/app/modules/core/shared/dtos/actionDTO';
import { FiltreActionDTO } from 'src/app/modules/core/shared/dtos/filterActionDTO';
import { ActionModel } from 'src/app/modules/core/shared/models/action.model';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { InterlocuteurModel } from 'src/app/modules/core/shared/models/interlocuteur.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { TypeActionModel } from 'src/app/modules/core/shared/models/type-action.model';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ModalSpinnerComponent } from '../../../divers/modals/modal-spinner/modal-spinner.component';
import { saveAs } from 'file-saver';
import { SocieteModel } from 'src/app/modules/core/shared/models/rsa/societe.model';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { SocieteService } from 'src/app/modules/core/services/rsa/societe.service';
import { CommercialModel } from 'src/app/modules/core/shared/models/users/commercial.model';
import * as _ from 'lodash';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { AutocompleteTiersComponent } from '../../../divers/autocompletes/autocomplete-tiers/autocomplete-tiers.component'; 

@Component({
  selector: 'app-search-action',
  templateUrl: './search-action.component.html',
  styleUrls: ['./search-action.component.scss']
})
export class SearchActionComponent implements OnInit {

  @Output() tierOpen = new EventEmitter<TierModel>();
  users: UserModel[] = [];
  user: UserModel;
  actionDatasource: ActionModel[] = [];
  action = new ActionDTO();
  selectedAction: ActionModel;
  panelOpenState = true;
  keyTitleArray = [
    {property: 'objetAction', libelle: 'objet'},
    {property: 'dateBiggerRealString', libelle: 'date réal'},
    {property: 'statut', libelle: 'statut'}];
  result: any;
  statuts = ['A traiter', 'En cours', 'Annulé', 'Clos'];
  typeActions: TypeActionModel[] = [];
  simpleQueryResponse =  new SimpleQueryResponse<ActionModel>();
  loading = false;
  length: number;
  pageIndex = 0;
  pageSize = 50;
  private modalsService: ModalsService;
  public dateSubject: Subject<Date>;
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  filtreData : FiltreActionDTO = new FiltreActionDTO();
  actionList : ActionModel[] = []
  simpleQueryResponse1 =  new SimpleQueryResponse<ActionModel>();
  private filteredSubscribe: Subscription;
  labelTiers = "Tiers"
  numDemandes : DemandeModel[];
  listStatus = ['A traiter','En cours', 'Annulé', 'Clos'];
  listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);
  listTypesActions :TypeActionModel[];
  listTypesActionsString :string[] = []
  listTypesActionsUpdated = new BehaviorSubject<string[]>(this.listTypesActionsString);
  resetSelect = true;
  numeroDemande : "";
  interlocuteurs : InterlocuteurModel[] = [];

  selectedSocieteIdsArray = new Set<number>();
  selectedAgenceIdsArray = new Set<number>();
  societes: SocieteModel[];
  agences: AgenceModel[];
  selectedSocietesString : string = "";

  eventsSubjectAgenceArray: Subject<Set<number>> = new Subject();
  eventsSubjectSocieteArray: Subject<Set<number>> = new Subject();

  selectedRealisateurIdsArray = new Set<number>();
  eventsSubjectRealisateurArray: Subject<Set<number>> = new Subject();
  selectedCreateurIdsArray = new Set<number>();
  eventsSubjectCreateurArray: Subject<Set<number>> = new Subject();

  nbFilterApplied: number = 0;

  @ViewChild('autocompleteTiers') autocompleteTiers: AutocompleteTiersComponent;

  constructor(public dialog: MatDialog,
    private actionService: ActionService,
    private userService: UserService,
    private demandeService: DemandeService,
    private typeActionservice: TypeActionService,
    private IntercomToastrService: IntercomToastrService,
    private filtreService: FiltreService,
    private changeDetector: ChangeDetectorRef,
    private agenceService: AgenceService,
    private societeService: SocieteService,
    injector: Injector,
    private cdr:ChangeDetectorRef,
    private toastrLoadingService: ToastrLoadingService,
    ) {
      this.modalsService = injector.get('ModalsService');
    }

    
  ngOnInit(): void {
    this.pageIndex =  0;
    this.action.typeActionId = null;
    this.action.statut = 'A traiter';
    this.typeActionservice.getAll().subscribe((typeActions: TypeActionModel[]) => {
      this.typeActions = typeActions;
      this.listTypesActions = typeActions
      typeActions.forEach(x=>this.listTypesActionsString.push(x.libelle))
    });
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
      if (this.action.rechercheTitle !== undefined) {
        this.rechercheAction(new SimpleQuery<ActionDTO>(this.action));
      }
    });
    this.societeService.getAllManaged(1).subscribe((societes) => {
      this.societes = (societes as SocieteModel[]).sort((a, b) => this.sociteteName(a).localeCompare(this.sociteteName(b)));
    });

    this.agenceService.getAllManaged(1).subscribe((agences) => {
      this.agences = (agences as AgenceModel[]).sort((a, b) => this.agenceName(a).localeCompare(this.agenceName(b)));
    });
    this.dateSubject = new Subject<Date>();
    this.dateSubject.pipe(debounceTime(100)).subscribe(_ => this.onClickSearchAction(this.action));
    this.filteredSubscribe = this.filtreService.filtreAction.pipe(debounceTime(100)).subscribe(result => {
      let idSearch: number;
      idSearch = this.toastrLoadingService.newSearch();

      this.filtreData = result;
      this.selectedSocieteIdsArray.clear();
      this.selectedSocietesString  = "";
      this.filtreData.societes.forEach((societe) => {
        this.selectedSocieteIdsArray.add(societe.id);
        this.selectedSocietesString += societe.id + ',';
      });
      this.eventsSubjectSocieteArray.next(this.selectedSocieteIdsArray);
      this.selectedAgenceIdsArray.clear();
      this.filtreData.agences.forEach((agence) => (this.selectedAgenceIdsArray.add(agence.id)));
      this.eventsSubjectAgenceArray.next(this.selectedAgenceIdsArray);

      this.selectedRealisateurIdsArray.clear();
      this.filtreData.realisateurs.forEach((real) => (this.selectedRealisateurIdsArray.add(real.id)));
      this.eventsSubjectRealisateurArray.next(this.selectedRealisateurIdsArray);
      this.selectedCreateurIdsArray.clear();
      this.filtreData.createurs.forEach((crea) => (this.selectedCreateurIdsArray.add(crea.id)));
      this.eventsSubjectCreateurArray.next(this.selectedCreateurIdsArray);

      if (result.status){
        this.listStatusUpdated.next(result.status)
      }

      var typesList:string[] = []
      if (result.typeActionId) {
      result.typeActionId.forEach(x=> typesList.push(this.typeActions.find(r=>r.id == x).libelle));
      this.listTypesActionsUpdated.next(typesList);
      }

      this.nbFilterApplied = this.countFilterApplied();

      this.actionService.getFiltredAction(this.filtreData).subscribe(x=> {
        this.actionList = x.objets;
        this.simpleQueryResponse1 = x;
        this.length = x.count;
        this.loading = false;
        this.toastrLoadingService.endSearch(idSearch);
        this.searchResult(x.objets);
      }, error => {
            this.toastrLoadingService.endSearch(idSearch);
          });
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.filteredSubscribe.unsubscribe();
  }

  resetSelects(){
    this.listTypesActionsString = []
    this.typeActionservice.getAll().subscribe((typeActions: TypeActionModel[]) => {
      this.typeActions = typeActions;
      this.listTypesActions = typeActions
      typeActions.forEach(x=>this.listTypesActionsString.push(x.libelle))
    });
    this.listTypesActionsUpdated = new BehaviorSubject<string[]>(this.listTypesActionsString);
    this.listStatus = ['A traiter','En cours', 'Annulé', 'Clos'];
    this.listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);

    this.resetSelect = true;
  }  
  
  clearFiltre(){
    this.resetSelect = false;
    this.filtreData = new FiltreActionDTO();
    this.filtreData.pageNumber = 0;
    this.filtreData.agences = [];
    this.filtreData.societes = [];
    this.filtreData.realisateurs = [];
    this.filtreData.createurs = [];
    this.autocompleteTiers.reinit();
    this.selectedAgenceIdsArray.clear();
    this.selectedSocieteIdsArray.clear();
    this.selectedRealisateurIdsArray.clear();
    this.selectedCreateurIdsArray.clear();
    this.selectedAgenceIdsArray = new Set();
    this.selectedSocieteIdsArray = new Set();
    this.selectedRealisateurIdsArray = new Set();
    this.selectedCreateurIdsArray = new Set();
    this.selectedSocietesString  = "";
    this.selectedSocietesString  = "";
    this.onChange(this.filtreData);
    this.resetSelects();
  }

  load(): void {
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
    });
  }

  loadData(value: string){
    if(value){
      this.demandeService.getAllFiltred(value).subscribe(x => {
        this.numDemandes = x;
      })
    }else{
      this.numDemandes = [];
    }
  }

  onTiersChanged(event, state) {
    switch(state){
      case 'Tier' :
        if(event){
          this.filtreData.tierId = event.id;
        }else{
          this.filtreData.tierId = null;
        }
        break;
    }
    this.onChange(event)
  }

  onSelectOptionChange(event, state){
    switch(state){
      case 'Statut' :
        this.filtreData.status = event;
        break;
      case 'Type':
        let temp = this.listTypesActions.filter(
          x => event.includes(x.libelle)
        )
        let temp1 = [] 
        temp.forEach(x=>temp1.push(x.id))
        this.filtreData.typeActionId = temp1;
        break;   
        
    }
    this.onChange(event)
  }

  onChange(event): void {
    this.selectedSocietesString  = "";
    this.filtreData.societes.forEach((societe) => {
      this.selectedSocieteIdsArray.add(societe.id);
      this.selectedSocietesString += societe.id + ',';
    });
    this.filtreData.agences.forEach((agence) => (this.selectedAgenceIdsArray.add(agence.id)));
    this.filtreData.realisateurs.forEach((real) => (this.selectedRealisateurIdsArray.add(real.id)));
    this.filtreData.createurs.forEach((crea) => (this.selectedCreateurIdsArray.add(crea.id)));
    this.filtreService.changeFiltreAction(this.filtreData);
    this.nbFilterApplied = this.countFilterApplied();
  }

  onClickSearchAction(action: ActionDTO) {
    this.rechercheAction(new SimpleQuery<ActionDTO>(action));
  }

  onTiersChange(value: string) {
    this.action.tierLibelle = value;
    this.rechercheAction(new SimpleQuery<ActionDTO>(this.action));
  }

  rechercheAction(query: SimpleQuery<ActionDTO>) {
    this.loading = true;
    if (this.action.typeActionId === 0) {
      this.action.typeActionId = null;
    }
    query.entry = this.action;
    this.actionService.getFromObjet(query).subscribe((response: SimpleQueryResponse<ActionModel>) => {
      this.actionDatasource = response.objets;
      this.length = response.count;
      this.simpleQueryResponse = response;
      this.loading = false;
      this.searchResult(response.objets);
    });
  }

  cleanAction() {
    this.action = new ActionDTO();
  }

  onTierOpen(tier: TierModel) {
    this.tierOpen.emit(tier);
  }

  onOpenAction(action: ActionModel): void {
    this.actionService.get(action.id).subscribe(action => {
      const simpleQuery = new SimpleQuery<ActionDTO>();
      simpleQuery.entry = this.action;
      simpleQuery.pageNumber = this.pageIndex;
      simpleQuery.pageSize = this.pageSize;
      simpleQuery.order = '';
      if (action.statut === 'Clos') {
        this.modalsService.openClosedAction(action).subscribe(result => {
          //action close immuable
        });
      } else {
        this.modalsService.openAction(action).subscribe(result => {
          if (result) {
            var actionModifIndex = this.actionList.findIndex(x => x.id == result.id)
            this.actionList[actionModifIndex] = result;
            this.actionList = Object.assign([], this.actionList);
          }
        });
      }
    });
  }

  setradio(id: number): void {
    this.action.typeActionId = id;
    this.rechercheAction(new SimpleQuery<ActionDTO>(this.action));
  }

  searchResult(searchWord: any) {
    if (searchWord.length === 0) {
      this.IntercomToastrService.warning('Oups : ' +  'Aucun résultat pour votre recherche');
    }
  }

  pageChange(pageEvent: SimpleQuery<any>) {
    const actionFilter = new SimpleQuery(new FiltreActionDTO());
    if(pageEvent.order){
      this.filtreData.order = pageEvent.order;
    }
    if(pageEvent){
      this.filtreData.descending = pageEvent.descending;
    }
    this.filtreData.pageNumber = pageEvent.pageNumber;
    this.filtreData.pageSize = pageEvent.pageSize;
    this.filtreService.changeFiltreAction(this.filtreData);

  }

  onExport(): void {
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    this.actionService.exportActions(this.filtreData).subscribe((res: BlobPart)  => {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res], {type: 'text/csv;charset=utf-8'});
      saveAs(blob, 'Export_Taches_' + new Date().toISOString() + '.csv');
      dialogRef.close();
    }, (error) => dialogRef.close());
  }

  societeChange(event) {
    if (event != undefined && event.length != 0) {
      let societes: SocieteModel[] = [];
      societes = this.societes.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.societes = [];
      this.filtreData.societes = societes;
      let agences : AgenceModel[] = [];
      agences = this.filtreData.agences.filter(item => event.includes(item.societeId));
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.societes = [];
      this.onChange(this.filtreData);
    }
  }

  agenceChange(event) {
    if (event != undefined && event.length != 0) {
      let agences: AgenceModel[] = [];
      agences = this.agences.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.agences = [];
      this.onChange(this.filtreData);
    }
  }

  agenceName(agence: AgenceModel) {
    return (agence.societe.libelle + " / " + agence.code + "-" + agence.libelle);
  }

  sociteteName(soc: SocieteModel) {
    return (soc.region.libelle + ' / ' + soc.code + '-' + soc.libelle);
  }

  realisateurChange(event) {
    if (event != undefined && event.length != 0) {
      let realisateurs: CommercialModel[] = [];
      realisateurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.realisateurs = [];
      this.filtreData.realisateurs = realisateurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.realisateurs = [];
      this.onChange(this.filtreData);
    }
  }

  createurChange(event) {
    if (event != undefined && event.length != 0) {
      let createurs: CommercialModel[] = [];
      createurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.createurs = [];
      this.filtreData.createurs = createurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.createurs = [];
      this.onChange(this.filtreData);
    }
  }

  countFilterApplied(): number {
    let nbFilter = 0;
    nbFilter += this.filtreData.agences?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.createurs?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.startDCreationAction !== null || this.filtreData.endDCreationAction !== null ? 1 : 0;
    nbFilter += this.filtreData.startDRealisationAction !== null || this.filtreData.endDRealisationAction !== null ? 1 : 0;
    nbFilter += this.filtreData.numDemande?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.objet?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.realisateurs?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.societes?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.status?.length > 0 && this.filtreData.status?.length < this.statuts.length ? 1 : 0;
    nbFilter += this.filtreData.tierId !== null ? 1 : 0;
    nbFilter += this.filtreData.typeActionId?.length > 0 && this.filtreData.typeActionId?.length < this.typeActions?.length ? 1 : 0;
    return nbFilter;
  }

}
