import { AgenceModel } from './rsa/agence.model';
import { SiteGeoModel } from './site-geo.model';
import { ActiviteModel } from './prestation.model';
import { UserModel } from './users/user.model';
import { TierBasicModel } from './basic-models/tier-basic.model';


export class ProtocoleModel extends TierBasicModel {
    public siteGeo?: SiteGeoModel;
    public siteGeoId: number;
    public prestation?: ActiviteModel;
    public prestationId: number = null;
    public realisateur?: UserModel;
    public realisateurId: number;
    public protocole: string;
    public agenceId: number;
    public agence: AgenceModel;
    public ville?: string = '';
    public codePostal?: string = '';
}
