<div class="col-md-12 body-class">
    <mat-card class="devis">
        <mat-card-content>

        <form [formGroup]="groupeDevis">
            <div class="row" style="display:flex;">
                <div class="col-sm-12 body-class" style="display:flex; justify-content: space-between;">
                    <h3 style="display:flex; align-items: start;">Prise d'appel devis</h3>
                    
                    <div class="col-sm-6">
                        <mat-form-field class="full-width">
                            <mat-label>Objet</mat-label>
                            <input matInput
                                placeholder="Objet" value=""
                                formControlName="objet"
                                name="intercom-objet-demande" />
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-flat-button color="primary"
                            (click)="clearTrieAndSubmit()"> 
                            <mat-icon>search</mat-icon> Chercher un tiers 
                        </button>
                    </div>
                    <div>
                        <button mat-flat-button [disabled]="!protocole || protocoleLoading" color="primary" (click)="onProtocolClicked()"
                            [matTooltip]="protocoleLoading ? 'Recherche de procole en cours' : protocole ? 'Un protocole a été trouvé' : 'Aucun protocole trouvé pour ces informations' " > 
                            <mat-icon>search</mat-icon> Protocole
                        </button>
                    </div>
                    <div>
                        <button mat-flat-button color="success" 
                            [disabled]="selectedTiersDevis.id == undefined || !prestationCtrl.valid || this.isSiteGeoSelectedNull || !this.demande.objet || !this.isPrestationValid || this.realisateurId == null" 
                            (click)="createDemande()"> <mat-icon>add</mat-icon> Créer le devis 
                        </button>          
                    </div>
                </div>
            </div>

            <div class="row" style="display:flex;">
                <div class="col-md-12  body-class" style="padding:0px !important;">
                    <form [formGroup]="tierRechercheForm" novalidate (submit)="onSubmitForm()" style="display: flex; justify-content: space-between;align-items: center;">
                        <button type="submit" style="display:none">hidden submit</button>
                            
                            <div class="col-md-4 data-class body-class" style="padding-top: 10px;">

                                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                                    <h4>Adresse</h4>
                                    <button type=button mat-flat-button (click)="clearForm('Adresse')">
                                        <mat-icon>settings_backup_restore</mat-icon> Vider la recherche
                                    </button>
                                </div>
                                <div class="col-md-8">
                                    <mat-form-field class="full-width">
                                        <input #addresstext matInput placeholder="Adresse" formControlName="address"  (keydown.enter)="$event.preventDefault();">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="full-width">
                                        <input  matInput placeholder="Libelle" formControlName="libelle">
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <app-autocomplete-code-postal 
                                        libelle="Code Postal" 
                                        (onUpdated)="onCodePostalChange($event)"
                                        [(selectedCity)]="selectedCity"
                                    >
                                    </app-autocomplete-code-postal>
                                </div>

                                <div class="col-md-6">
                                    <app-autocomplete-ville
                                        libelle="Ville"
                                        (onUpdated)="onCityChange($event)"
                                        [(selectedCity)]="selectedCity"
                                    >
                                    </app-autocomplete-ville>   
                                </div>
                            </div>
                            <div class="col-md-4 data-class body-class" 
                            style="padding-top: 10px; 
                            margin-left: 10px; margin-right: 10px;">

                                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                                    <h4>Contact</h4>

                                    <button type=button mat-flat-button 
                                        (click)="clearForm('Interlocuteur')">
                                        <mat-icon>settings_backup_restore</mat-icon> Vider la recherche
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Nom Interlocuteur"
                                            formControlName="nomInterlocuteur">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Prénom Interlocuteur"
                                            formControlName="prenomInterlocuteur">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Téléphone Interlocuteur"
                                            formControlName="telInterlocuteur">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-4 data-class body-class"
                                style="padding-top: 10px;">
                                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                                    <h4>Tiers</h4>

                                    <button type=button mat-flat-button (click)="clearForm('Tiers')">
                                        <mat-icon>settings_backup_restore</mat-icon> Vider la recherche
                                    </button>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Raison sociale / Nom prénom" formControlName="raisonSocial">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Id Jason" formControlName="idJason">
                                    </mat-form-field>
                                </div>
                            </div>
                    
                    </form>
                </div>
            </div>

            <div class="row" style="display:flex;">
                <div class="col-md-4 data-class body-class">
                    <div class="col-md-12" style="display: flex;justify-content: space-between;">
                        <h4>Devis</h4>
                    </div>
                    <div class="col-sm-4">
                        <app-autocomplete-realisateur [required]="true" libelle="Réalisateur" [users]="users" [commercialId]="realisateurId" (onUpdated)="onUpdateRealisateurId($event)"> 
                        </app-autocomplete-realisateur>
                    </div>
                    <mat-form-field  class="col-sm-4">
                        <mat-error *ngIf="!prestationCtrl.valid">
                            La prestation n'est pas paramétrée
                        </mat-error>
                        <input type= "text"
                            required
                            matInput
                            placeholder="Activité"
                            [formControl]="prestationCtrl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPrestation($event)">
                            <mat-option *ngFor="let prestation of filteredPrestations | async" [value]="prestation">
                                {{prestation}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    
                    <app-critere-calcul-devis 
                            [devisDTO]="devisDTO"
                            [site]="selectedSiteGeoDevis"
                            [prestationId]="prestationId"
                            [agenceId]="agenceId"
                            (isGroupValid)="isPrestationValid = $event"
                            (prestationNonParemetre)="prestationParametre($event)"
                            (critereUpdated)="critereUpdate($event)"
                            [tabIndex]="tabIndex">
                    </app-critere-calcul-devis>
                </div>

                <div class="col-md-4 data-class body-class"
                        style="margin-left: 10px; margin-right: 10px;">
                        
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <mat-label>Commentaire Interne</mat-label>
                                <textarea matInput 
                                    formControlName="commentaireDemande"
                                    style="height: 90px;">
                                </textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <mat-label>Information devis</mat-label>
                                <textarea matInput 
                                    formControlName="commentaireDevis"
                                    style="height: 50px;">
                                </textarea>
                            </mat-form-field>
                        </div>
                </div>
                
                <div class="col-md-4 data-class"
                    style="display: flex; flex-direction: column; align-items: start; justify-content: space-between;">

                    <div class="col-sm-12 body-class" style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                        <h4 style="display: flex; white-space: nowrap;">Tiers<div style="color:rgb(230, 126, 29)">*</div>: </h4>
                        <p style="text-align: end;"> {{selectedTiersDevis.libelle}}</p>
                    </div>

                    
                    <div class="col-sm-12 body-class" style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                        <h4 style="white-space: nowrap;">Tiers Facturé : </h4>
                        <p style="text-align: end;" [matTooltip]="tierFacture.adresse"> {{ tierFacture.libelle}}
                            <button *ngIf="selectedTiersDevis.id" mat-mini-fab color="primary" 
                                [matTooltip]="'Changer le tiers facturé'" 
                                (click)="onEditTiersFacture()">
                                <mat-icon style="color: white;"> edit </mat-icon>
                            </button> 
                        </p>
                    </div>
            

                    <div class="col-sm-12 body-class"  style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                        <h4 style="display: flex; white-space: nowrap;">Adresse<div style="color:rgb(230, 126, 29)">*</div>: 
                            <button *ngIf="timeTravel != ''" mat-mini-fab color="primary" 
                                [matTooltip]="timeTravel ? timeTravel : 'Afficher l\'itinéraire sur google map'" 
                                (click)="openGMap()">
                                <mat-icon style="color: white;"> pin_drop </mat-icon>
                            </button>
                        </h4> 
                        <p style="text-align: end;" *ngIf="!isSiteGeoSelectedNull">{{selectedSiteGeoDevis.libelle}}
                            {{selectedSiteGeoDevis.adresse}} {{selectedSiteGeoDevis.codePostal}}
                            {{selectedSiteGeoDevis.ville}} 
                        </p>
                    </div>
                    <div class="col-sm-12 body-class"  style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                        <h4 style="white-space: nowrap;">Contact : </h4>
                        <p style="text-align: end;" *ngIf="!isInterlocSelectedNull"> {{selectedInterlocDevis.nom}}
                            {{selectedInterlocDevis.prenom}} {{selectedInterlocDevis.telPrincipal}} 
                        </p>
                    </div>
                    
                    <app-estimation-devis [devisInfo]="devis">
                    </app-estimation-devis>
                </div>
            </div>
        </form>
            <div class="tabResponsives" *ngIf="!isFormEmpty">
                <app-basic-tier-array *ngIf="!IsWait"
                    [tierFilter]="tiersFilter"
                    [pageSize]="10"
                    [tierRequired]="true"
                    [origin]="'priseAppel'"
                    [adresseRequired]="true"
                    [contactRequired]="false"
                    [simpleQueryResponse]="pgQueryResponse"
                    [openTier]="false"
                    [selectedTiersDuplique]="selectedTiersDuplique"
                    [selectedSiteGeo]="selectedSiteGeoDevis"
                    [selectedInterlocuteur]="selectedInterlocDevis"
                    (tierOpen)="onTierOpen($event)" 
                    (pageChange)="pageChange($event)"
                    (selectTiers)="selectedTiers($event)"
                    (selectAdresseTier)="selectedAdress($event)"
                    (selectInterlocTier)="selectedInterloc($event)">
                </app-basic-tier-array>
            </div>

        </mat-card-content>
    </mat-card>
</div>