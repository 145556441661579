<div class="col-md-12 ">
    <div class="col-md-12" style="display: flex;justify-content: space-between;">
        <h4>Contact</h4>
        <button mat-mini-fab color="primary" *ngIf="modifiable">
            <mat-icon style="color: white;" (click)="editContact()" > create </mat-icon>
        </button>
    </div>
    <div class="col-md-2">
        <mat-form-field class="full-width">
            <mat-label>Civilité</mat-label>
            <input matInput [value]="interlocuteur.civilite ? interlocuteur.civilite.abreviation : ''" disabled>
          </mat-form-field>
      </div>
    <div class="col-md-5">
        <mat-form-field class="full-width">
            <mat-label>Nom</mat-label>
            <input matInput [value]="interlocuteur.nom ? interlocuteur.nom : ''" disabled>
          </mat-form-field>
    </div>
    <div class="col-md-5">
        <mat-form-field class="full-width">
            <mat-label>Prénom</mat-label>
            <input matInput [value]="interlocuteur.prenom ? interlocuteur.prenom : ''" disabled>
          </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field class="full-width">
            <mat-label>Fonction</mat-label>
            <input matInput [value]="interlocuteur.role ? interlocuteur.role : ''" disabled>
          </mat-form-field>
    </div>
    <div class="col-md-5">
        <app-input-telephone
      [(phoneNumber)]="interlocuteur.telPrincipal"
      (change)="selectedInterlocuteur.emit(this.interlocuteur)"
      libelle="Téléphone principal"
      [childFieldStatus]="true"
    ></app-input-telephone>
    </div>
    <div class="col-md-5">
        <mat-form-field class="full-width">
            <mat-label>E-mail</mat-label>
            <input matInput [value]="interlocuteur.email ? interlocuteur.email : ''" disabled>
          </mat-form-field>
    </div>
</div>