import { DatePipe } from "@angular/common";
import { ElementRef, Injectable, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import jsPDF, { AcroFormCheckBox } from "jspdf";
import autoTable from "jspdf-autotable";
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, tap, catchError, switchMap } from "rxjs/operators";
import { ModalSpinnerComponent } from "./components/modals/modal-spinner/modal-spinner.component";
import { DevisDTO } from "../devis/DTOs/devisDTO";
import { DocumentService } from "../devis/services/document.service";
import { DemandeModel } from "../core/shared/models/demande.model";
import { ActiviteModel } from "../core/shared/models/prestation.model";

@Injectable()
export class PdfService {

  @ViewChild('cgv') cgv: ElementRef;

  dialogRef: any;
    constructor(
        private datepipe: DatePipe,
        private IntercomToastrService: IntercomToastrService,
        private documentService: DocumentService,
        public dialog: MatDialog
    ) {
    
    }

    private formatDate(date: Date, h?: boolean, now?: boolean): string {
        return now ? (h ? this.datepipe.transform(date, 'dd/MM/yyyy HH:mm') : this.datepipe.transform(date, 'dd/MM/yyyy')) : (h ? this.datepipe.transform(date, 'dd/MM/yyyy HH:mm', 'UTC') : this.datepipe.transform(date, 'dd/MM/yyyy', 'UTC'));
    }

    printDevisRapide(devis: DevisDTO){
      const dialogRef = this.dialog.open(ModalSpinnerComponent);
      var erreur = false;
      combineLatest([
        this.documentService.getDoc(devis.urlLogo).pipe(
          catchError(e => {
          this.IntercomToastrService.error("Erreur dans la récupération du logo pour l’affichage du devis.", null, {timeOut: 10000});
          erreur = true;
          return of(null);}
        )), 
        this.documentService.getDoc(devis.urlActivite).pipe(
          catchError(e => {
          this.IntercomToastrService.error("Erreur dans la récupération de l’image d’activité pour l’affichage du devis.", null, {timeOut: 10000});
          erreur = true;
          return of(null);}
        )),  
        this.documentService.getDoc(devis.urlCertification1).pipe(
          catchError(e => {
          this.IntercomToastrService.error("Erreur dans la récupération de la première certification pour l’affichage du devis.", null, {timeOut: 10000});
          erreur = true;
          return of(null);}
        )),  
        this.documentService.getDoc(devis.urlCertification2).pipe(
          catchError(e => {
          this.IntercomToastrService.error("Erreur dans la récupération de la seconde certification pour l’affichage du devis.", null, {timeOut: 10000});
          erreur = true;
          return of(null);}
        )), 
        this.documentService.getDoc(devis.signature).pipe(
          catchError(e => {
          this.IntercomToastrService.error("Erreur dans la récupération de la signature pour l’affichage du devis.", null, {timeOut: 10000});
          erreur = true;
          return of(null);}
        ))
      ])
      .pipe(
        tap(([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature]) => {
          if (!urlactivite){
            this.IntercomToastrService.warning('Le fichier devis n\'est pas crée', 'Veuillez configurer l\'image Activité', {timeOut: 10000});
          }
          return ([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature]);
        }),
        filter(([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature]) => {
          if (urlactivite === null || erreur) {
            dialogRef.close();
          }
          return urlactivite !== null && !erreur
        }),
      ).subscribe(([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature])=> {
          var logo = urllogo ? URL.createObjectURL(urllogo) : null;
          var activite = URL.createObjectURL(urlactivite);
          var certification1 = urlcertification1 ? URL.createObjectURL(urlcertification1) : '';
          var certification2 = urlcertification2 ? URL.createObjectURL(urlcertification2) : '';
          var signature = urlSignature ? URL.createObjectURL(urlSignature) : null;
          const doc = new jsPDF('p', 'mm', 'a4', true);
          doc.setProperties({
            title: 'Devis rapide'
          });
          this.page1(doc, logo, devis, activite, certification1, certification2);
          this.page2(doc, devis);
          this.page3(doc, logo, devis, signature);
          this.page4(doc, devis);
          window.open(doc.output('bloburl').toString(), '_blank');
          dialogRef.close();
      });
    }

    printDemande(demande: DemandeModel, logo: string, prestations: ActiviteModel[], devis: string){
      combineLatest([
        this.documentService.getDoc(logo)]) 
      .pipe(
        tap(([urllogo]) => {
          if(!urllogo){
            //this.IntercomToastrService.warning('Le pdf demande n\'est pas généré', 'Veuillez configurer le logo agence');
          }
          return ([urllogo]);
        }),
      ).subscribe(([urllogo])=> {
        var logo = null;
        if(urllogo)
          logo = URL.createObjectURL(urllogo);
        const doc = new jsPDF('p', 'mm', 'a4', true);
        doc.setProperties({
          title: 'Demande'
        });
        this.pageDemande(doc, logo, demande, prestations, devis);
        window.open(doc.output('bloburl').toString(), '_blank');
      });    
    }

    getBlobPdfDemande(demande: DemandeModel, logo: string, prestations: ActiviteModel[], devis: string): Observable<Blob>{
      return this.documentService.getDoc(logo).pipe(switchMap((urllogo) => {
        var logo = null;
        if(urllogo)
          logo = URL.createObjectURL(urllogo);
        const doc = new jsPDF('p', 'mm', 'a4', true);
        doc.setProperties({
          title: 'Demande'
        });
        this.pageDemande(doc, logo, demande, prestations, devis);
        var blobPDF = doc.output('blob');
        return of(blobPDF);
      }));
    }


    getFileDevisRapide(devis: DevisDTO): Observable<File>{
      return combineLatest([
        this.documentService.getDoc(devis.urlLogo), 
        this.documentService.getDoc(devis.urlActivite), 
        this.documentService.getDoc(devis.urlCertification1), 
        this.documentService.getDoc(devis.urlCertification2), 
        this.documentService.getDoc(devis.signature)])
        .pipe(
          tap(([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature]) => {
            if(!urlactivite){
              this.IntercomToastrService.warning('Le fichier devis n\'est pas crée, nous n\'avons pas pu générer l\'email', 'Veuillez configurer l\'image Activité');
            }
            return ([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature]);
          }),
          map(([urllogo, urlactivite, urlcertification1, urlcertification2, urlSignature]) => {
          if(urlactivite){
            var logo = urllogo ? URL.createObjectURL(urllogo) : null;
            var activite = URL.createObjectURL(urlactivite);
            var certification1 = urlcertification1 ? URL.createObjectURL(urlcertification1) : '';
            var certification2 = urlcertification2 ? URL.createObjectURL(urlcertification2) : '';
            var signature = urlSignature ? URL.createObjectURL(urlSignature) : null;
            const doc = new jsPDF('p', 'mm', 'a4', true);
            doc.setProperties({
              title: 'Devis rapide'
            });
            this.page1(doc, logo, devis, activite, certification1, certification2);
            this.page2(doc, devis);
            this.page3(doc, logo, devis, signature);
            this.page4(doc, devis);
            return new File([doc.output('blob')], "test");
          }else {
            return null;
          }
        }));
    }

    pageDemande(doc: jsPDF, logo: string, demande: DemandeModel,  prestations: ActiviteModel[], devis: string) {
      let activites = prestations.length > 0 ? prestations[0].libelle : "";
      for(let i = 1; i < prestations.length; i++)
      {
       activites += ", " + prestations[i].libelle ;
      }
      var page =  "./../../../../../assets/imgs/maquette_demande.jpg";
      var client =  "./../../../../../assets/imgs/client.jpg";
      var section_client =  "./../../../../../assets/imgs/section_client.PNG";
      var section_demande =  "./../../../../../assets/imgs/section_demande.PNG";
      var section_demande_2 =  "./../../../../../assets/imgs/section_demande_2.png";
      var section_tache =  "./../../../../../assets/imgs/section_tache.PNG";
      var default_logo = "./../../../../../assets/imgs/logo-sarp.png";

      /*var icon_client =  "./../../../../../assets/imgs/client.png";
      var icon_demande =  "./../../../../../assets/imgs/resume.png";
      var icon_tache =  "./../../../../../assets/imgs/taches.png";*/
      doc.addImage(page, 'JPEG', 0, 0, doc.internal.pageSize.width , doc.internal.pageSize.height, undefined,'FAST');
      doc.addImage(client, 'JPEG', 11, 6, 105, 23, undefined,'FAST');
      logo ? doc.addImage(logo, 'JPEG', 122, 3.5, 80, 30, undefined,'FAST') : doc.addImage(default_logo, 'JPEG', 122, 3.5, 80, 30, undefined,'FAST');

      doc.setFontSize(14);
      doc.setTextColor('#f39800');
      doc.text(" " + demande.numDemande, 34, 24, {
        maxWidth: 60,
      });

      doc.addImage(section_client, 'JPEG', 8, 35, doc.internal.pageSize.width - 15, 86, undefined,'FAST');
      //doc.addImage(icon_client, 'JPEG', 180, 38, 15, 15);

      doc.setFontSize(10.5);
      doc.setTextColor('#000000');
      doc.text(
        'Libellé : ' + demande.tier.libelle + '\n' +
        'Adresse : ' + (demande.tier.complementAdresse ? demande.tier.complementAdresse : "") + " " + (demande.tier.adresse ? demande.tier.adresse : "") + " " + (demande.tier.codePostal ? demande.tier.codePostal : "") + " " + (demande.tier.ville ? demande.tier.ville : "") + '\n' +
        'Mail : ' + (demande.tier.email ? demande.tier.email : "") + '\n' +
        'Téléphone : ' + (demande.tier.telephone ? demande.tier.telephone : "") + '\n' +
        'Intercom ID : ' + demande.tier.id + '\n' +
        'Jason ID : ' + (demande.tier.identifiantJason ? demande.tier.identifiantJason : "")
        , 14, 59, {
        maxWidth: 83,
      });

      if(demande.tierFacture){
        doc.text(
          'Libellé : ' + (demande.tierFacture.libelle ? demande.tierFacture.libelle : "") + '\n' +
          'Adresse : ' + (demande.tierFacture.adresse ? ((demande.tierFacture.complementAdresse ? demande.tierFacture.complementAdresse : "") + " " + demande.tierFacture.adresse + " " + demande.tierFacture.codePostal + " " + demande.tierFacture.ville) : "") + '\n' +
          'Intercom ID : ' + demande.tierFacture.id + '\n' +
          'Jason ID : ' + (demande.tierFacture.identifiantJason ? demande.tierFacture.identifiantJason : "")
          , 103, 59, {
          maxWidth: 95,
        });
      }else {
        doc.text(
          'Libellé : '  + '\n' +
          'Adresse : '  + '\n' +
          'Intercom ID : ' + '\n' +
          'Jason ID : ' 
          , 103, 61, {
          maxWidth: 80,
        });
      }
      
      doc.text(
        (demande.civiliteInterlocuteur ? (demande.civiliteInterlocuteur + ". ") : "") +  (demande.prenomInterlocuteur ? (demande.prenomInterlocuteur + " ") : "")  + demande.nomInterlocuteur + '\n' +
        demande.roleInterlocuteur + '\n' +
        'Téléphone : ' + (demande.telPrincipalInterlocuteur ? demande.telPrincipalInterlocuteur : "") + '\n' +
        'Mail : ' + (demande.emailInterlocuteur ? demande.emailInterlocuteur : "")
        , 103, 93, {
        maxWidth: 95,
      });
      
      let realLinesCount = 0;
      if (demande.commentaire) {
        let comLines = demande.commentaire.split(/\r\n|\r|\n/);
        realLinesCount = 0;
        for (let line of comLines) {
          let subLinesCount = line.length / 84; //Une ligne = 84 chr         1.111
          if (subLinesCount === 0) {
            subLinesCount = 1;
          }
          realLinesCount += Math.ceil(subLinesCount);
        }
      }
      if(!demande.commentaire || realLinesCount < 7) {
        doc.addImage(section_demande, 'JPEG', 8, 120, doc.internal.pageSize.width - 15, 90, undefined,'FAST');
      } else {
        doc.addImage(section_demande_2, 'JPEG', 8, 120, doc.internal.pageSize.width - 15, 165, undefined,'FAST');
      }

      //doc.addImage(icon_demande, 'JPEG', 180, 122, 15, 15, undefined,'FAST');

      doc.setFontSize(11);
      doc.setTextColor('#000000');
      doc.text(
        'Objet : ' + demande.objet + '\n' +
        'Créateur : ' + demande.createur.nom + " " + demande.createur.prenom + '\n' +
        'Date de création : ' + (demande.dateCreation ? this.formatDate(demande.dateCreation , true , true): this.formatDate(new Date, true , true)) + '\n' +
        'Type : ' + demande.typeDemande.libelle + '\n' +
        'Date d’échéance : ' + (demande.dateIntervention ? this.formatDate(demande.dateIntervention , true , true): this.formatDate(new Date, true , true)) + '\n' +
        'Devis : ' + devis + '\n' +
        'Activité : ' + activites + '\n' +
        'Réalisateur : ' + demande.commercial.nom + " " + (demande.commercial.prenom ? demande.commercial.prenom : "") + '\n' +
        (demande.gestionnaire != null ?  ('Gestionnaire : ' + demande.gestionnaire.nom + " " + (demande.gestionnaire.prenom ? demande.gestionnaire.prenom : "") + '\n') : "" )+
        'Adresse d’intervention : ' + (demande.complementAdresse ? demande.complementAdresse : "") + " " + (demande.adresse ? demande.adresse : "") + " " + (demande.codePostalAdresse ? demande.codePostalAdresse : "") + " " + (demande.villeAdresse ? demande.villeAdresse : "") + '\n' +
        (demande.commentaireAdresse ? 'Commentaire de l\'adresse : ' + demande.commentaireAdresse + '\n' : "") +
        'Commentaire : ' + (demande.commentaire ? demande.commentaire : "")
        , 14, 135, {
        maxWidth: doc.internal.pageSize.width - 30
        });


        if(!demande.commentaire || realLinesCount < 7) {
          
          doc.addImage(section_tache, 'JPEG', 8, 209, doc.internal.pageSize.width - 15, 73, undefined,'FAST');
        }else {
          doc.addPage()
          doc.addImage(page, 'JPEG', 0, 0, doc.internal.pageSize.width , doc.internal.pageSize.height, undefined,'FAST');
          doc.addImage(section_tache, 'JPEG', 8, 10, doc.internal.pageSize.width - 15, 73, undefined,'FAST');
          doc.addImage(section_tache, 'JPEG', 11, 10, doc.internal.pageSize.width - 15, 73, undefined,'FAST');

        }

  
      //doc.addImage(icon_tache, 'JPEG', 180, 212, 15, 15, undefined,'FAST');

      if(demande.actions.length > 0){
        let bodyData = [];
      
        for(let i = 0; i < demande.actions.length; i++)
        {
          let rowData = [];
          rowData[0] = demande.actions[i].objetAction;
          rowData[1] = (demande.actions[i].dateCreation ? this.formatDate(demande.actions[i].dateCreation , true , true): "");
          rowData[2] = (demande.actions[i].dateCloture ? this.formatDate(demande.actions[i].dateCloture , true , true): ""); 
          rowData[3] = demande.actions[i].realisateur ? (demande.actions[i].realisateur.nom + " " + demande.actions[i].realisateur.prenom) : "" ;
          rowData[4] = "(" + demande.actions[i].statut + ") \n"+ demande.actions[i].commentaire ;
          bodyData.push(rowData);
        }
  
        if(!demande.commentaire || realLinesCount < 7) {
          
          autoTable(doc, {
            head: [['Objet', 'Date création', 'Date cloture', "Réalisateur", "commentaire"]],
            body: bodyData,
            startY: 228,
            tableWidth: doc.internal.pageSize.width - 26,

          })
        }else {
          autoTable(doc, {
            head: [['Objet', 'Date création', 'Date cloture', "Réalisateur", "commentaire"]],
            body: bodyData,
            startY: 25,
            tableWidth: doc.internal.pageSize.width - 22,
          })

        }

      }
      


      doc.text( "Édité : " +  this.formatDate(new Date, true , true)
        , 150, 290, {
        maxWidth: 55,
      });
      
  }

    page1(doc: jsPDF, logo: string, devis: DevisDTO, activite: string, certification1: string, certification2:string) {
        var page =  "./../../../../../assets/imgs/page1.jpg";
        var default_logo =  "./../../../../../assets/imgs/logo-sarp.png";
        doc.addImage(page, 'JPEG', 0, 0, doc.internal.pageSize.width , doc.internal.pageSize.height, undefined,'FAST');
        logo ? doc.addImage(logo, 'JPEG', 5, 5, 80, 30, undefined,'FAST') : doc.addImage(default_logo, 'JPEG', 5, 5, 80, 30, undefined,'FAST');
        
        autoTable(doc, {
            head: [[
              {
                content: 'VOTRE CONTACT : ',
              }
            ]],
            body: [
              [
                {
                  content: 
                  ((devis.nomRealisateur && devis.prenomRealisateur) ? (devis.nomRealisateur + ' ' + devis.prenomRealisateur + '\n'): '\n' ) +
                  (devis.telRealisateur ? (devis.telRealisateur + '\n') : '\n') +
                  (devis.emailRealisateur ? (devis.emailRealisateur + '\n') : '\n' ),
                }
              ],
            ],
            theme: 'plain',
            headStyles: {
                fontSize: 16
            },
            bodyStyles: {
                fontSize: 10
            },
            styles: {
              halign: 'right',
              textColor: "#004c97"
            },
            margin: {
                right: 40,
                top: 8
            }
        });

        doc.setFontSize(10);
        doc.setTextColor('#6a6869');
        if(devis.typeTiers !== 'Particulier') {
          doc.text(
            (devis.raisonSociale ? (devis.raisonSociale + '\n') : '\n') +
            (devis.adresse ? (devis.adresse + '\n'): '\n') +
            (devis.codePostal ? (devis.codePostal + ' '): '')+
            (devis.ville ? (devis.ville + '\n\n'): '\n\n' ) + 
            'À l\'attention de M./Mme.\n' +
            (devis.nomInterlocuteur ? devis.nomInterlocuteur + ' ' : '') +
            (devis.prenomInterlocuteur ? devis.prenomInterlocuteur: '') + '\n' +
            (devis.roleInterlocuteur ? devis.roleInterlocuteur + '\n': '\n') +
            (devis.telInterlocuteur ? devis.telInterlocuteur : '') + '\n' +
            (devis.emailInterlocuteur ? devis.emailInterlocuteur : '')
            , 20, 65, {
            maxWidth: 75,
          });
        } else {
          doc.text(
            (devis.raisonSociale ? (devis.raisonSociale + '\n') : '\n') +
            (devis.adresse ? (devis.adresse + '\n'): '\n') +
            (devis.codePostal ? (devis.codePostal + ' '): '')+
            (devis.ville ? (devis.ville + '\n'): '\n' ) + 
            (devis.telInterlocuteur ? devis.telInterlocuteur : '') + '\n' +
            (devis.emailInterlocuteur ? devis.emailInterlocuteur : '')
            , 20, 65, {
            maxWidth: 75,
          });
        }

        doc.setFontSize(10);
        doc.setTextColor('#6a6869');
        doc.text(
          (devis.adresseChantier ? (devis.adresseChantier + '\n'): '\n' ) +
          (devis.codePostalChantier ? (devis.codePostalChantier + ' ') : '' ) +
          (devis.villeChantier ? (devis.villeChantier + '\n'): '\n') +
          ('Information(s) supplémentaire(s) : \n') +
          (devis.commentaireChantier ? devis.commentaireChantier : '')
          , 115, 65, {
          maxWidth: 75,
        });


        doc.setFontSize(12);
        doc.setTextColor('#004c97');
        doc.text(
          'Proposition commerciale : ' + (devis.objet ? devis.objet : '') + '\n' +
          'Réfèrence : ' + (devis.code ? devis.code : 'XXXXXXXXXXX') + '\n' +
          'Date : ' + (devis.dModification ? this.formatDate(devis.dModification, false , true): this.formatDate(new Date, false , true)) + '\n' +
          'Validité de l\'offre : 2 mois'
          , 20, 120, {
          maxWidth: 160,
        });
        
        activite ? doc.addImage(activite, "JPEG", 15, 150, 180, 80, undefined,'FAST') : '';

        doc.setFontSize(10);
        doc.setFont('bold', 'bold')
        doc.setTextColor('#004c97');
        doc.text(
          'SARP ' + (devis.libelleSociete ? devis.libelleSociete + '\n' : '') +
          (devis.agenceLibelle ? (devis.agenceLibelle) : '')
          , 25, 245, {
          maxWidth: 90,
        });
        doc.setFontSize(10);
        doc.setFont('normal', 'normal')
        doc.setTextColor('#6a6869');
        doc.text(
          (devis.agenceAdresse ? (devis.agenceAdresse + '\n'): '\n')+
          (devis.agenceCodePostal ? (devis.agenceCodePostal + ' ') :'')+
          (devis.agenceVille ? (devis.agenceVille + '\n') : '\n')+
          ('Téléphone : ' + (devis.agenceTelephone ? (devis.agenceTelephone + '\n\n') : '\n\n')) +
          'RCS '+ (devis.rcs? devis.rcs: '') + ' B' + (devis.sirenSociete? devis.sirenSociete : '') + ' - NAF ' + (devis.nafAgence? devis.nafAgence + '\n' : '\n')
          , 25, 255, {
          maxWidth: 90,
        });
        certification1 ? doc.addImage(certification1, "JPEG", 130, 240, 30, 30, undefined,'FAST') : '';
        certification2 ? doc.addImage(certification2, "JPEG", 170, 240, 30, 30, undefined,'FAST') : '';
    }

    page2(doc: jsPDF, devis: DevisDTO) {
        doc.addPage();
        var page =  "./../../../../../assets/imgs/page2.jpg";
        doc.addImage(page, 'JPEG', 0, 0, doc.internal.pageSize.width , doc.internal.pageSize.height, undefined,'FAST');
        autoTable(doc, {
            body: [['La maintenance de vos ouvrages d\'assainissement en toute confiance']],
            theme: 'plain',
            styles: {
                halign: 'left',
                fontSize: 12,
                textColor: '#004c97'
            }
        });
        
        // PRESTATION
        autoTable(doc, {
            head:[['\n\n\n\nNOTRE PRESTATION POUR ' + (devis.prenomInterlocuteur? devis.prenomInterlocuteur : '')]],
            body: (devis.prestationsClient && devis.prestationsClient.length > 0) ? this.transformsRow(0, devis.prestationsClient[0].lignes.length, devis) : [[]],
            theme: 'plain',
            headStyles: {
              fontSize: 20,
            },
            bodyStyles: {
              fontSize: 10,
            },
            styles: {
                halign: 'left',
            },
        });
        // Description
        autoTable(doc, {
          head: [[{
            content: 'DESCRIPTION : ',
            styles: {
              fillColor: '#F5F5F5',
              textColor: 'black'
            }
          }]],
          body: [
            [
              {
                content: devis.descriptionOuvrage ? devis.descriptionOuvrage : '' ,
                styles: {
                  fillColor: '#FFFFFF'
                }
              }
            ],
          ],
          styles: {
            halign: 'left',
            fontSize: 10,
          },
        });

      // Intervention
      autoTable(doc, {
        head: [[{
          content: 'CONDITIONS D\'INTERVENTION : ',
          styles: {
            fillColor: '#F5F5F5',
            textColor: 'black'
          }
        }]],
        body: [
          [
            {
              content: (devis.modalite ? devis.modalite +'\n': '\n')
              + 'Information complémentaire : \n' 
              + (devis.demandeClient ? devis.demandeClient : ''),
              styles: {
                fillColor: '#FFFFFF'
              }
            }
          ],
        ],
        styles: {
          halign: 'left',
          fontSize: 10,
        }
      });

      if(devis.chargeClient) {
        autoTable(doc, {
          head:[[{
            content: 'A LA CHARGE DU CLIENT : ',
            styles: {
              fillColor: '#F5F5F5',
              textColor: 'black'
            }
          }]],
          body: [
            [
              {
                content: devis.chargeClient ? devis.chargeClient : '',
                styles: {
                  fillColor: '#FFFFFF'
                }
              }
            ],
          ],
          styles: {
            halign: 'left',
            fontSize: 10,
          }
        });
      }

      autoTable(doc, {
        body: [
          [
            {
              content: 'Montant TTC',
              styles: {
                fontSize: 10,
                fontStyle: 'bold',
                textColor: 'black'
              }
            },
            {
              content: (devis ? devis.ttc : 0) + '€ TTC' ,
              styles:{
                halign:'right',
                textColor : '#004c97',
                fontSize: 16
              }
            }],
            [
              {
                content: 'Montant HT',
                styles: {
                  fontSize: 10,
                  textColor: 'black'
                }
              },
              {
                content: (devis ? devis.ht : 0) + '€ HT' ,
                styles:{
                  halign:'right',
                  textColor : '#004c97',
                  fontSize: 10
                }
              },
            ],
          [{
            content: 'Montant TVA',
            styles: {
              fontSize: 10,
              textColor: 'black'
            }
          },
            {
              content: ((devis && devis.tvaPercent) ? Math.round(devis.ht * devis.tvaPercent) / 100 : 0) + '€ TVA' ,
              styles:{
                halign:'right',
                textColor : '#004c97',
                fontSize: 10
              }
            },
          ],
          [
            {
              content: '(Régler sur le lieu d\'intervention, si vous payez par chèque, veuillez mettre à l\'ordre de ' + (devis.libelleSociete ? devis.libelleSociete + ')' : ')'),
              styles: {
                fontSize: 8
              }
            },
            {
              content: 'Taux de TVA de ' + (devis.tvaPercent ? devis.tvaPercent : '20') + '% \n'
              + (devis.introduction ? devis.introduction : ''),
              styles: {
                halign: 'right',
                fontSize: 8,
                textColor : '#004c97'
              }
            }
          ]
        ],
        styles: {
          fillColor: '#F5F5F5'
        }
      });
    }

    page3(doc: jsPDF, logo: string, devis: DevisDTO, signature: string) {
      doc.addPage();
      var page =  "./../../../../../assets/imgs/page3.jpg";
      doc.addImage(page, 'JPEG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, undefined,'FAST');
      var activite = devis.libelleActivite === 'Entretien Cuve à Fioul' ? './../../../../../assets/imgs/Prestations-supplementaires-1.jpg' : (devis.libelleActivite === 'Diagnostic' ? './../../../../../assets/imgs/Prestations-supplementaires-2.jpg' : (devis.libelleActivite === 'Débouchage' ? './../../../../../assets/imgs/Prestations-supplementaires-3.jpg' : './../../../../../assets/imgs/Prestations-supplementaires-4.jpg' ));
      doc.addImage(activite, 'JPEG', 15, 0, 180, 120, undefined,'FAST');
      autoTable(doc, {
        head: [[
          {
            content: 'PARCE QUE VOTRE AVIS COMPTE',
            styles: {
              fontSize: 16,
              fontStyle: 'bold',
              cellPadding: {
                bottom : 0,
                left: 2
              }
            }
          }
        ], [
          {
            content: 'Chez SARP, nous protégeons aussi bien vos ouvrages d\'assainissement que vos données personnelles',
            styles:{
              fontSize: 10,
              fontStyle: 'normal',
              cellPadding: {
                top:0,
                left: 2
                
              }
            },
          }
        ]],
        body: [
          [
            {
              content: 'Nous collectons des données dans le cadre de développement commercial à savoir promouvoir nos savoir-faire et attirer de nouveaux clients ou pour réaliser des enquêtes de satisfaction.',
            }
          ]
        ],
        theme: 'plain',
        headStyles: {
          textColor:'#004c97',
        },
        bodyStyles: {
          fontSize: 10,
        },
        styles: {
          halign: 'left',
        },
        margin: {
          top: 130,
          left: 15,
          right: 5,
          bottom: 0
        }
      });
      var checkBox1 = new AcroFormCheckBox();
      checkBox1.fieldName = "CheckBox1";
      checkBox1.x = 75;
      checkBox1.y = 160;
      checkBox1.height = 4;
      checkBox1.width = 4;
      checkBox1.appearanceState = 'Off';
      doc.setDrawColor('#000000');
      doc.setFillColor('#000000');
      doc.rect(75, 160, 4, 4);
      doc.addField(checkBox1);
      var checkBox2 = new AcroFormCheckBox();
      checkBox2.fieldName = "CheckBox2";
      checkBox2.x = 75;
      checkBox2.y = 168
      checkBox2.height = 4;
      checkBox2.width = 4;
      checkBox2.appearanceState = 'Off';
      doc.setDrawColor('#000000');
      doc.setFillColor('#000000');
      doc.rect(75, 168, 4, 4);
      doc.addField(checkBox2);
      autoTable(doc, {
        body: [
          [
            {
              content: 'J\'accepte que mes informations soient utilisées pour de la prospection commerciale.',
            },
          ],
          [
            {
              content: 'J\'accepte que mes informations soient utilisées pour des enquêtes de satisfaction.'
            }
          ]
        ],
        theme: 'plain',
        styles: {
          halign: 'left',
          cellPadding: 0,
          fontSize: 10,
        },
        margin: {
          top: 0,
          left: 80,
          bottom: 0
        }
      });
      var checkBox3 = new AcroFormCheckBox();
      checkBox3.fieldName = "CheckBox3";
      checkBox3.x = 16.5;
      checkBox3.y = 211.5
      checkBox3.height = 3;
      checkBox3.width = 3;
      checkBox3.appearanceState = 'Off';
      doc.setDrawColor('#000000');
      doc.setFillColor('#000000');
      doc.rect(16.5, 211.5, 3, 3);
      doc.addField(checkBox3);
      autoTable(doc, {
        body: [
          [
            {
              content: 'Si vous ne souhaitez plus recevoir de publicité de la part de la société SARP ou  pour toute question sur le traitement de vos données dans ce dispositif, ou pour exercer vos droits (rectification de vos données, effacement de vos données ou exercer votre droit à la limitation du traitement de vos données, etc) vous pouvez contacter nos DPO à l\'adresse suivante : dpo.sarp@veolia.com.',
            },
            
          ]
        ],
        theme: 'plain',
        styles: {
          halign: 'left',
          fontSize: 10,
        },
        margin: {
          left: 15
        }
      });
      

      autoTable(doc, {
        body: [
          [
            {
              content: '      Je déclare avoir pris connaissance et accepté les conditions générales de vente jointes au devis.',
              styles: {
                halign: 'left'
              },
              colSpan: 2
            },
          ],
          [
            {
              content: 'Fait le ' + this.formatDate(new Date(), false, true) + ', à ..............................',
              styles: {
                halign: 'left'
              }
            }, ''
          ],
          [
            {
              content: 'Signature client \n\n\n\n\n'
            },
            {
              content: 'Signature entreprise \n\n\n\n\n'
            }
          ],
          [
            {
              content: 'Signature précédée de la mention\n\"Bon pour accord, Lu et approuvé\"',
              styles: {
                fontSize: 8
              }
            },
            ''
          ]
        ],
        styles: {
          halign: 'left',
          fontSize: 10,
          fillColor: '#F5F5F5'
        },
        margin: {
          top: 0,
          left: 15,
          right: 5,
          bottom: 0
        }
      });
      if(signature) {
        doc.addImage(signature, 'JPEG', 135, 236, 40, 20, undefined,'FAST');
      }
      autoTable(doc, {
        body: [
          [
            {
              content: (devis.libelleSociete? devis.libelleSociete +' ': '') + 
              (devis.adresseSociete ? (devis.adresseSociete+ ' - '): '')+
              (devis.codePostalSociete ? (devis.codePostalSociete + ' ') :'')+
              (devis.villeSociete ? (devis.villeSociete + '\n') : '\n')+
              'RCS '+ (devis.rcs? devis.rcs: '') + ' B' + (devis.sirenSociete? devis.sirenSociete : '') +  ' - ' +
              (devis.nafAgence ? (devis.nafAgence + '') : ''),
              styles: {
                halign: 'center'
              }
            }
          ]
        ],
        theme: 'plain',
        styles: {
          fontSize: 8,
        },
        margin: {
          top:0,
          left: 15
        }
      });
      logo ? doc.addImage(logo, 'JPEG', 170, 270, 30, 15, undefined,'FAST'): '';
    }

    page4(doc: jsPDF, devis: DevisDTO) {
      if(devis.typeTiers === 'Particulier'){
        this.cgv_particulier_page1(doc);
        this.cgv_particulier_page2(doc);
      }else{
        this.cgv_professionnel_page1(doc);
        this.cgv_professionnel_page2(doc);

      }
    }

    cgv_particulier_page1(doc: jsPDF){
      doc.addPage();
      doc.setFontSize(7);
      doc.setTextColor('#000000');
      doc.setFont(undefined, 'bold');
      doc.text("CONDITIONS GENERALES DE PRESTATIONS effectuées auprès de CLIENTS PARTICULIERS \n \n", 
      (doc.internal.pageSize.width / 2) / 2, 5,
      {
        maxWidth: (doc.internal.pageSize.width) / 3.5,
        align: 'center'
      });
      
      doc.setFontSize(7);

      doc.text("1. ACCEPTATION DES CONDITIONS GÉNÉRALES DE PRESTATIONS \n", 5,13 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');

      doc.text(
        "Sauf convention particulière, le fait de confier à SARP ou à l’une ou l’autre de ses filiales (ci après "+
        "indistinctement désignées « SARP ») des prestations d'assainissement (ci-après désignées «"+
        "l'Assainissement » ou « Prestations ») dans des locaux ou installations à usage d'habitation, implique "+
        "l'adhésion entière et sans réserve du client aux présentes conditions générales, à l'exclusion de tout "+
        "autre document. "+
        "Toute condition contraire sera donc, à défaut d'acceptation expresse, inopposable à SARP, quel que "+
        "soit le moment où elle aura pu être portée à sa connaissance. Le fait que SARP ne se prévale pas à un "+
        "moment donné de l'une quelconque des présentes conditions générales de Prestations (ci-après "+
        "désignées les «CGP») ne peut être interprété comme valant renonciation à se prévaloir "+
        "ultérieurement de l'une quelconque desdites CGP. Les présentes sont applicables aux seuls "+
        "consommateurs, au sens qu'en donne l'article préliminaire du Code de la consommation, agissant "+
        "exclusivement pour leur propre compte et ayant la pleine capacité juridique de contracter (ci-après dénommés le/les «Clients»).\n"
        , 5, 17, {
        maxWidth: (doc.internal.pageSize.width - 18)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      doc.text("2. DEMANDE DE PRESTATIONS - DEVIS - ACCEPTATION"
        , 5,53 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Aucune demande de Prestations ne pourra être prise en compte sans la signature préalable par le " +
        "Client : (i) d’un devis établi par SARP et remis par le Client dûment daté et revêtu de la mention " +
        "manuscrite « Devis reçu et accepté avant exécution des Prestations », précisant notamment les " +
        "coordonnées exactes du Client, le lieu d'exécution des Prestations, la description des Prestations et le " +
        "décompte détaillé en quantité et en prix de chaque Prestation et/ou produit nécessaire à l'opération " +
        "d'Assainissement prévue, ainsi que (ii) des présentes CGP datées et revêtues de la mention " +
        "manuscrite « Bon pour accord ».\n" +
        "Les devis de SARP sont valables deux (2) mois à compter de la date à laquelle ces devis ont été " +
        "établis, sauf stipulation particulière indiquées sur le devis. Le devis et les CGP signés par SARP et le " +
        "Client forment ainsi le contrat de Prestations conclu entre ces derniers.\n" +
        "SARP se réserve la faculté de conditionner l'exécution du contrat de Prestations visées au devis " +
        "dûment accepté par le Client, à la remise d’un acompte de 20 % du montant HT des Prestations tel " +
        "qu'indiqué au devis si celui n'excède pas 500 euros HT et d’un acompte de 50 % du montant HT des " +
        "Prestations tel qu'indiqué au devis si celui excède 500 euros HT.\n" +
        "Les devis de SARP sont établis sous réserve de difficultés d'exécution dues notamment à la présence " +
        "d'amiante, à l'existence de tartre dur, de laitance de ciment, de racines, de morceaux de fer, de bois " +
        "ou autres nécessitant des matériels ou des travaux non prévus qui seront facturés en sus du prix " +
        "indiqué sur le devis après accord exprès du Client sur un devis complémentaire, selon le tarif " +
        "préalablement communiqué au Client. " +
        "Pour l'établissement du devis et, en tout état de cause, avant le début d'exécution des Prestations, le " +
        "Client s'engage à fournir à SARP les informations les plus précises possibles nécessaires à la bonne " +
        "exécution des Prestations concernant notamment l'état, la nature et l'emplacement des " +
        "équipements et installations du Client ainsi que le cas échéant les conditions d'apparition du sinistre " +
        "et ce afin d'éviter toute recherche inutile de la part de SARP. À défaut, SARP se réserve la faculté de " +
        "facturer en sus au Client le temps passé pour la recherche de ces informations, selon le devis établi et " +
        "soumis à l'accord préalable du Client.\n"
        , 5, 56, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      
      doc.text("3. EXECUTION DES PRESTATIONS\n"
        , 5,132 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Les délais d'exécution des Prestations précisés sur le devis pourront être modifiés par SARP en cas " +
        "de force majeure." +
        "Conformément aux dispositions de l'article L.216-2 du Code de la consommation, en cas d'absence " +
        "d'exécution des Prestations par SARP à la date ou à l'expiration du délai prévus dans le devis, le " +
        "Client aura la possibilité, d'enjoindre SARP d'effectuer les Prestations dans un délai supplémentaire " +
        "raisonnable (par lettre recommandé avec demande d'avis de réception ou par écrit sur support " +
        "durable). Si SARP ne s'est toujours pas exécuté dans ce délai supplémentaire, le Client aura, alors, la " +
        "possibilité de dénoncer le contrat de Prestations par lettre recommandée avec demande d'avis de " +
        "réception ou par un écrit sur un autre support durable." +
        "Le contrat sera, le cas échéant, considéré comme rompu à la réception, par SARP, de la lettre de " +
        "résiliation précitée par laquelle le Client l'informe de sa décision, à moins que SARP ne se soit " +
        "exécutée entre-temps." +
        "Le Client peut immédiatement résoudre le contrat lorsque SARP refuse d'effectuer les Prestations ou " +
        "lorsqu'elle n'exécute pas son obligation d'exécution des Prestations à la date ou à l'expiration du " +
        "délai prévu et que cette date ou ce délai constitue pour le Client une condition essentielle du " +
        "contrat. Cette condition essentielle résulte d’une demande expresse du Client avant la conclusion du " +
        "contrat.\n" + 
        "Le Client s'engage à veiller au libre accès de ses installations et de ses équipements par SARP, dans le " + 
        "respect des règles de sécurité : si SARP ne peut accéder aux équipements ou peut y accéder mais " + 
        "dans des conditions non satisfaisantes, notamment de sécurité, elle se réserve le droit de refuser " +
        "l'exécution des Prestations et de facturer au Client le prix de son déplacement, selon le tarif " +
        "préalablement communiqué au Client.\n " +
        "En outre, SARP se réserve la faculté de refuser d'émettre un devis ou d'exécuter des Prestations en " +
        "cas de difficultés n'entrant pas dans le cadre de ses compétences ou de ses moyens ou susceptibles " +
        "d'être préjudiciables à son personnel ou à son matériel. \n " +
        "Les études, plans et autres documents établis par SARP dans le cadre de l'exécution des Prestations " +
        "d'Assainissement et transmis au Client, restent la propriété exclusive de SARP et ne peuvent donc " +
        "être communiqués ou utilisés par le Client sans l'accord préalable et écrit de cette dernière. La remise " +
        "en eau des fosses septiques et des bacs à graisse est de la responsabilité du Client."
        , 5, 136, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });


      doc.setFont(undefined, 'bold');

      
      doc.text("4. PRIX — PAIEMENT \n"
        , 5,217 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.text("4.1 Prix des prestations \n"
      , 5,220 ,{
      maxWidth: (doc.internal.pageSize.width - 20)/ 2,
    });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Les tarifs sont exprimés en euros et s'entendent hors TVA. En cas de facturation des Prestations au " +
        "temps passé, toute heure commencée depuis plus de 15 minutes sera intégralement due.\n " +
        
        "Si les équipements, objet des Prestations, présentent un vice de construction, une vétusté ou une " +
        "obstruction tel que les Prestations ne peuvent être exécutées ou achevées, SARP sera fondée à " +
        "facturer au Client le temps passé et le cas échéant, les moyens mis en Œuvre. Le prix des Prestations " +
        "ne comprend pas la fourniture d'énergie, d'eau ou d'électricité nécessaires à l'exécution des " +
        "Prestations et/ou aux premiers essais suivant l'exécution des Prestations, qui restent entièrement à " +
        "la charge du Client ainsi que les éventuels travaux et/ou prestations relevant d’autres corps d'état. " +
        
        "Le prix des Prestations réalisées en dehors des heures ouvrées (7H30/15H30) ainsi que les samedis, " +
        "dimanche ou jours fériés feront l’objet d’une majoration de nos tarifs usuels, à définir en fonction de " +
        "la Prestation à réaliser."
        , 5, 223, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      
      doc.text("4.2 Paiement \n"
        , 5,256 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Les Prestations seront facturées à l'issue de leur exécution. Toutefois, si les délais d'exécution sont " +
        "supérieurs à un mois, SARP émettra une facture mensuellement. Les factures de Prestations sont " +
        "payables comptant à réception de facture et peuvent être réglées en espèce, pour les montants " +
        "inférieurs à 1000 euros TTC. Tout retard de paiement donnera lieu, sans mise en demeure préalable, " +
        "au paiement d'un intérêt de retard d’un montant égal à trois fois le taux d'intérêt légal qui " +
        "commencera à courir à compter du jour suivant la date de règlement figurant sur la facture, et ce " +
        "jusqu'au complet paiement des sommes dues.\n " +
        
        "Dans l'hypothèse où SARP procéderait à des Prestations de maintenance et où le Client ne " +
        "procéderait pas au paiement des factures adressées par SARP, cette dernière pourra, huit jours après " +
        "réception par le Client d’une mise en demeure de payer adressée par courrier recommandé avec " +
        "accusé de réception, restée sans effet, décider d'interrompre ses Prestations jusqu’au complet " +
        "paiement des sommes dues augmentées d'éventuels intérêts de retard. Le Prestataire se réserve " +
        "également le droit de résoudre le contrat dans les conditions prévues à l'article 10."
        , 5, 259, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      
      doc.text("5. DROIT DE RÉTRACTATION \n"
        , (doc.internal.pageSize.width / 2) ,  3 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Dans le cadre de contrats de Prestations conclus à distance ou hors établissement, le Client dispose d'un " +
        "délai de 14 jours à compter du jour de la conclusion du contrat pour exercer son droit de rétractation et ce, " +
        "conformément à l'article L.221-18 du Code de la consommation. Conformément à l'article L.221-28 du Code " +
        "de la consommation, le droit de rétractation ne peut être exercé d'une part, s'agissant de prestations de " +
        "services pleinement exécutées avant la fin du délai de rétractation et dont l'exécution a commencé après " +
        "accord préalable et exprès du Client et renoncement exprès à son droit de rétractation et d'autre part, " +
        "s'agissant de travaux d'entretien ou de réparation à réaliser en urgence au domicile du Client et " +
        "expressément sollicités par lui, dans la limite des pièces de rechange et travaux strictement nécessaires " +
        "pour répondre à l'urgence. Le Client pourra exercer son droit de rétractation en adressant à SARP le " +
        "formulaire de rétractation figurant en bas de l'exemplaire du devis signé ou tout écrit exprimant de manière " +
        "explicite la mise en Œuvre de son droit de rétractation. \n" +
        
        "SARP remboursera alors au Client la totalité des sommes versées par celui-ci au plus tard dans les 14 jours à " +
        "compter de la date à laquelle elle est informée de la décision du Client de se rétracter Conformément à " +
        "l'article L.221-25 du Code de la consommation, le Client qui exerce son droit de rétractation s'agissant de " +
        "prestations de services dont l'exécution a commencé, à sa demande expresse, avant la fin du délai de " +
        "rétractation, verse à SARP un montant correspondant au service fourni jusqu'à la communication de la " +
        "décision de se rétracter Ce montant est proportionné au prix total de la Prestation convenu dans le " +
        "Contrat."
        , (doc.internal.pageSize.width / 2) , 6, {
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("6. RESPONSABILITÉ \n"
        , (doc.internal.pageSize.width / 2) ,  61 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.text("6.1 Responsabilité du client \n"
        , (doc.internal.pageSize.width / 2) ,  64 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Le Client est responsable de ses équipements et de manière générale de tout dommage qui pourrait " +
        "résulter de leur fonctionnement, du fait de leur vétusté, de leur caractère non réglementaire ou de leur " +
        "défaut ou vice caché à moins que le dommage ne résulte directement d'une faute de SARP dans l'exécution " +
        "de ses Prestations d'Assainissement. Le Client doit informer sans délai SARP de tout incident ou " +
        "modification qui pourrait avoir une incidence sur l'exécution des Prestations. " +
        "l'attention du Client producteur de déchets est attirée sur sa propre responsabilité telle qu'elle a été " +
        "définie par les dispositions légales et règlementaires relatives à l'élimination des déchets. Les " +
        "conséquences financières de l'évolution de la réglementation ou des modifications de filières de traitement " +
        "ou d'élimination, seront répercutées au Client sans préavis"
        , (doc.internal.pageSize.width / 2) , 67, 
        { 
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
       });


       doc.setFont(undefined, 'bold');
       doc.text("6.2 Responsabilité de SARP \n"
         , (doc.internal.pageSize.width / 2) ,  93 ,{
         maxWidth: (doc.internal.pageSize.width - 15)/ 2,
       });
 
       doc.setFont(undefined, 'normal');
 
       doc.text(
         "SARP apportera dans la réalisation de ses Prestations tous les soins requis d’un professionnel. SARP ne " +
         "pourra être tenu responsable de quelque manière que ce soit des erreurs du Client. SARP ne pourra être " +
         "tenu responsable de quelque manière que ce soit, des erreurs du Client, du personnel du Client ou des " +
         "fournisseurs du Client. " +
         "De même, SARP ne pourra en aucun cas être tenue responsable de tous dommages aux ouvrages ou aux " +
         "tiers, résultant de la vétusté, de vices cachés des ouvrages ou lorsqu'ils résulteront d'obstructions tels les " +
         "tartres durs, laitance de ciment, racines, morceaux de fer, bois, lingettes, papiers, graisses ou autres causes " +
         "de nature similaire. \r\n" +
         "SARP ne pourra en aucun cas être tenue responsable de tous dommages causés aux accès des ouvrages " +
         "(pelouses, dallages, chaussées...), étant ici rappelé que le Client doit assurer un libre accès aux ouvrages. " +
         "Les opérations telles que notamment le démontage des toilettes, ouverture de cloisons, étaiement, " +
         "ouverture et fermeture des fosses, regards, citernes, tampons, plaques de trou d'homme, manipulation " +
         "des canalisations, tuyauteries ou tous accessoires — sans que cette liste ne soit limitative -, (les « " +
         "Opérations »), ne font pas partie des Prestations sauf convention particulière. Ainsi, quand bien même " +
         "SARP serait dans l'obligation de procéder à ces Opérations pour réaliser ses Prestations, et même si elles " +
         "sont facturées en plus, la responsabilité de SARP ne pourra en aucun cas être engagée relativement à ces " +
         "Opérations."
         , (doc.internal.pageSize.width / 2) , 96, 
         { 
         maxWidth: (doc.internal.pageSize.width - 15)/ 2,
         align: "justify"
        });
       

       doc.setFont(undefined, 'bold');
       doc.text("7. ASSURANCES \n"
         , (doc.internal.pageSize.width / 2) ,  142 ,{
         maxWidth: (doc.internal.pageSize.width - 15)/ 2,
       });
 
       doc.setFont(undefined, 'normal');
 
       doc.text(
         "SARP maintiendra en vigueur pendant toute la durée d'exécution des Prestations une police d'assurance " +
         "garantissant sa responsabilité pour tous dommages causé par elle même et ses préposés dans le cadre de " +
         "l'exécution des Prestations. \r\n " +
         "Le Client disposera d’une assurance « Responsabilité Civile » pour se garantir contre tous les risques restant " +
         "à sa charge qui peuvent être assurés." 
         , (doc.internal.pageSize.width / 2) , 145, 
         { 
         maxWidth: (doc.internal.pageSize.width - 15)/ 2,
         align: "justify"
        });
       
        doc.setFont(undefined, 'bold');
        doc.text("8. FORCE MAJEURE \n"
          , (doc.internal.pageSize.width / 2) ,  160 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        });
  
        doc.setFont(undefined, 'normal');
  
        doc.text(
          "SARP sera dégagée de toute responsabilité dans le cadre de l'exécution de ses Prestations en cas de force " +
          "majeure empêchant en tout ou partie l'exécution des Prestations confiées par le Client. Un cas dit de Force " +
          "Majeure est un événement auquel on ne peut faire face, tel que défini par la jurisprudence française." 
          , (doc.internal.pageSize.width / 2) , 163, 
          { 
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
          align: "justify"
         });


         doc.setFont(undefined, 'bold');
        doc.text("9. PROPRIÉTÉ DES ÉTUDES, PLANS ET DOCUMENTS \n"
          , (doc.internal.pageSize.width / 2) ,  176 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        });
  
        doc.setFont(undefined, 'normal');
  
        doc.text(
          "Le Prestataire cède la propriété des études, plans et documents établis pour les besoins de la réalisation "+
          "des prestations de Services confiés par le Client." 
          , (doc.internal.pageSize.width / 2) , 179, 
          { 
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
          align: "justify"
         });
        

         doc.setFont(undefined, 'bold');
        doc.text("10 - PROTECTION DES DONNÉES PERSONNELLES \n"
          , (doc.internal.pageSize.width / 2) ,  186 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        });
  
        doc.setFont(undefined, 'normal');
  
        doc.text(
          "Les données à caractère personnel transmises directement ou indirectement font l'objet d’un traitement " +
          "informatique par SARP destiné à la gestion de la commande, la gestion de la relation client ainsi que, le cas " +
          "échéant, à des fins de prospection commerciale, avec le consentement préalable du Client. " +
          "Les données à caractère personnel collectées sont conservées pendant la durée nécessaire à l'exécution " +
          "des obligations légales et contractuelles de SARP à compter de la collecte, et stockées au sein de l’Union " +
          "Européenne. " +
          "Conformément au Règlement Général sur la Protection des Données, le Client dispose d’un droit " +
          "d'interrogation, d'accès, de rectification, de portabilité et d'opposition pour motifs légitimes relativement à " +
          "l'ensemble des données le concernant. Ce droit peut être exercé par l'envoi d’un " +
          "courriel accompagné d’une copie d’un titre d'identité à dpo.sarp@veolia. com Pour information, le Délégué " +
          "à la Protection des Données est joignable à l'adresse suivante : dpo.sarp @yeolia,com " +
          "Conformément à l'article 77 du Règlement Général sur la Protection des Données, vous disposez " +
          "également du droit d'introduire une réclamation relative au présent traitement auprès de la Commission " +
          "Nationale de l'informatique et des Libertés. Nous vous informons de l'existence de la liste d'opposition au " +
          "démarchage téléphonique « Bloctel », sur laquelle wus pouvez vous inscrire ici : httns//conso.blocteLfr/"
          , (doc.internal.pageSize.width / 2) , 189, 
          { 
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
          align: "justify"
         });

         doc.setFont(undefined, 'bold');
         doc.text("11- RÉSILIATION \n"
           , (doc.internal.pageSize.width / 2) ,  232 ,{
           maxWidth: (doc.internal.pageSize.width - 15)/ 2,
         });
   
         doc.setFont(undefined, 'normal');
   
         doc.text(
           "En cas de non-respect par l’une des parties de l'une de ses obligations au titre des Prestations, l'autre Partie " +
           "pourra résilier le contrat de Prestations, de façon anticipée, sans préjudice de tous dommages et intérêts qui " +
           "pourraient être réclamés, après notification adressée par lettre recommandée avec avis de réception, " +
           "précisant l'inexécution visée ainsi que l'intention de résilier la convention, restée infructueuse pendant " +
           "trente (30) jours."
           , (doc.internal.pageSize.width / 2) , 235, 
           { 
           maxWidth: (doc.internal.pageSize.width - 15)/ 2,
           align: "justify"
          });

          doc.setFont(undefined, 'bold');
          doc.text("12 -RÉCLAMATION ET LITIGES DE LA CONSOMMATION \n"
            , (doc.internal.pageSize.width / 2) ,  250 ,{
            maxWidth: (doc.internal.pageSize.width - 15)/ 2,
          });
    
          doc.setFont(undefined, 'normal');
    
          doc.text(
            "Toute réclamation sur les vices apparents ou sur la non-conformité de la Prestation effectuée ou du matériel " +
            "livré doit être formulée par écrit, dans les 8 jours de la Prestation ou de la réception du matériel, à l'adresse " +
            "du Prestataire. " +
            "En cas de réclamation non satisfaite, le client doit impérativement formuler son litige par écrit auprès de " +
            "notre société. Il formule sa demande à notre direction par voie postale ou sous forme de courriel aux " +
            "adresses suivantes:"
            , (doc.internal.pageSize.width / 2) , 253, 
            { 
            maxWidth: (doc.internal.pageSize.width - 15)/ 2,
            align: "justify"
           });
           
           doc.text(
            "- 28 boulevard de Pesaro 92000 Nanterre"
            , (doc.internal.pageSize.width / 2) , 267, 
            { 
            maxWidth: (doc.internal.pageSize.width - 15)/ 2,
            align: "justify"
           });

           doc.text(
            "- https://www.sarp-habitatservices.fr/formulaire-de-contact "
            , (doc.internal.pageSize.width / 2) , 270, 
            { 
            maxWidth: (doc.internal.pageSize.width - 15)/ 2,
            align: "justify"
           });            
            
           doc.setFontSize(7);

           doc.text(
            "Notre société s'efforcera, sauf difficultés particulières, de traiter toute demande avérée sous un délai " +
           "maximal de 3 semaines. " +
           "Conformément à l'article L612-1 du code de la consommation notre société s’est dotée d’un dispositif " +
           "de médiation: Si le différend commercial n'est pas résolu, au-delà de 2 mois, vous pourrez vous " +
           "adresser au médiateur de la consommation selon les modalités ci-après:-informations et saisine " +
           "numérique (par formulaire) des demandes de médiation: www.mediateurconso-bfc.fr \n" +
           "-Envoi par la voie postale des dossiers:C&C-Médiation/37 rue des Chênes - 25480 Miserey-Salines. " +
           "En cas de nécessité, les parties conservent toute faculté de saisir la juridiction compétente."
            , (doc.internal.pageSize.width / 2) , 273, 
            { 
            maxWidth: (doc.internal.pageSize.width - 15)/ 2,
            align: "justify"
           });     
           
    }

    cgv_particulier_page2(doc: jsPDF){
      doc.addPage();
      doc.setFontSize(10);
      doc.setTextColor('#000000');
      doc.setFont(undefined, 'bold');
      doc.text("FORMULAIRE DE RÉTRACTATION \n \n", 
      6, 17, 
      { 
      maxWidth: (doc.internal.pageSize.width - 5),
     });

     doc.setFont(undefined, 'normal');

     doc.text(
      "À l'attention de ................................................... [ le particulier insère ici son nom, son adresse géographique et, lorsqu'ils sont"
      ,10, 27, 
      { 
      maxWidth: (doc.internal.pageSize.width - 5),
      align: "justify"
     }); 

     doc.text(
      "disponibles, son numéro de téléphone portable et son adresse électronique] :"
      ,10, 32, 
      { 
      maxWidth: (doc.internal.pageSize.width - 5),
     }); 

     doc.text(
      "Je/nous (*) vous notifie/notifions (*) par la présente ma/notre (*) rétractation du contrat portant sur la vente du bien (*)/pour la"
      ,10, 40, 
      { 
      maxWidth: (doc.internal.pageSize.width - 5),
      align: "justify"
     });

     doc.text(
      "prestation de services (*) ci-dessous : \n\n" +
      "Commandé le (*)/reçu le (*) : \n\n" +
      "Nom du (des) consommateur(s) : \n\n" +
      "Adresse du (des) consommateur(s) : \n\n" +
      "Signature du (des) consommateur(s) : \n\n" +
      "Date : \n\n" +
      "(*) Rayez la mention inutile."
      ,10, 46, 
      { 
      maxWidth: (doc.internal.pageSize.width - 5),
     }); 
    }


    cgv_professionnel_page1(doc: jsPDF){
      doc.addPage();
      doc.setFontSize(7);
      doc.setTextColor('#000000');
      doc.setFont(undefined, 'bold');
      doc.text("CONDITIONS GENERALES DE PRESTATIONS effectuées auprès de CLIENTS PROFESSIONNELS \n \n", 
      (doc.internal.pageSize.width / 2) / 2, 5,
      {
        maxWidth: (doc.internal.pageSize.width) / 3.5,
        align: 'center'
      });
      
      doc.setFontSize(7);

      doc.text("1. Acceptation des conditions générales de prestations \n", 5,13 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');

      doc.text(
        "Sauf convention particulière, le fait de confier 4 SARP ou a I’une ou l’autre de ses " +
        "filiales (ci-après  indistinctement désignée «SARP») des _ prestations " +
        "d’assainissement (ci-après désignées « Prestations») dans des locaux ou " +
        "installations a usage professionnel, implique l’adhésion entière et sans réserve du " +
        "client (ci-après désigné le « Client») aux présentes conditions générales de " +
        "Prestations qui constituent le socle unique de la négociation commerciale " +
        "conformément a l’article L.441-6 du Code de commerce, a |’exclusion de tout autre " +
        "document. " +
        "Toutes conditions contraires et, notamment, toutes conditions générales ou " +
        "particulières @manant du Client, y compris ses conditions d'achat et bons de " +
        "commande, sont en conséquence inopposables a SARP, sauf acceptation préalable " +
        "et écrite. " +
        "Le fait que SARP ne se prévale pas a un moment donné de I'une quelconque des " +
        "présentes conditions générales de Prestations ne peut être interprété comme " +
        "valant renonciation a se prévaloir ultérieurement de l'une quelconque desdites " +
        "conditions."
        , 5, 17, {
        maxWidth: (doc.internal.pageSize.width - 18)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      doc.text("2. Demande de prestations — devis — acceptation"
        , 5,53 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Aucune demande de Prestations ne pourra être prise en compte sans la signature " +
        "préalable par le Client : \n \n "
        , 5, 56, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont("Zapfdingbats");
      doc.text("l", 5, 63 ,{maxWidth: (doc.internal.pageSize.width - 20)/ 2,});
  
      doc.setFont("courrier", 'normal');

      doc.text("soit du devis établi par SARP et retourné par le Client diment daté, signé et revêtu de la mention manuscrite « Devis reçu et accepté avant exécution des travaux », précisant les coordonnées exactes du Client, le descriptif exact de(s) équipement(s) et le lieu où il(s) se trouve(nt) , ainsi que le contenu et les limites des Prestations."
        , 8,63 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.setFont("Zapfdingbats");
      doc.text("l", 5, 77 ,{maxWidth: (doc.internal.pageSize.width - 20)/ 2,});
  
      doc.setFont("courrier", 'normal');
      doc.text(" soit d’un contrat de Prestations.\n \n " 
      , 8, 77 ,{
      maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.text("En outre, SARP se réserve la faculté de conditionner l’acceptation du devis a " +
      "'envoi d’un acompte de 20 % du montants HT des Prestations si celui-ci n’excède " +
      "pas 500 euros HT, d’un acompte de 50 % du montant HT des Prestations si celui-ci " +
      "n’excède pas 3.000 euros HT et d’un acompte de 75% du montant HT des " +
      "Prestations si celui-ci excède 7.000 euros. \n \n" +
      "Les devis de SARP sont valables deux (2) mois a compter de la date a laquelle ces " +
      "devis ont été établis, sauf stipulation particulière indiquée sur le devis. " +
      "Les devis de SARP sont établis sous réserve de difficultés d’exécution dues " +
      "notamment a la présence d’amiante, a l’existence de tartre dur, de laitance de " +
      "ciment, de racines, de morceaux de fer, de bois ou autres nécessitant des " +
      "matériels ou des travaux non prévus qui seront facturés en sus du prix indiqué sur " +
      "le devis après accord du Client. \n" +
      "Pour |l’établissement du devis et, en tout état de cause, avant le début d’exécution " +
      "des Prestations, le Client s’engage a fournir a SARP les informations les plus " +
      "précises possibles nécessaires a la bonne exécution des Prestations concernant " +
      "notamment |’état, la nature et l‘emplacement des équipements et installations du " +
      "Client et ce afin d’éviter toute recherche inutile de la part de SARP. A défaut, SARP " +
      "se réserve la faculté de facturer en sus au client le temps passé pour la recherche " +
      "de ces informations."
        , 5, 81 ,{
          maxWidth: (doc.internal.pageSize.width - 20)/ 2,
          align: "justify"
      });
      doc.setFont(undefined, 'bold');

      
      doc.text("3. Exécution des Prestations\n"
        , 5,134 ,{
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Les délais d’exécution des Prestations précisés sur le devis ou le contrat de " +
        "Prestations sont purement indicatifs: ces délais pourront être modifiés " +
        "unilatéralement par SARP en cas de force majeure, de grèves, de difficultés de la " +
        "circulation et plus généralement pour toute raison indépendante de la volonté de " +
        "SARP. En tout état de cause, le dépassement de ces délais indicatifs ne saurait être " +
        "une cause de résiliation du contrat de Prestations ni donner lieu a aucune retenue " +
        "ou indemnité au profit du Client. \n\n" +

        "En dehors des causes mentionnées ci-dessus, tout retard dans l’exécution des " +
        "Prestations ne pourra donner lieu qu’a la seule indemnisation du préjudice " +
        "réellement subi et préalablement démontré par le Client, a l’exclusion de toute " +
        "pénalité forfaitaire et ce, nonobstant l’existence de clauses contraires dans les " +
        "éventuelles conditions d’achat du Client. A défaut d’accord, l’évaluation du " +
        "préjudice subi interviendra a dire d’expert nommé par le président du Tribunal de " +
        "commerce de Nanterre, a la requête de la partie la plus diligente. " +
        "Le Client s’engage a veiller au libre accès de ses installations et de ses " +
        "équipements par SARP, dans le respect des règles de sécurité. Si SARP ne peut " +
        "accéder aux équipements ou peut y accéder mais dans des conditions non " +
        "satisfaisantes, notamment de sécurité, elle se réserve le droit de refuser " +
        "l’exécution des Prestations et de facturer au Client le prix de son déplacement. " +
        "En outre, SARP se réserve la faculté de refuser d’exécuter ou de poursuivre " +
        "l’exécution des Prestations en cas de contraintes ou sujétions nouvelles présentant " +
        "des difficultés n’entrant pas dans le cadre de ses compétences ou de ses moyens " +
        "ou susceptibles d’être préjudiciables a son personnel et ou a son matériel.\n " +
        "Les études, plans et autres documents établis par SARP dans le cadre de " +
        "l'exécution des Prestations et transmis au Client, restent la propriété exclusive de " +
        "SARP et ne peuvent donc être communiqués ou utilisés par le Client sans l’accord " +
        "préalable et écrit de cette dernière. \n \n" +
        
        "La remise en eau des fosses septiques et des bacs a graisse est de la responsabilité du Client. " +   
        "Toute modification des conditions de réalisation des prestations de services en " + 
        "cours de réalisation ne pourra être prise en considération que si cette modification " + 
        "a fait l'objet d’un avenant écrit. Pour chaque modification, le Prestataire indiquera " + 
        "au Client les répercussions éventuelles sur les prix et les délais. \n \n" + 
        
        "Le Prestataire restera propriétaire dans tous les cas des études, plans et " + 
        "documents établis pour les besoins de la réalisation des prestations de Services et " + 
        "confiés au Client.\n \n" + 
        
        "Lorsque la Prestation de Services s’accompagne de cession de marchandises, le " + 
        "transfert de propriété de la marchandise vendue est subordonné au paiement du " + 
        "prix par le Client, a I’échéance convenue. En cas de défaut de paiement a " + 
        "l'échéance, le Prestataire reprendra possession de la marchandise dont il est resté " + 
        "propriétaire. Le Prestataire ne sera pas tenu de reprendre un matériel neuf qui lui " + 
        "serait retourné pour un motif ne relevant pas de sa responsabilité. Le matériel " + 
        "devra être retourné franco. En cas d’acceptation du retour par le Prestataire, le " + 
        "prix de reprise sera minoré de 10% par rapport au prix facturé." 
        , 5, 138, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });


      doc.setFont(undefined, 'bold');
      doc.text("4. PRIX — PAIEMENT \n"
        , (doc.internal.pageSize.width / 2) ,  7 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.text("4.1 Prix des prestations \n"
      , (doc.internal.pageSize.width / 2) ,  11 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');
      doc.text(
        "Les tarifs sont exprimés en euros et s'entendent hors TVA. En cas de facturation des Prestations au " +
        "temps passé, toute heure commencée depuis plus de 15 minutes sera intégralement due.\n " +
        
        "Si les équipements, objet des Prestations, présentent un vice de construction, une vétusté ou une " +
        "obstruction tel que les Prestations ne peuvent être exécutées ou achevées, SARP sera fondée à " +
        "facturer au Client le temps passé et le cas échéant, les moyens mis en Œuvre. Le prix des Prestations " +
        "ne comprend pas la fourniture d'énergie, d'eau ou d'électricité nécessaires à l'exécution des " +
        "Prestations et/ou aux premiers essais suivant l'exécution des Prestations, qui restent entièrement à " +
        "la charge du Client ainsi que les éventuels travaux et/ou prestations relevant d’autres corps d'état. " +
        
        "Le prix des Prestations réalisées en dehors des heures ouvrées (7H30/15H30) ainsi que les samedis, " +
        "dimanche ou jours fériés feront l’objet d’une majoration de nos tarifs usuels, à définir en fonction de " +
        "la Prestation à réaliser."
        , (doc.internal.pageSize.width / 2) ,  15 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      
      doc.text("4.2 Paiement \n"
        , (doc.internal.pageSize.width / 2) ,  48 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Les Prestations seront facturées à l'issue de leur exécution. Toutefois, si les délais d'exécution sont " +
        "supérieurs à un mois, SARP émettra une facture mensuellement. Les factures de Prestations sont " +
        "payables comptant à réception de facture et peuvent être réglées en espèce, pour les montants " +
        "inférieurs à 1000 euros TTC. Tout retard de paiement donnera lieu, sans mise en demeure préalable, " +
        "au paiement d'un intérêt de retard d’un montant égal à trois fois le taux d'intérêt légal qui " +
        "commencera à courir à compter du jour suivant la date de règlement figurant sur la facture, et ce " +
        "jusqu'au complet paiement des sommes dues.\n " +
        
        "Dans l'hypothèse où SARP procéderait à des Prestations de maintenance et où le Client ne " +
        "procéderait pas au paiement des factures adressées par SARP, cette dernière pourra, huit jours après " +
        "réception par le Client d’une mise en demeure de payer adressée par courrier recommandé avec " +
        "accusé de réception, restée sans effet, décider d'interrompre ses Prestations jusqu’au complet " +
        "paiement des sommes dues augmentées d'éventuels intérêts de retard. Le Prestataire se réserve " +
        "également le droit de résoudre le contrat dans les conditions prévues à l'article 10."
        , (doc.internal.pageSize.width / 2) ,  52 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');

      
      doc.text("5. Responsabilité \n"
        , (doc.internal.pageSize.width / 2) ,  90 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.text("5.1 Responsabilité du client \n"
        , (doc.internal.pageSize.width / 2) ,  94 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');

      doc.text(
        "Le Client est responsable de ses équipements et de manière générale de tout dommage qui pourrait résulter de leur fonctionnement, du fait de leur vétusté ou " +
        "de leur défaut ou vice caché à moins que le dommage ne résulte directement " +
        "d’une faute dûment établie de SARP dans l'exécution de ses Prestations. \n" +
        
        "Le Client doit informer immédiatement SARP de tout incident ou modification qui " +
        "pourrait avoir une incidence sur l'exécution des Prestations. \n " +
        
        "L’attention du Client producteur de déchets est par ailleurs attirée sur sa propre " +
        "responsabilité telle qu'elle à été définie par les dispositions légales et " +
        "règlementaires relatives à l'élimination des déchets. Les conséquences financières " +
        "de l'évolution de la réglementation ou des modifications de filières de traitement " +
        "ou d'élimination, seront répercutées au Client sans préavis. "
        , (doc.internal.pageSize.width / 2) ,  98 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });


      doc.setFont(undefined, 'bold');
      doc.text("5.2 Responsabilité de SARP \n", (doc.internal.pageSize.width / 2) ,  130 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');
      doc.text(
        "SARP apportera dans la réalisation de ses Prestations tous les soins requis d’un professionnel. \n " +
        
        "La responsabilité de SARP s'entend exclusivement de la réparation des dommages " +
        "directs et matériels à l'exclusion de tout dommage indirect et immatériel tel que " +
        "perte de production, perte de chiffre d'affaires, etc... En tout état de cause, sauf " +
        "disposition contraire dans le devis ou le contrat de Prestations, la responsabilité de " +
        "SARP ne pourra excéder le montant encaissé du devis ou du contrat de Prestations " +
        "pour l’ensemble des cas où la responsabilité contractuelle de SARP serait retenue. \n " +
        
        "SARP ne pourra être tenu responsable de quelque manière que ce soit, des " +
        "erreurs du Client, du personnel du Client ou des fournisseurs du Client. De même, " +
        "SARP ne pourra en aucun cas être tenue responsable de tous dommages aux " +
        "ouvrages ou aux tiers, résultant de la vétusté, de vices cachés des ouvrages ou " +
        "lorsqu'ils résulteront d'obstructions tels les tartres durs, laitance de ciment, " +
        "racines, morceaux de fer, bois, lingettes, papiers, graisses ou autres causes de " +
        "nature similaire.\n " +
        
        "La SARP ne pourra en aucun cas être tenue responsable de tous dommages causés " +
        "aux accès des ouvrages (pelouses, dallages, chaussées…), étant ici rappelé que le " +
        "Client doit assurer un libre accès aux ouvrages.\n " +
        
        "Les opérations telles que notamment le démontage des toilettes, ouverture de " +
        "cloisons, étaiement, ouverture et fermeture des fosses, regards, citernes, " +
        "tampons, plaques de trou d'homme, manipulation des canalisations, tuyauteries " +
        "ou tous accessoires — sans que cette liste ne soit limitative -, (les « Opérations »), " +
        "ne font pas partie des Prestations sauf convention particulière. Ainsi, quand bien " +
        "même la SARP serait dans l'obligation de procéder à ces Opérations pour réaliser " +
        "ses Prestations, et même si elles sont facturées en plus, la responsabilité de la " +
        "SARP ne pourra en aucun cas être engagée relativement à ces Opérations."
        , (doc.internal.pageSize.width / 2) ,  134 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("6. Assurances", (doc.internal.pageSize.width / 2) , 200 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');
      doc.text(
        "Chacune des parties maintiendra en vigueur pendant toute la durée d'exécution " +
        "des Prestations une police d'assurance garantissant sa responsabilité pour tous " +
        "dommages causés par elle-même et ses préposés dans le cadre de l'exécution des Prestations."
        , (doc.internal.pageSize.width / 2) ,  204 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("7. Exclusion de toutes pénalités", (doc.internal.pageSize.width / 2) , 217 ,{
        maxWidth: (doc.internal.pageSize.width - 15)/ 2,
      });

      doc.setFont(undefined, 'normal');
      doc.text(
        "Nonobstant toutes clauses ou dispositions contraires pouvant figurer dans des " +
        "conditions d'achat ou tout autre document émanant du Client, aucune pénalité de " +
        "quelque nature qu’elle soit ne sera acceptée par SARP, sauf accord préalable et " +
        "écrit et ce, quelle que soit la nature et la cause de la pénalité. Toute clause " +
        "contraire est réputée non écrite. \n" +
        
        "Seul le préjudice réellement subi, démontré et évalué par le Client pourra " +
        "éventuellement donner lieu à indemnisation par SARP, après demande formulée " +
        "auprès du Client et négociation avec ce dernier et dans les limites de " +
        "responsabilité de SARP prévues dans les présentes CGV. A défaut d'accord, " +
        "l'évaluation du préjudice subi interviendra à dire d'expert nommé par le Président " +
        "du Tribunal de Commerce de Nanterre, à la requête de la partie la plus diligente. " +
        "En cas de violation de la présente clause par le client, SARP pourra refuser tout " +
        "nouveau contrat de Prestations et suspendre l'exécution de ses Prestations."
        , (doc.internal.pageSize.width / 2) ,  221 ,{
          maxWidth: (doc.internal.pageSize.width - 15)/ 2,
        align: "justify"
      });

    }

    cgv_professionnel_page2(doc: jsPDF){
      doc.addPage();
      doc.setFontSize(7);
      doc.setTextColor('#000000');


      doc.setFont(undefined, 'bold');
      doc.text("8. Sous-traitance", 5, 5, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "Le Client autorise le Prestataire à sous-traiter tout ou partie de ses Prestations de " +
        "Services auprès d’un sous-traitant choisi par le Prestataire et s'engage par avance " +
        "à agréer le ou les sous-traitants."
        , 5, 8, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("9.  Contestations commerciales", 5, 20, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "Toute contestation de la part du client relative à l'ensemble de la relation " +
        "commerciale avec SARP devra être formulée au plus tard dans les douze (12) mois, " +
        "à compter de la date de la dernière facture émise par SARP au titre des " +
        "Prestations. A défaut, et par dérogation expresse aux dispositions visées sous " +
        "l’article L.110-4 du Code de Commerce, aucune réclamation ou contestation ne " +
        "pourra plus être présentée et sera considérée, dès lors, comme étant prescrite et " +
        "donc strictement irrecevable."
        , 5, 23, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });
      
      doc.setFont(undefined, 'bold');
      doc.text("10. Résiliation - exception d’inexécution", 5, 43, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "A défaut pour le Client de payer le prix des Prestations ou d'exécuter l’une des " +
        "clauses, charges et conditions des présentes, la résiliation du contrat de " +
        "Prestations résultant du devis accepté par le Client sera encourue de plein droit 8 " +
        "jours après une mise en demeure de payer ou d'exécuter restée sans effet, " +
        "adressée par lettre recommandée avec demande d'avis de réception, et " +
        "manifestant la volonté de SARP d’user du bénéfice de la présente clause sans qu'il " +
        "soit besoin d’autres formalités judiciaires. \n" +
        
        "SARP peut en outre et de plein droit, 8 jours après réception par le Client, de la " +
        "mise en demeure énoncée ci-dessus, décider d'interrompre ses Prestations " +
        "jusqu'au complet paiement des sommes dues augmentées d'éventuels intérêts de " +
        "retard ou la parfaite exécution des clauses, charges et conditions."
        , 5, 46, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("11. Force majeure", 5, 78, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "SARP sera dégagée de toute responsabilité dans le cadre de l'exécution de ses " +
        "Prestations en cas de force majeure ou de cas fortuit, empêchant en tout ou partie " +
        "l'exécution des Prestations confiées par le Client. \n" +
        "Seront notamment considérés comme exonératoires les événements suivants : les " +
        "catastrophes d'origine atmosphérique, telles que le gel, la neige ou les pluies " +
        "d’une exceptionnelle importance ; les barrières de dégel ; l'incendie, l’inondation " +
        "ou explosion pour quelque cause que ce soit ; les grèves ou débrayages affectant " +
        "SARP ou le Client, les émeutes ou guerres."
        , 5, 81, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("12. Confidentialité", 5, 104, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "SARP et le Client reconnaissent qu'ils pourront, dans le cadre de l'exécution des " +
        "Prestations et de manière générale de leurs relations commerciales, se voir confier " +
        "des informations confidentielles de nature technique, commerciale, financière ou " +
        "relatives à des éléments auxquels des droits de propriété intellectuelle sont " +
        "attachés. Cette liste n’est toutefois pas limitative. Ces informations ne devront pas, " +
        "d'une quelconque façon, être divulguées à des tiers. Ils garantissent la " +
        "confidentialité des informations, de quelque nature qu'elles soient, écrites ou " +
        "orales, dont ils auront connaissance dans le cadre de l'exécution de leurs relations " +
        "commerciales et s'interdisent de les communiquer aux personnes autres que " +
        "celles qui ont qualité pour en connaître au titre de celles-ci, sous peine de devoir " +
        "en réparer le préjudice subi"
        , 5, 107, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("13. Publicité", 5, 137, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "Le Prestataire est autorisé, sauf désaccord écrit et expresse du Client signifié au " +
        "plus tard à la date d'acceptation du devis, à citer le nom du Client dans son " +
        "argumentaire ou publicitaire écrit ou verbal."
        , 5, 140, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("14. Protection des données personnelles", 5, 153, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "Dans le cadre de l'exécution du contrat, SARP est amenée à collecter des données " +
        "personnelles du personnel du Client. Ces données sont traitées par SARP et/ou ses " +
        "sous-traitants afin de gérer la relation client, l'exécution des prestations et la " +
        "facturation. Elles sont conservées pendant la durée nécessaire à l'exécution des " +
        "obligations légales et contractuelles de SARP à compter de la collecte. " +
        "Conformément au Règlement Général sur la Protection des Données, vous " +
        "disposez d’un droit d'interrogation, d'accès, de rectification et portabilité et " +
        "d'opposition pour motifs légitimes relativement à l’ensemble des données vous " +
        "concernant. Ce droit peut être exercé par l'envoi d’un courriel accompagné d'une " +
        "copie d'un titre d'identité à l'adresse suivante : dpo.sarp@veolia.com. Pour " +
        "information, le Délégué à la Protection des Données est joignable à l'adresse " +
        "suivante : dpo.sarp@veolja, com."
        , 5, 156, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });

      doc.setFont(undefined, 'bold');
      doc.text("15. Droit applicable - Règlement des litiges", 5, 190, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
      });
      
      doc.setFont(undefined, 'normal');
      doc.text(
        "l’ensemble des relations contractuelles entre SARP et le Client issu de l'application " +
        "des présentes conditions générales de Prestations, et les éventuels accords " +
        "particuliers qui pourraient être conclus, et tous les litiges en découlant, quelle " +
        "qu'en soit la nature, seront soumis à tous égards au droit français. " +
        "En cas de différend découlant de leurs relations contractuelles ou en relation avec " +
        "celles-ci, SARP et le Client s'efforceront de parvenir à un règlement amiable dans " +
        "le délai d'un mois à compter de la réception de la notification par écrit du " +
        "différend par SARP ou le Client.\n " +
        "Si toutefois, ils ne peuvent parvenir à un règlement amiable du différend dans le " +
        "délai ci-dessus énoncé de un mois, celui-ci sera soumis au Tribunal de commerce " +
        "de Nanterre."
        , 5, 193, {
        maxWidth: (doc.internal.pageSize.width - 20)/ 2,
        align: "justify"
      });
      doc.setDrawColor(0); 
      doc.rect(30, 230, 45, 25); // empty square    
      doc.text("Signature du Client datée et \n" +
      "précédée de la mention manuscrite \n " +     
      "« Bon pour accord »", 
      (doc.internal.pageSize.width / 2) / 2, 233,
      {
        maxWidth: 45,
        align: 'center'
      });

      doc.line(35, 250, 70, 250); // horizontal line  
    }

    public transformsRow(start: number, end: number, devis: DevisDTO) {
      var table = [];
      var lignes = devis.prestationsClient[0].lignes;
      for(var i = start; i < end; i++) {
        var prestations = [];
        prestations.push({
          content: lignes[i].libelle,
          styles: {
            halign:'left',
          }
        });
        table.push(prestations)
      }
      return table;
    }
}