import {environment} from "../environments/environment";


export function addRingcentral() {
    const ring_central = JSON.parse(localStorage.getItem("ring_central"));
    if (ring_central) {
        var rcs = document.createElement("script");
        rcs.src = "https://apps.ringcentral.com/integration/ringcentral-embeddable/2.x/adapter.js?clientId=" + environment.ring_central_id + "&appServer=" + environment.ring_central_server;
        var rcs0 = document.getElementsByTagName("script")[0];
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
    const ring_centralCX = JSON.parse(localStorage.getItem("ring_central_cx"));
    if (ring_centralCX) {
        var rcs = document.createElement("script");
        rcs.src = "https://ringcentral.github.io/engage-voice-embeddable/adapter.js?clientId=" + environment.ring_central_id + "&appServer=" + environment.ring_central_server;
        var rcs0 = document.getElementsByTagName("script")[0];
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
}