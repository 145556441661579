import { Component, EventEmitter, Input, NgZone, OnInit, Optional, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { EntrepriseService } from 'src/app/modules/core/services/tier/entreprise.service';
import { HlmService } from 'src/app/modules/core/services/tier/hlm.service';
import { MairieService } from 'src/app/modules/core/services/tier/mairie.service';
import { ParticulierService } from 'src/app/modules/core/services/tier/particulier.service';
import { SyndicService } from 'src/app/modules/core/services/tier/syndic.service';
import { TierService } from 'src/app/modules/core/services/tier/tier.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { EntrepriseDTO } from 'src/app/modules/core/shared/dtos/entrepriseDTO';
import { HlmDTO } from 'src/app/modules/core/shared/dtos/hlmDTO';
import { MairieDTO } from 'src/app/modules/core/shared/dtos/mairieDTO';
import { ParticulierDTO } from 'src/app/modules/core/shared/dtos/particulierDTO';
import { SyndicDTO } from 'src/app/modules/core/shared/dtos/syndicDTO';
import { TierDTO } from 'src/app/modules/core/shared/dtos/tierDTO';
import { ModalsTierInfos } from 'src/app/modules/core/shared/modals-menu-info';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { SiteGeoModel } from 'src/app/modules/core/shared/models/site-geo.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ModalAjoutDemandeComponent } from '../../../demande/modal-ajout-demande.component';
import { ModalSelectTier } from '../../modalSelectTier/modal-select-tier';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filterTierDTO } from 'src/app/modules/core/shared/dtos/filterTierDTO';
import { tierPgQueryResponse } from 'src/app/modules/core/shared/dtos/tierPgQueryResponse';
import { TierPgResult } from 'src/app/modules/core/shared/dtos/tierPgResult';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { AutocompleteCodePostalComponent } from 'src/app/modules/shared/components/autocomplete-code-postal/autocomplete-code-postal.component';
import { CityData } from 'src/app/modules/core/shared/models/city-data.model';
import { AutocompleteVilleComponent } from 'src/app/modules/shared/components/autocomplete-ville/autocomplete-ville.component';
import { GmapsService } from 'src/app/modules/shared/gapiServices/gmaps.service';
import { BasicTierArrayComponent } from 'src/app/modules/shared/components/array/basic.tier.array.component';

@Component({
  selector: "app-search-tiers",
  templateUrl: "./search-tiers.component.html",
  styleUrls: ["./search-tiers.component.scss"],
})
export class SearchTiersComponent implements OnInit {
  allowCreate: boolean;

  @Input() isInCreateTiersModal : boolean

  @Output() tierOpen = new EventEmitter<TierModel>();
  @Output() tierChange = new EventEmitter<ModalsTierInfos>();
  @Output() tierSelected = new EventEmitter<TierModel>();
  mairieDataSource: TierModel[] = [];
  simpleTierDataSource: TierModel[] = [];
  particulierDataSource: TierModel[] = [];
  tiersDataSource: TierModel[] = [];
  entrepriseDataSource: TierModel[] = [];
  syndicDataSource: TierModel[] = [];
  hlmDataSource: TierModel[] = [];
  dataSource = [];
  length: number;
  pageSize = 10;

  entreprise = new EntrepriseDTO();
  syndic = new SyndicDTO();
  tiers = new TierDTO();
  hlm = new HlmDTO();
  mairie = new MairieDTO();
  particulier = new ParticulierDTO();

  result: any;
  panelOpenState: boolean;
  loading: boolean;
  selectedLink: string;

  users: UserModel[] = [];
  user: UserModel;
  simpleQueryResponse = new SimpleQueryResponse<TierModel>();
  simpleQuerySiteGeo = new SimpleQuery<SiteGeoModel>();

  @ViewChild("addresstext") addresstext: any;
  @ViewChild(AutocompleteVilleComponent) autocVille: AutocompleteVilleComponent;
  @ViewChild(AutocompleteCodePostalComponent) autocCode: AutocompleteCodePostalComponent;

  @ViewChild("adresseOption") adresseOption: any;

  @ViewChild(BasicTierArrayComponent) tableTier: BasicTierArrayComponent;

  searchValue = '';
  autocompleteOptions: string[] = [];
  descriptionToPlaceIdMap = {};

  pacContainer = document.getElementsByClassName('.pac-container');

  tiersFilter = new filterTierDTO();
  tierRechercheForm = this.fb.group({
    raisonSocial: [null],
    idJason: [null],
    address: [null],
    ville: [null],
    libelle: [null],
    codePostal: [null, Validators.compose([Validators.maxLength(5)])],
    nomInterlocuteur: [null],
    prenomInterlocuteur: [null],
    telInterlocuteur: [null],
    adresseRefonte: [''],
  });
  IsWait: boolean = false;
  toolTipAdresses: string = "";

  tierWithPgDataSource: TierPgResult[] = [];
  pgQueryResponse = new tierPgQueryResponse();

  isFormEmpty = true;
  
  villeBounds;
  autocompleteAdresse: google.maps.places.Autocomplete;
  gmapsSubscriber: any;

  selectedCity: CityData = new CityData();

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private tierService: TierService,
    private entrepriseService: EntrepriseService,
    private mairieService: MairieService,
    private particulierService: ParticulierService,
    private syndicService: SyndicService,
    private hlmService: HlmService,
    private demandeService: DemandeService,
    private sessionService: SessionService,
    private zone: NgZone,
    private IntercomToastrService: IntercomToastrService,
    private userService: UserService,
    private gmapsService: GmapsService,

    @Optional() public dialogReftiers: MatDialogRef<ModalSelectTier>,
    private router: Router,
    private toastrLoadingService: ToastrLoadingService
  ) {
    this.panelOpenState = true;
    this.loading = false;
    this.selectedLink = "SimpleTier";
    this.allowCreate = true;
    if (this.router.url === "/prise-appel") {
      this.allowCreate = false;
    } else {
      this.allowCreate = true;
    }
   
  }

  ngOnChanges(changes : SimpleChange){
    this.getPlaceAutocomplete();
  }
  
  ngOnInit(): void {
    this.pageSize = 10;
    this.tiersFilter.pageSize = this.pageSize;
    this.simpleQuerySiteGeo.entry = new SiteGeoModel();
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
    });

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo;

    this.tierRechercheForm.get("telInterlocuteur").valueChanges.subscribe(inputTel => {
      this.tierRechercheForm.get("telInterlocuteur").patchValue(this.formatPhoneNumper(inputTel), { emitEvent: false });
    });
  }

  clearForm(value) {
    switch (value) {
      case "Interlocuteur":
        this.tierRechercheForm.patchValue({
          nomInterlocuteur: null,
          prenomInterlocuteur: null,
          telInterlocuteur: null,
        });
        break;
      case "Adresse":
        // Clear autocomplete Bounds
        this.villeBounds = null;
        this.autocompleteAdresse.setBounds(this.villeBounds);
        this.selectedCity = new CityData();
        this.selectedCity.codesPostaux = [null];
        this.selectedCity = Object.assign({}, this.selectedCity);
        this.autocCode.codePostal = null;

        this.tierRechercheForm.patchValue({
          address: null,
          ville: null,
          libelle: null,
          codePostal: null,
          adresseRefonte: null,
        });
        break;
      case "Tiers":
        this.tierRechercheForm.patchValue({
          raisonSocial: null,
          idJason: null,
        });
        break;
      default:
        break;
    }
    this.onSubmitForm();
  }

  clearTrieAndSubmit() {
    if (this.tableTier?.sort) {
      this.tiersFilter.pageNumber = 0;
      this.tiersFilter.order = 'Similarity';
      this.tiersFilter.descending = true;
      this.tableTier.sort.active = 'Similarity';
      this.tableTier.sort.direction = 'desc';
      this.tableTier.simpleQuery.order = 'Similarity';
      this.tableTier.simpleQuery.descending = true;
      this.tableTier.sort.sort({ id: 'Similarity', start: 'desc', disableClear: false }); 
    }
    this.onSubmitForm();
  }

  onSubmitForm() {
    let idSearch: number;
    this.tierWithPgDataSource = [];
    this.pgQueryResponse = new tierPgQueryResponse();
    //On each change update the adress outputed to parent componenet
    var oldpageSize =
      this.tiersFilter.pageSize != null ? this.tiersFilter.pageSize : 25;
    var oldpageOrder =
      this.tiersFilter.order != null ? this.tiersFilter.order : "Similarity";
    var oldPageDesc =
      this.tiersFilter.descending != null ? this.tiersFilter.descending : false;

    this.tiersFilter = new filterTierDTO();
    this.tiersFilter.pageNumber = 0;
    this.tiersFilter.pageSize = oldpageSize;
    this.tiersFilter.order = oldpageOrder;
    this.tiersFilter.descending = oldPageDesc;

    this.tiersFilter.siteGeo.Adresse = this.tierRechercheForm.value.address;
    this.tiersFilter.siteGeo.CodePostal =this.tierRechercheForm.value.codePostal;
    this.tiersFilter.siteGeo.Ville = this.tierRechercheForm.value.ville;
    this.tiersFilter.siteGeo.Libelle = this.tierRechercheForm.value.libelle;
    this.tiersFilter.interlocuteur.Nom = this.tierRechercheForm.value.nomInterlocuteur;
    this.tiersFilter.interlocuteur.Prenom = this.tierRechercheForm.value.prenomInterlocuteur;
    this.tiersFilter.interlocuteur.TelPrincipal = this.tierRechercheForm.value.telInterlocuteur;
    this.tiersFilter.tiers.libelle = this.tierRechercheForm.value.raisonSocial;
    this.tiersFilter.tiers.identifiantJason = this.tierRechercheForm.value.idJason;

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    if (!isEmptySiteGeo||!isEmptyTier||!isEmptyInterloc) {
      idSearch = this.toastrLoadingService.newSearch();
      this.tierService
        .GetFromObjetPgWithPagination(this.tiersFilter)
        .subscribe((result) => {
          this.tierWithPgDataSource = result.tiers;
          this.pgQueryResponse = result;
          this.length = result.count;
          this.loading = false;
          this.toastrLoadingService.endSearch(idSearch);
          this.searchResult(result.tiers);
          this.IsWait = false;
          if (this.autocCode.codePostal && this.autocCode.codePostal.nativeElement) {
            this.autocCode.codePostal.nativeElement.blur();
          }
        }, error => {
          this.toastrLoadingService.endSearch(idSearch);
        });
    }
    // this.tiersFilter = Object.assign({},this.tiersFilter)
  }

  formatPhoneNumper(input: string){
    let tel = null;
    if (input) {
      input = input.slice(0, 14);
      const tel1 = input.replace(/[^\d]/g, '');
      let tel2 = '';
      for (let i = 0; i < tel1.length; i = i + 2 ) {
        tel2 += tel1.substring(i, i + 2) + '.';
      }
      tel = tel2.substr(0, tel2.length - 1);
    }
    return(tel);
  }

  private getPlaceAutocomplete() {
    this.autocompleteAdresse = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        bounds: this.villeBounds,
        strictBounds: false,
        componentRestrictions: { country: "FR" },
        types: ["address"], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(this.autocompleteAdresse, "place_changed", () => {
        const place = this.autocompleteAdresse.getPlace();
      
        //Add street number to adress form if user choses
        if (place) {
          let streetNum = this.getStreetNumber(place);
          let street = this.getStreet(place);
          let city = this.getville(place);
          let cp = this.getPostCode(place);
          if (this.getStreetNumber(place) && street) {
            this.tierRechercheForm.patchValue({
              address: streetNum + " " + street
            });
          } else if (street) {
            this.tierRechercheForm.patchValue({
              address: street
            });
          } 
          if (city) {
            this.tierRechercheForm.patchValue({
              ville: city
            });
            this.selectedCity.nom = city;
          } 
          if (cp) {
            this.tierRechercheForm.patchValue({
              codePostal: cp
            });
            this.selectedCity.codesPostaux = [cp];
          }
          if (city || cp) {
            this.selectedCity = Object.assign({}, this.selectedCity);
          }
        }
        this.zone.run(() => this.onSubmitForm()); // run inside angular zone  
        this.addresstext.nativeElement.blur();
    });
  }

  onCodePostalChange(selectedCity: CityData) {
    if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      this.tierRechercheForm.get('codePostal').setValue(selectedCity.codesPostaux[0]);
      this.tierRechercheForm.get('ville').setValue(selectedCity.nom);
      this.onSubmitForm();
      if (!selectedCity.codesPostaux[0] || selectedCity.codesPostaux[0] === "") {
        this.autocompleteAdresse.setBounds(null);
      } else {
        this.gmapsService.getGeoCodeResultBoundsByCodePostal(selectedCity.codesPostaux[0]).subscribe((gmapGeo: google.maps.GeocoderGeometry[]) => {
          if (gmapGeo && gmapGeo.length >= 1) {
            this.autocompleteAdresse.setBounds(gmapGeo[0].bounds);
          } else {
            this.autocompleteAdresse.setBounds(null);
          }
        });
      }
    } else if (this.selectedCity?.nom !== selectedCity.nom) {
      this.onCityChange(selectedCity);
    }
  }

  onCityChange(selectedCity: CityData) {
    if (this.selectedCity?.nom !== selectedCity.nom) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      this.tierRechercheForm.get('codePostal').setValue(selectedCity.codesPostaux[0]);
      this.tierRechercheForm.get('ville').setValue(this.autocVille.villetext);
      this.onSubmitForm();
      if (!selectedCity.codesPostaux[0] || selectedCity.codesPostaux[0] === "") {
        this.autocompleteAdresse.setBounds(null);
      } else {
        this.gmapsService.getGeoCodeResultBoundsByCodePostal(selectedCity.codesPostaux[0]).subscribe((gmapGeo: google.maps.GeocoderGeometry[]) => {
          if (gmapGeo && gmapGeo.length >= 1) {
            this.autocompleteAdresse.setBounds(gmapGeo[0].bounds);
          } else {
            this.autocompleteAdresse.setBounds(null);
          }
        });
      }
    } else if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.onCodePostalChange(selectedCity);
    }
  }

  ngAfterViewInit() {
    //After page load, initialize google autocomplete
    this.getPlaceAutocomplete();
  }

  //----------Helpers methods for google maps autocomplete--------------
  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: "long_name" },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }
  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: "long_name" },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getville(place) {
    const COMPONENT_TEMPLATE = { locality: "long_name" },
      ville = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return ville;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: "short_name" },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getAddrComponent(place, componentTemplate) {
    let result;
    if (place.address_components) {
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentTemplate[addressType]) {
          result = place.address_components[i][componentTemplate[addressType]];
          return result;
        }
      }
    }
  }


  pageChange(pageEvent: SimpleQuery<any>) {
    let idSearch: number;
    this.tiersFilter.pageNumber = pageEvent.pageNumber;
    this.tiersFilter.pageSize = pageEvent.pageSize;
    if (pageEvent.order) {
      this.tiersFilter.order = pageEvent.order;
    }
    if (pageEvent?.descending !== undefined && pageEvent?.descending !== null) {
      this.tiersFilter.descending = pageEvent.descending;
    }

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    if (!isEmptySiteGeo||!isEmptyTier||!isEmptyInterloc) {
      idSearch = this.toastrLoadingService.newSearch();
      this.tierService
        .GetFromObjetPgWithPagination(this.tiersFilter)
        .subscribe((result) => {
          this.tierWithPgDataSource = result.tiers;
          this.pgQueryResponse = result;
          this.length = result.count;
          this.loading = false;
          this.toastrLoadingService.endSearch(idSearch);
          this.searchResult(result.tiers);
        }, error => {
          this.toastrLoadingService.endSearch(idSearch);
        });
    }
  }

  onTierOpen(tiers: TierModel) {
    if (!window.location.href.includes("prise-appel")) {
      this.router.navigate(["/fiche-contact", tiers.id]);
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/fiche-contact", tiers.id])
      );
      window.open(url, '_blank');    
    }

  }

  onAddDemandeOpen(tiers: TierModel): void {
    const dialogRef = this.dialog.open(ModalAjoutDemandeComponent, {
      disableClose: true,
      width: "900px",
      data: {
        tierFactureId: tiers.tierFactureId,
        tierId: tiers.id,
        result: this.result,
        demande: new DemandeModel(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.result = result;
      if (this.result) {
        this.demandeService
          .getFromTier(tiers.id)
          .subscribe((demande: DemandeModel[]) => {
            this.dataSource = demande;
          });
      }
    });
  }

  onTierSelected(tier){
    this.tierSelected.emit(tier)
  }

  searchResult(searchWord: any) {
    if (searchWord.length === 0) {
      this.IntercomToastrService.warning(
        "Oups : " + "Aucun résultat pour votre recherche"
      );
    } 
  }

}
