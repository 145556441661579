<div class="col-md-12 ">
    <div class="col-md-12" style="display: flex;justify-content: space-between;">
        <h4>{{libelle}}</h4>
        <button mat-mini-fab color="primary" matTooltip="Ouvrir le tiers" (click)="onTierOpen()" *ngIf="tier.id">
            <mat-icon style="color: white;" > person_pin </mat-icon>
        </button>
        <button mat-mini-fab color="primary" matTooltip="Changer le tiers facturé" (click)="onEditTiersFacture()" *ngIf="modifiable">
            <mat-icon style="color: white;"> create </mat-icon>
          </button>
    </div>
   
    <div class="col-md-8">
        <mat-form-field class="full-width">
            <mat-label>Libelle</mat-label>
            <input matInput [value]="tier.libelle ? tier.libelle : ''" disabled>
          </mat-form-field>
    </div>

    <div class="col-md-4">
        <mat-form-field class="full-width">
            <mat-label>ID Jason</mat-label>
            <input matInput [value]="tier.identifiantJason  ? tier.identifiantJason : ''" disabled>
          </mat-form-field>
      </div>

    <div class="col-md-6">
        <app-input-telephone
        [(phoneNumber)]="tier.telephone"
        libelle="Téléphone"
        [childFieldStatus]="true"
        ></app-input-telephone>
    </div>
    <div class="col-md-6">
        <mat-form-field class="full-width">
            <mat-label>E-mail</mat-label>
            <input matInput [value]="tier.email ? tier.email : ''" disabled>
          </mat-form-field>
    </div>
</div>