<mat-tab-group class="mat-big-tab" style="padding-top: 0px; margin-bottom: 16px;" #tabgroup color="primary" mat-stretch-tabs [(selectedIndex)]="selectedIndex"> 
  <mat-tab label="Demande"> 
    <app-prise-appel-demande [demandeDuplique]="demande">
    </app-prise-appel-demande>
  </mat-tab>
  <mat-tab label="Urgence"> 
    <app-prise-appel-urgence [demandeDuplique]="demande">
    </app-prise-appel-urgence>
  </mat-tab>
  <mat-tab label="Devis"> 
    <app-devis-rapide [devisDTO]="devisDTO" [tabIndex]="selectedIndex">
    </app-devis-rapide> 
  </mat-tab>
</mat-tab-group>
