import { PropertiesInfo } from './../modals-menu-info';
import { DatePipe } from '@angular/common';
import { TierLinkedDTO } from './tierLinkedDTO';
import { Injectable } from "@angular/core";
import { UserModel } from '../models/users/user.model';
@Injectable()
export class DemandeDTO extends TierLinkedDTO {
    public typeDemandeId: number;
    public rechercheTitle?: string;
    public siteGeoId: number;
    public interlocuteurId: number;
    public objet: string;
    public numDemande: string;
    public statut = '';
    public createurId: number;
    public nonClos?: boolean;
    public commercialId: number;
    public gestionnaireId: number;
    public commentaire = '';
    public commentaireApprox = '';
    public DateSmallerInterventionString: Date = null;
    public DateBiggerInterventionString: Date = null;
    public prestationIds?: string = null;
    public tierId: number;
    public id: number;
    public reponseForm = '';
    public numeroDemandeOrigine:string;

    public static getTitle(titleArray: PropertiesInfo[], demande: DemandeDTO, selectedTier: string, user: UserModel): string {
      let rechercheTitle = '';
      if (selectedTier !== null && selectedTier !== undefined && selectedTier !== 'Aucun') {
        rechercheTitle += 'Tiers : ' + selectedTier + ', ';
      }
      if (user !== null && user !== undefined) {
        rechercheTitle += 'Réalisateur : ' + user.nom + ' ' + user.prenom + ', ';
      }
      const dateIndex = titleArray.findIndex(p => p.property === 'DateSmallerInterventionString');
      if (dateIndex !== -1) {
        if (demande[titleArray[dateIndex].property] !== null) {
          const d = new DatePipe('fr-FR');
          rechercheTitle += titleArray[dateIndex].libelle + ' : ' +
          d.transform(<Date>demande[titleArray[dateIndex].property], 'dd/MM/yyyy') + ', ';
        }
        titleArray.splice(dateIndex, 1);
      }
      for (const key of titleArray) {
        if (demande.hasOwnProperty(key.property)) {
          if (demande[key.property] !== null && demande[key.property] !== undefined  &&  demande[key.property].length !== 0) {
            rechercheTitle += key.libelle + ' : ' + demande[key.property] + ', ';
          }
        }
      }
      if (rechercheTitle.length > 2) {
        rechercheTitle = rechercheTitle.substring(0, 1).toUpperCase() + rechercheTitle.substring(1, rechercheTitle.length - 2);
      }
      return rechercheTitle;
    }
}
