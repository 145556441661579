import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import { AgenceModel } from "src/app/modules/core/shared/models/rsa/agence.model";
import { AgenceService } from "src/app/modules/core/services/rsa/agence.service";
import * as _ from "lodash";

@Component({
  selector: "app-autocomplete-agence-multiple-select",
  templateUrl: "./autocomplete-agence-multiple-select.component.html",
  styleUrls: ["./autocomplete-agence-multiple-select.component.scss"],
})
export class AutocompleteAgenceMultipleSelectComponent implements OnInit {
  @Input() agenceIds: string;
  @Input() agencesIdsArray;
  @Input() selectedAgenceIds;
  @Input() inputSocietesString : string;
  _val: Subject<Set<number>> = new Subject();
  @Input()
  set eventsSubjectAgenceArray(val: Subject<Set<number>>) {
    this._val = val;
  }
  
  @Output() updated = new EventEmitter<number[]>();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isLoaded = new BehaviorSubject<boolean>(false);
  agenceCtrl = new UntypedFormControl();
  filteredAgences: Observable<string[]>;

  inputAgenceArray = new Set();

  // Input / Output
  // toute les agences
  selectableAgencesString: string[] = [];
  allAgences: AgenceModel[] = [];
  selectedAgenceString: string[] = [];
  agences: AgenceModel[] = [];
  eventsSubscription: Subscription; ///aaaaa
  
 
  
  @ViewChild('agenceInput', { static: true }) agenceInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(private agenceService: AgenceService
    ) {

   }

  ngOnInit() {
  this.agenceService.getAllManaged(1).subscribe(agences => {
    this.agences = agences as AgenceModel[];
    this.isLoaded.next(true);
  });
  this.eventsSubscription = this._val.subscribe((x) => {
    this.inputAgenceArray = x;
    this.selectableAgencesString  = [];
    this.allAgences = [];
    this.selectedAgenceString = [];
    this.loadAgences(this.agences);
  });
  }

  ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectableAgencesString  = [];
    this.allAgences = [];
    this.selectedAgenceString = [];
    this.isLoaded.pipe(filter(val => val)).subscribe(_ => 
      this.loadAgences(this.agences)
    );
  }
  
    /* Charger les agences  */
  loadAgences(agences: AgenceModel[]) {
    if (agences) {
      this.allAgences = agences;
      let listSocietes = this.inputSocietesString.split(',').filter(x=>x).map(Number);
      this.agencesIdsArray = [...this.inputAgenceArray];
      if(this.agencesIdsArray.length != 0){
        this.agencesIdsArray.forEach(idInput=>{
          if(agences.find(agence=>agence.id ==idInput) != undefined){
            let agenceFound = agences.find(agence=>agence.id ==idInput)
            if(!this.selectedAgenceString.find(libelle => libelle ==  this.agenceName(agenceFound)) && (listSocietes.includes(agenceFound.societeId) || listSocietes.length ==0)){
              this.selectedAgenceString.push( this.agenceName(agenceFound))
            }
          }
        })
        let tempAgences = Object.assign([],agences);
        tempAgences = tempAgences.filter(agence=>!this.agencesIdsArray.includes(agence.id))
        tempAgences.forEach(agence => {
        if(!this.selectableAgencesString.find(libelle => libelle ==  this.agenceName(agence)) && (listSocietes.includes(agence.societeId) || listSocietes.length ==0) ){
          this.selectableAgencesString.push( this.agenceName(agence))
        }})
      }else{
          agences.forEach(agence=>{
            if(!this.selectableAgencesString.find(libelle => libelle ==  this.agenceName(agence)) && (listSocietes.includes(agence.societeId) || listSocietes.length ==0)){
              this.selectableAgencesString.push( this.agenceName(agence))
            }
          })
      }
      this.selectableAgencesString.sort();
      this.selectedAgenceString.sort();
      this.filteredAgences = this.agenceCtrl.valueChanges.pipe(
        startWith(null),
        map((agence: string | null) => agence ? this._filter(agence) : this.selectableAgencesString.slice()));
    }
  }  
  
  /* Supression d'une agence*/
  remove(agence: string): void {
    let id;
    const index = this.selectedAgenceString.indexOf(agence);
    if (index >= 0) {
      this.selectableAgencesString.push(this.selectedAgenceString[index]);
      this.selectableAgencesString.sort();
      this.selectedAgenceString.splice(index, 1);
      this.selectedAgenceString.sort();
    }

    this.agencesIdsArray.splice(this.agencesIdsArray.indexOf(this.agences.find(x=>  this.agenceName(x) == agence).id),1)
    this.updated.emit(this.agencesIdsArray);

    this.filteredAgences = this.agenceCtrl.valueChanges.pipe(
      startWith(null),
      map((myAgence: string | null) => myAgence ? this._filter(myAgence) : this.selectableAgencesString.slice()));
  }

  addElement(inputValue,inputIndex){
    const value = inputValue;
    const index = inputIndex;

    if ((value || '').trim() && this.agences.find(x=>  this.agenceName(x) == value) != undefined) {
      this.selectedAgenceString.push(value.trim());
      this.selectableAgencesString.splice(index, 1);
      this.selectableAgencesString.sort();
      this.selectedAgenceString.sort();
      this.agencesIdsArray.push(this.agences.find(x=>  this.agenceName(x) == value).id)
    }

    this.agenceCtrl.setValue(null);
    this.updated.emit(this.agencesIdsArray);

    this.filteredAgences = this.agenceCtrl.valueChanges.pipe(
      startWith(null),
    map((agence: string | null) => agence ? this._filter(agence) : this.selectableAgencesString.slice()));
    this.updated.emit(this.agencesIdsArray);
    this.agenceInput.nativeElement.blur();
    this.agenceInput.nativeElement.focus();
  }

  
    /* Ajout d'une agence avec selection */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.addElement(event.option.viewValue,this.selectableAgencesString.indexOf(event.option.viewValue))
    this.agenceInput.nativeElement.value = '';
    this.agenceInput.nativeElement.blur();
  }


        /* Ajout d'une agence avec Entrer */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    this.addElement(event.value,this.selectableAgencesString.indexOf(event.value))
    if (input) {
      input.value = '';
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return this.selectableAgencesString.filter(agence => agence.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
  }

  agenceName(agence: AgenceModel) {
    return (agence.societe.region.libelle + " / " + agence.societe.libelle + " / " + agence.libelle).trim();
  }
}
