import { ActionModel } from '../../shared/models/action.model';
import { ActionService } from './../../services/action.service';
import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, Injector } from '@angular/core';
import { ModalAjoutActionComponent } from './modal-ajout-action.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TierModel } from './../../shared/models/tier/tier.model';
import { TrieTabService } from '../../services/trie-tab.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SimpleQuery } from './../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from './../..//shared/simple_query/simpleQueryResponse';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { SessionService } from 'src/app/modules/shared/session.service';

export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnChanges {
  private standardWidth = '90%';
  private standardHeight = '99vh';
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated = new EventEmitter<boolean>();
  @Input() tier: TierModel;
  mySubscription: any;
  result: boolean;
  selectedAction: ActionModel;
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['dateRealisation', 'heureDebut', 'duree', 'typeAction', 'realisateur', 'objetAction', 'commentaire', 'statut', 'modifications'];
  dataSource: ActionModel[];
  matDataSource: MatTableDataSource<ActionModel>;
  pageEvent: PageEvent;
  sortState: Sort = {active: 'dateRealisation', direction: 'desc'};
  length: number;
  pageIndex: number;
  pageSize = 10;
  private modalsService: ModalsService;

  constructor(private router: Router,
    private actionService: ActionService,
    private sessionService: SessionService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    injector: Injector,
    private comp: TrieTabService) {
    this.modalsService = injector.get('ModalsService');
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tier !== undefined && this.tier.id !== undefined) {
      this.loadPage();
    }
  }


  onAddAction(): void {
    const dialogRef = this.dialog.open(ModalAjoutActionComponent, {
      disableClose: true,
      autoFocus: false,
      width: this.standardWidth,
      maxHeight: this.standardHeight,
      data: {
        tierId: this.tier.id,
        realisateurId: this.sessionService.currentUserValue.id,
        action: new ActionModel(),
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result[0];
      if (this.result) {
        this.loadPage();
      }
    });
  }

  onOpenAction(action: ActionModel): void {
    this.actionService.get(action.id).subscribe(action => {
      this.modalsService.openAction(action).subscribe(result => {
        if  (result) {
          this.onUpdated.emit(true);
          this.loadPage();
        }
      });
    });
  }


  onVoirAction(action: ActionModel): void {
    this.actionService.get(action.id).subscribe(action => {
      this.modalsService.openClosedAction(action).subscribe(result => {
        result;
      });
    });
  }

  changePage(event?: PageEvent) {
    this.pageIndex =  event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPage();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadPage();
  }

  loadPage() {
    const query = new SimpleQuery<ActionModel>();
    query.entry = null;
    query.pageNumber = this.pageIndex;
    query.pageSize = this.pageSize;
    query.order = this.sortState.active.charAt(0).toUpperCase() + this.sortState.active.slice(1);
    query.descending = this.sortState.direction === 'desc' ? false : true;
    this.actionService.getFromTierQuery(this.tier.id, query).subscribe((response: SimpleQueryResponse<ActionModel>) => {
      this.dataSource = response.objets;
      const data = this.dataSource.slice();
      this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
      this.length = this.dataSource.length;
      this.length = response.count;
      this.pageIndex = response.pageNumber;
      this.pageSize = response.pageSize;
    });
  }
}

