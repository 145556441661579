import { SiteGeoModel } from '../../../core/shared/models/site-geo.model';
import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { GmapsSiteGeoModel } from 'src/app/modules/core/shared/models/gmapsSiteGeo.model';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ModalSelectAdresseComponent } from '../modals/modal-select-adresse/modal-select-adresse.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html'
})
export class AddressCardComponent implements OnChanges {

  @Input() adresse: SiteGeoModel = new SiteGeoModel();
  @Input() modifiable: boolean;
  @Input() adresseChangeDisabled = false;
  @Input() realisateur: UserModel;
  @Input() tier: TierModel;
  @Output() selectedAdresse = new EventEmitter<SiteGeoModel>();

  // focus
  @ViewChild('input') input: ElementRef;

  /*Input pour communiquer avec le component parent*/
  timeTravel: string = "";
  distanceTravel: string = "";
  gmapsAdresses: GmapsSiteGeoModel[] = [];

  constructor(
    public dialog: MatDialog,
    private agenceService: AgenceService,
    private sessionService: SessionService,
    private IntercomToastrService: IntercomToastrService) {
   }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.adresse){
      this.adresse = new SiteGeoModel();
    }
    //GMapTravel
    this.getTime();
  }

  
  /* selected event*/
  adresseChange(value: string) {
    this.getTime();
  }

  editAdresse(){
    const dialogRef = this.dialog.open(ModalSelectAdresseComponent, {
      disableClose: false,
      data: {
        tier: this.tier,
      },
      width: '1500px',
      panelClass: 'mat-dial-no-padd'
    });
    dialogRef.afterClosed().subscribe(siteGeo => {
      if(siteGeo){
        this.adresse = siteGeo;
        this.selectedAdresse.emit(this.adresse);
      }
    });
  }


  openGMap() {
    let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;
   if (this.realisateur?.agenceId){
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {
          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else if (this.sessionService.currentUserValue?.agenceId) {    
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agenceUser: AgenceModel) => {
        if (agenceUser.adresse && agenceUser.codePostal && agenceUser.ville) {
          let src = agenceUser.adresse + ', ' + agenceUser.codePostal + ' ' + agenceUser.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else {
      window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
    }
  }

  getTime() {
    if (!this.adresse?.adresse || !this.adresse.codePostal || !this.adresse.ville) {
      this.timeTravel = "Adresse d'intervention incomplète ou incorrecte";
    } else if (this.realisateur) {
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            this.distanceTravel = results.routes[0].legs[0].distance.text;
            this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Adresse de l'agence du réalisateur incomplète ou incorrecte"; }
      });
    }  else if (this.sessionService.currentUserValue?.agenceId) {
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Erreur : Adresse de votre agence est incomplète ou incorrecte"; }
      });
    } else { this.timeTravel = "Impossible de calculer l'itinéraire"; }
  }
}
