<!-- Les interlocuteurs du tiers -->
<div class="col-md-12">
  <mat-icon class="col-md-12" class="close-button" (click)="onModalCancel()">close</mat-icon>
</div>
<mat-card class="col-md-12" style="padding: 0;">
  <div class="col-md-12 table" style="padding: 0;">
    <div class="row header" style="margin: 0;">
      <h4 class="col-md-2">
        Contacts
      </h4>
      <mat-form-field class="col-md-8">
        <input matInput placeholder="Recherche contact par nom" [(ngModel)]="this.simpleQueryInterlocuteur.entry.Nom"
          (change)="rechercheContact()">
      </mat-form-field>
      <button
        matPrefix
        mat-stroked-button color="success"
        (click)="onAddInterlocuteur()"
        matTooltip="Ajouter un contact"
        matTooltipPosition="below"
      >
        <mat-icon>add</mat-icon> Ajouter un contact
      </button>
    </div>

    <div class="row">
      <app-interlocuteurs (onUpdated)="onUpdateInterlocuteur($event)" (onSelect)="onSelectContact($event)"
        [simpleQueryResponse]="simpleQueryResponse" [tier]=tier [selectRow]="true"></app-interlocuteurs>
    </div>
  </div>
</mat-card>