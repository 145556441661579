import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { gCardObj, gChatDTO, gChatDTOresponse, gSection } from "./gChatDTO";

@Injectable()
export class GchatService {
    constructor(private http: HttpClient) {
    }

    makeCard(title: string): gCardObj {
        return new gCardObj(title);
    }

    addToCard(card: gCardObj, title: string, text: string, isCollapsible: boolean = false) {
        card.card.sections.push(new gSection(title, text, isCollapsible));
    }
    
    sendMessage(link: string, mess: string): Observable<gChatDTOresponse> {
        let chatDTO = new gChatDTO(mess);
        return this.sendDTO(link, chatDTO);
    }

    sendCards(link: string, cards: gCardObj[], mess?: string): Observable<gChatDTOresponse> {
        let chatDTO = new gChatDTO();
        if (mess) {
            chatDTO.text = mess;
        }
        chatDTO.cardsV2 = cards;
        return this.sendDTO(link, chatDTO);
    }

    sendDTO(link: string, chatDTO?: gChatDTO): Observable<gChatDTOresponse> {
        return this.http.post<gChatDTOresponse>(link, chatDTO);
    }
}