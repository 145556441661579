import { HlmModel } from '../../../../shared/models/tier/hlm.model';
import { EventEmitter, Component, Input, Output } from '@angular/core';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

@Component({
  selector: 'app-hlm',
  templateUrl: './hlm.component.html'
})
export class HlmComponent{
  @Input() tier: HlmModel;
  @Input() required = true;
  @Input() disabled = false;
  @Output() tierChange = new EventEmitter<HlmModel>();
  keyTitleArray = [
    {property: 'raisonSociale', libelle: 'raison sociale'},
    {property: 'marqueEnseigne', libelle: 'marque'},
    {property: 'telephone', libelle: 'téléphone'},
    {property: 'clientNumber', libelle: 'numéro client'},
    {property: 'identifiantJason', libelle: 'identifiant Jason'},
    {property: 'siretSiren', libelle: 'siret/siren'}];


  

  changeEvent() {
    this.tier.rechercheTitle =  TierModel.getTitle(this.keyTitleArray, this.tier);
    this.tierChange.emit(this.tier);
  }
}
