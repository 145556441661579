export class SimpleQuery<TEntity> {
  public entry: TEntity;
  public descending: boolean;
  public pageNumber?: number;
  public pageSize?: number;
  public order?: string;
  constructor(tiers?: TEntity) {
    this.entry = tiers;
    this.pageNumber = 0;
    this.pageSize = 50;
  }
}
