<div *ngIf="simpleQuerySiteGeo.entry.tier">
  <div style="display: flex; justify-content: space-between; align-items: center; margin-right: 5px;">
    <h4 style="white-space: nowrap; display: flex; align-items: center;">
      Sélectionnez l'adresse 
      <div *ngIf="adresseRequired" style="color:rgb(230, 126, 29)"> * </div>
    </h4>
    <div>
      <button mat-stroked-button color="success" type="button" (click)="onAddAdresse()">  <mat-icon >add</mat-icon> Créer une adresse 
      </button>
    </div> 
    <br/>
    <mat-form-field class="col-md-4">
      <input matInput placeholder="Adresse" 
          [(ngModel)]="simpleQuerySiteGeo.entry.adresse"
          (change)="changeAdresses()">
    </mat-form-field> 
    
    <mat-form-field class="col-md-2">
      <input matInput placeholder="Code Postal" 
            [(ngModel)]="simpleQuerySiteGeo.entry.codePostal"
            (change)="changeAdresses()">
    </mat-form-field>
  </div>
  <table mat-table matSort (matSortChange)="trieTab($event)" matSortActive="Similarity" matSortDirection="desc"
    matSortDisableClear [dataSource]="matDataSource">
    <caption></caption>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="actions">Actions</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.siteGeo === selectedAdressesTier}">
          <div class="btn-container">
          <button mat-mini-fab class="tableau-btn"  *ngIf="!updateAdresse" [disabled]="!element.siteGeo.agenceId" color="basic" title="{{element.siteGeo.agenceId ? 'modifier l\'adresse' : 'adresse non modifiable'}}" (click)="onEditAdresse(element);$event.stopPropagation();" aria-label="Ouvrir le tiers">
            <mat-icon class="tableau-mat-icon">edit</mat-icon>
          </button>
          <button mat-mini-fab class="tableau-btn" *ngIf="element.histo" color="basic" title="Historique des demandes" (click)="openHistoriqueDemandesAdresses(element.siteGeo); $event.stopPropagation();" aria-label="Historique des demandes">
            <mat-icon class="tableau-mat-icon">folder_open</mat-icon>
          </button>
        </div>
    </ng-container>
    <ng-container matColumnDef="Adresse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Adresses</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.siteGeo.id === selectedAdresse.id}">
        <div title="{{element.siteGeo.adresse}}">
          {{element.siteGeo.adresse}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="CodePostal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Code postal</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.siteGeo.id === selectedAdresse.id}">
        <div title="{{element.siteGeo.codePostal}}">
          {{element.siteGeo.codePostal}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Ville">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ville</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.siteGeo.id === selectedAdresse.id}">
        <div title="{{element.siteGeo.ville}}">
          {{element.siteGeo.ville}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Similarity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Indice de<br>confiance</th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div >
          {{element.similarity * 100 | number:'1.1-2'}}
        </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="Libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element?.siteGeo.id === selectedAdresse.id}">
        <div title="{{element?.siteGeo.libelle}}">
          {{element.siteGeo?.libelle}}
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsAdresse"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsAdresse;" (click)="selectAdresse($event, row.siteGeo)" ></tr>
  </table>
  <mat-paginator *ngIf="simpleQuerySiteGeo.entry.tier" 
    class="paginator" 
    [length]="length" 
    [pageIndex]="pageIndex" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[5, 10, 25, 50]" 
    (page)="changePage($event)">
  </mat-paginator>
</div>
