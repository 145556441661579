import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TierModel } from '../shared/models/tier/tier.model';
import { InterlocuteurModel } from '../shared/models/interlocuteur.model';
import { SiteGeoModel } from '../shared/models/site-geo.model';
import { TierService } from './tier/tier.service';
import { filterTierDTO } from '../shared/dtos/filterTierDTO';
import { tierPgQueryResponse } from '../shared/dtos/tierPgQueryResponse';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ToastrLoadingService } from './toastrLoading.service';
import { DemandeModel } from '../shared/models/demande.model';
import { DevisDTO } from '../../devis/DTOs/devisDTO';

export class sharedDemande {
  activite: string = null;
  commercial : number = null;
  commentaire : string = null;
  statut : string = null;
}

@Injectable({
  providedIn: 'root'
})

export class PriseAppelService {
  tierRechercheFomShared : UntypedFormGroup =  this.fb.group({
    raisonSocial: [null],
    idJason: [null],
    address: [null],
    ville: [null],
    libelle: [null],
    codePostal: [null, Validators.compose([Validators.maxLength(5)])],
    nomInterlocuteur: [null],
    prenomInterlocuteur: [null],
    telInterlocuteur: [null],
  });

  filterDTOSubject: Subject<filterTierDTO> = new Subject<filterTierDTO>();
  selectedTiers : TierModel = new TierModel()
  selectedInterlocuteur : InterlocuteurModel = new InterlocuteurModel();
  selectedTiersAdresse : SiteGeoModel = new SiteGeoModel()
  addedTiers : TierModel = new TierModel()
  selectedTiersFacture : TierModel = new TierModel()
  searchOrigin : string = "";
  demande: DemandeModel = null;
  devis: DevisDTO = null;
  sharedDemande : sharedDemande = new sharedDemande();

  private tierRechercheFormBehaviorSubject = new BehaviorSubject(this.tierRechercheFomShared);
  currentTierRechercheForm = this.tierRechercheFormBehaviorSubject.asObservable();

  private obsTiersResult: BehaviorSubject<tierPgQueryResponse> = new BehaviorSubject(new tierPgQueryResponse());
  currentTiersResult = this.obsTiersResult.asObservable();

  private selectedTiersBehaviorSubject = new BehaviorSubject({tiers: this.selectedTiers, origin: ""});
  currentSelectedTiers = this.selectedTiersBehaviorSubject.asObservable();

  private selectedInterlocuteurBehaviorSubject = new BehaviorSubject(this.selectedInterlocuteur);
  currentselectedInterlocuteur = this.selectedInterlocuteurBehaviorSubject.asObservable();

  private selectedTiersAdresseBehaviorSubject = new BehaviorSubject(this.selectedTiersAdresse);
  currentselectedTiersAdresse = this.selectedTiersAdresseBehaviorSubject.asObservable();

  private addedTiersBehaviorSubject = new BehaviorSubject({tiers: this.addedTiers, origin: ""});
  currentAddedTiers = this.addedTiersBehaviorSubject.asObservable();

  private selectedTiersFactureBehaviorSubject = new BehaviorSubject(this.selectedTiers);
  currentSelectedTiersFacture = this.selectedTiersFactureBehaviorSubject.asObservable();

  private demandeBehaviorSubject = new BehaviorSubject(this.demande);
  currentDemande = this.demandeBehaviorSubject.asObservable();

  private sharedDemandeBehaviorSubject = new BehaviorSubject(this.sharedDemande);
  currentSharedDemande = this.sharedDemandeBehaviorSubject.asObservable();

  private devisBehaviorSubject = new BehaviorSubject(this.devis);
  currentDevis = this.devisBehaviorSubject.asObservable();

  private searchOriginSubject = new BehaviorSubject(this.searchOrigin);
  currentSearchOrigin = this.searchOriginSubject.asObservable();
  unsubObsTiers: Subscription;
  idSearch: number[] = [];

  constructor(private fb: UntypedFormBuilder, 
    private tierService: TierService,
    private toastrLoadingService: ToastrLoadingService) {
    this.unsubObsTiers = this.filterDTOSubject.pipe(debounceTime(300), switchMap((filterDTO: filterTierDTO) => {
      this.idSearch.push(this.toastrLoadingService.newSearch());
      return this.tierService.GetFromObjetPgWithPagination(filterDTO)
    })).subscribe(res => {
      for(let e of this.idSearch) {
        this.toastrLoadingService.endSearch(this.idSearch.pop());
      }
      this.obsTiersResult.next(res);
    }, e => {
      this.toastrLoadingService.endSearch(this.idSearch.pop());
      throw e;
    }, () => {
      this.toastrLoadingService.endSearch(this.idSearch.pop());
    });
  }

  changeFilter(tiersFilter: filterTierDTO) {
    tiersFilter.siteGeo = Object.assign({},tiersFilter.siteGeo);
    tiersFilter.interlocuteur = Object.assign({},tiersFilter.interlocuteur);
    this.filterDTOSubject.next(Object.assign({},tiersFilter));
  }

  updateTierRechercheForm(inputTiersRechercheForm:UntypedFormGroup){
    this.tierRechercheFormBehaviorSubject.next(Object.assign({},inputTiersRechercheForm));
  }

  updateTierselectedTiers(inputselectedTiers: TierModel, origin: string){
    this.selectedTiersBehaviorSubject.next({tiers: Object.assign({},inputselectedTiers), origin: origin})
    this.selectedTiersAdresseBehaviorSubject.next(new SiteGeoModel());
    this.selectedInterlocuteurBehaviorSubject.next(new InterlocuteurModel());
  }

  updateselectedInterlocuteur(inputselectedInterlocuteur:InterlocuteurModel){
    this.selectedInterlocuteurBehaviorSubject.next(Object.assign({},inputselectedInterlocuteur))
  }

  updateselectedTiersAdresse(inputselectedTiersAdresse:SiteGeoModel){
    this.selectedTiersAdresseBehaviorSubject.next(Object.assign({},inputselectedTiersAdresse))
  }

  updateAddedTiers(inputAddedTiers:TierModel,origin: string){
    this.addedTiersBehaviorSubject.next({tiers: Object.assign({},inputAddedTiers), origin: origin})
  }

  updateTierselectedTiersFacture(inputselectedTiersFacture:TierModel){
    this.selectedTiersFactureBehaviorSubject.next(Object.assign({},inputselectedTiersFacture))
  }

  updateSharedDemande(demande: sharedDemande){
    this.sharedDemandeBehaviorSubject.next(Object.assign({},demande))
  }

  updateDemande(demande: DemandeModel){
    this.demandeBehaviorSubject.next(Object.assign({},demande))
  }

  updateDevis(devis: DevisDTO){
    this.devisBehaviorSubject.next(Object.assign({},devis))
  }

  getDemande(): DemandeModel{
    return this.demandeBehaviorSubject.getValue();
  }

  updateStartSearch(inputStartSearch){
    this.searchOriginSubject.next(Object.assign({},inputStartSearch))
  }

  clearService(){
    let clearForm =    this.fb.group({
      raisonSocial: [null],
      idJason: [null],
      address: [null],
      ville: [null],
      libelle: [null],
      codePostal: [null, Validators.compose([Validators.maxLength(5)])],
      nomInterlocuteur: [null],
      prenomInterlocuteur: [null],
      telInterlocuteur: [null],
    });
    let clearTiers =  new TierModel()
    let clearInterlocuteur = new InterlocuteurModel()
    let clearSiteGeo = new SiteGeoModel()
    let clearOrigin = "priseAppel";
    let clearSearchOrigin = ""

    this.addedTiersBehaviorSubject.next({tiers: clearTiers, origin: clearOrigin});
    this.tierRechercheFormBehaviorSubject.next(clearForm);
    this.obsTiersResult.next(new tierPgQueryResponse());
    this.selectedTiersBehaviorSubject.next({tiers: clearTiers, origin: clearOrigin});
    this.selectedTiersFactureBehaviorSubject.next(clearTiers);
    this.selectedInterlocuteurBehaviorSubject.next(clearInterlocuteur);
    this.selectedTiersAdresseBehaviorSubject.next(clearSiteGeo);
    this.searchOriginSubject.next("");
  }
}
