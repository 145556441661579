import { Component, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: 'app-file-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {
    onChange: Function;
    file: File | null = null;
    disabled = false;

    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
        const file = event && event.item(0);
        this.onChange(file);
        this.file = file;
    }

    constructor(private host: ElementRef<HTMLInputElement>) {
    }
    writeValue(value: null): void {
        // clear file input
        this.host.nativeElement.value = '';
        this.file = null;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }
}

