import { ModalInfoOkComponent } from './../divers/modals/modal-info-ok.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemeStorage, DocsSiteTheme } from '../../../shared/theme-storage.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ThemePalette } from '@angular/material/core';
import { EtiquetteModel } from './../../shared/models/etiquette/etiquette.model';
import { EtiquetteService } from './../../services/etiquette.service';
import { UserEtiquetteModel } from './../../shared/models/etiquette/userEtiquette.model';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActiviteModel } from '../../shared/models/prestation.model';
import { ParametreUtilisateurService } from '../../services/parametre/parametre-utilisateur.service';
import { ActiviteService } from '../../services/prestation.service';
import { ParametreModel } from '../../shared/models/parametre/parametre-utilisateur.model';
import { UserModel } from '../../shared/models/users/user.model';



@Component({
  selector: 'app-parametre-utilisateur',
  templateUrl: './parametre-utilisateur.component.html',
  styleUrls: ['./parametre-utilisateur.component.scss']
})
export class ParametreUtilisateurComponent implements OnInit {
  public touchUi = false;
  color = '';
  checked: boolean;
  checkedCX: boolean;
  disabled = false;
  prestations: ActiviteModel[] = [];
  public disabledPicker = false;
  public colorPicker: ThemePalette = 'primary';
  themeChangerButton = 'dark';
  theme: DocsSiteTheme;
  themes: DocsSiteTheme[] = [
    { name: 'light-theme' },
    { name: 'dark-theme' }
  ];
  light = true;
  public parametres: ParametreModel[] = [];


  etiquettes: EtiquetteModel[] = [];
  userEtiquette = new UserEtiquetteModel('', this.color);
  displayForm = false;
  userEtiquettesData: UserEtiquetteModel[] = [];


  constructor(
    private toastr: IntercomToastrService,
    private cdr: ChangeDetectorRef,
    private etiquetteService: EtiquetteService,
    private overlay: OverlayContainer,
    private parametreService: ParametreUtilisateurService,
    private prestationService: ActiviteService,
    public dialog: MatDialog,
    private themeStorage: ThemeStorage) {
    }


  ngOnInit(): void {
    this.parametreService.getFromUser().subscribe(parametres => {
      this.parametres = parametres;
      this.installTheme(this.themeStorage.getStoredThemeName());
      this.checked = JSON.parse(localStorage.getItem('ring_central'));
      this.checkedCX = JSON.parse(localStorage.getItem('ring_central_cx'));
        // get from local Storage
      this.userEtiquettesData = this.etiquetteService.getUserEtiquette();
    });
    this.prestationService.getAll().subscribe((prestations: ActiviteModel[]) => {
      this.prestations = prestations;
    });
  }

  installTheme(themeName: string) {

    if (themeName === null) {
      themeName = 'light-theme';
    }

    this.theme = this.themes.find(theme => theme.name === themeName);

    if (this.theme.name === 'dark-theme') {
      this.overlay.getContainerElement().classList.remove('light-theme' || '');
      document.body.classList.remove('light-theme');
      this.overlay.getContainerElement().classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      this.themeChangerButton = 'Light';
      this.light = false;
    } else {
      this.overlay.getContainerElement().classList.remove('dark-theme' || '');
      document.body.classList.remove('dark-theme');
      this.overlay.getContainerElement().classList.add('light-theme');
      document.body.classList.add('light-theme');
      this.themeChangerButton = 'Dark';
      this.light = true;
    }

    if (this.theme) {
      this.themeStorage.storeTheme(this.theme);
    }
  }


  onChecked() {
    this.checked = !this.checked;
    localStorage.setItem('ring_central', JSON.stringify(this.checked));
    const parametre = this.parametres.find(p => p.libelle === 'RingCentral');
    this.saveSetting(parametre, 'RingCentral');
    this.cdr.detectChanges();
    location.reload();
  }

  onCheckedCX() {
    this.checkedCX = !this.checkedCX;
    localStorage.setItem('ring_central_cx', JSON.stringify(this.checkedCX));
    const parametre = this.parametres.find(p => p.libelle === 'RingCentralCX');
    this.saveSetting(parametre, 'RingCentralCX');
    this.cdr.detectChanges();
    location.reload();
  }

  removeEtiquette(i) {
    this.etiquettes.splice(i, 1);
  }

  removeUserEtiquette() {
    // confirmartion de suppression
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        message: 'Êtes-vous sur de vouloir supprimer cette étiquette ?',
        color: 'warn'
      }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.userEtiquette.libelle && this.userEtiquette.libelle !== '') {
          this.userEtiquettesData = this.userEtiquettesData.filter(userEtiq => userEtiq.libelle !== this.userEtiquette.libelle);
          this.etiquetteService.setUserEtiquette( this.userEtiquettesData);
          const parametre = this.parametres.find(p => p.libelle === 'Etiquette');
          this.saveSetting(parametre, 'Etiquette');
          this.toastr.success('Suppression de l\'étiquette ' + this.userEtiquette.libelle);
        } else {
          this.toastr.warning('Sélectionnez une liste  d\'étiquette');
        }
        this.displayForm = false;
      }
    });
  }

  addEtiquette() {
    this.etiquettes.push(new EtiquetteModel('', this.color));
  }

  getUserEtiquette(userEtiquette: UserEtiquetteModel) {
    this.displayForm = true;
    this.userEtiquette = userEtiquette;
    this.etiquettes = userEtiquette.etiquettes;
  }

  AfficherFormulaire() {
    this.userEtiquette.libelle = '';
    this.userEtiquette.color = '#ffffff';
    this.color = '#ffffff';
    this.etiquettes = [];
    for (let i = 0; i < 3 ; i++) {
      this.etiquettes.push(new EtiquetteModel('', this.color));
     }
    this.displayForm = true;
  }

  MasquerFormulaire() {
    this.displayForm = false;
  }

  CreerUserEtiquette() {
    if (this.userEtiquette.libelle !== '') {
      const etiqAssoc = this.etiquettes.filter(etiquette => etiquette.libelle !== '');
      this.etiquettes = etiqAssoc;
      this.userEtiquette.etiquettes = this.etiquettes;
      const element = this.userEtiquettesData.find(userEtiquette => userEtiquette.libelle === this.userEtiquette.libelle);
      // verifié si c'est une modification ou un ajout
      if ( element !== undefined) {
        const index = this.userEtiquettesData.indexOf(element);
        this.userEtiquettesData.splice(index, 1);
        this.toastr.success('Modification de l\'étiquette ' + this.userEtiquette.libelle, '');
      } else {
        this.toastr.success('Ajout de l\'étiquette ' + this.userEtiquette.libelle, '');
      }
      // enregistrement de l'étiquette
      this.userEtiquettesData.push(this.userEtiquette);
      // enregistrer dans le localStorage
      this.etiquetteService.setUserEtiquette( this.userEtiquettesData);
      const parametre  = this.parametres.find(p => p.libelle === 'Etiquette');
      this.saveSetting(parametre, 'Etiquette');
      // tout remettre a nouveau
      this.userEtiquette = new UserEtiquetteModel('', '#ffffff');
      this.color = '#ffffff';
      this.etiquettes = [];
      for (let i = 0; i < 3 ; i++) {
        this.etiquettes.push(new EtiquetteModel('', this.color));
      }
    } else {
      this.toastr.warning('Le nom de la liste est obligatoire ', '');
    }
  }

  toggleTheme() {
    this.themeStorage.clearStorage();
    if (this.overlay.getContainerElement().classList.contains('dark-theme')) {
      this.overlay.getContainerElement().classList.remove('dark-theme');
      this.overlay.getContainerElement().classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.light = true;
    } else if (this.overlay.getContainerElement().classList.contains('light-theme')) {
      this.overlay.getContainerElement().classList.remove('light-theme');
      this.overlay.getContainerElement().classList.add('dark-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'dark-theme'));
      this.themeChangerButton = 'Light';
      this.light = false;
    } else {
      this.overlay.getContainerElement().classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.light = true;
    }
    if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.light = false;
    } else if (document.body.classList.contains('light-theme')) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'dark-theme'));
      this.themeChangerButton = 'Light';
      this.light = true;
    } else {
      document.body.classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.light = false;
    }
    const parametre = this.parametres.find(p => p.libelle === 'Theme');
    this.saveSetting(parametre, 'Theme');
  }

  public saveSetting (parametre: ParametreModel, type: string) {
    parametre = parametre ? parametre : new ParametreModel();
    parametre.id = parametre.id ? parametre.id : 0;
    parametre.libelle = type;
    const user: UserModel = JSON.parse(localStorage.getItem('CURRENT_USER'));
    parametre.utilisateurId = user.id;
    switch (type) {
      case 'Theme':
        parametre.valeur = this.themeChangerButton === 'Dark' ? 'light-theme' : 'dark-theme';
        break;
      case 'RingCentral':
        parametre.valeur = JSON.stringify(this.checked);
        break;
      case 'Etiquette':
        parametre.valeur = JSON.stringify(this.userEtiquettesData);
        break;
      default:
        break;
    }
    if (!parametre.id) {
      this.parametreService.post(parametre).subscribe((res: ParametreModel) => {
        this.parametres.push(res);
      });
    } else {
      this.parametreService.put(parametre).subscribe((res: ParametreModel) => {
      });
    }
  }
}
