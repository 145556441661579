import { PropertiesInfo } from './../modals-menu-info';
import { DatePipe } from '@angular/common';
import { TierLinkedDTO } from './tierLinkedDTO';
import { UserModel } from '../models/users/user.model';

export class ActionDTO extends TierLinkedDTO {
  public realisateurId: number;
  public rechercheTitle?: string;
  public typeActionId: number;
  public createurId: number;
  public objetAction = '';
  public commentaire = '';
  public nonClos?: boolean;
  public dateSmallerRealString: Date = null;
  public dateSmallerCreateString: Date = null;
  public dateBiggerCreateString: Date = null;
  public dateBiggerRealString: Date  = null;
  public statut: string;
  public demandeId: number;

  public static getTitle(titleArray: PropertiesInfo[], action: ActionDTO, selectedTier, user: UserModel): string {
    let rechercheTitle = '';
    if (selectedTier !== null && selectedTier !== undefined && selectedTier !== 'Aucun') {
      rechercheTitle += 'Tiers : ' + selectedTier + ', ';
    }
    if (user !== null && user !== undefined) {
      rechercheTitle += 'Réalisateur : ' + user.nom + ' ' + user.prenom + ', ';
    }
    const dateIndex = titleArray.findIndex(p => p.property === 'dateBiggerRealString');
    if (dateIndex !== -1) {
      if (action[titleArray[dateIndex].property] !== null) {
        const d = new DatePipe('fr-FR');
        rechercheTitle += titleArray[dateIndex].libelle + ' : ' +
        d.transform(<Date>action[titleArray[dateIndex].property], 'dd/MM/yyyy') + ', ';
      }
      titleArray.splice(dateIndex, 1);
    }
    for (const key of titleArray) {
      if (action.hasOwnProperty(key.property)) {
        if (action[key.property] !== null && action[key.property] !== undefined && action[key.property].length !== 0) {
          rechercheTitle += key.libelle + ' : ' + action[key.property] + ', ';
        }
      }
    }
    if (rechercheTitle.length > 2) {
      rechercheTitle = rechercheTitle.substring(0, 1).toUpperCase() + rechercheTitle.substring(1, rechercheTitle.length - 2);
    }
    return rechercheTitle;
  }
}
