import { TierBasicMock } from './basic-mock/tier-basic.mock';
import { InterlocuteurModel } from '../models/interlocuteur.model';
import { Injectable } from "@angular/core";

@Injectable()
export class InterlocuteurMock extends TierBasicMock  {
  constructor() {
    super();
    (<InterlocuteurModel[]> this.objets) = [
      {
        id: 1, tierId: 2,
        civiliteId: 1, commentaire: 'M très gentil', email: 'guillaume.gentil@gmail.com',
        nom: 'GENTIL0', prenom: 'Guillaume0', role: 'Pdg', telPrincipal: '06 00 00 00', telSecondaire: '03 00 00 00 00',
      },
      {
        id: 2, tierId: 3,
        civiliteId: 1, commentaire: 'M très gentil', email: 'guillaume.gentil@gmail.com',
        nom: 'GENTIL1', prenom: 'Guillaume1', role: 'Pdg', telPrincipal: '06 00 00 00', telSecondaire: '03 00 00 00 00',
      },
      {
        id: 3, tierId: 4,
        civiliteId: 1, commentaire: 'M très gentil', email: 'guillaume.gentil@gmail.com',
        nom: 'GENTIL2', prenom: 'Guillaume2', role: 'Pdg', telPrincipal: '06 00 00 00', telSecondaire: '03 00 00 00 00',
      },
      {
        id: 4, tierId: 2,
        civiliteId: 1, commentaire: 'M très gentil', email: 'guillaume.gentil@gmail.com',
        nom: 'GENTIL3', prenom: 'Guillaume3', role: 'Pdg', telPrincipal: '06 00 00 00', telSecondaire: '03 00 00 00 00',
      },
    ];
  }
}