import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SessionService } from "src/app/modules/shared/session.service";
import { SocieteModel } from "../../shared/models/rsa/societe.model";
import { ApiService } from "../api.service";
import { BasicService } from "../basic-service/basic.service";

@Injectable()
export class SocieteService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTESOCIETE;
    }

  getAllManaged(id: number): Observable<SocieteModel[]> {
    return this.http.get<SocieteModel[]>(this.apiUrl + this.serviceUrl + 'GetAllManaged/' + id, {headers: this.header});
  }
}