import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicModel } from '../core/shared/models/basic-models/basic.model';
import { HostnameService } from '../core/services/basic-service/hostname.service';


class DateAndBehaviorResponse {
  dateMaxValid: number;
  response: ReplaySubject<HttpEvent<BasicModel>>;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public cache: Map<string, DateAndBehaviorResponse> = new Map<string, DateAndBehaviorResponse>();
  private urlsToCatched: string[] = [];
  helper: JwtHelperService;
  dureRetention = 100;
  dureRetentionRef = 3600000;
  constructor(
    private hostnameService: HostnameService) {
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Civilite/GetAll/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Utilisateur/GetAll/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Agence/GetAll/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/TypeAction/GetAll/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/TypeAction/GetAllActive/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/TypeDemande/GetAll/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/TypeDemande/GetAllActive/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Agence/getAllManaged/1'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Agence/getAllManaged/2'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Agence/getAllManaged/3'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Agence/getAllManaged/4'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Agence/getAllManaged/5'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Prestations/GetAll/'));
      this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/Prestations/GetAll/'));
      this.urlsToCatched.push(('https://geo.api.gouv.fr/departements/'));
    }

  public newRequest(request: HttpRequest<any>, httpResponse: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
    if (this.containsOrStartWith((request.url))) {
      httpResponse = this.saveInCache(request, httpResponse, this.dureRetentionRef);
      return httpResponse;
    } else {
      return httpResponse;
    }
  }

  public getFromCache(request: HttpRequest<any>): Observable<HttpEvent<any>>{
    const url = (request.url);
    const cachedResponse: DateAndBehaviorResponse = this.cache.get(url);
    if (cachedResponse) {
      // si dateMaxValid < date du jour => suppression de l'enregistrement cache + return null;
      if (cachedResponse.dateMaxValid < Date.now()) {
        this.removeFromCache(request);
        return null;
      }
      return cachedResponse.response;
    } else {
      return null;
    }
  }

  public saveInCache(request: HttpRequest<any>,
                     httpResponse: Observable<HttpEvent<any>>,
                     dureRetention: number): Observable<HttpEvent<any>>{
    // enregistrement Date + durée vie requette;
    const sub: DateAndBehaviorResponse = {
      dateMaxValid: (Date.now() + dureRetention),
      response: new ReplaySubject<HttpEvent<any>>(1)
    };
    httpResponse.subscribe(sub.response);
    this.cache.set(request.url, sub);
    return sub.response;
  }

  public removeFromCache(request: HttpRequest<any>): void {
    this.cache.delete(request.url);
  }

  containsOrStartWith( url: string): boolean{
    if (this.urlsToCatched.includes(url)) {
      return true;
    }
    for (const element of this.urlsToCatched){
      if (url.startsWith(element)) {
        return true;
      }
    }
    return false;
  }
}
