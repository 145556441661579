<mat-drawer-container class="container-position">
  <mat-drawer #sideNav mode="side" opened="false" position="end" class="drawer" style="Overflow-y:scroll;">
    <div>
      <br />
      <div class="row">
        <div class="col-sm-2 margin_bm_20">
          <h4>Filtres</h4>
        </div>
        <div class="col-sm-5 margin_bm_20">
          <button color="success" mat-flat-button (click)="saveFiltre()">
            Sauvegarder
          </button>
        </div>
        <div class="col-sm-5 margin_bm_20">
          <button mat-flat-button color="default" (click)="clearFiltre()">
            <mat-icon>settings_backup_restore</mat-icon> Réinitialiser
          </button>
        </div>
        
      </div>
    </div>
    <br />
    <div class="data-class" *ngIf="filtreData" style="padding-bottom: 10px;">
    <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-societe-multiple-select [eventsSubjectSocieteArray]="eventsSubjectSocieteArray"  (updated)="societeChange($event)">
          </app-autocomplete-societe-multiple-select>
        </div>
      </div>
      <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-agence-multiple-select [eventsSubjectAgenceArray]="eventsSubjectAgenceArray" [agencesIdsArray]="selectedAgenceIdsArray"
          [inputSocietesString]="selectedSocietesString" (updated)="agenceChange($event)">
          </app-autocomplete-agence-multiple-select>
        </div>
      </div>

      <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectRealisateurArray" (updated)="realisateurChange($event)"
          [title]="'réalisateur'">
          </app-autocomplete-realisateur-multiple-selection>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectCreateurArray" (updated)="createurChange($event)"
          [title]="'créateur'">
          </app-autocomplete-realisateur-multiple-selection>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h4>Filtres personnalisés</h4>
      </div>

      
    </div>
    <div class="data-class" *ngIf="filtres && filtres.length > 0">
      <!-- add list of the filtre user -->
      <mat-selection-list #selectedFiltre [multiple]="false">
        <mat-list-option [selected]="filtre.id == this.filtreData.id" *ngFor="let filtre of filtres; let i = index" [value]="filtre" (click)="showFiltre(filtre)" [disableRipple]="true">
          <div style="display:flex; justify-content:space-between;align-items:center">
            <div>
              <mat-icon matListIcon>filter_list</mat-icon>
            </div>
            <div style="display:flex;align-items:center">      
              <div style="display:flex; flex-direction:column">
                <div>
                  <h5 matLine> {{filtre.libelle}} </h5>
                </div>
              </div>
            </div>
            <div>
              <button color="error" mat-icon-button (click)="deleteFiltre($event, i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
         
        </mat-list-option>
      </mat-selection-list>
       
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <span class="filter-button">
      <mat-card-title>
        <button color="default" (click)="sideNav.toggle();" mat-flat-button>
          <mat-icon id="icon">{{
          this.nbFilterApplied == 0 ? "filter_list" :
          this.nbFilterApplied == 1 ? "filter_1" : 
          this.nbFilterApplied == 2 ? "filter_2" : 
          this.nbFilterApplied == 3 ? "filter_3" : 
          this.nbFilterApplied == 4 ? "filter_4" : 
          this.nbFilterApplied == 5 ? "filter_5" : 
          this.nbFilterApplied == 6 ? "filter_6" : 
          this.nbFilterApplied == 7 ? "filter_7" : 
          this.nbFilterApplied == 8 ? "filter_8" : 
          this.nbFilterApplied == 9 ? "filter_9" : "filter_9_plus"
            }}</mat-icon>Filtres
        </button>

      </mat-card-title>
    </span>
    <div class="row">
      <div class="col-sm-12">
        <mat-card class="card card-initial">
          <mat-card-header>
            <mat-card-title>
              <h4>Demandes non-urgentes</h4>
            </mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
            <div class="align-items-end">
              <button class="action-button bgwhite" mat-flat-button (click)="addDemande()">
                  <mat-icon>phone</mat-icon>Nouvelle prise d'appel
              </button>
              <button class="action-button bgwhite" mat-flat-button (click)="goToDemandes('All')">
                  <mat-icon>library_books</mat-icon> Toutes les demandes non-urgentes
              </button>
            </div>
          </mat-card-header>
          
          <mat-card-content>
            <div class="button-row">
    
              <mat-card class="sous-card premier" (click)="goToDemandes('A Traiter')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterDemandes.nbReclaEnCours != undefined">
                          {{counterDemandes.nbATraiter}} <br>
                        </div>
                        <mat-spinner *ngIf="counterDemandes.nbATraiter == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> À traiter</span>
                    </span>
                  </div>
                </mat-card-content>
    
              </mat-card>
    
              <mat-card class="sous-card premier" (click)="goToDemandes('En cours')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterDemandes.nbEnCours != undefined">
                          {{counterDemandes.nbEnCours}} <br>
                        </div>
                        <mat-spinner *ngIf="counterDemandes.nbEnCours == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> En cours</span>
                    </span>
                  </div>
                </mat-card-content>
    
              </mat-card>
    
    
              <mat-card class="sous-card premier" (click)="goToDemandes('Reclamation en cours')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterActions.nbReclaEnCours != undefined">
                          {{counterDemandes.nbReclaEnCours}} <br>
                        </div>
                        <mat-spinner *ngIf="counterDemandes.nbReclaEnCours == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> Réclamation{{counterDemandes.nbReclaEnCours > 1 ? 's' : ''}} en cours</span>
                    </span>
                  </div>
                </mat-card-content>
              </mat-card>
    
    
    
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <mat-card class="card card-initial">
          <mat-card-header>
            <mat-card-title>
              <h4>Demandes urgentes</h4>
            </mat-card-title>
            <div class="align-items-end">
              <button class="action-button bgwhite" mat-flat-button (click)="addDemandeUrgente()">
                  <mat-icon>phone</mat-icon>Nouvelle prise d'appel urgente
              </button>
              <button class="action-button bgwhite" mat-flat-button (click)="goToDemandes('InterventionAll')">
                  <mat-icon>library_books</mat-icon> Toutes les demandes urgentes
              </button>
            </div>
          </mat-card-header>
          
          <mat-card-content>
            <div class="button-row">
    
              <mat-card class="sous-card premier" (click)="goToDemandes('InterventionAEnvoyer')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterDemandes.nbInterventionAEnvoye != undefined">
                          {{counterDemandes.nbInterventionAEnvoye}} <br>
                        </div>
                        <mat-spinner *ngIf="counterDemandes.nbInterventionAEnvoye == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> À Envoyer</span>
                    </span>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="sous-card premier" (click)="goToDemandes('InterventionSent')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                      <div *ngIf="counterDemandes.nbInterventionSent != undefined">
                        {{counterDemandes.nbInterventionSent}} <br>
                      </div>
                      <mat-spinner *ngIf="counterDemandes.nbInterventionSent == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> Envoyée{{counterDemandes.nbInterventionSent>1?'s':''}} à Praxedo</span>
                    </span>
                  </div>
                </mat-card-content>
              </mat-card>
    
              <mat-card class="sous-card premier" (click)="goToDemandes('InterventionPlanif')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                      <div *ngIf="counterDemandes.nbInterventionPlanif != undefined">
                        {{counterDemandes.nbInterventionPlanif}} <br>
                      </div>
                      <mat-spinner *ngIf="counterDemandes.nbInterventionPlanif == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> Planifiée{{counterDemandes.nbInterventionPlanif>1?'s':''}}</span>
                    </span>
                  </div>
                </mat-card-content>
              </mat-card>

             
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    
    <div class="row">
      <div class="col-sm-12">
        <mat-card class="card card-initial">
          <mat-card-header>
            <mat-card-title>
              <h4>Tâches</h4>
            </mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
            <div class="align-items-end">
              <button class="action-button bgwhite" mat-flat-button (click)="goToActions('All')">
                  <mat-icon>library_books</mat-icon> Toutes les tâches
              </button>
          </div>
          </mat-card-header>
          <mat-card-content>
    
            <div class="button-row">
    
              <mat-card class="sous-card premier" (click)="goToActions('A Traiter')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterActions.nbATraiter != undefined">
                          {{counterActions.nbATraiter}} <br>
                        </div>
                        <mat-spinner *ngIf="counterActions.nbATraiter == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> À traiter</span>
                    </span>
                  </div>
                </mat-card-content>
    
              </mat-card>
    
              <mat-card class="sous-card premier" (click)="goToActions('En cours')">
                <mat-card-content>
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterActions.nbEnCours != undefined">
                          {{counterActions.nbEnCours}} <br>
                        </div>
                        <mat-spinner *ngIf="counterActions.nbEnCours == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> En cours</span>
                    </span>
                  </div>
                </mat-card-content>
    
              </mat-card>
    
    
              <mat-card class="sous-card premier" (click)="goToActions('Reclamation en cours')">
                <mat-card-content style="display: flex; flex-direction: column; ">
                  <div class="resume">
                    <div class="vl"></div>
                    <span class="text">
                      <h1>
                        <div *ngIf="counterActions.nbReclaEnCours != undefined">
                          {{counterActions.nbReclaEnCours}} <br>
                        </div>
                        <mat-spinner *ngIf="counterActions.nbReclaEnCours == undefined" class="spinner" diameter="40" mode="indeterminate"></mat-spinner>
                      </h1>
                      <span style="line-height: 1;"> Réclamation{{counterActions.nbReclaEnCours > 1 ? 's' : ''}} en cours</span>
                    </span>
                  </div>
                </mat-card-content>
              </mat-card>    
    
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>

