import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BasicService } from './basic-service/basic.service';
import { SessionService } from '../../shared/session.service';
import { Observable } from 'rxjs';
import { TypeDemandeModel } from '../shared/models/type-demande.model';
@Injectable()

export class TypeDemandeService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTETYPEDEMANDE;
    }

    getAllActive(): Observable<TypeDemandeModel[]> {
      return this.http.get<TypeDemandeModel[]>(this.apiUrl + this.serviceUrl + 'GetAllActive/');
    }
}
