<div class="text-center customContainer">
  <div class="col-sm-12 body-class">
    <div class="col-sm-8 body-class">
      <h2 class="pageTitle"> Les tiers associés</h2>
    </div>

    <div class="col-sm-3 text-center">
      <button mat-flat-button color="default" (click)="onModalCancel()">Autres</button>
    </div>
  </div>


  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="libelle" matSortDirection="asc" matSortDisableClear >
    <caption></caption>
    <!-- Position Column -->
    <ng-container matColumnDef="typeTier">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Type tier</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.typeTier">{{element.typeTier}}</td>
    </ng-container>
  
    <!-- Position Column -->
    <ng-container matColumnDef="libelle">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Libelle </th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.libelle">{{element.libelle | truncate:35}} </td>
    </ng-container>
    
  
    <!-- Position Column -->
    <ng-container matColumnDef="telephone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Téléphone</th>
      <td mat-cell *matCellDef="let element">
        <div [matTooltip]="element.telephone"> 
          {{element.telephone}}
        </div> 
      </td>
    </ng-container>
  
    <!-- Position Column -->
    <ng-container matColumnDef="ville">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ville</th>
      <td mat-cell *matCellDef="let element">
        <div [matTooltip]="element.ville + element.adresse"> 
          {{element.ville}}
        </div> 
      </td>
    </ng-container>
  
    <!-- Position Column -->
    <ng-container matColumnDef="agenceP">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Agence d'affectation</th>
      <td mat-cell *matCellDef="let element">
        <div [matTooltip]="element.agence.libelle" *ngIf="element.agence !== null">
          {{element.agence.libelle}}
        </div>  </td>
    </ng-container>
  
    <!-- Position Column -->
    <ng-container matColumnDef="commercialP">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Commercial de suivi</th>
      <td mat-cell *matCellDef="let element">
        <div [matTooltip]="element.commercial.nom + ' ' + element.commercial.prenom" *ngIf="element.commercial !== null"> 
          {{element.commercial.nom + ' ' + element.commercial.prenom}}
        </div> 
      </td>
    </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectTiers(row)"  class="example-element-row"></tr>
  </table>
  <mat-paginator class="paginator"
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]"
    (page)="pageEvent=changePage($event)">
  </mat-paginator>
  </div>