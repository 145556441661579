import { PrestationAgenceBasicModel } from './prestation-agence-basic.model';

export class OffreModel extends PrestationAgenceBasicModel {

    public libelle: string;

    public color: string;

    public idPrestation: number;

    public pu: number = 0;

    public tva: number;

    public qte: number;
    
   public codeJason: string;

    public idAgence: number;

    public unite: string;

    public type: string = 'Additionnel';

    public coefficient: number = 1;

    constructor(libelle: string, color: string) {
        super();
        this.libelle = libelle;
        this.color = color;
    }
}

export class TvaModel {
    libelle: string = '20%';
    taux: number = 20;

}

  