import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { DocumentService } from 'src/app/modules/core/services/document.service';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { DocumentModel } from 'src/app/modules/core/shared/models/document/document.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

export interface DialogData {
  demande: DemandeModel;
  tier:TierModel
}

@Component({
  selector: 'app-modal-afficher-documents',
  templateUrl: './modal-afficher-documents.component.html'
})
export class ModalAfficherDocumentsComponent implements OnInit {
  // documents
  documents: DocumentModel[] = [];
  document: DocumentModel;
  private modalsService: any;

  constructor(private documentService: DocumentService,
    private demandeService: DemandeService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    injector: Injector,

    ) { 
      this.modalsService = injector.get('ModalsService');
    }

  ngOnInit(): void {
    if(this.data.demande != null){
      this.getDocumentsByDemande();
    }
    if(this.data.tier != null){
      this.getDocumentsByTiers();
    }
  }

  onModalCancel(): void {
    this.dialogRef.close(false);
  }

  onOpenDocument(document: DocumentModel): void {

    this.document = Object.assign({}, document);

    this.modalsService.openDocument(this.document).subscribe(result => {
      //this.onUpdated.emit(true);
      if (result) {
        this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
          this.data.demande = dem;
        });
        this.getDocumentsByDemande();
      }
    });
  }

  getDocumentsByDemande() {
    this.documentService.getFromDemande(this.data.demande.id).subscribe((documents: DocumentModel[]) => {
      this.documents = documents;
     // this.data.demande.documents = documents;
    });
  }

  getDocumentsByTiers() {
    this.documentService.getFromTiers(this.data.tier.id).subscribe((documents: DocumentModel[]) => {
      this.documents = documents;
    });
  }

  


}
