
<mat-form-field *ngIf="users !== undefined" class="full-width">
  <mat-label>{{libelle}}</mat-label>
    <input type= "text"
    matInput
    [required]="required"
    placeholder="Selectionner le réalisateur"
    (change)="add($event.target.value)"
    [formControl]="myControl"
    [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="intervenantChange($event)"  [panelWidth]="'auto'" >
    <mat-option *ngFor="let usr of filteredRealisateur | async" [value]= "usr"> {{usr}}</mat-option>
  </mat-autocomplete>

</mat-form-field>