import { Component, OnInit, Inject } from '@angular/core';
import { SiteGeographiqueComponent } from './site-geographique.component';
import { SiteGeoService } from './../../services/site-geo.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SiteGeoModel } from '../../shared/models/site-geo.model';

export interface DialogData {
  site:   SiteGeoModel;
  result:   boolean;
}

@Component({
  selector: 'app-modal-afficher-site-geo',
  templateUrl: './modal-afficher-site-geo.component.html',
})
export class ModalAfficherSiteGeoComponent implements OnInit {

  constructor(public dialog: MatDialog
    , public dialogRef: MatDialogRef<SiteGeographiqueComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private sitegeoService: SiteGeoService
    , private toastr: IntercomToastrService) { }

  ngOnInit() {
    this.dialogRef.updateSize('80%', '50%');
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
    if (this.data.site.libelle.trim().length !== 0 && this.data.site.ville.trim().length !== 0 &&
      this.data.site.codePostal.trim().length !== 0 && this.data.site.adresse.trim().length !== 0) {
      this.data.result = true;
      this.sitegeoService.put(this.data.site).subscribe((s: SiteGeoModel) => {
        this.data.site = s;
        this.dialogRef.close(s);
        this.toastr.success('', 'Mise à jour de l\'adresse ' + this.data.site.libelle );
      });
    } else {
      this.toastr.warning('Impossible de modifier le site.' , 'le libelle, la ville, l\'adresse et le code postale sont obligatoire.');
    }
  }
}
