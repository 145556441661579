import { BasicMock } from './basic-mock/basic.mock';
import { NatureModel } from '../models/nature.model';
import { Injectable } from "@angular/core";

@Injectable()
export class NatureMock extends BasicMock  {
  constructor() {
    super();
    (<NatureModel[]> this.objets) = [
      {
        id: 1,
        code: 'NAT0', libelle: 'Pompage'
      },
      {
        id: 2,
        code: 'NAT1', libelle: '3D'
      },
      {
        id: 3,
        code: 'NAT2', libelle: 'I.T.V'
      },
      {
        id: 4,
        code: 'Par', libelle: 'Particuliers'
      }
    ];
  }
}
