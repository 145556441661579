import { GmapsSiteGeoModel } from './../../../shared/models/gmapsSiteGeo.model';
import { BasicModel } from './../../../shared/models/basic-models/basic.model';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { UserModel } from './../../../shared/models/users/user.model';
import { SiteGeoService } from './../../../services/site-geo.service';
import { ActiviteService } from './../../../services/prestation.service';
import { ActiviteModel } from './../../../shared/models/prestation.model';
import { SiteGeoModel } from './../../../shared/models/site-geo.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject, ElementRef, ViewChild, Injector } from '@angular/core';
import { ProtocoleModel } from './../../../shared/models/protocole.model';
import { ProtocoleComponent } from './../protocole.component';
import { MatLegacyAutocomplete as MatAutocomplete} from '@angular/material/legacy-autocomplete';
import { map, startWith, switchMap } from 'rxjs/operators';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ProtocoleService } from '../../../services/protocole.service';
import { UserService } from '../../../services/user/user.service';
import { GmapsService } from 'src/app/modules/shared/gapiServices/gmaps.service';
import { AutocompleteVilleComponent } from 'src/app/modules/shared/components/autocomplete-ville/autocomplete-ville.component';
import { AutocompleteCodePostalComponent } from 'src/app/modules/shared/components/autocomplete-code-postal/autocomplete-code-postal.component';
import { CityData } from '../../../shared/models/city-data.model';

  export interface DialogData {
    protocole: ProtocoleModel;
    tierId: number;
    agenceId: number;
    realisateurId: number;
    result: boolean;
  }

  @Component({
    selector: 'app-modal-afficher-protocole',
    templateUrl: './modal-afficher-protocole.component.html',
    styleUrls: ['./modal-afficher-protocole.component.scss']
  })

  export class ModalAfficherProtocoleComponent implements OnInit {
    users: UserModel[] = [];
    user: UserModel;
    realisateurId: number;
    siteModels: SiteGeoModel[] = null;
    siteModel: SiteGeoModel;
    prestations: ActiviteModel[] = [];
    prestation: ActiviteModel = new ActiviteModel();
    prestationId: number;
    siteModelId: number;
    prestationCtrl = new UntypedFormControl();
    filteredPrestations: Observable<string[]>;
    private modalsService: ModalsService;
    allPrestations: string[] = [];
    protocolesTier: ProtocoleModel[] = [];
    protocolesAgence: ProtocoleModel[] = [];

    gmapsAdresses: GmapsSiteGeoModel[] = [];
    adresseControl = new UntypedFormControl();
    codePostalControl = new UntypedFormControl();
    filteredAdresses: string[];
    adressesString: string[] = [];

    @ViewChild('prestationInput', { static: true }) prestationInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild(AutocompleteVilleComponent) autocVille: AutocompleteVilleComponent;
    @ViewChild(AutocompleteCodePostalComponent) autocCode: AutocompleteCodePostalComponent;
    realisateur: UserModel;

    selectedCity: CityData = new CityData();

    constructor(public dialog: MatDialog
      , public dialogRef: MatDialogRef<ProtocoleComponent>
      , @Inject(MAT_DIALOG_DATA) public data: DialogData
      , private siteService: SiteGeoService
      , private userService: UserService
      , private prestationService: ActiviteService
      , private gmapsService: GmapsService
      , private toastr: IntercomToastrService
      , injector: Injector
      , private protocoleService: ProtocoleService
      ) { 
        this.modalsService = injector.get('ModalsService');

      }

      ngOnInit() {
        this.realisateurId = this.data.protocole.realisateurId;
        this.prestation.id = this.data.protocole.prestationId;
        this.dialogRef.updateSize('90%', '80%');

        if (this.data.protocole.ville) {
          this.selectedCity.nom = this.data.protocole.ville;
        }
        if (this.data.protocole.codePostal) {
          this.selectedCity.codesPostaux[0] = this.data.protocole.codePostal;
        }

         // Récupération des protocoles du tiers
      if (this.data.protocole.tierId) {
        this.protocoleService.getFromTier(this.data.protocole.tierId).subscribe((protocoles: ProtocoleModel[]) => {
          this.protocolesTier = protocoles;
        });
      }
      // Récupération des protocoles de l'agence
      if (this.data.protocole.agenceId && !this.data.protocole.tierId) {
        this.protocoleService.getFromAgence(this.data.protocole.agenceId).subscribe((protocoles: ProtocoleModel[]) => {
          this.protocolesAgence = protocoles;
        });
      }

        // Récuperation des adresses du tiers
        if (this.data.protocole.tierId) {
          this.siteService.getFromTier(this.data.protocole.tierId).subscribe((site: SiteGeoModel[]) => {
            this.siteModels = site;
            this.siteModel = this.siteModels.find(s => s.id === this.data.protocole.siteGeoId);
          });
        }
        // Récuperation des prestations
        this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
          this.prestations = prestas;
          if (this.prestations.find(pres => pres.id === this.prestation.id)) {
            this.prestation.libelle = this.prestations.find(pres => pres.id === this.prestation.id).libelle;
          }
          for (let i = 0; i < prestas.length; i++) {
              this.allPrestations.push(prestas[i].libelle);
          }

        this.allPrestations.sort();
        this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
          startWith(null),
          map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
        });

        // Récuperation de tout les utilisateurs
        this.userService.getAll().subscribe((usrs: UserModel[]) => {
          this.user = usrs.find(usr => usr.id === this.data.protocole.realisateurId);
          this.users = usrs;
          this.realisateurId = this.data.protocole.realisateurId;
          this.realisateur = this.users.find(x => x.id === this.realisateurId);
        });

         // Adresse de Google
      this.adresseControl.valueChanges.pipe(
        startWith(''),
        switchMap((adresse: string) => {
          let gmapsAdressesString = [];
          const filteredAdresse = adresse != null ? this._filterSite(adresse) : [];
            if (adresse.length > 1) {
              return this.gmapsService.getAutoCompleteVilleResults(adresse).pipe(map((gmapsAdresses: GmapsSiteGeoModel[]) => {
                this.gmapsAdresses = gmapsAdresses;
                for (const gmapsAdresse of gmapsAdresses) {
                  gmapsAdressesString.push(gmapsAdresse.ville);
                }
                return gmapsAdressesString;
              }));
            } else {
              return of(gmapsAdressesString);
            }
        })
      ).subscribe((gmapsAdresses: string[]) => {
        this.filteredAdresses = gmapsAdresses;
      });

    }

    onUpdateRealisateurId(realisateurId: number) {
      if (realisateurId === null) {
        this.data.protocole.realisateurId = null;
        this.realisateur = null;
      } else {
        this.data.protocole.realisateurId = realisateurId;
        this.realisateur = this.users.find(x => x.id === this.realisateurId);
      }
    }

      onAddAddress(address: SiteGeoModel) {
        this.data.protocole.siteGeo = address;
        if (address) {
          this.data.protocole.siteGeoId = address.id;
        } else {
          this.data.protocole.siteGeoId = null;
        }
      }
      /* Ajout d'une prestation avec selection */
      selected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.viewValue;
        const index = this.allPrestations.indexOf(value);

        this.prestation.libelle = value;
        this.prestation.id = this.prestations.find(pres => pres.libelle === value).id;
        this.data.protocole.prestationId = this.prestation.id;
        this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
          startWith(null),
          map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
      }

      /* Ajout d'une prestation avec Entrer */
      add(value: string): void {
        this.data.protocole.prestationId = null;
        const index = this.allPrestations.indexOf(value);
        this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
          let exist = false;
          for (let i = 0; i < prestas.length; i++) {
            if (value === prestas[i].libelle) {
                  exist = true;
            }
          }
          // Add prestation
          if (exist) {
            for (let i = 0; i < prestas.length; i++) {
              if (value === prestas[i].libelle) {
                this.prestation = prestas[i];
                this.data.protocole.prestationId = this.prestation.id;
              }
            }
          }
        });

        this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
          startWith(null),
        map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
        }

      private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.allPrestations.filter(prestation => prestation.toLowerCase().indexOf(filterValue) >= 0);
      }

      getIdByLibelle (libelle: string) {
        return this.prestations.find(pres => pres.libelle === libelle).id;
      }

      siteChange() {
        if (this.data.protocole.siteGeoId !== null && this.data.protocole.siteGeoId !== undefined) {
          this.siteModel = Object.create(this.siteModels.find(site => site.id === this.data.protocole.siteGeoId));
          this.data.protocole.siteGeo = Object.create(this.siteModels.find(site => site.id === this.data.protocole.siteGeoId));
        } else {
          this.data.protocole.siteGeo = null;
        }
      }

      updateProtocole() {
        this.data.protocole.realisateur = null;
        this.data.protocole.siteGeo = null;
        this.data.protocole.prestation = null;
        this.data.protocole.agence = null;
        if ( this.data.protocole.protocole !== '' && this.data.protocole.protocole ) {
          if (this.data.protocole.ville && !this.data.protocole.codePostal) {
            this.toastr.warning('Veuillez saisir le code postal de la ville', 'Impossible d\'enregistrer le protocole');
          } else {
            if (!this.protocoleExist(this.data.protocole)) {
              this.protocoleService.put(this.data.protocole).subscribe((a: ProtocoleModel) => {
                this.data.result = true;
                this.data.protocole = a;
                this.dialogRef.close(true);
                this.toastr.success('Le protocole a bien été modifiée');
                this.modalsService.closeProtocole(this.data.protocole);
              });
            } else {
              this.toastr.warning('Le Protocole existe déja.', 'Impossible d\'enregistrer le protocole');
            }
          }
        } else {
          this.toastr.warning('Le champ Protocole est obligatoire.', 'Impossible d\'enregistrer le protocole');
        }
      }

      deleteIfEmptyCP(): void {
        this.codePostalControl.setValue('');
        this.data.protocole.codePostal = '';
      }

      deleteIfEmptyVille(): void {
        this.adresseControl.setValue('');
        this.data.protocole.ville = '';
      }

      onModalCancel(): void {
        this.data.result = false;
        this.dialogRef.close(false);
      }

      onModalConfirm(): void {
        if (this.data.protocole) {
          if (this.data.protocole.siteGeo) {
            if (this.data.protocole.siteGeo.id) {
              this.updateProtocole();
            } else {
            this.saveAddress();
            }
          } else {
            this.updateProtocole();
          }
        }
      }


      createAdresse(adresse: SiteGeoModel): Observable<BasicModel> {
        if (adresse.id) {
          return of(adresse);
        }
        this.data.protocole.siteGeo.tierId = this.data.protocole.tierId;
        const val = this.siteService.post(adresse);
        return val;
      }

      saveAddress() {
        this.createAdresse(this.data.protocole.siteGeo).subscribe((adresse: SiteGeoModel) => {
          this.data.protocole.siteGeoId = adresse.id;
            this.updateProtocole();
        });
      }


      protocoleExist(protocole: ProtocoleModel) {
        for (let i = 0; i < this.protocolesTier.length; i++) {
           if ((protocole.siteGeoId === this.protocolesTier[i].siteGeoId)
                 && (protocole.prestationId === this.protocolesTier[i].prestationId)
                 && (protocole.id != this.protocolesTier[i].id)
                 ) {
             return true;
           }
        }
        for (let i = 0; i < this.protocolesAgence.length; i++) {
         if ((protocole.ville === this.protocolesAgence[i].ville)
         && (protocole.codePostal === this.protocolesAgence[i].codePostal)
         && (protocole.prestationId === this.protocolesAgence[i].prestationId)
         && (protocole.id != this.protocolesAgence[i].id)
         ) {
           return true;
         }
       }
        return false;
     }

      adresseChange(value: string) {
        if (value !== '') {
           if (this.gmapsAdresses.length !== 0) {
            let id = '';
            for (const gmapAdresse of this.gmapsAdresses) {
              if (gmapAdresse.ville === value) {
                id = gmapAdresse.placeId;
                break;
              }
            }
            this.gmapsService.getGeoCodeResults(id).subscribe((gmapAdresse: GmapsSiteGeoModel[]) => {
              if (gmapAdresse[0].codePostal) {
                this.data.protocole.codePostal = gmapAdresse[0].codePostal;
                this.codePostalControl.disable();
              } else {
                this.data.protocole.codePostal = '';
                this.codePostalControl.enable();
              }
            });
          }
        }
      }

      private _filterSite(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.adressesString.filter(adresse => adresse.toLowerCase().indexOf(filterValue) >= 0);
      }

    onCodePostalChange(selectedCity: CityData) {
      if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
        this.selectedCity = selectedCity;
        this.autocCode.codePostal = selectedCity.codesPostaux[0];
        this.autocVille.villetext = selectedCity.nom;

        this.data.protocole.codePostal = selectedCity.codesPostaux[0];
        this.data.protocole.ville = selectedCity.nom;
        this.codePostalControl.setValue(selectedCity.codesPostaux[0]);
        this.adresseControl.setValue(selectedCity.nom);
      } else if (this.selectedCity?.nom !== selectedCity.nom) {
        this.onCityChange(selectedCity);
      }
    }
  
    onCityChange(selectedCity: CityData) {
      if (this.selectedCity?.nom !== selectedCity.nom) {
        this.selectedCity = selectedCity;
        this.autocCode.codePostal = selectedCity.codesPostaux[0];
        this.autocVille.villetext = selectedCity.nom;
        
        this.data.protocole.codePostal = selectedCity.codesPostaux[0];
        this.data.protocole.ville = selectedCity.nom;
        this.codePostalControl.setValue(selectedCity.codesPostaux[0]);
        this.adresseControl.setValue(selectedCity.nom);
      } else if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
        this.onCodePostalChange(selectedCity);
      }
    }
  }

