<div class="col-sm-12">
  <div class="col-sm-4">
    <h1>Gestion activités</h1>
  </div>  
  <div class="col-sm-4">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Rechercher une activité</mat-label>
      <input matInput [(ngModel)]="objet.entry" (ngChange)="search()" (keydown.enter)="search()">
    </mat-form-field>
  </div>
  <div class="col-sm-2 text-center">
    <button type="button" mat-stroked-button color="success" (click)="onCreate()"><mat-icon>add</mat-icon> Créer une activité</button>
  </div>
  <div class="col-sm-2  text-center">
    <button mat-flat-button color="primary" type="button" (click)="search()"><mat-icon>search</mat-icon>Rechercher</button>
  </div>
</div>
<div class="col-sm-12 table-inner-scroll-container">
  <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSortActive="Libelle" matSortDirection="asc" (matSortChange)="trieTab($event)">
    <caption></caption>

    <ng-container matColumnDef="Libelle">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Libellé </th>
      <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.libelle}} </td>
    </ng-container>

    <ng-container matColumnDef="commentaire">
      <th mat-header-cell *matHeaderCellDef> Commentaire </th>
      <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.commentaire}} </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Code </th>
      <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.code}} </td>
    </ng-container>

    <ng-container matColumnDef="CodePraxedo">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Code Praxedo</th>
      <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.codePraxedo}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell class="action-btns" *matCellDef="let element" style="padding: 0;">
        <div class="btn-container center">
          <button mat-mini-fab class="tableau-btn" (click)="onDelete(element); $event.stopPropagation()" color="warn">
            <mat-icon class="tableau-mat-icon">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openActivite(row)"  class="element-row"></tr>
  </table>
</div>
<mat-paginator
  class="paginator"
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 25, 50]"
  (page)="changePage($event)">
</mat-paginator>



