<div class="header-class">
    <h3 class="line-title">Fiche demande urgente&nbsp;
      <mat-icon data-html="true" style="align-self:self-start;" 
      [matTooltip]="'Créateur : ' + data.demande.createur.nom +' ' + data.demande.createur.prenom + '\n' +
      'Date de création : ' + (data.demande.dateCreation ? (data.demande.dateCreation.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date d\'envoi à Praxedo : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dEnvoiPxo) ? (data.demande.interventionPxo.dEnvoiPxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date debut de planification : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dStartPlanifPxo) ? (data.demande.interventionPxo.dStartPlanifPxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date fin de planification : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dEndPlanifPxo) ? (data.demande.interventionPxo.dEndPlanifPxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date de sychronisation : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dSynchroPxo) ? (data.demande.interventionPxo.dSynchroPxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date de démarrage : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dDemarrePxo) ? (data.demande.interventionPxo.dDemarrePxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date de réalisation : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dRealPxo) ? (data.demande.interventionPxo.dRealPxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      'Date de validation : ' + ((data.demande.interventionPxo && data.demande.interventionPxo.dValidPxo) ? (data.demande.interventionPxo.dValidPxo.toString() | date:'dd/MM/yyyy HH:mm'):'') + '\n' +
      ((data.demande.interventionPxo && data.demande.interventionPxo.dAnnulePxo) ? ('Date d\'annulation : ' + (data.demande.interventionPxo.dAnnulePxo.toString() | date:'dd/MM/yyyy HH:mm')) : '') +
      (data.demande.dateCloture ? ('Date de cloture : ' + (data.demande.dateCloture.toString() | date:'dd/MM/yyyy HH:mm')) : '') +' &#013;'"
      matTooltipPosition="left"
      matTooltipClass="mat-tooltip">info</mat-icon>
      &nbsp;: {{data.demande.numDemande}} 
      <button
          matPrefix
          mat-icon-button
          (click)="copyclip(data.demande.numDemande, $event)"
          matTooltip="copier dans le presse-papiers">
        <mat-icon class="icon duplique" inline="true" >content_copy</mat-icon>
      </button>
      <button
          matPrefix
          mat-mini-fab
          color="primary"
          *ngIf="demandesLinked.length > 1"
          (click)="openHistorique($event)"
          matTooltip="Demandes liées">
        <mat-icon class="icon duplique" inline="true" >folder_open</mat-icon>
      </button>
    </h3>
  <div style="display: flex;justify-content: space-between; ">
    <div class="subDivContainer">
      <button mat-flat-button color="success" class="button-white" (click)="onModalConfirm()">Enregistrer</button>
    </div>

    <div class="subDivContainer">
      <button mat-flat-button color="primary" class="button-white" (click)="onDupliquer()">Dupliquer</button>
    </div>

    <div class="subDivContainer" *ngIf="this.data.demande.agenceId && !this.data.demande.interventionPxo && this.data.demande.statut !== 'Annulé'">
      <button mat-flat-button color="success" class="button-white" (click)="onSendToPraxedo()">Envoyer à Praxedo</button>
    </div>
  </div>
  <div>
    <a (click)="onModalCancel()" class="close-button">
      <mat-icon class="close-icon">close</mat-icon>
    </a>
    <a (click)="onModalMinimize()" class="close-button">
      <mat-icon class="close-icon">remove</mat-icon>
    </a>
    <a (click)="print()" class="close-button">
      <mat-icon class="print-icon">print</mat-icon>
    </a>
  </div>
</div>

<mat-divider style="margin-bottom: 5px; margin-top: 3px;"></mat-divider>

<div class="col-sm-12" style="display: flex;justify-content: space-between;">
  <div class="col-sm-11">
    <mat-form-field class="full-width objet" appearance="outline">
            <mat-label>Objet</mat-label>
            <input required 
              [disabled]="objetDisabled"
              matInput
              class="text-large"
              placeholder=""
              value=""
              name="intercom-objet-demande"
              [(ngModel)]="data.demande.objet"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
    </div>
  
    <div class="col-md-1" style="padding: 10px 0;">
      <button mat-mini-fab color="primary" [disabled]="isSendToPxo" (click)="modifyObjet()">
        <mat-icon style="color: white;"> create </mat-icon>
      </button>
    </div>
  </div>
<!-- Stepper -->
<div class="col-sm-12">
    <mat-horizontal-stepper [linear]="true"  labelPosition="right" #stepper>
        <mat-step *ngFor="let flag of flagsPxo; let i = index" [editable]="i === selectedIndex" [completed]="i < selectedIndex">
            <ng-template matStepLabel >
                <span (mouseover)="getTooltip(i)" [matTooltip]="matTooltip"> {{flag}} </span>
            </ng-template>
        </mat-step>
    </mat-horizontal-stepper>
</div>

<!-- Tiers + Contact -->
<div class="col-sm-12"  style="display: flex;justify-content: space-between;">
  <div class="col-sm-8 data-class body-class" style="margin: 10px;">
    <div class="col-sm-6 data-class body-class">
      <!-- Tiers donneur d'ordre -->
      <app-tiers-card [libelle]="libelleTiers" [tier]="data.demande.tier">
      </app-tiers-card>
    </div>
    <div class="col-sm-6 data-class body-class">
      <!-- Tiers facturé -->
      <app-tiers-card (selectedTiers)="onTiersFactureChange($event)" [libelle]="libelleTiersFacture"  [tier]="data.demande.tierFacture" [modifiable]="!isSendToPxo">
      </app-tiers-card>
    </div>
  </div>
  <div class="col-sm-4 data-class body-class" style="margin: 10px;">
    <!-- Contact -->
    <app-contact-card [interlocuteurs]="interlocuteurs"
      (selectedInterlocuteur)='onEditInterlocuteur($event)'
      [demande]="data.demande" [modifiable]="!isSendToPxo">
    </app-contact-card>
  </div>
</div>

<!-- Details demande (infos demande + adresse d'intervention + document ) -->
<div class="col-sm-12" style="display: flex;justify-content: space-between;">
  <div class="col-sm-8 data-class body-class" style="margin: 10px;" >
      <div class="header-class col-sm-12">
        <h4>
          Details de la demande
          <button class="action-button bgwhite" mat-flat-button (click)="onShowDocuments()">
              <mat-icon>perm_media</mat-icon> Documents
          </button>
          <button class="action-button bgwhite" mat-flat-button (click)="onShowPraxedo()" 
          *ngIf="true || this.data.demande.interventionPxo"
          >
            <mat-icon>open_in_new</mat-icon> Praxedo
        </button>
        </h4>
      </div>
      <div class="col-sm-12">
        <!-- Adresse d'intervention -->
        <app-address-card
          [adresse]="adresse"
          [tier]="this.data.demande.tier" 
          [realisateur]="commercial"
          [modifiable]="!isSendToPxo" 
          (selectedAdresse)='onAddOrEditAddress($event)'>
        </app-address-card>
      </div>
      <div class="col-sm-12">
        <div class="col-sm-3">
          <mat-form-field  class="full-width" *ngIf="typeDemandes !== undefined">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="data.demande.typeDemandeId" 
            (selectionChange)="typChange()" 
            required  [disabled]="true"
            >
              <mat-option *ngFor="let typeD of typeDemandes.slice()" [value]="typeD.id">{{typeD.libelle}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <div class="col-sm-3">
          <app-autocomplete-realisateur [required]="true" [disable]="isSendToPxo" libelle="Réalisateur" [users]="usersPxo" [user]="user" [commercialId]= "commercialId" (onUpdated)="onUpdateRealisateurId($event)"> </app-autocomplete-realisateur>
        </div>
        
        <div class="col-sm-3">
          <app-autocomplete-realisateur [required]="true" [disable]="isSendToPxo" libelle="Gestionnaire" [users]="users" [user]="user" [commercialId]= "gestionnaireId" (onUpdated)="onUpdateGestionnaireId($event)"> </app-autocomplete-realisateur>
        </div>

        <div class="col-sm-3">
          <mat-form-field class="full-width">
            <mat-label>Date d'échéance</mat-label>
            <input required matInput [matDatepicker]="picker" [(ngModel)]="data.demande.dateIntervention" [disabled]="isSendToPxo">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        
        
        <div class="col-sm-8">
          <div>
            <app-select-prestation [disabled]="isSendToPxo" 
            [required]="true" [prestationIds] = "prestationIds" 
            [prestationsList]=prestations (updated)="onUpdatePrestationsId($event)"> 
          </app-select-prestation>
          </div>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="full-width">
            <mat-label>Statut </mat-label>
            <mat-select required [(ngModel)]="data.demande.statut">
              <mat-option value="A traiter">A traiter</mat-option>
              <mat-option value="En cours">En cours</mat-option>
              <mat-option *ngIf="!(this.data.demande.interventionPxo && 
              ((this.data.demande.interventionPxo.dDemarrePxo 
             && !this.data.demande.interventionPxo.dRealPxo) ||
              this.data.demande.interventionPxo.dValidPxo))" 
              value="Annulé">Annulé</mat-option>
              <mat-option value="Clos">Clos</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
  </div>
  <div class="col-sm-4 data-class body-class" style="margin: 10px;">
      <div class="col-sm-12">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Commentaire de la demande</mat-label>
          <textarea matInput rows="14" (keyup)="limitCommentTextArea($event)" [(ngModel)]="data.demande.commentaire"></textarea>
          <mat-hint align="end" matTooltip="Le commentaire sera tronqué dans Praxedo s'il dépasse 240 caractères" 
              [ngStyle]="{'color': data.demande && data.demande.commentaire && data.demande.commentaire.length > 240 ? 'red' : 'inherit'}"> 
              {{(data.demande && data.demande.commentaire) ? data.demande.commentaire.length : 0}} / 240
          </mat-hint>
        </mat-form-field>
      </div>     
  </div>
</div>

<!-- Taches  -->
<div class="col-sm-12" style="display: flex;justify-content: space-between;">
  <div class="col-sm-12 data-class body-class" style="margin: 10px;">
    <div class="header-class col-sm-12">
      <h4>
        Les tâches de la demande
      </h4>
      <div>
        <button mat-stroked-button color="success" (click)="onAddAction()"><mat-icon>add</mat-icon> Ajouter une tâche </button>
        <button *ngIf="data.demande.agence.lienWebHook && data.demande.agence.lienWebHook.length > 0" mat-stroked-button color="success" (click)="onAddRelance()"><mat-icon>chat_bubble_outline</mat-icon> Relancer</button>
    </div>
    </div>
    <div  *ngIf="data.demande.actions && data.demande.actions.length" class="col-sm-12 data-class body-class mat-tablee table-inner-scroll-container">

      <table mat-table [dataSource]="matDataSourceAction" class="tableSimpl table-inner-scroll">
        <caption></caption>
        <ng-container matColumnDef="modifications">
          <th mat-header-cell *matHeaderCellDef> Modifier </th>
          <td mat-cell class="action-btns" *matCellDef="let element">
            <div class="btn-container">
              <button mat-mini-fab color="primary" (click)="onVoirAction(element.id)" *ngIf="element.statut == 'Clos'" class="tableau-btn"><mat-icon class="tableau-mat-icon">search</mat-icon></button>
              <button mat-mini-fab color="primary" (click)="onOpenAction(element.id)" *ngIf="element.statut != 'Clos'" class="tableau-btn"><mat-icon class="tableau-mat-icon">create</mat-icon></button>
            </div>
          </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Id. </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
    
        <ng-container matColumnDef="clore">
          <th mat-header-cell *matHeaderCellDef> Clore </th>
          <td mat-cell *matCellDef="let element">
          <p class="col-sm-6 body-class" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'">
            <!-- typeActionId = 1000 : système -->
            <mat-checkbox [disabled]="element.typeActionId >= 1000" (change)="onChecked($event.checked, element)" [checked]="element.statut === 'Clos'? true : false"></mat-checkbox>
          </p>
        </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="dateRealisation">
          <th mat-header-cell *matHeaderCellDef> Date d'échéance </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'"> {{ element.dateRealisation | date:'dd/MM/yyyy' }} </td>
        </ng-container>
    
        <ng-container matColumnDef="dateCloture">
          <th mat-header-cell *matHeaderCellDef> Date de cloture </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'"> {{ element.dateCloture | date:'dd/MM/yyyy' }} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="duree">
          <th mat-header-cell *matHeaderCellDef> Durée (00h00) </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'">
            <span > {{ element.duree }} </span> 
          </td>
        </ng-container>
    
        <ng-container matColumnDef="heureDebut">
          <th mat-header-cell *matHeaderCellDef> Heure (00h00)</th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'">
            <span > {{ element.heureDebut }} </span> 
          </td>
        </ng-container>
    
        <!-- Weight Column -->
        <ng-container matColumnDef="typeAction">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'"> {{element.typeAction.libelle}} </td>
        </ng-container>
    
        <!-- Weight Column -->
        <ng-container matColumnDef="statut">
          <th mat-header-cell *matHeaderCellDef> Statut </th>
          <td mat-cell *matCellDef="let element">
            <strong  *ngIf="element.statut === 'A traiter'" class="chip info-blue text-center"> {{element.statut}} </strong>
            <strong *ngIf="element.statut === 'En cours'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
            <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
            <strong *ngIf="element.statut === 'Clos'" class="chip info-green text-center"> {{element.statut}} </strong>
          </td>
        </ng-container>
        
        <!-- Symbol Column -->
        <ng-container matColumnDef="realisateur">
          <th mat-header-cell *matHeaderCellDef> Réalisateur </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'"> {{element.realisateur.nom + ' ' + element.realisateur.prenom}} </td>
        </ng-container>
    
        <ng-container matColumnDef="objetAction">
          <th mat-header-cell *matHeaderCellDef> Objet </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'"> {{element.objetAction}} </td>
        </ng-container>
    
        <ng-container matColumnDef="commentaire">
          <th mat-header-cell *matHeaderCellDef> Commentaire </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.statut == 'Clos' ? 'text-complete' : 'text-standard'"> {{element.commentaire}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumnsAction"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsAction;"></tr>
      </table>
    </div>
  </div>
</div>
