<mat-icon class="col-md-12" class="close-button" (click)="onModalCancel()">close</mat-icon>

<h5>Ajout d'un document</h5>
<div class="col-md-12">
  <mat-divider></mat-divider>
</div>
<form class="row" [formGroup]="form" (ngSubmit)="submit()" >
  <div class="col-md-12">
    <mat-form-field class="full-width">
      <mat-label>Nom du fichier</mat-label>
      <input matInput required formControlName="libelle">
      <mat-error *ngIf="libelleControl.hasError('required')">
        Le nom du fichier est obligatoire
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-sm-6">
    <app-file-upload formControlName="file"></app-file-upload>
    <mat-error *ngIf="fileControl.hasError('validatorFileSize')">
        Fichier trop volumineux
    </mat-error>
  </div>
  <div class="col-sm-6">
    <button mat-flat-button color="primary" type="submit">
      <mat-icon>publish</mat-icon> Envoyer
    </button>
  </div>
</form>
<mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress"></mat-progress-bar>
