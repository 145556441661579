import { TierModel } from '../../shared/models/tier/tier.model';
import { Component, OnInit, SimpleChanges, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalPriseNoteComponent } from './modal-prise-note.component';
import { default as packageInfo }  from 'package.json';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EventEmitter } from '@angular/core';
import { ModalListeTiersComponent } from '../prise-appel/modal-liste-tiers/modal-liste-tiers.component';
import { TierService } from '../../services/tier/tier.service';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { ModalsActionInfos, ModalsDemandeInfos, ModalsMenuInfo, ModalsTierInfos } from '../../shared/modals-menu-info';
import { TierDTO } from '../../shared/dtos/tierDTO';
import { TransformatorTiersModel } from '../../utils/transformator-tiers.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { PriseAppelService } from '../../services/prise-appel.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { filterTierDTO } from '../../shared/dtos/filterTierDTO';
import { FiltreService } from '../../services/filter.service';
import { FiltreDemandeDTO } from '../../shared/dtos/filterDemandeDTO';
import { FiltreDevisDTO } from '../../shared/dtos/filterDevisDTO';
import { FiltreActionDTO } from '../../shared/dtos/filterActionDTO';


export interface DialogData {
  info: ModalsMenuInfo;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('run', [
      transition('* => *', animate('1000ms linear', keyframes([
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(-15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(-15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(-15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(15deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(00deg)' }),
        style({ transform: 'rotate(00deg)' })
      ])))
    ])
  ]
})

export class MenuComponent implements OnInit, OnChanges {
  showHistory = false;
  @Input() showCall = false;
  @Input() callNumber: string;
  @Input() callNumberTo: string;
  @Input() isExpanded: boolean;
  @Input() isShowing: boolean;
  public transfomator : TransformatorTiersModel = new TransformatorTiersModel();
  trigger: boolean;
  des: NodeJS.Timeout;
  tiersDTO: filterTierDTO;

  constructor(private router: Router,
    private serviceTier: TierService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    public IntercomToastrService: IntercomToastrService,
    private sessionService: SessionService,
    private filterService: FiltreService,
    private priseAppelService: PriseAppelService) {
  }
  navHistory: ModalsMenuInfo[][] = [];
  navActionHistory: ModalsActionInfos[] = [];
  navDemandeHistory: ModalsDemandeInfos[] = [];
  navTierHistory: ModalsTierInfos[] = [];
  private searchForm: UntypedFormGroup = this.fb.group({
    raisonSocial: [null],
    idJason: [null],
    address: [null],
    ville: [null],
    libelle: [null],
    codePostal: [null, Validators.compose([Validators.maxLength(5)])],
    nomInterlocuteur: [null],
    prenomInterlocuteur: [null],
    telInterlocuteur: [null],
  });
  @Output() Emittertiers = new EventEmitter<TierModel[]>();
  public version = packageInfo.version;
  public versionSmall = packageInfo.version.substr(0, 5);

  ngOnInit() {
    const tierh = localStorage.getItem('historyTier');
    const actionh = localStorage.getItem('historyAction');
    const demandeh =  localStorage.getItem('historyDemande');
    if (tierh !== null && tierh !== '') {
      this.showHistory = true;
      this.navTierHistory = JSON.parse(tierh);
    }
    if (actionh !== null && actionh !== '') {
      this.showHistory = true;
      this.navActionHistory = JSON.parse(actionh);
    }
    if (demandeh !== null && demandeh !== '') {
      this.showHistory = true;
      this.navDemandeHistory = JSON.parse(demandeh);
    }
    this.navHistory.push(this.navTierHistory);
    this.navHistory.push(this.navDemandeHistory);
    this.navHistory.push(this.navActionHistory);

    this.tiersDTO = new filterTierDTO();
    this.priseAppelService.currentTierRechercheForm.subscribe(form => {
      this.searchForm.patchValue(form.value);
    });
    this.priseAppelService.filterDTOSubject.subscribe(tiersDTO=> {
      this.tiersDTO = tiersDTO;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showCall !== undefined) {
      this.showCall = changes.showCall.currentValue;
      if (this.showCall) {
        this.des = setInterval(() => (this.trigger = !this.trigger), 1000);
      } else if (this.des) {
        clearInterval(this.des);
      }
    }
    if (changes.callNumber !== undefined) {
      this.callNumber = changes.callNumber.currentValue;
    }
    if (changes.callNumberTo !== undefined) {
      this.callNumberTo = changes.callNumberTo.currentValue;
    }
  }

  silentrefresh() {
    this.sessionService.silentRefresh();
  }

  openPriseNote() {
    const dialogRef = this.dialog.open(ModalPriseNoteComponent, {
      disableClose: false,
      width: '1800px',
      data: {
      }
    });
    dialogRef.afterClosed().subscribe((result: DialogData) => {
    });
  }

  openPriseAppel() {
    const info = new ModalsTierInfos();
    info.tierFields = new TierModel();
    info.tierFields.typeTier = '';
    if (this.des) {
      clearInterval(this.des);
    }
    info.tierFields.telephone =  this.callNumber;
    const query = new SimpleQuery<TierDTO>();
    if (this.callNumber) {         // afficher prise dappel avec tiers vide
      this.searchForm.get("telInterlocuteur").setValue(this.callNumber);
      this.priseAppelService.updateTierRechercheForm(this.searchForm);
      this.tiersDTO.interlocuteur.TelPrincipal = this.callNumber;
      this.priseAppelService.changeFilter(this.tiersDTO);
    }
    if (this.router.url !== '/prise-appel') {
      this.router.navigate(['/prise-appel']);
    }
  }

  clearDemandes(): void {
    this.filterService.changeFiltreDemande(new FiltreDemandeDTO());
  }
  
  clearActions(): void {
    this.filterService.changeFiltreAction(new FiltreActionDTO());
  }

  clearDevis(): void {
    this.filterService.changeFiltreDevis(new FiltreDevisDTO());
  }

  openModalTiers(info: ModalsTierInfos): void {
    if (info === null) {
      info = new ModalsTierInfos();
      info.tierFields = new TierModel();
      info.tierFields.typeTier = '';
      (<ModalsTierInfos>info).selectedOption = 'SimpleTier';
    }
    const dialogRef = this.dialog.open(ModalListeTiersComponent, {disableClose: true,
      width: '1500px',
      data: {
        info: info
      }
    });
    dialogRef.afterClosed().subscribe((result: DialogData) => {
      if (result.info !== undefined) {
        result.info.modalName = 'Annuaire';
        if ((<ModalsTierInfos>result.info).tierFields.rechercheTitle !== undefined &&
            (<ModalsTierInfos>result.info).tierFields.rechercheTitle.length > 2) {
          this.showHistory = true;
          const index = this.navTierHistory.findIndex(histo =>
            histo !== null && histo !== undefined &&
            histo.tierFields !== undefined && histo.tierFields !== null &&
            histo.tierFields.rechercheTitle === (<ModalsTierInfos>result.info).tierFields.rechercheTitle &&
            (histo.selectedOption === (<ModalsTierInfos>result.info).selectedOption));
          if (index === -1) {
            this.navTierHistory.unshift(<ModalsTierInfos>result.info);
            if (this.navTierHistory.length > 10) {
              this.navTierHistory.pop();
            }
            localStorage.setItem('historyTier', JSON.stringify(this.navTierHistory));
          }
        }
      }
    });
  }

  Redirect(page: string) {
    if (this.router.url !== '/' + page) {
      this.router.navigate(['/' + page]);
    } else {
      this.router.navigateByUrl(' ', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/' + page]);
      });
    }
  }

}
