<mat-form-field class="full-width">
    <mat-label>{{libelle}}</mat-label>
      <input type= "text"
      matInput
      #villetext
      [required]="required"
      placeholder="Selectionner la ville"
      (change)="add($event.target.value)"
      [formControl]="myControl"
      [matAutocomplete]="auto"
      >
      <mat-autocomplete 
      autoActiveFirstOption 
      #auto="matAutocomplete" 
      (optionSelected)="cityChange($event)"
      [panelWidth]="250"
      >
      <mat-option *ngFor="let city of filteredCities | async" [value]="city.split(';')[0]" [matTooltip]="city.replace(';', ' ')" (mousedown)="$event.preventDefault()"
      matTooltipPosition="after"> {{city.split(';')[0]}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>