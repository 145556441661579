import { ApiService } from './api.service';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TierBasicService } from './basic-service/tier-basic.service';
import { DocumentModel } from '../shared/models/document/document.model';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { VerifyFields } from '../shared/verifyFields';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from '../../shared/session.service';
import { DocumentDTO } from '../shared/dtos/documentDTO';

@Injectable()
export class DocumentService extends TierBasicService {

  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              protected IntercomToastrService: IntercomToastrService) {
                super(http, apiService, sessionService);
                this.serviceUrl = apiService.ROUTEDOCUMENT;
  }

  save(objet: DocumentModel): Observable<DocumentModel> {
    return this.http.post<DocumentModel>(this.apiUrl + this.serviceUrl + 'Save/', objet);
  }

  getFromDemande(demandeId: number): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(this.apiUrl + this.serviceUrl + 'GetFromDemande/' +
    demandeId, {headers: this.header}).pipe(map((documents: DocumentModel[]) => documents));
  }

  filterDocuments(documentDTO: DocumentDTO): Observable<DocumentModel[]> {
    return this.http.post<DocumentModel[]>(this.apiUrl + this.serviceUrl + 'filterDocuments/', documentDTO ,{headers: this.header}).pipe(map((documents: DocumentModel[]) => documents));
  }

  getDoc(idDoc): Observable<Blob> {
    return this.http.get(this.apiUrl + this.serviceUrl + 'getDoc?idDoc=' + idDoc,
      { responseType: 'text' })
      .pipe(switchMap(filegeturl => {
        if (filegeturl) {
          return this.http.get(filegeturl, { responseType: 'blob' });
        }
        return of(null);
      }), catchError(err => this.handleError(err)));
  }

  deleteDoc(idDoc): Observable<DocumentModel> {
    return this.http.get(this.apiUrl + this.serviceUrl + 'deleteDoc?idDoc=' + idDoc,
      { responseType: 'text' })
      .pipe(switchMap(filedeleteurl => {
        if (filedeleteurl) {
          return this.http.delete(filedeleteurl, { reportProgress: true });
        }
        return of(null);
      }), catchError(err => this.handleError(err)));
  }


  sendDoc(filename: string,  typeId: number, type: string, file: File): Observable<HttpEvent<{}>> {
    return this.http.get(this.apiUrl + this.serviceUrl + 'sendDoc?typeId=' + typeId + '&type=' + type + '&libelleDoc=' + filename,
      { responseType: 'text' })
      .pipe(first(), switchMap((fileuploadurl: string) => {
        const headers = new HttpHeaders({ 'Content-Type': file.type });
        if (fileuploadurl) {
          const req = new HttpRequest('PUT', fileuploadurl, file, { headers });
          return this.http.request(req);
        }
        return of(null);
      }), catchError(err => {
        return this.handleError(err);
      }));
  }
  

  getFromAction(actionId: number): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(this.apiUrl + this.serviceUrl + 'GetFromAction/' +
    actionId, {headers: this.header}).pipe(map((documents: DocumentModel[]) => documents));
  }

  getFromTiers(tiersId: number): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(this.apiUrl + this.serviceUrl + 'GetFromTiers/' +
    tiersId, {headers: this.header}).pipe(map((documents: DocumentModel[]) => documents));
  }

  getFromActivite(activiteId: number): Observable<DocumentModel> {
    return this.http.get<DocumentModel>(this.apiUrl + this.serviceUrl + 'GetFromActivite/' +
    activiteId, {headers: this.header}).pipe(map((documents: DocumentModel) => documents));
  }

  getFromAgence(agenceId: number): Observable<DocumentModel> {
    return this.http.get<DocumentModel>(this.apiUrl + this.serviceUrl + 'GetFromAgence/' +
    agenceId, {headers: this.header}).pipe(map((documents: DocumentModel) => documents));
  }

  beforeSave(objet: DocumentModel): boolean {
    let success = true;
    if (!VerifyFields.verifyName(objet.libelle)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir un libelle correct.');
    }
    if (!VerifyFields.verifyUrl(objet.url, objet.type)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir une url correct.');
    }
    if (!VerifyFields.verifyStringExist(objet.libelle)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir un libelle.');
    }
    if (!VerifyFields.verifyStringExist(objet.url)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir une url.');
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    return success;
  }

}
