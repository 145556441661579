import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FiltreDemandeDTO } from '../shared/dtos/filterDemandeDTO';
import { FiltreActionDTO } from '../shared/dtos/filterActionDTO';
import { FiltreDevisDTO } from '../shared/dtos/filterDevisDTO';
import { FiltrePerimetreDTO } from '../shared/dtos/filterPerimetreDTO';

@Injectable({
  providedIn: 'root'
})
export class FiltreService {
  private filtreSourceDemande = new BehaviorSubject<FiltreDemandeDTO>(new FiltreDemandeDTO());
  private filtreSourceAction = new BehaviorSubject<FiltreActionDTO>(new FiltreActionDTO());
  private filtreSourceDevis = new BehaviorSubject<FiltreDevisDTO>(new FiltreDevisDTO());
  private filtreSourcePerimetre = new BehaviorSubject<FiltrePerimetreDTO>(new FiltrePerimetreDTO());

  public filtreDemande = this.filtreSourceDemande.asObservable();
  public filtreAction = this.filtreSourceAction.asObservable();
  public filtreDevis = this.filtreSourceDevis.asObservable();
  public filtrePerimetre = this.filtreSourcePerimetre.asObservable();

  constructor() { 
    const localFlPerimetre = localStorage.getItem('flPerimetre');
    if (localFlPerimetre) {
      this.filtreSourcePerimetre.next(JSON.parse(localFlPerimetre));
    }
  }

  changeFiltreDemande(fl: FiltreDemandeDTO): void {
    this.filtreSourceDemande.next(fl);
  }

  changeFiltreAction(fl: FiltreActionDTO): void {
    this.filtreSourceAction.next(fl);
  }

  changeFiltreDevis(fl: FiltreDevisDTO): void {
    this.filtreSourceDevis.next(fl);
  }

  changeFiltrePerimetre(fl: FiltrePerimetreDTO): void {
    this.filtreSourcePerimetre.next(fl);
    localStorage.setItem('flPerimetre', JSON.stringify(fl));
  }
}
