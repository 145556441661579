    <h3>
        Validation manuelle
    </h3>

<div class="col-sm-12 body-class">
    <div class="col-sm-5">
      <mat-form-field appearance="fill">
        <mat-label>Action</mat-label>
        <mat-select required>
          <mat-option [value]="true">Accepter</mat-option>
          <mat-option [value]="false">Refuser</mat-option>
        </mat-select>
    </mat-form-field>
    </div>

    <div class="col-sm-5">
      <a mat-stroked-button color="success" class="row" mat-button> Importer un document <i class="fas fa-file-import"></i></a>
    </div>

    <div class="col-sm-6">
      <mat-form-field class="full-width">
        <mat-label>Commentaire</mat-label>
        <textarea matInput type="text" name="commentaire" placeholder="Commentaire"></textarea>
      </mat-form-field> 
    </div>

    

    <div class="col-sm-12" style="text-align: right; bottom: 0px"> 
      <button mat-flat-button color="success" class="button-white" (click)="onModalConfirm()">Enregistrer</button>
    </div>
    
</div>