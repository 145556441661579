<div class="table-inner-scroll">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="DateCreation" matSortDirection="desc" matSortDisableClear >

      <!-- Name Column -->
      <ng-container matColumnDef="ObjetAction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Objet </th>
        <td mat-cell [title]="element.commentaire" *matCellDef="let element"> {{element.objetAction | truncate: 25}} </td>
      </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Commentaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
      <td mat-cell  [title]="element.commentaire" *matCellDef="let element"> {{element.commentaire | truncate: 30}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell [title]="element.typeAction.libelle" *matCellDef="let element"> {{element.typeAction.libelle | truncate: 15}} </td>
    </ng-container>


    <ng-container matColumnDef="Statut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
      <td mat-cell *matCellDef="let element" style="width: 100px;">
        <strong *ngIf="element.statut === 'A traiter'" class="chip info-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'En cours'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Clos'" class="chip info-green text-center"> {{element.statut}} </strong>
      </td>
    </ng-container>
    
    <!-- Position Column -->
    <ng-container matColumnDef="DateCreation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de création </th>
      <td mat-cell *matCellDef="let element"> {{element.dateCreation | date:'dd/MM/yyyy HH:mm'}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="DateRealisation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date d'échéance</th>
      <td mat-cell *matCellDef="let element"> {{element.dateRealisation | date:'dd/MM/yyyy'}}  </td>
    </ng-container>

    <ng-container matColumnDef="Createur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Créateur </th>
      <td mat-cell *matCellDef="let element" [title]="element.createur.prenom + ' '+ element.createur.nom">
        <div>{{ element.createur.prenom}} </div></td>
    </ng-container>

    <ng-container matColumnDef="Realisateur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Réalisateur </th>
      <td mat-cell *matCellDef="let element"> {{element.realisateur.prenom}} {{element.realisateur.nom}} </td>
    </ng-container>

    <ng-container matColumnDef="Interlocuteur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact </th>
      <td mat-cell *matCellDef="let element"> {{element.prenomInterlocuteur}} {{element.nomInterlocuteur}} </td>
    </ng-container>

    <ng-container matColumnDef="Tiers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiers </th>
      <td [title]="element.tier !== null ? element.tier.libelle : ''" mat-cell *matCellDef="let element"> 
        <div *ngIf="element.tier !== null">
          {{element.tier.libelle  | truncate: 30}}
      </div> 
    </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openEvent(row)"  class="example-element-row"></tr>
  </table>
</div>

<mat-paginator
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="[5, 10, 25, 50]"
  (page)="changePage($event)">
</mat-paginator>

  
