import { BasicModel } from './../../shared/models/basic-models/basic.model';
import { ApiService } from './../api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/modules/shared/session.service';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';

@Injectable()
export class BasicService {

  protected apiUrl: string;
  protected serviceUrl: string;
  protected header: HttpHeaders;
  constructor(protected http: HttpClient,
    apiService: ApiService,
    protected sessionService: SessionService) {
      this.apiUrl = apiService.URLAPI;
      this.header = new HttpHeaders({
        'Authorization': 'Bearer ' + this.sessionService.tokenValue
      });
  }
  /****************************************************************************************************************/
  /*                                  SERVICE                                                                    */
  /****************************************************************************************************************/
  get(id: number): Observable<BasicModel> {
    return this.http.get<BasicModel>(this.apiUrl + this.serviceUrl + 'Get/' + id);
  }

  getFromObjet(objet: any): Observable<SimpleQueryResponse<BasicModel>> {
    return this.http.post<SimpleQueryResponse<BasicModel>>(this.apiUrl + this.serviceUrl + 'GetFromObjetWithPagination/', objet);
  }

  getAll(): Observable<BasicModel[]> {
    return this.http.get<BasicModel[]>(this.apiUrl + this.serviceUrl + 'GetAll/');
  }


  put(objet: BasicModel): Observable<BasicModel> {

    if (!this.beforeSave(objet)) {
      return throwError(new Error('sauvegarde impossible'));
    }
    return this.http.put<BasicModel>(this.apiUrl + this.serviceUrl + 'Update/' + objet.id, objet);
  }

  post(objet: BasicModel): Observable<BasicModel> {
    if (!this.beforeSave(objet)) {
      return throwError(new Error('sauvegarde impossible'));
    }
    return this.http.post<BasicModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
  }


  beforeSave(objet) {
    return true;
  }

  delete(id: number): Observable<BasicModel> {
    return this.http.delete<BasicModel>(this.apiUrl + this.serviceUrl + 'Delete/' + id);
  }
/* ex 1977-04-22T06:00:00Z*/
  convDateToIso(dat: Date): string {
    let dStr: string;
    dStr = dat.getFullYear().toString() + '-' + (dat.getMonth() < 9 ? '0' : '') +  (dat.getMonth() + 1).toString() + '-' +
    (dat.getDate() < 10 ? '0' : '') +  dat.getDate().toString();
    dStr += 'T' + (dat.getHours() < 10 ? '0' : '') +  dat.getHours().toString() + ':' +
    (dat.getMinutes() < 10 ? '0' : '') +  dat.getMinutes().toString() + ':00Z';
    return dStr;
  }

  convDateFromIso(dStr: string): Date {
    const dat: Date = new Date();
    dat.setFullYear( +dStr.substring(0, 4), +dStr.substring(5, 7) - 1, +dStr.substring(8, 10));
    dat.setHours(+dStr.substring(11, 13), +dStr.substring(14, 16), 0, 0);
    return dat;
  }
  convDateToString(dat: Date): string {
    let dStr: string;
    dStr = (dat.getDate() < 10 ? '0' : '') +  dat.getDate().toString() + '/' + (dat.getMonth() < 9 ? '0' : '') +
    (dat.getMonth() + 1).toString() + '/' + dat.getFullYear().toString();
    if (dat.getHours() !== 0 || dat.getMinutes() !== 0) {
      dStr += ' ' + (dat.getHours() < 10 ? '0' : '') +  dat.getHours().toString() + ':' +
      (dat.getMinutes() < 10 ? '0' : '') +  dat.getMinutes().toString() ;
    }
    return dStr;
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
