import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';


@Component({
  selector: 'app-autocomplete-realisateur',
  templateUrl: './autocomplete-realisateur.component.html',
  styleUrls: ['./autocomplete-realisateur.component.scss']
})
export class AutocompleteRealisateurComponent implements OnInit, OnChanges {

  @Input() users: UserModel[];
  @Input() user: UserModel;
  @Input() required = false;
  @Input() libelle: string;
  @Input() disable = false;
  @Output() onUpdated = new EventEmitter<number>();
  @Input() commercialId: number;
  realisateur: string;
  realisateursString: string[] = [];
  filteredRealisateur: Observable<string[]>;
  myControl = new UntypedFormControl();




  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.users !== undefined  ) {
      if (this.commercialId !== undefined && this.commercialId != null) {
        this.user = this.users.find(usr => usr.id === this.commercialId);
        if (this.user !== undefined) {
          this.realisateur = this.user.nom + ' ' + this.user.prenom;
          this.myControl.setValue(this.realisateur);

        }
      } else {
        this.myControl.setValue("");
        this.realisateur = '';
      }

      if(this.disable){
        this.myControl.disable();
      } else {
        this.myControl.enable();
      }

      if (this.realisateursString.length === 0) {
        for (let i = 0; i < this.users.length; i++) {
          this.realisateursString.push(this.users[i].nom + ' ' + (this.users[i].prenom ? this.users[i].prenom : ''));
        }
        this.realisateursString.sort();

        this.filteredRealisateur = this.myControl.valueChanges.pipe(
          startWith(null),
          map((usr: string | null) => usr ? this._filter(usr) : this.realisateursString.slice()));
      }
    }
  }

  /* Add with enterKey */
  add(value: string): void {
    if (this.users.find(usr => usr.nom + ' ' + usr.prenom === value) !== undefined) {
      this.user = Object.create(this.users.find(usr => usr.nom + ' ' + usr.prenom === value));
      this.onUpdated.emit(this.user.id);
    } else {
      if (value === '') {
        this.onUpdated.emit(null);
      }
    }
  }


  /* selected event*/
  
  intervenantChange(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    this.user = Object.create(this.users.find(usr => usr.nom + ' ' + usr.prenom === value));
    this.onUpdated.emit(this.user.id);

  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.realisateursString.filter(realisateur => realisateur.toLowerCase().indexOf(filterValue) >= 0);
  }

}
