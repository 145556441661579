import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class UserInfo {
  info: {
    sub: string
    email: string,
    name: string,
    picture: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  authImplicitFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://accounts.google.com',
    // strict discovery document disallows urls which not start with issuers url
    strictDiscoveryDocumentValidation: false,
  
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/login',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    silentRefreshTimeout: 5000, // For faster testing
    sessionChecksEnabled: true,
    clearHashAfterLogin: false,  
    useSilentRefresh: true,
    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: environment.google_client_id,
    // set the scope for the permissions the client should request
    showDebugInformation: true,
    scope: 'openid profile email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.settings.basic',
  };
  gmail = 'https://gmail.googleapis.com';
  private state: string;
  private askedScope: string [];
  userProfileSubject = new BehaviorSubject<UserInfo>(null);
  constructor(private readonly oAuthService: OAuthService) {
    this.launchLogin();
  }

  launchLogin(): void {
    // manually configure a logout url, because googles discovery document does not provide it
    const scope = localStorage.getItem('scope');
    if (scope && scope !== '') {
      this.authImplicitFlowConfig.scope = scope;
    }
    const oldEmail = localStorage.getItem("USER_EMAIL");
    if (oldEmail) {
      this.setUserEmail(oldEmail);
    } else {
      this.oAuthService.configure(this.authImplicitFlowConfig);
      this.oAuthService.logoutUrl = environment.logoutUrl;
      this.startLogin();
    }
  }

  startLogin() {
    this.oAuthService.loadDiscoveryDocument().then(  () => {
      this.oAuthService.tryLoginImplicitFlow();
    }).then( () => {
      // when not logged in, redirecvt to google for login
      // else load user profile
      if (this.oAuthService.hasValidAccessToken() && this.oAuthService.hasValidIdToken() && this.containsAskedScope()) {
        return Promise.resolve();
      }
      return this.oAuthService.silentRefresh()
        .then(info => console.debug('refresh ok', info))
        .catch(result => {
          console.error('refresh error', result);
          this.oAuthService.initImplicitFlow();
          return Promise.resolve();
        });
    }).then(() => {
      this.oAuthService.loadUserProfile()
      .then((profile: any) => {
        if (profile.info.email !== localStorage.getItem('USER_EMAIL')) {
          this.setUserEmail(profile.info.email);
        } else {
          this.userProfileSubject.next(profile as unknown as UserInfo);
        }
      })
      .catch(e => this.oAuthService.logOut());

    });
  }


  setUserEmail(email: string) {
    this.authImplicitFlowConfig.customQueryParams =  {
      login_hint: email
    };
    localStorage.setItem("USER_EMAIL", email);
    this.oAuthService.stopAutomaticRefresh();      
    this.oAuthService.configure(this.authImplicitFlowConfig);
    this.oAuthService.logoutUrl = environment.logoutUrl;
    this.oAuthService.setupAutomaticSilentRefresh();
    this.startLogin();
  }


  containsAskedScope(): boolean {
    const scopeSplit = this.authImplicitFlowConfig.scope.split(' ');
    for (const scope of scopeSplit) {
      if (this.oAuthService.getGrantedScopes().toString().indexOf(scope) === -1) {
        return false;
      }
    }
    return true;
  }

  containScope(scope: string): boolean {
    const scopeSplit = scope.split(' ');
    for (const scope of scopeSplit) {
      if (this.oAuthService.getGrantedScopes().toString().indexOf(scope) === -1) {
        return false;
      }
    }
    return true;
  }

  addScope(scope: string): void {
    if (!this.containScope(scope)) {
      this.authImplicitFlowConfig.scope += ' ' + scope;
      localStorage.setItem('scope', this.authImplicitFlowConfig.scope);
      this.launchLogin();
    }
  }

  setState(state: string): void {
    this.state = state;
  }

  isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  refreshAuth(): Observable<TokenResponse> {
    return from(this.oAuthService.silentRefresh().catch(result => {
          this.oAuthService.initImplicitFlow();
          return Promise.resolve();
    }).then(e => {
      return this.getTokens();
    }));
  }

  getIdToken(): string {
    return this.oAuthService.getIdToken();
  }

  getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  getScope(): string {
    if (this.oAuthService.getGrantedScopes()) {
      return this.oAuthService.getGrantedScopes().toString();
    }
    return "";
  }

  getTokens(): TokenResponse {
    let tokens = {
      access_token: this.getAccessToken(),
      id_token: this.getIdToken(),
      token_type: this.oAuthService.responseType,
      expires_in: this.oAuthService.getIdTokenExpiration(),
      refresh_token: this.oAuthService.getRefreshToken(),
      scope: this.getScope(),
      state: this.oAuthService.state,
    };
    return tokens;
  }

  signOut() {
    try {
      localStorage.removeItem('USER_EMAIL')
      this.oAuthService.stopAutomaticRefresh();
      this.oAuthService.revokeTokenAndLogout().catch(e => 
        this.oAuthService.logOut()
      );
    } catch (exception) {
      this.oAuthService.logOut();
    }
  }

  observableUserInfo(): Observable<UserInfo> {
    return this.userProfileSubject;
  }
}
