<!-- Informations du tiers -->
<mat-card class="col-md-12">
  <app-information-tiers (tiersUpdated)="onTiersUpdated()" [tiers]="tier"></app-information-tiers>
</mat-card>

<div class="col-md-6 table-left">
  <!-- Les adresses du tiers -->
  <mat-card class="col-md-12">
    <div class="col-md-12" style="display: flex;">
      <h4 class="col-md-2" style="align-self: center;">
        Adresses
      </h4>
      <div class="col-md-10">
        <button
          matPrefix
          mat-stroked-button
          color="success"
          (click)="onAddSite()"
          matTooltip="Ajouter une adresse"
          matTooltipPosition="below">
          <mat-icon>add</mat-icon> Ajouter une adresse
        </button>
      <mat-form-field>
        <input matInput placeholder="Recherche adresse" [(ngModel)]="this.simpleQuerySiteGeo.entry.adresse" 
             (change)="rechercheAdresse()">
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-12 table">
    <app-site-geographique (onUpdated)="onUpdateSite($event)" [simpleQueryResponse]="simpleQueryResponseSiteGeo" [tier]=tier></app-site-geographique>
  </div>
</mat-card>
  
</div>

<div class="col-md-6 table-right">
  <mat-card class="col-md-12">
    <div class="col-md-12" style="display: flex;">
      <h4 class="col-md-2" style="align-self: center;">
        Contacts
      </h4>
      <div class="col-md-10">
        <button
          matPrefix
          mat-stroked-button
          color="success"
          (click)="onAddInterlocuteur()"
          matTooltip="Ajouter un contact"
          matTooltipPosition="below">
          <mat-icon>add</mat-icon> Ajouter un contact
        </button>
        <mat-form-field>
          <input matInput placeholder="Recherche contact par nom" [(ngModel)]="this.simpleQueryInterlocuteur.entry.Nom" 
             (change)="rechercheContact()">
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-12 table">
      <app-interlocuteurs (onUpdated)="onUpdateInterlocuteur($event)" [simpleQueryResponse]="simpleQueryResponseInterlocuteur" [tier]=tier ></app-interlocuteurs>
    </div>
  </mat-card>
</div>


