import { Injectable } from "@angular/core";


@Injectable()
export class DashboardDTO {
  public nbATraiter: number = null ;
  public nbEnCours: number = null ;
  public nbAnnule: number = null ;
  public nbClos: number = null ;
  public nbAEnvoyer: number = null ;
  public nbRefuse: number = null ;
  public nbTransmis: number = null ;
  public nbToJason: number = null ;
  public nbValide: number = null ;
  public nbReclaEnCours: number = null ;
  public nbInterventionSent: number = null ;
  public nbInterventionPlanif: number = null ;
  public nbInterventionRealise: number = null ;
  public nbInterventionRegulJason: number = null ;
  public nbInterventionAnnule: number = null ;
  public nbInterventionClos: number = null ;
  public nbInterventionAEnvoye: number = null ;
}
