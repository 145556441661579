
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {CustomSnackBarComponent} from 'src/app/modules/core/components/divers/custom-snack-bar/custom-snack-bar.component';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  currentToken = new BehaviorSubject(null);

  constructor( private angularFireMessaging: AngularFireMessaging,
    private _snackBar: MatSnackBar ) {
  }

    /*
    * This function request permissions and get the current token
    */
    requestPermission() {
      this.angularFireMessaging.requestToken.subscribe((token) => {
        this.currentToken.next(token);
        localStorage.setItem('FCM_TOKEN', token);
      }, (err) => {
        console.error('Unable to get permission to notify.', err);
      });
    }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
      this.openSnackBar(JSON.stringify(payload));
    });
  }

  openSnackBar(message: string) {
    const snackBarRef = this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: message
    });
  }
  refreshToken() {
    this.angularFireMessaging.getToken.subscribe((token) => {
      this.currentToken.next(token);
    });
  }



}

