import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-input-telephone',
    templateUrl: './input.telephone.component.html'
  })
export class InputTelephoneComponent implements OnInit, OnChanges {

  @Input() phoneNumber: string;
  @Input() libelle: string;
  @Input() required = false;
  @Output() phoneNumberChange = new EventEmitter<string>();
  @Output() change = new EventEmitter();
  @Output() isValid = new EventEmitter<boolean>();
  @Input() childFieldStatus: boolean;
  max:number = 14;
  min:number = 14;
  phoneControl = new UntypedFormControl('', [Validators.minLength(this.min), Validators.maxLength(this.max), Validators.pattern('^[0-9]{2}.[0-9]{2}.[0-9]{2}.[0-9]{2}.[0-9]{2}$')]);


  ngOnInit(): void {
    this.phoneControl.setValue(this.phoneNumber);
    this.phoneControl.valueChanges.subscribe(value => {
      this.phoneChangeEvent(value)
    })
    this.isValid.emit(true);
  }

  ngOnChanges(val: SimpleChanges) {
    this.phoneControl.setValue(this.phoneNumber);
    if (this.childFieldStatus){
      this.phoneControl.disable();
    } else {
      this.phoneControl.enable();
    }
    if (val.required && val.required.currentValue != val.required.previousValue) {
      if ((this.phoneControl.valid || this.phoneControl.disabled) || (!val.required.currentValue && (!this.phoneNumber || this.phoneNumber.length === 0))) {
        this.isValid.emit(true);
      } else {
        this.isValid.emit(false);
      }
    }
  }

  formatPhoneNumber(phoneNumber: string): string | undefined {
    const regex = /(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/;
    const match = regex.exec(phoneNumber.replace(/\D/g, ''));
  
    if (match) {
      match.shift(); // Remove the full match element
      const filteredParts = match.filter(part => !!part); // Filter out empty strings
      return filteredParts.join('.');
    }
  
    return undefined; // Or return an empty string '' if you prefer
  }


  phoneChangeEvent(phone: string) {
    if (phone) {
      this.phoneNumber = this.formatPhoneNumber(phone)
      if (this.phoneNumber !== phone) {
        this.phoneControl.setValue(this.phoneNumber);
        this.phoneControl.updateValueAndValidity();
      }
    } else {
      this.phoneNumber = null;
      if (this.phoneNumber !== this.phoneControl.value) {
        this.phoneControl.setValue(null);
      }
    }
    if (this.phoneControl.valid || this.phoneControl.disabled){
      this.phoneNumberChange.emit(this.phoneNumber);
      this.isValid.emit(true);
    } else {
      if (!this.required && this.phoneNumber && this.phoneNumber.length === 0) {
        this.isValid.emit(true);
      } else {
        this.isValid.emit(false);
      }
    }
  }
}

