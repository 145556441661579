import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap, filter } from "rxjs/operators";
import { SessionService } from "../../shared/session.service";
import { DescriptionActiviteModel } from "../shared/models/description-activite.model";
import { DocumentModel } from "../shared/models/document/document.model";
import { ApiService } from "./api.service";
import { BasicService } from "./basic-service/basic.service";
import { DocumentService } from "./document.service";
import { ActiviteModel } from "../shared/models/prestation.model";

@Injectable()
export class DescriptionActiviteService extends BasicService {
    
    constructor(
        http: HttpClient,
        apiService: ApiService,
        sessionService: SessionService,
        private documentService: DocumentService) 
    {
        super(http, apiService, sessionService);
        this.serviceUrl = apiService.ROUTEDESCRIPTIONACTIVITE;
    }

    getFromDevis(agenceId: number, activiteId: number): Observable<DescriptionActiviteModel> {
      return this.http.get<DescriptionActiviteModel>(this.apiUrl + this.serviceUrl + 'GetFromDevis?agenceId='+agenceId+'&activiteId='+activiteId);
  }

    getFromActiviteAgence(agenceId: number, activiteId: number): Observable<DescriptionActiviteModel> {
        return this.http.get<DescriptionActiviteModel>(this.apiUrl + this.serviceUrl + 'GetFromActiviteAgence?agenceId='+agenceId+'&activiteId='+activiteId);
    }
    getActiviteDevisFromAgence(agenceId: number): Observable<ActiviteModel[]> {
      return this.http.get<ActiviteModel[]>(this.apiUrl + this.serviceUrl + 'GetActiviteDevisFromAgence?agenceId='+agenceId);
    }

    deleteDocument(idDoc: number): Observable<number> {
        const avancement = new BehaviorSubject<number>(0);
        this.documentService.delete(idDoc).subscribe(sub => {
          avancement.next(sub.id);
        });
        return avancement.asObservable();
      }
    
      getDocumentById(idDoc: number): Observable<Blob> {
        return this.documentService.getDoc(idDoc);
      }
    
      sendDocument(filename: string, typeId: number, file: File): Observable<number> {
        const avancement = new BehaviorSubject<number>(0);
        this.documentService.sendDoc(filename, typeId, "Activite", file).pipe(filter((doc: HttpEvent<unknown>) => {
          return doc.type === HttpEventType.Response;
        }),
          switchMap((doc: HttpEvent<unknown>) => {
            const document = new DocumentModel();
            document.libelle = filename;
            document.typeId = typeId;
            document.type = "Activite";
            return this.documentService.save(document);
          })).subscribe(sub => {
            avancement.next(sub.id);
          }, error => {
            avancement.next(null);
          });
        return avancement.asObservable();
      }
}