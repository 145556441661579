import { Injectable } from "@angular/core";
import { SocieteModel } from "../models/rsa/societe.model";
import { AgenceModel } from "../models/rsa/agence.model";
import { CommercialModel } from "../models/users/commercial.model";

@Injectable()
export class FiltreDevisDTO {
    public Code: string = '' ;
    public Adresse: string = '' ;
    public CodePostal: string = '' ;
    public Ville: string = '' ;
    public Objet: string = '' ;
    public Statut: string[] ;
    public DStarClotureDevis: Date = null; // Date de création du
    public DEndClotureDevis: Date = null; // Date de création au
    public DStartCreationDevis: Date = null; // Date de création du
    public DEndCreationDevis: Date = null; // Date de création au
    public IdActivites: string = '';

    public pageNumber = 0;
    public pageSize = 50;
    public order = "DateCreation";
    public descending : boolean = true;

    public societes: SocieteModel[] = [];
    public agences: AgenceModel[] = [];
    public AgencesIds: number[] = null;
    public createurs: CommercialModel[] = [];
    public realisateurs: CommercialModel[] = [];
    public createurIds: number[] = null;
    public realisateurIds: number[] = null;
}