<div class="col-md-12" style="display: flex; justify-content: space-between; align-content: center;">
  
  <div class="col-sm-6">
    <h2>Rechercher un tiers</h2>
  </div>
  <div class="col-sm-3" style="display: flex; align-items: center; justify-content: flex-end;">
    <button type=button mat-flat-button color="primary" (click)="clearTrieAndSubmit()"> <mat-icon>search</mat-icon> Lancer la recherche </button>
  </div>
</div>
<form [formGroup]="tierRechercheForm" novalidate (submit)="onSubmitForm()">
  <button type="submit" style="display:none">hidden submit</button>
  <div class="row">
    <div class="col-md-12 body-class" style="display:flex; flex-direction: row; justify-content: space-between;">

      <div class="col-md-4">

        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-md-12" style="display: flex;justify-content: space-between;">
                <h3>Tiers</h3>
                <button type=button mat-flat-button color="default" (click)="clearForm('Tiers')"> <mat-icon>settings_backup_restore</mat-icon> Vider la recherche </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Libelle" formControlName="raisonSocial">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Id Jason" formControlName="idJason">
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-4">
        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-md-12" style="display: flex;justify-content: space-between;">
                <h3>Adresse</h3>
               
                <button type=button color="default" mat-flat-button (click)="clearForm('Adresse')">
                  <mat-icon>settings_backup_restore</mat-icon>
                  Vider la recherche
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <mat-form-field class="full-width">
                  <input #addresstext matInput  placeholder="Adresse" formControlName="address" (keydown.enter)="$event.preventDefault();">
                </mat-form-field>
              </div> 
              
              <div class="col-md-4">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Libelle" formControlName="libelle">
                </mat-form-field>
              </div>
             
            </div>
            <div class="row">
              <div class="col-md-6">
                <app-autocomplete-code-postal 
                  libelle="Code Postal" 
                  (onUpdated)="onCodePostalChange($event)"
                  [(selectedCity)]="selectedCity"
                >
                </app-autocomplete-code-postal>
              </div>

              <div class="col-md-6">
                <app-autocomplete-ville
                  libelle="Ville"
                  (onUpdated)="onCityChange($event)"
                  [(selectedCity)]="selectedCity"
                >
              </app-autocomplete-ville>   
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      
      <div class="col-md-4">

        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-md-12" style="display: flex;justify-content: space-between;">
                <h3>Contact</h3>
               
                <button type=button color="default" mat-flat-button (click)="clearForm('Interlocuteur')">
                  <mat-icon>settings_backup_restore</mat-icon>
                  Vider la recherche
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Nom Interlocuteur" formControlName="nomInterlocuteur">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Prénom Interlocuteur" formControlName="prenomInterlocuteur">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Téléphone Interlocuteur" formControlName="telInterlocuteur">
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>
  </div>
</form>
<div class="row" *ngIf="!isFormEmpty">
  <div class="col-md-12">
    <mat-card>
      <div class="tabResponsives">
        <app-basic-tier-array *ngIf="!IsWait" class="mat-tablee" 
          [tierFilter]="tiersFilter" 
          [isInCreateTiersModal]="isInCreateTiersModal"
          [simpleQueryResponse]="pgQueryResponse"
          (addDemandeOpen)='onAddDemandeOpen($event)'
          (tierOpen)="onTierOpen($event)" 
          (selectTiers)="onTierSelected($event)"
          (pageChange)="pageChange($event)"></app-basic-tier-array>
      </div>
    </mat-card>
  </div>
</div>

