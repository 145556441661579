import { TierDTO } from './tierDTO';
import { Injectable } from "@angular/core";

@Injectable()
export class SyndicDTO extends TierDTO {
  public raisonSociale: string;
  public siretSiren: string;
  public marqueEnseigne: string;
  public typeTier = 'Syndic';
}
