 
         <mat-form-field class="full-width">
          <mat-label> Liste des societes</mat-label> 
          <mat-chip-list #chipList aria-label="societe selection">
            <mat-chip
              *ngFor="let societe of selectedSocieteString"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(societe)"
              [matTooltip]="societe"
              >
              <div class="text-overflow">
                {{societe}}
              </div>
              <mat-icon class="fixed-icon" matChipRemove *ngIf="removable">close</mat-icon>
            </mat-chip>
            <input
              #societeInput
              [formControl]="societeCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let societe of filteredSocietes | async" [value]="societe" matTooltip={{societe}}
            matTooltipPosition="right">
              {{societe}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
 

 
 