import { Injectable } from "@angular/core";
import { SocieteModel } from "../models/rsa/societe.model";
import { AgenceModel } from "../models/rsa/agence.model";
import { CommercialModel } from "../models/users/commercial.model";


@Injectable()
export class FiltreActionDTO {
    public tierId: number = null ;
    public objet: string = '' ;
    public status: string[];
    public startDCreationAction: Date = null; // Date de création du
    public endDCreationAction: Date = null; // Date de création au
    public startDRealisationAction: Date = null; // Date de création du
    public endDRealisationAction: Date = null; // Date de création au
    public typeActionId: number[] = null;
    public createurs: CommercialModel[] = [];
    public realisateurs: CommercialModel[] = [];
    public numDemande : string = '';
    public pageNumber = 0;
    public pageSize = 50;
    public order = "DateCreation";
    public descending : boolean = true;

    public societes: SocieteModel[] = [];
    public agences: AgenceModel[] = [];
}