import { AgenceModel } from '../rsa/agence.model';
import { ActionModel } from '../action.model';
import { BasicModel } from '../basic-models/basic.model';
import { DroitModel } from '../droit.model';
import { DocumentModel } from '../document/document.model';

export class UserModel extends BasicModel {
  code: string;
  nom: string;
  prenom: string;
  tel: string;
  email: string;
  token?: string;
  agenceId: number;
  type?: string;
  agence?: AgenceModel;
  actions?: ActionModel[];
  droits?: DroitModel[];
  actif: boolean;
  webDeviceToken: string;
  photoId?: number;
  photo?: DocumentModel;
  signatureId?: number;
  signature: DocumentModel;
}
