<mat-nav-list>
  <a mat-list-item routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <mat-icon mat-list-icon class="icon" matTooltip="Accueil" matTooltipPosition="right">home</mat-icon>
  </a>
  
  <a mat-list-item routerLink="/agenda" routerLinkActive="active">
      <mat-icon mat-list-icon class="icon" matTooltip="Agenda" matTooltipPosition="right">event_note</mat-icon>
  </a >

  <a mat-list-item *ngIf="!showCall" routerLink="/prise-appel" routerLinkActive="active">
      <mat-icon mat-list-icon class="icon" matTooltip="Prise d'appel" matTooltipPosition="right">phone</mat-icon>
  </a >

  <a  mat-list-item *ngIf="showCall">
    <a [@run]="trigger" (click)="openPriseAppel()">
      <mat-icon  mat-list-icon color="success" class="icon" matTooltip="Prise d'appel" matTooltipPosition="right">call</mat-icon>
    </a>
  </a>
  

  <a mat-list-item routerLink="/tiers" routerLinkActive="active" >
      <mat-icon mat-list-icon class="icon" matTooltip="Rechercher un tiers" matTooltipPosition="right">find_in_page</mat-icon>
  </a>


  <a mat-list-item routerLink="/demande" (click)="clearDemandes()" routerLinkActive="active" >
      <mat-icon mat-list-icon class="icon" matTooltip="Liste des demandes" matTooltipPosition="right">work</mat-icon>
  </a>

  <a mat-list-item routerLink="/action" (click)="clearActions()" routerLinkActive="active" >
      <mat-icon mat-list-icon class="icon" matTooltip="Liste des tâches" matTooltipPosition="right">assignment</mat-icon>
  </a >

  <a mat-list-item routerLink="/devis" (click)="clearDevis()" routerLinkActive="active">
      <mat-icon mat-list-icon class="icon" matTooltip="Liste des devis" matTooltipPosition="right">euro_symbol</mat-icon>
  </a>
  <a mat-list-item>
    <a (click)="openPriseNote()">
      <mat-icon mat-list-icon class="icon" matTooltip="Prise de note" matTooltipPosition="right">note_add</mat-icon>
    </a>
  </a>


  <a class="version" matTooltip={{version}}>V{{versionSmall}}</a>
  
</mat-nav-list>
