
  <div class="col-sm-12 row text-center">

    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Rechercher un protocole</mat-label>
        <input matInput name="protocole" [(ngModel)]="objet.query" (change)="search()">
      </mat-form-field>
    </div>
    <div class="col-sm-2" >
      <a mat-stroked-button color="success"  (click)="onAddProtocole()"><mat-icon>add</mat-icon> Ajouter un protocole</a>
    </div>
    <div class="col-sm-2" >
      <button mat-flat-button color="success"  (click)="openModalUrl()">Importer</button>
    </div>
  
    <div class="col-sm-2" >
      <button mat-flat-button color="primary" class="btn-hover" (click)="export()" >Exporter</button>
    </div>

  </div>
  <div class="col-sm-12 row text-center center-div" *ngIf="this.loading">
    <div class="col-sm-6" style="align-content: center;">
      <p>Importation des protocoles en cours .. Merci de ne pas fermer la page</p>
      <p>{{message}}</p>
      <mat-progress-bar mode="determinate" [value]="avancement * 100"></mat-progress-bar>
    </div>
  </div>
  <div class="col-sm-12 row text-center center-div" *ngIf="this.loadingExport">
    <div class="col-sm-6" style="align-content: center;">
      <p>Export des protocoles en cours .. Merci de ne pas fermer la page</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
 
  <div class="col-md-12">
    <div class="mat-table">
      <div class="table_principale">
        <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="libelle" matSortDirection="asc" matSortDisableClear>
          <caption></caption>

          <ng-container matColumnDef="modifications">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let row">
              <div class="btn-container">
                  <button mat-mini-fab color="primary" class="tableau-btn" (click)="onOpenProtocole(row)"> <mat-icon class="tableau-mat-icon">create</mat-icon></button>
                  <button mat-mini-fab style="color: white; background-color: #b51621" class="tableau-btn" (click)="deleteProtocole(row)"> <mat-icon class="tableau-mat-icon">delete</mat-icon></button>
              </div>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="adresse">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Adresse </th>
            <td mat-cell *matCellDef="let element"> 
              <div *ngIf="element.siteGeo !== null"> {{element.siteGeo.adresse}} {{element.siteGeo.ville}} {{element.siteGeo.codePostal}} </div>
              <div *ngIf="element.siteGeo === null"> Toutes </div>
            </td>
          </ng-container>
        
          <ng-container matColumnDef="prestation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Préstation </th>
            <td mat-cell *matCellDef="let element"> 
              <div *ngIf="element.prestation !== null"> {{element.prestation.libelle}} </div>
              <div *ngIf="element.prestation === null"> Toutes </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="realisateur">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Réalisateur </th>
              <td mat-cell *matCellDef="let element"> 
                <div *ngIf="element.realisateur !== null">   {{element.realisateur.nom}} {{element.realisateur.prenom}} </div>
              </td>
            </ng-container>
        
          <ng-container matColumnDef="protocole">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Protocole </th>
            <td mat-cell *matCellDef="let element">  {{element.protocole}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
  
        <div class="footer">
          <mat-paginator
            #paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="changePage($event)">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>