import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ActiviteService } from '../../../services/prestation.service';
import { ActiviteModel } from '../../../shared/models/prestation.model';
import { ModalSpinnerComponent } from '../../divers/modals/modal-spinner/modal-spinner.component';

export interface DialogData {
  libelle: string;
  prestation: ActiviteModel;
  agenceId: number;
  result: boolean;
}

@Component({
  selector: 'app-record-prestation',
  templateUrl: './record-prestation.component.html',
  styleUrls: ['./record-prestation.component.scss']
})
export class RecordPrestationComponent implements OnInit {

  libelle: string;
  prestation: ActiviteModel;

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<RecordPrestationComponent>,
    public dialogSpinner: MatDialogRef<ModalSpinnerComponent>,
    private prestationService: ActiviteService,
    private IntercomToastrService: IntercomToastrService
    ) { }

  ngOnInit(): void {
    this.libelle = this.data.libelle;
    this.prestation = this.data.prestation;
    if (this.prestation.id === undefined) {
      this.prestation.id = 0;
      this.prestation.commentaire = '';
      this.prestation.agenceId = this.data.agenceId;
    }
  }

  onModalCancel() {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm() {
    if (this.prestation.libelle !== undefined && this.prestation.libelle !== '') {
      this.prestation.libelle = this.prestation.libelle.trim();
      this.dialogSpinner = this.dialog.open(ModalSpinnerComponent);
      if (this.prestation.id === 0) {
        this.prestationService.post(this.prestation).subscribe((prestation: ActiviteModel) => {
          this.IntercomToastrService.success('L\'ajout de votre prestation : ' + prestation.libelle + ' est un succès');
          this.dialogRef.close(true);
          this.dialogSpinner.close();
        }, error => {
          this.IntercomToastrService.error('L\'ajout de votre prestation est un échec. Veuillez vérifier vos droits');
          this.dialogSpinner.close();
        });
      } else {
        this.prestationService.put(this.prestation).subscribe((prestation: ActiviteModel) => {
          this.IntercomToastrService.success('La mise à jour de votre prestation : ' + prestation.libelle + ' est un succès');
          this.dialogRef.close(true);
          this.dialogSpinner.close();
        }, error => {
          this.IntercomToastrService.error('La mise à jour de votre prestation est un échec. Veuillez vérifier vos droits');
          this.dialogSpinner.close();
        });
      }
    }
  }
}
