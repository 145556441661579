<div class="col-sm-9">
  <h3>Prise de note</h3>
</div>

<a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon">close</mat-icon></a>

  
<div class="row center">
  <div class="col-sm-12" style="margin-top: 10px;">
    <mat-form-field class="col-sm-12" appearance="fill" style="background-color:#ffe8bf!important; border-radius: 10px">
      <mat-label style="color: black;">Note</mat-label>
      <textarea  style="color: black;" rows="6" matInput [(ngModel)]="strNote" required></textarea>
    </mat-form-field>
  </div>

  <div class="col-sm-12">
    <app-input-telephone [(phoneNumber)]="telNote" libelle="Téléphone"></app-input-telephone>
  </div>
  <div class="col-sm-12">
    <app-autocomplete-realisateur [required]="true" libelle="Réalisateur" [users]="users" [user]="user" [commercialId]="commercialId" (onUpdated)="onUpdateRealisateurId($event)"> </app-autocomplete-realisateur>
  </div>

  <div class="col-sm-12 text-center">
    <button type="button" mat-flat-button color="primary" (click)="onNoteAdd()">Enregistrer</button>
  </div>
</div>