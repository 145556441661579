import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { TiersComponent } from "../../tiers/tiers.component";
import { TierModel } from "../../../shared/models/tier/tier.model";
import { MatLegacySelectChange as MatSelectChange } from "@angular/material/legacy-select";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TierService } from "../../../services/tier/tier.service";
import { Observable, of } from "rxjs";
import { EntrepriseService } from "../../../services/tier/entreprise.service";
import { HlmService } from "../../../services/tier/hlm.service";
import { MairieService } from "../../../services/tier/mairie.service";
import { ParticulierService } from "../../../services/tier/particulier.service";
import { SyndicService } from "../../../services/tier/syndic.service";
import { SessionService } from "src/app/modules/shared/session.service";
import { IntercomToastrService } from "src/app/modules/shared/IntercomToastr.service";

export interface DialogData {
  result: boolean;
  tier: TierModel;
}
@Component({
  selector: "app-modal-update-tiers",
  templateUrl: "./modal-update-tiers.component.html"
})
export class ModalUpdateTiersComponent implements OnInit {
  tiers: any;
  @ViewChild("addresstext") addresstext: any;
  tierRechercheFom = this.fb.group({
    address: [null],
    ville: [null],
    libelle: [null],
    codePostal: [null, Validators.compose([Validators.maxLength(5)])],
    complementAdresse: [null],
    addressFact: [null],
    villeFact: [null],
    codePostalFact: [null, Validators.compose([Validators.maxLength(5)])],
    complementAdresseFact: [null],
  });
  result: string;
  typeTiers = ["Entreprise", "Mairie", "Syndic", "Hlm", "Particulier"];

  indentifiantJasonInput: string = "";
  tvaInput: string = "";
  emailInput: string = "";
  typeSelected = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TiersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private toastr: IntercomToastrService,
    private tiersService: TierService,
    private entrepriseService: EntrepriseService,
    private particulierService: ParticulierService,
    private mairieService: MairieService,
    private syndicService: SyndicService,
    private hlmService: HlmService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize("80%");

    const isTierExist = this.data.tier;

    if (isTierExist) {
      this.tierRechercheFom.patchValue({
        address: this.data.tier.adresse,
        ville: this.data.tier.ville,
        codePostal: this.data.tier.codePostal,
        addressFact: this.data.tier.adresseFact,
        villeFact: this.data.tier.villeFact,
        codePostalFact: this.data.tier.codePostalFact,
        complementAdresse: this.data.tier.complementAdresse,
        complementAdresseFact:  this.data.tier.complementAdresseFact,
        tva: this.data.tier.tva,

      });
    }
  }

  onTierTypeChange(event: any, value) {
    this.tiers = event;
  }

  onSelectTiersChange(event) {
    this.typeSelected = true;
    if (this.addresstext) {
    }
    // const isEmptyTiers = Object.values(this.data.filter.tiers).every(
    //   (x) => x === null || x === ""
    // ); //check if no address sent from parent

    // switch (event.value) {
    //   case "Entreprise":
    //     this.tiers = new EntrepriseModel();
    //     if (!isEmptyTiers) {
    //       this.tiers.raisonSociale = this.data.filter.tiers.libelle;
    //     }
    //     setTimeout(() => this.getPlaceAutocomplete(), 2500);

    //     break;
    //   case "Mairie":
    //     this.tiers = new MairieModel();
    //     if (!isEmptyTiers) {
    //       this.tiers.commune = this.data.filter.tiers.libelle;
    //     }
    //     setTimeout(() => this.getPlaceAutocomplete(), 2500);
    //     break;
    //   case "Syndic":
    //     this.tiers = new SyndicModel();
    //     if (!isEmptyTiers) {
    //       this.tiers.raisonSociale = this.data.filter.tiers.libelle;
    //     }
    //     setTimeout(() => this.getPlaceAutocomplete(), 2500);
    //     break;
    //   case "Hlm":
    //     this.tiers = new HlmModel();
    //     if (!isEmptyTiers) {
    //       this.tiers.raisonSociale = this.data.filter.tiers.libelle;
    //     }
    //     setTimeout(() => this.getPlaceAutocomplete(), 2500);
    //     break;
    //   case "Particulier":
    //     this.tiers = new ParticulierModel();
    //     if (!isEmptyTiers) {
    //       const libelleSplit =
    //         typeof this.data.filter.tiers.libelle !== "undefined"
    //           ? this.data.filter.tiers.libelle.split(" ")
    //           : undefined;
    //       if (libelleSplit && libelleSplit.length > 0) {
    //         this.tiers.nom = libelleSplit[0];
    //         if (libelleSplit.length > 1) {
    //           this.tiers.prenom = libelleSplit[1];
    //         }
    //       }
    //     }
    //     setTimeout(() => this.getPlaceAutocomplete(), 2500);
    //     break;
    //   default:
    //     break;
    // }
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onTvaChange(event: MatSelectChange) {
    this.tiers.tva = event.value;
  }
  onphoneNumberChange(event){
    this.tiers.telephone = event;
  }

  onModalConfirm(): void {
    this.tiers.email= this.emailInput;
    this.tiers.identifiantJason =this.indentifiantJasonInput;

    this.tiers.adresse = this.tierRechercheFom.value.address
    this.tiers.adresseFact = this.tierRechercheFom.value.addressFact
    this.tiers.complementAdresse = this.tierRechercheFom.value.complementAdresse
    this.tiers.complementAdresseFact = this.tierRechercheFom.value.complementAdresseFact
    this.tiers.codePostal = this.tierRechercheFom.value.codePostal
    this.tiers.codePostalFact = this.tierRechercheFom.value.codePostalFact
    this.tiers.ville = this.tierRechercheFom.value.ville
    this.tiers.villeFact = this.tierRechercheFom.value.villeFact

    this.createTiers(this.tiers).subscribe((tiers) => {
      this.dialogRef.close(true);
      this.toastr.success('', 'Nouveau tiers ajouté' );
      (error) => {
        this.dialogRef.close(false);
      };
    });
  }

  getTiersService(tiers: TierModel): TierService {
    let service = this.tiersService;
    if (tiers && tiers.typeTier && tiers.typeTier !== "") {
      switch (tiers.typeTier) {
        case "Entreprise": {
          service = this.entrepriseService;
          break;
        }
        case "Particulier": {
          service = this.particulierService;
          break;
        }
        case "Mairie": {
          service = this.mairieService;
          break;
        }
        case "Syndic": {
          service = this.syndicService;
          break;
        }
        case "Hlm": {
          service = this.hlmService;
          break;
        }
      }
    }
    return service;
  }

  createTiers(tiers: TierModel): Observable<TierModel> {
    const service = this.getTiersService(tiers);
    tiers.agenceId = this.sessionService.currentUserValue.agenceId;
    const val = service.post(tiers);
    return val;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country: "FR" },
        types: ["address"], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();

      //Add street number to adress form if user choses
      let streetNum = this.getStreetNumber(place);
      let street = this.getStreet(place);
      let city = this.getville(place);
      let cp = this.getPostCode(place);
      if (this.getStreetNumber(place)) {
        this.tierRechercheFom.patchValue({
          address: streetNum + " " + street,
          ville: city,
          codePostal: cp,
          addressFact: streetNum + " " + street,
          villeFact: city,
          codePostalFact: cp,
        });
      } else {
        this.tierRechercheFom.patchValue({
          address: street,
          ville: city,
          codePostal: cp,
          addressFact: street,
          villeFact: city,
          codePostalFact: cp,
        });
      }
    });
  }

  ngAfterViewInit() {
    //After page load, initialize google autocomplete
    // this.getPlaceAutocomplete();
    //After page load, , and start listening for changes in adress form
  }

  //----------Helpers methods for google maps autocomplete--------------
  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: "long_name" },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }
  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: "long_name" },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getville(place) {
    const COMPONENT_TEMPLATE = { locality: "long_name" },
      ville = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return ville;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: "short_name" },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getAddrComponent(place, componentTemplate) {
    let result;
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
  }
}
