import { switchMap } from 'rxjs/operators';
import { AgenceService } from './../../services/rsa/agence.service';
import { AgenceModel } from './../../shared/models/rsa/agence.model';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalFicheUtilisateurComponent, UserType } from './modal-fiche-utilisateur/modal-fiche-utilisateur.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UserService } from '../../services/user/user.service';
import { UserModel } from '../../shared/models/users/user.model';
import { SimpleQueryUser } from '../../shared/simple_query/simpleQueryUser';
import { SimpleQueryUserResponse } from '../../shared/simple_query/simpleQueryUserResponse';

const USERTYPE: UserType[] = [
  {id: 1, libelle: 'Assistante'},
  {id: 2, libelle: 'Commercial'}
];

@Component({
  selector: 'app-gestion-utilisateur',
  templateUrl: './gestion-utilisateur.component.html',
  styleUrls: ['./gestion-utilisateur.component.scss']
})
export class GestionUtilisateurComponent implements OnInit {

  users: UserModel[] = [];
  agences: AgenceModel[] = [];
  displayedColumns: string[] = ['nom', 'prenom', 'tel', 'type', 'email', 'agence'];
  dataSource = new MatTableDataSource<UserModel>();
  length: number;
  pageSize = 50;
  types: UserType[] = USERTYPE;
  objet: SimpleQueryUser = new SimpleQueryUser();
  pageEvent: PageEvent;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor( public router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private agenceService: AgenceService) {}

  ngOnInit() {
    this.objet.query = '';
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.dataSource.paginator = this.paginator;
    this.load_user();
  }

  load_user() {
    this.agenceService.getAll().pipe(switchMap(res => {
      this.agences = <AgenceModel[]>res;
      return this.userService.getFromSimpleQuery(this.objet);
    })).subscribe((query: SimpleQueryUserResponse) => {
      if (query.tiers != null) {
        this.users = this.orderUsers(query.tiers);
        for (const user of this.users) {
          user.agence = this.agences.find(ag => ag.id === user.agenceId);
        }
      } else {
        this.users = [];
      }
      this.length = query.count;
      this.dataSource.data = this.users;
    });
  }

  orderUsers(users: UserModel[]): UserModel[] {
    users = users.sort((a, b) => {
      if (a.nom) {
        return a.nom.localeCompare(b.nom);
      } else {
        return -1;
      }
    });
    return users;
  }

  create_user(): void {
    const dialogRef = this.dialog.open(ModalFicheUtilisateurComponent, {
      width: '60%',
      data: {email: null}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/fiche-user-droit', result]);
       }
    });
  }

  openEvent(user: UserModel) {
    this.router.navigate(['/fiche-user-droit', user.id]);
  }

  changePage(event?: PageEvent) {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadPage();
  }

  search() {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  loadPage() {
    this.userService.getFromSimpleQuery(this.objet).subscribe((query: SimpleQueryUserResponse) => {
      if (query.tiers != null) {
        this.users = query.tiers;
        for (const user of this.users) {
          user.agence = this.agences.find(ag => ag.id === user.agenceId);
        }
      } else {
        this.users = [];
      }
      this.length = query.count;
      this.dataSource = new MatTableDataSource<UserModel>(this.users);
      this.paginator.length = this.length;
    });
  }
}
