import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';

@Component({
    selector: 'app-autocomplete-agence',
    templateUrl: './autocomplete.agence.component.html'
  })
export class AutoCompleteAgenceComponent implements OnInit, OnChanges {

  @Input() agences: AgenceModel[];
  @Input() agenceId: number;
  @Input() libelle: string;
  @Input() required = false;
  @Input() disabled = false;
  @Output() agenceChange = new EventEmitter<AgenceModel>();
  @Output() change = new EventEmitter();
  agencesString: string[] = [];
  filteredAgences: Observable<string[]>;
  myControl = new UntypedFormControl();



  ngOnInit(): void {
  }

  isValid(): boolean {
    return this.agences.find(agence => agence.libelle === this.myControl.value) !== undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.disabled) {
      this.myControl.disable();
    } else {
      this.myControl.enable();
    }
    if (changes.agences !== undefined && changes.agences.currentValue) {
      this.agences = changes.agences.currentValue;
    }
    if (changes.agenceId !== undefined && changes.agenceId.currentValue) {
      this.agenceId = changes.agenceId.currentValue;
    }
    if (this.agences) {
      if (this.agences.length !== 0 && !this.agences.find(agence => agence.id === this.agenceId) && this.agenceId !== 0 && this.agenceId) {
        this.agenceId = this.agences[0].id;
      }
      this.agencesString = this.agences.map(agence => agence.libelle);
    }

    if (this.agences !== undefined && this.agenceId !== undefined) {
      const selectedAgence = this.agences.find(agence => agence.id === this.agenceId);
      if (selectedAgence !== undefined) {
        this.myControl.setValue(selectedAgence.libelle);
      }
    }
    this.filteredAgences = this.myControl.valueChanges
    .pipe(
      startWith(null),
      map((agence: string | undefined | null) => agence ? this._filter(agence) : this.agencesString.slice()));
  }

  changeEvent() {
    if (this.isValid()) {
      const selectedAgence = this.agences.find(agence => agence.libelle === this.myControl.value);
      this.agenceChange.emit(selectedAgence);
    } else {
      if (this.myControl.value === '') {
        this.agenceChange.emit(undefined);
      }
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.agencesString = this.agences.map(agence => agence.libelle);
    return this.agencesString.filter(agence => agence.toLowerCase().indexOf(filterValue) >= 0);
  }

}
