<mat-form-field  class="full-width">
    <mat-label>{{libelle}}</mat-label>
        <input 
          type="text"
          matInput
          (focusout)="onObjetChange($event.target.value)"
          (keydown.enter)="onObjetChange($event.target.value)"
          (change)="$event.target.value === '' ? deleteIfEmptyOrNotFound(): null"
          [required]="required"
          [placeholder]="'Sélectionner le ' + libelle"
          [formControl]="myControl"      
          [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption 
        #auto="matAutocomplete"
        panelWidth="auto"
        (optionSelected)="onObjetChange($event.option.value)">
        
        <mat-option *ngFor="let obj of filteredObjets | async" [value]="obj"> 
          {{obj}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="myControl.hasError('forbiddenNames')">
        Veuillez sélectionner une valeur suggérée
    </mat-error>
  </mat-form-field>
    