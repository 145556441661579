import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { Component,  Inject, Injector, Output, EventEmitter } from '@angular/core';
import { FormulaireService } from './../../services/formulaire.service';
import { DemandeService } from './../../services/demande.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { UserService } from '../../services/user/user.service';
import { FormulaireModel } from '../../shared/models/formulaire.model';
import { UserModel } from '../../shared/models/users/user.model';
import { FormulaireComponent } from './formulaire.component';


export interface DialogData {
  formulaire: FormulaireModel;
  result: boolean;
}

@Component({
  selector: 'app-modal-afficher-formulaire',
  templateUrl: './modal-afficher-formulaire.component.html',
  styleUrls: ['./formulaire.component.scss']
})

export class ModalAfficherFormulaireComponent {

  users: UserModel[];
  user: UserModel;

  private modalsService: ModalsService;

  @Output() onUpdated = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<FormulaireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formulaireService: FormulaireService,
    private toastr: IntercomToastrService,
    injector: Injector,
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService,
    private demandeService: DemandeService
    ) {
    this.modalsService = injector.get('ModalsService');
  }

  

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
    this.modalsService.closeFormulaire(this.data.formulaire);
  }

  onModalMinimize(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  // button enregistrer
  onModalConfirm(): void {
   // Si tout OK => création
   if ( this.data.formulaire.lienEdit !== '' && this.data.formulaire.libelle !== '' ) {
    if (this.data.formulaire.lienEdit.search('edit') !== -1) {
    this.demandeService.getPublishedUrlFromScript(this.data.formulaire.lienEdit)
      .subscribe(response => { this.data.formulaire.lien = this.getUrlPublished(response);
          if ( this.data.formulaire.lien !== null ) {
                this.data.result = true;
                this.formulaireService.put(this.data.formulaire).subscribe((a: FormulaireModel) => {
                this.data.formulaire = a;
                this.toastr.success('', 'Modification du formulaire réussie');
                this.dialogRef.close(true);
                this.modalsService.closeFormulaire(this.data.formulaire);
              });
          } else {
            this.toastr.warning('Erreur formulaire', 'La collecte des emails de ce formulaire n\'est pas activé');
          }
        });
      } else {
        this.toastr.warning('Pas de permissions sur le formulaire', 'Le lien du formulaire n\'est pas valide');
      }
    } else {
      this.toastr.warning('Le lien et le libelle sont obligatoires.', 'Impossible d\'enregistrer la demande');
    }
  }

  getUrlPublished(response: any): string {
    if (response !== null && response.url !== null ) {
      return response.url;
    } else {
      return null;
    }
  }
}
