<div class="table_overflow_x">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="fonction" matSortDirection="asc">
    <caption></caption>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Modifier </th>
      <td class="action-btns" *matCellDef="let row">
        <div class="btn-container" *ngIf="row.agenceId">
          <button  mat-mini-fab class="tableau-btn" color="primary"  (click)="onOpenInter($event, row.id)">
            <mat-icon class="tableau-mat-icon" >create</mat-icon>
          </button>
          <button  mat-mini-fab type="button" color="warn" class="tableau-btn"(click)="onDeleteInter($event, row)">
            <mat-icon class="tableau-mat-icon" >delete</mat-icon>
          </button>
        </div>
        <div class="action-btns"  *ngIf="!row.agenceId">
          <button  mat-mini-fab class="tableau-btn" color="primary"  (click)="onOpenInter($event, row.id)">
            <mat-icon class="tableau-mat-icon" >create</mat-icon>
          </button>
          <button  mat-mini-fab color="gray" disabled class="tableau-btn">
            <mat-icon class="tableau-mat-icon" matTooltip="Impossible de supprimer un contact provenant de Jason"> delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fonction </th>
      <td mat-cell *matCellDef="let element" class="truncate-text" [title]="element.role"> {{element.role}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="prenom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
      <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
    </ng-container>

    <ng-container matColumnDef="telPrincipal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Téléphone </th>
      <td mat-cell *matCellDef="let element"> {{element.telPrincipal}} </td>
    </ng-container>

    <ng-container matColumnDef="telSecondaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Téléphone Secondaire </th>
      <td mat-cell *matCellDef="let element"> {{element.telSecondaire}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openEvent(row)"></tr>
  </table>
  <mat-paginator class="paginator" [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]" (page)="changePage($event)">
  </mat-paginator>
</div>