import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { DocumentService } from '../document.service';
@Injectable()

export class AssistantService extends UserService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService,
    documentService: DocumentService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, apiService, sessionService, documentService, IntercomToastrService);
      this.serviceUrl = apiService.ROUTEASSISTANT;
    }
}
