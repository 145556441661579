import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MairieModel } from 'src/app/modules/core/shared/models/tier/mairie.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

@Component({
  selector: 'app-mairie',
  templateUrl: './mairie.component.html'
})
export class MairieComponent implements OnChanges {


  @Input() tier: MairieModel;
  @Input() required = true;
  @Input() disabled = false;
  @Output() tierChange = new EventEmitter<MairieModel>();
  keyTitleArray = [
    {property: 'commune', libelle: 'commune'},
    {property: 'telephone', libelle: 'téléphone'},
    {property: 'clientNumber', libelle: 'numéro client'},
    {property: 'identifiantJason', libelle: 'identifiant Jason'},
    {property: 'siretSiren', libelle: 'siret/siren'}];
  

  ngOnChanges(changes: SimpleChanges): void {
    this.tier = Object.assign({}, changes.tier.currentValue);
  }
  changeEvent() {
    this.tier.rechercheTitle =  TierModel.getTitle(this.keyTitleArray, this.tier);
    this.tierChange.emit(this.tier);
  }
}
