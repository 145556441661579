import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SessionService } from "../../shared/session.service";
import { ApiService } from "./api.service";
import { BasicService } from "./basic-service/basic.service";
import { FiltrePerimetreDTO } from "../shared/dtos/filterPerimetreDTO";
import { Observable } from "rxjs";

@Injectable()
export class FiltrePerimetreService extends BasicService {

  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService) {
                super(http, apiService, sessionService);
                this.serviceUrl = apiService.ROUTEFILTREPERIMETRE;
  }


  post(dto: FiltrePerimetreDTO): Observable<any> {
    return this.http.post<any>(this.apiUrl + this.serviceUrl + 'save/', dto);
  }

}