import { EntiteSARPModel } from './entite-sarp.model';
import { RegionModel } from './region.model';
import { AgenceModel } from './agence.model';

export class SocieteModel extends EntiteSARPModel {
    public region?: RegionModel;
    public regionId: number;
    public agences?: AgenceModel[];
    public adresse: string;
    public complementAdresse: string;
    public codePostal: string;
    public ville: string;
    public siren?: string
}
