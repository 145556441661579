<div style="text-align: left" class="text" *ngIf="myGroup">
  <form focusInvalidInput [formGroup]="myGroup">

    <div formArrayName="prestationsFormArray">
      <mat-form-field class="col-lg-4" *ngFor="let prestationForm of prestationsFormArray.controls; let i = index" [formGroupName]="i">
        <mat-label>
           {{prestationForm.value.libelle }}
        </mat-label>
        <mat-select formArrayName="offres" formControlName="selected" [value]="prestationForm.get('selected').value" (selectionChange)="calculer()" required>
          <mat-option *ngFor="let offre of prestationForm.controls.offres.controls; let index = index" [formGroupName]="index" [value]="offre.value.id">
            {{offre.value.libelle }}
          </mat-option>                         
        </mat-select>
        <mat-error *ngIf="prestationForm.get('selected').hasError('required')">
          Veuillez saisir une offre
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="col-lg-6" formGroupName="zone">
      <mat-label>Zone d'intervention</mat-label>
      <mat-select formControlName="libelle" required (selectionChange)="zoneChange($event)">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let agenceZone of agenceZones" [value]="agenceZone.libelle">{{ agenceZone.libelle + " - Coefficient " + (agenceZone.majoration)}}</mat-option>
      </mat-select>
      <mat-error *ngIf="myGroup.get('zone').get('libelle').hasError('required')">
        Veuillez saisir une zone
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-lg-2" formGroupName="tva">
      <mat-label>TVA</mat-label>
        <mat-select formControlName="taux" (selectionChange)="tvaChange()" required> 
        <mat-option [value]="20">20%</mat-option>
        <mat-option [value]="10">10%</mat-option>
        <mat-option [value]="5.5">5.5%</mat-option>
        <mat-option [value]="0">0%</mat-option>
      </mat-select>
      <mat-error *ngIf="myGroup.get('tva').get('taux').hasError('required')">
        Veuillez saisir une TVA
      </mat-error>
    </mat-form-field>
  </form>
</div>