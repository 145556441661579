<div class="table-inner-scroll">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="DateCreation" matSortDirection="desc" matSortDisableClear>
  <caption></caption>
  
    <ng-container matColumnDef="Urgence" >
      <th mat-header-cell *matHeaderCellDef style="  text-align: center;  "> 
        <img src="../../../../../../assets/imgs/logo_praxedo.png" alt="Icon" width="20" height="20"> 
      </th>
      <td mat-cell *matCellDef="let element"  style="text-align: center;" class="text-center">
        <span style="display: flex; flex-direction: row;  flex-wrap: nowrap; align-items: center;">
        
        <mat-icon class="v-align-middle" *ngIf="(element.typeDemandeId === 1000 && !element.interventionPxo) 
          || (element.interventionPxo && !element.interventionPxo.dEnvoiPxo)"
          style="color: #7c3535;" matTooltip="À envoyer">hourglass_full</mat-icon>  <!-- À envoyer -->

        <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dEnvoiPxo 
          && !element.interventionPxo.dStartPlanifPxo && !element.interventionPxo.fAnnulePxo"
          style="color: #d87b84;" matTooltip="Envoyée"> send </mat-icon> <!-- Envoyée, couleur synchro : #f8bb8c-->
          
        <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dStartPlanifPxo 
          && !element.interventionPxo.dSynchroPxo && !element.interventionPxo.fAnnulePxo && !element.interventionPxo.dDemarrePxo && !element.interventionPxo.dRealPxo && !element.interventionPxo.dValidPxo "
          style="color: #ffe065;" matTooltip="Planifiée"> event_available </mat-icon> <!-- Planifiée -->

        <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dSynchroPxo 
        && !element.interventionPxo.dDemarrePxo && !element.interventionPxo.fAnnulePxo && !element.interventionPxo.dRealPxo && !element.interventionPxo.dValidPxo "
        style="color: #FF6600;" matTooltip="Synchronisé">sync</mat-icon> <!-- Synchronisé -->

        <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dDemarrePxo 
          && !element.interventionPxo.dRealPxo && !element.interventionPxo.dValidPxo && !element.interventionPxo.fAnnulePxo"
          style="color: #57d87e;" matTooltip="Démarrée">timelapse</mat-icon> <!-- Démarrée -->

          <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dRealPxo 
          && !element.interventionPxo.dValidPxo
          && !element.interventionPxo.fAnnulePxo"
          style="color: #489edc;" matTooltip="Réalisée"> phonelink_ring </mat-icon> <!-- Réalisée non à envoyer Jason ni annulé -->

        <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dRealPxo 
          && element.interventionPxo.dValidPxo
          && !element.interventionPxo.fAnnulePxo"
          style="color: #a285f8;" matTooltip="Validée"> verified_user </mat-icon> <!-- Validé à envoyer Jason-->

        <strong class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.fAnnulePxo" 
          matTooltip="Annulée"> <mat-icon>close</mat-icon> </strong> <!-- Annulée -->
        </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="envoye">
        <th mat-header-cell *matHeaderCellDef matTooltip="Envoye" class="no-resize"> E </th>
        <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
          <span class="span-icon">
            <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dEnvoiPxo"
              style="color: #d87b84;" matTooltip="Envoyée"> send </mat-icon> <!-- Envoyée, couleur synchro : #f8bb8c-->
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="planif">
        <th mat-header-cell *matHeaderCellDef  matTooltip="Planifie" class="no-resize"> P </th>
        <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
          <span class="span-icon">
            <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dStartPlanifPxo"
              style="color: #ffe065;" matTooltip="Planifiée"> event_available </mat-icon> <!-- Planifiée -->
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="synchro">
        <th mat-header-cell *matHeaderCellDef matTooltip="Synchronisé" class="no-resize"> S </th>
        <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
          <span class="span-icon">
          <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dSynchroPxo"
          style="color: #FF6600;" matTooltip="Synchronisé">sync</mat-icon> <!-- Synchronisé -->
    
          </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="demarre">
        <th mat-header-cell *matHeaderCellDef matTooltip="Démarré" class="no-resize"> D </th>
        <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
          <span class="span-icon">
          <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dDemarrePxo"
            style="color: #57d87e;" matTooltip="Démarrée">timelapse</mat-icon> <!-- Démarrée -->
          </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="realise">
        <th mat-header-cell *matHeaderCellDef matTooltip="Réalisé" class="no-resize"> R </th>
        <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
          <span class="span-icon">
            <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dRealPxo"
            style="color: #489edc;" matTooltip="Réalisée"> phonelink_ring </mat-icon> <!-- Réalisée non à envoyer Jason ni annulé -->
          </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="valide">
        <th mat-header-cell *matHeaderCellDef matTooltip="Validé" class="no-resize"> V </th>
        <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
          <span class="span-icon">
          <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dValidPxo"
            style="color: #a285f8;" matTooltip="Validée"> verified_user </mat-icon> <!-- Validé à envoyer Jason-->
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="annule">
      <th mat-header-cell *matHeaderCellDef  matTooltip="Annulé" class="no-resize"> A </th>
      <td mat-cell *matCellDef="let element"  style="text-align: center;" class="min-icon">
        <span class="span-icon">
          <strong class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.fAnnulePxo" 
            matTooltip="Annulée"> <mat-icon>close</mat-icon> </strong> <!-- Annulée -->
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Demande Intercom </th>
      <td mat-cell *matCellDef="let element"> 
        <div style="white-space: nowrap;"> {{element.id}} 
          <mat-icon (click)="copyclip(element.id, $event)" inline="true" matTooltip="copier dans le presse-papiers">content_copy</mat-icon>
        </div> 
      </td>  
    </ng-container>

    <ng-container matColumnDef="NumDemande">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Numéro </th>
      <td mat-cell *matCellDef="let element"> 
        <div style="white-space: nowrap;"> {{element.numDemande}} 
          <mat-icon (click)="copyclip(element.numDemande, $event)" inline="true" matTooltip="copier dans le presse-papiers">content_copy</mat-icon>
        </div> 
      </td>  
    </ng-container>

    <ng-container matColumnDef="DateCreation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de création </th>
      <td mat-cell *matCellDef="let element"> <div>{{element.dateCreation | date:'dd/MM/yyyy HH:mm'}} </div></td>
    </ng-container>

    <ng-container matColumnDef="DateIntervention">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date d'échéance </th>
      <td mat-cell *matCellDef="let element"> <div>{{element.dateIntervention | date:'dd/MM/yyyy'}} </div></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="TypeDemande">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell  *matCellDef="let element" [title]="element.typeDemande.libelle"> <div>{{element.typeDemande.libelle | truncate: 15}}</div> </td>
    </ng-container>
      
    <!-- Weight Column -->
    <ng-container matColumnDef="Statut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
      <td mat-cell *matCellDef="let element" >
        <strong  *ngIf="element.statut === 'A traiter'" class="chip info-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'En cours'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Clos'" class="chip info-green text-center"> {{element.statut}} </strong>
    </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Objet">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Objet </th>
      <td mat-cell *matCellDef="let element"> <div> {{element.objet}} </div></td>
    </ng-container>

    <ng-container matColumnDef="prestationIds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Activité </th>
      <td mat-cell *matCellDef="let element" [title]="element.prestaLibs ? element.prestaLibs : ''"> 
        <div> {{element.prestaLibs ? (element.prestaLibs | truncate: 18) : ""}} </div></td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Tier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiers DO </th>
      <td mat-cell *matCellDef="let element" [title]="element.tier !== null ? element.tier.libelle : ''"> 
        <div *ngIf="element.tier !== null" > {{element.tier.libelle  | truncate: 18 }} </div> 
      </td>  
    </ng-container>

    <ng-container matColumnDef="IdJasonTier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Jason tiers DO </th>
      <td mat-cell *matCellDef="let element" [title]="element.tier !== null ? element.tier.identifiantJason : ''">
        <div>{{ element.tier !== null ? element.tier.identifiantJason : '' }} </div></td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="TierFact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiers facturé </th>
      <td mat-cell *matCellDef="let element" [title]="element.tierFacture !== null ? element.tierFacture.libelle : ''"> 
        <div *ngIf="element.tierFacture !== null" > {{element.tierFacture.libelle  | truncate: 18 }} </div> 
      </td>  
    </ng-container>

    <ng-container matColumnDef="IdJasonTierFact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Jason tiers facturé </th>
      <td mat-cell *matCellDef="let element" [title]="element.tierFacture !== null ? element.tierFacture.identifiantJason : ''">
        <div>{{ element.tierFacture !== null ? element.tierFacture.identifiantJason : '' }} </div></td>
    </ng-container>

    

    <ng-container matColumnDef="CodePostalAdresse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> CP </th>
      <td mat-cell *matCellDef="let element">
        <div> {{ element.codePostalAdresse ? element.codePostalAdresse : '' }} </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="VilleAdresse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
      <td mat-cell *matCellDef="let element" [title]="(element.villeAdresse ? element.villeAdresse : '')">
        <div> {{ (element.villeAdresse ? element.villeAdresse : '') | truncate: 12 }} </div>
      </td>
    </ng-container>

  <ng-container matColumnDef="Adresse">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Adresse </th>
    <td mat-cell *matCellDef="let element"   [title]="element.adresse">
      <div> {{ element.adresse | truncate: 25 }} </div>
    </td>
  </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Createur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Créateur </th>
      <td mat-cell *matCellDef="let element" [title]="element.createur.prenom + ' '+ element.createur.nom">
        <div>{{ element.createur.prenom}} </div></td>
    </ng-container>

    <ng-container matColumnDef="LibAgenceCrea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Agence créatrice </th>
      <td mat-cell *matCellDef="let element" [title]="element.createur.agence.libelle">
        <div>{{ element.createur.agence.libelle}} </div></td>
    </ng-container>

    <ng-container matColumnDef="CodeAgenceCrea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code d'agence créatrice </th>
      <td mat-cell *matCellDef="let element" [title]="element.createur.agence.code">
        <div>{{ element.createur.agence.code}} </div></td>
    </ng-container>

    <ng-container matColumnDef="Commercial">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Réalisateur </th>
      <td mat-cell *matCellDef="let element" [title]="element.commercial.nom + ' ' + element.commercial.prenom"><div>{{(element.commercial.nom + ' ' + element.commercial.prenom) | truncate: 35}} </div></td>
    </ng-container>
    

    <ng-container matColumnDef="LibAgenceComm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Agence réalisatrice </th>
      <td mat-cell *matCellDef="let element" [title]="element.commercial.prenom + ' '+ element.commercial.nom">
        <div>{{ element.commercial.agence.libelle}} </div></td>
    </ng-container>

    <ng-container matColumnDef="CodeAgenceComm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code d'agence réalisatrice </th>
      <td mat-cell *matCellDef="let element" [title]="element.commercial.prenom + ' '+ element.commercial.nom">
        <div>{{ element.commercial.agence.code}} </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Contact">
      <th mat-header-cell *matHeaderCellDef> Contact </th>
      <td mat-cell *matCellDef="let element" [title]="(element.nomInterlocuteur ? element.nomInterlocuteur : '') + ' ' + (element.prenomInterlocuteur ? element.prenomInterlocuteur : '') ">
        <div>{{ (element.civiliteInterlocuteur ? element.civiliteInterlocuteur : '') + " " + (element.nomInterlocuteur ? element.nomInterlocuteur : '') + " " + (element.prenomInterlocuteur ? element.prenomInterlocuteur : '') }} </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="NumContact">
      <th mat-header-cell *matHeaderCellDef> Tel contact </th>
      <td mat-cell *matCellDef="let element" [title]="element.telephone">
        <div>{{ element.telPrincipalInterlocuteur?element.telPrincipalInterlocuteur : "" +""+ element.telSecondaireInterlocuteur? element.telSecondaireInterlocuteur : "" }} </div></td>
    </ng-container>

    <ng-container matColumnDef="EmailContact">
      <th mat-header-cell *matHeaderCellDef> Mail contact </th>
      <td mat-cell *matCellDef="let element" [title]="element.email">
        <div>{{ element.emailInterlocuteur }} </div></td>
    </ng-container>


    <ng-container matColumnDef="Gestionnaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Gestionnaire </th>
      <td mat-cell *matCellDef="let element" [title]="element.gestionnaire ? element.gestionnaire.prenom + ' ' + element.gestionnaire.nom : '' ">
        <div>{{element.gestionnaire ? (element.gestionnaire.prenom + ' '+ element.gestionnaire.nom) : ''}} </div></td>
    </ng-container>


    <ng-container matColumnDef="LibAgenceGest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Agence gestionnaire </th>
      <td mat-cell *matCellDef="let element" [title]="element.gestionnaire && element.gestionnaire.agence ? element.gestionnaire.agence.libelle : '' ">
        <div>{{ element.gestionnaire && element.gestionnaire.agence ? element.gestionnaire.agence.libelle : ''}} </div></td>
    </ng-container>

    <ng-container matColumnDef="CodeAgenceGest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code d'agence gestionnaire </th>
      <td mat-cell *matCellDef="let element" [title]="element.gestionnaire && element.gestionnaire.agence ? element.gestionnaire.agence.code : '' ">
        <div>{{ element.gestionnaire && element.gestionnaire.agence ? element.gestionnaire.agence.code : ''}} </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Commentaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
      <td mat-cell *matCellDef="let element" [title]="element.commentaire ">
        <div>{{ element.commentaire | truncate: 25}} </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openEvent(row)"  class="example-element-row"></tr>
  </table>
</div>

<mat-paginator class="paginator"
  [length]="length" 
  [pageIndex]="pageIndex" 
  [pageSize]="pageSize"
  [pageSizeOptions]="[5, 10, 25, 50]" 
  (page)="changePage($event)">
</mat-paginator>

