import { Injectable } from "@angular/core";


@Injectable()
export class DocumentDTO {
  public SearchString: string = null;
  public Type: string = null;
  public TypeId: number = null;
 
}
