export class CityData  {
  nom: string;
  code: string;
  codeDepartement: string;
  siren: string;
  codeEpci: string;
  codeRegion: string;
  codesPostaux: string[] = [];
  population: number;
  departement: {
    code: string,
    nom: string
  }
}
