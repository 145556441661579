import { Component, OnInit, Inject } from '@angular/core';
import { InterlocuteursComponent } from './interlocuteurs.component';
import { InterlocuteurService } from './../../services/interlocuteur.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CiviliteService } from '../../services/civilite.service';
import { CiviliteModel } from '../../shared/models/civilite.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { ContactJasonDTO } from '../../shared/dtos/contactJasonDTO';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ToastrLoadingService } from '../../services/toastrLoading.service';
import { environment } from 'src/environments/environment';

export interface DialogData {
  interloc: InterlocuteurModel;
  tierId: number;
  tierIdTJason: number;
  tierIdentifiantJason: string;
  tierEntreprise: boolean;
  result: boolean;
}

@Component({
  selector: 'app-modal-afficher-interlocuteur',
  templateUrl: './modal-afficher-interlocuteur.component.html'
})
export class ModalAfficherInterlocuteurComponent implements OnInit {
  civilites: CiviliteModel[] = [];
  contactJasonDTO: ContactJasonDTO = new ContactJasonDTO();
  emailRequired = true;
  telRequired = true;
  constructor(public dialog: MatDialog
    , public dialogRef: MatDialogRef<InterlocuteursComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private interlocService: InterlocuteurService
    , private civiliteService: CiviliteService
    , private session: SessionService
    , private toastr: IntercomToastrService
    , private toastrLoadingService: ToastrLoadingService) { }

  ngOnInit() {
    this.telRequired = !this.data.interloc.email || this.data.tierEntreprise;
    this.emailRequired = !this.data.interloc.telPrincipal || this.data.tierEntreprise;
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
    });
    this.dialogRef.updateSize('80%');
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
    if (this.data.interloc.role.trim().length !== 0 && this.data.interloc.nom.trim().length !== 0) {
      if (this.data.tierEntreprise ? (this.data.interloc.email && this.data.interloc.telPrincipal && this.data.interloc.email.trim().length !== 0 && this.data.interloc.telPrincipal.trim().length !== 0) :  ((this.data.interloc.telPrincipal && this.data.interloc.telPrincipal.trim().length !== 0) || (this.data.interloc.email && this.data.interloc.email.trim().length !== 0))) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.interlocService.put(this.data.interloc).subscribe((i: InterlocuteurModel) => {
          this.data.result = true;
          if (i.civiliteId) {
            this.data.interloc.civilite = this.civilites.find(civ => i.civiliteId === civ.id);
          }
          this.toastr.success('', 'Mise à jour du contact ' + this.data.interloc.nom );
          if(this.data.tierIdTJason) {
            this.session.currentUser.subscribe(user => {
              this.contactJasonDTO.interlocuteurs = [i];
              this.contactJasonDTO.userIntercom = user.email;
              this.contactJasonDTO.idtAgence = user.agenceId;
              this.contactJasonDTO.IdtTiersIntercom = this.data.tierId;
              this.contactJasonDTO.IdtTiers = this.data.tierIdTJason;
              this.contactJasonDTO.IdentifiantTiers = this.data.tierIdentifiantJason;
              dialogRef.close();
              this.dialogRef.close(this.data);
              let idSave = this.toastrLoadingService.newSave();
              if (environment.sendTojason) {
                this.interlocService.sendContactToJason(this.contactJasonDTO)
                .subscribe(res => {
                  if (res) {
                    if (res.envoyeAJason == 2){
                      this.toastr.success('Contact synchronisé à Jason');
                    } else if (res.envoyeAJason == 1) {
                      this.toastr.warning('Contact refusé par JASON');
                    } else if (res.envoyeAJason == 0) {
                      this.toastr.error('Information non envoyé a Jason');
                    }
                  } else {
                    this.toastr.error("Erreur lors de l’envoie à JASON", null , {timeOut: 20000});
                  }
                  this.toastrLoadingService.endSave(idSave);
                  }, error =>
                  {
                    this.toastr.error("Erreur lors de l’envoie à JASON", null , {timeOut: 20000});
                    this.toastrLoadingService.endSave(idSave);
                  }, 
                  () => {
                    this.toastrLoadingService.endSave(idSave)
                  }
                );
              }
            });
          } else {
            dialogRef.close();
            this.dialogRef.close(this.data);
          }
        }, error => {
          this.toastr.error("Erreur lors de l’enregistrement", null , {timeOut: 20000});
          dialogRef.close();
        });
      } else {
        this.toastr.warning(this.data.tierEntreprise ? 'le téléphone et l\'email sont obligatoires.' : 'le téléphone ou l\'email sont obligatoires.', 'Impossible de modifier l\'interlocuteur');
      }
    } else {
      this.toastr.warning('le rôle et le nom sont obligatoires.', 'Impossible de modifier l\'interlocuteur');
    }
  }
}
