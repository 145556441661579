<div class="row">
  <div class="col-sm-10">
    <h4> {{tier.typeTier}} / {{tier.clientNumber}} / {{tier.libelle}}</h4>
  </div>
  <div class="col-sm-1">
    <button mat-flat-button (click)="openDialog2()">Quitter la fiche contact</button>
  </div>

</div>

<div class="col-7">
  <mat-divider></mat-divider>
</div>
<div class="row">
  <mat-tab-group dynamicHeight>
    
    <mat-tab label="Tiers">
      <div class="row">
        <app-tiers (onUpdated)="onUpdated()" [tier]=tier></app-tiers>
      </div>
    </mat-tab>

    <mat-tab label="Demandes" *ngIf="tier.id !== 0">
      <div class="row">
        <app-demande (onUpdated)="onUpdated()" [tier]=tier></app-demande>
      </div>
    </mat-tab>

    <mat-tab label="Tâches" *ngIf="tier.id !== 0">
      <div class="row">
        <app-action (onUpdated)="onUpdated()" [tier]=tier></app-action>
      </div>
    </mat-tab>

    <!-- Ajout des protocoles -->
    <mat-tab label="Protocoles" *ngIf="tier.id !== 0 && userWithDroitProtocole">
      <div class="row">
        <app-protocole (onUpdated)="onUpdated()" [tier]=tier></app-protocole>
      </div>
    </mat-tab>

  </mat-tab-group>
</div> 

 