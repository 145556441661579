import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { FileUploadComponent } from './components/app-file-upload.component';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatPaginatorIntlFr } from './frenchPaginatorIntl';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { HttpClientJsonpModule } from '@angular/common/http';
import { MatGridListModule } from '@angular/material/grid-list';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { CustomDateAdapter } from './custom-date-adapter';
import { ModalSpinnerComponent } from './components/modals/modal-spinner/modal-spinner.component';
import { InputTelephoneComponent } from './components/input/input.telephone.component';
import { ModalInfoOkComponent } from './components/modals/modal-info-ok.component';
import { HostnameService } from './hostname.service';
import { AutocompleteAdresseComponent } from './components/autocomplete-adresse/autocomplete-adresse.component';
import { AutoCompleteAgenceComponent } from './components/autocomplete.select/autocomplete.agence.component';
import { AutocompleteInterlocuteurComponent } from './components/autocomplete-interlocuteur/autocomplete-interlocuteur.component';
import { BasicTierArrayComponent } from './components/array/basic.tier.array.component';
import { ListInterlocuteursComponent } from './components/list-interlocuteurs/list-interlocuteurs.component';
import { TierAdressesComponent } from './components/list-adress/tier-adresses.component';
import { TruncatePipe } from '../devis/truncate';
import { AutocompleteRealisateurComponent } from './components/autocomplete-realisateur/autocomplete-realisateur.component';
import { AutocompleteAgenceMultipleSelectComponent } from './components/autocomplete-agence-multiple-select/autocomplete-agence-multiple-select.component';
import { AutocompleteSocieteMultipleSelectComponent } from './components/autocomplete-societe-multiple-select/autocomplete-societe-multiple-select.component';
import { ModalHistoriqueDemandesComponent } from './components/modals/modal-historique-demandes/modal-historique-demandes.component';
import { ListTiersDemandeComponent } from '../core/components/prise-appel/liste-tiers-demande/list-tiers-demande.component';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { TiersCardComponent } from './components/tiers-card/tiers-card.component';
import { ModalSelectAdresseComponent } from './components/modals/modal-select-adresse/modal-select-adresse.component';
import { ModalSelectContactComponent } from './components/modals/modal-select-contact/modal-select-contact.component';
import { SiteGeographiqueComponent } from '../core/components/site-geographique/site-geographique.component';
import { InterlocuteursComponent } from '../core/components/interlocuteurs/interlocuteurs.component';
import { ModalAfficherDocumentsComponent } from './components/modals/modal-afficher-documents/modal-afficher-documents.component';
import { ArrayDocumentComponent } from '../core/components/document/array-document/array-document.component';
import { IntercomToastrService } from './IntercomToastr.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AutocompleteCodePostalComponent } from './components/autocomplete-code-postal/autocomplete-code-postal.component';
import { AutocompleteVilleComponent } from './components/autocomplete-ville/autocomplete-ville.component';
import { RemoveTimeZonePipe } from '../core/utils/removeTimeZonePipe';
import { datadogRum } from '@datadog/browser-rum';
import { OrderColumnMenuComponent } from './components/order-column-menu/order-column-menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColumnService } from './column.service';

export class GenericErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        datadogRum.addError(error);
        console.error(error);
    }
}

@NgModule({
    declarations: [
        RemoveTimeZonePipe,
        TierAdressesComponent,
        ListInterlocuteursComponent,
        InputTelephoneComponent,
        ModalSpinnerComponent,
        ModalInfoOkComponent,
        FileUploadComponent,
        AutocompleteAdresseComponent,
        AutoCompleteAgenceComponent,
        AutocompleteInterlocuteurComponent,
        BasicTierArrayComponent,
        TruncatePipe,
        AutocompleteRealisateurComponent,
        AutocompleteAgenceMultipleSelectComponent,
        AutocompleteSocieteMultipleSelectComponent,
        ModalHistoriqueDemandesComponent,
        ListTiersDemandeComponent,
        AddressCardComponent,
        ContactCardComponent,
        OrderColumnMenuComponent,
        TiersCardComponent,
        ModalSelectAdresseComponent,
        ModalSelectContactComponent,
        SiteGeographiqueComponent,
        InterlocuteursComponent,
        ModalAfficherDocumentsComponent,
        ArrayDocumentComponent,
        AutocompleteCodePostalComponent,
        AutocompleteVilleComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatIconModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatCheckboxModule,
        DragDropModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTreeModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatRadioModule,
        MatTooltipModule,
        HttpClientJsonpModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatChipsModule,
        MatSnackBarModule,
        ClipboardModule
    ],
    exports: [
        RemoveTimeZonePipe,
        FileUploadComponent,
        AutocompleteAdresseComponent,
        AutocompleteInterlocuteurComponent,
        AutoCompleteAgenceComponent,
        BasicTierArrayComponent,
        TierAdressesComponent,
        ListInterlocuteursComponent,
        TruncatePipe,
        AutocompleteRealisateurComponent,
        AutocompleteAgenceMultipleSelectComponent,
        AutocompleteSocieteMultipleSelectComponent,
        ContactCardComponent,
        AddressCardComponent,
        TiersCardComponent,
        SiteGeographiqueComponent,
        InterlocuteursComponent,
        ArrayDocumentComponent,
        InputTelephoneComponent,
        OrderColumnMenuComponent,
        AutocompleteCodePostalComponent,
        AutocompleteVilleComponent
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
        { provide: ErrorHandler, useClass: GenericErrorHandler },
        HostnameService,
        IntercomToastrService,
        ColumnService
    ]
})
export class SharedModule {}
