
<div class="etiquettesList" *ngIf="listPrestationsKeys(listPrestations).length > 0">
  <mat-expansion-panel [(expanded)]="panelOpenState" cdkDropList class="example-list">
    <mat-expansion-panel-header>
      <mat-panel-description>
        {{ panelOpenState ? "Masquer" : "Afficher" }} les listes des critères de calcul
      </mat-panel-description>
    </mat-expansion-panel-header>
  <div>
    <ul class="etiquettesListeUl example-box" *ngFor="let prestation of listPrestations" cdkDropList>
      <li class="etiquettesListItem item" (click)="modify(prestation)">
        {{ prestation.libelle }}
      </li>
      <li  class="etiquettesItemLi_etiquette" *ngIf="prestation">
        <ul class="etiquettesItemUl_etiquette"  *ngFor="let offre of prestation.offres"  cdkDrag>
          <li class="etiquetteItem item_etiquette clickable" [style.backgroundColor]="offre.color"  [style.color]="offre.color < '#8b0000' ? 'white': 'black'" >
            {{ offre.libelle }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
  </mat-expansion-panel>
</div>
