import { AgenceModel } from '../../shared/models/rsa/agence.model';
import { ApiService } from './../api.service';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../basic-service/basic.service';
import { BasicModel } from './../../shared/models/basic-models/basic.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionService } from 'src/app/modules/shared/session.service';
import { filter, switchMap } from 'rxjs/operators';
import { DocumentModel } from '../../shared/models/document/document.model';
import { DocumentService } from '../document.service';

@Injectable()

export class AgenceService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService,
    private documentService: DocumentService,
    ) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEAGENCE;
    }

  getAllManaged(id: number): Observable<AgenceModel[]> {
    return this.http.get<AgenceModel[]>(this.apiUrl + this.serviceUrl + 'GetAllManaged/' + id, {headers: this.header});
  }

  deleteDocument(idDoc: number): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.documentService.delete(idDoc).subscribe(sub => {
      avancement.next(sub.id);
    });
    return avancement.asObservable();
  }

  getDocumentById(idDoc: number): Observable<Blob> {
    return this.documentService.getDoc(idDoc);
  }

  sendDocument(filename: string, typeId: number,  file: File): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.documentService.sendDoc(filename, typeId, "Agence", file).pipe(filter((doc: HttpEvent<unknown>) => {
      return doc.type === HttpEventType.Response;
    }),
      switchMap((doc: HttpEvent<unknown>) => {
        //avancement.next(80);
        const document = new DocumentModel();
        document.libelle = filename;
        document.typeId = typeId;
        document.type = "Agence";
        return this.documentService.save(document);
      })).subscribe(sub => {
        avancement.next(sub.id);
      }, error => {
        avancement.next(null);
      });
    return avancement.asObservable();
  }
}
