import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SiteGeoModel } from 'src/app/modules/core/shared/models/site-geo.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { InterlocuteurModel } from 'src/app/modules/core/shared/models/interlocuteur.model';
import { InterlocuteurDTO } from 'src/app/modules/core/shared/dtos/interlocuteurDTO';
import { TierPgResult } from 'src/app/modules/core/shared/dtos/tierPgResult';
import { tierPgQueryResponse } from 'src/app/modules/core/shared/dtos/tierPgQueryResponse';
import { filterTierDTO } from 'src/app/modules/core/shared/dtos/filterTierDTO';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalAjoutTiersComponent } from 'src/app/modules/core/components/type-tier/modal-ajout-tiers/modal-ajout-tiers.component';
import { ModalUpdateTiersComponent } from 'src/app/modules/core/components/type-tier/modal-update-tiers/modal-update-tiers.component';
import { ModalHistoriqueDemandesComponent } from '../modals/modal-historique-demandes/modal-historique-demandes.component';
import { PriseAppelService } from 'src/app/modules/core/services/prise-appel.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';    
import { Router } from '@angular/router';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { DemandeDTO } from 'src/app/modules/core/shared/dtos/demandeDTO';
import { filter } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-basic-tier-array',
  templateUrl: './basic.tier.array.component.html',
  styleUrls: ['./basic.tier.array.component.scss']
})
export class BasicTierArrayComponent implements OnInit, OnChanges , OnDestroy {

  
  @Input() tierRequired = false
  @Input() adresseRequired =false
  @Input() contactRequired =false
  @Input() tierFilter: filterTierDTO;
  @Input() tier: TierModel;
  @Input() pageSize = 10;
  @Input() origin = "";
  @Input() openTier = true;
  @Input() simpleQueryResponse = new tierPgQueryResponse();
  @Input() isInCreateTiersModal : boolean;
  @Input() selectedTiersDuplique: TierModel; //Utilisé pour trigger ngOnChanges 
  @Input() selectedSiteGeo : SiteGeoModel = new SiteGeoModel();
  @Input() selectedInterlocuteur : InterlocuteurDTO = new InterlocuteurDTO();
  @Output() tierOpen = new EventEmitter<TierModel>();
  @Output() addDemandeOpen = new EventEmitter<TierModel>();
  @Output() pageChange = new EventEmitter<SimpleQuery<TierModel>>();
  @Output() selectTiers = new EventEmitter<TierModel>();
  @Output() selectAdresseTier = new EventEmitter<SiteGeoModel>();
  @Output() selectInterlocTier = new EventEmitter<InterlocuteurModel>();

  selectedAdressesTier: TierModel = new TierModel(); //Utilisé pour trigger ngOnChanges dans tier-adresses-component
  displayedColumnsTier: string[] = ['TypeTier', 'IdentifiantJason', 'Libelle', 'CodePostal','Actions'];
  simpleQuery = new SimpleQuery<TierModel>();
  tiersFilter = new filterTierDTO();
  pgQueryResponse = new tierPgQueryResponse();
  currentRoute : any;
  matDataSource: MatTableDataSource<any>;
  simpleQueryInterlocuteur: SimpleQuery<InterlocuteurDTO> =  new SimpleQuery<InterlocuteurDTO>();
  simpleQuerySiteGeo : SimpleQuery<SiteGeoModel> =  new SimpleQuery<SiteGeoModel>();
  selectInterlocuteurTier : TierModel; 
  initDuplic = 0;

  pageEvent: PageEvent;
  sortState: Sort = {active: 'Similarity', direction: 'desc'};
  length: number;
  pageIndex: number;

  subscriptionSelectedTiers: Subscription;
  subscriptionAddedTiers: Subscription;
  addedRowTiers : TierModel;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private ref: ChangeDetectorRef,
    private priseAppelService: PriseAppelService,
    private clipboard: Clipboard,
    private demandeService: DemandeService) {
    this.currentRoute = this.router.url;
  }

  ngOnInit() {
    this.subscriptionSelectedTiers = this.priseAppelService.currentSelectedTiers
    .pipe(filter((sharedSelectedTiersOrigin: {tiers: TierModel, origin: string}) => sharedSelectedTiersOrigin.origin === this.origin))
    .subscribe(sharedSelectedTiersOrigin => {
      if(!_.isEqual(this.selectedAdressesTier,sharedSelectedTiersOrigin))  {
        let tier = sharedSelectedTiersOrigin.tiers
        if(tier.id != undefined && !_.isEqual(tier,this.selectAdresseTier)){
          this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
          this.simpleQueryInterlocuteur.entry.TierLibelle = tier.libelle;
          this.simpleQueryInterlocuteur.entry.TierId = tier.id.toString();

          this.simpleQuerySiteGeo.entry = new SiteGeoModel();
          this.simpleQuerySiteGeo.entry.tier = tier;
          this.simpleQuerySiteGeo.entry.tierId = tier.id;

          this.selectedAdressesTier = tier;
          this.selectInterlocuteurTier = tier;
          
          this.selectTiers.emit(tier);
          this.ref.detectChanges();
        } 
      }
    });
    this.subscriptionAddedTiers = this.priseAppelService.currentAddedTiers
      .pipe(filter((sharedAddedTiersOrigin: {tiers: TierModel, origin: string}) => sharedAddedTiersOrigin.origin === this.origin))
      .subscribe((sharedAddedTiers: {tiers: TierModel, origin: string}) => {
      let tierInput = sharedAddedTiers.tiers;
      if(tierInput.id != undefined && !_.isEqual(tierInput,this.addedRowTiers)){

        let tierRow = new TierPgResult();
        tierRow.id = tierInput.id
        tierRow.tier = tierInput
        tierRow.typeTier = tierInput.typeTier
        tierRow.libelle = tierInput.libelle
        tierRow.tva = tierInput.tva
        tierRow.identifiantJason = tierInput.identifiantJason;
        const currentdata = this.matDataSource.data;
        currentdata.unshift(tierRow);
        this.matDataSource = new MatTableDataSource(currentdata);
        this.matDataSource._updateChangeSubscription();
        // }
        this.addedRowTiers = tierInput;
        this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
        this.simpleQuerySiteGeo.entry = new SiteGeoModel();
        this.simpleQuerySiteGeo.entry.tier = tierInput;
        this.simpleQuerySiteGeo.entry.tierId = tierInput.id;
        this.selectInterlocuteurTier = tierInput;
        this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
        this.simpleQueryInterlocuteur.entry.TierLibelle = tierInput.libelle;
        this.simpleQueryInterlocuteur.entry.TierId = tierInput.id.toString();
    
        this.selectedAdressesTier = tierInput;
        this.selectTiers.emit(tierInput);
        this.ref.detectChanges();
      } 
    });
  }

  copyclip(res: string, event: Event) {
    event.stopPropagation();
    this.clipboard.copy(res);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(this.selectedTiersDuplique && this.selectedTiersDuplique.id && this.simpleQueryResponse && this.simpleQueryResponse.tiers && this.initDuplic < 5){

        this.initDuplic++;
        this.displayedColumnsTier = this.openTier ? ['TypeTier', 'IdentifiantJason', 'Libelle', 'CodePostal','Actions'] : ['TypeTier', 'IdentifiantJason', 'Libelle', 'CodePostal','Actions'];
        this.matDataSource = new MatTableDataSource(Object.assign([],this.simpleQueryResponse.tiers));
        this.length = this.simpleQueryResponse.count;
        this.pageIndex = this.simpleQueryResponse.pageNumber;

        this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
        this.simpleQueryInterlocuteur.entry.TierLibelle = this.selectedTiersDuplique.libelle;
        this.simpleQueryInterlocuteur.entry.TierId = this.selectedTiersDuplique.id.toString();

        this.simpleQuerySiteGeo.entry = new SiteGeoModel();
        this.simpleQuerySiteGeo.entry.tier = this.selectedTiersDuplique;
        this.simpleQuerySiteGeo.entry.tierId = this.selectedTiersDuplique.id;

        this.selectedAdressesTier = this.selectedTiersDuplique;
        this.selectInterlocuteurTier = this.selectedTiersDuplique;
        this.ref.detectChanges();
        
    } else {
      if((this.simpleQueryResponse 
        && this.simpleQueryResponse.tiers 
        && this.simpleQueryResponse.tiers.length > 0) 
        && (changes.simpleQueryResponse && changes.simpleQueryResponse.currentValue !== changes.simpleQueryResponse.previousValue)) {
        this.displayedColumnsTier = this.openTier ? ['TypeTier', 'IdentifiantJason', 'Libelle', 'CodePostal','Actions'] : ['TypeTier', 'IdentifiantJason', 'Libelle', 'CodePostal','Actions'];
  
          this.matDataSource = new MatTableDataSource(Object.assign([],this.simpleQueryResponse.tiers));
          this.length = this.simpleQueryResponse.count;
          this.pageIndex = this.simpleQueryResponse.pageNumber;
        
          this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
          this.simpleQueryInterlocuteur.entry.TierLibelle = undefined;
          this.simpleQueryInterlocuteur.entry.TierId = undefined;
          this.selectInterlocuteurTier =  new TierModel();
          
          this.simpleQuerySiteGeo.entry = new SiteGeoModel();
          this.simpleQuerySiteGeo.entry.tierId = undefined;
          this.simpleQuerySiteGeo.entry.tier = undefined;
          this.selectedAdressesTier = new TierModel();

          this.selectTiers.emit(new TierModel());
        } else if(!this.simpleQueryResponse 
          || !this.simpleQueryResponse.tiers 
          || this.simpleQueryResponse.tiers.length == 0 &&
             !this.addedRowTiers) {
            this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
            this.simpleQueryInterlocuteur.entry.TierLibelle = undefined;
            this.simpleQueryInterlocuteur.entry.TierId = undefined;
            this.selectInterlocuteurTier =  new TierModel();
            
            this.simpleQuerySiteGeo.entry = new SiteGeoModel();
            this.simpleQuerySiteGeo.entry.tierId = undefined;
            this.simpleQuerySiteGeo.entry.tier = undefined;
            this.selectedAdressesTier = new TierModel();
            this.matDataSource = new MatTableDataSource([]);
        }
      }
  }

  ngOnDestroy() {
    this.subscriptionSelectedTiers.unsubscribe();
    this.subscriptionAddedTiers.unsubscribe();
  }

  openEvent(tier: TierModel) {
    this.tierOpen.emit(tier);
  }

  changeEvent() {
    this.tierOpen.emit(this.tier);
  }

  addDemande(tier: TierModel) {
    this.addDemandeOpen.emit(tier);
  }

  
  onAddTier(): void {
    const dialogRef = this.dialog.open(ModalAjoutTiersComponent, {disableClose: true,
      width: '700px',
      data: {
        result: false,
        tier: new TierModel(),
        filter: this.tierFilter,
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.result) {
        let tier = new TierPgResult();
        tier.id = data.tier.id
        tier.tier = data.tier
        tier.libelle = data.tier.libelle
        tier.typeTier = data.tier.typeTier
        tier.tva = data.tier.tva
        tier.identifiantJason = data.tier.identifiantJason
        this.priseAppelService.updateAddedTiers(tier.tier, this.origin)
      }
    });
  }

  onEditTier(tier) : void {
    const dialogRef = this.dialog.open(ModalUpdateTiersComponent, {disableClose: true,
      width: '700px',
      data: {
        tier: tier,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.simpleQuery.pageNumber = this.pageIndex;
        this.simpleQuery.pageSize = this.pageSize;
        this.simpleQuery.order = "Similarity";
        this.simpleQuery.descending = true;
        this.pageChange.emit(this.simpleQuery);
      }
    });
  }

  

  changePage(event?: PageEvent): PageEvent {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    this.simpleQuery.pageNumber = event.pageIndex;
    this.simpleQuery.pageSize = event.pageSize;
    this.pageChange.emit(this.simpleQuery);
    return event;
  }

  trieTab(sort: Sort) {
    if (this.sort.sortables.has(sort.active)) {
      this.sortState = sort;
      this.simpleQuery.pageNumber = this.tierFilter.pageNumber;
      this.simpleQuery.pageSize = this.tierFilter.pageSize;
      this.simpleQuery.order = sort.active;
      this.simpleQuery.descending = sort.direction === 'desc';
      this.pageChange.emit(this.simpleQuery);
    } else {
      for (const key of this.sort.sortables.keys())
      (this.sort.sortables.get(key) as MatSortHeader)._setAnimationTransitionState({ toState: 'active'});
    }
  }

  selectTier(event, tier: TierModel) {
    if(event){
      event.stopPropagation();
    }
    this.selectedAdressesTier = tier;
    this.simpleQuerySiteGeo.entry.tier = tier;
    this.simpleQuerySiteGeo.entry.tierId = tier.id;
    this.selectInterlocuteurTier = tier;
    this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
    this.simpleQueryInterlocuteur.entry.TierLibelle = tier.libelle;
    this.simpleQueryInterlocuteur.entry.TierId = tier.id.toString();
    this.selectTiers.emit(tier);
    if(this.router.url !== '/prise-appel' || (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
      this.priseAppelService.updateTierselectedTiers(tier, this.origin);
    }
  }

  selectAdresseTiers(event){
    this.selectAdresseTier.emit(event)
  }

  selectedInterlocTiers(event){
    this.selectInterlocTier.emit(event)
  }

  openHistoriqueDemandesTiers(tier: TierModel) {
    var demandeDTO = new DemandeDTO();
    demandeDTO.tierId = tier.id;
    const query = new SimpleQuery<DemandeDTO>();
    query.entry = demandeDTO;
    query.order = 'DateIntervention';
    query.descending = true;
    query.pageSize = 10;
    this.demandeService.getFromObjet(query).subscribe(res =>  {
      tier.demandes = res.objets;
      const dialogRef = this.dialog.open(ModalHistoriqueDemandesComponent, {
        data: {
          title: "Historique des demandes du tiers",
          demandes: res.objets,
          query: query
        }
      });    
    })   
  }

}
