<div class="row text-center">
  <a mat-stroked-button color="success" class="row" mat-button (click)="onAddAction()"><mat-icon>add</mat-icon> Ajouter une tâche</a>
</div>

<div class="table_overflow_x">

  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="dateReal" matSortDirection="asc" matSortDisableClear>
    <caption></caption>
    <ng-container matColumnDef="modifications">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell *matCellDef="let row">
        <div class="btn-container">
          <button mat-mini-fab color="primary" (click)="onVoirAction(row)" *ngIf="row.statut == 'Clos'" class="tableau-btn"><mat-icon class="tableau-mat-icon">search</mat-icon></button>
          <button mat-mini-fab color="primary" (click)="onOpenAction(row)" *ngIf="row.statut != 'Clos'" class="tableau-btn"><mat-icon class="tableau-mat-icon">create</mat-icon></button>
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id. </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="dateRealisation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date d'échéance </th>
      <td mat-cell *matCellDef="let element"> {{ element.dateRealisation | date:'dd/MM/yyyy' }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="duree">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Durée (00h00) </th>
      <td mat-cell *matCellDef="let element">
        <span > {{ element.duree }} </span> 
      </td>
    </ng-container>

    <ng-container matColumnDef="heureDebut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Heure (00h00)</th>
      <td mat-cell *matCellDef="let element">
        <span > {{ element.heureDebut }} </span> 
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="typeAction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.typeAction.libelle}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="statut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
      <td mat-cell *matCellDef="let element">
        <strong  *ngIf="element.statut === 'A traiter'" class="chip info-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'En cours'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Clos'" class="chip info-green text-center"> {{element.statut}} </strong>
      </td>
    </ng-container>
    
    <!-- Symbol Column -->
    <ng-container matColumnDef="realisateur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Réalisateur </th>
      <td mat-cell *matCellDef="let element"> {{element.realisateur.nom + ' ' + element.realisateur.prenom}} </td>
    </ng-container>

    <ng-container matColumnDef="objetAction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Objet </th>
      <td mat-cell *matCellDef="let element"> {{element.objetAction}} </td>
    </ng-container>

    <ng-container matColumnDef="commentaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
      <td mat-cell *matCellDef="let element"> {{element.commentaire}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]"
    (page)="changePage($event)">

  </mat-paginator>
</div>