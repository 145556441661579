<div class="col-md-8">
  <h4>Informations générales</h4>
</div>
<div class="col-md-2">
  <button class="action-button bgwhite" mat-flat-button (click)="onShowDocuments()">
    <mat-icon>perm_media</mat-icon> Consulter les documents
</button>
</div>
<div class="col-md-2">
  <button mat-flat-button color="success" *ngIf="this.tiers.id === 0" (click)="onCreateTiers()">Création</button>
  <button mat-flat-button color="success" *ngIf="this.tiers.id !== 0 && canModify"(click)="onUpdateTiers()">Enregistrer</button>
</div>
<div class="col-md-12">
<div *ngIf="particulier !== null">
  <app-particulier (tierChange)="onParticulierChange($event)" [tier]="particulier" [disabled]="!canModify"></app-particulier>
</div>

<div *ngIf="entreprise !== null">
  <app-entreprise (tierChange)="onEntrepriseChange($event)" [tier]="entreprise" [disabled]="!canModify"></app-entreprise>
</div>

<div *ngIf="mairie !== null">
  <app-mairie (tierChange)="onMairieChange($event)" [tier]="mairie" [disabled]="!canModify"></app-mairie>
</div>

<div *ngIf="syndic !== null">
  <app-syndic (tierChange)="onSyndicChange($event)" [tier]="syndic" [disabled]="!canModify"></app-syndic>
</div>

<div *ngIf="hlm !== null">
  <app-hlm (tierChange)="onHlmChange($event)" [tier]="hlm" [disabled]="!canModify"></app-hlm>
</div>

<div class="col-md-2">
  <app-input-telephone [phoneNumber]="telInput" [required]="telRequired" (phoneNumberChange)="onphoneNumberChange($event)" (isValid)="telValid = $event" libelle="Téléphone" [childFieldStatus]="!canModify"></app-input-telephone>
</div>

<div [ngClass]= redimension_email >
  <mat-form-field class="full-width">
    <mat-label>Mail</mat-label>
    <input matInput placeholder="" [(ngModel)]="tiers.email" (ngModelChange)="telRequired = !$event" [required]="emailRequired" [disabled]="!canModify">
  </mat-form-field>
</div>
</div> 

<div class="col-md-12">
 
  <div class="col-md-3">
    <mat-form-field class="full-width">
      <mat-label>TVA</mat-label>
      <mat-select (selectionChange)="onTvaChange($event)" [ngModel]="tiers.tva" [disabled]="!canModify"> 
        <mat-option [value]="null"> Indéfini  </mat-option>
        <mat-option value="20.00"> 20%  </mat-option>
        <mat-option value="10.00"> 10% (attestation)</mat-option>
        <mat-option value="5.50"> 5,50% (attestation)</mat-option>
        <mat-option value="0.00"> 0% (attestation)</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Identifiant jason</mat-label>
      <input matInput placeholder="" maxlength="255" name="intercom-identifiantJ" [value]="tiers.identifiantJason ? tiers.identifiantJason : 'Prospect'" [disabled]="true">
    </mat-form-field>
  </div>
</div>
<div class="col-md-12">
  <h4>Tiers Facturé</h4>

  <div class="col-md-12">
    <app-autocomplete-tiers
      [tierId]="tiers.tierFactureId" 
      (tiersFactureChange)="onTiersFactureChange($event)"
      [canBeDisabled]="true"
      [disabled]="!canModify">
  </app-autocomplete-tiers>
  </div>
</div>


<div class="col-md-6" style="border-right: 3px inset #333;">
  <h4>Adresse principale</h4>

  <div class="col-md-6">
    <mat-form-field class="full-width">
      <mat-label>Adresse</mat-label>
      <input matInput placeholder="" name="intercom-adresse" (change)="onUpdateAdresseFact()" [(ngModel)]="tiers.adresse" [disabled]="!canModify">
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <mat-form-field class="full-width">
      <mat-label>Code postal</mat-label>
      <input matInput placeholder="" name="intercom-code-postal" (change)="onUpdateCodePostalFact()"  [(ngModel)]="tiers.codePostal" [disabled]="!canModify">
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Ville</mat-label>
      <input matInput placeholder="" name="intercom-ville" (change)="onUpdateVilleFact()" [(ngModel)]="tiers.ville" [disabled]="!canModify">
    </mat-form-field>
  </div>

  <div class="col-md-12">
    <mat-form-field class="full-width">
      <mat-label>Complément adresse</mat-label>
      <input matInput placeholder=""  name="intercom-complement-adresse" (change)="onUpdateComplementAdresseFact()"   [(ngModel)]="tiers.complementAdresse" [disabled]="!canModify">
    </mat-form-field>
  </div>
</div>

<div class="col-md-6">
  <h4>Adresse facturation</h4>

  <div class="col-md-6">
    <mat-form-field class="full-width">
      <mat-label>Adresse</mat-label>
      <input matInput placeholder="" name="intercom-adresse"  [(ngModel)]="tiers.adresseFact" [disabled]="!canModify">
    </mat-form-field>
  </div>

  <div class="col-md-2">
    <mat-form-field class="full-width">
      <mat-label>Code postal</mat-label>
      <input matInput placeholder="" name="intercom-code-postale"  [(ngModel)]="tiers.codePostalFact" [disabled]="!canModify">
    </mat-form-field>
  </div>

  <div class="col-md-4">
    <mat-form-field class="full-width">
      <mat-label>Ville</mat-label>
      <input matInput placeholder="" name="intercom-ville" [(ngModel)]="tiers.villeFact" [disabled]="!canModify">
    </mat-form-field>
  </div>

  <div class="col-md-12">
    <mat-form-field class="full-width">
      <mat-label>Complément adresse</mat-label>
      <input matInput placeholder="" name="intercom-complement-adresse" [(ngModel)]="tiers.complementAdresseFact" [disabled]="!canModify">
    </mat-form-field>
  </div>
</div>
