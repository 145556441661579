
<div *ngIf="tier !== null" class="col-sm-12 container">
  <div class="col-sm-4" *ngIf="tier.identifiantJason">
      <h6>Jason Id : {{ tier.identifiantJason }}</h6>
  </div>
  
  <div class="col-sm-6">
      <h6>Libellé : {{ tier.libelle }}</h6>
  </div>
  <div class="col-sm-2">
    <button mat-mini-fab type="button" color="primary" matTooltip="Ouvrir tiers" (click)="onOpenTier()"><mat-icon>person_pin</mat-icon></button>
  </div>
</div>