import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ProtocoleModel } from './../../shared/models/protocole.model';
import { AgenceService } from './../../services/rsa/agence.service';
import { AgenceModel } from './../../shared/models/rsa/agence.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DroitLevels } from '../../shared/droitsLevels';
import { RegionModel } from '../../shared/models/rsa/region.model';
import { SocieteModel } from '../../shared/models/rsa/societe.model';
import { DocumentService } from '../../services/document.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DocumentModel } from '../../shared/models/document/document.model';
import { ModalAddDocumentComponent } from '../document/modal-add-document/modal-add-document.component';
import { SocieteService } from '../../services/rsa/societe.service';
import { ModalShowDocumentComponent } from '../document/modal-show-document/modal-show-document.component';


@Component({
  selector: 'app-parametre-agence',
  templateUrl: './parametre-agence.component.html',
  styleUrls: ['./parametre-agence.component.scss']
})
export class ParametreAgenceComponent implements OnInit {

  searchedAgence: string = '';
  types: string[];
  niveaux: string[];
  agences: AgenceModel[];
  form: UntypedFormGroup;
  agence: AgenceModel;
  allAgence: AgenceModel[] = [];
  protocoles: ProtocoleModel[] = [];
  result: boolean = false;
  destinatairesControl = new FormControl('', [
    Validators.pattern(/^(([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(;[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*)?$/)
  ]);

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private agenceService: AgenceService,
    private documentService: DocumentService,
    private societeService: SocieteService,
    private toastr: IntercomToastrService,
    private router: Router
  ) {
    this.agenceService.getAllManaged(DroitLevels.MANAGE).subscribe(agences => {
      this.agences = agences;
      this.allAgence = agences;
    });
    this.agence = new AgenceModel();
    this.agence.code = null;
    this.agence.libelle = null;
    this.agence.societe = new SocieteModel();
    this.agence.societe.region = new RegionModel();
  }

  ngOnInit(): void {
  }

  onChangeAgence(agence: AgenceModel): void {
    if (agence) {
      this.agence = this.agences.find((ag: AgenceModel) =>  ag.id === agence.id);
    }
  }

  close(): void {
    this.router.navigate(['/']);
  }

  searchedAgenceChanged() {
    if (this.searchedAgence) {
      this.agences = this.allAgence.filter(obj => (obj.libelle).toString().toLowerCase().trim().includes(this.searchedAgence.toLowerCase().trim()));
    } else {
      this.agences = this.allAgence;
      this.agence = new AgenceModel();
      this.agence.code = null;
      this.agence.libelle = null;
      this.agence.societe = new SocieteModel();
      this.agence.societe.region = new RegionModel();
    }
  }

  gestionProtocoleAgence(): void {
    if (this.agence && this.agence.id) {
      this.router.navigate(['/protocole-agence', this.agence.id]);
    } else {
      this.toastr.warning('Veuillez selectionnez une agence dans la liste');
    }
  }

  gestionPrestationAgence(): void {
    if (this.agence && this.agence.id) {
      this.router.navigate(['/activite-agence', this.agence.id]);
    } else {
      this.toastr.warning('Veuillez selectionnez une agence dans la liste');
    }
  }

  gestionZonesAgence(): void {
    if (this.agence && this.agence.id) {
      this.router.navigate(['/zones-agence', this.agence.id]);
    } else {
      this.toastr.warning('Veuillez selectionnez une agence dans la liste');
    }
  }

  submit() {
    if(this.destinatairesControl.valid){
      this.agenceService.put(this.agence).subscribe((res: AgenceModel) => {
        if(res) {
          if(this.agence.societe) {
            this.societeService.put(this.agence.societe).subscribe(_ => {});
          }
          this.agence = res;
          this.toastr.success('L \' agence ' + res.libelle + ' a bien été mise à jour' );
        }
      });
    }else{
      this.toastr.warning('Le champ destinataires est incorrect' );
    }
    
  }

  addLogo() {
    var id = this.agence.logoId;
    if(id) {
      this.documentService.get(id).subscribe(res => {
        const dialogRef = this.dialog.open(ModalShowDocumentComponent, {disableClose: true,
          data: {
            typeId: this.agence.id,
            type: 'Agence',
            document: res,
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // if res -> delete doc
          if(res) {
            this.agence.logoId = null;
          }
        });
      });
    } else {
      const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
        data: {
          type: "Agence",
          typeId: this.agence.id,
          document: new DocumentModel(),
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.documentService.get(res).subscribe((document: DocumentModel) => {
            this.agence.logoId = document.id;
            this.agenceService.put(this.agence).subscribe((agence: AgenceModel) => {
              if(agence) {
                if(this.agence.societe) {
                  this.societeService.put(this.agence.societe).subscribe(_ => {});
                }
                this.agence = agence;
                this.toastr.success('L\'agence ' + agence.libelle + ' a bien été mise à jour' );
              }
            });
          });
        }
      });
    }
  }

  openExemple() {
    window.open('https://docs.google.com/spreadsheets/d/1pAaCr6XG_uulZPybW1B8xzNgU5S8dSO928Fcv4cHuxI/edit#gid=0');
  }

  /*addUrlZones(): void {
    var id = this.agence.urlZoneId;
    if(id) {
      this.documentService.get(id).subscribe(res => {
        const dialogRef = this.dialog.open(ModalAfficherDocumentComponent, {disableClose: true,
          data: {
            typeId: this.agence.id,
            type: 'Agence',
            document: res,
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          if(res) {
          }
        });
      });
    } else {
      const dialogRef = this.dialog.open(ModalAjoutDocumentComponent, {
        disableClose: true,
        data: {
          type: 'Agence',
          document: new DocumentModel(),
          typeId: this.agence.id,
          result: this.result
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.documentService.get(res).subscribe((document: DocumentModel) => {
            this.agence.urlZoneId = document.id;
            this.agenceService.put(this.agence).subscribe((agence: AgenceModel) => {
              if(agence) {
                if(this.agence.societe) {
                  this.societeService.put(this.agence.societe).subscribe(_ => {});
                }
                this.agence = agence;
                this.toastr.success('L\'agence ' + agence.libelle + ' a bien été mise à jour' );
              }
            });
          });
        }
      });
    }
  }*/

  addCertification(numero: number) {
    var id = numero === 1 ? this.agence.certificationId1 : this.agence.certificationId2;
    if(id) {
      this.documentService.get(id).subscribe(res => {
        const dialogRef = this.dialog.open(ModalShowDocumentComponent, {disableClose: true,
          data: {
            typeId: this.agence.id,
            type: 'Agence',
            document: res,
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // if res -> delete doc
          if(res) {
            numero === 1 ? (this.agence.certificationId1 = null ): (this.agence.certificationId2 = null);
          }
        });
      });
    } else {
      const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
        data: {
          type: "Agence",
          typeId: this.agence.id,
          document: new DocumentModel(),
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.documentService.get(res).subscribe((document: DocumentModel) => {
            numero === 1 ? this.agence.certificationId1 = document.id : this.agence.certificationId2 = document.id;
            this.agenceService.put(this.agence).subscribe((agence: AgenceModel) => {
              if(agence) {
                if(this.agence.societe) {
                  this.societeService.put(this.agence.societe).subscribe(_ => {});
                }
                this.agence = agence;
                this.toastr.success('L\'agence ' + agence.libelle + ' a bien été mise à jour' );
              }
            });
          });
        }
      });
    }
  }

  changeTelephone(event) {
    if(event) {
      this.agence.telephone = event;
    }
  }

}

