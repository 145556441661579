import { TrieTabService } from './../../../../services/trie-tab.service';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActionModel } from 'src/app/modules/core/shared/models/action.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { ActionDTO } from 'src/app/modules/core/shared/dtos/actionDTO';

@Component({
  selector: 'app-array-action',
  templateUrl: './array-action.component.html',
  styleUrls: ['./array-action.component.scss']
})
export class ArrayActionComponent implements OnChanges {

  @Input() datasource: ActionModel[];
  @Input() simpleQueryResponse = new SimpleQueryResponse<ActionModel>();
  @Output() actionOpen = new EventEmitter<ActionModel>();
  @Output() pageChange = new EventEmitter<SimpleQuery<ActionDTO>>();
  matDataSource: MatTableDataSource<ActionModel>;
  pageEvent: PageEvent;
  pageIndex = 0;
  sortState: Sort = {active: 'DateCreation', direction: 'desc'};
  length = 0;
  pageSize = 50;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: any[];
  simpleQuery = new SimpleQuery<ActionDTO>();
  constructor(private comp: TrieTabService) { }

  displayedColumns: string[] = ['Tiers', 'ObjetAction', 'Type', 'Statut', 'Commentaire', 'DateRealisation', 'DateCreation' , 'Createur','Realisateur' , 'Interlocuteur'];

  ngOnChanges(changes: SimpleChanges): void {
    const data = changes.datasource.currentValue.slice();
    this.matDataSource = new MatTableDataSource(data);
    this.length = this.simpleQueryResponse.count;
    this.pageIndex = this.simpleQueryResponse.pageNumber;
  }

  openEvent(action: ActionModel) {
    this.actionOpen.emit(action);
  }

  changePage(event?: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    this.simpleQuery.order = this.sortState.active;
    this.simpleQuery.descending = this.sortState.direction === 'desc';
    this.simpleQuery.pageNumber = event.pageIndex;
    this.simpleQuery.pageSize = event.pageSize;
    this.pageChange.emit(this.simpleQuery);
    return event;
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.simpleQuery.order = this.sortState.active;
    this.simpleQuery.descending = this.sortState.direction === 'desc';
    this.pageChange.emit(this.simpleQuery);
  }

}

