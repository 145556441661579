import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-modal-add-filtre-personnalise',
  templateUrl: './modal-add-filtre-personnalise.component.html'
})
export class ModalAddFiltrePersonnaliseComponent implements OnInit {

  public form: UntypedFormGroup;
  libelle: string;
  progress = 0;
  libelleControl: UntypedFormControl;
  constructor(public dialog: MatDialog,
              public router: Router,
              public dialogRef: MatDialogRef<Component>) {
    this.libelleControl = new UntypedFormControl("", Validators.required);
  }

  ngOnInit(): void {
  }

  onModalCancel(): void {
    this.dialogRef.close(null);
  }

  submit(): void {
    if (this.libelleControl.valid) {
      this.dialogRef.close(this.libelleControl.value);
    }
  }
}

