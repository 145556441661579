import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class IntercomToastrService {
  
  constructor (private toastrService: ToastrService) {}

  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any> {
    return this.show(message, title, override, type);
  }

  success(message: string, title?: string, override?: Partial<IndividualConfig>, type?: string):  ActiveToast<any> {
    if (!this.toastrService.findDuplicate("",message, true, true)) {
      return this.toastrService.success(message, title, override);
    }
    return null;
  }

  error(message: string, title?: string, override?: Partial<IndividualConfig>):  ActiveToast<any> {
    if (!this.toastrService.findDuplicate("",message, true, true)) {
      return this.toastrService.error(message, title, override);
    }
    return null;
  }
  info(message: string, title?: string, override?: Partial<IndividualConfig>):  ActiveToast<any> {
    if (!this.toastrService.findDuplicate("",message, true, true)) {
      return this.toastrService.info(message, title, override);
    }
    return null;
  }
  warning(message: string, title?: string, override?: Partial<IndividualConfig>):  ActiveToast<any> {
    if (!this.toastrService.findDuplicate("",message, true, true)) {
      return this.toastrService.warning(message, title, override);
    }
    return null;
  }
  clear(toastId?: number): void {
    this.toastrService.clear(toastId);
  }
  remove(toastId: number): boolean{
    return this.toastrService.remove(toastId);
  }

}
