
<mat-form-field class="col-sm-7" style="padding-left: 0px !important;">
  <mat-label>{{label}}</mat-label>
  <input
    #input
    type="text"
    placeholder="Sélectionner l'adresse"
    aria-label="Adresse"
    matInput
    [formControl]="adresseControl"
    (keydown.Tab)="onFullAutoAdresse($event.target.value)"
    (keypress.enter)="adresseChange($event.target.value)"
    (change)="adresseChange($event.target.value)"
    [matAutocomplete]="auto"
    [(ngModel)]="adresseDemande"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="adresseChange($event.option.viewValue)" >
    <mat-option [ngClass]="{'text-google': this.gmapsAdresses.length !== 0,'text-tiers': this.gmapsAdresses.length === 0}" *ngFor="let adresse of filteredAdresses" [value]="adresse" [matTooltip]="adresse">
      {{ adresse }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div class="col-sm-3 body-class" *ngIf="adresse">
  <mat-form-field class="full-width">
    <mat-label>Libellé</mat-label>
    <input
      matInput
      type="text"
      (change)="this.selectedAdresse.emit(this.adresse)"
      placeholder="Libellé"
      [(ngModel)]="adresse.libelle"
      [disabled]="fieldStatus || disabledLibelle"
    />
  </mat-form-field>
</div>

<div class="col-sm-2 body-class" style="text-align: center;" *ngIf="adresse && timeTravel">
  <button mat-mini-fab color="primary" [matTooltip]="timeTravel !== '' ? timeTravel : 'Afficher l\'itinéraire sur google map'" 
    (click)="openGMap()">
    <mat-icon style="color: white;"> pin_drop </mat-icon>
  </button>
</div>

<div *ngIf="adresse && !disabledCommentaire">
  <mat-form-field class="full-width">
    <mat-label>Commentaire</mat-label>
    <textarea 
    matInput type="text" 
    style="height: 49px;" 
    placeholder="Commentaire" 
    name="intercom-adresse" 
    (change)="this.selectedAdresse.emit(this.adresse); this.commentaireUpdate.emit($event.target.value)" 
    [(ngModel)]="adresse.commentaire"></textarea>
  </mat-form-field>
</div>
