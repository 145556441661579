import { ModalInfoOkComponent } from './../divers/modals/modal-info-ok.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { first } from 'rxjs/operators';
import {  Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalEnregistrerSaisieComponent } from './modal-enregistrer-saisie.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { DemandeService } from '../../services/demande.service';
import { TierService } from '../../services/tier/tier.service';
import { DroitService } from '../../services/user/droit.service';
import { DemandeModel } from '../../shared/models/demande.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { SessionService } from 'src/app/modules/shared/session.service';


export interface DialogData {

  result: boolean;
}

@Component({
  selector: 'app-fiche-contact',
  templateUrl: './fiche-contact.component.html',
  styleUrls: ['./fiche-contact.component.scss']
})
export class FicheContactComponent implements OnInit {
  tier: TierModel = new TierModel();
  link = '';
  links = ['Tiers', 'Interlocuteurs', 'test'];
  activeLink = this.links[0];
  droitlevel = 0;
  userWithDroitProtocole = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private IntercomToastrService: IntercomToastrService,
    private demandeService: DemandeService,
    private modalsService: ModalsService,
    private tierService: TierService,
    private sessionService: SessionService,
    public dialog: MatDialog,
    private droitService: DroitService) {
  }

  ngOnInit() {
    this.loadTiers();
    this.loadDemande();
    if (this.sessionService.currentUserValue && this.sessionService.currentUserValue.droits) {
      const droit = this.sessionService.currentUserValue.droits.filter(userDroit => {
        return userDroit.type >= 3;
      });
      this.userWithDroitProtocole =  (droit !== null && droit.length > 0);
    }
  }

  loadDemande() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.demande) {
        this.demandeService.get(params.demande).pipe(first()).subscribe((demande: DemandeModel) => {
          this.modalsService.openDemande(demande).subscribe();
        });
      }
    });
  }

  loadTiers(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id === '0') {
        this.tierService.tierSubscriber.subscribe((tier: TierModel) => {
          if (tier !== null) {
            this.tier = Object.assign({}, tier);
          } else {
            this.IntercomToastrService.error('Erreur durant la création.', 'Erreur');
            this.router.navigate(['/']);
          }
        });
      } else {
        this.tierService.getFromNumber(params.id).subscribe((tier: TierModel) => {
          if (tier != null && tier !== undefined) {
            this.tier = Object.assign({}, tier);
            this.droitService.getFromAgence(tier.agenceId).subscribe((level: number) => {
              this.droitlevel = level;
            });
          }
        });
      }
    });
  }

  onUpdated() {
    this.loadTiers();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalEnregistrerSaisieComponent, {
      width: '250px',
      data: {
      }
    });
    dialogRef.afterClosed().subscribe();
  }

  openDialog2(): void {
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: '',
        message: 'Êtes-vous sur de vouloir quitter cette fiche ?',
        color: 'accent'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.router.navigate(['']);
      }
    });
  }

}
