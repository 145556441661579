<!--
> Génère et télécharge un fichier pdf
> Le devis pdf n’aura pas de numéro roulant (car non enregistré donc non généré)
> Ni le devis ni la demande ne sont enregistrées
-->
<div  *ngIf="!devisSaved">
    <button  style="margin-left: 20px;" color="primary"  [matTooltip]="'Afficher le devis'" [disabled]="!enabled" mat-flat-button (click)="generatepdf()">
        <mat-icon>print</mat-icon> Afficher
    </button>
</div>
<div class="col-sm-12 body-class" *ngIf="devisSaved">
    <button style="margin-left: 20px;" *ngIf="!isDevisSigned" color="primary" [matTooltip]="'Afficher le devis'" mat-flat-button (click)="openDoc('Devis')">
        <mat-icon>print</mat-icon> Afficher
    </button>
    <button style="margin-left: 20px;" *ngIf="isDevisSigned" color="primary" [matTooltip]="'Afficher le devis signé'"  mat-flat-button (click)="openDoc('Devis Signé')">
        <mat-icon>print</mat-icon> Afficher le devis signé
    </button>
    <button style="margin-left: 20px;" *ngIf="isTvaSigned" color="primary" mat-flat-button (click)="openDoc('TVA Signé')">
        <mat-icon>print</mat-icon> Afficher l'attestation TVA simplifié signé
    </button>
</div>
