import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ColumnService } from '../../column.service';

@Component({
  selector: 'order-column-menu',
  templateUrl: './order-column-menu.component.html',
  styleUrls: ['./order-column-menu.component.scss']
})
export class OrderColumnMenuComponent implements OnChanges  {
  
  @Input() displayedColumns: string[];
  @Input() columnType: string;

  @Output() displayedColumnsChange = new EventEmitter<string[]>();
  
  public hiddenColumns = [];
  public totalColumns = [];
  public libelleColumns = [];
  constructor(
    private columnService: ColumnService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.columnType){
      this.displayedColumns = Object.assign(this.displayedColumns, this.columnService.getUserColumnTech(this.columnType));
      this.displayedColumnsChange.emit(this.displayedColumns);
      this.libelleColumns = this.columnService.getUserColumn(this.columnType);
      this.totalColumns = this.columnService.getTotalColumn(this.columnType);
      this.hiddenColumns = this.totalColumns.filter(e => this.libelleColumns.indexOf(e) === -1)
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.columnService.setUserColumn(this.columnType, this.libelleColumns);
    this.displayedColumns = this.columnService.getUserColumnTech(this.columnType);
    this.displayedColumnsChange.emit(this.displayedColumns);
  }

  resetColumns() {
    this.libelleColumns = this.columnService.resetColumns(this.columnType);
    this.displayedColumns = this.columnService.getUserColumnTech(this.columnType);
    this.hiddenColumns = this.totalColumns.filter(e => this.libelleColumns.indexOf(e) === -1)
    this.displayedColumnsChange.emit(this.displayedColumns);
  }

}
