import { DemandeModel } from "../models/demande.model";
import { SiteGeoModel } from "../models/site-geo.model";
import { InterlocuteurModel } from "../models/interlocuteur.model";
import { EntrepriseModel } from "../models/tier/entreprise.model";
import { ParticulierModel } from "../models/tier/particulier.model";
import { TierModel } from "../models/tier/tier.model";
import { DevisModel } from "src/app/modules/devis/models/devis.model";

export class DevisJasonDTO {
    public demande: DemandeModel;
    public site: SiteGeoModel;
    public interlocuteur: InterlocuteurModel;
    public entrepriseDO: EntrepriseModel;
    public particulierDO: ParticulierModel;
    public tiersDO: TierModel;
    public entrepriseFacture: EntrepriseModel;
    public particulierFacture: ParticulierModel;
    public tiersFacture: TierModel;
    public codeActivite: string;
    public numContrat: string;
    public devis: DevisModel;
}