import { DescriptionActiviteModel } from "../core/shared/models/description-activite.model";
import { InterlocuteurModel } from "../core/shared/models/interlocuteur.model";
import { AgenceModel } from "../core/shared/models/rsa/agence.model";
import { SiteGeoModel } from "../core/shared/models/site-geo.model";
import { PrestationShared } from "./prestationShared";

export class DevisInfo {
    public agenceId: number;
    public ht: number;
    public ttc: number;
    public idRealisateur: number;
    public idActivite: number;
    public description: DescriptionActiviteModel = new DescriptionActiviteModel();
    public agence: AgenceModel;
    public agenceCreateur: AgenceModel;
    public interlocuteur: InterlocuteurModel;
    public site: SiteGeoModel = new SiteGeoModel();
    public prestations: PrestationShared[] = [];
    public prestationsMax: PrestationShared[] = [];
    public prestationsMin: PrestationShared[] = [];
    public zone : {libelle: string, majoration: number};
    public zoneMax : {libelle: string, majoration: number};
    public zoneMin : {libelle: string, majoration: number};
    public tva : number;
}