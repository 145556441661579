import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalConfirmationComponent } from 'src/app/utils/modals/modal-confirmation/modal-confirmation.component';
import { ActiviteService } from '../../services/prestation.service';
import { PrestationsDTO } from '../../shared/dtos/prestationDTO';
import { ActiviteModel } from '../../shared/models/prestation.model';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { RecordPrestationComponent } from './record-prestation/record-prestation.component';

@Component({
  selector: 'app-gestion-prestation',
  templateUrl: './gestion-prestation.component.html',
  styleUrls: ['./gestion-prestation.component.scss']
})
export class GestionPrestationComponent implements OnInit {

  prestations: ActiviteModel[] = [];
  displayedColumns: string[] = ['Libelle', 'commentaire', 'code', 'CodePraxedo', 'action'];
  dataSource = new MatTableDataSource<ActiviteModel>();
  length: number;
  pageSize = 50;
  sortState: Sort = {active: 'Libelle', direction: 'asc'};
  objet: SimpleQuery<string> = new SimpleQuery();
  pageEvent: PageEvent;
  agenceId: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private prestationService: ActiviteService,
    private IntercomToastrService: IntercomToastrService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.agenceId = params.id;
      this.objet.entry = '';
      this.objet.pageNumber = 0;
      this.objet.pageSize = this.pageSize;
      this.objet.order = 'Libelle';
      this.objet.descending = false;
      this.dataSource.paginator = this.paginator;
      this.loadPage();
    });
  }

  orderUsers(prestations: ActiviteModel[]): ActiviteModel[] {
    prestations = prestations.sort((a, b) => {
      if (a.libelle) {
        return a.libelle.localeCompare(b.libelle);
      } else {
        return -1;
      }
    });
    return prestations;
  }

  onCreate(): void {
    const dialogRef = this.dialog.open(RecordPrestationComponent, {
      data: {
        libelle: 'Ajout d\'une nouvelle prestation',
        prestation: new ActiviteModel(),
        agenceId: this.agenceId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
         this.loadPage();
       }
    });
  }


  onModify(prestation: ActiviteModel): void {
    if (prestation.agenceId != null) {
      const dialogRef = this.dialog.open(RecordPrestationComponent, {
        data: {
          libelle: 'Mise à jour de la prestation',
          prestation: prestation
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
           this.loadPage();
         }
      });
    }
  }

  onDelete(prestation: ActiviteModel) {
    const dialogConf = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: 'Suppression de la prestation : ' + prestation.libelle,
          message: 'Êtes-vous sûr de vouloir supprimer cette prestation ?'
        }
    });
    dialogConf.afterClosed().subscribe(res => {
      if (res) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.prestationService.delete(prestation.id).subscribe((prest: ActiviteModel) => {
          this.IntercomToastrService.success('La suppression de votre prestation : ' + prest.libelle + ' est un succès');
          this.loadPage();
          dialogRef.close();
        }, error => {
          this.IntercomToastrService.error('La suppression de votre prestation est un échec. Veuillez vérifier vos droits');
          dialogRef.close();
        });
      }
    });
  }

  openActivite(row: ActiviteModel) {
    this.router.navigateByUrl('/prestation-agence', { state: {idActivite: row.id, idAgence: this.agenceId, libelle: row.libelle }});
  }

  changePage(event?: PageEvent): void {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadPage();
  }

  search(): void {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  loadPage(): void {
    const prestationDTO = new PrestationsDTO();
    prestationDTO.query = this.objet;
    prestationDTO.agenceId = this.agenceId;
    this.prestationService.getFromSimpleQuery(prestationDTO).subscribe((query: SimpleQueryResponse<ActiviteModel>) => {
      if (query.objets !== null) {
        this.prestations = query.objets;
      } else {
        this.prestations = [];
      }
      this.length = query.count;
      this.dataSource = new MatTableDataSource<ActiviteModel>(this.prestations);
      this.paginator.length = this.length;
    }, error => {
      this.IntercomToastrService.error('Le chargement des prestations de l\'agence à échoué. Veuillez verifier vos droits.');
    });
  }

  onLeave() {
    this.router.navigate(['/parametres-agence']);
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.objet.order = sort.active;
    this.objet.descending = sort.direction === 'desc';
    this.loadPage();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
