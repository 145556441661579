import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import * as _ from "lodash";

@Component({
  selector: 'app-autocomplete-realisateur-multiple-selection',
  templateUrl: './autocomplete-realisateur-multiple-selection.component.html',
  styleUrls: ['./autocomplete-realisateur-multiple-selection.component.scss']
})
export class AutocompleteRealisateurMultipleSelection implements OnInit {
  @Input() title: string;
  @Input() selectedUserIds: number[];
  @Input() usersIdsArray;

  @Input() firstLoadId: any;
  _val: Subject<Set<number>> = new Subject();
  isLoaded = new BehaviorSubject<boolean>(false);
  @Input()
  set eventsSubjectUserArray(val: Subject<Set<number>>) {
    this._val = val;
  }
  
  @Output() updated = new EventEmitter<number[]>();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  userCtrl = new UntypedFormControl();
  filteredUsers: Observable<string[]>;


  inputUserArray = new Set();

// Input / Output
// toute les users
  selectableUsersString: string[] = [];
  allUsers: UserModel[] = [];
  selectedUserString: string[] = [];
  users : UserModel[] = [];
  
  eventsSubscription: Subscription;

  
  @ViewChild('userInput', { static: true }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(private userService: UserService) { }

   ngOnInit() {
    this.userService.getAll().subscribe(users => {
      this.users = users as UserModel[];
      this.isLoaded.next(true);
      this.loadUsers(this.users);
    });

    this.eventsSubscription = this._val.subscribe((x) => {
      this.inputUserArray = x;
      this.selectableUsersString  = [];
      this.allUsers = [];
      this.selectedUserString = [];
      this.loadUsers(this.users);
     });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectableUsersString  = [];
    this.allUsers = [];
    this.selectedUserString = [];
    this.isLoaded.pipe(filter(val => val)).subscribe(_ => 
      this.loadUsers(this.users)
    );
  }
  
    /* Charger les users  */
    loadUsers(users: UserModel[]) {
      if (users) {
        this.allUsers = users;
        this.usersIdsArray = [...this.inputUserArray];
        if(this.usersIdsArray.length != 0){
          this.usersIdsArray.forEach(idInput=>{
            if(users.find(user=>user.id ==idInput) != undefined){
              let userFound = users.find(user=>user.id ==idInput)
              if(!this.selectedUserString.find(libelle => libelle ==  this.userName(userFound))){
                this.selectedUserString.push( this.userName(userFound))
              }
            }
          })
          let tempUsers = Object.assign([],users);
          tempUsers = tempUsers.filter(user=>!this.usersIdsArray.includes(user.id))
      
          tempUsers.forEach(user => {
          if(!this.selectableUsersString.find(libelle => libelle ==  this.userName(user) )  ){
            this.selectableUsersString.push(this.userName(user))
          }})
        }else{
            users.forEach(user=>{
              if(!this.selectableUsersString.find(libelle => libelle ==  this.userName(user)) ){
                this.selectableUsersString.push(this.userName(user))
              }
            })
        }
        this.selectableUsersString.sort();
        this.selectedUserString.sort();
        this.filteredUsers = this.userCtrl.valueChanges.pipe(
          startWith(null),
          map((user: string | null) => user ? this._filter(user) : this.selectableUsersString.slice()));
          
      }
  }
  

  
  
  
  /* Supression d'une user*/
    remove(user: string): void {
      let id;
      const index = this.selectedUserString.indexOf(user);
      if (index >= 0) {
        this.selectableUsersString.push(this.selectedUserString[index]);
        this.selectableUsersString.sort();
        this.selectedUserString.splice(index, 1);
        this.selectedUserString.sort();
      }
  
      this.usersIdsArray.splice(this.usersIdsArray.indexOf(this.users.find(x=>  this.userName(x)  == user).id),1)
      this.updated.emit(this.usersIdsArray);

      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((myUser: string | null) => myUser ? this._filter(myUser) : this.selectableUsersString.slice()));
  
    }
    addElement(inputValue,inputIndex){
      const value = inputValue;
      const index = inputIndex;
      if ((value || '').trim() && this.users.find(x=>  this.userName(x)  == value) != undefined) {
        this.selectedUserString.push(value.trim());
        this.selectableUsersString.splice(index, 1);
        this.selectableUsersString.sort();
        this.selectedUserString.sort();
        this.usersIdsArray.push(this.users.find(x=>  this.userName(x) == value).id)
      }
      this.userCtrl.setValue(null);
      this.updated.emit(this.usersIdsArray);

      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
      map((user: string | null) => user ? this._filter(user) : this.selectableUsersString.slice()));
      this.updated.emit(this.usersIdsArray);
      this.userInput.nativeElement.blur();
      this.userInput.nativeElement.focus();
    }

  
    /* Ajout d'une user avec selection */
    selected(event: MatAutocompleteSelectedEvent): void {
      
      this.addElement(event.option.viewValue,this.selectableUsersString.indexOf(event.option.viewValue))
      this.userInput.nativeElement.value = '';
      this.userInput.nativeElement.blur();
    }
  

          /* Ajout d'une user avec Entrer */
    add(event: MatChipInputEvent): void {
      const input = event.input;
      this.addElement(event.value,this.selectableUsersString.indexOf(event.value))
      if (input) {
        input.value = '';
      }
    }

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return this.selectableUsersString.filter(user => user.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
    }
  
    userName(usr: UserModel) {
      return (usr.nom.toUpperCase() + " " + usr.prenom).trim();
    }
}
