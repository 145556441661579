import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { saveAs } from 'file-saver';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { AgenceService } from '../../../services/rsa/agence.service';
import { DescriptionActiviteService } from '../../../services/description-activite.service';
import { UserService } from '../../../services/user/user.service';

export interface DialogData {
  type:string;
  typeId: number;
  document: DocumentModel;
}

@Component({
  selector: 'app-modal-show-document',
  templateUrl: './modal-show-document.component.html',
  styleUrls: ['./modal-show-document.component.scss']
})

export class ModalShowDocumentComponent implements OnInit {

  constructor(public dialog: MatDialog,
              private toastr: IntercomToastrService,
              public router: Router,
              private agenceService: AgenceService,
              private descriptionActivite: DescriptionActiviteService,
              private userService: UserService,
              public dialogRef: MatDialogRef<Component>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit(): void {
  }

  onModalCancel(): void {
    this.dialogRef.close(false);
  }
// modify selon type ( agence || user)
  visualiseDoc(doc: DocumentModel): void {
    switch(this.data.type) { 
      case "Agence": { 
        this.agenceService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        }); 
        break; 
      } 
      case "Utilisateur": { 
        this.userService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        });  
         break; 
      } 
      case "Activite": { 
        this.descriptionActivite.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        });  
         break; 
      } 
    } 
    
  }

  onOpenDoc(doc: DocumentModel): void {
    switch(this.data.type) { 
      case "Agence": { 
        this.agenceService.getDocumentById(doc.id).subscribe((res: Blob) => {
          saveAs(res, doc.libelle);
        });
        break; 
      } 
      case "Utilisateur": { 
        this.userService.getDocumentById(doc.id).subscribe((res: Blob) => {
          saveAs(res, doc.libelle);
        });
         break; 
      } 
      case "Activite": { 
        this.descriptionActivite.getDocumentById(doc.id).subscribe((res: Blob) => {
          saveAs(res, doc.libelle);
        });
         break; 
      } 
    } 
  }

  onDeleteDoc(doc: DocumentModel): void {
    switch(this.data.type) { 
      case "Agence": {
        this.agenceService.deleteDocument(doc.id).subscribe(res => {
          if(res){
            this.data.document = null;
            this.toastr.success('Le fichier : ' + doc.libelle + ' à bien été supprimé.');
            this.dialogRef.close(res);
          }
        }); 
        break; 
      } 
      case "Utilisateur": { 
        this.userService.deleteDocument(doc.id).subscribe(res => {
          if(res){
            this.data.document = null;
            this.toastr.success('Le fichier : ' + doc.libelle + ' à bien été supprimé.');
            this.dialogRef.close(res);
          }
        });
         break; 
      } 
      case "Activite": { 
        this.descriptionActivite.deleteDocument(doc.id).subscribe(res => {
          if(res){
            this.data.document = null;
            this.toastr.success('Le fichier : ' + doc.libelle + ' à bien été supprimé.');
            this.dialogRef.close(res);
          }
        });
         break; 
      } 
    } 
  }
}
