import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DevisDTO } from 'src/app/modules/devis/DTOs/devisDTO';
import { ActiviteModel } from '../../../shared/models/prestation.model';
import { UserModel } from '../../../shared/models/users/user.model';
import { SimpleQuery } from '../../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../../shared/simple_query/simpleQueryResponse';

@Component({
  selector: 'app-array-devis',
  templateUrl: './array-devis.component.html',
  styleUrls: ['./array-devis.component.scss']
})
export class ArrayDevisComponent implements OnChanges {

  @Input() datasource: DevisDTO[];
  @Input() length: number;
  @Input() users: UserModel[] = [];
  @Input() activites: ActiviteModel[] = [];
  @Input() simpleQueryResponse = new SimpleQueryResponse<DevisDTO>();
  @Output() pageChange = new EventEmitter<SimpleQuery<DevisDTO>>();
  @Output() demandeOpen = new EventEmitter<number>();
  pageIndex = 0;
  pageSize = 50;
  pageEvent: PageEvent;
  sortState: Sort = {active: 'dCreation', direction: 'desc'};
  simpleQuery = new SimpleQuery<DevisDTO>();
  matDataSource: MatTableDataSource<DevisDTO>;
  displayedColumns: string[] = ['idActivites', 'code', 'Adresse', 'RaisonSociale', 'Objet', 'Createur', 'Realisateur', 'dCreation','dCloture', 'Statut', 'Tva', 'Ttc'];
  


  ngOnChanges(changes: SimpleChanges): void {
    const data = this.datasource;
    this.matDataSource = new MatTableDataSource(data);
    this.length = this.simpleQueryResponse.count;
    this.pageIndex = this.simpleQueryResponse.pageNumber;
  }

  getFirstActiviteLibelle(element: DevisDTO): string{
    if (element.idActivites) {
      const id = element.idActivites.split(',')[0];
      if (id && id !== '') {
        var activite = this.activites.find(activite => activite.id.toString() === id);
        if (activite){
          return activite.libelle;
        }
      }
    }
    return '';
  }

  openEvent(devisDTO: DevisDTO) {
    this.demandeOpen.emit(devisDTO.demandeId);
  }

  changePage(event?: PageEvent): PageEvent {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    this.simpleQuery.order = this.sortState.active;
    this.simpleQuery.descending = this.sortState.direction === 'desc';
    this.simpleQuery.pageNumber = event.pageIndex;
    this.simpleQuery.pageSize = event.pageSize;
    this.pageChange.emit(this.simpleQuery);
    return event;
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.simpleQuery.order = this.sortState.active;
    this.simpleQuery.descending = this.sortState.direction === 'desc';
    this.pageChange.emit(this.simpleQuery);
  }
}
