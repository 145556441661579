import { CommonModule } from '@angular/common'
import { CookieService } from 'ngx-cookie-service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ToastrModule } from 'ngx-toastr';
import { FullCalendarModule } from '@fullcalendar/angular';
import { LayoutModule } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { environment } from 'src/environments/environment';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { AngularFireModule } from '@angular/fire/compat';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { ModalConfirmationComponent } from './utils/modals/modal-confirmation/modal-confirmation.component';
import { WINDOW_PROVIDERS } from './window.provider';
import { AgendaComponent } from './modules/core/components/agenda/agenda.component';
import { AutocompleteUsersComponent } from './modules/core/components/divers/autocompletes/autocomplete-users/autocomplete-users.component';
import { CustomSnackBarComponent } from './modules/core/components/divers/custom-snack-bar/custom-snack-bar.component';
import { InputHeureComponent } from './modules/core/components/divers/input/input-heure/input-heure.component';
import { ModalSpinnerComponent } from './modules/core/components/divers/modals/modal-spinner/modal-spinner.component';
import { ModalAfficherDocumentComponent } from './modules/core/components/document/modal-afficher-document/modal-afficher-document.component';
import { ModalAjoutDocumentComponent } from './modules/core/components/document/modal-ajout-document/modal-ajout-document.component';
import { AfficherUserEtiquetteComponent } from './modules/core/components/etiquette/afficher-user-etiquette/afficher-user-etiquette.component';
import { GestionPrestationComponent } from './modules/core/components/gestion-prestation/gestion-prestation.component';
import { RecordPrestationComponent } from './modules/core/components/gestion-prestation/record-prestation/record-prestation.component';
import { SearchActionComponent } from './modules/core/components/menu/search/search-action/search-action.component';
import { SearchDemandeComponent } from './modules/core/components/menu/search/search-demande/search-demande.component';
import { SearchTiersComponent } from './modules/core/components/menu/search/search-tiers/search-tiers.component';
import { ParametreAgenceComponent } from './modules/core/components/parametre-agence/parametre-agence.component';
import { ParametreUtilisateurComponent } from './modules/core/components/parametre-utilisateur/parametre-utilisateur.component';
import { ArrayActionPrincipalComponent } from './modules/core/components/principal/type-array/array-action-principal/array-action-principal.component';
import { ArrayAllPrincipalComponent } from './modules/core/components/principal/type-array/array-all-principal/array-all-principal.component';
import { ArrayDemandePrincipalComponent } from './modules/core/components/principal/type-array/array-demande-principal/array-demande-principal.component';
import { ModalListeTiersComponent } from './modules/core/components/prise-appel/modal-liste-tiers/modal-liste-tiers.component';
import { PriseAppelComponent } from './modules/core/components/prise-appel/prise-appel.component';
import { PriseDappelTiersComponent } from './modules/core/components/prise-appel/prise-dappel-tiers/prise-dappel-tiers.component';
import { SaisieDemandeComponent } from './modules/core/components/prise-appel/saisie-demande/saisie-demande.component';
import { ProtocoleAgenceComponent } from './modules/core/components/protocole-agence/protocole-agence.component';
import { ModalAfficherProtocoleComponent } from './modules/core/components/protocole/modal-afficher-protocole/modal-afficher-protocole.component';
import { ModalAjoutProtocoleComponent } from './modules/core/components/protocole/modal-ajout-protocole/modal-ajout-protocole.component';
import { ProtocoleComponent } from './modules/core/components/protocole/protocole.component';
import { InformationTiersComponent } from './modules/core/components/tiers/information-tiers/information-tiers.component';
import { HostnameService } from './modules/core/services/basic-service/hostname.service';
import { DashboardService } from './modules/core/services/dashboard.service';
import { DocumentService } from './modules/core/services/document.service';
import { ParametreUtilisateurService } from './modules/core/services/parametre/parametre-utilisateur.service';
import { ProtocoleService } from './modules/core/services/protocole.service';
import { PrestationsDTO } from './modules/core/shared/dtos/prestationDTO';
import { ProtocoleDTO } from './modules/core/shared/dtos/protocoleDTO';
import { ProtocoleMock } from './modules/core/shared/mocks/protocole.mock';
import { ProtocoleModel } from './modules/core/shared/models/protocole.model';
import { CacheService } from './modules/shared/cache.service';
import { GmapsService } from './modules/shared/gapiServices/gmaps.service';
import { AuthentificationComponent } from './modules/core/components/authentification/authentification.component';
import { ClosDemandeComponent } from './modules/core/components/demande/clos-demande/clos-demande.component';
import { DemandeArrayComponent } from './modules/core/components/demande/demande-array/demande-array.component';
import { SelectPrestationComponent } from './modules/core/components/demande/select-prestation/select-prestation.component';
import { FormulaireComponent } from './modules/core/components/formulaire/formulaire.component';
import { ModalAfficherFormulaireComponent } from './modules/core/components/formulaire/modal-afficher-formulaire.component';
import { ModalAjoutFormulaireComponent } from './modules/core/components/formulaire/modal-ajout-formulaire.component';
import { FicheUtilisateurDroitComponent } from './modules/core/components/gestion-utilisateur/fiche-utilisateur-droit/fiche-utilisateur-droit.component';
import { GestionUtilisateurComponent } from './modules/core/components/gestion-utilisateur/gestion-utilisateur.component';
import { ModalFicheUtilisateurComponent } from './modules/core/components/gestion-utilisateur/modal-fiche-utilisateur/modal-fiche-utilisateur.component';
import { ModalSelectTier } from './modules/core/components/menu/modalSelectTier/modal-select-tier';
import { TypeDemandeComponent } from './modules/core/components/menu/type-demande/type-demande.component';
import { ModalDashboardListActionComponent } from './modules/core/components/principal/dashboard/dashboard-action/modal-dashboard-list-action.component';
import { ResumeTierComponent } from './modules/core/components/type-tier/tier/resume/resume.tier.component';
import { ApiService } from './modules/core/services/api.service';
import { FormulaireService } from './modules/core/services/formulaire.service';
import { MessagingService } from './modules/core/services/messaging.service';
import { TrieTabService } from './modules/core/services/trie-tab.service';
import { AssistantService } from './modules/core/services/user/assistant.service';
import { CommercialService } from './modules/core/services/user/commercial.service';
import { DemandeDTO } from './modules/core/shared/dtos/demandeDTO';
import { MatPaginatorIntlFr } from './modules/shared/frenchPaginatorIntl';
import { SessionService } from './modules/shared/session.service';
import { AuthGuard } from './auth.guard';
import { ActionComponent } from './modules/core/components/action/action.component';
import { ModalAjoutActionComponent } from './modules/core/components/action/modal-ajout-action.component';
import { ModalClosedActionComponent } from './modules/core/components/action/modal-closed-action.component';
import { ModalResumeActionComponent } from './modules/core/components/action/modal-resume-action.component';
import { DemandeComponent } from './modules/core/components/demande/demande.component';
import { ModalAfficherDemandeComponent } from './modules/core/components/demande/modal-afficher-demande.component';
import { ModalAjoutDemandeComponent } from './modules/core/components/demande/modal-ajout-demande.component';
import { AutocompleteTiersComponent } from './modules/core/components/divers/autocompletes/autocomplete-tiers/autocomplete-tiers.component';
import { ModalInfoOkComponent } from './modules/core/components/divers/modals/modal-info-ok.component';
import { EtudeComponent } from './modules/core/components/etude/etude.component';
import { FicheContactComponent } from './modules/core/components/fiche-contact/fiche-contact.component';
import { ModalEnregistrerSaisieComponent } from './modules/core/components/fiche-contact/modal-enregistrer-saisie.component';
import { FourOhFourComponent } from './modules/core/components/four-oh-four/four-oh-four.component';
import { ModalAfficherInterlocuteurComponent } from './modules/core/components/interlocuteurs/modal-afficher-interlocuteur.component';
import { ModalAjoutInterlocuteurComponent } from './modules/core/components/interlocuteurs/modal-ajout-interlocuteur.component';
import { MenuComponent } from './modules/core/components/menu/menu.component';
import { ModalPriseNoteComponent } from './modules/core/components/menu/modal-prise-note.component';
import { ModalSelectTypeTier } from './modules/core/components/menu/recherche/modal-select-type-tier';
import { ArrayActionComponent } from './modules/core/components/menu/type-action/array-action/array-action.component';
import { ParametreComponent } from './modules/core/components/parametre/parametre.component';
import { ModalDashboardListDemandeComponent } from './modules/core/components/principal/dashboard/dashboard-demande/modal-dashboard-list-demande.component';
import { PrincipalComponent } from './modules/core/components/principal/principal.component';
import { FicheTiersAppelComponent } from './modules/core/components/prise-appel/fiche-tiers.components/fiche-tiers-appel.component';
import { ModalAfficherSiteGeoComponent } from './modules/core/components/site-geographique/modal-afficher-site-geo.component';
import { ModalAjoutSiteGeoComponent } from './modules/core/components/site-geographique/modal-ajout-site-geo.component';
import { SuiviMapComponent } from './modules/core/components/suivi-map/suivi-map.component';
import { SuiviComponent } from './modules/core/components/suivi/suivi.component';
import { TiersComponent } from './modules/core/components/tiers/tiers.component';
import { EntrepriseArrayComponent } from './modules/core/components/type-tier/entreprise/array/entreprise.array.component';
import { EntrepriseComponent } from './modules/core/components/type-tier/entreprise/single/entreprise.component';
import { HlmArrayComponent } from './modules/core/components/type-tier/hlm/array/hlm.array.component';
import { HlmComponent } from './modules/core/components/type-tier/hlm/single/hlm.component';
import { MairieArrayComponent } from './modules/core/components/type-tier/mairie/array/mairie.array.component';
import { MairieComponent } from './modules/core/components/type-tier/mairie/single/mairie.component';
import { ParticulierArrayComponent } from './modules/core/components/type-tier/particulier/array/particulier.array.component';
import { ParticulierComponent } from './modules/core/components/type-tier/particulier/single/particulier.component';
import { SyndicArrayComponent } from './modules/core/components/type-tier/syndic/array/syndic.array.component';
import { SyndicComponent } from './modules/core/components/type-tier/syndic/single/syndic.component';
import { BasicTierComponent } from './modules/core/components/type-tier/tier/single/basic.tier.component';
import { ActionService } from './modules/core/services/action.service';
import { TierBasicService } from './modules/core/services/basic-service/tier-basic.service';
import { CiviliteService } from './modules/core/services/civilite.service';
import { DemandeService } from './modules/core/services/demande.service';
import { InterlocuteurService } from './modules/core/services/interlocuteur.service';
import { JwtHelper } from './modules/shared/jwtHelper';
import { NatureService } from './modules/core/services/nature.service';
import { ActiviteService } from './modules/core/services/prestation.service';
import { AgenceService } from './modules/core/services/rsa/agence.service';
import { RegionService } from './modules/core/services/rsa/region.service';
import { SiteGeoService } from './modules/core/services/site-geo.service';
import { EntrepriseService } from './modules/core/services/tier/entreprise.service';
import { HlmService } from './modules/core/services/tier/hlm.service';
import { MairieService } from './modules/core/services/tier/mairie.service';
import { ParticulierService } from './modules/core/services/tier/particulier.service';
import { SyndicService } from './modules/core/services/tier/syndic.service';
import { TierService } from './modules/core/services/tier/tier.service';
import { TypeActionService } from './modules/core/services/type-action.service';
import { TypeDemandeService } from './modules/core/services/type-demande.service';
import { DroitService } from './modules/core/services/user/droit.service';
import { UserService } from './modules/core/services/user/user.service';
import { EntrepriseDTO } from './modules/core/shared/dtos/entrepriseDTO';
import { HlmDTO } from './modules/core/shared/dtos/hlmDTO';
import { MairieDTO } from './modules/core/shared/dtos/mairieDTO';
import { ParticulierDTO } from './modules/core/shared/dtos/particulierDTO';
import { SyndicDTO } from './modules/core/shared/dtos/syndicDTO';
import { TierDTO } from './modules/core/shared/dtos/tierDTO';
import { ActionMock } from './modules/core/shared/mocks/action.mock';
import { BasicMock } from './modules/core/shared/mocks/basic-mock/basic.mock';
import { TierBasicMock } from './modules/core/shared/mocks/basic-mock/tier-basic.mock';
import { CiviliteMock } from './modules/core/shared/mocks/civilite.mock';
import { DemandeMock } from './modules/core/shared/mocks/demande.mock';
import { FormulairenMock } from './modules/core/shared/mocks/formulaire.mock';
import { InterlocuteurMock } from './modules/core/shared/mocks/interlocuteur.mock';
import { NatureMock } from './modules/core/shared/mocks/nature.mock';
import { PrestationMock } from './modules/core/shared/mocks/prestation.mock';
import { AgenceMock } from './modules/core/shared/mocks/rsa/agence.mock';
import { RegionMock } from './modules/core/shared/mocks/rsa/region.mock';
import { SocieteMock } from './modules/core/shared/mocks/rsa/societe.mock';
import { SiteGeoMock } from './modules/core/shared/mocks/site-geo.mock';
import { EntrepriseMock } from './modules/core/shared/mocks/tier/entreprise.mock';
import { MairieMock } from './modules/core/shared/mocks/tier/mairie.mock';
import { ParticulierMock } from './modules/core/shared/mocks/tier/particulier.mock';
import { TierMock } from './modules/core/shared/mocks/tier/tier.mock';
import { TypeActionMock } from './modules/core/shared/mocks/type-action.mock';
import { TypeDemandeMock } from './modules/core/shared/mocks/type-demande.mock';
import { AssistantMock } from './modules/core/shared/mocks/user/assistant.mock';
import { CommercialMock } from './modules/core/shared/mocks/user/commercial.mock';
import { UserMock } from './modules/core/shared/mocks/user/user.mock';
import { ModalsTierInfos } from './modules/core/shared/modals-menu-info';
import { DevisModule } from './modules/devis/devis.module';
import { PrestationModule } from './modules/prestation/prestation.module';
import { ModalsService } from './modules/shared/modals.service';
import { ThemeStorage } from './modules/shared/theme-storage.service';
import { TokenInterceptor } from './modules/shared/token-interceptor';
import { APP_DATE_FORMATS, CustomDateAdapter } from './modules/shared/custom-date-adapter';
import { ActiviteModel } from './modules/core/shared/models/prestation.model';
import { DevisRapideComponent } from './modules/core/components/devis-rapide/devis-rapide.component';
import { PageNoDroitComponent } from './utils/pages/page-no-droit/page-no-droit.component';
import { RecordActiviteComponent } from './modules/core/components/gestion-prestation/record-activite/record-activite.component';
import { ActiviteComponentService } from './modules/core/components/gestion-prestation/activite-component.service';
import { DescriptionActiviteService } from './modules/core/services/description-activite.service';
import { ModalAddDocumentComponent } from './modules/core/components/document/modal-add-document/modal-add-document.component';
import { SocieteService } from './modules/core/services/rsa/societe.service';
import { SharedModule } from './modules/shared/shared.module';
import { ModalShowDocumentComponent } from './modules/core/components/document/modal-show-document/modal-show-document.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SplashScreenComponent } from './splash-screen.component';
import { FicheTiersFactureComponent } from './modules/core/components/prise-appel/fiche-tiers-facture.components/fiche-tiers-facture.component';
import { SearchDevisComponent } from './modules/core/components/menu/search/search-devis/search-devis.component';
import { ArrayDevisComponent } from './modules/core/components/menu/array-devis/array-devis.component';
import { ModalEnvoiDevisCoreComponent } from './modules/core/components/divers/modals/modal-envoi-devis-core.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ModalEnvoyerJasonComponent } from './modules/core/components/demande/modal-envoyer-jason';
import { GestionZonesComponent } from './modules/core/components/gestion-zones/gestion-zones.component';
import { ZoneService } from './modules/core/services/zone.service';
import { ModalAjoutZoneComponent } from './modules/core/components/gestion-zones/modal-ajout-zone/modal-ajout-zone.component';
import { AutocompleteWithNumComponent } from './modules/core/components/divers/autocompletes/autocomplete-with-num/autocomplete-with-num.component';
import { CustomSelectMultipleOptionComponent } from './modules/core/components/divers/custom-select-multiple-option/custom-select-multiple-option.component';
import { ModalAjoutTiersComponent } from './modules/core/components/type-tier/modal-ajout-tiers/modal-ajout-tiers.component';
import { PriseAppelDemandeComponent } from './modules/core/components/demande/prise-appel-demande/prise-appel-demande.component';
import { ModalUpdateTiersComponent } from './modules/core/components/type-tier/modal-update-tiers/modal-update-tiers.component';
import { AutocompleteRealisateurMultipleSelection } from './modules/shared/components/autocomplete-realisateur-multiple-selection/autocomplete-realisateur-multiple-selection.component';
import { ModalSelectionTiersFactureComponent } from './modules/core/components/demande/modal-selection-tiers-facture.component';
import { ToastrLoadingService } from './modules/core/services/toastrLoading.service';
import { ModalAddFiltrePersonnaliseComponent } from './modules/core/components/principal/modal-add-filtre-personnalise/modal-add-filtre-personnalise.component';
import { FiltrePerimetreService } from './modules/core/services/filtre-perimetre.service';
import { IntercomToastrService } from './modules/shared/IntercomToastr.service';
import { PriseAppelUrgenceComponent } from './modules/core/components/prise-appel-urgence/prise-appel-urgence.component';
import { ModalAfficherDemandeUrgenceComponent } from './modules/core/components/demande/modal-afficher-demande-urgence.component';
import { CdkStepperModule, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CityDataService } from './modules/core/services/cityData.service';
import { datadogRum } from '@datadog/browser-rum';
import { GchatService } from './modules/shared/gapiServices/gChatApi.service';
import { MaintenanceComponent } from './modules/core/components/maintenance/maintenance.component';
import { ResizeDirective } from './utils/resize.directive';

// the second parameter 'fr-FR' is optional
registerLocaleData(localeFr, 'fr-FR');

  /**
   * to remove for clean Gapi
   */
  export class GenericErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        datadogRum.addError(error);
        console.error(error);
    }
}

@NgModule({
    declarations: [
        ActionComponent,
        AppComponent,
        SyndicComponent,
        SyndicArrayComponent,
        AutocompleteTiersComponent,
        ArrayActionComponent,
        BasicTierComponent,
        DemandeComponent,
        EntrepriseArrayComponent,
        EntrepriseComponent,
        MaintenanceComponent,
        EtudeComponent,
        FicheContactComponent,
        FourOhFourComponent,
        HlmArrayComponent,
        HlmComponent,
        FicheTiersFactureComponent,
        FicheTiersAppelComponent,
        MairieArrayComponent,
        MairieComponent,
        MenuComponent,
        ModalAfficherDemandeComponent,
        ModalAfficherInterlocuteurComponent,
        ModalAfficherSiteGeoComponent,
        ResizeDirective,
        ModalAjoutActionComponent,
        ModalAjoutDemandeComponent,
        ModalSelectTier,
        ModalAjoutInterlocuteurComponent,
        ModalAjoutSiteGeoComponent,
        ModalSelectTypeTier,
        ModalEnvoiDevisCoreComponent,
        ModalDashboardListDemandeComponent,
        ModalDashboardListActionComponent,
        ModalClosedActionComponent,
        ModalSelectTypeTier,
        ModalEnregistrerSaisieComponent,
        ModalPriseNoteComponent,
        ModalResumeActionComponent,
        ModalInfoOkComponent,
        ParametreComponent,
        ParticulierArrayComponent,
        ParticulierComponent,
        PrincipalComponent,
        ResumeTierComponent,
        AuthentificationComponent,
        SuiviComponent,
        SuiviMapComponent,
        TiersComponent,
        ClosDemandeComponent,
        DemandeArrayComponent,
        TypeDemandeComponent,
        GestionUtilisateurComponent,
        ModalFicheUtilisateurComponent,
        FicheUtilisateurDroitComponent,
        SelectPrestationComponent,
        FormulaireComponent,
        ModalAjoutFormulaireComponent,
        ModalAfficherFormulaireComponent,
        CustomSnackBarComponent,
        PriseAppelComponent,
        PriseDappelTiersComponent,
        SaisieDemandeComponent,
        ArrayActionPrincipalComponent,
        ArrayDemandePrincipalComponent,
        ArrayAllPrincipalComponent,
        AutocompleteUsersComponent,
        ParametreUtilisateurComponent,
        AfficherUserEtiquetteComponent,
        InformationTiersComponent,
        ModalAjoutDocumentComponent,
        ModalAddDocumentComponent,
        ModalShowDocumentComponent,
        ModalAfficherDocumentComponent,
        AgendaComponent,
        SearchTiersComponent,
        SearchDemandeComponent,
        SearchActionComponent,
        ProtocoleComponent,
        ModalAjoutProtocoleComponent,
        ModalAfficherProtocoleComponent,
        ModalListeTiersComponent,
        ModalSpinnerComponent,
        GestionPrestationComponent,
        RecordPrestationComponent,
        ParametreAgenceComponent,
        ProtocoleAgenceComponent,
        ModalConfirmationComponent,
        InputHeureComponent,
        DevisRapideComponent,
        PageNoDroitComponent,
        RecordActiviteComponent,
        SplashScreenComponent,
        SearchDevisComponent,
        ArrayDevisComponent,
        ModalEnvoyerJasonComponent,
        GestionZonesComponent,
        ModalAjoutZoneComponent,
        ArrayDevisComponent,
        AutocompleteWithNumComponent,
        CustomSelectMultipleOptionComponent,
        ModalAjoutTiersComponent,
        ModalUpdateTiersComponent,
        PriseAppelDemandeComponent,
        AutocompleteRealisateurMultipleSelection,
        ModalSelectionTiersFactureComponent,
        ModalAddFiltrePersonnaliseComponent,
        PriseAppelUrgenceComponent,
        ModalAfficherDemandeUrgenceComponent
    ],
    imports: [
        CdkStepperModule,
        MatStepperModule,
        PrestationModule,
        DevisModule,
        CommonModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        FullCalendarModule,
        HttpClientModule,
        LayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatCheckboxModule,
        AngularFireMessagingModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTreeModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatRadioModule,
        MatTooltipModule,
        HttpClientJsonpModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatChipsModule,
        OverlayModule,
        ReactiveFormsModule,
        DragDropModule,
        MatSnackBarModule,
        SharedModule,
        OAuthModule.forRoot(),
        ToastrModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
        { provide: "ModalsService", useClass: ModalsService },
        { provide: ModalsService, useClass: ModalsService },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "fr-FR" },
        { provide: ErrorHandler, useClass: GenericErrorHandler },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
        ActionMock,
        ActionService,
        AgenceMock,
        AgenceMock,
        AgenceService,
        AssistantMock,
        AuthGuard,
        BasicMock,
        CiviliteMock,
        CiviliteService,
        CommercialMock,
        FiltrePerimetreService,
        DemandeMock,
        DemandeService,
        ModalsService,
        EntrepriseMock,
        EntrepriseService,
        InterlocuteurMock,
        InterlocuteurService,
        GmapsService,
        RegionService,
        MairieMock,
        JwtHelper,
        MairieService,
        NatureMock,
        NatureService,
        ParticulierMock,
        ParticulierService,
        HostnameService,
        WINDOW_PROVIDERS,
        CacheService,
        HlmService,
        SyndicService,
        PrestationMock,
        IntercomToastrService,
        SessionService,
        DashboardService,
        ActiviteModel,
        ParticulierDTO,
        EntrepriseDTO,
        HlmDTO,
        SyndicDTO,
        MairieDTO,
        TierDTO,
        ModalsTierInfos,
        ActiviteService,
        RegionMock,
        SiteGeoMock,
        SiteGeoService,
        SocieteMock,
        ThemeStorage,
        TierBasicMock,
        ApiService,
        TierBasicService,
        TierMock,
        TierService,
        DroitService,
        TypeActionMock,
        TypeActionService,
        TypeDemandeMock,
        TypeDemandeService,
        UserMock,
        UserService,
        TrieTabService,
        DemandeDTO,
        AssistantService,
        CommercialService,
        FormulaireService,
        FormulairenMock,
        MessagingService,
        CookieService,
        ParametreUtilisateurService,
        DocumentService,
        ProtocoleService,
        ProtocoleMock,
        ProtocoleDTO,
        ProtocoleModel,
        DatePipe,
        PrestationsDTO,
        ActiviteComponentService,
        DescriptionActiviteService,
        SocieteService,
        ZoneService,
        ToastrLoadingService,
        CityDataService,
        GchatService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
