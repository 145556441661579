import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { InterlocuteurService } from './../../services/interlocuteur.service';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalAjoutInterlocuteurComponent } from './modal-ajout-interlocuteur.component';
import { Router } from '@angular/router';
import { ModalAfficherInterlocuteurComponent } from './modal-afficher-interlocuteur.component';
import { TrieTabService } from '../../services/trie-tab.service';
import { MatSort, Sort } from '@angular/material/sort';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TierModel } from '../../shared/models/tier/tier.model';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalInfoOkComponent } from '../divers/modals/modal-info-ok.component';
import { InterlocuteurPgResult } from '../../shared/dtos/interlocuteurPgResult';

export interface DialogData {

  result: boolean;
}

@Component({
  selector: 'app-interlocuteurs',
  templateUrl: './interlocuteurs.component.html',
  styleUrls: ["./interlocuteurs.component.scss"],
})
export class InterlocuteursComponent implements OnChanges {
  @Input() tier: TierModel;
  @Input() selectRow: boolean = false;
  @Input() simpleQueryResponse: SimpleQueryResponse<InterlocuteurPgResult>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() onUpdated = new EventEmitter<InterlocuteurModel[]>();
  @Output() onSelect = new EventEmitter<InterlocuteurModel>();
  displayedColumns: string[] = ['role', 'nom', 'prenom', 'telPrincipal', 'telSecondaire', 'email', 'actions'];
  dataSource = [];
  matDataSource: MatTableDataSource<InterlocuteurModel>;
  selectedInter: InterlocuteurModel;
  sortState: Sort = {active: 'nom', direction: 'asc'};
  length: number;
  result: boolean;
  pageIndex: number;
  pageSize = 10;
  constructor(private router: Router,
    private toastr: IntercomToastrService,
    public dialog: MatDialog,
    private interlocuteurService: InterlocuteurService,
    private comp: TrieTabService) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.tier !== undefined && this.tier.id !== undefined) {
      if(this.simpleQueryResponse){
        this.dataSource = this.simpleQueryResponse.objets;
        const data = this.dataSource.slice();
        this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
        this.length = this.simpleQueryResponse.count;
        this.pageIndex = this.simpleQueryResponse.pageNumber;
        this.pageSize = this.simpleQueryResponse.pageSize;
      }else{
        this.loadPage();
      }
    }
  }

  openEvent(contact: InterlocuteurModel) {
    this.onSelect.emit(contact);
  }

  onAddInter(): void {
    const dialogRef = this.dialog.open(ModalAjoutInterlocuteurComponent, {disableClose: true,
      width: '700px',
      data: {
        tierId: this.tier.id,
        tierIdTJason: this.tier.idTJason,
        tierEntreprise: this.tier.typeTier !== 'Particulier',
        tierIdentifiantJason: this.tier.identifiantJason,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.result = result;
      if (this.result) {
        this.result = result;
        if (this.result) {
          this.loadPage();
        }
      }
    });
  }

  onOpenInter(event: any, id: number): void {
    event.stopPropagation();
    this.interlocuteurService.get(id).subscribe((i: InterlocuteurModel) => {
      this.selectedInter = i;
      const dialogRef = this.dialog.open(ModalAfficherInterlocuteurComponent, {disableClose: true,
        data: {
          interloc: this.selectedInter,
          tierId: this.tier.id,
          tierIdTJason: this.tier.idTJason,
          tierEntreprise: this.tier.typeTier !== 'Particulier',
          tierIdentifiantJason: this.tier.identifiantJason,
          result: this.result
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.result = result;
        if (this.result) {
          this.loadPage();
        }
      });
    });
  }

  onDeleteInter(event: any, interlocuteur: InterlocuteurModel) {
    event.stopPropagation();
    const dialogConf = this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Suppression de l\'interlocuteur : ' + interlocuteur.nom + ' ' +  interlocuteur.prenom ,
          message: 'Êtes-vous sûr de vouloir supprimer cette interlocuteur ?',
          color: 'warn'
        }
    });
    dialogConf.afterClosed().subscribe(res => {
      if (res) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.interlocuteurService.delete(interlocuteur.id).subscribe((site: InterlocuteurModel) => {
          this.toastr.success('Suppression de l\'interlocuteur : ' +  interlocuteur.nom + ' ' +  interlocuteur.prenom + ' avec succès');
          this.loadPage();
          dialogRef.close();
        }, error => {
          this.toastr.error('Suppression de l\'interlocuteur en échec. Veuillez vérifier vos droits');
          dialogRef.close();
        });
      }
    });
  }

  changePage(event?: PageEvent) {
    this.pageIndex =  event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPage();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadPage();
  }

  loadPage() {
    const query = new SimpleQuery<InterlocuteurModel>();
    query.entry = null; 
    query.pageNumber = this.pageIndex;
    query.pageSize = this.pageSize;
    query.order = this.sortState.active.charAt(0).toUpperCase() + this.sortState.active.slice(1);
    query.descending = this.sortState.direction === 'desc' ? false : true;
    this.interlocuteurService.getFromTierQuery(this.tier.id, query).subscribe((response: SimpleQueryResponse<InterlocuteurModel>) => {
      this.dataSource = response.objets;
      const data = this.dataSource.slice();
      this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
      this.length = response.count;
      this.pageIndex = response.pageNumber;
      this.pageSize = response.pageSize;
    });
  }
}


