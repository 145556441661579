<mat-form-field class="full-width">
  <mat-label *ngIf="label == null">Raison sociale / Nom prénom</mat-label>
  <mat-label *ngIf="label != null">{{label}}</mat-label>
      <input
          matInput
          aria-label="Number"
          placeholder=""
          [formControl]="myControl"
          [matAutocomplete]="auto"
          [required]="required"
          (change)="onChange($event.target.value)">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getLibelle.bind(this)" (optionSelected)="selectedTiersChange($event)" [panelWidth]="'auto'">
      <mat-option *ngFor="let tiers of listTiers" [value]= "tiers.id"  [matTooltip]="getTiersDescription(tiers)"> {{ tiers.ville ? tiers.libelle + ' - ' + tiers.ville : tiers.libelle }}</mat-option>
  </mat-autocomplete>
</mat-form-field>