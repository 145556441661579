
  <div class="header-class">
    <div>
      <h3>Ajouter une {{data.action.typeActionId === 1001 ? "relance" : "tâche"}}</h3>
    </div>
    <div>
      <a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon">close</mat-icon></a>
    </div>
  </div>
    
  <mat-divider></mat-divider>
  <div class="modal-container">
  <div class="col-sm-12 body-class">
    <div [ngClass]="hasTiers ? 'col-sm-8 body-class' : 'col-sm-12 body-class' ">
      <div class="header-class"> 
        <h4>{{data.action.typeActionId === 1001 ? "Relance" : "Tâche"}} :</h4>
      </div>
      
      <div class="col-sm-12 data-class body-class">
        <div class="col-sm-12">
          <mat-form-field class="full-width" required>
            <mat-label>Objet</mat-label>
            <input required 
            matInput 
            placeholder="" value="" 
            (click)="objetAuto = false;"
            [(ngModel)]="data.action.objetAction" 
            name="intercom-objet-action" 
            style="font-size: large;"
            [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
  
        <div class="col-sm-4">
          <mat-form-field class="full-width">
            <mat-label>Type</mat-label>
            <mat-select [disabled]="data.action.typeActionId >= 1001" [(ngModel)]="data.action.typeActionId" (selectionChange)="typeChange()" required>
              <mat-option *ngFor="let typeA of typeActions.slice()" [value]="typeA.id">{{ typeA.libelle }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
              
  
        <div class="col-sm-4">
          <app-autocomplete-realisateur [required]="true" libelle="Réalisateur" [users]="users" [commercialId]="this.data.realisateurId" [user]="user" (onUpdated)="onUpdateRealisateurId($event)"> 
          </app-autocomplete-realisateur>
        </div>
  
        <div class="col-sm-4">
          <mat-form-field class="full-width">
            <mat-label>Statut</mat-label>
            <mat-select required [(ngModel)]="data.action.statut">
              <mat-option value="A traiter">A traiter</mat-option>
              <mat-option value="En cours">En cours</mat-option>
              <mat-option value="Annulé">Annulé</mat-option>
              <mat-option value="Clos">Clos</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div class="col-sm-4">
          <mat-form-field class="full-width" required>
            <mat-label>Date d'échéance </mat-label>
            <input matInput required [matDatepicker]="picker" [(ngModel)]="data.action.dateRealisation"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
  
        <div class="col-sm-4">
          <app-input-heure [libelle]="'Heure (00h00)'" [(value)]="data.action.heureDebut" [required]="false" (valueChange)="data.action.heureDebut"></app-input-heure>
        </div>
  
        <div class="col-sm-4">
          <app-input-heure [libelle]="'Durée (00h00)'" [(value)]="data.action.duree" [required]="false" (valueChange)="data.action.duree"></app-input-heure>
        </div>
  
        <div class="col-sm-12">
          <mat-form-field class="full-width">
            <mat-label>Commentaire</mat-label>
            <textarea matInput placeholder="" value="" [(ngModel)]="data.action.commentaire" rows="10"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  
    <div class="col-sm-4 element-right" *ngIf="hasTiers">
      <div class="header-class">
        <h4>Demande : </h4>
      </div>
  
      <div class="col-sm-12 data-class body-class mat-form-field-position-top">
        <mat-form-field appearance="fill" class="full-width"  *ngIf="data.action !== null && data.action !== undefined && data.tierId !== undefined">
          <mat-label>Demande</mat-label>
          <mat-select disabled="true" [(ngModel)]="data.action.demandeId" (selectionChange)="demandeChange()">
            <mat-option [value]="">Aucun</mat-option>
            <mat-option *ngFor="let dem of demandes.slice()" [value]="dem.id">{{ dem.objet }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  
    <!-- Contact -->
    <div class="col-sm-4 element-right" *ngIf="hasTiers" >
      <div class="header-class">
        <h4>Contact : </h4>
      </div>
      <div class="col-sm-12 data-class element-padding-top-10">
        <div>
          <app-autocomplete-interlocuteur 
          [interlocuteurs]="interlocuteurs"
          (selectedInterlocuteur)='onAddInterlocuteur($event)'
          [action]="data.action">
        </app-autocomplete-interlocuteur>
        </div>
      </div>
    </div>
  
  
  
  </div>
  
  <div class="col-sm-12 element-padding-top-10">
    <mat-divider></mat-divider>
  </div>
  
  <div class="text-center">
    <div class="col-sm-12 flex-container">
      <div class="col-sm-4 subDivContainer">
        <button mat-flat-button color="default"(click)="onModalCancel()">Annuler</button>
      </div>
      <div class="col-sm-4 subDivContainer">
        <button mat-flat-button color="success" (click)="onModalConfirm()">Enregistrer</button>
      </div>
    </div>
  </div>
</div>

