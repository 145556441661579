<mat-form-field appearance="fill" floatLabel="always" *ngIf="users !== undefined" class="full-width">
  <mat-label>{{libelle}}</mat-label>
    <input type= "text"
    matInput
    placeholder="Selectionner l'utilisateur .."
    [formControl]="myControl"
    [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption  #auto="matAutocomplete" (optionSelected)="intervenantChange($event)"  >
    <mat-option *ngFor="let usr of filteredRealisateur | async" [value]= "usr" [matTooltip]="usr"> {{usr}}</mat-option>
  </mat-autocomplete>

</mat-form-field>