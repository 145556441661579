<div class="table-inner-scroll">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="dCreation" matSortDirection="desc" matSortDisableClear >
  <caption></caption>
  <ng-container matColumnDef="idActivites">
      <th mat-header-cell *matHeaderCellDef> Activite </th>
      <td mat-cell *matCellDef="let element"> 
        <div>{{getFirstActiviteLibelle(element)}} </div>
      </td>  
    </ng-container>
    
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> N° </th>
      <td mat-cell *matCellDef="let element"> 
        <div> {{element.code}} </div> 
      </td>  
    </ng-container>
  
    <ng-container matColumnDef="RaisonSociale">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
      <td mat-cell *matCellDef="let element">
        <div> {{element.raisonSociale}} </div>
      </td>
    </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Adresse">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Adresse </th>
          <td mat-cell [title]="element.adresse?element.adresse:'' + ' ' + element.ville?element.ville:'' + ' ' + element.codePostal?element.codePostal:''" *matCellDef="let element"> {{(element.adresse?element.adresse:'' + " " + element.ville?element.ville:'' + " " + element.codePostal?element.codePostal:'') | truncate: 20}} </td>
        </ng-container>
  
    <!-- Position Column -->
    <ng-container matColumnDef="Objet">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Objet </th>
      <td mat-cell [title]="element.objet" *matCellDef="let element"> {{element.objet | truncate: 20}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="Createur">
      <th mat-header-cell *matHeaderCellDef> Créateur </th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let user of users">
          <div *ngIf="user.id === element.idCreateur">
            {{user.nom}} {{user.prenom}}
          </div>
        </div>   
      </td>
    </ng-container>

    <ng-container matColumnDef="Realisateur">
        <th mat-header-cell *matHeaderCellDef> Réalisateur </th>
        <td mat-cell *matCellDef="let element">{{element.nomRealisateur}} {{element.prenomRealisateur}} </td>
    </ng-container>

    <ng-container matColumnDef="dCreation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de création </th>
        <td mat-cell *matCellDef="let element"> {{element.dCreation | date:'dd/MM/yyyy HH:mm':'+0000'}} </td>
    </ng-container>

    <ng-container matColumnDef="dCloture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de clôture </th>
      <td mat-cell *matCellDef="let element"> {{element.dateCloture | date:'dd/MM/yyyy'}} </td>
  </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="Statut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Etat du devis </th>
      <td mat-cell *matCellDef="let element" style="width: 130px;">
        <strong  *ngIf="element.statut === 'À envoyer'" class="chip info-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Refusé'" class="chip info-red text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Transmis'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Validé'" class="chip info-green text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Envoyé à Jason'" class="chip info-green text-center"> {{element.statut}} </strong>
      </td>
    </ng-container>

    <ng-container matColumnDef="Tva">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TVA </th>
      <td mat-cell *matCellDef="let element"> {{element.tvaPercent}}%</td>
    </ng-container>

  <ng-container matColumnDef="Ttc">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> TTC </th>
    <td mat-cell *matCellDef="let element"> {{element.ttc}}€</td>
  </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openEvent(row)"  class="example-element-row"></tr>
  </table>
</div>

  <mat-paginator class="paginator" 
    [length]="length" 
    [pageIndex]="pageIndex" 
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]" 
    (page)="changePage($event)">
  </mat-paginator>
  
  
