
<h3>
    Génération de l'email
</h3>

<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="!this.data.devis.idDevis">
  <div class="col-sm-12 text-center" style="margin-top: 24px;" >
    <button mat-flat-button color="success" (click)="enregistrerSansEnvoi()">Enregistrer</button>
  </div>
</div>
<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="!this.data.devis.idDevis">
  <div class="col-sm-12 text-center" style="margin-top: 24px;">
    <button mat-flat-button color="primary" (click)="envoiSeul()">Enregistrer et Générer le mail avec le devis seul</button>
  </div>
</div>
<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="!this.data.devis.idDevis">
  <div class="col-sm-12 text-center"  style="margin-top: 24px;">
    <button mat-flat-button color="primary" (click)="envoiAttestation()">Enregistrer et Générer le mail avec attestation TVA simplifiée 10 %</button> 
  </div>
</div>
<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="!this.data.devis.idDevis">
  <div class="col-sm-12 text-center"  style="margin-top: 24px;">
    <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button> 
  </div>
</div>

<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="this.data.devis.idDevis">
  <div class="col-sm-12 text-center" style="margin-top: 24px;">
    <button mat-flat-button color="primary" (click)="envoiSeul()">Générer le mail avec le devis seul</button>
  </div>
</div>
<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="this.data.devis.idDevis">
  <div class="col-sm-12 text-center" style="margin-top: 24px;">
    <button mat-flat-button color="primary" (click)="envoiAttestation()">Générer le mail avec attestation TVA simplifiée 10 %</button> 
  </div>
</div>
<div class="col-sm-12 " style="display: flex; justify-content: space-between;" *ngIf="this.data.devis.idDevis">
  <div class="col-sm-12 text-center" style="margin-top: 24px;">
    <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button> 
  </div>
</div>

