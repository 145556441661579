import { TierDTO } from './tierDTO';
import { Injectable } from "@angular/core";
import { InterlocuteurDTO } from './interlocuteurDTO';
import { SiteGeoDTO } from './siteGeoDTO';

@Injectable()
export class filterTierDTO {
  public tiers : TierDTO = new TierDTO()
  public siteGeo : SiteGeoDTO= new SiteGeoDTO();
  public interlocuteur : InterlocuteurDTO = new InterlocuteurDTO();
  public pageNumber = 0;
  public pageSize = 10;
  public order = "Similarity";
  public descending : boolean = true;
}
