import { TierModel } from './tier.model';

export class MairieModel extends TierModel {
  public commune = '';
  public typeTier = 'Mairie';

  constructor(tiers?: TierModel) {
    super(tiers);
    if (tiers) {
      this.commune = tiers.libelle;
    }
  }
}

