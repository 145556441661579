import { GmapsService } from '../../../../shared/gapiServices/gmaps.service';
import { GmapsSiteGeoModel } from './../../../shared/models/gmapsSiteGeo.model';
import { BasicModel } from './../../../shared/models/basic-models/basic.model';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { UserModel } from './../../../shared/models/users/user.model';
import { SiteGeoService } from './../../../services/site-geo.service';
import { ActiviteService } from './../../../services/prestation.service';
import { Router } from '@angular/router';
import { ActiviteModel } from './../../../shared/models/prestation.model';
import { SiteGeoModel } from './../../../shared/models/site-geo.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { ProtocoleModel } from './../../../shared/models/protocole.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ProtocoleComponent } from './../protocole.component';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ProtocoleService } from '../../../services/protocole.service';
import { UserService } from '../../../services/user/user.service';
import { AutocompleteVilleComponent } from 'src/app/modules/shared/components/autocomplete-ville/autocomplete-ville.component';
import { AutocompleteCodePostalComponent } from 'src/app/modules/shared/components/autocomplete-code-postal/autocomplete-code-postal.component';
import { CityData } from '../../../shared/models/city-data.model';

export interface DialogData {
  protocole: ProtocoleModel;
  tierId: number;
  agenceId: number;
  realisateurId: number;
  result: boolean;
}

@Component({
  selector: 'app-modal-ajout-protocole',
  templateUrl: './modal-ajout-protocole.component.html',
  styleUrls: ['./../protocole.component.scss', './../modal-afficher-protocole/modal-afficher-protocole.component.scss']
})
export class ModalAjoutProtocoleComponent implements OnInit {

  @ViewChild(AutocompleteVilleComponent) autocVille: AutocompleteVilleComponent;
  @ViewChild(AutocompleteCodePostalComponent) autocCode: AutocompleteCodePostalComponent;

  users: UserModel[] = [];
  user: UserModel;
  realisateurId: number;
  siteModels: SiteGeoModel[] = [];
  siteModel: SiteGeoModel;
  prestations: ActiviteModel[] = [];
  prestation: ActiviteModel = new ActiviteModel();
  prestationIds: string;

  // prestation: PrestationModel;
  prestationCtrl = new UntypedFormControl();
  filteredPrestations: Observable<string[]>;
  adresseChanged = false;
  allPrestations: string[] = [];
  protocolesTier: ProtocoleModel[] = [];
  protocolesAgence: ProtocoleModel[] = [];

  realisateur: UserModel = null;
  gmapsAdresses: GmapsSiteGeoModel[] = [];
  adresseControl = new UntypedFormControl();
  codePostalControl = new UntypedFormControl();
  filteredAdresses: string[];
  adressesString: string[] = [];

  selectedCity: CityData = new CityData();

  // focus
  @ViewChild('input') input: ElementRef;
  constructor(public dialog: MatDialog
    , public dialogRef: MatDialogRef<ProtocoleComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private siteService: SiteGeoService
    , private userService: UserService
    , private gmapsService: GmapsService
    , private prestationService: ActiviteService
    , private siteGeoService: SiteGeoService
    , private toastr: IntercomToastrService
    , private router: Router
    , private protocoleService: ProtocoleService
    ) { }

    ngOnInit() {

      this.data.protocole.tierId = this.data.tierId;
      this.data.protocole.agenceId = this.data.agenceId;
      this.data.protocole.siteGeoId = null;
      this.data.protocole.prestationId = null;
      this.data.protocole.realisateurId = null;
      this.prestationIds = '';
      this.dialogRef.updateSize('90%', '80%');

      // Récupération des protocoles du tiers
      if (this.data.tierId) {
        this.protocoleService.getFromTier(this.data.tierId).subscribe((protocoles: ProtocoleModel[]) => {
          this.protocolesTier = protocoles;
        });
      }
      // Récupération des protocoles de l'agence
      if (this.data.agenceId) {
        this.protocoleService.getFromAgence(this.data.agenceId).subscribe((protocoles: ProtocoleModel[]) => {
          this.protocolesAgence = protocoles;
        });
      }

      // Récuperation des adresses du tiers
      if (this.data.tierId) {
        this.siteService.getFromTier(this.data.tierId).subscribe((site: SiteGeoModel[]) => {
          this.siteModels = site;
          if (site !== undefined && site.length > 0) {
            const siteF = this.siteModels.find(u => u.libelle === 'Siege');
          }
        });
      }
      // Récuperation des prestations
      this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
        this.prestations = prestas;
        for (let i = 0; i < prestas.length; i++) {
            this.allPrestations.push(prestas[i].libelle);
        }
      this.allPrestations.sort();

      this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
        startWith(null),
        map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
      });
      // Récuperation de tout les utilisateurs
      this.userService.getAll().subscribe((usrs: UserModel[]) => {
        this.user = usrs.find(usr => usr.id === this.data.protocole.realisateurId);
        this.users = usrs;
        this.realisateurId = this.data.protocole.realisateurId;
      });
      // Adresse de Google
      this.adresseControl.valueChanges.pipe(
        startWith(''),
        switchMap((adresse: string) => {
          let gmapsAdressesString = [];
          const filteredAdresse = adresse != null ? this._filterSite(adresse) : [];
            if (adresse.length > 1) {
              return this.gmapsService.getAutoCompleteVilleResults(adresse).pipe(map((gmapsAdresses: GmapsSiteGeoModel[]) => {
                this.gmapsAdresses = gmapsAdresses;
                for (const gmapsAdresse of gmapsAdresses) {
                  gmapsAdressesString.push(gmapsAdresse.ville);
                }
                return gmapsAdressesString;
              }));
            } else {
              return of(gmapsAdressesString);
            }
        })
      ).subscribe((gmapsAdresses: string[]) => {
        this.filteredAdresses = gmapsAdresses;
      });

    }



    onUpdateRealisateurId(realisateurId: number) {
      if (realisateurId === null) {
        this.data.protocole.realisateurId = null;
        this.realisateur = null;
      } else {
        this.data.protocole.realisateurId = realisateurId;
        this.realisateur = this.users.find(x => x.id === this.realisateurId);
      }
    }

    onAddAddress(address: SiteGeoModel) {
      this.data.protocole.siteGeo = address;
      if (address) {
        this.data.protocole.siteGeoId = address.id;
      } else {
        this.data.protocole.siteGeoId = null;
      }
    }

    siteChange() {
      if (this.data.protocole.siteGeoId !== null && this.data.protocole.siteGeoId !== undefined) {
        this.siteModel = Object.create(this.siteModels.find(site => site.id === this.data.protocole.siteGeoId));
        this.data.protocole.siteGeo = Object.create(this.siteModels.find(site => site.id === this.data.protocole.siteGeoId));
      } else {
        this.data.protocole.siteGeo = null;
      }
    }

  /* Ajout d'une prestation avec selection */
selected(event: MatAutocompleteSelectedEvent): void {
  const value = event.option.viewValue;
  const index = this.allPrestations.indexOf(value);

  this.prestation.libelle = value;
  this.prestation.id = this.prestations.find(pres => pres.libelle === value).id;

  this.data.protocole.prestationId = this.prestation.id;
  this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
    startWith(null),
    map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
}


/* Ajout d'une prestation avec Entrer */
add(value: string): void {
  const index = this.allPrestations.indexOf(value);

  this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
    let exist = false;
    for (let i = 0; i < prestas.length; i++) {
      if (value === prestas[i].libelle) {
            exist = true;
      }
    }
    // Add prestation
    if (exist) {
      for (let i = 0; i < prestas.length; i++) {
        if (value === prestas[i].libelle) {
          this.prestation = prestas[i];
          this.data.protocole.prestationId = this.prestation.id;
        }
      }
    }
  });

  this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
    startWith(null),
  map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
 // this.updated.emit(this.prestationIds);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allPrestations.filter(prestation => prestation.toLowerCase().indexOf(filterValue) >= 0);
  }

  deleteIfEmptyCP(): void {
    this.codePostalControl.setValue('');
    this.data.protocole.codePostal = '';
  }

  deleteIfEmptyVille(): void {
    this.adresseControl.setValue('');
    this.data.protocole.ville = '';
  }
    onModalCancel(): void {
      this.data.result = false;
      this.dialogRef.close(false);
    }

    onModalConfirm(): void {
      if (this.data.protocole) {
        if (this.data.protocole.siteGeo) {
          if (this.data.protocole.siteGeo.id) {
            this.saveProtocole();
          } else {
            this.saveAdress();
          }
        } else {
          this.saveProtocole();
        }
      }
   }

   saveAdress() {
    this.createAdresse(this.data.protocole.siteGeo).subscribe((adresse: SiteGeoModel) => {
      this.data.protocole.siteGeoId = adresse.id;
      this.saveProtocole();
    });
   }

   createAdresse(adresse: SiteGeoModel): Observable<BasicModel> {
    if (adresse.id) {
      return of(adresse);
    }

    this.data.protocole.siteGeo.tierId = this.data.protocole.tierId;
    const val = this.siteService.post(adresse);
    return val;
  }

   saveProtocole() {
    this.data.result = true;
    this.data.protocole.tierId = this.data.tierId;
    this.data.protocole.tier = null;
    this.data.protocole.siteGeo = null;
    this.data.protocole.prestation = null;
    this.data.protocole.realisateur = null;
    this.data.protocole.agence = null;
    if ( this.data.protocole.protocole !== '' && this.data.protocole.protocole ) {
      // tslint:disable-next-line: max-line-length
      if ((this.data.protocole.ville && !this.data.protocole.codePostal) || (!this.data.protocole.ville && this.data.protocole.codePostal)) {
        this.toastr.warning('Veuillez saisir la ville et le code postal', 'Impossible d\'enregistrer le protocole');
      } else {
        if (!this.protocoleExist(this.data.protocole)) {
          this.protocoleService.post(this.data.protocole).subscribe((a: ProtocoleModel) => {
            this.data.protocole = a;
            this.toastr.success('', 'Création du protocole réussie');
            this.dialogRef.close(true);
          });
        } else {
          this.toastr.warning('Le Protocole existe déja.', 'Impossible d\'enregistrer le protocole');
        }
      }
    } else {
      this.toastr.warning('Veuillez remplir les champs obligatoire', 'Impossible d\'enregistrer le protocole');
    }
   }


  protocoleExist(protocole: ProtocoleModel) {
     for (let i = 0; i < this.protocolesTier.length; i++) {
        if ((protocole.siteGeoId === this.protocolesTier[i].siteGeoId)
              && (protocole.prestationId === this.protocolesTier[i].prestationId)) {
          return true;
        }
     }
     for (let i = 0; i < this.protocolesAgence.length; i++) {
      if ((protocole.ville === this.protocolesAgence[i].ville)
      && (protocole.codePostal === this.protocolesAgence[i].codePostal)
      && (protocole.prestationId === this.protocolesAgence[i].prestationId)) {
        return true;
      }
    }
     return false;
  }

  /* selected event*/
  adresseChange(value: string) {
    if (value !== '') {
       if (this.gmapsAdresses.length !== 0) {
        let id = '';
        for (const gmapAdresse of this.gmapsAdresses) {
          if (gmapAdresse.ville === value) {
            id = gmapAdresse.placeId;
            break;
          }
        }
        this.gmapsService.getGeoCodeResults(id).subscribe((gmapAdresse: GmapsSiteGeoModel[]) => {
          if (gmapAdresse[0].codePostal) {
            this.data.protocole.codePostal = gmapAdresse[0].codePostal;
            this.codePostalControl.disable();
          } else {
            this.data.protocole.codePostal = '';
            this.codePostalControl.enable();
          }
        });
      }
    }
  }

  onCodePostalChange(selectedCity: CityData) {
    if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;

      this.data.protocole.codePostal = selectedCity.codesPostaux[0];
      this.data.protocole.ville = selectedCity.nom;
      this.codePostalControl.setValue(selectedCity.codesPostaux[0]);
      this.adresseControl.setValue(selectedCity.nom);
    } else if (this.selectedCity?.nom !== selectedCity.nom) {
      this.onCityChange(selectedCity);
    }
  }

  onCityChange(selectedCity: CityData) {
    if (this.selectedCity?.nom !== selectedCity.nom) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      
      this.data.protocole.codePostal = selectedCity.codesPostaux[0];
      this.data.protocole.ville = selectedCity.nom;
      this.codePostalControl.setValue(selectedCity.codesPostaux[0]);
      this.adresseControl.setValue(selectedCity.nom);
    } else if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.onCodePostalChange(selectedCity);
    }
  }

  private _filterSite(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.adressesString.filter(adresse => adresse.toLowerCase().indexOf(filterValue) >= 0);
  }
}
