import { BasicMock } from '../basic-mock/basic.mock';
import { UserModel } from '../../models/users/user.model';
import { Injectable } from "@angular/core";

@Injectable()
export class UserMock extends BasicMock  {
  constructor() {
    super();
    (<UserModel[]> this.objets) = [] /*[
      {code: 'cherassist.loss@veolia.com', id: 1, email: 'cherassist.loss@veolia.com', nom: 'loss', prenom: 'cherassist', telFixe: '01 39 43 87 20', telPortable: '07 39 43 88 20'},
      {code: 'leonide.tesle@veolia.com', id: 2, email: 'leonide.tesle@veolia.com', nom: 'tesle', prenom: 'leonide', telFixe: '01 39 43 87 21', telPortable: '07 39 43 88 21'},
      {code: 'gariel.loss@veolia.com', id: 2, email: 'gariel.loss@veolia.com', nom: 'loss', prenom: 'gariel', telFixe: '01 39 43 87 22', telPortable: '07 39 43 88 22'},
      {code: 'guillaume.pont@veolia.com', id: 3, email: 'guillaume.pont@veolia.com', nom: 'pont', prenom: 'guillaume', telFixe: '01 39 43 87 23', telPortable: '07 39 43 88 23'},
    ];*/
  }
}
