<mat-form-field class="full-width">
  <mat-label>{{ libelle }}</mat-label>
  <input matInput
    ngModel
    maxlength="5"
    pattern="^[0-9]{1,2}[h][0-9]{0,2}$"
    name="intercom-heure" 
    [ngModel]="value"
    (ngModelChange)="_heureChange($event)"
    (change)="change.emit()"
    name="intercom-heure"
    [ngModelOptions]="{ standalone: true }"
    >
</mat-form-field>
