import { HlmModel } from '../models/tier/hlm.model';

import { TierDTO } from './tierDTO';
import { Injectable } from "@angular/core";

@Injectable()
export class HlmDTO extends TierDTO {
  public raisonSociale: string;
  public siretSiren: string;
  public marqueEnseigne: string;
  public typeTier = 'Hlm';
}
