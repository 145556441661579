import { TablePrestationClientService } from './services/table-prestation-client.service';
import { TablePrestationChantierService } from './services/table-prestation-chantier.service';
import { CycleValidationService } from './services/cycle-validation.service';
import { DevisApiService } from './services/devis-api.service';
import { DevisBasicService } from './services/devis-basic.service';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { GoogleApiService } from 'src/app/modules/shared/gapiServices/googleApiService';
import { GoogleAuthService } from 'src/app/modules/shared/gapiServices/googleAuthService';
import { SessionService } from 'src/app/modules/shared/session.service';
import { DevisService } from './services/devis.service';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { TokenInterceptor } from 'src/app/modules/shared/token-interceptor';
import { APP_DATE_FORMATS, CustomDateAdapter } from '../shared/custom-date-adapter';
import { MatPaginatorIntlFr } from '../shared/frenchPaginatorIntl';
import { JwtHelper } from '../shared/jwtHelper';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { ModalValidationManuelleComponent } from './components/modal-validation-manuelle/modal-validation-manuelle.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ResumeDevisComponent } from './components/resume-devis/resume-devis.component';
import { PdfDevisComponent } from './components/pdf-devis/pdf-devis.component';
import { DevisComponent } from './components/devis-standard/devis.component';
import { DocumentService } from './services/document.service';
import { ModalRefusDevisComponent } from './components/modal-refus-devis/modal-refus-devis.component';
import { ResumeCycleDevisComponent } from './components/resume-cycle-devis/resume-cycle-devis.component';
import { ModalEnvoiDevisComponent } from './components/modal-envoi-devis/modal-envoi-devis.component';
import { ModalImportDevisComponent } from './components/modal-import-devis/modal-import-devis.component';
import { SharedModule } from '../shared/shared.module';
import { PdfService } from '../shared/pdf-service';
import { EstimationDevisComponent } from './components/estimation-devis/estimation-devis.component';
import { AddDevisRapideComponent } from './components/add-devis-rapide/add-devis-rapide.component';
import { PrestationModule } from '../prestation/prestation.module';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import { datadogRum } from '@datadog/browser-rum';

export class GenericErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        datadogRum.addError(error);
        console.error(error);
    }
}

@NgModule({
    declarations: [
        ModalValidationManuelleComponent,
        ResumeDevisComponent,
        PdfDevisComponent,
        DevisComponent,
        ModalRefusDevisComponent,
        ResumeCycleDevisComponent,
        ModalEnvoiDevisComponent,
        ModalImportDevisComponent,
        EstimationDevisComponent,
        AddDevisRapideComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PrestationModule,
        AppRoutingModule,
        MatSliderModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        FullCalendarModule,
        HttpClientModule,
        LayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatCheckboxModule,
        AngularFireMessagingModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTreeModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatRadioModule,
        MatTooltipModule,
        HttpClientJsonpModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatChipsModule,
        OverlayModule,
        ReactiveFormsModule,
        DragDropModule,
        MatSnackBarModule,
        MatTooltipModule
    ],
    exports: [
        ModalValidationManuelleComponent,
        ResumeDevisComponent,
        PdfDevisComponent,
        DevisComponent,
        ResumeCycleDevisComponent,
        EstimationDevisComponent,
        AddDevisRapideComponent
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
        { provide: 'ModalsService', useClass: ModalsService },
        { provide: ModalsService, useClass: ModalsService },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: ErrorHandler, useClass: GenericErrorHandler },
        SessionService,
        GoogleAuthService,
        SessionService,
        GoogleApiService,
        JwtHelper,
        DevisBasicService,
        DevisApiService,
        DevisService,
        DocumentService,
        CycleValidationService,
        TablePrestationClientService,
        TablePrestationChantierService,
        PdfService
    ]
})
export class DevisModule { }
