import { ModalInfoOkComponent } from './../divers/modals/modal-info-ok.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DemandeModel } from '../../shared/models/demande.model';
import { DevisDTO } from 'src/app/modules/devis/DTOs/devisDTO';
import { PriseAppelService } from '../../services/prise-appel.service';
import { ONGLETSPRISEAPPEL } from '../../shared/ongletsPriseAppel';

@Component({
  selector: 'app-prise-appel',
  templateUrl: './prise-appel.component.html',
  styleUrls: ['./prise-appel.component.scss']
})
export class PriseAppelComponent implements OnInit {

  selectedIndex = 0;
  devisDTO: DevisDTO;
  demande: DemandeModel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private priseAppelService : PriseAppelService) {
  }

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        if(Number(params.tabIndex) === ONGLETSPRISEAPPEL.DEVIS){
          this.selectedIndex = ONGLETSPRISEAPPEL.DEVIS;
          this.priseAppelService.currentDevis.subscribe(devis => {
            if(devis){
              this.devisDTO = devis;
            }
          });
        }
        else if(Number(params.tabIndex) === ONGLETSPRISEAPPEL.DEMANDE){
          this.selectedIndex = ONGLETSPRISEAPPEL.DEMANDE;
          //this.demande = this.priseAppelService.getDemande();
          this.priseAppelService.currentDemande.subscribe(demande => {
            if(demande){
              this.demande = demande;
              this.devisDTO = new DevisDTO();
              this.devisDTO.idDevis = -1;
              this.devisDTO.agenceId = this.demande.commercial.agenceId;Number
              this.devisDTO.tierId = this.demande.tierId;
              this.devisDTO.siteGeoId = this.demande.siteGeoId;
              this.devisDTO.interlocuteurId = this.demande.interlocuteurId;
              this.devisDTO.tierFactId = this.demande.tierFactureId;
              this.devisDTO.idRealisateur = this.demande.commercialId;
              this.devisDTO.commentaireDemande = this.demande.commentaire;
            }
          });
        }
        else if(Number(params.tabIndex) === ONGLETSPRISEAPPEL.DEMANDEURGENTE){
          this.selectedIndex = ONGLETSPRISEAPPEL.DEMANDEURGENTE;
          //this.demande = this.priseAppelService.getDemande();
          this.priseAppelService.currentDemande.subscribe(demande => {
            if(demande){
              this.demande = demande;
              this.devisDTO = new DevisDTO();
              this.devisDTO.idDevis = -1;
              this.devisDTO.agenceId = this.demande.commercial.agenceId;Number
              this.devisDTO.tierId = this.demande.tierId;
              this.devisDTO.siteGeoId = this.demande.siteGeoId;
              this.devisDTO.interlocuteurId = this.demande.interlocuteurId;
              this.devisDTO.tierFactId = this.demande.tierFactureId;
              this.devisDTO.idRealisateur = this.demande.commercialId;
              this.devisDTO.commentaireDemande = this.demande.commentaire;
            }
          });
          
        }   
      }
    );
  }

  onSubmit() {
  }

  
  onExit(): void {
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        message: 'Êtes-vous sur de vouloir quitter cette fiche ?',
        color: 'accent'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const r = result;
      if (result) {
        this.router.navigate(['']);
      }
    });
  }
 
}
