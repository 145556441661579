<h3>{{libelle}}</h3>

<mat-form-field class="full-width">
  <mat-label>Code Jason</mat-label>
  <input
    matInput
    class="text-large"
    placeholder=""
    value=""
    maxlength="30"
    [(ngModel)]="data.prestation.code"
    name="intercom-code-activite"
  />
</mat-form-field>

<mat-form-field class="full-width">
  <mat-label>Code Praxedo</mat-label>
  <input
    matInput
    class="text-large"
    placeholder=""
    value=""
    maxlength="25"
    [(ngModel)]="data.prestation.codePraxedo"
    name="intercom-code-praxedo-activite"
  />
</mat-form-field>

<mat-form-field class="full-width">
  <mat-label>Libellé</mat-label>
  <input required
    matInput
    class="text-large"
    placeholder=""
    value=""
    [(ngModel)]="data.prestation.libelle"
    name="intercom-libelle-activite"
  />
</mat-form-field>

<mat-form-field class="full-width">
  <mat-label>Commentaire</mat-label>
  <textarea matInput [(ngModel)]="data.prestation.commentaire" rows="20"></textarea>
</mat-form-field>

<div class="col-md-12 flex-container">
  <div class="subDivContainer">
    <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
  </div>
  <div class="subDivContainer">
    <button mat-flat-button color="success" class="button-white" (click)="onModalConfirm()">Enregistrer</button>
  </div>
</div>