<div>
<a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon">close</mat-icon></a>
  <div class="col-sm-8">
    <h3>Ajouter un document</h3>
  </div>
  <div class="col-sm-12">
    <mat-divider></mat-divider>
  </div>

  <div class="col-sm-12">
    <mat-card class="info">
      <mat-card-header>
        <mat-card-title>Consignes d'ajout d'un document</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>Saisir un libellé et un lien</li>
          <li>Le lien correspond à l'adresse web permettant d'accéder à votre document
            <div> Exemple 1 : https://docs.google.com/forms/d/XXXXXXXXXXX/edit</div>
            <div> Exemple 2 : https://docs.google.com/document/d/XXXXXXXXXXX</div>
          </li>
          <li>Pour autoriser l'accès à votre document, veuillez donner les droits aux personnes depuis Google </li>
        </ul>
      </mat-card-content>
      <mat-card-footer>   </mat-card-footer>
      <mat-card-actions>
      
      </mat-card-actions>
    </mat-card>
  </div>
  
  <div class="row">
    <div class="col-sm-6" style="margin-top: 15px;">
      <mat-form-field class="full-width" required>
        <mat-label>Libellé</mat-label>
        <input required 
        matInput 
        placeholder="" value="" 
        [(ngModel)]="data.document.libelle" 
        name="intercom-libelle-document" 
        style="font-size: large;"
        [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>

    <div class="col-sm-6" style="margin-top: 15px;">
        <mat-form-field class="full-width" required>
          <mat-label>Type</mat-label>
          <input required 
          disabled
          matInput 
          placeholder="" value="" 
          [(ngModel)]="data.document.type" 
          name="intercom-type-document" 
          style="font-size: large;"
          [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

  </div>

  <div class="row">
    <div class="col-sm-12">
        <mat-form-field class="full-width" required>
          <mat-label>Lien</mat-label>
          <input required 
          matInput 
          placeholder="" value="" 
          [(ngModel)]="data.document.url" 
          name="intercom-url-document" 
          style="font-size: large;"
          [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
  </div>
  
  <div class="row text-center flex-container">
    <div class="col-sm-6 subDivContainer">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>
    <div class="col-sm-6 subDivContainer">
      <button mat-flat-button color="success" (click)="onModalConfirm()">Ajouter</button>
    </div>
  </div>

</div>
  