<div>

<div class="test" style="text-align: center">
  <div class="col-sm-12">
    <p>Votre saisie a bien été enregistrée</p>
  </div>
  <div class="col-sm-12">
    <mat-divider></mat-divider>
  </div>
  <div class="col-sm-12" style="margin-top: 10px;">
    <button mat-button (click)="onModalCancel()">Ok</button>
  </div>
</div>
</div>