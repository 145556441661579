import { Injectable, Inject } from '@angular/core';
import { WINDOW } from 'src/app/window.provider';


@Injectable()
export class HostnameService {

    constructor(@Inject(WINDOW) private window: Window) {
    }

    getHostname(): string {
        return this.window.location.hostname;
    }

    getProtocole(): string {
        return this.window.location.protocol;
    }

    getUrl(): string {
        return this.window.location.protocol + '//' + this.window.location.hostname + '/';
    }
}
