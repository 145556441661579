<div class="col-md-12 body-class">
    <mat-card>
        <mat-card-content>
            <form [formGroup]="ajoutDemandeForm" novalidate>
                <div class="row"style="display:flex;">
                    <div class="col-sm-12 body-class" style="display:flex; justify-content: space-between;">
                        <h3 style="display:flex; align-items: start;">Prise d'appel demande</h3>
                        
                        <div class="col-sm-6">
                            <mat-form-field class="full-width">
                                <mat-label>Objet</mat-label>
                                <input (click)="objetAuto = false" matInput  required="false"
                                    placeholder="Objet" value="" formControlName="objet"
                                    name="intercom-objet-demande"  />
                            </mat-form-field>
                        </div>

                        <div>
                            <button mat-flat-button color="primary" type='button'
                                (click)="clearTrieAndSubmit()">
                                <mat-icon>search</mat-icon> Chercher un tiers
                            </button>
                        </div>
                        <div>
                            <button mat-flat-button [disabled]="!protocole || protocoleLoading" color="primary" (click)="onProtocolClicked()"
                                [matTooltip]="protocoleLoading ? 'Recherche de procole en cours' : protocole ? 'Un protocole a été trouvé' : 'Aucun protocole trouvé pour ces informations' " > 
                                <mat-icon>search</mat-icon> Protocole
                            </button>
                        </div>
                        <div>
                            <button mat-flat-button [color]="isSiteGeoSelectedNull || isInterlocSelectedNull ? 'accent' : 'success'"
                                [disabled]="selectedTiersDemande.id == undefined || !ajoutDemandeForm.valid"
                                (click)="createDemande()"> <mat-icon>add</mat-icon> Créer la demande 
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-md-12  body-class" style="padding:0px !important;">
                        <form [formGroup]="tierRechercheForm" novalidate 
                            style="display: flex; justify-content: space-between;align-items: center;" (submit)="onSubmitForm()">
                            <button type="submit" style="display:none">hidden submit</button>

                            <div class="col-md-4 data-class body-class" style="padding-top: 10px;">
                                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                                    <h4>Adresse</h4>
                                    <button type=button mat-flat-button
                                        (click)="clearForm('Adresse')">
                                        <mat-icon>settings_backup_restore</mat-icon> Vider la recherche
                                    </button>
                                </div>

                                    <div class="col-md-8">
                                        <mat-form-field class="full-width">
                                            <input #addresstext matInput placeholder="Adresse"
                                                formControlName="address" (keydown.enter)="$event.preventDefault();">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="full-width">
                                            <input matInput placeholder="Libelle" formControlName="libelle">
                                        </mat-form-field>
                                    </div>

                                    <div class="col-md-6">
                                        <app-autocomplete-code-postal 
                                            libelle="Code Postal" 
                                            (onUpdated)="onCodePostalChange($event)"
                                            [(selectedCity)]="selectedCity"
                                        >
                                        </app-autocomplete-code-postal>
                                    </div>

                                    <div class="col-md-6">
                                        <app-autocomplete-ville
                                            libelle="Ville"
                                            (onUpdated)="onCityChange($event)"
                                            [(selectedCity)]="selectedCity"
                                        >
                                        </app-autocomplete-ville>   
                                    </div>
                            </div>

                            <div class="col-md-4 data-class body-class"
                                style="padding-top: 10px; margin-left: 10px; margin-right: 10px;">
                                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                                    <h4>Contact</h4>

                                    <button type=button mat-flat-button
                                        (click)="clearForm('Interlocuteur')">
                                        <mat-icon>settings_backup_restore</mat-icon> Vider la recherche
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Nom Interlocuteur"
                                            formControlName="nomInterlocuteur">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Prénom Interlocuteur"
                                            formControlName="prenomInterlocuteur">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Téléphone Interlocuteur"
                                            formControlName="telInterlocuteur">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-4 data-class body-class"
                                style="padding-top: 10px;">
                                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                                    <h4>Tiers</h4>

                                    <button type=button mat-flat-button (click)="clearForm('Tiers')">
                                        <mat-icon>settings_backup_restore</mat-icon> Vider la recherche
                                    </button>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Raison sociale / Nom prénom" formControlName="raisonSocial">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <mat-form-field class="full-width">
                                        <input matInput placeholder="Id Jason" formControlName="idJason">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            
                <div class="row" style="display:flex;">
                    
                    <div class="col-md-4 data-class body-class">
                        <div class="col-md-12" style="display: flex;justify-content: space-between;">
                            <h4>Demande</h4>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="full-width" *ngIf="typeDemandes !== undefined">
                                <mat-label>Type</mat-label>
                                <mat-select required  value="" formControlName="type">
                                    <mat-option *ngFor="let typeD of typeDemandes.slice()"
                                        [value]="typeD.id">{{typeD.libelle}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field class="full-width">
                                <mat-label>Date d'échéance</mat-label>
                                <input required formControlName="dateEcheance" matInput [matDatepicker]="picker" >
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field class="full-width">
                                <mat-label>Statut </mat-label>
                                <mat-select required formControlName="statut">
                                    <mat-option value="A traiter">A traiter</mat-option>
                                    <mat-option value="En cours">En cours</mat-option>
                                    <mat-option value="Clos">Clos</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <div class="col-md-6">
                            <app-select-prestation [prestationIds]="prestationIds"
                                [prestationsList]=prestations
                                (updated)="onUpdatePrestationsId($event)">
                            </app-select-prestation>
                        </div>

                        <div class="col-md-6">
                            <app-autocomplete-realisateur [required]="true" libelle="Réalisateur"
                                [users]="users" [user]="user" [commercialId]="commercialId"
                                (onUpdated)="onUpdateRealisateurId($event)">
                            </app-autocomplete-realisateur>
                        </div>
                    </div>
                    <div class="col-md-4 data-class body-class"
                        style="margin-left: 10px; margin-right: 10px;">
                        
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <mat-label>Commentaire</mat-label>
                                <textarea matInput formControlName="commentaire"
                                style="height: 170px;"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-4 data-class"
                        style="display: flex; flex-direction: column; align-items: start; justify-content: space-between;">
                        <h3>Résumé de la demande : </h3>

                        <div class="col-sm-12 body-class" style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                            <h4 style="display: flex; white-space: nowrap;">Tiers<div style="color:rgb(230, 126, 29)"> * </div>: </h4>
                            <p style="text-align: end;"> {{selectedTiersDemande.libelle}}</p>
                        </div>

                        <div class="col-sm-12 body-class" style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                            <h4 style="white-space: nowrap;">Tiers Facturé : </h4>
                            <p style="text-align: end;" [matTooltip]="tierFacture.adresse"> {{ tierFacture.libelle}}
                                <button *ngIf="selectedTiersDemande.id" mat-mini-fab color="primary" 
                                    [matTooltip]="'Changer le tiers facturé'" 
                                    (click)="onEditTiersFacture()">
                                    <mat-icon style="color: white;"> edit </mat-icon>
                                </button> 
                            </p>
                        </div>
                        
                        <div class="col-sm-12 body-class"  style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                            <h4 style="white-space: nowrap;">Adresse : 
                                <button *ngIf="timeTravel != ''" mat-mini-fab color="primary" 
                                    [matTooltip]="timeTravel ? timeTravel : 'Afficher l\'itinéraire sur google map'" 
                                    (click)="openGMap()">
                                    <mat-icon style="color: white;"> pin_drop </mat-icon>
                                </button>
                            </h4> 
                            <p style="text-align: end;" *ngIf="!isSiteGeoSelectedNull">{{selectedSiteGeoDemande.libelle}}
                                {{selectedSiteGeoDemande.adresse}} {{selectedSiteGeoDemande.codePostal}}
                                {{selectedSiteGeoDemande.ville}} 
                            </p>

                        </div>
                        <div class="col-sm-12 body-class"  style="display: flex; flex-direction: row; justify-content: space-between;align-items: baseline;">
                            <h4 style="white-space: nowrap;">Contact : </h4>
                            <p style="text-align: end;" *ngIf="!isInterlocSelectedNull"> {{selectedInterlocDemande.nom}}
                                {{selectedInterlocDemande.prenom}} {{selectedInterlocDemande.telPrincipal}} 
                            </p>

                        </div>
                    </div>
                </div>

                <div class="tabResponsives" *ngIf="!isFormEmpty">
                    <app-basic-tier-array *ngIf="!IsWait" 
                        [tierFilter]="tiersFilter"
                        [tierRequired]="true"
                        [adresseRequired]="false"
                        [contactRequired]="false"
                        [origin]="'priseAppel'"
                        [pageSize]="10"
                        [simpleQueryResponse]="pgQueryResponse"
                        [openTier]="false"
                        [selectedTiersDuplique]="selectedTiersDuplique"
                        [selectedSiteGeo]="selectedSiteGeoDemande"
                        [selectedInterlocuteur]="selectedInterlocDemande"
                        (tierOpen)="onTierOpen($event)"
                        (pageChange)="pageChange($event)" 
                        (addDemandeOpen)='onAddDemandeOpen($event)'
                        (selectTiers)="selectedTiers($event)"
                        (selectAdresseTier)="selectedAdress($event)"
                        (selectInterlocTier)="selectedInterloc($event)">
                    </app-basic-tier-array>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>