import { TablePrestationChantierModel } from '../models/table-prestation-chantier.model';
import { TablePrestationClientModel } from '../models/table-prestation-client.model';
import { CycleValidationModel } from '../models/cycle-validation.model';
import { DevisBasicModel } from '../models/devis-basic.model';
import { DocumentModel } from '../../core/shared/models/document/document.model';
import { CiviliteModel } from '../../core/shared/models/civilite.model';

export class DevisDTO extends DevisBasicModel {
    public idDevis: number;
    public code: string = "XXXXXX";
    public tierId: number;
    public typeTiers: string;
    public idtJasonTiers: number;
    public siteGeoId: number;
    public tierFactId: number;
    public identifiantJasonTiers: string;
    public agenceId: number;
    public agenceLibelle: string;
    public agenceCode: string;
    public agenceEmail: string;
    public agenceTelephone: string;
    public agenceAdresse: string;
    public agenceCodePostal: string;
    public agenceVille: string;

    public agenceIdCrea: number;
    public agenceLibelleCrea: string;
    public agenceCodeCrea: string;

    public agenceIdReal: number;
    
    public demandeId: number;
    public dCreation: Date;
    public dModification: Date;
    public raisonSociale: string;
    public adresseChantier: string;
    public complementAdresseChantier: string;
    public codePostalChantier: string;
    public villeChantier: string;
    public commentaireChantier: string;
    public interlocuteurId: number;
    public roleInterlocuteur: string;
    public nomInterlocuteur: string;
    public prenomInterlocuteur: string;
    public emailInterlocuteur: string;
    public telInterlocuteur: string;
    public commentaireInterlocuteur: string;
    public telSecondaire: string;
    public idJasonInterlocuteur: number;
    public civilite: CiviliteModel;
    public civiliteId: number;
    public adresse: string;
    public codePostal: string;
    public ville: string;
    public dateCloture: Date;
    public objet: string;
    public statut: string = "À envoyer";
    public statutNum: number;
    public ht: number;
    public ttc: number;
    public tvaPercent: number;
    public tvaAmount: number;
    public nature: string;
    public idRealisateur: number;
    public nomRealisateur: string;
    public prenomRealisateur: string;
    public emailRealisateur: string;
    public telRealisateur: string;
    public chantier: boolean;
    public url: string;
    public idActivites: string;
    public libelleActivite: string;
    public libelleContratJason: string;
    public codeActiviteJason: string;
    public documents: DocumentModel[];
    public prestationsClient: TablePrestationClientModel[];
    public prestationsChantier: TablePrestationChantierModel;
    public cycleValidations: CycleValidationModel[];
    public sloganAgence: string;
    public siretAgence: string;
    public complementAdresseAgence:string;
    public libelleSociete:string;
    public adresseSociete: string;
    public complementAdresseSociete:string;
    public codePostalSociete: string;
    public villeSociete: string;
    public sirenSociete: string;
    public fonctionRealisateur: string;
    public introduction: string;
    public descriptionOuvrage: string;
    public modalite:string;
    public chargeClient: string;
    public nafAgence:string;
    public logoAgence: string;
    public rcs: string;
    public certification1: string;
    public certification2: string;
    public signature: string;
    public photo :string;
    public demandeClient: string;
    public destinataire: string;
    public zone: string;
    public majoration: number;

    public motif: string;
    public sendAttestationTva: boolean = false;
    public sendDevis: boolean;

    public dStart: Date;
    public dEnd: Date;
    
    public urlLogo: string = "";
    public urlCertification1: string = "";
    public urlCertification2: string = "";
    public urlActivite: string = "";

    public commentaireDemande = "";
}
