import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { AgenceService } from '../../../services/rsa/agence.service';
import { UserService } from '../../../services/user/user.service';
import { DescriptionActiviteService } from '../../../services/description-activite.service';
import { DemandeService } from '../../../services/demande.service';
import { TierService } from '../../../services/tier/tier.service';

export interface DialogData {
  type: string;
  typeId: number;
  document: DocumentModel;
}

@Component({
  selector: 'app-modal-add-document',
  templateUrl: './modal-add-document.component.html',
  styleUrls: ['./modal-add-document.component.scss'],
})

export class  ModalAddDocumentComponent implements OnInit {

  public form: UntypedFormGroup;
  filename: string;
  file: File;
  progress = 0;
  ext: string;
  libelleControl: UntypedFormControl;
  fileControl: UntypedFormControl;
  result = false;
  constructor(public dialog: MatDialog,
              private toastr: IntercomToastrService,
              public router: Router,
              private agenceService: AgenceService,
              private userService: UserService,
              private descriptionActiviteService: DescriptionActiviteService,
              private demandeService: DemandeService,
              private tierService: TierService,
              public dialogRef: MatDialogRef<Component>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private changeDetectorRef: ChangeDetectorRef) {
    this.libelleControl = new UntypedFormControl("", Validators.required);
    this.fileControl = new UntypedFormControl(null, [Validators.required, validatorFileSize(16000000)]);
    this.form = new UntypedFormGroup({
      file: this.fileControl,
      libelle: this.libelleControl
    });
  }
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
  }
  ngOnInit(): void {
    this.fileControl.valueChanges.subscribe((inputValue : File) => {
      if(inputValue){
        var name = inputValue.name.split('.');
        this.libelleControl.setValue(name.length > 1 ? name[0] : inputValue.name);
      }
    });
  }

  onModalCancel(): void {
    this.dialogRef.close(this.result);
  }

  submit(): void {
    if (this.form.valid) {
      this.file = this.fileControl.value;
      var length = this.file.name.split('.').length;
      this.libelleControl.setValue(this.libelleControl.value.replace(/[\W_]+/g, "-"));
      const extension = length > 1 ? this.file.name.split('.')[length - 1].toLowerCase() : null;
      this.filename = extension ? this.libelleControl.value + '.' + extension : this.libelleControl.value;
      let x;
      switch(this.data.type) { 
        case "Agence": { 
          this.agenceService.sendDocument(this.filename, this.data.typeId, this.file).subscribe((res) => {
            if (res) {
              this.toastr.success('Le fichier : ' + this.filename + ' à bien été enregistré.');
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close(res);
            } else { 
              if(res !== 0){
                this.form.enable();
                this.toastr.warning('Le fichier : ' + this.filename + ' éxiste déjà pour cette agence');  
              }
            }
          });
          break; 
        } 
        case "Utilisateur": { 
          this.userService.sendDocument(this.filename, this.data.typeId, this.file).subscribe((res) => {
            if (res) {
              this.toastr.success('Le fichier : ' + this.filename + ' à bien été enregistré.');
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close(res);
            } else { 
              if(res !== 0){
                this.form.enable();
                this.toastr.warning('Le fichier : ' + this.filename + ' éxiste déjà pour cette agence');
              }
            }
          });

           break; 
        } 
        case "Activite": {
          this.descriptionActiviteService.sendDocument(this.filename, this.data.typeId, this.file).subscribe((res) => {
            if (res) {
              this.toastr.success('Le fichier : ' + this.filename + ' à bien été enregistré.');
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close(res);
            } else{ 
              if(res !== 0){
                this.form.enable();
                this.toastr.warning('Le fichier : ' + this.filename + ' éxiste déjà pour cette agence');
              }
            }
          });
           break; 
        }
        case "DemandeS3": {
          this.demandeService.sendDocument(this.filename, this.data.typeId, this.file).subscribe((res) => {
            if (res) {
              this.toastr.success('Le fichier : ' + this.filename + ' à bien été enregistré.');
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close(res);
            } else if(res !== 0){
              this.form.enable();
              this.toastr.warning('Le fichier : ' + this.filename + ' éxiste déjà pour cette demande');
            }
          });
           break; 
        }
        case "TiersS3": {
          this.tierService.sendDocument(this.filename, this.data.typeId, this.file).subscribe((res) => {
            if (res) {
              this.toastr.success('Le fichier : ' + this.filename + ' à bien été enregistré.');
              this.changeDetectorRef.detectChanges();
              this.dialogRef.close(res);
            } else if(res !== 0){
              this.form.enable();
              this.toastr.warning('Le fichier : ' + this.filename + ' éxiste déjà pour cette demande');
            }
          });
           break; 
        } 
      } 
      
    }
  }
}

export function validatorFileSize(size: number): any {
  return (control: UntypedFormControl) => {
    const file = control.value;
    if (file) {
      const sizeFile = (file as File).size;
      if (sizeFile > size) {
        return {
          validatorFileSize: true
        };
      }
      return null;
    }
    return null;
  };
}