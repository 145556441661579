import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
export interface DialogData {

  result: boolean;
}

@Component({
  selector: 'app-modal-enregistrer-saisie',
  templateUrl: './modal-enregistrer-saisie.component.html'
})
export class ModalEnregistrerSaisieComponent implements OnInit {

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, ) { }

  ngOnInit() {
    this.dialogRef.updateSize('80%', '70%');
  }
  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
    this.data.result = true;
    this.dialogRef.close(true);
  }


}
