import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

@Injectable()
export class JwtHelper {
  static jwtHelperService = new JwtHelperService();

  static decode(jwtToken: string) {
    return this.jwtHelperService.decodeToken(jwtToken);
  }

  static expiresInLessThan(jwtToken: string, milliseconds: number): boolean {
    const decodedJwtToken = this.jwtHelperService.decodeToken(jwtToken);
    // in JS timestamps are in milliseconds, while UNIX timestamps used in JWT tokens are in seconds
    const tokenExpiresAt = new Date(decodedJwtToken.exp * 1000);
    const now = new Date();
    return (tokenExpiresAt.getTime() - now.getTime()) < milliseconds;
  }
}
