import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CiviliteService } from 'src/app/modules/core/services/civilite.service';
import { CiviliteModel } from 'src/app/modules/core/shared/models/civilite.model';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { InterlocuteurModel } from 'src/app/modules/core/shared/models/interlocuteur.model';
import { ModalSelectContactComponent } from '../modals/modal-select-contact/modal-select-contact.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html'
})
export class ContactCardComponent implements OnChanges {

  @Input() demande: DemandeModel;
  @Input() modifiable: boolean;
  @Input() interlocuteurs: InterlocuteurModel[];
  @Output() selectedInterlocuteur = new EventEmitter<InterlocuteurModel>();
  interlocuteurControl = new UntypedFormControl();
  civiliteControl = new UntypedFormControl();
  interlocuteursString: string[] = [];
  civilitesString: string[] = [];
  filteredInterlocuteur: Observable<string[]>;
  filteredCivilite: Observable<string[]>;
  civilites: CiviliteModel[] = [];
  civiliteDemande = '';
  interlocuteurDemande = '';
  fieldStatus = false;
  interlocuteur: InterlocuteurModel = new InterlocuteurModel();

  constructor(private civiliteService: CiviliteService,
    public dialog: MatDialog,
    private cdRef:ChangeDetectorRef,
    ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
      this.civilites.forEach(element => {
        this.civilitesString.push(element.libelle);
      });
      this.filteredCivilite = this.civiliteControl.valueChanges.pipe(
        startWith(''),
        map((civilite: string) => this._filter(civilite, this.civilitesString)));

        if (this.demande && !(Object.keys(changes).length == 1 && Object.keys(changes)[0] == 'demande')) {
          this.initInterlocuteur(this.demande);
        }
        this.cdRef.detectChanges();
    }); 
  }

  editContact(){
    const dialogRef = this.dialog.open(ModalSelectContactComponent, {
      disableClose: false,
      width: '1200px',
      data: {
        tier: this.demande.tier,
      },
      panelClass: 'mat-dial-no-padd'
    });
    dialogRef.afterClosed().subscribe(contact => {
      if(contact){
        this.interlocuteur = contact;
        this.interlocuteur.civilite = this.civilites.find(c => c.id === contact.civiliteId);
        this.selectedInterlocuteur.emit(this.interlocuteur);
      }
      
    });
  }

  initInterlocuteur(objet: DemandeModel) {
    if (objet && objet.nomInterlocuteur) {
      this.interlocuteur = new InterlocuteurModel();
      this.interlocuteur.id = objet.interlocuteurId;
      this.interlocuteur.nom = objet.nomInterlocuteur;
      this.interlocuteur.prenom = objet.prenomInterlocuteur;
      this.interlocuteur.role = objet.roleInterlocuteur;
      this.interlocuteur.email = objet.emailInterlocuteur;
      this.interlocuteur.telPrincipal = objet.telPrincipalInterlocuteur;
      this.interlocuteur.telSecondaire = objet.telSecondaireInterlocuteur;
      this.interlocuteur.commentaire = objet.commentaireInterlocuteur;
      this.interlocuteur.civilite = this.civilites.find(c => c.libelle === objet.civiliteInterlocuteur);
      this.interlocuteur.idJason = objet.idJasonInterlocuteur;
      this.interlocuteurDemande = objet.nomInterlocuteur && objet.prenomInterlocuteur ? objet.civiliteInterlocuteur ?
        objet.civiliteInterlocuteur + ' ' + objet.nomInterlocuteur + ' ' + objet.prenomInterlocuteur :
        objet.nomInterlocuteur + ' ' + objet.prenomInterlocuteur :
        objet.nomInterlocuteur;
        
      if(this.interlocuteur.civilite) {
        this.interlocuteur.civiliteId = this.interlocuteur.civilite.id;
      }
      this.civiliteDemande = objet.civiliteInterlocuteur;
      this.civiliteControl.setValue(objet.civiliteInterlocuteur);
      this.selectedInterlocuteur.emit(this.interlocuteur);
    }
    
  }

  private _filter(value: string, objets: string[]): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return objets.filter(objet => objet.toLowerCase().indexOf(filterValue) >= 0);
    } else {
      return objets;
    }
  }
}

