import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BasicService } from './basic-service/basic.service';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { Observable } from 'rxjs';
import { SessionService } from '../../shared/session.service';
import { ZoneInterventionModel } from '../shared/models/zone-intervention.model';
import { SimpleQueryUser } from '../shared/simple_query/simpleQueryUser';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable()
export class ZoneService extends BasicService {
  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
    super(http, apiService, sessionService);
    this.serviceUrl = apiService.ROUTEZONE;
  }

  getFromSimpleQuery(objet: SimpleQueryUser, agenceId: number): Observable<SimpleQueryResponse<ZoneInterventionModel>> {
    let param = new HttpParams();
    param = param.append('agenceId', agenceId ? agenceId.toString() : null);
    // tslint:disable-next-line: max-line-length
    return this.http.post<SimpleQueryResponse<ZoneInterventionModel>>(this.apiUrl + this.serviceUrl + 'GetFromSimpleQuery/', objet, {params: param});
  }
  
  getFromAgence(agenceId: number): Observable<ZoneInterventionModel[]> {
    return this.http.get<ZoneInterventionModel[]>(this.apiUrl + this.serviceUrl + 'GetFromAgence/' +
    agenceId, {headers: this.header}).pipe(map((zones: ZoneInterventionModel[]) => zones));
  }

  /*getFromAgenceAndCP(agenceId: number,  cp: string): Observable<ZoneInterventionModel> {
    let param = new HttpParams();
    param = param.append('agenceId', agenceId ? agenceId.toString() : null);
    param = param.append('cp', cp ? cp.toString() : null);
    return this.http.get<ZoneInterventionModel>(this.apiUrl + this.serviceUrl + 'GetFromAgenceAndCP/', {params: param}).pipe(map((zones: ZoneInterventionModel[]) => zones));
  }*/

  // script start when the user import zones
  import(Sheeturl: string): Observable<any> {
    if (Sheeturl.includes('edit')) {
      Sheeturl = Sheeturl.split('edit')[0] + 'edit';
    }
    const url = environment.URL_WEBAPP_ZONES;
    const params = '?url=' + Sheeturl;
    const res = this.http.jsonp(url + params, 'callback');
    return res;
  }

  postAll(objets: ZoneInterventionModel[]): Observable<ZoneInterventionModel[]> {
    return this.http.post<ZoneInterventionModel[]>(this.apiUrl + this.serviceUrl + 'PostAll/', objets);
  }

  deleteAll(objets: ZoneInterventionModel[]): Observable<ZoneInterventionModel[]> {
    return this.http.post<ZoneInterventionModel[]>(this.apiUrl + this.serviceUrl + 'DeleteAll/', objets);
  }
}