<div class="col-md-12">
    <div class="row"> 
        <div class="col-7">
            <h3 >Ajout d'un nouveau tiers</h3> 
        </div>
    
      <div class="col-7">
        <mat-divider></mat-divider>
      </div>
    </div>

 
      
    <div class="row">
        <div class="col-md-2">
            <mat-form-field  class="full-width">
                <mat-label>Type de tiers</mat-label>
                <mat-select [required]="true" [(ngModel)]="result" (selectionChange)="onSelectTiersChange($event)">
                  <mat-option *ngFor="let type of typeTiers" [value]="type">
                    {{type}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-md-10">
        <div *ngIf="result == 'Particulier'">
            <app-particulier (tierChange)="onTierTypeChange($event,'Particulier')" [tier]="tiers" [disabled]="false"></app-particulier>
          </div>
          
          <div *ngIf="result == 'Entreprise'">
            <app-entreprise (tierChange)="onTierTypeChange($event, 'Entreprise')" [tier]="tiers" [disabled]="false"></app-entreprise>
          </div>
          
          <div *ngIf="result == 'Mairie'">
            <app-mairie (tierChange)="onTierTypeChange($event,'Mairie')" [tier]="tiers" [disabled]="false"></app-mairie>
          </div>
          
          <div *ngIf="result == 'Syndic'">
            <app-syndic (tierChange)="onTierTypeChange($event,'Syndic')" [tier]="tiers" [disabled]="false"></app-syndic>
          </div>
          
          <div *ngIf="result == 'Hlm'">
            <app-hlm (tierChange)="onTierTypeChange($event,'Hlm')" [tier]="tiers" [disabled]="false"></app-hlm>
          </div>
        </div> 
    </div>


    <div class="row" *ngIf="typeSelected">
        <div class="col-md-3">
            <app-input-telephone
            libelle="Téléphone"
            (isValid)="telValid=$event"
            [required]="telRequired"
            [phoneNumber]="telephoneInput"
            (phoneNumberChange)="onphoneNumberChange($event)"
            [childFieldStatus]="false"
            ></app-input-telephone>
        </div>
        <div class="col-md-3">
            <mat-form-field class="full-width">
                <mat-label>Mail</mat-label>
                <input matInput placeholder="" [required]="emailRequired" (ngModelChange)="telRequired = !$event" [(ngModel)]="emailInput">
            </mat-form-field>
        </div>


        <div class="col-md-2">
            <mat-form-field class="full-width">
                <mat-label>TVA</mat-label>
                <mat-select (selectionChange)="onTvaChange($event)" [ngModel]="tvaInput" [disabled]="false">
                    <mat-option [value]="null"> Indéfini </mat-option>
                    <mat-option value="20.00"> 20% </mat-option>
                    <mat-option value="10.00"> 10% (attestation)</mat-option>
                    <mat-option value="5.50"> 5,50% (attestation)</mat-option>
                    <mat-option value="0.00"> 0% (attestation)</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field class="full-width">
                <mat-label>Identifiant jason</mat-label>
                <input matInput placeholder="" maxlength="255" name="intercom-identifiantJ"
                    [(ngModel)]="indentifiantJasonInput" [disabled]="true">
            </mat-form-field>
        </div>

    </div>
    <div class="row" *ngIf="typeSelected">
        <form [formGroup]="tierCreateForm" novalidate>
            <div class="col-md-12">
                <div class="col-md-6" style="border-right: 3px inset #333;">
                    <div class="row">
                        <div class="col-md-12" style="display: flex;justify-content: space-between;">
                            <h3>Adresse Principale</h3>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <input #addresstext matInput placeholder="Adresse Principale" formControlName="address">
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Ville" formControlName="ville">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="full-width">
                                <input matInput #codePostal maxlength="5" placeholder="Code postal" type="text"
                                    formControlName="codePostal">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Complement Adresse Principale" formControlName="complementAdresse">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12" style="display: flex;justify-content: space-between;">
                            <h3>Adresse Facturée</h3>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <input #addresstext matInput placeholder="Adresse Facturée" formControlName="addressFact">
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Ville" formControlName="villeFact">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="full-width">
                                <input matInput #codePostal maxlength="5" placeholder="Code postal" type="text"
                                    formControlName="codePostalFact">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Complement Adresse Facturé" formControlName="complementAdresseFact">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>





    <div class="row">
    <div class="col-md-3" style="margin-top: 35px;">
        <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>

    <div class="col-md-3" style="margin-top: 35px;">
        <button mat-flat-button color="success" (click)="onModalConfirm()">Ajouter</button>
    </div>
</div>



</div>