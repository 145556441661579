<div class="header-class">
  <div>
    <h3>Ajouter une demande
    </h3>
  </div>
  <div>
    <a (click)="onModalCancel()" class="close-button">
      <mat-icon class="close-icon">close</mat-icon>
    </a>
  </div>
</div>
<mat-divider></mat-divider>

<div class="col-md-8 body-class">
  <div class="header-class"> 
    <h4>Demande :</h4>
  </div>
  <div class="col-md-12 data-class body-class" style="padding-top: 10px;">
    <div class="col-md-12">
      <mat-form-field class="full-width">
        <mat-label>Objet</mat-label>
        <input required
          (click)="objetAuto = false"
          matInput
          style="font-size: large;"
          placeholder=""
          value=""
          name="intercom-objet-demande"
          [(ngModel)]="data.demande.objet"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field  class="full-width" *ngIf="typeDemandes !== undefined">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="data.demande.typeDemandeId" (selectionChange)="typeChange()" required>
          <mat-option *ngFor="let typeD of typeDemandes.slice()" [value]="typeD.id">{{typeD.libelle}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-3">
      <mat-form-field class="full-width">
        <mat-label>Date d'échéance</mat-label>
        <input required matInput [matDatepicker]="picker" [(ngModel)]="data.demande.dateIntervention">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    
    <div class="col-md-3">
      <app-autocomplete-realisateur [required]="true" libelle="Réalisateur" [users]="users" [user]="user" [commercialId]= "commercialId" (onUpdated)="onUpdateRealisateurId($event)"> </app-autocomplete-realisateur>
    </div>

    <div class="col-md-3">
      <mat-form-field class="full-width">
        <mat-label>Statut </mat-label>
        <mat-select required [(ngModel)]="data.demande.statut">
          <mat-option value="A traiter">A traiter</mat-option>
          <mat-option value="En cours">En cours</mat-option>
          <mat-option value="Clos">Clos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field style="width: 100%;">
        <mat-label>Commentaire</mat-label>
        <textarea matInput rows="10" (keyup)="limitCommentTextArea($event)" [(ngModel)]="data.demande.commentaire"></textarea>
      </mat-form-field>
    </div>
  </div>
  
  <div class="header-class"> 
    <h4>Adresse :</h4>
  </div>
  <div class="col-sm-12 data-class body-class" style="padding-top: 10px;">
    <div class="col-sm-12">
      <app-autocomplete-adresse  [realisateur]="commercial" [adresses]="siteModels" [adresseId]="adresseId" (selectedAdresse)='onAddAddress($event)'></app-autocomplete-adresse>
    </div>
  </div>
</div>



<div class="col-md-4 element-right">
  <app-select-prestation [prestationsList]=prestations [prestationIds] = "prestationIds" (updated)="onUpdatePrestationsId($event)"> </app-select-prestation>
  <div class="header-class">
    <h4>Contact : </h4>
  </div>
  <div class="col-sm-12 data-class" style="padding-top: 10px;">
    <div>
      <app-autocomplete-interlocuteur 
        [interlocuteurs]="interlocuteurs"
        (selectedInterlocuteur)='onAddInterlocuteur($event)'
        [demande]="data.demande">
      </app-autocomplete-interlocuteur>
    </div>
  </div>
</div>
<div class="col-md-12 flex-container">
  <div class="subDivContainer">
    <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
  </div>
  <div class="subDivContainer">
  <a mat-stroked-button color="success" class="row" mat-button (click)="searchProtocole()"><mat-icon>add</mat-icon>Rechercher un protocole</a>
    </div>
  <div class="subDivContainer">
    <button mat-flat-button color="success" style="color:white;" (click)="onModalConfirm()">Ajouter</button>
  </div>
</div>
