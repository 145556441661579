import { BasicModel } from '../basic-models/basic.model';

export class EntiteSARPModel extends BasicModel {
    public code: string;
    public type?: string;
    public libelle: string;
}

export class EntiteSARPNode extends BasicModel {
    public code: string;
    public libelle: string;
    public type: string;
    public childrens?: EntiteSARPNode[];
}
