import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-modal-envoyer-jason',
    templateUrl: './modal-envoyer-jason.html',
    styleUrls: ['./modal-envoyer-jason.scss']
})

  export class ModalEnvoyerJasonComponent {
    @Input() data;
    stringsPresta : string[] = [];

    ngOnInit() {
      let prestaIds = this.data.demande.prestationIds.split(',');
      for (let id in prestaIds) {
        let i = 0;
        while (i < this.data.prestations.length) {
          if (this.data.prestations[i].id === Number(id)) {
            this.stringsPresta.push(this.data.prestations[i].libelle);
            i = this.data.prestations.length;
          }
          i++;
        }
      }
    }

    checkTVADoc() {
      if (this.data && this.data.devisDTO && this.data.devisDTO.documents) {      
        for (let doc of this.data.devisDTO.documents) {
          if (doc.libelle == 'TVA Signé')
          return (true);
        }
      }
      return (false);
    }

  }