import { BasicService } from '../basic-service/basic.service';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/modules/shared/session.service';
@Injectable()

export class DroitService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEDROIT;
    }

    getFromAgence(id: number): Observable<Number> {
      return this.http.get<Number>(this.apiUrl + this.serviceUrl + 'GetFromAgence/' + id);
    }
  }
