import { BasicMock } from './basic-mock/basic.mock';
import { ActiviteModel } from '../models/prestation.model';
import { Injectable } from '@angular/core';
@Injectable()
export class PrestationMock  extends BasicMock {
    constructor() {
        super();
        (<ActiviteModel[]> this.objets) = [
          {
            id: 1,
            libelle: 'Pompage',
            commentaire: '',
            agenceId: 1,
            code: "1",
            codePraxedo: "1A"
          },
          {
            id: 2,
            libelle: 'I.T.V',
            commentaire: '',
            agenceId: 1,
            code: "2",
            codePraxedo: "2B"
          },
          {
            id: 3,
            libelle: '3D',
            commentaire: '',
            agenceId: 1,
            code: "3",
            codePraxedo: "3C"
          },
          {
            id: 4,
            libelle: 'Hydrocarbure',
            commentaire: '',
            agenceId: 1,
            code: "4",
            codePraxedo: "4D"
          },
          {
            id: 5,
            libelle: 'Contrôle',
            commentaire: '',
            agenceId: 1,
            code: "5",
            codePraxedo: "5E"
          }
        ];
      }

}
