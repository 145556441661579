import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.scss']
})
export class ModalSpinnerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalSpinnerComponent>) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
  }

}
