import { DroitService } from '../../../services/user/droit.service';
import { DroitModel } from '../../../shared/models/droit.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { EntiteSARPNode } from '../../../shared/models/rsa/entite-sarp.model';
import { RegionModel } from '../../../shared/models/rsa/region.model';
import { RegionService } from './../../../services/rsa/region.service';
import { DroitLevels } from '../../../shared/droitsLevels';
import { TypeDroit } from './../../../shared/typeDroits';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { tap } from 'rxjs/operators';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AgenceService } from '../../../services/rsa/agence.service';
import { UserService } from '../../../services/user/user.service';
import { AgenceModel } from '../../../shared/models/rsa/agence.model';
import { UserModel } from '../../../shared/models/users/user.model';
import { DocumentService } from '../../../services/document.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { ModalShowDocumentComponent } from '../../document/modal-show-document/modal-show-document.component';
import { ModalAddDocumentComponent } from '../../document/modal-add-document/modal-add-document.component';


export class Droits {
  niveau: string;
  RSA: string;
  code: string;
  RSACode: string;
  typeRSA?: string;
  idDroit: number;

  constructor(droitM: DroitModel) {
    const niv = DroitLevels.TYPEDROITS.find(i => i.niveau === droitM.type);
    if (niv === undefined) {
      this.niveau = 'ERROR';
    }
    this.niveau = niv.libelle;
    this.RSA = droitM.rsa.libelle;
    this.code = droitM.rsa.code;
    this.RSACode = droitM.rsa.code;
    this.idDroit = droitM.id;
    this.typeRSA = droitM.rsa.type;
  }
}

@Component({
  selector: 'app-fiche-utilisateur-droit',
  templateUrl: './fiche-utilisateur-droit.component.html',
  styleUrls: ['./fiche-utilisateur-droit.component.scss']
})
export class FicheUtilisateurDroitComponent implements OnInit {

  user: UserModel = new UserModel();
  types: TypeDroit[] = DroitLevels.TYPEDROITS;
  regionsManaged: RegionModel[] = [];
  regionsAll: RegionModel[] = [];
  niveauDroitSelected: number;
  displayedColumns: string[] = ['RSA', 'Code', 'Type', 'Action'];
  dataSource = new MatTableDataSource<Droits>([]);
  showAllRSA = true;
  treeControl = new NestedTreeControl<EntiteSARPNode>(node => node.childrens);
  dataSourceTree = new MatTreeNestedDataSource<EntiteSARPNode>();
  idUser: number;
  agences: AgenceModel[] = [];
  result = false;

  constructor(private route: ActivatedRoute,
    public dialog: MatDialog,
    private userService: UserService,
    private droitService: DroitService,
    private agenceService: AgenceService,
    private IntercomToastrService: IntercomToastrService,
    private regionService: RegionService,
    private documentService: DocumentService) { }

  loadTree(loadAll: boolean) {
    if (!loadAll) {
      this.dataSourceTree.data = this.regionService.constructSmallRegionTree(this.regionsManaged);
    } else {
      this.dataSourceTree.data = this.regionService.constructEntitySarpTree(this.regionsManaged);
    }
  }

  loadUser(idUser: number) {
    return this.userService.get(idUser).pipe(tap((user: UserModel) => {
      if (user != null && user !== undefined) {
        this.user = Object.assign({}, user);
        this.dataSource.data = user.droits.map<Droits>(droitM => new Droits(droitM));
      }
    }));
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
        this.idUser = params.id;
        this.loadUser(params.id).subscribe();

        this.agenceService.getAllManaged(DroitLevels.MANAGE).subscribe(agences => {
          this.agences = agences.sort((a, b) => a.libelle.localeCompare(b.libelle));
        });
    });

    this.regionService.getAllManaged(4).subscribe(regions => {
      this.regionsManaged = regions;
      this.loadTree(this.showAllRSA);
    });
  }

  onDroitSelectedChanged(event: MatSelectChange) {
    if (this.showAllRSA === (event.value === 4)) {
      this.showAllRSA = ! this.showAllRSA;
      this.loadTree(this.showAllRSA);
    }
  }

  hasChild = (_: number, node: EntiteSARPNode) => !!node.childrens && node.childrens.length > 0;

  saveUser() {
    this.user.nom = this.user.nom.toUpperCase();
    this.user.prenom = this.user.prenom.charAt(0).toUpperCase() + this.user.prenom.slice(1);
    this.userService.put(this.user).subscribe(result => {
      if (result !== undefined) {
        this.IntercomToastrService.success('Enregistrement de l\'utilisateur réalisé.', 'Succès !');
      } else {
        this.IntercomToastrService.error('Enregistrement de l\'utilisateur annulée !', 'Erreur !');
      }
    });
  }

  addDroit(node: EntiteSARPNode) {
    if (this.niveauDroitSelected === undefined) {
      this.IntercomToastrService.warning('Sélectionnez le niveau de droit', 'Aucun niveau de droit sélectionner');
    } else {
      const droit: DroitModel = new DroitModel();
      droit.rsaId = node.id;
      droit.utilisateurId = this.user.id;
      droit.type = this.niveauDroitSelected;
      this.droitService.post(droit).subscribe(result => {
        if (result !== null) {
          this.loadUser(this.idUser).subscribe(_ => this.IntercomToastrService.success('Ajout du droit effective', 'Succès !'));
        } else {
          this.IntercomToastrService.error('Ajout du droit annulé ! ', 'Erreur !');
        }
      });
    }
  }


  selectedAgenceChanged(event: AgenceModel) {
    if (event !== undefined) {
      this.user.agenceId = event.id;
    }
  }

  deleteDroit(droit: Droits){
    if (droit.idDroit === undefined) {
      this.IntercomToastrService.warning('Sélectionnez le niveau de droit', 'Aucun niveau de droit sélectionner');
    } else {
      this.droitService.delete(droit.idDroit).subscribe(result => {
        if (result !== undefined) {
          this.loadUser(this.idUser).subscribe(_ => this.IntercomToastrService.success('Suppression du droit effectué.', 'Succès !'));
        } else {
          this.IntercomToastrService.error('Droits non supprimé.', 'Erreur');
        }
      });
    }
  }

  addDocument(typeDoc: string) {
    var id = (typeDoc === "Photo") ? this.user.photoId : this.user.signatureId;
    if(id) {
      this.documentService.get(id).subscribe(res => {
        const dialogRef = this.dialog.open(ModalShowDocumentComponent, {disableClose: true,
          data: {
            typeId: this.user.id,
            type: 'Utilisateur',
            document: res,
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          // if res -> delete doc
          if(res) {
            (typeDoc === "Photo") ? (this.user.photoId = null ): (this.user.signatureId = null);
          }
        });
      });
      
    } else {
      const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
        data: {
          typeId: this.user.id,
          type: 'Utilisateur',
          document: new DocumentModel(),
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.documentService.get(res).subscribe((document: DocumentModel) => {
            (typeDoc === "Photo") ? this.user.photoId = document.id : this.user.signatureId = document.id;
            this.userService.put(this.user).subscribe((user: UserModel) => {
              if(user) {
                this.user = user;
                this.IntercomToastrService.success('L \' utilisateur ' + user.nom + (user.prenom ? user.prenom : '') + ' a bien été mise à jour' );
              }
            });
          });
        }
      });
    }
  }
}

