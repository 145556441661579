import { Router } from '@angular/router';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { PrestationModel } from '../../models/prestation.model';
import { OffreModel } from '../../models/offre.model';
import { PrestationService } from '../../services/prestation.service';

@Component({
  selector: 'app-afficher-prestation-offre',
  templateUrl: './afficher-prestation-offre.component.html',
  styleUrls: ['./afficher-prestation-offre.component.scss']
})
export class AfficherPrestationOffreComponent implements OnChanges {
  color = '';
  listPrestationsTmp = [];
  panelOpenState = true;

  @Input() listPrestations: PrestationModel[];
  @Output() update = new EventEmitter<OffreModel>();
  @Output() selectedPrestation = new EventEmitter<PrestationModel>();

  listPrestationsKeys = Object.keys;

  constructor(private router: Router,
              private prestationService: PrestationService ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if(!this.listPrestations) {
      this.listPrestations = [];
    }
    this.listPrestationsTmp.push(this.listPrestations);
  }

  modify(prestation: PrestationModel) {
    this.selectedPrestation.emit(prestation);
  }
}
