<h3>
  Importation document
</h3>

<form class="row" [formGroup]="form" (ngSubmit)="submit()" >
  <div class="col-sm-12 " style="display: flex; justify-content: space-between;">
      <div class="col-sm-6 " style="margin-top: 24px;">
        Importer le devis signé
        <app-file-upload formControlName="fileControlDevis"></app-file-upload>
      </div>
      <div class="col-sm-6 " style="margin-top: 24px;">
        Importer la tva simplifié signé
        <app-file-upload formControlName="fileControlTva"></app-file-upload>
      </div>
  </div>

  <div class="col-sm-12"  style="display: flex; justify-content: space-between;">
      <div class="col-sm-4 text-center" style="margin-top: 24px; margin-left: 30px;">
        <p> <mat-checkbox formControlName="noDoc"></mat-checkbox> Valider le devis sans document</p>
        <button mat-flat-button color="primary" type="submit">
          <mat-icon>publish</mat-icon> Enregistrer
        </button>
        
      </div>
      <div class="col-sm-4 text-center" style="margin-top: 24px; margin-left: 80px;" >
        <p ><br/></p>
        <button type="button" mat-flat-button color="default" (click)="closeDialog()">Annuler</button> 
      </div>
  </div>
</form>

