import { Component,  Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CiviliteService } from 'src/app/modules/core/services/civilite.service';
import { ActionModel } from 'src/app/modules/core/shared/models/action.model';
import { CiviliteModel } from 'src/app/modules/core/shared/models/civilite.model';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { InterlocuteurModel } from 'src/app/modules/core/shared/models/interlocuteur.model';


@Component({
  selector: 'app-autocomplete-interlocuteur',
  templateUrl: './autocomplete-interlocuteur.component.html'
})
export class AutocompleteInterlocuteurComponent implements OnChanges {

  @Input() action: ActionModel;
  @Input() emailRequired: boolean = false;
  @Input() demande: DemandeModel;
  @Input() interlocuteurs: InterlocuteurModel[];
  @Input() printChips = false;
  @Output() selectedInterlocuteur = new EventEmitter<InterlocuteurModel>();
  interlocuteurControl = new UntypedFormControl();
  civiliteControl = new UntypedFormControl();
  interlocuteursString: string[] = [];
  civilitesString: string[] = [];
  filteredInterlocuteur: Observable<string[]>;
  filteredCivilite: Observable<string[]>;
  civilites: CiviliteModel[] = [];
  civiliteDemande = '';
  interlocuteurDemande = '';
  fieldStatus = false;
  interlocuteur: InterlocuteurModel;
  libelleChips = "Création";

  constructor(private civiliteService: CiviliteService,) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
      this.civilites.forEach(element => {
        this.civilitesString.push(element.libelle);
      });
      this.filteredCivilite = this.civiliteControl.valueChanges.pipe(
        startWith(''),
        map((civilite: string) => this._filter(civilite, this.civilitesString)));

    if (this.interlocuteurs && this.civilites && this.civilites.length > 0) {
      if (this.demande && !(Object.keys(changes).length == 1 && Object.keys(changes)[0] == 'demande')) {
        this.initInterlocuteur(this.demande);
      } else if (this.action && !(Object.keys(changes).length == 1 && Object.keys(changes)[0] == 'demande')) {
        this.initInterlocuteur(this.action);
      }
      this.interlocuteursString = [];
      // Fin de la récupération de l'interlocuteur associé à la demande
      // filter interlocuteur
      for (let i = 0; i < this.interlocuteurs.length; i++) {
        const value = ((this.interlocuteurs[i].nom !== '') && (this.interlocuteurs[i].prenom !== undefined)) ?
          // tslint:disable-next-line: max-line-length
          ((this.interlocuteurs[i].nom !== '') && (this.interlocuteurs[i].prenom !== undefined) && (this.interlocuteurs[i].civiliteId !== undefined && this.interlocuteurs[i].civiliteId !== null && this.interlocuteurs[i].civiliteId !== 0) ?
            (this.civilites.find(civilite => civilite.id === this.interlocuteurs[i].civiliteId).libelle + ' ' + this.interlocuteurs[i].nom + ' ' + this.interlocuteurs[i].prenom) :
            (this.interlocuteurs[i].nom + ' ' + this.interlocuteurs[i].prenom)) :
            this.interlocuteurs[i].nom;
        this.interlocuteursString.push(value);
      }
      this.interlocuteursString.sort();
      this.filteredInterlocuteur = this.interlocuteurControl.valueChanges.pipe(
        startWith(''),
        map((interloc: string) => this._filter(interloc, this.interlocuteursString)));
    }
    }); 
  }

  // Filtered interlocuteur
  onFullAutoInterlocutor(value: string) {
    let interlocuteur;
    if (value !== '') {
      if (this.interlocuteurs) {
        interlocuteur = this.interlocuteurs.find(interloc => interloc.nom + ' ' + interloc.prenom === value);
      }
      if (interlocuteur) {
        this.libelleChips = 'Modification';
        this.interlocuteur = Object.assign({}, interlocuteur);
        this.selectedInterlocuteur.emit(this.interlocuteur);
      } else {
        const interlocuteurFullName = value.split(' ', 3);
        const civilite = this.civilites.find(civil => civil.libelle.localeCompare(interlocuteurFullName[0]) === 0);
        if(this.interlocuteur === undefined) {
          this.fieldStatus = false;
          this.interlocuteur = new InterlocuteurModel();
          if (civilite) {
            this.interlocuteur.civiliteId = civilite.id;
            this.interlocuteur.nom = interlocuteurFullName[1];
            this.interlocuteur.prenom = interlocuteurFullName[2];
            this.civiliteDemande = civilite.libelle;
            this.interlocuteur.civilite = civilite;
          } else {
            this.interlocuteur.civiliteId = undefined;
            this.interlocuteur.civilite = null;
            this.interlocuteur.nom = interlocuteurFullName[0];
            this.interlocuteur.prenom = interlocuteurFullName[1];
          }
          this.interlocuteur.role = 'interlocuteur terrain';
          this.selectedInterlocuteur.emit(this.interlocuteur);
        } else {
          if (civilite) {
            this.interlocuteur.civiliteId = civilite.id;
            this.interlocuteur.nom = interlocuteurFullName[1];
            this.interlocuteur.prenom = interlocuteurFullName[2];
            this.civiliteDemande = civilite.libelle;
            this.interlocuteur.civilite = civilite;
          } else {
            this.interlocuteur.civiliteId = undefined;
            this.interlocuteur.civilite = null;
            this.interlocuteur.nom = interlocuteurFullName[0];
            this.interlocuteur.prenom = interlocuteurFullName[1];
          }
          this.selectedInterlocuteur.emit(this.interlocuteur);
        }
      }
    } else {
      this.libelleChips = 'Création';
      this.interlocuteur = undefined;
      this.selectedInterlocuteur.emit(this.interlocuteur);
    }
  }

  /* selected event*/
  interlocuteurChange(event: MatAutocompleteSelectedEvent) {
    this.libelleChips = 'Modification';
    const value = event.option.viewValue;
    const filtreValue = value.split(' ', 3);
    // tslint:disable-next-line: max-line-length
    const civilite = this.civilites.find(civ => civ.libelle === filtreValue[0]);
    this.interlocuteur = this.findInterloc(value, filtreValue, civilite);
    this.interlocuteur.civilite = civilite;
    this.interlocuteur.civiliteId = civilite ? civilite.id : undefined;
    this.civiliteDemande = civilite ? civilite.libelle : '';
    this.selectedInterlocuteur.emit(this.interlocuteur);
    if (this.interlocuteur.id) {
      this.fieldStatus = true;
    }
  }

  findInterloc(value: string, filtreValue: string[], civilite: CiviliteModel) {
    if (civilite) {
      if (filtreValue.length > 2){
        return Object.assign({}, this.interlocuteurs.find(interloc => interloc.nom + ' ' + interloc.prenom === filtreValue[1] + ' ' + filtreValue[2]));
      } else {
        return Object.assign({}, this.interlocuteurs.find(interloc => interloc.nom === filtreValue[1]));
      }
    } else {
      if (filtreValue.length > 1){
        return Object.assign({}, this.interlocuteurs.find(interloc => interloc.nom + ' ' + interloc.prenom === value));
      }else{
        return Object.assign({}, this.interlocuteurs.find(interloc => interloc.nom === value));
      }
    }
  }

  civiliteChange(event: string) {
    if (this.civilites  && this.interlocuteur) {
      if(event.trim() !== '') {
        const value = this.civilites.find(civ => civ.libelle === event);
        if(value){
          this.interlocuteur.civilite = value;
          this.interlocuteur.civiliteId = value.id;
        }
        this.selectedInterlocuteur.emit(this.interlocuteur);
        if (this.interlocuteur.id) {
          this.fieldStatus = true;
        }
      } else {
        this.interlocuteur.civilite = null;
        this.interlocuteur.civiliteId = null;
        this.selectedInterlocuteur.emit(this.interlocuteur);
      }
    }
  }


  initInterlocuteur(objet) {
    if (objet && objet.nomInterlocuteur) {
      this.interlocuteur = new InterlocuteurModel();
      this.interlocuteur.id = objet.interlocuteurId;
      this.interlocuteur.nom = objet.nomInterlocuteur;
      this.interlocuteur.prenom = objet.prenomInterlocuteur;
      this.interlocuteur.role = objet.roleInterlocuteur;
      this.interlocuteur.email = objet.emailInterlocuteur;
      this.interlocuteur.telPrincipal = objet.telPrincipalInterlocuteur;
      this.interlocuteur.telSecondaire = objet.telSecondaireInterlocuteur;
      this.interlocuteur.commentaire = objet.commentaireInterlocuteur;
      this.interlocuteur.civilite = this.civilites.find(c => c.libelle === objet.civiliteInterlocuteur);
      this.interlocuteur.idJason = objet.idJasonInterlocuteur;
      this.interlocuteurDemande = objet.nomInterlocuteur && objet.prenomInterlocuteur ? objet.civiliteInterlocuteur ?
        objet.civiliteInterlocuteur + ' ' + objet.nomInterlocuteur + ' ' + objet.prenomInterlocuteur :
        objet.nomInterlocuteur + ' ' + objet.prenomInterlocuteur :
        objet.nomInterlocuteur;
      this.interlocuteurControl.setValue(this.interlocuteurDemande);
        
      if(this.interlocuteur.civilite) {
        this.interlocuteur.civiliteId = this.interlocuteur.civilite.id;
      }
      this.civiliteDemande = objet.civiliteInterlocuteur;
      this.civiliteControl.setValue(objet.civiliteInterlocuteur);
      this.selectedInterlocuteur.emit(this.interlocuteur);
    }
    
  }

  private _filter(value: string, objets: string[]): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return objets.filter(objet => objet.toLowerCase().indexOf(filterValue) >= 0);
    } else {
      return objets;
    }
  }
}