import { DroitService } from './../../services/user/droit.service';
import { AgenceService } from './../../services/rsa/agence.service';
import { AgenceModel } from './../../shared/models/rsa/agence.model';
import { UserService } from './../../services/user/user.service';
import { ActiviteService } from './../../services/prestation.service';
import { SessionService } from '../../../shared/session.service';
import { Component, OnInit, OnChanges, Output, EventEmitter, SimpleChanges, Injector } from '@angular/core';
import { TrieTabService } from '../../services/trie-tab.service';
import { ModalsService } from '../../../shared/modals.service';
import { ModalAjoutProtocoleComponent } from './../protocole/modal-ajout-protocole/modal-ajout-protocole.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GoogleApiService } from '../../../shared/gapiServices/googleApiService';
import { ModalInfoOkComponent } from '../divers/modals/modal-info-ok.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ActiviteModel } from './../../shared/models/prestation.model';
import { UserModel } from './../../shared/models/users/user.model';
import { ProtocoleService } from '../../services/protocole.service';
import { ProtocoleModel } from '../../shared/models/protocole.model';

export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-protocole-agence',
  templateUrl: './protocole-agence.component.html',
  styleUrls: ['./protocole-agence.component.scss']
})
export class ProtocoleAgenceComponent implements OnInit, OnChanges {

   // tslint:disable-next-line: no-output-on-prefix
   @Output() onUpdated = new EventEmitter<boolean>();
   private standardWidth = '90%';
   private standardHeight = '99vh';
   agence: AgenceModel;
   mySubscription: any;
   result = false;
   loading = false;
   selectedAction: ProtocoleModel;
   displayedColumns: string[] = ['adresse', 'codePostal', 'prestation', 'realisateur', 'protocole', 'modifications'];
   dataSource: ProtocoleModel[];
   matDataSource: MatTableDataSource<ProtocoleModel>;
   pageEvent: PageEvent;
   sortState: Sort = {active: 'adresse', direction: 'desc'};
   length: number;
   pageIndex: number;
   pageSize = 10;
   prestationString: string[] = [];
   prestations: ActiviteModel[] = [];
   users: UserModel[] = [];
   protocoles: ProtocoleModel[] = [];
   dataSourceCopy: ProtocoleModel[];
   droitLevel = 0;
   private modalsService: ModalsService;

   constructor( private router: Router,

     public dialog: MatDialog,
     private comp: TrieTabService,
     injector: Injector,
     private route: ActivatedRoute,
     private toastr: IntercomToastrService,
     private sessionService: SessionService,
     private gapiService: GoogleApiService,
     private agenceService: AgenceService,
     private IntercomToastrService: IntercomToastrService,
     private prestationService: ActiviteService,
     private userService: UserService,
     private protocoleService: ProtocoleService,
     private droitService: DroitService) {

      this.route.params.subscribe((params: any) => {
        if (params.id === '0') {
          this.IntercomToastrService.error('Erreur durant la création.', 'Erreur');
          this.router.navigate(['/']);
        } else {
          this.agenceService.get(params.id).subscribe((agence: AgenceModel) => {
            if (agence) {
              this.agence = agence;
              this.protocoleService.getFromAgence(this.agence.id).subscribe((protocoles: ProtocoleModel[]) => {
                this.dataSource = protocoles;
                const data = this.dataSource.slice();
                this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
                this.length = this.dataSource.length;
                this.pageIndex = 0;
                this.trieTab(this.sortState);
              });
              this.droitService.getFromAgence(agence.id).subscribe((level: number) => {
                this.droitLevel = level;
              });
            }
          });
        }
      });
       this.userService.getAll().subscribe((users: UserModel[]) => this.users = users);

       this.prestationService.getAll().subscribe((prestations: ActiviteModel[]) => {
         for (let i = 0; i < prestations.length; i++) {
           this.prestationString.push(prestations[i].libelle);
           this.prestations = prestations;
         }
       });
       this.modalsService = injector.get('ModalsService');
       this.router.routeReuseStrategy.shouldReuseRoute = function () {
         return false;
       };
       this.mySubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
           this.router.navigated = false;
         }
       });
      }

   ngOnInit() {
   }

   ngOnChanges(changes: SimpleChanges): void {
   }

   onAddProtocole(): void {
     const protocole = new ProtocoleModel();
     protocole.agence = Object.create(this.agence);
       const dialogRef = this.dialog.open(ModalAjoutProtocoleComponent, {
         autoFocus: false,
         disableClose: true,
         width: this.standardWidth,
         maxHeight: this.standardHeight,
         data: {
           agenceId: this.agence.id,
           protocole: protocole,
           result: this.result
         }
       });

       dialogRef.afterClosed().subscribe(result => {
         this.onUpdated.emit(true);
         if (result) {
          this.protocoleService.getFromAgence(this.agence.id).subscribe((protocoles: ProtocoleModel[]) => {
             this.dataSource = protocoles;
             this.loadPage();
           });
         }
       });

   }

   onOpenProtocole(p: ProtocoleModel): void {
     this.protocoleService.get(p.id).subscribe((protocole: ProtocoleModel ) => {
       this.modalsService.openProtocole(protocole).subscribe(result => {
        if (result) {
          this.protocoleService.getFromAgence(this.agence.id).subscribe((protocoles: ProtocoleModel[]) => {
             this.dataSource = protocoles;
             this.loadPage();
           });
          }
       });
     });
   }

   changePage(event?: PageEvent) {
     this.pageIndex =  event.pageIndex;
     this.pageSize = event.pageSize;
     this.loadPage();
   }

   trieTab(sort: Sort) {
     this.sortState = sort;
     this.loadPage();
   }

   loadPage() {
     this.matDataSource =  this.comp.sortData(this.sortState, this.dataSource, this.matDataSource, this.pageIndex, this.pageSize);
     if (this.matDataSource === undefined) {
        const data = this.dataSource.slice();
         this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
     }
   }

   deleteProtocole(protocole: ProtocoleModel): void {
     const dialogRef = this.dialog.open(ModalInfoOkComponent, {
       data: {
         title: 'Suppression du protocole',
         message: 'Êtes-vous sur de vouloir supprimer le protocole ?',
         icone: 'warning_amber',
         iconColor: 'accent',
         color: 'accent'
       }
     });
     dialogRef.afterClosed().subscribe(res => {
       if (res) {
         this.protocoleService.delete(protocole.id).subscribe((response: ProtocoleModel) => {
           this.IntercomToastrService.success('Le protocole a bien été supprimé');
           this.dataSource.forEach((element, index) => {
             if (element.id === response.id) {
               this.dataSource.splice(index, 1);
               this.matDataSource = new MatTableDataSource(this.dataSource.slice(0, this.pageSize));
               this.length = this.dataSource.length;
             }
           });
         });
       }
     });
   }
}
