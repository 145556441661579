<div>
    <a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon">close</mat-icon></a>
    
    <div class="col-sm-8">
      <h3 *ngIf="!this.data.zone.id">Ajouter une zone d'intervention</h3>
      <h3 *ngIf="this.data.zone.id">Modifier une zone d'intervention</h3>
    </div>
   
    <div class="col-sm-12">
      <mat-divider></mat-divider>
    </div>
    
<div class="col-sm-12 body-class">
    <div class="col-sm-4">
      <mat-form-field  class="full-width">
        <mat-label>Libelle</mat-label>
        <input
        matInput
        type="text"
        [(ngModel)]="this.data.zone.libelle" required>
      </mat-form-field>
    </div>


    <div class="col-sm-4">
        <mat-form-field class="full-width">
        <mat-label>Code postal</mat-label>
        <input 
        type="text"
        maxlength="5"
        matInput 
        [(ngModel)]="data.zone.codePostal" required>
        </mat-form-field>
    </div>

    <div class="col-sm-4">
        <mat-form-field class="full-width">
            <mat-label>Majoration</mat-label>
            <input
            #input
            type="float"
            matInput
            [(ngModel)]="this.data.zone.majoration" required/>
        </mat-form-field>
    </div>
    
    <div class="col-sm-12">
      <mat-form-field class="full-width">
        <mat-label>Commentaire</mat-label>
        <textarea matInput type="textarea" placeholder="" style="height: 90px;" value=""
          [(ngModel)]="data.zone.commentaire"></textarea>
      </mat-form-field>
    </div>
  </div>
  
  <div class="col-sm-12 text-center">
    <div class="col-sm-6 text-center">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>
  
    <div class="col-sm-6 text-center">
        <button mat-flat-button color="success" *ngIf="this.data.zone.id" (click)="onModalConfirm()">Modifier</button>
        <button mat-flat-button color="success" *ngIf="!this.data.zone.id" (click)="onModalConfirm()">Ajouter</button>
    </div>
  </div>