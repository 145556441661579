import { OffreService } from './services/offre.service';
import { PrestationApiService } from './services/prestation-api.service';
import { PrestationBasicService } from './services/prestation-basic.service';
import { PrestationService } from './services/prestation.service';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { GoogleApiService } from 'src/app/modules/shared/gapiServices/googleApiService';
import { GoogleAuthService } from 'src/app/modules/shared/gapiServices/googleAuthService';
import { SessionService } from 'src/app/modules/shared/session.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { TokenInterceptor } from 'src/app/modules/shared/token-interceptor';
import { CustomDateAdapter, APP_DATE_FORMATS } from '../shared/custom-date-adapter';
import { MatPaginatorIntlFr } from '../shared/frenchPaginatorIntl';
import { JwtHelper } from '../shared/jwtHelper';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { HttpClientJsonpModule } from '@angular/common/http';
import { MatGridListModule } from '@angular/material/grid-list';
import { ParametrePrestationComponent } from './components/parametre-prestations/parametre-prestation.component';
import { AfficherPrestationOffreComponent } from './components/afficher-prestation-offre/afficher-prestation-offre.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { OffreModalComponent } from './components/offre-modal/offre-modal.component';
import { ParametrePrestationComponentService } from './components/parametre-prestation-component.service';
import { CritereCalculDevisComponent } from './components/critere-calcul-devis/critere-calcul-devis.component';
import { datadogRum } from '@datadog/browser-rum';

export class GenericErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        datadogRum.addError(error);
        console.error(error);
    }
}

@NgModule({
    declarations: [
        ParametrePrestationComponent,
        AfficherPrestationOffreComponent,
        OffreModalComponent,
        CritereCalculDevisComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatIconModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatIconModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatMenuModule,
        MatCheckboxModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTreeModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatRadioModule,
        MatTooltipModule,
        HttpClientJsonpModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatChipsModule,
        MatSnackBarModule
    ],
    exports: [
        CritereCalculDevisComponent,
        ParametrePrestationComponent
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr },
        { provide: 'ModalsService', useClass: ModalsService },
        { provide: ModalsService, useClass: ModalsService },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: ErrorHandler, useClass: GenericErrorHandler },
        SessionService,
        GoogleAuthService,
        IntercomToastrService,
        GoogleApiService,
        JwtHelper,
        IntercomToastrService,
        PrestationApiService,
        PrestationBasicService,
        PrestationService,
        OffreService,
        SessionService,
        ParametrePrestationComponentService
    ]
})
export class PrestationModule { }
