import { DemandeModel } from '../../shared/models/demande.model';
import { Component, OnInit, Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ActionComponent } from './action.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActionService } from '../../services/action.service';
import { DemandeService } from '../../services/demande.service';
import { DocumentService } from '../../services/document.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { TypeActionService } from '../../services/type-action.service';
import { UserService } from '../../services/user/user.service';
import { ActionModel } from '../../shared/models/action.model';
import { DocumentModel } from '../../shared/models/document/document.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { TypeActionModel } from '../../shared/models/type-action.model';
import { UserModel } from '../../shared/models/users/user.model';

export interface DialogData {
  action: ActionModel;
  demandeLib: string;
  result: boolean;
}


@Component({
  selector: 'app-modal-closed-action',
  templateUrl: './modal-closed-action.component.html',
  styleUrls: ['./modal-closed-action.component.scss']

})
export class ModalClosedActionComponent implements OnInit {
  typeActions: TypeActionModel[];
  typeAction: TypeActionModel;
  users: UserModel[];
  user: UserModel;
  result: boolean;
  private modalsService: any;
  // documents
  documents: DocumentModel[] = [];
  document: DocumentModel;

  interlocuteurs: InterlocuteurModel[] = [];

  constructor(private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private actionService: ActionService,
    private typeActionService: TypeActionService,
    private userService: UserService,
    private demandeService: DemandeService,
    injector: Injector,
    private documentService: DocumentService,
    private interlocuteurService: InterlocuteurService
     ) {
      this.modalsService = injector.get('ModalsService');
    }

  ngOnInit() {
    this.typeActionService.get(this.data.action.typeActionId).subscribe((x: TypeActionModel) =>
      this.data.action.typeAction = x);
    this.userService.get(this.data.action.realisateurId).subscribe((x: UserModel) => this.data.action.realisateur = x);
    this.getDocumentsByAction();
    if (this.data.action.demandeId == null) {
      this.data.demandeLib = 'Aucun';
    } else {
      this.demandeService.get(this.data.action.demandeId).subscribe(dem => this.data.demandeLib = dem.objet);
    }
    if (this.data.action.tierId) {
      this.interlocuteurService.getFromTier(this.data.action.tierId).subscribe((interloc: InterlocuteurModel[]) => {
        this.interlocuteurs = interloc;
      });
    }
  }

  onOpenDemande(): void {
    this.demandeService.get(this.data.action.demandeId).subscribe((demande: DemandeModel) => {
      this.modalsService.openDemande(demande).subscribe(result => {
      });
    });
  }

  onModalCancel(): void {
    this.data.result = true;
    this.modalsService.closeAction(this.data.action);
    this.dialogRef.close(true);
  }

  hasTier() {
    return (this.data.action.tierId !== null && this.data.action.tierId !== undefined && this.data.action.tierId !== 0);
  }

  onTierOpen() {
    this.router.navigate(['/fiche-contact', this.data.action.tierId]);
    this.dialogRef.close(false);
  }

  onModalMinimize(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  openNoteAudio(): void {

  }

  /* Documents */
  onOpenDocument(document: DocumentModel): void {

    this.document = Object.assign({}, document);

    this.modalsService.openDocument(this.document).subscribe(result => {
      this.result = result;
      this.data.result = result;
      if (this.result) {
        this.actionService.get(this.data.action.id).subscribe((a: ActionModel) => {
          this.data.action = a;
        });
        this.getDocumentsByAction();
      }
    });
  }

  getDocumentsByAction() {
    this.documentService.getFromAction(this.data.action.id).subscribe((documents: DocumentModel[]) => {
      this.documents = documents;
      this.data.action.documents = documents;
    });
  }

}
