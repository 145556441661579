<div class="col-md-6">
  
  <div style="display: flex;  align-items: center;">
    <h4 style="display: flex; align-items: center; padding-left: 0px !important;" class="col-md-4">
      Sélectionnez un tiers 
      <div *ngIf="tierRequired" style="color:rgb(230, 126, 29)"> * </div>
    </h4>
    <div class="col-md-4">
      <button mat-stroked-button color="success" type="button" (click)="onAddTier()">  
        <mat-icon >add</mat-icon> Créer un tiers 
      </button>
    </div>
      
    
  </div>
  <table mat-table matSort (matSortChange)="trieTab($event)"   matSortActive="Similarity" matSortDirection="asc" 
    matSortDisableClear [dataSource]="matDataSource">
    <caption></caption>
    <!-- Position Column -->
    <ng-container matColumnDef="Similarity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Indice de<br>confiance</th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div >
          {{element.similarity * 100 | number:'1.1-2'}}
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="SmlInter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Similarité Contact</th>
      <td mat-cell *matCellDef="let element" 
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div>
          {{element.smlInter | number:'1.1-2'}}
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="SmlAd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Similarité Adresse</th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div>
          {{element.smlAd | number:'1.1-2'}}
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="TypeTier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type tiers</th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div title="{{element.typeTier}}">
          {{element.typeTier }}
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Libellé </th>
      <td mat-cell *matCellDef="let element" title="{{element.libelle}}"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div>
          {{element.libelle | truncate:50 }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="IdentifiantJason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Identifiant Jason </th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div *ngIf="element.identifiantJason" class="div-client">
          {{element.identifiantJason | truncate:35}}  
          <mat-icon matRipple (click)="copyclip(element.identifiantJason, $event)" inline="true" matTooltip="copier dans le presse-papiers">content_copy</mat-icon>
        </div>
        <div *ngIf="!element.identifiantJason" class="div-prospect">
          Prospect
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="CodePostal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Postal </th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div >
          {{element.tier.codePostal | truncate:35}}
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="TVA">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TVA</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div>
          {{element.tva}}
        </div>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="Bloque">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bloqué sur Jason</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.tier.id === selectedAdressesTier.id}">
        <div title="{{element.bloque}}">
          <strong *ngIf="element.bloque == false"> <mat-icon style="color:seagreen">check_circle</mat-icon>
          </strong>
          <strong *ngIf="element.bloque == true"> <mat-icon style="color:rgb(230, 126, 29)">warning</mat-icon></strong>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="actions">Actions</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{selected: element === selectedAdressesTier}">
          
          <div class="btn-container">
            <button  mat-mini-fab class="tableau-btn" color="basic" title="Ouvrir le tiers" (click)="openEvent(element.tier);$event.stopPropagation();" aria-label="Ouvrir le tiers">
              <mat-icon class="tableau-mat-icon" >open_in_new</mat-icon>
            </button>
            <button  *ngIf="element.countDemande > 0" mat-mini-fab class="tableau-btn" color="basic" title="Historique des demandes du tiers" (click)="openHistoriqueDemandesTiers(element.tier);$event.stopPropagation();" aria-label="Historique des demandes du tiers">
              <mat-icon class="tableau-mat-icon" >folder_open</mat-icon>
            </button>
            <strong *ngIf="element.bloque == true"> <mat-icon style="color:rgb(230, 126, 29)">warning</mat-icon></strong>
          </div> 
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumnsTier"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsTier;" (click)="selectTier($event, row.tier)"></tr>
  </table>
  <mat-paginator class="paginator" [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 50]" (page)="pageEvent=changePage($event)">
    </mat-paginator>
</div>

<div class="col-md-6">
  <div class="row body-class">
    <app-tier-adresses  *ngIf="simpleQuerySiteGeo.entry"
      [tierFilter]="tierFilter" 
      [adresseRequired]="adresseRequired"
      [selectedAdresseDuplique]="selectedSiteGeo"
      [selectedAdressesTier]="selectedAdressesTier" 
      [simpleQuerySiteGeo]="simpleQuerySiteGeo"
      [isInCreateTiersModal]="isInCreateTiersModal"
      (selectAdresseTiers)="selectAdresseTiers($event)"></app-tier-adresses>
      
  </div>
  <div class="row  body-class"> 
    <app-list-interlocuteurs *ngIf="simpleQueryInterlocuteur.entry"
      id="list-interlocuteurs"
      [tierFilter]="tierFilter" 
      [contactRequired]="contactRequired"
      [selectedInterlocDuplique]="selectedInterlocuteur"
      [selectInterlocuteurTier]="selectInterlocuteurTier"
      [simpleQueryInterlocuteur]="simpleQueryInterlocuteur"
      [isInCreateTiersModal]="isInCreateTiersModal"
      (selectedInterlocTiers)="selectedInterlocTiers($event)"></app-list-interlocuteurs>
     
  </div>
</div>
