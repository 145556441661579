import { SocieteModel } from './societe.model';
import { EntiteSARPModel } from './entite-sarp.model';
import { DocumentModel } from '../document/document.model';

export class AgenceModel extends EntiteSARPModel {
    public societe?: SocieteModel;
    public societeId: number;
    public adresse: string;
    public complementAdresse: string;
    public codePostal: string;
    public ville: string;
    public telephone: string;
    public email: string;
    public slogan: string;
    public logoId?: number;
    public logo?: DocumentModel;
    public siret?: string;
    public naf?: string;
    public rcs: string;
    public certificationId1?: number;
    public certification1?: DocumentModel;
    public certificationId2?: number;
    public certification2?: DocumentModel;
    public urlZoneId?: number;
    public codePraxedo: string;
    public urlZone?: DocumentModel;
    public destinataires: string;
    public lienWebHook: string;

}
