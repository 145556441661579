<mat-icon class="col-md-12" class="close-button" (click)="onModalCancel()" color="primary">close</mat-icon>

<h5>Ajout d'un filtre personnalisé</h5>
<div class="col-md-12">
  <mat-divider></mat-divider>
</div>
<form class="row" (ngSubmit)="submit()" >
  <div class="col-md-12">
    <mat-form-field class="full-width">
      <mat-label>Libelle du filtre</mat-label>
      <input matInput required [formControl]="libelleControl">
      <mat-error *ngIf="libelleControl.hasError('required')">
        Le libelle du filtre est obligatoire
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-sm-6">
    <button mat-flat-button color="primary" type="submit">
      <mat-icon>save</mat-icon> Enregistrer
    </button>
  </div>
</form>
<mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress"></mat-progress-bar>