import { ChangeDetectorRef, Injector, NgZone, OnDestroy, Optional, SimpleChanges, ViewChild} from '@angular/core';
import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { PrestationShared } from 'src/app/modules/shared/prestationShared';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { GmapsSiteGeoModel } from 'src/app/modules/core/shared/models/gmapsSiteGeo.model';
import { ActiviteModel } from 'src/app/modules/core/shared/models/prestation.model';
import { SiteGeoModel } from 'src/app/modules/core/shared/models/site-geo.model';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { startWith, map,filter } from 'rxjs/operators';
import { SiteGeoService } from 'src/app/modules/core/services/site-geo.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { GmapsService } from 'src/app/modules/shared/gapiServices/gmaps.service';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';
import { SessionService } from 'src/app/modules/shared/session.service';
import { DevisInfo } from 'src/app/modules/shared/devisInfo';
import { InterlocuteurModel } from 'src/app/modules/core/shared/models/interlocuteur.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { filterTierDTO } from 'src/app/modules/core/shared/dtos/filterTierDTO';
import { tierPgQueryResponse } from 'src/app/modules/core/shared/dtos/tierPgQueryResponse';
import { TierPgResult } from 'src/app/modules/core/shared/dtos/tierPgResult';
import { TierService } from 'src/app/modules/core/services/tier/tier.service';
import { ModalSelectTier } from 'src/app/modules/core/components/menu/modalSelectTier/modal-select-tier';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { DevisService } from '../../services/devis.service';
import { DevisDTO } from '../../DTOs/devisDTO';
import { LigneClient, TablePrestationClientModel } from '../../models/table-prestation-client.model';
import { LigneChantier, SousLigneChantier, TablePrestationChantierModel } from '../../models/table-prestation-chantier.model';
import { ProtocoleModel } from 'src/app/modules/core/shared/models/protocole.model';
import { ProtocoleDTO } from 'src/app/modules/core/shared/dtos/protocoleDTO';
import { ProtocoleService } from 'src/app/modules/core/services/protocole.service';
import * as _ from 'lodash';    
import { DescriptionActiviteService } from 'src/app/modules/core/services/description-activite.service';
import { ModalSelectionTiersFactureComponent } from 'src/app/modules/core/components/demande/modal-selection-tiers-facture.component';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { PrestationModel } from 'src/app/modules/prestation/models/prestation.model';
import { PriseAppelService, sharedDemande } from 'src/app/modules/core/services/prise-appel.service';
import { InterlocuteurService } from 'src/app/modules/core/services/interlocuteur.service';
import { ModalSpinnerComponent } from 'src/app/modules/shared/components/modals/modal-spinner/modal-spinner.component';
import { CiviliteService } from 'src/app/modules/core/services/civilite.service';
import { CiviliteModel } from 'src/app/modules/core/shared/models/civilite.model';
import { PrestationService } from 'src/app/modules/prestation/services/prestation.service';
import { AutocompleteVilleComponent } from 'src/app/modules/shared/components/autocomplete-ville/autocomplete-ville.component';
import { AutocompleteCodePostalComponent } from 'src/app/modules/shared/components/autocomplete-code-postal/autocomplete-code-postal.component';
import { CityData } from 'src/app/modules/core/shared/models/city-data.model';
import { BasicTierArrayComponent } from 'src/app/modules/shared/components/array/basic.tier.array.component';

@Component({
  selector: 'app-add-devis-rapide',
  templateUrl: './add-devis-rapide.component.html'
})

export class AddDevisRapideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tabIndex: number;
  @Input() devisDTO: DevisDTO;
  @Output() updateDevis = new EventEmitter<DevisInfo>();
  currentUser: UserModel;
  agenceId: number;
  public tva: number;
  demande: DemandeModel = new DemandeModel();
  prestations: ActiviteModel[] = [];
  prestation: ActiviteModel = new ActiviteModel();
  prestationId: number;

  devis: DevisInfo = new DevisInfo();
  prestationCtrl = new UntypedFormControl({value:null, disabled: true});
  filteredPrestations: Observable<string[]>;
  adresseChanged = false;
  allPrestations: BehaviorSubject<string[]> = new BehaviorSubject([]);
  prestationListSubj: Subject<PrestationModel[]> = new Subject();
  realisateur: UserModel = null;
  realisateurId: number;
  users: UserModel[] = [];
  agences: AgenceModel[] = [];
  gmapsAdresses: GmapsSiteGeoModel[] = [];
  adresseControl = new UntypedFormControl();
  codePostalControl = new UntypedFormControl();
  filteredAdresses: string[];
  adressesString: string[] = [];
  protocoleLoading = false;
  protocole: ProtocoleModel;

  @ViewChild("addresstext") addresstext: any;
  @ViewChild(AutocompleteVilleComponent) autocVille: AutocompleteVilleComponent;
  @ViewChild(AutocompleteCodePostalComponent) autocCode: AutocompleteCodePostalComponent;

  @ViewChild(BasicTierArrayComponent) tableTier: BasicTierArrayComponent;

  selectedCity: CityData = new CityData();

  IsWait: boolean = false;
  toolTipAdresses: string = "";

  tierWithPgDataSource: TierPgResult[] = [];
  pgQueryResponse = new tierPgQueryResponse();

  isFormEmpty = true;

  dataSource = [];
  length: number;
  pageSize = 10;
  pageIndex: number;

  chemin = 'api/Document/getDoc?idDoc=';

  result: any;
  panelOpenState: boolean;
  loading: boolean;
  selectedLink: string;
  tiersFilter = new filterTierDTO();

  simpleQueryResponse = new SimpleQueryResponse<TierModel>();
  simpleQuerySiteGeo = new SimpleQuery<SiteGeoModel>();

  selectedTiersDuplique: TierModel = new TierModel();
  selectedTiersDevis : TierModel = new TierModel();
  selectedInterlocDevis : InterlocuteurModel = new InterlocuteurModel ();
  selectedSiteGeoDevis : SiteGeoModel = new SiteGeoModel();

  searchedInterlocDevis : InterlocuteurModel = new InterlocuteurModel ();
  searchedSiteGeoDevis : SiteGeoModel = new SiteGeoModel();
  addedTiers : TierModel = new TierModel();

  tierFacture : TierModel = new TierModel();

  isInterlocSelectedNull : boolean = true
  isSiteGeoSelectedNull : boolean = true

  subscriptionTierRecherche: Subscription;
  subscriptionSelectedTiersFacture: Subscription;
  subscriptionSearchOrigin: Subscription;
  subscriptionTiersResult: Subscription;
  subscriptionSharedDemande: Subscription;
  sharedDemande : sharedDemande = new sharedDemande();

  tierRechercheForm : UntypedFormGroup
  
  groupeDevis : UntypedFormGroup;

  timeTravel: string = "";
  distanceTravel: string = "";


  private modalsService: ModalsService;

  villeBounds;
  autocompleteAdresse: google.maps.places.Autocomplete;
  currentRoute : any;

  isPrestationValid = false;
  civilites: CiviliteModel[] = [];
  gmapsSubscriber: any;

  constructor( public dialog: MatDialog,
    @Optional() public dialogReftiers: MatDialogRef<ModalSelectTier>,
    private sessionService: SessionService,
    private zone: NgZone
    , private interlocuteurService: InterlocuteurService
    , private devisService: DevisService
    , private agenceService: AgenceService
    , private userService: UserService
    , private siteGeoService: SiteGeoService
    , private prestationService: PrestationService
    , private protocoleService: ProtocoleService
    , public tiersService: TierService
    , private cdRef:ChangeDetectorRef
    , private demandeService: DemandeService
    , private IntercomToastrService: IntercomToastrService
    , private descriptionService: DescriptionActiviteService
    , private router: Router
    , injector: Injector
    , private civiliteService: CiviliteService
    , private fbDevis: UntypedFormBuilder
    , private priseAppelService : PriseAppelService
    , private toastrLoadingService: ToastrLoadingService
    , private gmapsService: GmapsService
    ) { 
      this.modalsService = injector.get('ModalsService');
      this.currentRoute = this.router.url;
      this.groupeDevis  = this.fbDevis.group({
        prestation: [null],
        commentaireDemande: [null],
        commentaireDevis: [null],
        objet: [null, Validators.compose([Validators.required])]
      });

      this.tierRechercheForm  = this.fbDevis.group({
        raisonSocial: [null],
        idJason: [null],
        address: [null],
        ville: [null],
        libelle: [null],
        codePostal: [null, Validators.compose([Validators.maxLength(5)])],
        nomInterlocuteur: [null],
        prenomInterlocuteur: [null],
        telInterlocuteur: [null],
      });

      this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
        this.civilites = civs;
      });

      // get current user
      this.sessionService.currentUser.subscribe(user => {
      // Récuperation des users
      this.userService.getAll().subscribe((usrs: UserModel[]) => {
        this.users = usrs;
      });
      if(user){
        this.currentUser = user;
      }
      // Récuperation des agences
      this.agenceService.getAll().subscribe((agences: AgenceModel[]) => {
        this.agences = agences;
        if(user){
          //this.devis.agence = this.agences.find(ag => ag.id === user.agenceId);
          this.devis.agenceCreateur = this.agences.find(ag => ag.id === user.agenceId);
        }
      });
    
      // Récuperation des prestations
      /*this.descriptionService.getActiviteDevisFromAgence(this.agenceId).subscribe((prestas: ActiviteModel[]) => {
        this.prestations = prestas;
        if (this.prestations.find(pres => pres.id === this.prestation.id)) {
          this.prestation.libelle = this.prestations.find(pres => pres.id === this.prestation.id).libelle;
        }
        var allPrestations = [];
        for (let i = 0; i < prestas.length; i++) {
            allPrestations.push(prestas[i].libelle);
        }
        allPrestations.sort();
        this.allPrestations.next(allPrestations);
        this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
          startWith(null),
          map((prestation: string | null) => prestation ? this._filterForPrestationsAuto(prestation) : this.allPrestations.value));
      });*/
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des infos utilisateurs")
      );
  }


  ngOnChanges(changes: SimpleChanges): void {
    // initialisation du devis dans le cas d'une duplication
    if(this.devisDTO && changes.devisDTO && changes.devisDTO.currentValue !== changes.devisDTO.previousValue){
      this.initDevisFromDTO(this.devisDTO);
      const dialog = this.dialog.open(ModalSpinnerComponent);
      this.prestation.libelle = this.devisDTO.libelleActivite;
      this.prestationCtrl.setValue(this.prestation.libelle);
      this.isPrestationValid = true;
      this.realisateurId = this.devisDTO.idRealisateur;
      this.agenceId = this.devisDTO.agenceId;
      this.prestationCtrl.setValue(this.devisDTO.libelleActivite);
      this.prestationId =this.devisDTO.idActivites ? Number(this.devisDTO.idActivites.split(",")[0]) : null;
      this.devis.idActivite = this.prestationId;
      this.groupeDevis.controls['objet'].setValue(this.devisDTO.objet);
      this.demande.objet = this.devisDTO.objet;
      this.groupeDevis.controls['prestation'].setValue(this.devisDTO.libelleActivite);
      this.groupeDevis.controls['commentaireDemande'].setValue(this.devisDTO.commentaireDemande);
      this.groupeDevis.controls['commentaireDevis'].setValue(this.devisDTO.demandeClient);
      this.groupeDevis.updateValueAndValidity();
      this.tiersFilter = new filterTierDTO();

      // selected tiers : 
      if (this.devisDTO.tierId) {
        this.tiersService.get(this.devisDTO.tierId).subscribe(tiers => {
          this.selectedTiersDuplique = tiers;
          this.selectedTiersDevis = tiers;
          this.tiersFilter.tiers.libelle = tiers.libelle;
          this.cdRef.detectChanges();
          // selected site geo : 
          if(this.devisDTO.siteGeoId){
            this.siteGeoService.get(this.devisDTO.siteGeoId).subscribe((site: SiteGeoModel) => {
              this.selectedSiteGeoDevis = site;
              this.tiersFilter.siteGeo.Adresse = site.adresse;
              this.tiersFilter.siteGeo.CodePostal = site.codePostal;
              this.tiersFilter.siteGeo.Ville = site.ville;
              this.isSiteGeoSelectedNull = false
              this.cdRef.detectChanges();
              // selected contact :
              if(this.devisDTO.interlocuteurId){
                this.interlocuteurService.get(this.devisDTO.interlocuteurId).subscribe((contact: InterlocuteurModel) => {
                  this.selectedInterlocDevis = contact;
                  this.tiersFilter.interlocuteur.Nom = contact.nom;
                  this.tiersFilter.interlocuteur.Prenom = contact.prenom;
                  this.isInterlocSelectedNull = false
                  this.cdRef.detectChanges();
                  if(this.devisDTO.tierFactId){
                    // selected tiers facturé : 
                    this.tiersService.get(this.devisDTO.tierFactId).subscribe(tiers => {
                      this.tierFacture = tiers;
                      dialog.close();
                      this.cdRef.detectChanges();
                    });
                  }else{
                    dialog.close();
                  }
                  this.onSubmitForm2();
                });
              }else{
                dialog.close();
                this.onSubmitForm2();
              }
            });
          }else{
            dialog.close();
            this.onSubmitForm2();
          }
        });
      } else {
        dialog.close();
      }
    }
  }

  ngOnInit(): void {
    this.initTitleDemande();
    let oldpageSize = 10;
    let oldpageOrder = "Similarity";
    let oldPageDesc = true;

    this.tiersFilter = new filterTierDTO();
    this.tiersFilter.pageNumber = 0;
    this.tiersFilter.pageSize = oldpageSize;
    this.tiersFilter.order = oldpageOrder;
    this.tiersFilter.descending = oldPageDesc;

    this.simpleQuerySiteGeo.entry = new SiteGeoModel();

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    this.groupeDevis.valueChanges.subscribe(value => {
      //this.demande.commentaire = this.groupeDevis.value.commentaireDevis; 
      this.demande.commentaire = this.groupeDevis.value.commentaireDemande;
      
      let matchingDemande = this.sharedDemande.commentaire == this.groupeDevis.value.commentaireDemande;
      if(!matchingDemande){
        this.sharedDemande.commentaire = this.groupeDevis.value.commentaireDemande;
        this.priseAppelService.updateSharedDemande(this.sharedDemande);
      } 
    });
    this.tierRechercheForm.valueChanges.subscribe(formTierValue => {

      this.tierRechercheForm.get("telInterlocuteur").patchValue(this.formatPhoneNumper(formTierValue.telInterlocuteur), { emitEvent: false });
      formTierValue.telInterlocuteur = this.tierRechercheForm.value.telInterlocuteur;

      let siteGeo = new SiteGeoModel()
      siteGeo.libelle = formTierValue.libelle
      siteGeo.adresse = formTierValue.address
      siteGeo.ville = formTierValue.ville
      siteGeo.codePostal = formTierValue.codePostal

      let interloc = new InterlocuteurModel()
      interloc.nom = formTierValue.nomInterlocuteur
      interloc.prenom = formTierValue.prenomInterlocuteur
      interloc.telPrincipal = formTierValue.telInterlocuteur
      // lors des changements du formulaire, nous préparons ici la mise a jour des filtres
      this.tiersFilter = Object.assign({}, this.tiersFilter);
      this.tiersFilter.siteGeo.Adresse = this.tierRechercheForm.value.address;
      this.tiersFilter.siteGeo.CodePostal =this.tierRechercheForm.value.codePostal;
      this.tiersFilter.siteGeo.Ville = this.tierRechercheForm.value.ville;
      this.tiersFilter.siteGeo.Libelle = this.tierRechercheForm.value.libelle;
      this.tiersFilter.interlocuteur.Nom = this.tierRechercheForm.value.nomInterlocuteur;
      this.tiersFilter.interlocuteur.Prenom = this.tierRechercheForm.value.prenomInterlocuteur;
      this.tiersFilter.interlocuteur.TelPrincipal = this.tierRechercheForm.value.telInterlocuteur;
      this.tiersFilter.tiers.libelle = this.tierRechercheForm.value.raisonSocial;
      this.tiersFilter.tiers.identifiantJason = this.tierRechercheForm.value.idJason;
      
      this.isSiteGeoSelectedNull = Object.values(this.selectedSiteGeoDevis).every(x => x === null || x === '')
      this.isInterlocSelectedNull = Object.values(this.selectedInterlocDevis).every(x => x === null || x == '')
      this.initTitleDemande();
      this.cdRef.detectChanges();
    });

    this.subscriptionTierRecherche = this.priseAppelService.currentTierRechercheForm.subscribe(sharedTierForm => {
        if(this.router.url === '/prise-appel' && !_.isEqual(this.tierRechercheForm.value, sharedTierForm.value)){
          this.tierRechercheForm.patchValue(sharedTierForm.value);
          let cp =this.tierRechercheForm.get('codePostal').value;
          let ville = this.tierRechercheForm.get('ville').value;
          let data = new CityData();
          data.codesPostaux = [cp];
          data.nom = ville;
          this.selectedCity = data;
          // this.onSubmitForm(false);
          const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
          const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
          const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');
          this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo
        }
    }, error =>  
    this.IntercomToastrService.error("Erreur durant le chargement des tiers")
  );


    this.subscriptionTiersResult = this.priseAppelService.currentTiersResult.subscribe(result => {
      this.tierWithPgDataSource = result.tiers;
      this.pgQueryResponse = result;
      this.length = result.count;
      this.loading = false;
      this.cdRef.detectChanges();
      if (this.length === 0 || this.length == undefined) {
        const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
        const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
        const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');
    
        this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo
        if(this.length != undefined){
          this.IntercomToastrService.warning(
            "Oups : " + "Aucun résultat pour votre recherche"
          );
        }
      } else {
        this.isFormEmpty = false;
      }
    }, error =>  
    this.IntercomToastrService.error("Erreur durant le chargement des tiers")
  );

    this.subscriptionSelectedTiersFacture = this.priseAppelService.currentSelectedTiersFacture.subscribe(sharedSelectedTiersFacture => {
      if(!_.isEqual(this.tierFacture,sharedSelectedTiersFacture)  && this.router.url === '/prise-appel'){
        this.tierFacture = sharedSelectedTiersFacture
      }
    }, error =>  
    this.IntercomToastrService.error("Erreur durant le chargement du tiers facturé")
  );

  this.subscriptionSharedDemande = this.priseAppelService.currentSharedDemande.subscribe(sharedDemande => {
    if( this.router.url === '/prise-appel'  && this.sharedDemande.commentaire != sharedDemande.commentaire) {
      this.sharedDemande = Object.assign({},sharedDemande);
      this.groupeDevis.patchValue({
        commentaireDemande : sharedDemande.commentaire,
      });
      this.demande.commentaire = sharedDemande.commentaire;
    }
  }, error =>  
    this.IntercomToastrService.error("Erreur durant le chargement des tiers")
  );
  }

  ngAfterViewInit() {
    //After page load, initialize google autocomplete
    this.getPlaceAutocomplete();
    this.gmapsSubscriber = combineLatest([
      this.tierRechercheForm.get('ville').valueChanges,
      this.tierRechercheForm.get('codePostal').valueChanges])
    .pipe(filter(([a,b]) => !a && !b))
    .subscribe(_ => {
      this.villeBounds = undefined;
      this.autocompleteAdresse.setBounds(this.villeBounds);
    });
    //After page load, and start listening for changes in adress form
  }

  ngOnDestroy() {
    if(this.currentRoute == '/prise-appel'){
      this.gmapsSubscriber.unsubscribe();      
      this.priseAppelService.updateStartSearch("")
      this.subscriptionTiersResult.unsubscribe();
      this.subscriptionTierRecherche.unsubscribe();
      this.subscriptionSharedDemande.unsubscribe();
    }
  }

  initTitleDemande() {
    this.demande.objet = 'Demande devis : ';
    if (this.demande.typeDemande) {
      this.demande.objet = this.demande.typeDemande.libelle ? this.demande.typeDemande.libelle + ' : ' : 'Demande devis : ';
    }
    this.demande.objet += this.demande.tier?.libelle ? this.demande.tier.libelle + ' ' : '';
    if (this.demande.tier) {
      this.demande.objet += this.demande.tier.libelle !== this.demande.nomInterlocuteur &&
      this.demande.tier.libelle !== this.demande.nomInterlocuteur + ' ' + this.demande.prenomInterlocuteur && 
       ((this.demande.nomInterlocuteur) !== null) ?
      (((this.demande.prenomInterlocuteur) !== null) ?
        '- ' +  this.demande.nomInterlocuteur + ' ' + this.demande.prenomInterlocuteur :
        '- ' +  this.demande.nomInterlocuteur) :  '';
    } else {
      this.demande.objet +=   ((this.demande.nomInterlocuteur) !== null) ?
      (((this.demande.prenomInterlocuteur) !== null) ?
        '- ' +  this.demande.nomInterlocuteur + ' ' + this.demande.prenomInterlocuteur :
        '- ' +  this.demande.nomInterlocuteur) :  '';
    }
    /// prestations
    if (this.prestation && this.prestation.libelle) {
      this.demande.objet += ' (';
      this.demande.objet += this.prestation.libelle;
      this.demande.objet += ')';
    }
    this.groupeDevis.controls['objet'].setValue(this.demande.objet);
    this.groupeDevis.updateValueAndValidity();
  }

  formatPhoneNumper(input: string){
    let tel = null;
    if (input) {
      input = input.slice(0, 14);
      const tel1 = input.replace(/[^\d]/g, '');
      let tel2 = '';
      for (let i = 0; i < tel1.length; i = i + 2 ) {
        tel2 += tel1.substring(i, i + 2) + '.';
      }
      tel = tel2.substring(0, tel2.length - 1);
    }
    return(tel);
  }

  onUpdateRealisateurId(realisateurId: number) {
    this.allPrestations.next([]);
    if (realisateurId) {
      var realisateur = this.users.find(user => user.id === realisateurId);
      this.realisateurId = realisateurId;
      var agence = this.agences.find(ag => ag.id === realisateur.agenceId);
      this.devis.idRealisateur = realisateurId;
      this.realisateur = realisateur;
      this.devis.agence = agence;
      this.agenceId = realisateur ? realisateur.agenceId : null;
      this.devis.agenceId = realisateur ? realisateur.agenceId : null;
      if (this.agenceId) {
        this.descriptionService.getActiviteDevisFromAgence(this.agenceId).subscribe((prestas: ActiviteModel[]) => {
          if(prestas && prestas.length > 0){
            this.prestationCtrl.enable();
            this.prestations = prestas;
            if (this.prestations.find(pres => pres.id === this.prestation.id)) {
              this.prestation.libelle = this.prestations.find(pres => pres.id === this.prestation.id).libelle;
              this.prestationCtrl.setValue(this.prestation.libelle);
            }
            var allPrestations = [];
            for (let i = 0; i < prestas.length; i++) {
                allPrestations.push(prestas[i].libelle);
            }
            allPrestations.sort();
            this.allPrestations.next(allPrestations);
            this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
              startWith(null),
              map((prestation: string | null) => prestation ? this._filterForPrestationsAuto(prestation) : this.allPrestations.value)
            );
          }else{
            this.IntercomToastrService.warning("Aucune activité pour ce réalisateur");
            this.prestationCtrl.disable();
          } 
        });
      }
      if(this.devis.idActivite){
        this.descriptionService.getFromDevis(this.agenceId, this.devis.idActivite)
          .subscribe(description => {
            this.devis.description = description;
          }, error =>  
            this.IntercomToastrService.error("Erreur durant le chargement des infos activité")
          );
      }
    }else {
      this.devis.idRealisateur = null;
      this.realisateur = null;
      this.agenceId = null;
      this.devis.agence = null;
      this.realisateurId = null;
      this.prestationCtrl.disable();
    }
    this.prestationCtrl.setValue(null);
    this.prestation = new ActiviteModel();
    this.prestationCtrl.setValue(this.prestation.libelle);
    this.prestationId = null;
    this.devis.idActivite = this.prestationId;
    this.groupeDevis.controls['prestation'].setValue("");
    this.groupeDevis.updateValueAndValidity();
    this.getTime();
    this.onProtocolCalled();
    this.calculer();
  }

  onEditTiersFacture(){
    const dialogRef = this.dialog.open(ModalSelectionTiersFactureComponent, {
      disableClose: true,
      panelClass: 'tiers-facture-dialog',
      height:"95%",
      data :{
        tier : this.tierFacture,
        result : this.result,
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.result) {
        this.tierFacture = data.tier;      
        this.priseAppelService.updateTierselectedTiersFacture(this.tierFacture)  
      }
    });
  }

  scrollTo(className: string):void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    if(element){
      element.scrollIntoView({ behavior: "auto", block: "center", inline: "center"  });
    }
  }

  clearForm(value) {
    switch (value) {
      case "Interlocuteur":
        this.tierRechercheForm.patchValue({
          nomInterlocuteur: null,
          prenomInterlocuteur: null,
          telInterlocuteur: null,
        });
        break;
      case "Adresse":
        // Clear autocomplete Bounds
        this.villeBounds = null;
        this.autocompleteAdresse.setBounds(this.villeBounds);
        this.selectedCity = new CityData();
        this.selectedCity.codesPostaux = [null];
        this.selectedCity = Object.assign({}, this.selectedCity);
        this.autocCode.codePostal = null;
        this.tierRechercheForm.patchValue({
          address: null,
          ville: null,
          libelle: null,
          codePostal: null,
        });
        break;
      case "Tiers":
        this.tierRechercheForm.patchValue({
          raisonSocial: null,
          idJason: null,
        });
        break;
      default:
        break;
    }
    this.onSubmitForm();
  }

  onSubmitForm() {
    this.tierWithPgDataSource = [];
    this.pgQueryResponse = new tierPgQueryResponse();
    this.tiersFilter.pageNumber = 0;

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    if (!isEmptySiteGeo||!isEmptyTier||!isEmptyInterloc) {
      this.priseAppelService.changeFilter(this.tiersFilter);
    }
    this.priseAppelService.updateTierRechercheForm(this.tierRechercheForm);
  }

  onSubmitForm2() {
    this.tierWithPgDataSource = [];
    this.pgQueryResponse = new tierPgQueryResponse();
    this.tiersFilter.pageNumber = 0;
    this.priseAppelService.changeFilter(this.tiersFilter);
    this.priseAppelService.updateTierRechercheForm(this.tierRechercheForm);
  }

  clearTrieAndSubmit() {
    if (this.tableTier?.sort) {
      this.tiersFilter.pageNumber = 0;
      this.tiersFilter.order = 'Similarity';
      this.tiersFilter.descending = true;
      this.tableTier.sort.active = 'Similarity';
      this.tableTier.sort.direction = 'desc';
      this.tableTier.simpleQuery.order = 'Similarity';
      this.tableTier.simpleQuery.descending = true;
      this.tableTier.sort.sort({ id: 'Similarity', start: 'desc', disableClear: false });
    }
    this.onSubmitForm();
  }

  searchResult(searchWord: any) {
    if (searchWord.length === 0) {
      this.IntercomToastrService.warning(
        "Oups : " + "Aucun résultat pour votre recherche"
      );
    }
    else{
      this.selectedTiersDevis = searchWord[0];
    }
  }
  
  /* Ajout d'une prestation avec selection */
  selectedPrestation(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    this.prestation = Object.assign({}, this.prestations.find(pres => pres.libelle.trim() === value.trim()));
    this.prestationCtrl.setValue(this.prestation.libelle);
    let lastPrestationId = this.prestationId;
    this.prestationId = this.prestations.find(pres => pres.libelle.trim() === value.trim()).id;
    if (lastPrestationId == this.prestationId && this.agenceId && this.prestationId) {
      this.prestationService.getFromActivite(this.agenceId, this.prestationId).subscribe((res: PrestationModel[]) => {
        if (!res || res.length == 0) {
          this.IntercomToastrService.warning("Aucun critére de calcul pour cette activité/agence");
          this.prestationParametre(true);
        }
      });
    }

    this.devis.idActivite = this.prestationId;
    this.initTitleDemande();
    if (this.devis.agenceId){
      this.descriptionService.getFromDevis(this.devis.agenceId, this.devis.idActivite)
        .subscribe(description => {
          this.devis.description = description;
        }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des prestations"));
    }
    this.calculer();
    this.onProtocolCalled();
    this.getTime();
  }

  private _filterForPrestationsAuto(value: string): string[] {
    const filterValue = value.toLowerCase();
    const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return this.allPrestations.value.filter(prestation => prestation.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
  }

  // Gestion du tableau tiers 
  pageChange(pageEvent: SimpleQuery<any>) {
    let idSearch: number;
    this.tiersFilter.pageNumber = pageEvent.pageNumber;
    this.tiersFilter.pageSize = pageEvent.pageSize;
    if (pageEvent.order) {
      this.tiersFilter.order = pageEvent.order;
    }
    if (pageEvent?.descending !== undefined && pageEvent?.descending !== null) {
      this.tiersFilter.descending = pageEvent.descending;
    }

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    if (!isEmptySiteGeo||!isEmptyTier||!isEmptyInterloc) {
      this.priseAppelService.changeFilter(this.tiersFilter);
    }
  }

  onTierOpen(tiers: TierModel) {
    if (!window.location.href.includes("prise-appel")) {
      this.router.navigate(["/fiche-contact", tiers.id]);
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/fiche-contact", tiers.id])
      );
      window.open(url, '_blank');    
    }
  }
  
  selectedTiers(event){
    this.demande.tierId = event.id;
    //this.demande.tier = event;
    this.selectedTiersDevis = event;
    this.demande.siteGeoId = null;
    this.selectedSiteGeoDevis = new SiteGeoModel();
    this.demande.siteGeoId = null;
    this.selectedInterlocDevis = new InterlocuteurModel();

    let interloc = new InterlocuteurModel();
    interloc.nom = this.tierRechercheForm.value.nomInterlocuteur;
    interloc.prenom = this.tierRechercheForm.value.prenomInterlocuteur;
    interloc.telPrincipal = this.tierRechercheForm.value.telInterlocuteur;

    let siteGeo = new SiteGeoModel();
    siteGeo.libelle = this.tierRechercheForm.value.libelle;
    siteGeo.adresse = this.tierRechercheForm.value.address;
    siteGeo.ville = this.tierRechercheForm.value.ville;
    siteGeo.codePostal = this.tierRechercheForm.value.codePostal;

    this.isSiteGeoSelectedNull = Object.values(this.selectedSiteGeoDevis).every(x => x === null || x === '')
    this.isInterlocSelectedNull = Object.values(this.selectedInterlocDevis).every(x => x === null || x === '')
    /*
    ne plus enregistrer les interlocuteurs ou contacts dans la demande / devis si ils ne sont pas sélectionné
    if(this.isInterlocSelectedNull){
        this.demande.nomInterlocuteur = this.tierRechercheForm.value.nomInterlocuteur
        this.demande.prenomInterlocuteur = this.tierRechercheForm.value.prenomInterlocuteur
        this.demande.telPrincipalInterlocuteur = this.tierRechercheForm.value.telInterlocuteur
        this.searchedInterlocDevis = interloc
    }

    if(this.isSiteGeoSelectedNull){
      this.demande.libelleAdresse = this.tierRechercheForm.value.libelle
      this.demande.adresse = this.tierRechercheForm.value.address
      this.demande.villeAdresse = this.tierRechercheForm.value.ville
      this.demande.codePostalAdresse = this.tierRechercheForm.value.codePostal
      this.searchedSiteGeoDevis = siteGeo
    }
    */

    if (event.tierFactureId) {
      this.tiersService.get(event.tierFactureId).subscribe((tierFactRes: TierModel) => {
        this.tierFacture = tierFactRes;
        if (this.tierFacture) {
          this.demande.libelleAdresseFact = "Facturation"
          this.demande.adresseFact = this.tierFacture.adresseFact
          this.demande.villeAdresseFact = this.tierFacture.villeFact
          this.demande.codePostalAdresseFact = this.tierFacture.codePostalFact
        }
        this.searchedInterlocDevis = interloc
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement du tiers facturé")
      );
    }

    this.getTime();
    this.onProtocolCalled();
    this.cdRef.detectChanges();

  }

  selectedAdress(event: SiteGeoModel){
    if(!Object.values(event).every(x => x === null || x === '')){
      this.demande.siteGeoId = event.id
      this.selectedSiteGeoDevis = event
      this.isSiteGeoSelectedNull = false

      this.demande.libelleAdresse = event.libelle
      this.demande.adresse = event.adresse
      this.demande.villeAdresse = event.ville
      this.demande.codePostalAdresse = event.codePostal

      this.demande.emailAdresse = event.email
      this.demande.commentaireAdresse = event.commentaire
      this.demande.complementAdresse = event.complementAdresse
      this.demande.gpsAdresse = event.gps
      this.demande.telephoneAdresse = event.telephone
    } else {
      this.demande.siteGeoId = null
      this.selectedSiteGeoDevis = new SiteGeoModel()
      this.isSiteGeoSelectedNull = true

      let siteGeo = new SiteGeoModel()
      siteGeo.libelle = this.tierRechercheForm.value.libelle
      siteGeo.adresse = this.tierRechercheForm.value.address
      siteGeo.ville = this.tierRechercheForm.value.ville
      siteGeo.codePostal = this.tierRechercheForm.value.codePostal

      this.demande.libelleAdresse = this.tierRechercheForm.value.libelle
      this.demande.adresse = this.tierRechercheForm.value.address
      this.demande.villeAdresse = this.tierRechercheForm.value.ville
      this.demande.codePostalAdresse = this.tierRechercheForm.value.codePostal
      this.searchedSiteGeoDevis = siteGeo
    }

    this.getTime();
    this.onProtocolCalled();
    this.cdRef.detectChanges();

  }

  selectedInterloc(event){
    if(!Object.values(event).every(x => x === null || x === '')){
      this.selectedInterlocDevis = event
      this.isInterlocSelectedNull = false
      this.demande.nomInterlocuteur = event.nom
      this.demande.prenomInterlocuteur = event.prenom
      this.demande.emailInterlocuteur = event.email
      this.demande.roleInterlocuteur = event.role
      this.demande.civiliteInterlocuteurId = event.civiliteId;
      if(event.civiliteId){
        this.demande.civiliteInterlocuteur = this.civilites.find(c => c.id === event.civiliteId).libelle;
      }
      if (event.civilite) {
        this.demande.civiliteInterlocuteur = event.civilite.libelle
      }
      this.demande.commentaireInterlocuteur = event.commentaire
      this.demande.telSecondaireInterlocuteur = event.telSecondaire
      this.demande.idJasonInterlocuteur = event.idJason
      this.demande.telPrincipalInterlocuteur = event.telPrincipal
    }else{
    this.demande.siteGeoId = null
    this.selectedInterlocDevis = new InterlocuteurModel()
    this.isInterlocSelectedNull = true
      
    let interloc = new InterlocuteurModel()
    interloc.nom = this.tierRechercheForm.value.nomInterlocuteur
    interloc.prenom = this.tierRechercheForm.value.prenomInterlocuteur
    interloc.telPrincipal = this.tierRechercheForm.value.telInterlocuteur
    this.demande.nomInterlocuteur = this.tierRechercheForm.value.nomInterlocuteur
    this.demande.prenomInterlocuteur = this.tierRechercheForm.value.prenomInterlocuteur
    this.demande.telPrincipalInterlocuteur = this.tierRechercheForm.value.telInterlocuteur
    this.searchedInterlocDevis = new InterlocuteurModel()
    this.searchedInterlocDevis = interloc
  

    }
    this.cdRef.detectChanges();

  }
  onProtocolCalled() {
    let idSearch: number = this.toastrLoadingService.newSearch();
    this.loading = true;
    const protocoleDTO = new ProtocoleDTO();
    protocoleDTO.tierId = this.demande.tierId;
    protocoleDTO.agenceId = this.sessionService.currentUserValue.agenceId;
    protocoleDTO.siteGeoId = this.demande.siteGeoId;
    protocoleDTO.ville = this.demande.villeAdresse;
    protocoleDTO.codePostal = this.demande.codePostalAdresse;
    if (this.prestation) {
        protocoleDTO.prestationId = this.prestation.id;
    } else {
      protocoleDTO.prestationId = null;
    }
    this.protocoleLoading = true;
    this.protocoleService.GetFromObjet(protocoleDTO).subscribe((response: ProtocoleModel[]) => {
      this.toastrLoadingService.endSearch(idSearch);
      this.protocoleLoading = false;
      if (response.length > 0 && response[0]) {
        if (this.protocole) {
          if (response[0].id != this.protocole.id)
            this.IntercomToastrService.success('Nouveau protocole trouvé.');
        } else {
          this.IntercomToastrService.success('Un protocole trouvé.');
        }
        this.protocole = response[0];
      } else {
        if (this.protocole) {
          this.IntercomToastrService.warning('Pas de protocole trouvé.');
        }
        this.protocole = undefined;
      }
    }, error => {
      this.toastrLoadingService.endSearch(idSearch);
      this.protocoleLoading = false;
    });
  }

  onProtocolClicked() {
    if (this.protocole) {
       // mettre a jour le realisateur
      if(this.protocole.realisateurId){
        this.demande.commercialId = this.protocole.realisateurId;
        this.realisateurId = this.protocole.realisateurId;
        this.onUpdateRealisateurId(this.protocole.realisateurId);
      }
      // mettre a jour le commentaire
      if (this.demande.commentaire) {
        this.demande.commentaire =  this.demande.commentaire + '\n' + this.protocole.protocole;
      } else {
        this.demande.commentaire =   this.protocole.protocole;
      }
      this.groupeDevis.controls['commentaireDemande'].setValue(this.demande.commentaire);
      this.IntercomToastrService.success('Demande mise à jour.');
    } else {
      this.IntercomToastrService.warning('Aucun protocole trouvé.');
    }
  }

  // DEVIS
  critereUpdate(devis: DevisInfo){
    let devisClone = Object.assign({}, this.devis);
    devisClone.prestationsMax = devis.prestationsMax;
    devisClone.prestationsMin = devis.prestationsMin;
    devisClone.zoneMax = devis.zoneMax;
    devisClone.zoneMin = devis.zoneMin;
    devisClone.zone = devis.zone;
    devisClone.tva = devis.tva;
    devisClone.prestations = devis.prestations;
    this.devis = devisClone;
    this.calculer();
  }

  prestationParametre(value){
    //This method checks if the prestation has been cofigured, and sets the form to error if its not the case so that the Devis can't be created
    if(value){
      this.IntercomToastrService.warning(
        "Vous ne pouvez pas créer de devis pour une prestation non parametrée"
      );
      this.prestationCtrl.setErrors({'incorrect': true});
      this.cdRef.detectChanges()
    }else{
      this.prestationCtrl.setErrors(null);
      this.cdRef.detectChanges()
    }
  }

   // convert prestation offres -> lignes sous lignes  
  getLignesChantierFromPrestations(prestations: PrestationShared[]) : TablePrestationChantierModel{
    let prestationClient = new TablePrestationChantierModel();
    prestationClient.agenceId = this.devis.agenceId;
    let ht = 0;
    let tva = 0;
    for (let i = 0; i < prestations.length; i++) {
      let ligne= new LigneChantier();
      ligne.libelle = prestations[i].prestation.libelle;
      let offre = prestations[i].offre;
      let sousLigne= new SousLigneChantier();
      sousLigne.libelleActivite = offre.libelle;
      sousLigne.prix = offre.pu;
      sousLigne.qte = offre.qte ? offre.qte : 1;
      sousLigne.tva = offre.tva ? offre.tva : this.devis.tva;
      ligne.orders.push(sousLigne);
      tva = offre.tva ? offre.tva : this.devis.tva;
      ht += offre.pu * (offre.qte ? offre.qte : 1); 
      prestationClient.lignes.push(ligne);
    }
    prestationClient.tauxTva = tva;
    prestationClient.ht = ht;
    prestationClient.ttc = ht + ((ht * tva) / 100);
    return prestationClient;
  }

  // convert prestation offres -> lignes sous lignes  
  getPrestationClientFromPrestations(prestations: PrestationShared[]) : TablePrestationClientModel{
    let prestationClient = new TablePrestationClientModel();
    prestationClient.agenceId = this.devis.agenceId;
    prestationClient.titre = "Cout client principal"
    let ht = 0;
    let tva = 0;
    let prixDeBase = 0;
    let prixHT =0;
    for (let i = 0; i < prestations.length; i++) {
      let offre = prestations[i].offre;
      if(offre){
        let ligne = new LigneClient();
        ligne.libelle = prestations[i].prestation.libelle + ': ' + offre.libelle;
        ligne.prix = offre.pu;
        ligne.qte = offre.qte ? offre.qte : 1;
        ligne.unite = offre.unite;
        prestationClient.lignes.push(ligne);
        tva = offre.tva ? offre.tva : this.devis.tva;        
        prixDeBase += offre.pu;
        prixHT = prixDeBase;
        if(offre.type == 'Multiplicatif')
        {
          prixHT += prixDeBase + ((prixDeBase*offre.coefficient)-prixDeBase);
        }
        ht += prixHT;
      }
      
    }
    prestationClient.tva = tva;
    prestationClient.ht = Math.round(ht * 100) / 100;
    prestationClient.ttc = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;

    return prestationClient;
  }

  calculTotaux(prestations: PrestationShared[], devis: DevisDTO) {
    let tva = (devis.tvaPercent !== null && devis.tvaPercent !== undefined) ? this.devis.tva : 20;
    let majoration = devis.majoration ? devis.majoration : 1;
    let prix = 0;
    let coefficient = 1;
    for (let i = 0; i < prestations.length; i++) {
      const offre = prestations[i].offre;
      prix += offre.type !== 'Multiplicatif' ? offre.pu : 0;
      coefficient *= offre.type === 'Multiplicatif' ? offre.coefficient: 1;
    }
    let multiplicatif = coefficient * majoration;
    let ht = prix * multiplicatif;
    devis.ht = Math.round(ht * 100) / 100;
    devis.tvaAmount = ht * tva / 100;
    devis.ttc = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;
    devis.ht = Number.isNaN(Math.round(ht * 100) / 100) ? 0 : Math.round(ht * 100) / 100;
    devis.tvaAmount = Number.isNaN(ht * tva / 100) ? 0 : ht * tva / 100;
    devis.ttc = Number.isNaN(Math.round((ht + ((ht * tva) / 100)) * 100) / 100) ? 0 : Math.round((ht + ((ht * tva) / 100)) * 100) / 100 ;
  }
    
  calculer() {
    this.updateDevis.emit(this.devis);
  }

  createDemande(){
    if(this.agenceId && this.devis.idRealisateur && this.devis.idActivite && this.devis&& this.groupeDevis.valid && this.isPrestationValid){
      var date = new Date();
      this.demande.tierId = this.selectedTiersDevis.id;
      this.demande.typeDemandeId = 7;
      this.demande.commercialId = this.devis.idRealisateur;
      this.demande.agenceId = this.agenceId;
      this.demande.prestationIds = this.devis.idActivite + "";
      this.demande.dateIntervention = new Date(date.setDate(date.getDate() + 2));
      this.demande.statut = "A traiter";
      this.demande.objet = "Demande devis rapide pour le tiers " + this.selectedTiersDevis.libelle;
      this.demande.tier = null;
      this.demande.typeDemande = null;
      this.demande.commercial = null;
      this.demande.commentaire = this.groupeDevis.get('commentaireDemande').value;
      this.demande.tierFactureId = this.tierFacture.id;
      this.demande.agence = null;
      if(this.selectedSiteGeoDevis){
        this.demande.siteGeoId = this.selectedSiteGeoDevis.id;
        this.demande.libelleAdresse = this.selectedSiteGeoDevis.libelle;
        this.demande.adresse = this.selectedSiteGeoDevis.adresse;
        this.demande.complementAdresse = this.selectedSiteGeoDevis.complementAdresse
        this.demande.codePostalAdresse = this.selectedSiteGeoDevis.codePostal;
        this.demande.villeAdresse = this.selectedSiteGeoDevis.ville;
        this.demande.commentaireAdresse = this.selectedSiteGeoDevis.commentaire;
      }
      this.demande.createur = null;
      const dialogRef = this.dialog.open(ModalSpinnerComponent);
      this.demandeService.post(this.demande).subscribe((dem: DemandeModel) => {
        this.demandeService.get(dem.id).subscribe(res => {
          let tempDemande = this.demande
          this.demande = res;          
            // cas normal
          var devis = this.createDevisFromShared(this.devis); 
          devis.demandeClient = this.groupeDevis.get('commentaireDevis').value;
          this.devisService.create(devis).subscribe((res1: DevisDTO) => {
            this.IntercomToastrService.success('La demande : ' + res.objet + ' a bien été créée');
            this.IntercomToastrService.success('Le devis : ' + res1.code + ' a bien été créée');
            dialogRef.close();
            this.modalsService.openDemande(res).subscribe( result => {
              this.result = result;
              if(result){
                this.result = result;
                var formEmpty = this.fbDevis.group({
                  
                  raisonSocial: [null],
                  idJason: [null],
                  address: [null],
                  ville: [null],
                  libelle: [null],
                  codePostal: [null, Validators.compose([Validators.maxLength(5)])],
                  nomInterlocuteur: [null],
                  prenomInterlocuteur: [null],
                  telInterlocuteur: [null],
                });

                this.groupeDevis.patchValue(
                  this.fbDevis.group({
                    prestation: [null],
                    commentaireDemande: [null],
                    commentaireDevis: [null],
                    objet: [null, Validators.compose([Validators.required])]
                  })
                );
                this.priseAppelService.updateDevis(this.groupeDevis.value);
                this.priseAppelService.updateTierselectedTiers(null, '');
                this.priseAppelService.updateTierselectedTiersFacture(null);
                this.priseAppelService.updateselectedTiersAdresse(null);
                this.priseAppelService.updateselectedInterlocuteur(null);
                this.priseAppelService.changeFilter(new filterTierDTO());
                this.priseAppelService.updateTierRechercheForm(formEmpty);
              }
              this.demande = tempDemande //reset the values of the components demande to the ones before it was created to be able to recreate one without refreshing page  
            });
          },error =>{
            this.demandeService.delete(res.id).subscribe(_ =>  dialogRef.close());
            //Delete the related demande that was created before the devis creation failed
            this.demande = tempDemande //reset the values of the components demande to the ones before it was created to be able to recreate one without refreshing page
            this.IntercomToastrService.error("La création du devis a échoué");
        });
        }, error =>{
          this.demandeService.delete(dem.id).subscribe(_ => dialogRef.close());
          //Delete the related demande that was created before the devis creation failed
        }); 
      }, error =>  {
        dialogRef.close();
        this.IntercomToastrService.error("La création de la demande a échoué")
      });
    }else{
      this.IntercomToastrService.warning(
        "Le réalisateur et l'activité sont obligatoire!"
      );
    }
  }

  initDevisFromDTO(devisDTO: DevisDTO){
    this.devis.idRealisateur = devisDTO.idRealisateur;
    this.devis.agenceId = this.agenceId;
    this.devis.ht = devisDTO.ht;
    this.devis.ttc= devisDTO.ttc;
    this.devis.tva = devisDTO.tvaAmount;
   
  }

  createDevisFromShared(devishared: DevisInfo): DevisDTO {
    var devis = new DevisDTO();
    devis.code = this.demande.numDemande;
    devis.demandeId = this.demande.id;
    devis.idRealisateur = devishared.idRealisateur;
    devis.tierId = this.selectedTiersDevis.id;
    devis.agenceId = this.agenceId;
    devis.dModification = new Date(); 
    //devis.destinataire = devishared.destinataire;

    devis.tierId = this.selectedTiersDevis.id;
    devis.idtJasonTiers = this.selectedTiersDevis.idTJason;
    devis.identifiantJasonTiers = this.selectedTiersDevis.identifiantJason;
    devis.raisonSociale = this.selectedTiersDevis.libelle;
    devis.typeTiers = this.selectedTiersDevis.typeTier;
    //devis.tiersSiret ???
    devis.adresse = this.selectedTiersDevis.adresse;
    devis.codePostal = this.selectedTiersDevis.codePostal;
    devis.ville = this.selectedTiersDevis.ville;
  
    if (this.tierFacture.id) {
      devis.tierFactId = this.tierFacture.id;
    } else {
      devis.tierId = this.selectedTiersDevis.tierFactureId;
    }

    if(this.demande) {
      devis.idActivites = this.demande.prestationIds;
      if(this.prestation) {
        devis.libelleActivite = this.prestation.libelle;
        devis.codeActiviteJason = this.prestation.code;
      }
      devis.objet = this.demande.objet;
      devis.siteGeoId = this.demande.siteGeoId;
      devis.adresseChantier = this.demande.adresse;
      devis.complementAdresseChantier = this.demande.complementAdresse
      devis.codePostalChantier = this.demande.codePostalAdresse;
      devis.villeChantier = this.demande.villeAdresse;
      devis.commentaireChantier = this.demande.commentaireAdresse;
    }

    if(this.realisateur){
      devis.nomRealisateur = this.realisateur.nom;
      devis.prenomRealisateur = this.realisateur.prenom;
      devis.telRealisateur = this.realisateur.tel;
      devis.emailRealisateur = this.realisateur.email;
      devis.fonctionRealisateur = this.realisateur.type;
      devis.agenceIdReal = this.realisateur.agenceId;
    }
    if (devishared.agenceCreateur) {
      devis.agenceIdCrea = devishared.agenceCreateur.id;
      devis.agenceCodeCrea = devishared.agenceCreateur.code;
      devis.agenceLibelleCrea = devishared.agenceCreateur.libelle;
    }

    if (devishared.agence) {
      devis.agenceCode = devishared.agence.code;
      devis.agenceLibelle = devishared.agence.libelle;
      devis.agenceAdresse = devishared.agence.adresse;
      devis.complementAdresseAgence = devishared.agence.complementAdresse;
      devis.agenceCodePostal = devishared.agence.codePostal;
      devis.agenceVille = devishared.agence.ville;
      devis.sloganAgence = devishared.agence.slogan;
      devis.siretAgence = devishared.agence.siret;
      devis.nafAgence = devishared.agence.naf;
      devis.rcs = devishared.agence.rcs;
      devis.agenceEmail = devishared.agence.email;
      devis.agenceTelephone = devishared.agence.telephone;
      if (devishared.agence.societe) {
        devis.libelleSociete = devishared.agence.societe.libelle;
        devis.adresseSociete = devishared.agence.societe.adresse;
        devis.complementAdresseSociete = devishared.agence.societe.complementAdresse;
        devis.codePostalSociete = devishared.agence.societe.codePostal;
        devis.villeSociete = devishared.agence.societe.ville;
        devis.sirenSociete = devishared.agence.societe.siren;
      }
      if (devishared.agence.logo) {
        devis.logoAgence = devishared.agence.logo.url;
        devis.urlLogo = this.chemin + devishared.agence.logo.id; 
      }else {
        devis.urlLogo = ""; 
      }
      if (devishared.agence.certification1) {
        devis.certification1 = devishared.agence.certification1.url;
        devis.urlCertification1 = this.chemin + devishared.agence.certification1.id;
      }else {
        devis.urlCertification1 = ""; 
      }
      if (devishared.agence.certification2) {
        devis.certification2 = devishared.agence.certification2.url;
        devis.urlCertification2 = this.chemin + devishared.agence.certification2.id;
      }else {
        devis.urlCertification2 = ""; 
      }
    }
    if (devishared.description) {
      devis.introduction = devishared.description.introduction;
      devis.descriptionOuvrage = devishared.description.descriptionOuvrage;
      devis.modalite = devishared.description.modalite;
      devis.chargeClient = devishared.description.chargeClient;
      devis.libelleContratJason = devishared.description.libelleContratJason;
      if (devishared.description.image) {
        // a verifier
        devis.url = devishared.description.image.url;
        devis.urlActivite = this.chemin + devishared.description.image.id;
      }
      else {
        devis.urlActivite = "";
      }
    }
    if (this.selectedInterlocDevis) {
      devis.interlocuteurId = this.selectedInterlocDevis.id;
      devis.civiliteId = this.selectedInterlocDevis.civiliteId;
      devis.roleInterlocuteur = this.selectedInterlocDevis.role;
      devis.nomInterlocuteur = this.selectedInterlocDevis.nom;
      devis.prenomInterlocuteur = this.selectedInterlocDevis.prenom;
      devis.emailInterlocuteur = this.selectedInterlocDevis.email;
      devis.telInterlocuteur = this.selectedInterlocDevis.telPrincipal;
      devis.idJasonInterlocuteur = this.selectedInterlocDevis.idJason;
      devis.commentaireInterlocuteur = this.selectedInterlocDevis.commentaire;
    }
    
      
    if(devishared.zone) {
      devis.zone = devishared.zone.libelle;
      devis.majoration = devishared.zone.majoration;
    }
    if(devishared.tva) {
      devis.tvaPercent = devishared.tva; 
    }else {
      devis.tvaPercent = 0;
    }
    this.calculTotaux(devishared.prestations, devis);
    devis.prestationsChantier = this.getLignesChantierFromPrestations(devishared.prestations);
    devis.prestationsClient = [this.getPrestationClientFromPrestations(devishared.prestations)];
    return devis;
  }

  // GMAPS
 
  openGMap() {
  let dst = !this.isSiteGeoSelectedNull? (this.selectedSiteGeoDevis.adresse + ', ' + this.selectedSiteGeoDevis.ville + ' ' + this.selectedSiteGeoDevis.codePostal) : 
  (this.searchedSiteGeoDevis.adresse + ', ' + this.searchedSiteGeoDevis.ville + ' ' + this.searchedSiteGeoDevis.codePostal);
 if (this.realisateur?.agenceId){
    this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
      if (agence.adresse && agence.codePostal && agence.ville) {
        let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
        window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
      } else {
        window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
      }
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
    );
  } else if (this.sessionService.currentUserValue?.agenceId) {    
    this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agenceUser: AgenceModel) => {
      if (agenceUser.adresse && agenceUser.codePostal && agenceUser.ville) {
        let src = agenceUser.adresse + ', ' + agenceUser.codePostal + ' ' + agenceUser.ville;
        window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
      } else {
        window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
      }
    }, error =>  
    this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
  );
  } else {
    window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
  }
  }


  getTime() {
    let dst = !this.isSiteGeoSelectedNull? (this.selectedSiteGeoDevis.adresse + ', ' + this.selectedSiteGeoDevis.ville + ' ' + this.selectedSiteGeoDevis.codePostal) : "";
      //(this.searchedSiteGeoDevis.adresse + ' ' + this.searchedSiteGeoDevis.ville + ' ' + this.searchedSiteGeoDevis.codePostal);
      
    if (dst.length < 4) {
      this.timeTravel = "Adresse d'intervention incomplète ou incorrecte";
    } else if (this.realisateur) {
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {

        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
            new google.maps.DirectionsService().route({
              'origin': src,
              'destination': dst,
              'travelMode': google.maps.TravelMode.DRIVING,
              'provideRouteAlternatives': false,
              drivingOptions: {
                'departureTime': new Date,
                'trafficModel': google.maps.TrafficModel.BEST_GUESS
              }
            }, (results: any) => {
              if (results?.routes[0]) {
                this.distanceTravel = results.routes[0].legs[0].distance.text;
                this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
              }
            }).catch(e => console.log(e));
        } else { this.timeTravel = "Adresse de l'agence du réalisateur incomplète ou incorrecte"; }
      }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
    );
    }  else if (this.sessionService.currentUserValue?.agenceId) {
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            if (results?.routes[0]) {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
            }
          }).catch(e => console.log(e));
        } else { this.timeTravel = "Erreur : Adresse de votre agence est incomplète ou incorrecte"; }
      }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
    );
    } else { this.timeTravel = "Impossible de calculer l'itinéraire"; }
  }

  private getPlaceAutocomplete() {
    this.autocompleteAdresse = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        bounds: this.villeBounds,
        strictBounds: false,
        componentRestrictions: { country: "FR" },
        types: ["address"], // 'establishment' / 'address' / 'geocode'
      }
    );
   google.maps.event.addListener(this.autocompleteAdresse, "place_changed", () => {
      const place = this.autocompleteAdresse.getPlace();
      if (place) {
        //Add street number to adress form if user choses
        let streetNum = this.getStreetNumber(place);
        let street = this.getStreet(place);
        let city = this.getville(place);
        let cp = this.getPostCode(place);
        if (this.getStreetNumber(place) && street) {
          this.tierRechercheForm.patchValue({
            address: streetNum + " " + street
          });
        } else if (street) {
          this.tierRechercheForm.patchValue({
            address: street
          });
        } 
        if (city) {
          this.tierRechercheForm.patchValue({
            ville: city
          });
          this.selectedCity.nom = city;
        } 
        if (cp) {
          this.tierRechercheForm.patchValue({
            codePostal: cp
          });
          this.selectedCity.codesPostaux = [cp];
        }
        if (city || cp) {
          this.selectedCity = Object.assign({}, this.selectedCity);
        }
        this.zone.run(() => this.onSubmitForm()); // run inside angular zone  
        this.addresstext.nativeElement.blur();
      }
    });
  }

  onCodePostalChange(selectedCity: CityData) {
    if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      this.tierRechercheForm.get('codePostal').setValue(selectedCity.codesPostaux[0]);
      this.tierRechercheForm.get('ville').setValue(selectedCity.nom);
      this.onSubmitForm();
      if (!selectedCity.codesPostaux[0] || selectedCity.codesPostaux[0] === "") {
        this.autocompleteAdresse.setBounds(null);
      } else {
        this.gmapsService.getGeoCodeResultBoundsByCodePostal(selectedCity.codesPostaux[0]).subscribe((gmapGeo: google.maps.GeocoderGeometry[]) => {
          if (gmapGeo && gmapGeo.length >= 1) {
            this.autocompleteAdresse.setBounds(gmapGeo[0].bounds);
          } else {
            this.autocompleteAdresse.setBounds(null);
          }
        });
      }
    } else if (this.selectedCity?.nom !== selectedCity.nom) {
      this.onCityChange(selectedCity);
    }
  }

  onCityChange(selectedCity: CityData) {
    if (this.selectedCity?.nom !== selectedCity.nom) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      this.tierRechercheForm.get('codePostal').setValue(selectedCity.codesPostaux[0]);
      this.tierRechercheForm.get('ville').setValue(this.autocVille.villetext);
      this.onSubmitForm();
      if (!selectedCity.codesPostaux[0] || selectedCity.codesPostaux[0] === "") {
        this.autocompleteAdresse.setBounds(null);
      } else {
        this.gmapsService.getGeoCodeResultBoundsByCodePostal(selectedCity.codesPostaux[0]).subscribe((gmapGeo: google.maps.GeocoderGeometry[]) => {
          if (gmapGeo && gmapGeo.length >= 1) {
            this.autocompleteAdresse.setBounds(gmapGeo[0].bounds);
          } else {
            this.autocompleteAdresse.setBounds(null);
          }
        });
      }
    } else if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.onCodePostalChange(selectedCity);
    }
  }
  
  //----------Helpers methods for google maps autocomplete--------------
  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: "long_name" },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: "long_name" },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getville(place) {
    const COMPONENT_TEMPLATE = { locality: "long_name" },
      ville = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return ville;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: "short_name" },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getAddrComponent(place, componentTemplate) {
    let result;
    if (place.address_components) {
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentTemplate[addressType]) {
          result = place.address_components[i][componentTemplate[addressType]];
          return result;
        }
      }
    }
  }
}