
  <div class="header-class">
    <div>
      <h3>Fiche tâche
        <mat-icon data-html="true" #tooltip="matTooltip" 
        [matTooltip]="'Créateur : ' + data.action.createur.nom +' ' + data.action.createur.prenom + '\n' +
        (data.action.dateCreation ? ('Date de création : ' + (data.action.dateCreation.toString() | date:'dd/MM/yyyy HH:mm')) : '') + '\n' +
        (data.action.dateFin ? ('Date de cloture : ' + (data.action.dateFin.toString() | date:'dd/MM/yyyy HH:mm')) : '') +' &#013;'"
        matTooltipPosition="left"
        matTooltipClass="mat-tooltip">info</mat-icon>
      </h3>
    </div>
    <div>
      <a (click)="onModalCancel()" class="close-button">
        <mat-icon class="close-icon">close</mat-icon>
      </a>
      <a (click)="onModalMinimize()" class="close-button">
        <mat-icon class="close-icon">remove</mat-icon>
      </a>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class="modal-container">
  <div class="col-sm-12 body-class">

    <div class="col-sm-8 body-class">
      <div class="header-class">
        <h4>Tiers : </h4>
      </div>
      <div class="col-sm-12 data-class body-class element-top-bottom-6">
        <div class="col-sm-6">
          <app-resume-tier (tierOpen)="onTierOpen()" [tier]="data.action.tier" ></app-resume-tier>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="header-class">
        <div class="header-description-class">
          <h4>Demande : </h4>
        </div>
      </div>
      <div class="data-class col-sm-12 element-top-bottom-6">
        <button *ngIf="data.action.demandeId !== null" matTooltip="{{'Ouvrir la demande ' + data.demandeLib}}"  (click)="onOpenDemande()" mat-flat-button color="primary" class="button-demande"> {{data.demandeLib | truncate: 30}} <mat-icon>work</mat-icon> </button>
      </div>
    </div>
  </div>

<div class="col-sm-12 body-class">
  <!-- Tâches -->
  <div class="col-sm-8 body-class">
    <div class="header-class">
      <h4>Tâche : </h4>
    </div>
    <div class="col-sm-12 data-class body-class">
      <div class="col-sm-12 element-padding-top-10">
        <mat-form-field  class="full-width">
          <mat-label>Objet</mat-label>
          <input matInput placeholder="" disabled  value="{{data.action.objetAction}}" >
        </mat-form-field>
      </div>

      <div class="col-sm-3">
        <mat-form-field class="full-width">
          <mat-label>Type</mat-label>
          <input matInput placeholder="" disabled  value="{{data.action.typeAction.libelle}}">
        </mat-form-field>
      </div>

      <div class="col-sm-3">
        <mat-form-field class="full-width">
          <mat-label>Date d'échéance</mat-label>
          <input matInput placeholder="" disabled  value="{{data.action.dateRealisation | date:'dd/MM/yyyy'}}" >
        </mat-form-field>
      </div>
      
      <div class="col-sm-3">
        <mat-form-field class="full-width">
          <mat-label>Heure</mat-label>
          <input matInput placeholder="" disabled  value="{{data.action.heureDebut}}" >
        </mat-form-field>
      </div>

      <div class="col-sm-3">
        <mat-form-field class="full-width">
          <mat-label>Durée</mat-label>
          <input matInput disabled value="{{data.action.duree}}">
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field class="full-width">
          <mat-label>Commentaire</mat-label>
          <textarea matInput placeholder="" disabled  value="{{data.action.commentaire}}" rows="10"></textarea>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div class="col-sm-4">
    <!-- Contact -->
    <div class="col-sm-12 body-class">
      <div class="header-class">
        <h4>Contact : </h4>
      </div>
      <div class="col-sm-12 data-class element-padding-top-10 element-disabled">
        <div>
          <app-autocomplete-interlocuteur 
            [interlocuteurs]="interlocuteurs"
            [action]="data.action">
          </app-autocomplete-interlocuteur>
        </div>
      </div>
    </div>

  </div>
</div>
</div>