import { BasicModel } from './basic-models/basic.model';
import { Injectable } from '@angular/core';
import { AgenceModel } from './rsa/agence.model';
@Injectable()
export class ZoneInterventionModel extends BasicModel {
    libelle: string;
    agenceId: number;
    agence: AgenceModel;
    codePostal: string;
    majoration: number = 0;
    commentaire: string;
}
