import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
        if (value !== undefined && value !== null) {
            if (value.length < limit)
            return `${value.substr(0, limit)}`;

            if (completeWords) {
                limit = value.substr(0, limit).lastIndexOf(' ');
            }
            return `${value.substr(0, limit)}${ellipsis}`;
        }
        return '';
    }
}