import { TierModel } from './tier/tier.model';
import { TierBasicModel } from './basic-models/tier-basic.model';
import { CiviliteModel } from './civilite.model';
import { AgenceModel } from './rsa/agence.model';

export class InterlocuteurModel extends TierBasicModel {
  public tier?: TierModel;
  public tierId: number;
  public telPrincipal: string;
  public telSecondaire: string;
  public nom: string;
  public prenom: string;
  public civilite?: CiviliteModel;
  public civiliteId: number;
  public email: string;
  public commentaire: string;
  public role: string;
  public agenceId?: number;
  public agence?: AgenceModel;
  public idJason?: number;
}
 