import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import { SocieteModel } from "src/app/modules/core/shared/models/rsa/societe.model";
import { SocieteService } from "src/app/modules/core/services/rsa/societe.service";
import * as _ from "lodash";

@Component({
  selector: 'app-autocomplete-societe-multiple-select',
  templateUrl: './autocomplete-societe-multiple-select.component.html',
  styleUrls: ['./autocomplete-societe-multiple-select.component.scss']
})
export class AutocompleteSocieteMultipleSelectComponent implements OnInit {
  @Input() selectedSocieteIds: number[];
  @Input() societesIdsArray;
  @Input() firstLoadId: any;
  _val: Subject<Set<number>> = new Subject();
  @Input()
  set eventsSubjectSocieteArray(val: Subject<Set<number>>) {
    this._val = val;
  }
  
  @Output() updated = new EventEmitter<number[]>();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isLoaded = new BehaviorSubject<boolean>(false);
  societeCtrl = new UntypedFormControl();
  filteredSocietes: Observable<string[]>;


  inputSocieteArray = new Set();

// Input / Output
// toute les societes
  selectableSocietesString: string[] = [];
  allSocietes: SocieteModel[] = [];
  selectedSocieteString: string[] = [];
  societes : SocieteModel[] = [];
  
  eventsSubscription: Subscription;

  
  @ViewChild('societeInput', { static: true }) societeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(private societeService: SocieteService) {}

  ngOnInit() {

    this.societeService.getAllManaged(1).subscribe(res => {
      this.societes = res;
      this.isLoaded.next(true);
    });

    this.eventsSubscription = this._val.subscribe((x) => {
      this.inputSocieteArray = x;
      this.selectableSocietesString  = [];
      this.allSocietes = [];
      this.selectedSocieteString = [];
      this.isLoaded.next(true);
      this.loadSocietes(this.societes);
    });
  }

  ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectableSocietesString  = [];
    this.allSocietes = [];
    this.selectedSocieteString = [];
    this.isLoaded.pipe(filter(val => val)).subscribe(_ => 
      this.loadSocietes(this.societes)
    );
  }
  
    /* Charger les societes  */
  loadSocietes(societes: SocieteModel[]) {
    if (societes) {
      this.allSocietes = societes;
      this.societesIdsArray = [...this.inputSocieteArray];
      if(this.societesIdsArray.length != 0){
        // pour chaque societe 
        this.societesIdsArray.forEach(idInput=>{
          if(societes.find(societe=>societe.id ==idInput) != undefined){
            let societeFound = societes.find(societe=>societe.id ==idInput)
            if(!this.selectedSocieteString.find(libelle => libelle ==  this.societeName(societeFound))){
              this.selectedSocieteString.push( this.societeName(societeFound))
            }
          }
        })
        let tempSocietes = Object.assign([],societes);
        tempSocietes = tempSocietes.filter(societe=>!this.societesIdsArray.includes(societe.id))
    
        tempSocietes.forEach(societe => {
        if(!this.selectableSocietesString.find(libelle => libelle ==  this.societeName(societe) )  ){
          this.selectableSocietesString.push(this.societeName(societe))
        }})
      }else{
          societes.forEach(societe=>{
            if(!this.selectableSocietesString.find(libelle => libelle ==  this.societeName(societe)) ){
              this.selectableSocietesString.push(this.societeName(societe))
            }
          })
      }
      this.selectableSocietesString.sort();
      this.selectedSocieteString.sort();
      this.filteredSocietes = this.societeCtrl.valueChanges.pipe(
        startWith(null),
        map((societe: string | null) => societe ? this._filter(societe) : this.selectableSocietesString.slice()));
    }
  }
  
  /* Supression d'une societe*/
  remove(societe: string): void {
    let id;
    const index = this.selectedSocieteString.indexOf(societe);
    if (index >= 0) {
      this.selectableSocietesString.push(this.selectedSocieteString[index]);
      this.selectableSocietesString.sort();
      this.selectedSocieteString.splice(index, 1);
      this.selectedSocieteString.sort();
    }

    this.societesIdsArray.splice(this.societesIdsArray.indexOf(this.societes.find(x=>  this.societeName(x)  == societe).id),1)
    this.updated.emit(this.societesIdsArray);
    this.filteredSocietes = this.societeCtrl.valueChanges.pipe(
      startWith(null),
      map((mySociete: string | null) => mySociete ? this._filter(mySociete) : this.selectableSocietesString.slice()));
  }

  addElement(inputValue,inputIndex){
    const value = inputValue;
    const index = inputIndex;

    if ((value || '').trim() && this.societes.find(x=>  this.societeName(x)  == value) != undefined) {
      this.selectedSocieteString.push(value.trim());
      this.selectableSocietesString.splice(index, 1);
      this.selectableSocietesString.sort();
      this.selectedSocieteString.sort();
      this.societesIdsArray.push(this.societes.find(x=>  this.societeName(x) == value).id)
    }

    this.societeCtrl.setValue(null);
    this.updated.emit(this.societesIdsArray);

    this.filteredSocietes = this.societeCtrl.valueChanges.pipe(
      startWith(null),
      map((societe: string | null) => societe ? this._filter(societe) : this.selectableSocietesString.slice()));
    this.updated.emit(this.societesIdsArray);
    this.societeInput.nativeElement.blur();
    this.societeInput.nativeElement.focus();
  }
  
  /* Ajout d'une societe avec selection */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.addElement(event.option.viewValue,this.selectableSocietesString.indexOf(event.option.viewValue))
    this.societeInput.nativeElement.value = '';
    this.societeInput.nativeElement.blur();
  }

  /* Ajout d'une societe avec Entrer */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    this.addElement(event.value,this.selectableSocietesString.indexOf(event.value))
    if (input) {
      input.value = '';
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return this.selectableSocietesString.filter(societe => societe.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
  }
  
  societeName(soc: SocieteModel) {
    return (soc.region.libelle + ' / ' + soc.libelle).trim();
  }
}
