<div class="table-container">
  <table mat-table [dataSource]="matDataSource" multiTemplateDataRows *ngIf="demandes && demandes.length > 0">
    <caption></caption>
    <!-- Position Column -->
    <ng-container matColumnDef="numDemande">
      <th mat-header-cell *matHeaderCellDef > Numéro demande </th>
      <td mat-cell *matCellDef="let element"> {{element.numDemande | truncate: 75}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="objetDemande">
      <th mat-header-cell *matHeaderCellDef > Objet </th>
      <td mat-cell *matCellDef="let element"> {{element.objet | truncate: 75}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="typeDemande">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.typeDemande.libelle}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="statut">
      <th mat-header-cell *matHeaderCellDef > Statut </th>
      <td mat-cell *matCellDef="let element">
        <strong  *ngIf="element.statut === 'A traiter'" class="chip info-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'En cours'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
        <strong *ngIf="element.statut === 'Clos'" class="chip info-green text-center"> {{element.statut}} </strong>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="commercialP">
      <th mat-header-cell *matHeaderCellDef >Réalisateur</th>
      <td mat-cell *matCellDef="let element">{{element.commercial.nom}} {{element.commercial.prenom}} </td>
    </ng-container>
  
    <ng-container matColumnDef="gestionnaire">
      <th mat-header-cell *matHeaderCellDef >Gestionnaire</th>
      <td mat-cell *matCellDef="let element">{{element?.gestionnaire?.nom}} {{element?.gestionnaire?.prenom}} </td>
    </ng-container>
  
    <ng-container matColumnDef="dateInterv">
      <th mat-header-cell *matHeaderCellDef >Date d'échéance </th>
      <td mat-cell *matCellDef="let element"> {{element.dateIntervention | date:'dd/MM/yyyy'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="dateCreation">
      <th mat-header-cell *matHeaderCellDef >Date de création </th>
      <td mat-cell *matCellDef="let element"> {{element.dateCreation | date:'dd/MM/yyyy'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="activite">
      <th mat-header-cell *matHeaderCellDef>Activité</th>
      <td mat-cell *matCellDef="let element"> {{ prestationLibellesMap[element.id] }} </td>
    </ng-container>
  
    <ng-container matColumnDef="siteGeo">
      <th mat-header-cell *matHeaderCellDef > Adresse </th>
      <td mat-cell *matCellDef="let element">
        <div [matTooltip]="element.libelleAdresse?element.libelleAdresse:''"> {{(element.adresse?element.adresse:'') + ' ' + (element.codePostalAdresse?element.codePostalAdresse:'') + ' '  + (element.villeAdresse?element.villeAdresse:'')}} </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let demande" [attr.colspan]="displayedColumns.length">
        <div class="demande-detail"
             [@detailExpand]="demande == expandedDemande ? 'expanded' : 'collapsed'">
             <div class="separation-detail">
              Détail de la demande : 
              <div class="demande-infos">
                <div *ngIf="demande.siteGeo">Adresse intervention : {{demande.siteGeo.adresse + ' ' + demande.siteGeo.codePostal + ' ' + demande.siteGeo.ville }}</div>
                <div><p>Commentaire : {{demande.commentaire? '' : 'aucun commentaire' }}</p>
                   <textarea class="col-md-12" readonly *ngIf="demande.commentaire" (focus)="false" matInput
                   cdkTextareaAutosize >{{demande.commentaire}}</textarea></div>
                <div>Réalisateur : {{demande.commercial.nom + ' ' + demande.commercial.prenom}}</div>
                <div>Ouvrir la demande : <button matTooltip="Ouvrir la demande"  (click)="onOpenDemande(demande)" mat-flat-button color="primary"> <mat-icon>work</mat-icon></button></div>
              </div>
              <div class="list-action" *ngIf="demande.actions && demande.actions.length > 0">
                Tâches liée à la demande : 
                <table>
                  <caption></caption>
                  <thead>
                    <th> Objet : </th>
                    <th> Type : </th>
                    <th> Date intervention : </th>
                    <th class="col-statut"> Statut : </th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let action of demande.actions| slice:0:10; let i=index">
                      <td>{{action.objetAction}}</td>
                      <td>{{action.typeAction.libelle}}</td>
                      <td>{{action.dateRealisation | date:'dd/MM/yyyy' }}</td>
                      <td>
                        <strong  *ngIf="action.statut === 'A traiter'" class="chip info-blue text-center"> {{action.statut}} </strong>
                        <strong *ngIf="action.statut === 'En cours'" class="chip info-dark-blue text-center"> {{action.statut}} </strong>
                        <strong *ngIf="action.statut === 'Annulé'" class="chip info-light-brown text-center"> {{action.statut}} </strong>
                        <strong *ngIf="action.statut === 'Clos'" class="chip info-green text-center"> {{action.statut}} </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let demande; columns: displayedColumns;"
        class="demande-row"
        [class.demande-expanded-row]="expandedDemande === demande"
        (click)="expandedDemande = expandedDemande === demande ? null : demande">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="demande-detail-row"></tr>
  
  </table>
  
  <div *ngIf="!demandes || demandes.length == 0">
    Aucune demande
  </div>
</div>