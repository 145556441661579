import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { BasicService } from '../../../services/basic-service/basic.service';
import { AgenceService } from '../../../services/rsa/agence.service';
import { SiteGeoService } from '../../../services/site-geo.service';
import { EntrepriseService } from '../../../services/tier/entreprise.service';
import { HlmService } from '../../../services/tier/hlm.service';
import { MairieService } from '../../../services/tier/mairie.service';
import { ParticulierService } from '../../../services/tier/particulier.service';
import { SyndicService } from '../../../services/tier/syndic.service';
import { UserService } from '../../../services/user/user.service';
import { DroitLevels } from '../../../shared/droitsLevels';
import { AgenceModel } from '../../../shared/models/rsa/agence.model';
import { SiteGeoModel } from '../../../shared/models/site-geo.model';
import { EntrepriseModel } from '../../../shared/models/tier/entreprise.model';
import { HlmModel } from '../../../shared/models/tier/hlm.model';
import { MairieModel } from '../../../shared/models/tier/mairie.model';
import { ParticulierModel } from '../../../shared/models/tier/particulier.model';
import { SyndicModel } from '../../../shared/models/tier/syndic.model';
import { TierModel } from '../../../shared/models/tier/tier.model';
import { CommercialModel } from '../../../shared/models/users/commercial.model';
import { UserModel } from '../../../shared/models/users/user.model';
import { ModalAfficherDocumentsComponent } from 'src/app/modules/shared/components/modals/modal-afficher-documents/modal-afficher-documents.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-information-tiers',
  templateUrl: './information-tiers.component.html',
  styleUrls: ['./information-tiers.component.scss']
})
export class InformationTiersComponent implements OnInit, OnChanges {

  @Input() tiers: TierModel;
  @Output() tiersUpdated = new EventEmitter<TierModel>();
  public particulier: ParticulierModel = null;
  public entreprise: EntrepriseModel = null;
  public mairie: MairieModel = null;
  public syndic: SyndicModel = null;
  public hlm: HlmModel = null;
  public commercial: CommercialModel;
  public commerciaux: CommercialModel[] = [];
  public agence: AgenceModel;
  public agences: AgenceModel[] = [];
  public type: string;
  public redimension_email: string = 'col-md-3';
  public canModify = true;
  public emailRequired = true;
  public telRequired = true;
  public telValid = true;
  public telInput = "";

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private particulierService: ParticulierService,
    private entrepriseService: EntrepriseService,
    private mairieService: MairieService,
    private syndicService: SyndicService,
    private hlmService: HlmService,
    private agenceService: AgenceService,
    private userService: UserService,
    private adresseService: SiteGeoService,
    private IntercomToastrService: IntercomToastrService,
  ) { }

  ngOnInit(): void {
    this.onLoadTier();
    // Chargement des réalisateurs
    this.userService.getAll().subscribe((commerciaux: UserModel[]) => {
      this.commerciaux = commerciaux.sort((a, b) => a.nom.localeCompare(b.nom));
    });
    // Chargement des agences que l'utilisateur à le droit d'écriture
    this.agenceService.getAllManaged(DroitLevels.READ).subscribe((agences: AgenceModel[]) => {
      this.agences = agences.sort((a, b) => a.libelle.localeCompare(b.libelle));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mairie = null;
    this.entreprise = null;
    this.particulier = null;
    this.hlm = null;
    this.syndic = null;
    this.tiers = changes.tiers.currentValue;
    if (this.tiers.email) {
      this.telRequired = false;
    }
    if (this.tiers.telephone) {
      this.emailRequired = false;
      this.telInput = this.tiers.telephone;
    }
    this.onLoadTier();
  }

  onLoadTier() {
    if (this.tiers != null && this.tiers !== undefined && this.tiers.id !== 0 && this.tiers.id !== undefined) {
      this.type = this.tiers.typeTier;
      if(this.tiers.identifiantJason !== null && this.tiers.identifiantJason !== undefined && this.tiers.identifiantJason !== "") {
        this.canModify = false;
      } else {
        this.canModify = true;
      }

      if (this.tiers.typeTier === 'Particulier') {
        this.particulierService.get(this.tiers.id).subscribe((particulier: ParticulierModel) => {
          this.particulier = particulier;
          this.tiers = particulier;
          this.redimension_email = 'col-md-4';
        });

      } else if (this.tiers.typeTier === 'Entreprise') {
        this.entrepriseService.get(this.tiers.id).subscribe((entreprise: EntrepriseModel) => {
          this.entreprise = entreprise;
          this.tiers = entreprise;
          this.redimension_email = 'col-md-2';
        });

      } else if (this.tiers.typeTier === 'Mairie') {
        this.mairieService.get(this.tiers.id).subscribe((mairie: MairieModel) => {
          this.mairie = mairie;
          this.tiers = mairie;
          this.redimension_email = 'col-md-6';
        });
      } else if (this.tiers.typeTier === 'Syndic') {
        this.syndicService.get(this.tiers.id).subscribe((syndic: SyndicModel) => {
          this.syndic = syndic;
          this.tiers = syndic;
          this.redimension_email = 'col-md-3';
        });
      } else if (this.tiers.typeTier === 'Hlm') {
        this.hlmService.get(this.tiers.id).subscribe((hlm: HlmModel) => {
          this.hlm = hlm;
          this.tiers = hlm;
          this.redimension_email = 'col-md-3';
        });
      }
    } else {
      if (this.tiers.typeTier === 'Particulier') {
          this.particulierService.subscriber.subscribe((particulier: ParticulierModel) => {
            this.particulier = particulier;
            this.tiers = this.particulier;
            this.redimension_email = 'col-md-4';
          });
      } else if (this.tiers.typeTier === 'Entreprise') {
        this.entrepriseService.subscriber.subscribe((entreprise: EntrepriseModel) => {
          this.entreprise = entreprise;
          this.tiers = this.entreprise;
          this.redimension_email = 'col-md-2';
        });
      } else if (this.tiers.typeTier === 'Mairie') {
        this.mairieService.subscriber.subscribe((mairie: MairieModel) => {
          this.mairie = mairie;
          this.tiers = this.mairie;
          this.redimension_email = 'col-md-6';
        });
      } else if (this.tiers.typeTier === 'Syndic') {
        this.syndicService.subscriber.subscribe((syndic: SyndicModel) => {
          this.syndic = syndic;
          this.tiers = this.syndic;
          this.redimension_email = 'col-md-3';
        });
      } else if (this.tiers.typeTier === 'Hlm') {
        this.hlmService.subscriber.subscribe((hlm: HlmModel) => {
          this.hlm = hlm;
          this.tiers = this.hlm;
          this.redimension_email = 'col-md-3';
        });
      }
    }
  }

  onParticulierChange(particulier: ParticulierModel) {
    this.particulier = particulier;
  }

  onMairieChange(mairie: MairieModel) {
    this.mairie = mairie;
  }

  onSyndicChange(syndic: SyndicModel) {
    this.syndic = syndic;
  }
  onHlmChange(hlm: HlmModel) {
    this.hlm = hlm;
  }
  onphoneNumberChange(event) {
    this.emailRequired = !event;
    this.tiers.telephone = event;
  }

  onEntrepriseChange(entreprise: EntrepriseModel) {
    this.entreprise = entreprise;
  }

  onChangeEvent(event: string) {
    this.tiers.telephone = event;
    this.telInput = event;
  }

  onCommercialChange(event: MatSelectChange) {
    this.tiers.commercialId = event.value;
    this.tiers.commercial = this.commerciaux.find(c =>  c.id ===  event.value);
  }

  onAgenceChange(event: AgenceModel) {
    this.agence = event;
    if (this.agence !== undefined) {
      this.tiers.agenceId = event.id;
    }
  }

  onTvaChange(event: MatSelectChange) {
    this.tiers.tva = event.value;
  }

  onUpdateVilleFact() {
    if (this.tiers.villeFact === null || this.tiers.villeFact === undefined || this.tiers.villeFact === '') {
      this.tiers.villeFact = this.tiers.ville;
    }
  }

  onUpdateAdresseFact() {
    if (this.tiers.adresseFact === null || this.tiers.adresseFact === undefined || this.tiers.adresseFact === '') {
      this.tiers.adresseFact = this.tiers.adresse;
    }
  }

  onUpdateCodePostalFact() {
    if (this.tiers.codePostalFact === null || this.tiers.codePostalFact === undefined || this.tiers.codePostalFact === '') {
      this.tiers.codePostalFact = this.tiers.codePostal;
    }
  }

  onUpdateComplementAdresseFact() {
    if (this.tiers.complementAdresseFact === null || this.tiers.complementAdresseFact === undefined || this.tiers.complementAdresseFact === '') {
        this.tiers.complementAdresseFact = this.tiers.complementAdresse;
     }
  }

  onBuildTiers(otherTier: TierModel) {
    otherTier.commercialId = this.tiers.commercialId;
    otherTier.email = this.tiers.email;
    otherTier.tva = this.tiers.tva;
    otherTier.adresse = this.tiers.adresse;
    otherTier.codePostal = this.tiers.codePostal;
    otherTier.ville = this.tiers.ville;
    otherTier.complementAdresse = this.tiers.complementAdresse;
    otherTier.adresseFact = this.tiers.adresseFact;
    otherTier.codePostalFact = this.tiers.codePostalFact;
    otherTier.villeFact = this.tiers.villeFact;
    otherTier.complementAdresseFact = this.tiers.complementAdresseFact;
    otherTier.agenceId = this.tiers.agenceId;
    otherTier.adresseIdentique = this.tiers.adresseIdentique;
    otherTier.telephone = this.tiers.telephone;
    otherTier.identifiantJason = this.tiers.identifiantJason;
    // otherTier.clientNumber = this.tiers.clientNumber;
  }

  onCreateAdresse(tiers: TierModel): SiteGeoModel {
    if (tiers.adresse !== null && tiers.adresse !== undefined && tiers.adresse !== ''  &&
      tiers.codePostal !== null && tiers.codePostal !== undefined && tiers.codePostal !== ''  &&
      tiers.ville !== null && tiers.ville !== undefined && tiers.ville !== '') {
      const adresse = new SiteGeoModel();
      adresse.libelle = 'Siege';
      adresse.adresse = tiers.adresse;
      adresse.codePostal = tiers.codePostal;
      adresse.ville = tiers.ville;
      adresse.complementAdresse = tiers.complementAdresse;
      adresse.tierId = tiers.id;
      adresse.agenceId = tiers.agenceId;
      return adresse;
    }
    return null;
  }

  onCreateAdresseFact(tiers: TierModel): SiteGeoModel {
    if (tiers.adresseFact !== null && tiers.adresseFact !== undefined && tiers.adresseFact !== ''  &&
      tiers.codePostalFact !== null && tiers.codePostalFact !== undefined && tiers.codePostalFact !== ''  &&
      tiers.villeFact !== null && tiers.villeFact !== undefined && tiers.villeFact !== '') {
      const adresse = new SiteGeoModel();
      adresse.libelle = 'Facturation';
      adresse.adresse = tiers.adresseFact;
      adresse.codePostal = tiers.codePostalFact;
      adresse.ville = tiers.villeFact;
      adresse.complementAdresse = tiers.complementAdresseFact;
      adresse.tierId = tiers.id;
      adresse.agenceId = tiers.agenceId;
      return adresse;
    }
    return null;
  }

  onCreateTiers() {
    if (this.tiers !== null && this.tiers !== undefined && this.tiers.id === 0) {
      this.type = this.tiers.typeTier;
      let service: BasicService;
      let tier: any;
      if (this.tiers.typeTier === 'Particulier') {
        if (!this.particulier.nom) {
          this.IntercomToastrService.error('Le champ : Nom est obligatoire', 'Erreur');
        } else {
          service = this.particulierService;
          this.onBuildTiers(this.particulier);
          tier = this.particulier;
      }
      } else if (this.tiers.typeTier === 'Entreprise') {
        if (!this.entreprise.raisonSociale) {
          this.IntercomToastrService.error('Le champ : Raison sociale est obligatoire', 'Erreur');
        } else {
          service = this.entrepriseService;
          this.onBuildTiers(this.entreprise);
          tier = this.entreprise;
        }
      } else if (this.tiers.typeTier === 'Mairie') {
        if (!this.mairie.commune) {
          this.IntercomToastrService.error('Le champ : Commune mairie est obligatoire', 'Erreur');
        } else {
          service = this.mairieService;
          this.onBuildTiers(this.mairie);
          tier = this.mairie;
        }
      } else if (this.tiers.typeTier === 'Syndic') {
        if (!this.syndic.raisonSociale) {
          this.IntercomToastrService.error('Le champ : Raison sociale est obligatoire', 'Erreur');
        } else {
          service = this.syndicService;
          this.onBuildTiers(this.syndic);
          tier = this.syndic;
        }
      } else if (this.tiers.typeTier === 'Hlm') {
        if (!this.hlm.raisonSociale) {
          this.IntercomToastrService.error('Le champ : Raison sociale est obligatoire', 'Erreur');
        } else {
          service = this.hlmService;
          this.onBuildTiers(this.hlm);
          tier = this.hlm;
        }
      }
      if (service !==  null && this.tiers !== null) {
        service.post(tier).subscribe((tierCreated: TierModel) => {
          this.IntercomToastrService.success('', 'Création du tiers réussie');
          const adresseTier: SiteGeoModel =  this.onCreateAdresse(tierCreated);
          const adresseFactTier: SiteGeoModel =  this.onCreateAdresseFact(tierCreated);
          if (adresseTier !== null) {
            this.adresseService.post(adresseTier).subscribe((res) => {
              this.IntercomToastrService.success('', 'Création de l\'adresse réussie');
              this.tiersUpdated.emit(this.tiers);
              this.router.navigate(['/fiche-contact', tierCreated.id]);
            });
          } else {
            this.IntercomToastrService.warning('', 'Nous n\'avons pas réussi à créer l\'adresse principale du tiers');
            this.tiersUpdated.emit(this.tiers);
            this.router.navigate(['/fiche-contact', tierCreated.id]);
          }

          if (adresseFactTier !== null) {
            this.adresseService.post(adresseFactTier).subscribe((res) => {
              this.IntercomToastrService.success('', 'Création de l\'adresse de facturation réussie');
              this.tiersUpdated.emit(this.tiers);
              this.router.navigate(['/fiche-contact', tierCreated.id]);
            });
          } else {
            this.IntercomToastrService.warning('', 'Nous n\'avons pas réussi à créer l\'adresse de facturation du tiers');
            this.tiersUpdated.emit(this.tiers);
            this.router.navigate(['/fiche-contact', tierCreated.id]);
          }
        });
      }
    }
  }

  onUpdateTiers() {
    if (this.tiers !== null && this.tiers !== undefined && this.tiers.id !== undefined && this.tiers.id !== 0 && this.telValid) {
      this.type = this.tiers.typeTier;
      let service: BasicService;
      let tier: any;
      if (this.tiers.typeTier === 'Particulier') {
        service = this.particulierService;
        this.onBuildTiers(this.particulier);
        tier = this.particulier;
      } else if (this.tiers.typeTier === 'Entreprise') {
        service = this.entrepriseService;
        this.onBuildTiers(this.entreprise);
        tier = this.entreprise;
      } else if (this.tiers.typeTier === 'Mairie') {
        service = this.mairieService;
        this.onBuildTiers(this.mairie);
        tier = this.mairie;
      } else if (this.tiers.typeTier === 'Syndic') {
        service = this.syndicService;
        this.onBuildTiers(this.syndic);
        tier = this.syndic;
      } else if (this.tiers.typeTier === 'Hlm') {
        service = this.hlmService;
        this.onBuildTiers(this.hlm);
        tier = this.hlm;
      }
      if (service !==  null && this.tiers !== null) {
        service.put(tier).subscribe((tierCreated: TierModel) => {
          const adresseTier = this.onCreateAdresse(tierCreated);
          const adresseFactTier = this.onCreateAdresseFact(tierCreated);
          this.IntercomToastrService.success('', 'Enregistrement du tiers réussie');
          if (adresseTier !== null) {
            this.adresseService.addOrUpdated(adresseTier).subscribe(site => {
              this.tiersUpdated.emit(tierCreated);
              this.IntercomToastrService.success('', 'Mise à jour de l\'adresse de principale réussie');
            });
          } else {
            this.tiersUpdated.emit(tierCreated);
            this.IntercomToastrService.warning('', 'Nous n\'avons pas réussi à créer l\'adresse principale du tiers');
          }
          if (adresseFactTier !== null) {
            this.adresseService.addOrUpdated(adresseFactTier).subscribe(site => {
              this.tiersUpdated.emit(tierCreated);
              this.IntercomToastrService.success('', 'Mise à jour de l\'adresse de facturation réussie');
            });
          } else {
            this.tiersUpdated.emit(tierCreated);
            this.IntercomToastrService.warning('', 'Nous n\'avons pas réussi à créer l\'adresse de facturation du tiers');
          }
        });
      }
    } else if (!this.telValid) {
      this.IntercomToastrService.warning('', 'Format du téléphone incorrect');
    }
  }

  onTiersFactureChange(tiersFacture: TierModel) {
    if(tiersFacture){
      this.tiers.tierFactureId = tiersFacture.id;
    } 
  }

  onShowDocuments(){
    const dialogRef = this.dialog.open(ModalAfficherDocumentsComponent, {
      disableClose: false,
      data: {
        tier: this.tiers
      },
      panelClass: 'mat-dial-no-padd'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
