import { UserModel } from './../../shared/models/users/user.model';
import { Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import { DemandeService } from './../../services/demande.service';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { animate, style, transition, trigger } from '@angular/animations';
import { SessionService } from 'src/app/modules/shared/session.service';
import { DashboardService } from '../../services/dashboard.service';
import { UserService } from '../../services/user/user.service';
import { DashboardDTO } from '../../shared/dtos/dashboardDTO';
import { ActionService } from '../../services/action.service';
import { DevisService } from 'src/app/modules/devis/services/devis.service';
import { FiltreDemandeDTO } from '../../shared/dtos/filterDemandeDTO';
import { FiltreService } from '../../services/filter.service';
import { FiltreActionDTO } from '../../shared/dtos/filterActionDTO';
import { FiltreDevisDTO } from '../../shared/dtos/filterDevisDTO';
import { AgenceService } from '../../services/rsa/agence.service';
import { SocieteService } from '../../services/rsa/societe.service';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { SocieteModel } from '../../shared/models/rsa/societe.model';
import { AgenceModel } from '../../shared/models/rsa/agence.model';
import { FiltrePerimetreDTO } from '../../shared/dtos/filterPerimetreDTO';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CommercialModel } from '../../shared/models/users/commercial.model';
import { PriseAppelService } from '../../services/prise-appel.service';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { ModalAddFiltrePersonnaliseComponent } from './modal-add-filtre-personnalise/modal-add-filtre-personnalise.component';
import { FiltrePerimetreService } from '../../services/filtre-perimetre.service';
import { ModalConfirmationComponent } from 'src/app/utils/modals/modal-confirmation/modal-confirmation.component';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { EtatUrgencePxo } from '../../shared/etatUrgencePxo';
import { ONGLETSPRISEAPPEL } from '../../shared/ongletsPriseAppel';
import { TypeDemandeService } from '../../services/type-demande.service';


export const fadeInOutTimeout = 250;
export const fadeInOut = trigger('fadeInOut', [
  transition('void => *', [style({ opacity: '0', transform: 'translateX(-10%)' }), animate(fadeInOutTimeout)]),
  transition('* => void', [animate(fadeInOutTimeout, style({ opacity: '0' }))]),
  transition('* => *', [
style({ opacity: '0', transform: 'translateX(-10%)' }),
animate(fadeInOutTimeout, style({ opacity: '1', transform: 'translateX(0%)' })),
  ]),
]);

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss'],
  animations: [ fadeInOut ]
})


export class PrincipalComponent implements OnInit, OnDestroy{

  eventsSubjectAgenceArray: Subject<Set<number>> = new Subject();
  eventsSubjectSocieteArray: Subject<Set<number>> = new Subject();

  counterDemandes = new DashboardDTO();
  counterDevis = new DashboardDTO();
  counterActions = new DashboardDTO();
  filtre : any;

  filtres: FiltrePerimetreDTO[] = [];
  @ViewChild('selectedFiltre') selectedFiltre: MatSelectionList;

  resetSelect = true;

  societes: SocieteModel[];
  agences: AgenceModel[];
  filtreData: FiltrePerimetreDTO = new FiltrePerimetreDTO();

  selectedSocieteIdsArray: Set<number>  = new Set();
  selectedAgenceIdsArray: Set<number>  = new Set();
  selectedSocietesString: string = "";

  selectedRealisateurIdsArray = new Set<number>();
  eventsSubjectRealisateurArray: Subject<Set<number>> = new Subject();
  selectedCreateurIdsArray = new Set<number>();
  eventsSubjectCreateurArray: Subject<Set<number>> = new Subject();

  users: UserModel[] = [];

  allTypeDemandesId: number[] = [];

  nbFilterApplied: number = 0;

  private filteredSubscribe: Subscription;

  constructor(public dialog: MatDialog,
    private router: Router,
    private toastr: IntercomToastrService,
    private userService: UserService,
    public demandeService: DemandeService,
    public actionService : ActionService,
    private agenceService: AgenceService,
    private societeService: SocieteService,
    private dashboardService: DashboardService,
    private filtreService: FiltreService,
    public devisService : DevisService,
    public sessionService: SessionService,
    private priseAppelService : PriseAppelService,
    private toastrLoadingService: ToastrLoadingService,
    private filtrePerimetreService: FiltrePerimetreService,
    private typeDemandeService: TypeDemandeService
    ) {}

  ngOnInit(){ 
    this.dashboardService.getFiltres().subscribe((response: FiltrePerimetreDTO[]) => {
      this.filtres = response;
    });
    this.typeDemandeService.getAll().subscribe(res => {
      if (res) {
        this.allTypeDemandesId = res.map(typeDem => typeDem.id);
      }
    });

    this.filteredSubscribe = combineLatest([this.userService.getAll(), this.societeService.getAllManaged(1), this.agenceService.getAllManaged(1)])
    .pipe(switchMap(([usrs, societes, agences]) => {
      this.societes = (societes as SocieteModel[]).sort((a, b) => this.sociteteName(a).localeCompare(this.sociteteName(b)));
      this.agences = (agences as AgenceModel[]).sort((a, b) => this.agenceName(a).localeCompare(this.agenceName(b)));
      this.users = (usrs as UserModel[]);
      return this.filtreService.filtrePerimetre;
    }), debounceTime(1000)).subscribe(result => {
      let idSearchDemandes: number;
      let idSearchActions: number;
      let idSearchDevis: number;
      // idSearchDemandes = this.toastrLoadingService.newSearch();
      // idSearchActions = this.toastrLoadingService.newSearch();
      // idSearchDevis = this.toastrLoadingService.newSearch();
      this.filtreData = result;
    
      this.selectedSocieteIdsArray.clear();
      this.selectedSocietesString  = "";
      this.filtreData.societes.forEach((societe) => {
        this.selectedSocieteIdsArray.add(societe.id);
        this.selectedSocietesString += societe.id + ',';
      });
      this.eventsSubjectSocieteArray.next(this.selectedSocieteIdsArray);
      this.selectedAgenceIdsArray.clear();
      this.filtreData.AgencesIds = (this.filtreData.agences.length === 0 && this.filtreData.societes.length === 0) ? null : [];
      this.filtreData.realisateurIds = (this.filtreData.realisateurs.length === 0) ? null : [];
      this.filtreData.createurIds = (this.filtreData.createurs.length === 0) ? null : [];
      if (this.filtreData.agences.length > 0) {
        this.filtreData.agences.forEach((agence) => {
          this.selectedAgenceIdsArray.add(agence.id);
          if (this.filtreData.societes == null || this.filtreData.societes.length === 0 || this.filtreData.societes.find(soc => soc.id === agence.societeId)) {
            this.filtreData.AgencesIds.push(agence.id);
          }
        });
        this.eventsSubjectAgenceArray.next(this.selectedAgenceIdsArray);
      } else if (this.filtreData.societes.length > 0) {
        this.agences.forEach((agence) => {
          if (this.filtreData.societes.find(soc => soc.id === agence.societeId)) {
            this.filtreData.AgencesIds.push(agence.id);
          }
        });
      }
      this.selectedRealisateurIdsArray.clear();
      this.filtreData.realisateurs.forEach((real) => {
        this.selectedRealisateurIdsArray.add(real.id);
        this.filtreData.realisateurIds.push(real.id);
      });
      this.eventsSubjectRealisateurArray.next(this.selectedRealisateurIdsArray);

      this.selectedCreateurIdsArray.clear();
      this.filtreData.createurs.forEach((crea) => {
        this.selectedCreateurIdsArray.add(crea.id);
        this.filtreData.createurIds.push(crea.id);
      });
      this.eventsSubjectCreateurArray.next(this.selectedCreateurIdsArray);
      this.nbFilterApplied = this.countFilterApplied();
      this.demandeService.getDashboard(this.filtreData).subscribe(value => {
        //this.toastrLoadingService.endSearch(idSearchDemandes);
        this.counterDemandes = value
      }, error => {
        //this.toastrLoadingService.endSearch(idSearchDemandes);
      });

      this.actionService.getDashboard(this.filtreData).subscribe(value => {
        //this.toastrLoadingService.endSearch(idSearchActions);
        this.counterActions = value
      }, error => {
        //this.toastrLoadingService.endSearch(idSearchActions);
      });

      /*
      this.devisService.getDashboard(this.filtreData).subscribe(value => {
        this.toastrLoadingService.endSearch(idSearchDevis);
        this.counterDevis = value
      }, error => {
        this.toastrLoadingService.endSearch(idSearchDevis);
        this.toastr.error('Le chargement des données a échoué. Veuillez vérifier vos droits');
      });*/
    });
  }

  societeChange(event) {
    let array = event
    if(array != undefined && array.length !=0){
      let societes : SocieteModel[] = [];
      societes = this.societes.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.societes = [];
      this.filtreData.societes = societes
      let agences : AgenceModel[] = [];
      agences = this.filtreData.agences.filter(item => array.includes(item.societeId));
      this.filtreData.agences = [];
      this.filtreData.agences = agences
      this.onChange(this.filtreData)
    }else{
      this.filtreData.societes = [];
      this.onChange(this.filtreData)
    }
  }

  agenceChange(event) {
    let array = event
    if(array != undefined && array.length !=0){
      let agences : AgenceModel[] = [];
      agences = this.agences.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.agences = []
      this.filtreData.agences = agences
      this.onChange(this.filtreData)
    }else{
      this.filtreData.agences = []
      this.onChange(this.filtreData)
    }
  }

  ngOnDestroy(): void {
    this.filteredSubscribe.unsubscribe();
    this.eventsSubjectAgenceArray.unsubscribe()
  }

  onChange(event): void {
    this.filtreData.societes.forEach(x=> {
      this.selectedSocieteIdsArray.add(x.id);
      this.selectedSocietesString += x.id+',';
    });
    this.filtreData.agences.forEach(x=> this.selectedAgenceIdsArray.add(x.id));
    this.filtreData.realisateurs.forEach((real) => (this.selectedRealisateurIdsArray.add(real.id)));
    this.filtreData.createurs.forEach((crea) => (this.selectedCreateurIdsArray.add(crea.id)));
    this.filtreService.changeFiltrePerimetre(this.filtreData);
    this.nbFilterApplied = this.countFilterApplied();
  }

  resetSelects() {
    this.resetSelect = true;
  }

  countFilterApplied(): number {
    let nbFilter = 0;
    nbFilter += this.filtreData.createurs?.length > 0 || this.filtreData.createurIds?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.realisateurs?.length > 0 || this.filtreData.realisateurIds?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.agences?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.societes?.length > 0 || this.filtreData.societeIds?.length > 0 ? 1 : 0;
    return nbFilter;
  }

  clearFiltre() {
    this.resetSelect = false;
    this.filtreData = new FiltrePerimetreDTO();
    this.filtreData.createurIds = null;
    this.filtreData.realisateurIds = null;
    this.filtreData.agences = [];
    this.filtreData.societes = [];
    this.filtreData.realisateurs = [];
    this.filtreData.createurs = [];
    this.selectedAgenceIdsArray.clear();
    this.selectedSocieteIdsArray.clear();
    this.selectedRealisateurIdsArray.clear();
    this.selectedCreateurIdsArray.clear();
    this.selectedAgenceIdsArray = new Set();
    this.selectedSocieteIdsArray = new Set();
    this.selectedRealisateurIdsArray = new Set();
    this.selectedCreateurIdsArray = new Set();
    this.selectedSocietesString  = "";
    this.onChange(this.filtreData);
    this.resetSelects();
    this.selectedFiltre?.deselectAll();
  }

  saveFiltre() {
    var newFiltre = Object.assign({},this.filtreData);
    const dialogRef = this.dialog.open(ModalAddFiltrePersonnaliseComponent, {disableClose: true,});
    dialogRef.afterClosed().subscribe(libelle => {
      if(libelle) {
        const dialog = this.dialog.open(ModalSpinnerComponent);
        newFiltre.libelle = libelle;
        this.filtrePerimetreService.post(newFiltre).subscribe(res => {
          newFiltre.id = res.id;
          this.filtres.push(Object.assign({},newFiltre));
          dialog.close();
          this.toastr.success('Filtre ' + libelle + ' enregistré' );
          this.showFiltre(newFiltre);
        }, error => {
          this.toastr.error('L\'ajout de votre filtre a échoué. Veuillez vérifier vos droits');
          dialog.close();
        });
      }
    });
  }

  showFiltre(filtre: FiltrePerimetreDTO) {
    if(filtre){
      if( JSON.stringify(filtre) === JSON.stringify(this.filtreData)){
        //Si on a cliqué sur un filtre déja selectionné on le deselectionne
        this.clearFiltre() 
      }else{
        this.selectedAgenceIdsArray.clear();
        this.selectedSocieteIdsArray.clear();
        this.selectedRealisateurIdsArray.clear();
        this.selectedCreateurIdsArray.clear();
        this.selectedAgenceIdsArray = new Set();
        this.selectedSocieteIdsArray= new Set();
        this.selectedRealisateurIdsArray = new Set();
        this.selectedCreateurIdsArray = new Set();
        this.selectedSocietesString  = "";
        this.filtreData = filtre;
        this.onChange(this.filtreData)
      }    
    } 
  }

  deleteFiltre(event: any, i: number) {
    event.stopPropagation();
    const dialogConf = this.dialog.open(ModalConfirmationComponent, {
      data: {
        title: 'Suppression du filtre : ' + this.filtres[i].libelle,
        message: 'Êtes-vous sûr de vouloir supprimer ce filtre ?'
      }
    });
    dialogConf.afterClosed().subscribe(res => {
      if (res) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.filtrePerimetreService.delete(this.filtres[i].id).subscribe(res => {
          this.filtres.splice(i, 1);
          this.toastr.success('Filtre supprimé');
          dialogRef.close();
        }, error => {
          this.toastr.error('La suppression de votre filtre a échoué. Veuillez vérifier vos droits');
          dialogRef.close();
        });
      }
    });
  }

  duplicateDemande() {
    throw new Error('Method not implemented.');
  }



  goToDemandes(value){
    switch (value) {
      case "A Traiter":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.status = ["A traiter"];
        this.filtre.typeDemandeId = this.allTypeDemandesId.filter(x => x != 1000); //Non urgentes
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "En cours":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.status = ["En cours"];
        this.filtre.typeDemandeId = this.allTypeDemandesId.filter(x => x != 1000); //Non urgentes
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "Reclamation en cours":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.status = ["A traiter", "En cours"];
        this.filtre.typeDemandeId = [4]; //Demande reclamation
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "Clos":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.status = ["Clos"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "Annule":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.status = ["Annulé"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "All":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = this.allTypeDemandesId.filter(x => x != 1000); //Non urgentes
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

        case "InterventionAEnvoyer":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.status = ["A traiter", "En cours"];
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.etatUrgence = [EtatUrgencePxo.TOSEND];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "InterventionSent":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.etatUrgence = [EtatUrgencePxo.SENT];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "InterventionPlanif":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.etatUrgence = [EtatUrgencePxo.PLANIF];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "InterventionRealise":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.etatUrgence = [EtatUrgencePxo.REALISE];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "InterventionValide":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.etatUrgence = [EtatUrgencePxo.VALIDE];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "InterventionAnnule":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.status = ["Annulé"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

      case "InterventionClos":
        this.filtre = new FiltreDemandeDTO();
        this.filtre.typeDemandeId = [1000]; //Demande urgente
        this.filtre.status = ["Clos"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDemande(this.filtre);
        this.router.navigate(["demande"]);
        break;

        case "InterventionAll":
          this.filtre = new FiltreDemandeDTO();
          this.filtre.typeDemandeId = [1000]; //Demande urgente
          this.filtre.societes = this.filtreData.societes;
          this.filtre.agences = this.filtreData.agences;
          this.filtre.createurs = this.filtreData.createurs;
          this.filtre.realisateurs = this.filtreData.realisateurs;
          this.filtreService.changeFiltreDemande(this.filtre);
          this.router.navigate(["demande"]);
          break;

      default:
        break;
    }
  }


  addDevis() {
    this.priseAppelService.clearService()
    this.router.navigate(['prise-appel'])
  }

  
  addDemande() {
    this.priseAppelService.clearService()
    this.router.navigate(['prise-appel'])
  }

  addDemandeUrgente() {
    this.priseAppelService.clearService();
    this.router.navigate(['prise-appel'], { queryParams: { tabIndex: ONGLETSPRISEAPPEL.DEMANDEURGENTE} });
  }

  goToActions(value){
    switch (value) {
      case "A Traiter":
        this.filtre = new FiltreActionDTO();
        this.filtre.status = ["A traiter"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreAction(this.filtre);
        this.router.navigate(["action"]);
        break;

      case "En cours":
        this.filtre = new FiltreActionDTO();
        this.filtre.status = ["En cours"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreAction(this.filtre);
        this.router.navigate(["action"]);
        break;

      case "Reclamation en cours":
        this.filtre = new FiltreActionDTO();
        this.filtre.status = ["A traiter", "En cours"];
        this.filtre.typeActionId = [11]; //Action reclamation
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreAction(this.filtre);
        this.router.navigate(["action"]);
        break;

      case "Clos":
        this.filtre = new FiltreActionDTO();
        this.filtre.status = ["Clos"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreAction(this.filtre);
        this.router.navigate(["action"]);
        break;

      case "Annule":
        this.filtre = new FiltreActionDTO();
        this.filtre.status = ["Annulé"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreAction(this.filtre);
        this.router.navigate(["action"]);
        break;
      case "All":
        this.filtre = new FiltreActionDTO();
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreAction(this.filtre);
        this.router.navigate(["action"]);  
        break;
      default:
        break;
    }
  }


  goToDevis(value){
    switch (value) {
      case "À envoyer":
        this.filtre = new FiltreDevisDTO();
        this.filtre.Statut = ["À envoyer"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDevis(this.filtre);
        this.router.navigate(["devis"]);
        break;

      case "Refusé":
        this.filtre = new FiltreDevisDTO();
        this.filtre.Statut = ["Refusé"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDevis(this.filtre);
        this.router.navigate(["devis"]);
        break;

      case "Transmis":
        this.filtre = new FiltreDevisDTO();
        this.filtre.Statut = ["Transmis"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDevis(this.filtre);
        this.router.navigate(["devis"]);
        break;

      case "Validé":
        this.filtre = new FiltreDevisDTO();
        this.filtre.Statut = ["Validé"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDevis(this.filtre);
        this.router.navigate(["devis"]);
        break;
      case "Annulé":
          this.filtre = new FiltreDevisDTO();
          this.filtre.Statut = ["Annulé"];
          this.filtre.societes = this.filtreData.societes;
          this.filtre.agences = this.filtreData.agences;
          this.filtre.createurs = this.filtreData.createurs;
          this.filtre.realisateurs = this.filtreData.realisateurs;
          this.filtreService.changeFiltreDevis(this.filtre);
          this.router.navigate(["devis"]);
          break; 
      case "All":
        this.filtre = new FiltreDevisDTO();
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDevis(this.filtre);
        this.router.navigate(["devis"]);
        break;
      case "EnvoyéJason" : 
        this.filtre = new FiltreDevisDTO();
        this.filtre.Statut = ["Envoyé à Jason"];
        this.filtre.societes = this.filtreData.societes;
        this.filtre.agences = this.filtreData.agences;
        this.filtre.createurs = this.filtreData.createurs;
        this.filtre.realisateurs = this.filtreData.realisateurs;
        this.filtreService.changeFiltreDevis(this.filtre);
        this.router.navigate(["devis"]);
        break;
      default:
        break;
    }
  }
  
  
  agenceName(agence: AgenceModel) {
    return (agence.societe.libelle + " / " + agence.code + "-" + agence.libelle);
  }

  sociteteName(soc: SocieteModel) {
    return (soc.region.libelle + ' / ' + soc.code + '-' + soc.libelle);
  }

  realisateurChange(event) {
    if (event != undefined && event.length != 0) {
      let realisateurs: CommercialModel[] = [];
      realisateurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.realisateurs = [];
      this.filtreData.realisateurs = realisateurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.realisateurs = [];
      this.onChange(this.filtreData);
    }
  }

  createurChange(event) {
    if (event != undefined && event.length != 0) {
      let createurs: CommercialModel[] = [];
      createurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.createurs = [];
      this.filtreData.createurs = createurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.createurs = [];
      this.onChange(this.filtreData);
    }
  }
}
