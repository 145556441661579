import { SiteGeoService } from './../../services/site-geo.service';
import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { ModalAjoutSiteGeoComponent } from './modal-ajout-site-geo.component';
import { Router } from '@angular/router';
import { ModalAfficherSiteGeoComponent } from './modal-afficher-site-geo.component';
import { TrieTabService } from '../../services/trie-tab.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalInfoOkComponent } from '../divers/modals/modal-info-ok.component';
import { SiteGeoPgResult } from '../../shared/dtos/siteGeoPgResult';
import { DemandeDTO } from '../../shared/dtos/demandeDTO';
import { DemandeService } from '../../services/demande.service';
import { ModalHistoriqueDemandesComponent } from 'src/app/modules/shared/components/modals/modal-historique-demandes/modal-historique-demandes.component';


export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-site-geographique',
  templateUrl: './site-geographique.component.html',
  styleUrls: ['./site-geographique.component.scss']
})
export class SiteGeographiqueComponent implements OnChanges {
  @Input() tier: TierModel;
  @Input() selectRow: boolean = false;
  @Input() simpleQueryResponse: SimpleQueryResponse<SiteGeoPgResult>;
  @Output() onUpdated = new EventEmitter<SiteGeoModel[]>();
  @Output() onSelect = new EventEmitter<SiteGeoModel>();
  displayedColumns: string[] = ['libelle', 'adresse', 'codePostal', 'ville', 'actions'];
  dataSource = [];
  matDataSource: MatTableDataSource<SiteGeoModel>;
  selectedSite: SiteGeoModel;
  sortState: Sort = {active: 'libelle', direction: 'asc'};
  length: number;
  pageIndex: number;
  pageSize = 10;
  result: boolean;
  constructor(private router: Router,
    private toastr: IntercomToastrService,
    public dialog: MatDialog,
    private demandeService: DemandeService,
    private sitegeoService: SiteGeoService,
    private comp: TrieTabService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tier?.id) {
      if(this.simpleQueryResponse){
        this.dataSource = this.simpleQueryResponse.objets;
        const data = this.dataSource.slice();
        this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
        this.length = this.simpleQueryResponse.count;
        this.pageIndex = this.simpleQueryResponse.pageNumber;
        this.pageSize = this.simpleQueryResponse.pageSize;
      }else{
        this.loadPage();
      }
    }
  }

  openEvent(site: SiteGeoModel) {
    this.onSelect.emit(site);
  }

  onAddSite(): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ModalAjoutSiteGeoComponent, {disableClose: true,
      width: '700px',
      data: {
        tierId: this.tier.id,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.result = result;
      if (this.result) {
        this.loadPage();
      }
    });
  }

  onOpenSite(event: any, id: number): void {
    event.stopPropagation();
    this.sitegeoService.get(id).subscribe((s: SiteGeoModel) => {
      this.selectedSite = s;
      const dialogRef = this.dialog.open(ModalAfficherSiteGeoComponent, {disableClose: true,
        width: '700px',
        data: {
          site: this.selectedSite,
          result: this.result
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.result = result;
        if (this.result) {
          this.loadPage();
        }
      });
    });
  }

  onDeleteSite(event: any, site: SiteGeoModel) {
    const dialogConf = this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Suppression de l\'adresse : ' + site.libelle,
          message: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
          color: 'warn'
        }
    });
    dialogConf.afterClosed().subscribe(res => {
      if (res) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.sitegeoService.delete(site.id).subscribe((site: SiteGeoModel) => {
          this.toastr.success('Suppression de l\'adresse ' + site.libelle + ' avec succès');
          this.loadPage();
          dialogRef.close();
        }, error => {
          this.toastr.error('Suppression de l\'adresse en échec. Veuillez vérifier vos droits');
          dialogRef.close();
        });
      }
    });
  }

  changePage(event?: PageEvent) {
    this.pageIndex =  event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPage();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadPage();
  }

  openHistoriqueDemandesAdresses(siteGeo: SiteGeoModel) {
    var demandeDTO = new DemandeDTO();
    demandeDTO.siteGeoId = siteGeo.id;
    demandeDTO.tierId = siteGeo.tierId;
    const query = new SimpleQuery<DemandeDTO>();
    query.entry = demandeDTO;
    query.order = 'DateCreation';
    query.descending = true;
    query.pageSize = 10;
    this.demandeService.getFromObjet(query).subscribe(res =>  {
      const dialogRef = this.dialog.open(ModalHistoriqueDemandesComponent, {
        data: {
          title: "Historique des demandes du tiers",
          demandes: res.objets,
          query: query
        }
      });
    })
  }

  loadPage() {
    const query = new SimpleQuery<SiteGeoModel>();
    query.entry = new SiteGeoModel();
    query.entry.tierId = this.tier.id;
    query.pageNumber = this.pageIndex;
    query.pageSize = this.pageSize;
    query.order = this.sortState.active.charAt(0).toUpperCase() + this.sortState.active.slice(1);
    query.descending = this.sortState.direction === 'desc' ? false : true;

    this.sitegeoService.GetFromObjetPgWithPagination(query).subscribe((response: SimpleQueryResponse<SiteGeoPgResult>) => {
      this.dataSource = response.objets;
      const data = this.dataSource.slice();
      this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
      this.length = response.count;
      this.pageIndex = response.pageNumber;
      this.pageSize = response.pageSize;
    });
  }
}


