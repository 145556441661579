  <div class="col-md-12">
     <h5>Adresse d'intervention :</h5>
  </div >

  <div class="col-sm-2">
    <mat-form-field class="full-width">
        <mat-label>Libellé</mat-label>
        <input matInput [value]="this.adresse ?  this.adresse.libelle : ''" disabled>
      </mat-form-field>
  </div>
  
  <div class="col-sm-4">
    <mat-form-field style="padding-left: 0px !important;">
        <mat-label>Adresse</mat-label>
        <input matInput [value]="this.adresse.adresse" disabled>
      </mat-form-field>
  </div>

  <div class="col-sm-2">
    <mat-form-field class="full-width">
      <mat-label>Code postal</mat-label>
      <input required matInput placeholder="" [value]="this.adresse.codePostal" name="intercom-codepostal" disabled>
    </mat-form-field>
  </div>

  <div class="col-sm-2">
    <mat-form-field class="full-width">
      <mat-label>Ville</mat-label>
      <input required  matInput placeholder="" [value]="this.adresse.ville" name="intercom-ville" disabled>
    </mat-form-field>
  </div>

  <div class="col-sm-2" style="text-align: center;">
    <button mat-mini-fab color="primary" [matTooltip]="timeTravel !== '' ? timeTravel : 'Afficher l\'itinéraire sur google map'" 
    (click)="openGMap()">
    <mat-icon style="color: white;"> pin_drop </mat-icon>
  </button>
  <button mat-mini-fab color="primary" *ngIf="modifiable">
    <mat-icon style="color: white;" (click)="editAdresse()" > create </mat-icon>
  </button>
  </div>

  
