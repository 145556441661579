export class DevisBasicModel {
    id: number;
    // Date de création
    dCreation: Date;
    idCreateur?: number;
    // Date de modification
    dModification: Date;
    // Date de création
    idModifieur: Date;
    // Date de modification
    dDesactivation: Date;
    // Date de modification
    idDesativateur: number;
}
