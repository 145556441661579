import { TierBasicModel } from './basic-models/tier-basic.model';
import { TypeDemandeModel } from './type-demande.model';
import { UserModel } from './users/user.model';
import { ActiviteModel } from './prestation.model';
import { ActionModel } from './action.model';
import { DocumentModel } from './document/document.model';
import { AgenceModel } from './rsa/agence.model';
import { TierModel } from './tier/tier.model';
import { InterventionPxoModel } from './interventionPxo.model';

export class DemandeModel extends TierBasicModel {
  public tierFacture?: TierModel;
  public tierFactureId?: number;
  public typeDemande?: TypeDemandeModel;
  public typeDemandeId: number;
  public objet = '';
  public statut = 'A traiter';
  public etat?: string;
  public numDemande: string;
  public createur?: UserModel;
  public createurId: number;
  public commercial?: UserModel;
  public commercialId: number;
  public gestionnaire?: UserModel;
  public gestionnaireId: number;
  public commentaire: string;
  public dateIntervention: Date;
  // foreign key
  public idIntervention: number;
  public interventionPxo : InterventionPxoModel;

  public dateCreation: Date;
  public dateCloture: Date;
  public prestations?: ActiviteModel[] = [];
  public prestationIds?: string = '';
  public actions?: ActionModel[];
  public visite: boolean;
  public dechets: boolean;
  public bsa: boolean;
  public bsd: boolean;
  public reponseForm: string;
  public documents?: DocumentModel[];
  public agenceId?: number;
  public agence?: AgenceModel;

  public siteGeoId?: number;
  public numSite = '';
  public libelleAdresse = '';
  public codePostalAdresse = '';
  public villeAdresse = '';
  public telephoneAdresse = '';
  public adresse = '';
  public complementAdresse = '';
  public commentaireAdresse = '';
  public emailAdresse = '';
  public gpsAdresse = '';

  public libelleAdresseFact = '';
  public codePostalAdresseFact = '';
  public villeAdresseFact = '';
  public telephoneAdresseFact = '';
  public adresseFact = '';
  public complementAdresseFact = '';
  public commentaireAdresseFact = '';
  public emailAdresseFact = '';
  public gpsAdresseFact = '';

  public interlocuteurId?: number = null;
  public telPrincipalInterlocuteur = '';
  public telSecondaireInterlocuteur = '';
  public nomInterlocuteur = '';
  public prenomInterlocuteur = '';
  public civiliteInterlocuteur = '';
  public civiliteInterlocuteurId?: number;
  public emailInterlocuteur = '';
  public commentaireInterlocuteur = '';
  public roleInterlocuteur = '';
  public idJasonInterlocuteur?: number;
  public idJasonSiteGeo?: number;

  public CiviliteId?: number;
  public prestaLibs: string;

  public envoyeAJason: number;
  public dAccepteJason: Date;
  public dDernierEnvoiJason: Date;
}
