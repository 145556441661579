
<h3><span *ngIf="data.devisDTO && data.devisDTO.agenceLibelle">{{data.devisDTO.agenceLibelle}}</span><br></h3>
<div class="container">
    <div class="firstGridContainer">
        <div>
            <h3>TIERS DO <br></h3>
            <b>Libellé : </b><span *ngIf="data.tierDODevis && data.tierDODevis.libelle">{{data.tierDODevis.libelle}}</span><br>
            <b>Adresse : </b><span *ngIf="data.tierDODevis && data.tierDODevis.adresse">{{data.tierDODevis.adresse + ', '}}</span>
            <span *ngIf="data.tierDODevis && data.tierDODevis.complementAdresse && data.tierDODevis.complementAdresse.trim()">{{data.tierDODevis.complementAdresse + ', '}}</span>
            <span *ngIf="data.tierDODevis && data.tierDODevis.codePostal && data.tierDODevis.ville">{{data.tierDODevis.codePostal + ', ' + data.tierDODevis.ville}}</span><br>
            <b>Mail : </b><span *ngIf="data.tierDODevis && data.tierDODevis.email">{{data.tierDODevis.email}}</span><br>
            <b>Numéro téléphone : </b><span *ngIf="data.tierDODevis && data.tierDODevis.telephone">{{data.tierDODevis.telephone}}</span><br>
            <b>Intercom ID : </b><span *ngIf="data.tierDODevis && data.tierDODevis.id">{{data.tierDODevis.id}}</span><br>
            <b>Jason ID : </b><span *ngIf="data.tierDODevis && data.tierDODevis.identifiantJason">{{data.tierDODevis.identifiantJason}}</span><br>
        </div>

        <div>
            <h3>TIERS FACTURÉ<br></h3>
            <b>Libellé : </b><span *ngIf="data.tierFactDevis && data.tierFactDevis.libelle">{{data.tierFactDevis.libelle}}</span><br>
            <b>Adresse : </b><span *ngIf="data.tierFactDevis && data.tierFactDevis.adresseFact">{{data.tierFactDevis.adresseFact + ', '}}</span>
            <span *ngIf="data.tierFactDevis && data.tierFactDevis.complementAdresseFact && data.tierFactDevis.complementAdresseFact.trim()">{{data.tierFactDevis.complementAdresseFact + ', '}}</span>
            <span *ngIf="data.tierFactDevis && data.tierFactDevis.codePostalFact && data.tierFactDevis.villeFact">{{data.tierFactDevis.codePostalFact
                + ', ' + data.tierFactDevis.villeFact}}</span><br>
            <b>Intercom ID : </b><span *ngIf="data.tierFactDevis && data.tierFactDevis.id">{{data.tierFactDevis.id}}</span><br>
            <b>Jason ID : </b><span *ngIf="data.tierFactDevis && data.tierFactDevis.identifiantJason">{{data.tierFactDevis.identifiantJason}}</span><br>
        </div>

        <div>
            <h3>INTERLOCUTEUR<br></h3>
            <b>Libellé : </b><span *ngIf="data.devisDTO && data.devisDTO.civiliteInterlocuteur">{{data.devisDTO.civiliteInterlocuteur + ' '}}</span>
            <span *ngIf="data.devisDTO.prenomInterlocuteur">{{data.devisDTO.prenomInterlocuteur + ' '}}</span>
            <span *ngIf="data.devisDTO.nomInterlocuteur">{{data.devisDTO.nomInterlocuteur | uppercase}}</span><br>
            <b>Fonction : </b><span *ngIf="data.devisDTO.roleInterlocuteur">{{data.devisDTO.roleInterlocuteur}}</span><br>
            <b>Numéro téléphone principal : </b><span *ngIf="data.devisDTO.telInterlocuteur">{{data.devisDTO.telInterlocuteur}}</span><br>
            <b>Numéro téléphone secondaire : </b><span *ngIf="data.devisDTO.telSecondaire">{{data.devisDTO.telSecondaire}}</span><br>
            <b>Commentaire : </b><span *ngIf="data.devisDTO.commentaireInterlocuteur">{{data.devisDTO.commentaireInterlocuteur}}</span><br>
            <b>Mail : </b><span *ngIf="data.devisDTO.emailInterlocuteur">{{data.devisDTO.emailInterlocuteur}}</span><br>
            <b>Jason ID : </b><span *ngIf="data.devisDTO.idJasonInterlocuteur">{{data.devisDTO.idJasonInterlocuteur}}</span><br>
        </div>
    </div>

    <div class="secondGridContainer">
        <div>
            <h3>DEMANDE</h3>
            <b>Objet : </b><span *ngIf="data.demande.objet">{{data.demande.objet}}</span><br>
            <b>Type : </b><span *ngIf="data.demande.typeDemande.libelle">{{data.demande.typeDemande.libelle}}</span><br>
            <b>Date d'échéance : </b><span *ngIf="data.demande.dateIntervention">{{data.demande.dateIntervention | date : 'dd/MM/YY'}}</span><br>
            <span *ngFor=""></span>
            <b>Activité : </b><span *ngFor="let presta of stringsPresta">
                    {{presta + ' '}}
                </span><br>
            <b>Adresse d'intervention : </b><span *ngIf="data.demande && data.demande.adresse">{{data.demande.adresse + ', '}}</span>
            <span *ngIf="data.demande && data.demande.complementAdresse && data.demande.complementAdresse.trim()">{{data.demande.complementAdresse + ', '}}</span>
            <span *ngIf="data.demande && data.demande.codePostalAdresse && data.demande.villeAdresse">{{data.demande.codePostalAdresse + ', ' + data.demande.villeAdresse}}</span><br>
            <b>Commentaire : </b><br>
            <span *ngIf="data.demande.commentaire">{{data.demande.commentaire}}<br></span>
        </div>

        <div>
            <h3>DEVIS</h3>
            <b>Statut : </b><span *ngIf="data.devisDTO && data.devisDTO.statut">{{data.devisDTO.statut}}</span><br>
            <b>Critères de calcul</b><br>
            <b>Tarifs : </b><span *ngIf="data.devisDTO && data.devisDTO.zone">{{data.devisDTO.zone}}</span><br>
            <div *ngIf="data.devisDTO && data.devisDTO.prestationsClient">
                <div *ngFor="let prestaClient of data.devisDTO.prestationsClient">
                    <div *ngIf="prestaClient.lignes">
                        <div *ngFor="let ligne of prestaClient.lignes">
                            {{ligne.libelle}}<br>
                        </div>
                    </div>
                </div> 
            </div>
            <b>Description :</b><br>
            <span *ngIf="data.devisShared.description && data.devisShared.description.descriptionOuvrage">{{data.devisShared.description.descriptionOuvrage}}<br></span>
            <b>Conditions d'intervention :</b><br>
            <span *ngIf="data.devisShared.description && data.devisShared.description.modalite">{{data.devisShared.description.modalite}}<br></span>
            <b>À la charge du client :</b><br>
            <span *ngIf="data.devisShared.description && data.devisShared.description.chargeClient">{{data.devisShared.description.chargeClient}}<br></span>
            <b>Montant : </b><span *ngIf="data.devisDTO && data.devisDTO.ht && data.devisDTO.ttc">{{data.devisDTO.ttc + '€ TTC / ' + data.devisDTO.ht + ' € HT'}}</span><br>
            <b>Attestation TVA 10% : </b><span *ngIf="data.devisDTO && data.devisDTO.documents && checkTVADoc(); else printNo">Oui</span>
            <ng-template #printNo>Non</ng-template>
            <br>
        </div>
    </div>
</div>