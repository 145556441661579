import { BasicMock } from './basic-mock/basic.mock';
import { TypeDemandeModel } from '../models/type-demande.model';
import { Injectable } from "@angular/core";

@Injectable()
export class TypeDemandeMock extends BasicMock  {
  constructor() {
    super();
    (<TypeDemandeModel[]> this.objets) = [
      {id: 1, libelle: 'Appel'},
      {id: 2, libelle: 'Rdv'},
      {id: 3, libelle: 'Devis'},
      {id: 4, libelle: 'Visite chantier'},
      {id: 5, libelle: 'Etude Chantier'},
    ];
  }
}
