import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { FiltreService } from 'src/app/modules/core/services/filter.service';
import { ActiviteService } from 'src/app/modules/core/services/prestation.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { FiltreDevisDTO } from 'src/app/modules/core/shared/dtos/filterDevisDTO';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { ActiviteModel } from 'src/app/modules/core/shared/models/prestation.model';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { DevisDTO } from 'src/app/modules/devis/DTOs/devisDTO';
import { DevisService } from 'src/app/modules/devis/services/devis.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalSpinnerComponent } from '../../../divers/modals/modal-spinner/modal-spinner.component';
import { saveAs } from 'file-saver';
import { SocieteModel } from 'src/app/modules/core/shared/models/rsa/societe.model';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { SocieteService } from 'src/app/modules/core/services/rsa/societe.service';
import { CommercialModel } from 'src/app/modules/core/shared/models/users/commercial.model';
import * as _ from 'lodash';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';

@Component({
  selector: 'app-search-devis',
  templateUrl: './search-devis.component.html',
  styleUrls: ['./search-devis.component.scss']
})
export class SearchDevisComponent implements OnInit {

  datasource: DevisDTO[] = [];
  devisDTO: DevisDTO = new DevisDTO();
  users: UserModel[] = [];
  realisateur: UserModel;
  createur: UserModel;
  activites: ActiviteModel[] = [];
  simpleQueryResponse =  new SimpleQueryResponse<DevisDTO>();
  panelOpenState = true;
  loading = false;
  statuts = ['À envoyer', 'Transmis', 'Validé', 'Envoyé à Jason', 'Annulé', 'Refusé'];
  pageSize = 50;
  pageIndex = 0;
  length = 0;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  prestations: ActiviteModel[] = [];
  filtreData : FiltreDevisDTO = new FiltreDevisDTO();
  devisList : DevisDTO[] = []
  simpleQueryResponse1 =  new SimpleQueryResponse<DevisDTO>();
  private filteredSubscribe: Subscription;
  labelTiers = "Tiers"
  numDemandes : DemandeModel[];
  listStatus = ['À envoyer', 'Transmis', 'Validé', 'Envoyé à Jason', 'Annulé', 'Refusé'];
  listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);
  resetSelect = true;
  numeroDemande : "";

  selectedSocieteIdsArray = new Set<number>();
  selectedAgenceIdsArray = new Set<number>();
  societes: SocieteModel[];
  agences: AgenceModel[];
  selectedSocietesString : string = "";

  eventsSubjectAgenceArray: Subject<Set<number>> = new Subject();
  eventsSubjectSocieteArray: Subject<Set<number>> = new Subject();

  selectedRealisateurIdsArray = new Set<number>();
  eventsSubjectRealisateurArray: Subject<Set<number>> = new Subject();
  selectedCreateurIdsArray = new Set<number>();
  eventsSubjectCreateurArray: Subject<Set<number>> = new Subject();

  allAgences : AgenceModel[] = [];

  nbFilterApplied: number = 0;

  private modalsService: ModalsService;
  public dateSubject: Subject<Date>;
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private devisService: DevisService,
    private demandeService: DemandeService,
    private activiteService: ActiviteService,
    private IntercomToastrService: IntercomToastrService,
    private filtreService: FiltreService,
    private changeDetector: ChangeDetectorRef,
    private agenceService: AgenceService,
    private societeService: SocieteService,
    private cdr:ChangeDetectorRef,
    private prestationService: ActiviteService,
    private toastrLoadingService: ToastrLoadingService,
    injector: Injector) {
      this.modalsService = injector.get('ModalsService');
    }
   
  ngOnInit(): void {

    this.agenceService.getAllManaged(1).subscribe(agences => {
      this.allAgences = agences as AgenceModel[];
    });

    this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
      this.prestations = prestas;
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos demandes")
    );

    this.devisDTO.code = "";
    this.devisDTO.statut = "";
    this.pageIndex = 0;
    
    this.dateSubject = new Subject<Date>();
    this.dateSubject.pipe(debounceTime(100)).subscribe(_ => this.onSearch());
    this.filteredSubscribe = combineLatest([
      this.userService.getAll(), 
      this.societeService.getAllManaged(1), 
      this.agenceService.getAllManaged(1),
      this.activiteService.getAll()])
    .pipe(switchMap(([usrs, societes, agences, activites]) => {
      this.societes = (societes as SocieteModel[]).sort((a, b) => this.sociteteName(a).localeCompare(this.sociteteName(b)));
      this.agences = (agences as AgenceModel[]).sort((a, b) => this.agenceName(a).localeCompare(this.agenceName(b)));
      this.users = (usrs as UserModel[]);
      this.activites = activites as ActiviteModel[];
      return this.filtreService.filtreDevis;
    }), debounceTime(100)).subscribe(result => {
      let idSearch: number;
      idSearch = this.toastrLoadingService.newSearch();
      this.filtreData = result;
      this.selectedSocieteIdsArray.clear();
      this.selectedSocietesString  = "";
      this.filtreData.societes.forEach((societe) => {
        this.selectedSocieteIdsArray.add(societe.id);
        this.selectedSocietesString += societe.id + ',';
      });
      this.eventsSubjectSocieteArray.next(this.selectedSocieteIdsArray);
      this.selectedAgenceIdsArray.clear();
      this.filtreData.AgencesIds = (this.filtreData.agences.length === 0 && this.filtreData.societes.length === 0) ? null : [];
      this.filtreData.realisateurIds = (this.filtreData.realisateurs.length === 0) ? null : [];
      this.filtreData.createurIds = (this.filtreData.createurs.length === 0) ? null : [];
      if (this.filtreData.agences.length > 0) {
        this.filtreData.agences.forEach((agence) => {
          this.selectedAgenceIdsArray.add(agence.id);
          if (this.filtreData.societes == null || this.filtreData.societes.length === 0 || this.filtreData.societes.find(soc => soc.id === agence.societeId)) {
            this.filtreData.AgencesIds.push(agence.id);
          }
        });
        this.eventsSubjectAgenceArray.next(this.selectedAgenceIdsArray);
      } else if (this.filtreData.societes && this.filtreData.societes.length > 0) {
        this.agences.forEach((agence) => {
          if (this.filtreData.societes.find(soc => soc.id === agence.societeId)) {
            this.filtreData.AgencesIds.push(agence.id);
          }
        });
      }
      this.selectedRealisateurIdsArray.clear();
      this.filtreData.realisateurs.forEach((real) => {
        this.selectedRealisateurIdsArray.add(real.id);
        this.filtreData.realisateurIds.push(real.id);
      });
      this.eventsSubjectRealisateurArray.next(this.selectedRealisateurIdsArray);

      this.selectedCreateurIdsArray.clear();
      this.filtreData.createurs.forEach((crea) => {
        this.selectedCreateurIdsArray.add(crea.id);
        this.filtreData.createurIds.push(crea.id);
      });
      this.eventsSubjectCreateurArray.next(this.selectedCreateurIdsArray);
      
      if(result.Statut){
        this.listStatusUpdated.next(result.Statut);
      }

      var tempFilter = Object.assign({},this.filtreData);

      var societeIds = tempFilter.societes.length != 0 ? tempFilter.societes.map(x=>x.id) : null;
      if(societeIds != null){ //On verifie si on a bien selectionné des societes
        var allAgencesCopy = Object.assign([],this.allAgences);
        var idAgences = allAgencesCopy.filter(x=>societeIds.includes(x.societeId)).map(x=>x.id)
        if(idAgences.length != 0){//on verifie si des agences sont bien liées a cette société
        tempFilter.AgencesIds = [...new Set(idAgences.concat(this.filtreData.AgencesIds))];
        }else{//si les societe selectionnées n'ont pas d'agence on met un idAgence faux pour avoir 0 résultats
          tempFilter.AgencesIds = [-9999]
        }
      }
      this.nbFilterApplied = this.countFilterApplied();
      this.devisService.getFiltredDevis(tempFilter).subscribe(devisQueryResp => {
        if (devisQueryResp)
        {
          this.devisList = devisQueryResp.objets;
          this.simpleQueryResponse1 = devisQueryResp;
          this.length = devisQueryResp.count;
          this.loading = false;
          this.searchResult(devisQueryResp.objets);
          this.toastrLoadingService.endSearch(idSearch);
        } else {
          this.devisList = [];
          this.simpleQueryResponse1 = new SimpleQueryResponse<DevisDTO>();
          this.length = 0;
          this.loading = false;
          this.toastrLoadingService.endSearch(idSearch);
          this.searchResult([]);
        }
      }, error => {
        this.toastrLoadingService.endSearch(idSearch);
      });
      
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.filteredSubscribe.unsubscribe();
  }

  resetSelects(){
    this.listStatus = ['À envoyer', 'Transmis', 'Validé', 'Envoyé à Jason', 'Annulé', 'Refusé'];
    this.listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);
    this.resetSelect = true;
  }  

  
  clearFiltre(){
    this.resetSelect = false;
    this.filtreData = new FiltreDevisDTO();
    this.filtreData.pageNumber = 0;
    this.filtreData.agences = [];
    this.filtreData.societes = [];
    this.filtreData.realisateurs = [];
    this.filtreData.createurs = [];
    this.filtreData.createurIds = null;
    this.filtreData.realisateurIds = null;
    this.selectedAgenceIdsArray.clear();
    this.selectedSocieteIdsArray.clear();
    this.selectedRealisateurIdsArray.clear();
    this.selectedCreateurIdsArray.clear();
    this.selectedAgenceIdsArray = new Set();
    this.selectedSocieteIdsArray = new Set();
    this.selectedRealisateurIdsArray = new Set();
    this.selectedCreateurIdsArray = new Set();
    this.selectedSocietesString  = "";
    this.selectedSocietesString  = "";
    this.filtreService.changeFiltreDevis(this.filtreData);
    this.resetSelects()
  }

  load(): void {
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
    });
  }

  loadData(value: string){
    if(value){
      this.demandeService.getAllFiltred(value).subscribe(x => {
        this.numDemandes = x;
      })
    }else{
      this.numDemandes = [];
    }
  }

  onSelectOptionChange(event, state){
    switch(state){
      case 'Statut' :
        this.filtreData.Statut = event;
        break;        
      case 'Activites' : 
      this.filtreData.IdActivites = event;
      break;
    }
    
    this.onChange(event)
  }

  onChange(event): void {
    this.selectedSocietesString  = "";
    this.filtreData.societes.forEach((societe) => {
      this.selectedSocieteIdsArray.add(societe.id);
      this.selectedSocietesString += societe.id + ',';
    });
    this.filtreData.agences.forEach((agence) => (this.selectedAgenceIdsArray.add(agence.id)));
    this.filtreData.realisateurs.forEach((real) => (this.selectedRealisateurIdsArray.add(real.id)));
    this.filtreData.createurs.forEach((crea) => (this.selectedCreateurIdsArray.add(crea.id)));
    this.filtreService.changeFiltreDevis(this.filtreData);
    this.nbFilterApplied = this.countFilterApplied();
  }

  onSearch() {
    this.onSearchDevis(new SimpleQuery<DevisDTO>(this.devisDTO));
  }

  onSearchReal(id: number) {
    if (id === null) {
      this.devisDTO.idRealisateur = undefined;
    } else {
      this.devisDTO.idRealisateur = id;
    }
    this.onSearch();
  }

  onSearchCreat(id: number) {
    if (id === null) {
      this.devisDTO.idCreateur = undefined;
    } else {
      this.devisDTO.idCreateur = id;
    }
    this.onSearch();
  }

  onSearchTiers(value: string) {
    this.devisDTO.raisonSociale = value;
    this.onSearch();
  }

  searchResult(searchWord: any) {
    if (searchWord.length === 0) {
      this.IntercomToastrService.warning('Oups : ' +  'Aucun résultat pour votre recherche');
    }
  }

  onSearchDevis(query: SimpleQuery<DevisDTO>) {
    this.loading = true;
    query.entry = this.devisDTO;
    this.devisService.getFromObjet(query).subscribe((response: SimpleQueryResponse<DevisDTO>) => {
      this.datasource = response.objets;
      this.simpleQueryResponse = response;
      this.length = response.count;
      this.loading = false;
      this.searchResult(response.objets);
    }, error => {
      this.loading = false;
      this.IntercomToastrService.error('Problème technique', 'Erreur du chargement');
    });
  }

  onChangePage(pageEvent: SimpleQuery<DevisDTO>) {
    const devisFilter = new SimpleQuery(new FiltreDevisDTO());
    if(pageEvent.order){
      this.filtreData.order = pageEvent.order;
    }
    if(pageEvent){
      this.filtreData.descending = pageEvent.descending;
    }
    this.filtreData.pageNumber = pageEvent.pageNumber;
    this.filtreData.pageSize = pageEvent.pageSize;
    this.filtreService.changeFiltreDevis(this.filtreData);
  }

  onOpenDemande(id: number): void {
    this.demandeService.get(id).subscribe(demande => {
      this.modalsService.openDemande(demande).subscribe(res => {
        this.demandeService.get(id).subscribe(demande => {
          this.devisService.getFromDemande(demande.id, demande.agenceId).subscribe(dev => {
            if (dev) {
              var demModifIndex = this.devisList.findIndex(x => x.id == dev.id)
              this.devisList[demModifIndex] = dev;
              this.devisList = Object.assign([], this.devisList);
            }
          })
        })
      });
    });
  }

  onExport(): void {
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    this.devisService.exportDevis(this.filtreData).subscribe((res: BlobPart)  => {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res], {type: 'text/csv;charset=utf-8'});
      saveAs(blob, 'Export_Devis_' + new Date().toISOString() + '.csv');
      dialogRef.close();
    }, (error) => dialogRef.close());
  }

  societeChange(event) {
    if (event != undefined && event.length != 0) {
      let societes: SocieteModel[] = [];
      societes = this.societes.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.societes = [];
      this.filtreData.societes = societes;
      let agences : AgenceModel[] = [];
      agences = this.filtreData.agences.filter(item => event.includes(item.societeId));
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.societes = [];
      this.onChange(this.filtreData);
    }
  }

  agenceChange(event) {
    if (event != undefined && event.length != 0) {
      let agences: AgenceModel[] = [];
      agences = this.agences.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.agences = [];
      this.onChange(this.filtreData);
    }
  }

  agenceName(agence: AgenceModel) {
    return (agence.societe.libelle + " / " + agence.code + "-" + agence.libelle);
  }

  sociteteName(soc: SocieteModel) {
    return (soc.region.libelle + ' / ' + soc.code + '-' + soc.libelle);
  }

  realisateurChange(event) {
    if (event != undefined && event.length != 0) {
      let realisateurs: CommercialModel[] = [];
      realisateurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.realisateurs = [];
      this.filtreData.realisateurs = realisateurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.realisateurs = [];
      this.onChange(this.filtreData);
    }
  }

  createurChange(event) {
    if (event != undefined && event.length != 0) {
      let createurs: CommercialModel[] = [];
      createurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.createurs = [];
      this.filtreData.createurs = createurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.createurs = [];
      this.onChange(this.filtreData);
    }
  }

  countFilterApplied(): number {
    let nbFilter = 0;
    nbFilter += this.filtreData.Adresse?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.agences?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.Code?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.CodePostal?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.DStarClotureDevis !== null || this.filtreData.DEndClotureDevis !== null ? 1 : 0;
    nbFilter += this.filtreData.DStartCreationDevis !== null || this.filtreData.DEndCreationDevis !== null ? 1 : 0;
    nbFilter += this.filtreData.IdActivites?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.Objet?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.Statut?.length > 0 && this.filtreData.Statut?.length < this.statuts.length ? 1 : 0;
    nbFilter += this.filtreData.Ville?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.createurs?.length > 0 || this.filtreData.createurIds?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.realisateurs?.length > 0 || this.filtreData.realisateurIds?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.societes?.length > 0 ? 1 : 0;
    return nbFilter;
  }

}
