import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface DialogData {
  title: string;
  message: string;
  result: boolean;
}

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ModalConfirmationComponent>
  ) {}

  onModalCancel() {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm() {
    this.data.result = true;
    this.dialogRef.close(true);
  }
}
