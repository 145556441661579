<div class="col-sm-12">
  <div class="col-sm-4">
    <h1>Gestion utilisateur</h1>
  </div>
  <div class="col-sm-4">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Rechercher un utilisateur</mat-label>
      <input matInput placeholder="Ex: Dupont" [(ngModel)]="objet.query" (change)="search()">
    </mat-form-field>
  </div>
  <div class="col-sm-2 text-center">
    <button mat-flat-button color="primary" type="button" (click)="search()"><mat-icon>search</mat-icon>Rechercher</button>
  </div>
  <div class="col-sm-2 text-center">
    <button type="button"(click)="create_user()" mat-stroked-button color="success" ><mat-icon>add</mat-icon> Créer un utilisateur </button>
  </div>
</div>

<div class="col-sm-12 table-inner-scroll-container">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <caption></caption>
    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
    </ng-container>

    <ng-container matColumnDef="prenom">
      <th mat-header-cell *matHeaderCellDef> Prénom </th>
      <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
    </ng-container>

    <ng-container matColumnDef="tel">
      <th mat-header-cell *matHeaderCellDef> Téléphone </th>
      <td mat-cell *matCellDef="let element"> {{element.tel}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type utilisateur </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="agence">
      <th mat-header-cell *matHeaderCellDef> Agence </th>
      <td mat-cell *matCellDef="let element"> {{element.agence ? element.agence.libelle : ''}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="openEvent(row)"></tr>
  </table>
</div>
<mat-paginator
  class="paginator"
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 25, 50]"
  (page)="changePage($event)">
</mat-paginator>
