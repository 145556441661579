
<div class="col-sm-12">
  <div class="col-md-4">
    <h2>Gestion des zones d'intervention</h2>
  </div>
  <div class="col-md-2">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Rechercher une zone d'intervention</mat-label>
      <input matInput name="zone" [(ngModel)]="objet.query" (change)="search()">
    </mat-form-field>
  </div>
  <div class="col-lg-2 text-center" >
    <a mat-stroked-button color="success" (click)="onAddZone()"><mat-icon>add</mat-icon> Ajouter une zone d'intervention</a>
  </div>
  <div class="col-md-2 text-center" >
    <button mat-flat-button color="success"  (click)="openModalUrl()" 
    matTooltip="Importer des zones d'intervention">Importer</button>
  </div>

  <div class="col-md-2  text-center" >
    <button mat-flat-button color="primary" class="btn-hover" (click)="redirect()"
    matTooltip="Ouvrir le fichier d'exemple" >Fichier d'exemple</button>
  </div>
</div>

<div class="col-sm-12 row text-center center-div" *ngIf="this.loading">
  <div class="col-sm-6" style="align-content: center;">
    <p>Importation des zones en cours .. Merci de ne pas fermer la page</p>
    <p>{{message}}</p>
    <mat-progress-bar mode="determinate" [value]="avancement * 100"></mat-progress-bar>
  </div>
</div>

<div class="col-sm-12 row text-center center-div" *ngIf="this.loadingExport">
  <div class="col-sm-6" style="align-content: center;">
    <p>Export des zones en cours .. Merci de ne pas fermer la page</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
 
<div div class="col-sm-12 table-inner-scroll-container">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="libelle" matSortDirection="asc" matSortDisableClear>
    <caption></caption>
    <ng-container matColumnDef="modifications">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell class="action-btns" *matCellDef="let row">
        <div class="btn-container center">
          <button mat-mini-fab color="warn" class="tableau-btn" (click)="deleteZone($event, row)"> 
            <mat-icon class="tableau-mat-icon">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Libelle </th>
      <td mat-cell *matCellDef="let element"> 
          {{element.libelle}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="codePostal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code postale </th>
      <td mat-cell *matCellDef="let element"> 
        <div> {{element.codePostal}} </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="majoration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Majoration </th>
        <td mat-cell *matCellDef="let element"> 
          <div> {{element.majoration}} </div>
        </td>
    </ng-container>
  
    <ng-container matColumnDef="commentaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
      <td mat-cell *matCellDef="let element">  {{element.commentaire}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onOpenZone(row)"></tr>
  </table>
</div>

<mat-paginator
  class="paginator" 
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 25, 50]"
  (page)="changePage($event)">
</mat-paginator>