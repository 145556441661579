import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Inject, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { DevisShared } from 'src/app/modules/shared/DevisShared';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalAfficherDocumentsComponent } from 'src/app/modules/shared/components/modals/modal-afficher-documents/modal-afficher-documents.component';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { PdfService } from 'src/app/modules/shared/pdf-service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ActionService } from '../../services/action.service';
import { CiviliteService } from '../../services/civilite.service';
import { DemandeService, IntercomToPraxedoDTO } from '../../services/demande.service';
import { DescriptionActiviteService } from '../../services/description-activite.service';
import { FormulaireService } from '../../services/formulaire.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { ActiviteService } from '../../services/prestation.service';
import { PriseAppelService } from '../../services/prise-appel.service';
import { AgenceService } from '../../services/rsa/agence.service';
import { SiteGeoService } from '../../services/site-geo.service';
import { TierService } from '../../services/tier/tier.service';
import { TrieTabService } from '../../services/trie-tab.service';
import { TypeDemandeService } from '../../services/type-demande.service';
import { UserService } from '../../services/user/user.service';
import { DevisJasonDTO } from '../../shared/dtos/devisJasonDTO';
import { EnvoiJason } from '../../shared/envoyeJason';
import { ActionModel } from '../../shared/models/action.model';
import { CiviliteModel } from '../../shared/models/civilite.model';
import { DemandeModel } from '../../shared/models/demande.model';
import { DocumentModel } from '../../shared/models/document/document.model';
import { FormulaireModel } from '../../shared/models/formulaire.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { ActiviteModel } from '../../shared/models/prestation.model';
import { AgenceModel } from '../../shared/models/rsa/agence.model';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { TypeDemandeModel } from '../../shared/models/type-demande.model';
import { UserModel } from '../../shared/models/users/user.model';
import { ModalAjoutActionComponent } from '../action/modal-ajout-action.component';
import { ModalClosedActionComponent } from '../action/modal-closed-action.component';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { ModalAjoutDocumentComponent } from '../document/modal-ajout-document/modal-ajout-document.component';
import { DemandeComponent } from './demande.component';
import {MatStepper} from '@angular/material/stepper';
import { DatePipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { ModalConfirmationComponent } from 'src/app/utils/modals/modal-confirmation/modal-confirmation.component';
import { environment } from 'src/environments/environment';
import { DemandeDTO } from '../../shared/dtos/demandeDTO';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { ModalHistoriqueDemandesComponent } from 'src/app/modules/shared/components/modals/modal-historique-demandes/modal-historique-demandes.component';

 export interface DialogData {
  demande: DemandeModel;
  tierId: number;
  result: boolean;
  viewActions: boolean;
  viewDocuments: boolean;
}

@Component({
  selector: 'app-modal-afficher-demande-urgence',
  templateUrl: './modal-afficher-demande-urgence.component.html',
  styleUrls: ['./modal-afficher-demande-urgence.component.scss']
})
export class ModalAfficherDemandeUrgenceComponent implements AfterViewInit, OnInit {
  envoiJason = EnvoiJason;
  tiersFacture = new TierModel();
  adresses: SiteGeoModel[] = [];
  disableAdresseFact = false;
  adresseFactChanged = false;
  adresseFact: SiteGeoModel;
  adressesFact: SiteGeoModel[] = [];
  listTiers: TierModel[] ;
  myControl = new UntypedFormControl();
  agence: AgenceModel;

  civilites: CiviliteModel[] = [];
  typeDemandes: TypeDemandeModel[];
  typeDemande: TypeDemandeModel;
  usersPxo: UserModel[];
  users: UserModel[];
  user: UserModel;
  interlocuteurs: InterlocuteurModel[] = [];
  interlocuteur: InterlocuteurModel;
  interlocuteurExists = false;
  commercialId: number;
  gestionnaireId: number;
  siteModels: SiteGeoModel[];
  siteModel: SiteGeoModel;
  prestations: ActiviteModel[];
  prestation: ActiviteModel;
  checkboxIds: string[];
  private modalsService: ModalsService;
  selectedAction: ActionModel;
  result: boolean;
  currentText = '';
  devisShared: DevisShared = new DevisShared();
  devisJasonDTO: DevisJasonDTO = new DevisJasonDTO();

  //Envoie JASON
  tierDODevis: TierModel;
  tierFactDevis: TierModel;
  siteGeoDevis: SiteGeoModel;
  interlocuteurDevis: InterlocuteurModel;
  isEnvoyeJason: boolean = false;

  /* ajout des formulaires */
  displayedColumnsFormulaire: string[] = ['libelle', 'modifications'];
  dataSourceFormulaire: FormulaireModel[] = [];
  lengthFormulaire: number;

  dataSourceAction: ActionModel[] = [];
  displayedColumnsAction: string[] = ['clore', 'objetAction', 'dateRealisation', 'dateCloture', 'realisateur', 'statut', 'modifications'];
  matDataSourceAction: MatTableDataSource<ActionModel>;
  sortStateAction: Sort = { active: 'dateReal', direction: 'asc' };
  lengthAction: number;
  pageIndexAction = 0;
  pageSizeAction = 999;
  prestationIds: string;
  loading = false;
  interlocuteurId: number;
  // gestion des documents
  document: DocumentModel;
  documents: DocumentModel[] = [];
  siteModelId: number;
  adresse: SiteGeoModel;
  devis: string = "";
  isSendToPxo: boolean = false;
  selectedIndex = 0;
  @Output() onUpdated = new EventEmitter<boolean>();
  commercial: UserModel;
  gestionnaire: UserModel;
  objetDisabled = true;
  demandesLinked: DemandeModel[] = [];
  timeTravel: string = "";
  distanceTravel: string = "";
  libelleTiers = "Tiers donneur d'ordre";
  libelleTiersFacture = "Tiers facturé";
  matTooltip = "";
  flagsPxo = ["À envoyer", "Envoyée à Praxedo", "Planifiée", "Synchronisée", "Démarrée", "Réalisée", "Validée"];
  @ViewChild('stepper') private stepper: MatStepper;

  constructor(public dialog: MatDialog,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<DemandeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private pdfService: PdfService,
    private demandeService: DemandeService,
    private civiliteService: CiviliteService,
    private typeDemandeService: TypeDemandeService,
    private siteService: SiteGeoService,
    private agenceService: AgenceService,
    private toastr: IntercomToastrService,
    private comp: TrieTabService,
    injector: Injector,
    private router: Router,
    private descriptionService: DescriptionActiviteService,
    private userService: UserService,
    private activiteService :ActiviteService,
    private tierService: TierService,
    private actionService: ActionService,
    private formulaireService: FormulaireService,
    private sessionService: SessionService,
    private interlocuteurService: InterlocuteurService,
    private priseAppelService : PriseAppelService,
    private IntercomToastrService: IntercomToastrService, 
    private clipboard: Clipboard
) {
    this.modalsService = injector.get('ModalsService');
    this.data.result = false;
  }

  loadData(){
    this.loadPageAction();
    if(this.data.demande.interventionPxo) {
      this.isSendToPxo = true;
    }

    if(this.data.demande) {
      var demandeDTO = new DemandeDTO();
      demandeDTO.numeroDemandeOrigine = this.data.demande.numDemande;
      const query = new SimpleQuery<DemandeDTO>();
      query.entry = demandeDTO;
      query.order = 'NumDemande';
      query.descending = true;
      query.pageSize = 10;
      
      this.demandeService.getFromObjet(query).subscribe(res =>  {
        if (res.objets) {
          this.demandesLinked = res.objets;
        }
      });
    }
    // load prestations
    this.activiteService.getAllPxo().subscribe((activites: ActiviteModel[]) => {
      this.prestations = activites;
            
      // load prestations
      const ids = this.data.demande.prestationIds ? this.data.demande.prestationIds.split(',') : [];
      const filteredIds = ids.filter(id => this.prestations.some(obj => obj.id === parseInt(id, 10))).join(',');
      this.data.demande.prestationIds = filteredIds;
      this.prestationIds = filteredIds;
    });

    if(this.data.demande.tierFactureId) {
      this.tierService.get(this.data.demande.tierFactureId).subscribe((tiers: TierModel) => {
        this.tiersFacture = tiers;
        this.data.demande.tierFacture = tiers;
      });
      this.adresseFact = new SiteGeoModel();
      this.adresseFact.agenceId = this.data.demande.agenceId;
      this.adresseFact.libelle = "Facturation";
      this.adresseFact.adresse = this.data.demande.adresseFact;
      this.adresseFact.ville = this.data.demande.villeAdresseFact;
      this.adresseFact.codePostal = this.data.demande.codePostalAdresseFact;
      this.adresseFact.complementAdresse = this.data.demande.complementAdresseFact;
      this.adresseFact.commentaire = this.data.demande.commentaireAdresseFact;
    }
    
    this.devisShared.demande = this.data.demande;
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
    });

    this.agenceService.get(this.data.demande.agenceId).subscribe((agence: AgenceModel) => {
      let devisClone = Object.assign({}, this.devisShared);
      devisClone.agence = agence;
      this.agence = agence;
      this.devisShared = devisClone;
      
      // DEVIS NON utilisé dans la demande d'urgence
      // if (this.devisShared && this.devisShared.agence.id && filteredIds && filteredIds.length > 0) {
      //   this.descriptionService.getFromDevis(this.devisShared.agence.id, Number(filteredIds[0]))
      //     .subscribe(description => {
      //       let devisClone = Object.assign({}, this.devisShared);
      //       devisClone.description = description;
      //       this.devisShared = devisClone;
      //     });
      // }
      
    });
    

    this.siteModelId = this.data.demande.siteGeoId;
    // get the adress from demande object
    this.adresse = new SiteGeoModel();
    this.adresse.id = this.data.demande.siteGeoId;
    this.adresse.libelle = this.data.demande.libelleAdresse;
    this.adresse.adresse = this.data.demande.adresse;
    this.adresse.email = this.data.demande.emailAdresse;
    this.adresse.ville = this.data.demande.villeAdresse;
    this.adresse.codePostal = this.data.demande.codePostalAdresse;
    this.adresse.complementAdresse = this.data.demande.complementAdresse;
    this.adresse.commentaire = this.data.demande.commentaireAdresse;
    this.adresse.telephone = this.data.demande.telephoneAdresse;
    this.adresse.gps = this.data.demande.gpsAdresse;
    this.adresse.numSite = this.data.demande.numSite;
    this.getTime();

    // get interlocuteur from demande object
    this.interlocuteur = new InterlocuteurModel();
    this.interlocuteur.id = this.data.demande.interlocuteurId;
    this.interlocuteur.tierId = this.data.demande.tierId;
    this.interlocuteur.nom = this.data.demande.nomInterlocuteur;
    this.interlocuteur.prenom = this.data.demande.prenomInterlocuteur;
    this.interlocuteur.role = this.data.demande.roleInterlocuteur;
    this.interlocuteur.commentaire = this.data.demande.commentaireInterlocuteur;
    this.interlocuteur.telPrincipal = this.data.demande.telPrincipalInterlocuteur;
    this.interlocuteur.telSecondaire = this.data.demande.telSecondaireInterlocuteur;
    this.interlocuteur.email = this.data.demande.emailInterlocuteur;
    this.interlocuteur.idJason = this.data.demande.idJasonInterlocuteur;
    this.interlocuteur.civiliteId = this.data.demande.civiliteInterlocuteurId;
    let civ: CiviliteModel;
    if (this.data.demande.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.demande.civiliteInterlocuteur.toLowerCase()) ;
        this.interlocuteur.civilite = civ ? civ : null;
        this.interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      this.interlocuteur.civilite = null;
      this.interlocuteur.civiliteId = null;
    }
    this.devisShared.interlocuteur = this.interlocuteur;

    this.typeDemandeService.getAllActive().subscribe((ta: TypeDemandeModel[]) => {
      this.typeDemandes = ta.filter(t => t.id === 1000)
    });
    this.siteService.getFromTier(this.data.demande.tierId).subscribe((site: SiteGeoModel[]) => this.siteModels = site);
    this.userService.getAllPxo().subscribe((usrs: UserModel[]) => {
      this.usersPxo = usrs
      this.userService.get(this.data.demande.commercialId).subscribe((usr: UserModel) => {
        this.commercial = usr;
        let devisClone = Object.assign({}, this.devisShared);
        devisClone.realisateur = usr;
        this.commercial = this.usersPxo.find(x => x.id === this.commercialId);
        this.devisShared = devisClone;
      });
    });
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
      this.userService.get(this.data.demande.gestionnaireId).subscribe((usr: UserModel) => {
        this.gestionnaire = usr;
        this.gestionnaire = this.users.find(x => x.id === this.gestionnaireId);
      });
    });

    
    this.data.viewActions = true;
    this.data.viewDocuments = false;
    this.commercialId = this.data.demande.commercialId;
    this.gestionnaireId = this.data.demande.gestionnaireId;
    this.interlocuteurId = this.data.demande.interlocuteurId;

    // load formulaires
    this.formulaireService.getFromTier(this.data.demande.tierId).subscribe((forms: FormulaireModel[]) => {
      this.dataSourceFormulaire = forms;
      this.lengthFormulaire = forms.length;
    });
    // get interlocuteurs InterlocuteurModel by tierId
    this.interlocuteurService.getFromTier(this.data.demande.tierId).subscribe((interloc: InterlocuteurModel[]) => {
      this.interlocuteurs = interloc;
    });

    this.dialogRef.backdropClick().subscribe(event => this.onModalMinimize());
    if (this.data.demande.envoyeAJason == this.envoiJason.INTEGRE) {
      this.isEnvoyeJason = true;
    }
  }
  ngOnInit() {
    this.loadData()
  }

  refreshStepper(){
  //this.stepper._getIndicatorType = () => 'number';
    // Flag Praxedo
    if(this.data.demande.interventionPxo){
      // determiné le flag par rapport au date 
      if(this.data.demande.interventionPxo.dValidPxo){
        this.move(6);
      }else{
        if(this.data.demande.interventionPxo.dRealPxo){
          this.move(5);
        }else{
          if(this.data.demande.interventionPxo.dDemarrePxo){
            this.move(4);
          }else{
            if(this.data.demande.interventionPxo.dSynchroPxo){
              this.move(3);
            }else{
              if(this.data.demande.interventionPxo.dStartPlanifPxo){
                this.move(2);
              }else{
                this.move(1);
              }
            }
          }
        }
      }
    }else{
      this.move(0);
    }
  }

  ngAfterViewInit() {
    this.refreshStepper();
  }


  updateDechetsChilds() {
    if (!this.data.demande.dechets) {
      this.data.demande.bsa = false;
      this.data.demande.bsd = false;
    }
  }

  modifyObjet(): void{
    this.objetDisabled = false;
  }

  onTierOpen() {
    this.router.navigate(['/fiche-contact', this.data.demande.tierId]);
    this.dialogRef.close(false);
  }

  updateDechets() {
    if (this.data.demande.bsa || this.data.demande.bsd) {
      this.data.demande.dechets = true;
    }
  }

  async print(){
    const url =  this.agence.logoId ?  'api/Document/getDoc?idDoc=' + this.agence.logoId : null;
    const ids = this.data.demande.prestationIds ? this.data.demande.prestationIds.split(',') : [];
    const filteredIds = ids.filter(id => this.prestations.some(obj => obj.id === parseInt(id, 10))).join(',');
    const prestations = [];
    for (let i = 0; i < filteredIds.length; i++) {
      for (let y = 0; y < this.prestations.length; y++) {
        if (this.prestations[y].id === Number(filteredIds[i])) {
          prestations.push(this.prestations[y]);
        }
      }
    }
    this.pdfService.printDemande(this.data.demande, url, prestations, this.devis);
  }

  onUpdatePrestationsId(prestationIds: string) {
    this.prestationIds = prestationIds;
    this.data.demande.prestationIds = prestationIds;
  }

  onUpdateRealisateurId(realisateurId: number) {
    if (realisateurId === null) {
      this.data.demande.commercialId = -1;
      this.commercial = null;
    } else {
      this.data.demande.commercialId = realisateurId;
      this.data.demande.agenceId = this.usersPxo.find(x => x.id === realisateurId).agenceId;
      this.commercial = this.usersPxo.find(x => x.id === realisateurId);
    }
  }

  onUpdateGestionnaireId(gestionnaireId: number) {
    if (gestionnaireId === null) {
      this.data.demande.gestionnaireId = -1;
      this.gestionnaire = null;
    } else {
      this.data.demande.gestionnaireId = gestionnaireId;
      this.gestionnaire = this.users.find(x => x.id === gestionnaireId);
    }
  }

  typChange() {
    this.typeDemande = Object.create(this.typeDemandes.find(ta => ta.id === this.data.demande.typeDemandeId));
    this.data.demande.typeDemandeId = this.typeDemande.id;
  }


  createurChange() {
    this.user = Object.create(this.usersPxo.find(usr => usr.id === this.data.demande.createurId));
  }

  getInterlocuteur() {
    if (this.interlocuteur) {
      this.interlocuteurExists = true;
    }
  }

  changePageAction(event?: PageEvent) {
    this.pageIndexAction = event.pageIndex;
    this.pageSizeAction = event.pageSize;
    this.loadPageAction();
  }

  trieTabAction(sort: Sort) {
    this.sortStateAction = sort;
    this.loadPageAction();
  }

  loadPageAction() {
    this.dataSourceAction = this.data.demande.actions;
    const act = this.dataSourceAction.find(a => a.statut === 'En cours' || a.statut === 'Clos');
    if (act && this.data.demande.statut === 'A traiter') {
      this.data.demande.statut = 'En cours';
      this.demandeService.put(this.data.demande).subscribe();
    }
    this.matDataSourceAction = this.comp.sortData(this.sortStateAction, this.dataSourceAction,
      this.matDataSourceAction, this.pageIndexAction, this.pageSizeAction);
    if (this.matDataSourceAction === undefined) {
      const data = this.dataSourceAction.slice(0, this.pageSizeAction);
      this.matDataSourceAction = new MatTableDataSource(data);
    }
  }

  onModalCancel(): void {
    this.dialogRef.close(this.data.result);
    this.data.demande.actions = this.dataSourceAction;
    this.modalsService.closeDemande(this.data.demande);
  }

  onModalMinimize(): void {
    this.dialogRef.close(this.data.result);
  }

  onModalListeAction(): void {
    this.data.viewActions = !this.data.viewActions;
    if (this.data.viewDocuments === true) {
      this.data.viewDocuments = !this.data.viewDocuments;
    } else {
      this.data.viewDocuments = this.data.viewDocuments;
    }
  }

  updateDemandeOrSynchPraxedo(dialogRef) {
    try {
      if(this.data.demande.prestationIds !== null && this.data.demande.prestationIds !== ""){
        if (this.data.demande.typeDemandeId === 1000 && this.data.demande.interventionPxo && this.data.demande.interventionPxo.dEnvoiPxo) {
            if (!this.data.demande.interventionPxo.dSynchroPxo && !this.data.demande.interventionPxo.dRealPxo && !this.data.demande.interventionPxo.dValidPxo) {
              this.onUpdateToPraxedo(dialogRef);
            } else {
              const dialogConf = this.dialog.open(ModalConfirmationComponent, {
                data: {
                  title: 'Les modifications de votre demande ne seront pas synchronisées avec Praxedo',
                  message: ''
                }
              });
              dialogConf.afterClosed().subscribe(res => {
                if (res) {
                  this.updateDemande(dialogRef);
                } else {
                  dialogRef.close(true);
                }
              });
            }
        } else {
          this.updateDemande(dialogRef);
        } 
      }else{
        this.IntercomToastrService.warning("L'activité est obligatoire");
        dialogRef.close(true); //spinner
      }
    } catch {
      dialogRef.close(true); //spinner
    }
  }

  updateDemande(dialogRef) {
    this.demandeService.put(this.data.demande).subscribe((a: DemandeModel) => {
      if (!a) {
        dialogRef.close(true); //spinner
      }
      this.data.result = true;
      this.data.demande = a;
      this.dialogRef.close(a); //demande
      dialogRef.close(true); //spinner
      this.IntercomToastrService.success('La demande : ' + a.objet + ' a bien été modifiée');
      this.modalsService.closeDemande(this.data.demande);
      if (this.router.url === '/') {
        this.router.navigate(['']);
      }
    }, error =>
    {
      dialogRef.close();
      if (error.error) {
        this.IntercomToastrService.error("Erreur lors de l’enregistrement de la demande : " + error.error);
      }
      else {
        this.IntercomToastrService.error("Erreur lors de l’enregistrement de la demande");
      }
    });
  }

  copyclip(res: string, event: Event) {
    event.stopPropagation();
    this.clipboard.copy(res);
  }

  openHistorique(event: Event) {
    event.stopPropagation();
    this.dialog.open(ModalHistoriqueDemandesComponent, {
      data: {
        title: "Historique des demandes liées",
        demandes: this.demandesLinked,
      }
    });
  }

  // button enregistrer
  onModalConfirm() {
    if(this.data.demande.statut === 'Annulé' && (this.selectedIndex === 1 || this.selectedIndex === 2) && this.data.demande.interventionPxo && !this.data.demande.interventionPxo.dAnnulePxo){
      // annulation Praxedo
      const dialogConf = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: 'Annulation de la demande urgente Praxedo: ' + this.data.demande.numDemande,
          message: 'Êtes-vous sûr de vouloir annuler cette demande ?'
        }
      });
      dialogConf.afterClosed().subscribe(res => {
        if (res) {
          const dialogRef = this.dialog.open(ModalSpinnerComponent);
          var objet = new IntercomToPraxedoDTO(this.data.demande.id, this.data.demande.agenceId, this.data.demande.interventionPxo.codePrestaPxo, null);
          this.demandeService.cancelPraxedoIntervention(objet).subscribe((result: string) => {
            if(result){
              this.IntercomToastrService.success('Votre demande a bien été annulée');
              this.saveDemande(dialogRef);
            }else{
              this.IntercomToastrService.error('L\'annulation de votre demande est un échec. Veuillez vérifier vos droits');
            }
            dialogRef.close();
          }, error => {
            this.IntercomToastrService.error('L\'annulation de votre demande est un échec. Veuillez vérifier vos droits');
            dialogRef.close();
          });
        }
      });
    }else{
      const dialogRef = this.dialog.open(ModalSpinnerComponent);
      if (this.data.demande) {
        this.saveDemande(dialogRef);
      } else {
        dialogRef.close();
      }
    }
    
  }

  onOpenAction(id: number): void {
    this.actionService.get(id).subscribe((a: ActionModel) => {
      this.modalsService.openAction(a).subscribe(result => {
        this.result = result;
        this.onUpdated.emit(true);
        this.data.result = result;
        if (this.result) {
          this.actionService.getFromDemande(this.data.demande.id).subscribe((actions: ActionModel[]) => {
            this.dataSourceAction = actions;
            this.data.demande.actions = actions;
            this.loadPageAction();
          });
        this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
          this.data.demande = dem;
        });
        }
      });
    });
  }

  onVoirAction(id: number): void {
    this.actionService.get(id).subscribe((a: ActionModel) => {
      this.selectedAction = a;
      const dialogRef = this.dialog.open(ModalClosedActionComponent, {
        data: {
          action: this.selectedAction,
          result: this.result
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.result = result;
        if (this.result) {
          this.actionService.getFromDemande(this.data.demande.id).subscribe((actions: ActionModel[]) => {
            this.dataSourceAction = actions;
            this.data.demande.actions = actions;
            this.loadPageAction();
          });
        } else {
        }
      });
    });
  }

  onAddAction(): void {
    const dialogRef = this.dialog.open(ModalAjoutActionComponent, {
      disableClose: true,
      data: {
        tierId: this.data.demande.tierId,
        realisateurId: this.sessionService.currentUserValue.id,
        action: new ActionModel(),
        demandeId: this.data.demande.id,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result[0];
      this.data.result = result[0];
      if (this.result) {
        this.data.demande.actions.push(result[1]);
        this.dataSourceAction = this.data.demande.actions;
        this.loadPageAction();
      }
    });
  }

  onAddRelance(): void {
    const dialogRef = this.dialog.open(ModalAjoutActionComponent, {
      disableClose: true,
      data: {
        tierId: this.data.demande.tierId,
        realisateurId: this.sessionService.currentUserValue.id,
        action: new ActionModel(),
        demandeId: this.data.demande.id,
        result: this.result,
        date: new Date(),
        typeTitre: "relance",
        typeAction: 1001,
        satut: "Clos"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result[0];
      this.data.result = result[0];
      if (this.result) {
        this.data.demande.actions.push(result[1]);
        this.dataSourceAction = this.data.demande.actions;
        this.loadPageAction();
      }
    });
  }


  parseResponse(response: any, libelle: string): string {
    let res = '________________________________________________ \n ';
    if (response !== null) {
      const array = response.response;
      const date = response.date;
      res = res + 'Formulaire : ' + libelle + '  ' + date + '\n';
      if (array.length !== 0 && array !== null) {
        for (let i = 0; i < array.length; i++) {
          const value = array[i].value.replace(/\"/gi, '');
          const question = array[i].questionName.replace(/\?/gi, '');
          if (value.length !== 0) {
            res = res + question + ' : ' + value + ' \n ';
          }
        }
        return res;
      } else { // pas de submit
        return '';
      }
    } else {
      this.IntercomToastrService.warning('Erreur formulaire', 'Le lien du formulaire n\'est pas valide');
      return ' Collect des emails non activé pour le formulaire  \n' + libelle;

    }
  }

  getResponses(lien: string, libelle: string): void {
    this.loading = true;
    this.demandeService.getFormulaireFromUrl(lien).subscribe(response => {
      if (this.data.demande.reponseForm !== null && this.data.demande.reponseForm !== undefined) {
        this.data.demande.reponseForm = this.parseResponse(response, libelle) + this.data.demande.reponseForm;
      } else {
        this.data.demande.reponseForm = this.parseResponse(response, libelle);
      }
      this.loading = false;
    });
  }

  onEditInterlocuteur(interlocuteur: InterlocuteurModel) {
    if (interlocuteur) { // modification dun interlocuteur
      this.data.demande.interlocuteurId = interlocuteur.id;
      this.data.demande.nomInterlocuteur = interlocuteur.nom;
      this.data.demande.prenomInterlocuteur = interlocuteur.prenom;
      this.data.demande.roleInterlocuteur = interlocuteur.role;
      this.data.demande.emailInterlocuteur = interlocuteur.email;
      this.data.demande.telPrincipalInterlocuteur = interlocuteur.telPrincipal;
      this.data.demande.telSecondaireInterlocuteur = interlocuteur.telSecondaire;
      this.data.demande.commentaireInterlocuteur = interlocuteur.commentaire;
      this.data.demande.civiliteInterlocuteur = (interlocuteur.civilite ? interlocuteur.civilite.libelle : undefined);
      if (this.data.demande.nomInterlocuteur.trim().length === 0) {
        this.data.demande.nomInterlocuteur = undefined;
        this.data.demande.prenomInterlocuteur = undefined;
        this.data.demande.roleInterlocuteur = undefined;
        this.data.demande.emailInterlocuteur = undefined;
        this.data.demande.telPrincipalInterlocuteur = undefined;
        this.data.demande.telSecondaireInterlocuteur = undefined;
        this.data.demande.commentaireInterlocuteur = undefined;
        this.data.demande.civiliteInterlocuteur = undefined;
      }
    } else { // suppression d'un interlocuteur
      this.data.demande.interlocuteurId = undefined;
      this.data.demande.nomInterlocuteur = undefined;
      this.data.demande.prenomInterlocuteur = undefined;
      this.data.demande.roleInterlocuteur = undefined;
      this.data.demande.emailInterlocuteur = undefined;
      this.data.demande.telPrincipalInterlocuteur = undefined;
      this.data.demande.telSecondaireInterlocuteur = undefined;
      this.data.demande.commentaireInterlocuteur = undefined;
      this.data.demande.civiliteInterlocuteur = undefined;
    }
  }

  /* Fonctions pour la gestion des documents */

  getDocumentByID(document: DocumentModel) {
    this.document = document;
  }

  onShowDocuments(){
    const dialogRef = this.dialog.open(ModalAfficherDocumentsComponent, {
      disableClose: false,
      data: {
        demande: this.data.demande
      },
      panelClass: 'mat-dial-no-padd'
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  onSendToPraxedo(): void{
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    try {
      const url =  this.agence?.logoId ?  'api/Document/getDoc?idDoc=' + this.agence.logoId : null;
      const ids = this.data.demande.prestationIds ? this.data.demande.prestationIds.split(',') : [];
      const filteredIds = ids.filter(id => this.prestations.some(obj => obj.id === parseInt(id, 10))).join(',');
      const prestations = [];
      for (let i = 0; i < filteredIds.length; i++) {
        for (let y = 0; y < this.prestations.length; y++) {
          if (this.prestations[y].id === Number(filteredIds[i])) {
            prestations.push(this.prestations[y]);
          }
        }
      }
      this.data.demande.prestationIds = filteredIds;
      // enregistrement de la demande
      if(this.data.demande.prestationIds !== null && this.data.demande.prestationIds !== ""){
        this.demandeService.put(this.data.demande).subscribe((a: DemandeModel) => {
          this.data.result = true;
          this.data.demande = a;
          dialogRef.close(true); //spinner
          this.sendToPraxedo(url, prestations);
        }, error =>
        {
          dialogRef.close();
          if (error.error) {
            this.IntercomToastrService.error("Erreur lors de l’enregistrement de la demande : " + error.error);
          }
          else {
            this.IntercomToastrService.error("Erreur lors de l’enregistrement de la demande : " + error.split("Stack")[0]);
          }
          dialogRef.close(true);
        });
      }else{
        this.IntercomToastrService.warning("L'activité est obligatoire");
        dialogRef.close(true); 
      }
    } catch {
      dialogRef.close(true); 
    }
  }

  sendToPraxedo(url: string, prestations: any){
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    this.pdfService.getBlobPdfDemande(this.data.demande, url, prestations, this.devis).subscribe(demandePDF =>{
      const formData = new FormData();
      formData.append('IdAgence', this.data.demande.agenceId.toString());
      formData.append('DemandeId', this.data.demande.id.toString());      
      formData.append('CodePrestaPxo', null);
      formData.append('document', new File([demandePDF], "DemandePDF"), 'DemandePDF.pdf');
      this.demandeService.sendDemandeToPraxedo(formData).subscribe((response: string) => {
        if (response) {
          this.IntercomToastrService.success("Demande envoyée à Praxedo");
          this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
            this.data.demande = dem;
            this.loadData();
            this.refreshStepper();
          });
        } else {
          this.IntercomToastrService.warning('Praxedo n\'a pas répondu.');
        }
        dialogRef.close();
      }, (error: HttpErrorResponse) => {
        if (error && error.error && error.error.value) {
          this.IntercomToastrService.error(error.error.value.split("Stack")[0]);  
        } else {
          this.IntercomToastrService.error("Erreur, l'envoi a Praxedo ne répond pas, Erreur HTTP STATUS : " + error.statusText);
        }
        dialogRef.close();
      });
    }, error => {
      dialogRef.close();
      this.IntercomToastrService.warning('La génération du pdf demande a échouée.');
    });
  }

  onUpdateToPraxedo(dialogRef) {
    try {
      const url =  this.agence?.logoId ?  'api/Document/getDoc?idDoc=' + this.agence.logoId : null;
      const ids = this.data.demande.prestationIds ? this.data.demande.prestationIds.split(',') : [];
      const filteredIds = ids.filter(id => this.prestations.some(obj => obj.id === parseInt(id, 10))).join(',');
      const prestations = [];
      for (let i = 0; i < filteredIds.length; i++) {
        for (let y = 0; y < this.prestations.length; y++) {
          if (this.prestations[y].id === Number(filteredIds[i])) {
            prestations.push(this.prestations[y]);
          }
        }
      }
      this.data.demande.prestationIds = filteredIds;
      // enregistrement de la demande
      if(this.data.demande.prestationIds !== null && this.data.demande.prestationIds !== ""){
        this.updateToPraxedo(url, prestations, dialogRef);
      }else{
        this.IntercomToastrService.warning("L\'activité est obligatoire");
        dialogRef.close(true); //spinner
      }
    } catch {
      dialogRef.close(true); //spinner
    }
  }

  updateToPraxedo(url: string, prestations: any, dialogRef){
    this.pdfService.getBlobPdfDemande(this.data.demande, url, prestations, this.devis).subscribe(demandePDF =>{
      const formData = new FormData();
      formData.append('demande', new Blob([JSON.stringify(this.data.demande)], { type: 'application/json' }));
      formData.append('document', new File([demandePDF], 'DemandePDF.pdf', { type: 'application/pdf' }));
      this.demandeService.updateToPraxedo(formData).subscribe((response: DemandeModel) => {
        if (response) {
          setTimeout(() => this.IntercomToastrService.info("Les modifications sont envoyées à Praxedo"), 500);
          this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
            this.data.demande = dem;
            this.loadData();
            this.refreshStepper();
          });
        } else {
          this.IntercomToastrService.warning('Erreur lors de l’enregistrement de la demande ou sa synchronisation avec Praxedo.');
        }
        dialogRef.close();
      }, (error: HttpErrorResponse) => {
        if (error && error.error && error.error.value) {
          this.IntercomToastrService.error(error.error.value.split("Stack")[0]);  
        } else {
          this.IntercomToastrService.error("Erreur, l\'envoi a Praxedo ne répond pas, Erreur HTTP STATUS : " + error.statusText);
        }
        this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
          this.data.demande = dem;
          this.loadData();
          this.refreshStepper();
        });
        dialogRef.close();
      });
    }, error => {
      dialogRef.close();
      this.IntercomToastrService.warning('La génération du pdf demande a échouée.');
    });
  }

  onModalListeDocument(): void {
    this.data.viewDocuments = !this.data.viewDocuments;

    if (this.data.viewActions === true) {
      this.data.viewActions = !this.data.viewActions;
    } else {
      this.data.viewActions = this.data.viewActions;
    }
  }

  onAddDocument(): void {
    const dialogRef = this.dialog.open(ModalAjoutDocumentComponent, {
      disableClose: true,
      data: {
        type: 'Demande',
        document: new DocumentModel(),
        typeId: this.data.demande.id,
        tierId: this.data.demande.tierId,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result ? true : false;
      this.data.result = result ? true : false;
      if (this.result) {
        this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
          this.data.demande = dem;
        });
      }
    });
  }

  onChecked(checked: boolean, action: ActionModel) {
    if (checked) {
      this.data.demande.actions.find(a => a.id === action.id).statut = 'Clos';
    } else {
      this.data.demande.actions.find(a => a.id === action.id).statut = 'En cours';
    }
    this.actionService.put(this.data.demande.actions.find(a => a.id === action.id)).subscribe(
      (res: ActionModel) =>{
        this.data.demande.actions = this.data.demande.actions.filter(a => a.id !== action.id);
        this.data.demande.actions.push(res);
        this.loadPageAction();
      }
    );
  }

  createInterlocuteur(dialogRef) {
    const interlocuteur = new InterlocuteurModel();
    interlocuteur.id = undefined;
    interlocuteur.tierId = this.data.demande.tierId;
    interlocuteur.nom = this.data.demande.nomInterlocuteur;
    interlocuteur.prenom = this.data.demande.prenomInterlocuteur;
    interlocuteur.role = this.data.demande.roleInterlocuteur;
    interlocuteur.commentaire = this.data.demande.commentaireInterlocuteur;
    interlocuteur.telPrincipal = this.data.demande.telPrincipalInterlocuteur;
    interlocuteur.telSecondaire = this.data.demande.telSecondaireInterlocuteur;
    interlocuteur.email = this.data.demande.emailInterlocuteur;
    let civ: CiviliteModel;
    if (this.data.demande.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.demande.civiliteInterlocuteur.toLowerCase()) ;
        interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      interlocuteur.civiliteId = null;
    }
    interlocuteur.civilite = null;
    this.interlocuteurService.post(interlocuteur).subscribe((interloc: InterlocuteurModel) => {
      this.data.demande.interlocuteurId = interloc.id;
      this.updateDemande(dialogRef);
    }, error =>
    {
      dialogRef.close();
      if (error.error) {
        this.IntercomToastrService.error("Erreur lors de l’enregistrement de l’interlocuteur de la demande : " + error.error);
      }
      else {
        this.IntercomToastrService.error("Erreur lors de l’enregistrement de l’interlocuteur de la demande");
      }
    });
  }

  saveDemande(dialogRef) {
    // Si dans la demande il y a une adresse et pas d'interlocuteur, alors création de la demande.
    this.updateDemandeOrSynchPraxedo(dialogRef);
    
  }

  onDupliquer(){
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    var demandeToCreate = Object.assign({}, this.data.demande);
    demandeToCreate.id = 0;
    demandeToCreate.tier = null;
    demandeToCreate.typeDemande = null;
    demandeToCreate.commercial = null;
    demandeToCreate.agence = null;
    demandeToCreate.createur = null;
    demandeToCreate.tierFactureId = demandeToCreate.tierFacture ? demandeToCreate.tierFacture.id : null;
    demandeToCreate.tierFacture = null;
    this.demandeService.duplicate(demandeToCreate).subscribe((dem: DemandeModel) => {
      this.demandeService.get(dem.id).subscribe(res => {
        dialogRef.close();
        this.toastr.success('La demande : ' + res.objet + ' a bien été dupliquée');
        this.toastr.info('Vous pouvez maintenant ajouter les tâches correspondantes à la nouvelle demande');
        this.modalsService.openDemande(res).subscribe(demMod => {
          this.onModalCancel();
        }, error =>  {
          this.toastr.error("Erreur durant l’ouverture de la demande dupliquée");
        });
      }, error =>  {
        this.toastr.error("Erreur durant la récupération de la demande dupliquée");
        dialogRef.close();
      });
    }, error =>  {
      this.toastr.error("Erreur durant la création de la demande dupliquée");
      dialogRef.close();
    });
  }

  onAddOrEditAddress(adresse: SiteGeoModel) {
    if (adresse) {
      this.data.demande.siteGeoId = adresse.id;
      this.data.demande.libelleAdresse = adresse.libelle;
      this.data.demande.complementAdresse = adresse.complementAdresse;
      this.data.demande.adresse = adresse.adresse;
      this.data.demande.villeAdresse = adresse.ville;
      this.data.demande.commentaireAdresse = adresse.commentaire;
      this.data.demande.codePostalAdresse = adresse.codePostal;
      this.data.demande.emailAdresse = adresse.email;
      this.data.demande.telephoneAdresse = adresse.telephone;
      this.data.demande.gpsAdresse = adresse.gps;
      this.data.demande.numSite = adresse.numSite;
      this.getTime();
    } else {
      this.data.demande.siteGeoId = undefined;
      this.data.demande.libelleAdresse = undefined;
      this.data.demande.commentaireAdresse = undefined;
      this.data.demande.adresse = undefined;
      this.data.demande.villeAdresse = undefined;
      this.data.demande.codePostalAdresse = undefined;
      this.data.demande.complementAdresse = undefined;
      this.data.demande.commentaireAdresse = undefined;
      this.data.demande.emailAdresse = undefined;
      this.data.demande.telephoneAdresse = undefined;
      this.data.demande.gpsAdresse = undefined;
      this.data.demande.numSite = undefined;
      this.timeTravel = "";
    }
  }

  saveAddressSaveDemande(dialogRef) {
    if (!this.data.demande.siteGeoId) {
      const site = new SiteGeoModel();
      site.id = undefined;
      site.tierId = this.data.demande.tierId;
      site.libelle = this.data.demande.libelleAdresse;
      site.adresse = this.data.demande.adresse;
      site.email = this.data.demande.emailAdresse;
      site.ville = this.data.demande.villeAdresse;
      site.codePostal = this.data.demande.codePostalAdresse;
      site.agenceId = this.data.demande.agenceId;
      site.complementAdresse = this.data.demande.complementAdresse;
      site.commentaire = this.data.demande.commentaireAdresse;
      site.telephone = this.data.demande.telephoneAdresse;
      site.gps = this.data.demande.gpsAdresse;
      site.numSite = this.data.demande.numSite;
      this.siteService.post(site).subscribe((adresse: SiteGeoModel) => {
        this.data.demande.siteGeoId = adresse.id;
        this.saveDemande(dialogRef);
      }, error =>
      {
        dialogRef.close();
        if (error.error) {
          this.IntercomToastrService.error("Erreur lors de l’enregistrement de l’adresse de la demande : " + error.error);
        }
        else {
          this.IntercomToastrService.error("Erreur lors de l’enregistrement de l’adresse de la demande");
        }
      });
    } else {
      this.saveDemande(dialogRef);
    }
  }

  onChangeCommentaire(value: string) {
    this.adresseFact.commentaire = value;
    this.data.demande.commentaireAdresseFact = value;
  }

  onTiersFactureChange(tiersFacture: TierModel) {
    if(tiersFacture){
      this.tiersFacture.id = tiersFacture.id;
      this.data.demande.tierFactureId = tiersFacture.id;
  
      this.adresseFact = new SiteGeoModel();
      this.adresseFact.agenceId = this.data.demande.tierId;
      this.adresseFact.libelle = "Facturation";
      this.adresseFact.adresse = tiersFacture.adresseFact;
      this.adresseFact.ville = tiersFacture.villeFact;
      this.adresseFact.codePostal = tiersFacture.codePostalFact;
      this.adresseFact.complementAdresse = tiersFacture.complementAdresseFact;
      this.data.demande.adresseFact = tiersFacture.adresseFact;
      this.data.demande.villeAdresseFact = tiersFacture.villeFact;
      this.data.demande.codePostalAdresseFact = tiersFacture.codePostalFact;
      this.data.demande.complementAdresseFact = tiersFacture.complementAdresseFact;
    } else {
      this.tiersFacture.id = null;
      this.data.demande.tierFactureId = null;
      this.adresseFact = new SiteGeoModel();
      this.adresseFact.libelle = "Facturation";
      
      this.data.demande.adresseFact = "";
      this.data.demande.villeAdresseFact = "";
      this.data.demande.codePostalAdresseFact = "";
      this.data.demande.complementAdresseFact = "";
    }
  }

  openGMap() {
    let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;
    if (this.commercial && this.commercial.agenceId){
      this.agenceService.get(this.commercial.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {
          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else if (this.sessionService.currentUserValue?.agenceId) {    
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agenceUser: AgenceModel) => {
        if (agenceUser.adresse && agenceUser.codePostal && agenceUser.ville) {
          let src = agenceUser.adresse + ', ' + agenceUser.codePostal + ' ' + agenceUser.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else {
      window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
    }
  }

  getTime() {
    if (!this.adresse?.adresse || !this.adresse.codePostal || !this.adresse.ville) {
      this.timeTravel = "Adresse d'intervention incomplète ou incorrecte";
    } else if (this.commercial) {
      this.agenceService.get(this.commercial.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            this.distanceTravel = results.routes[0].legs[0].distance.text;
            this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Adresse de l'agence du réalisateur incomplète ou incorrecte"; }
      });
    }  else if (this.sessionService.currentUserValue?.agenceId) {
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Erreur : Adresse de votre agence est incomplète ou incorrecte"; }
      });
    } else { this.timeTravel = "Impossible de calculer l'itinéraire"; }
  }
  
  limitCommentTextArea(evt: KeyboardEvent) {

    let textarea = evt.target as any
    let maxRows: number = 24;

    let newContent: string = (textarea.value as string)
        .split('\n')
        .filter((val, row) => row < maxRows)
        .join('\n');

    textarea.value = newContent;
  }

  move(index: number) {
    this.stepper.linear = false;    
    this.selectedIndex = index;
    if(index > 0){
      this.stepper.steps.toArray()[index - 1].completed = true;
      this.stepper.steps.toArray()[index - 1].editable = false;
    }
    this.stepper.selectedIndex = index;
    setTimeout(() => {
      this.stepper.linear = true;
   });
  }

  getTooltip(index){
    switch (index) {
      case 0: 
        this.matTooltip = (this.data.demande.dateCreation ? ("À envoyer le : " + this.datePipe.transform(this.data.demande.dateCreation , 'dd/MM/yyyy HH:mm')) : '' );
        break
      case 1:
        this.matTooltip = ((this.data.demande.interventionPxo && this.data.demande.interventionPxo.dEnvoiPxo) ? ("Envoyée à Praxedo le "  + this.datePipe.transform(this.data.demande.interventionPxo.dEnvoiPxo , 'dd/MM/yyyy HH:mm')) : '');
        break
      case 2:
        this.matTooltip = ((this.data.demande.interventionPxo && this.data.demande.interventionPxo.dStartPlanifPxo) ? (
          "Planifiée le "  
          + this.datePipe.transform(this.data.demande.interventionPxo.dStartPlanifPxo , 'dd/MM/yyyy')
          + " de " + this.datePipe.transform(this.data.demande.interventionPxo.dStartPlanifPxo , 'HH:mm')
          + " à " + this.datePipe.transform(this.data.demande.interventionPxo.dEndPlanifPxo , 'HH:mm')
        ) : '');
        break
      case 3:
        this.matTooltip = ((this.data.demande.interventionPxo && this.data.demande.interventionPxo.dSynchroPxo) ? ("Synchronisée le "  + this.datePipe.transform(this.data.demande.interventionPxo.dSynchroPxo , 'dd/MM/yyyy HH:mm')) : '');
          break
      case 4:
        this.matTooltip =  ((this.data.demande.interventionPxo && this.data.demande.interventionPxo.dDemarrePxo) ? ("Démarrée le "  + this.datePipe.transform(this.data.demande.interventionPxo.dDemarrePxo , 'dd/MM/yyyy HH:mm')) : '');
          break
      case 5:
        this.matTooltip =  ((this.data.demande.interventionPxo && this.data.demande.interventionPxo.dRealPxo) ? ("Réalisée le "  + this.datePipe.transform(this.data.demande.interventionPxo.dRealPxo , 'dd/MM/yyyy HH:mm')) : '');
          break
      case 6:
        this.matTooltip = ((this.data.demande.interventionPxo && this.data.demande.interventionPxo.dValidPxo) ? ("Validée/Annulée le " + this.datePipe.transform(this.data.demande.interventionPxo.dValidPxo , 'dd/MM/yyyy HH:mm') ) : '' );
        break
    }
  }

  onShowPraxedo(): void{
    var url =  environment.lien_praxedo;
    window.open(url, '_blank');
  }

}
