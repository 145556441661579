import { BasicMock } from '../basic-mock/basic.mock';
import { EntrepriseModel } from '../../models/tier/entreprise.model';
import { Injectable } from "@angular/core";

@Injectable()
export class EntrepriseMock extends BasicMock  {
  constructor() {
    super();
    (<EntrepriseModel[]> this.objets) = [
      /*{ id: 1,
        clientNumber: 'C00191727', codeNaf: '369', commercialId: 1, libelle: 'SARL BEST AUTOS 53',
        agenceId: 1, famille: '', siretSiren: '53920185500027', identifiantJason: '221809',
        marqueEnseigne: '', raisonSociale: 'SARL BEST AUTOS 53', natureId: 2, typeTier: 'Entreprise',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      { id: 2,
        clientNumber: 'C00048758', codeNaf: '519', commercialId: 1, libelle: 'SNC BRASSERIE LORRAINE LGE',
        agenceId: 1, famille: 'famille0', siretSiren: '81880245600025', identifiantJason: '21716',
        marqueEnseigne: 'BRASSERIE LORRAINE', raisonSociale: 'SNC BRASSERIE LORRAINE LGE', natureId: 3, typeTier: 'Entreprise',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      { id: 3,
        clientNumber: 'C00191725', codeNaf: '515', commercialId: 3, libelle: 'LE BEAUGENCY',
        agenceId: 1, famille: 'famille0', siretSiren: '83367865900016', identifiantJason: '221808',
        marqueEnseigne: 'HOTEL', raisonSociale: 'LE BEAUGENCY', natureId: 1, typeTier: 'Entreprise',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      { id: 4,
        clientNumber: 'C00191724', codeNaf: '580', commercialId: 2, libelle: 'SCI BMB',
        agenceId: 1, famille: 'famille0', siretSiren: '42823351400010', identifiantJason: '221807',
        marqueEnseigne: '', raisonSociale: 'SCI BMB', natureId: 3, typeTier: 'Entreprise',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      }*/
    ];
  }

  getFromObjet(objRecherche: EntrepriseModel): EntrepriseModel[] {
    return Object.assign([], this.objets.filter((objTab: EntrepriseModel) => this.sameTier(objTab, objRecherche)));
  }

  post(obj: EntrepriseModel): EntrepriseModel {
    obj.libelle = obj.raisonSociale;
    return <EntrepriseModel>super.post(obj);
  }

  put(id, obj: EntrepriseModel): EntrepriseModel {
    obj.libelle = obj.raisonSociale;
    return <EntrepriseModel>super.put(id, obj);
  }
  
  sameTier(objTab: EntrepriseModel, objRecherche: EntrepriseModel): boolean{   
    if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
      return false;
    }
    else if (objRecherche.agenceId !== undefined && objRecherche.agenceId !== 0 && objTab.agenceId === objRecherche.agenceId) {
      return false;
    }
    else if (objRecherche.commercialId !== undefined && objRecherche.commercialId !== 0 && objTab.commercialId === objRecherche.commercialId) {
      return false;
    }
    else if (objRecherche.identifiantJason !== undefined  && objRecherche.identifiantJason.length !== 0 && objTab.identifiantJason.indexOf(objRecherche.identifiantJason) === -1) {
      return false;
    }
    else if (objRecherche.natureId !== undefined && objRecherche.natureId !== 0 && objTab.natureId === objRecherche.natureId) {
      return false;
    }
    else if (objRecherche.clientNumber !== undefined && objRecherche.clientNumber.length !== 0 && objTab.clientNumber.toLowerCase().indexOf(objRecherche.clientNumber.toLowerCase()) === -1) {
      return false;
    }
    return this.sameEntreprise(objTab, objRecherche);
  }

  sameEntreprise(objTab: EntrepriseModel, objRecherche: EntrepriseModel): boolean {
    if (objRecherche.agenceId !== 0 && objTab.marqueEnseigne.toLowerCase().indexOf(objRecherche.marqueEnseigne.toLowerCase()) === -1) {
      return false;
    }
    else if (objRecherche.raisonSociale !== undefined && objTab.raisonSociale.toLowerCase().indexOf(objRecherche.raisonSociale.toLowerCase()) === -1) {
      return false;
    }
    else if (objRecherche.codeNaf !== undefined && objTab.codeNaf.toLowerCase().indexOf(objRecherche.codeNaf.toLowerCase()) === -1) {
      return false;
    }
    return true;
  }
}
