<div *ngIf="simpleQueryInterlocuteur.entry.TierId">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h4 class="body-class" style="white-space: nowrap; display: flex; align-items: center; margin-right: 5px;">
      Sélectionnez le contact
      <div *ngIf="contactRequired" style="color:rgb(230, 126, 29)"> * </div>
    </h4>

    <div>
      <button mat-stroked-button color="success" type="button" [disabled]="!selectInterlocuteurTier" (click)="onAddInterlocuteur()">  
        <mat-icon >add</mat-icon>  Créer un contact 
      </button>
    </div>
    
    <mat-form-field class="col-md-4">
      <input matInput placeholder="Nom-Prénom" [(ngModel)]="simpleQueryInterlocuteur.entry.Nom"
        (change)="changeInterlocuteur()">
    </mat-form-field>
    
    <mat-form-field class="col-md-2">
      <input matInput placeholder="Téléphone" 
        [(ngModel)]="simpleQueryInterlocuteur.entry.TelPrincipal"
        (ngModelChange)="changePhone($event)" (change)="changeInterlocuteur()">
    </mat-form-field>
  </div>
  <table mat-table matSort (matSortChange)="trieTab($event)" matSortActive="Nom" matSortDirection="desc"
    matSortDisableClear [dataSource]="matDataSource">
    <caption></caption>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="actions">Actions</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{selected: element === selectedInterlocuteur}">
        <div class="btn-container">
          <button *ngIf="!updateInterloc" mat-mini-fab class="tableau-btn" color="basic" 
            title="{{element.idJason ? 'modifier le contact Jason' : 'modifier le contact intercom'}}" 
            [disabled]="!selectInterlocuteurTier"
            (click)="onEditInterloc(element)" aria-label="Ouvrir le tiers">
            <mat-icon class="tableau-mat-icon">edit</mat-icon>
          </button>
          <button mat-mini-fab class="tableau-btn" *ngIf="element.histo" color="basic" title="Historique des demandes" (click)="openHistoriqueDemandesInterlocuteur(element); $event.stopPropagation();" aria-label="Historique des demandes">
            <mat-icon class="tableau-mat-icon">folder_open</mat-icon>
          </button>
        </div>
    </ng-container>

    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.id === selectedInterlocuteur.id}">
        <div title="{{element.nom}}">
          {{element.nom}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="prenom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.id === selectedInterlocuteur.id}">
        <div title="{{element.prenom}}">
          {{element.prenom}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.id === selectedInterlocuteur.id}">
        <div title="{{element.email}}">
          {{element.email}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="telPrincipal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tél principal</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.id === selectedInterlocuteur.id}">
        <div title="{{element.telPrincipal}}">
          {{element.telPrincipal}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="telSecondaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tél secondaire</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.id === selectedInterlocuteur.id}">
        <div title="{{element.telSecondaire}}">
          {{element.telSecondaire}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fonction</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{selected: element.id === selectedInterlocuteur.id}">
        <div title="{{element.role}}">
          {{element.role}}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsInterlocuteurs"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsInterlocuteurs;" (click)="selectInterlocuteur($event, row)"></tr>
  </table>
  <mat-paginator *ngIf="simpleQueryInterlocuteur.entry.TierId" 
  class="paginator" 
  [length]="length" 
  [pageIndex]="pageIndex" 
  [pageSize]="pageSize" 
  [pageSizeOptions]="[5, 10, 25, 50]" 
  (page)="changePage($event)">
  </mat-paginator>
</div>