import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { DevisService } from '../../services/devis.service';

@Component({
  selector: 'app-modal-import-devis',
  templateUrl: './modal-import-devis.component.html',
  styleUrls: ['./modal-import-devis.component.scss']
})
export class ModalImportDevisComponent implements OnInit {
  fileControlDevis: UntypedFormControl;
  fileControlTva: UntypedFormControl;
  noDoc: UntypedFormControl;
  form: UntypedFormGroup;
  file: File;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
  }

  constructor(
    public dialogRef:MatDialogRef<ModalImportDevisComponent>,
    public toastr: IntercomToastrService,
    public devisService: DevisService 
    ) { 
    this.fileControlDevis = new UntypedFormControl(null, [Validators.required, validatorFileSize(16000000)]);
    this.fileControlTva = new UntypedFormControl(null, [validatorFileSize(16000000)]);
    this.noDoc = new UntypedFormControl(null, []);
    this.form = new UntypedFormGroup({
      fileControlDevis: this.fileControlDevis,
      fileControlTva: this.fileControlTva,
      noDoc: this.noDoc
    });
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  
  submit(): void {
    if (this.form.valid || this.noDoc) {
      this.dialogRef.close({devisFile: this.fileControlDevis.value, tvaFile: this.fileControlTva.value, noDoc: this.noDoc.value});
    }
  }
}
function validatorFileSize(size: number): any {
  return (control: UntypedFormControl) => {
    const file = control.value;
    if (file) {
      const sizeFile = (file as File).size;
      if (sizeFile > size) {
        return {
          validatorFileSize: true
        };
      }
      return null;
    }
    return null;
  };
}