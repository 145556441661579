import { TierBasicModel } from './basic-models/tier-basic.model';
import { AgenceModel } from './rsa/agence.model';


export class SiteGeoModel extends TierBasicModel {
  public numSite = '';
  public libelle = '';
  public codePostal = '';
  public ville = '';
  public telephone = '';
  public adresse = '';
  public complementAdresse = '';
  public commentaire = '';
  public email = '';
  public gps = '';
  public agenceId?: number;
  public agence?: AgenceModel;
  public idJason?: number;
}
