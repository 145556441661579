import { TypeActionModel } from './type-action.model';
import { UserModel } from './users/user.model';
import { TierBasicModel } from './basic-models/tier-basic.model';
import { DemandeModel } from './demande.model';
import { DocumentModel } from './document/document.model';
import { InterlocuteurModel } from './interlocuteur.model';
import { AgenceModel } from './rsa/agence.model';

export class ActionModel extends TierBasicModel {
  public realisateurId: number;
  public realisateur?: UserModel;
  public typeActionId?: number;
  public typeAction?: TypeActionModel;
  public createur?: UserModel;
  public createurId: number;
  public objetAction = '';
  public etat?: string;
  public commentaire = '';
  public dateRealisation: Date = new Date();
  public dateCloture: Date;
  public dateFin: Date = null;
  public heureDebut: string;
  public duree: string;
  public dateCreation: Date = new Date();
  public statut: string;
  public demandeId: number;
  public demande?: DemandeModel;
  public documents?: DocumentModel[];
  public agenceId?: number;
  public agence?: AgenceModel;

  public interlocuteurId?: number = null;
  public telPrincipalInterlocuteur = '';
  public telSecondaireInterlocuteur = '';
  public nomInterlocuteur = '';
  public prenomInterlocuteur = '';
  public civiliteInterlocuteur = '';
  public civiliteInterlocuteurId?: number;
  public emailInterlocuteur = '';
  public commentaireInterlocuteur = '';
  public roleInterlocuteur = '';
}
