import { BasicModel } from '../../models/basic-models/basic.model';
import { Injectable } from '@angular/core';

@Injectable()
export class BasicMock {
protected objets: BasicModel[];

    getFromObjet(objet: BasicModel): BasicModel[] {
        return Object.assign([], this.objets.find(o => o.id === objet.id));
    }

    get(id: number): BasicModel {
        return Object.assign({}, this.objets.find(o => o.id === id));
    }

    getAll(): BasicModel[] {
        return Object.assign([], this.objets);
    }

    put(id: number, obj: BasicModel): BasicModel {
        const index = this.objets.findIndex(o => o.id === id);
        this.objets[index] = Object.assign({}, obj);
        return obj;
    }

    post(obj: BasicModel): BasicModel {
        if (obj.id == null || obj.id == undefined) 
        {
            obj.id = (this.objets.length + 1);
        }
        this.objets.push(Object.assign({}, obj));
        return obj;
    }

    delete(id: number): BasicModel {
        const index = this.objets.findIndex(o => o.id === id);
        if (index > -1) {
            this.objets.splice(index, 1);
            return null;
        } else {
            return null;
        }
    }
}
