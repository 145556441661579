import { CiviliteModel } from './../../shared/models/civilite.model';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { Component, OnInit, Inject, Injector, Output, EventEmitter } from '@angular/core';
import { DemandeComponent } from './demande.component';
import { DemandeService } from './../../services/demande.service';
import { TypeDemandeService } from './../../services/type-demande.service';
import { SiteGeoService } from './../../services/site-geo.service';
import { ActionService } from './../../services/action.service';
import { ActionModel } from '../../shared/models/action.model';
import { ModalClosedActionComponent } from '../action/modal-closed-action.component';
import { ModalAjoutActionComponent } from '../action/modal-ajout-action.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DocumentService } from './../../services/document.service';
import { ModalAjoutDocumentComponent } from '../document/modal-ajout-document/modal-ajout-document.component';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { CiviliteService } from '../../services/civilite.service';
import { FormulaireService } from '../../services/formulaire.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { TrieTabService } from '../../services/trie-tab.service';
import { UserService } from '../../services/user/user.service';
import { DemandeModel } from '../../shared/models/demande.model';
import { DocumentModel } from '../../shared/models/document/document.model';
import { FormulaireModel } from '../../shared/models/formulaire.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { ActiviteModel } from '../../shared/models/prestation.model';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { TypeDemandeModel } from '../../shared/models/type-demande.model';
import { UserModel } from '../../shared/models/users/user.model';
import { DevisShared } from 'src/app/modules/shared/DevisShared';
import { TierModel } from '../../shared/models/tier/tier.model';
import { UntypedFormControl } from '@angular/forms';
import { TierService } from '../../services/tier/tier.service';
import { AgenceModel } from '../../shared/models/rsa/agence.model';
import { AgenceService } from '../../services/rsa/agence.service';
import { DescriptionActiviteService } from '../../services/description-activite.service';
import { PdfService } from 'src/app/modules/shared/pdf-service';
import { ActiviteService } from '../../services/prestation.service';
import { DevisDTO } from 'src/app/modules/devis/DTOs/devisDTO';
import { ModalInfoOkComponent } from '../divers/modals/modal-info-ok.component';
import { DevisJasonDTO } from '../../shared/dtos/devisJasonDTO';
import { ParticulierModel } from '../../shared/models/tier/particulier.model';
import { EntrepriseModel } from '../../shared/models/tier/entreprise.model';
import { DevisService } from 'src/app/modules/devis/services/devis.service';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { EnvoiJason } from '../../shared/envoyeJason';
import { catchError, switchMap } from 'rxjs/operators';
import { ModalAfficherDocumentsComponent } from 'src/app/modules/shared/components/modals/modal-afficher-documents/modal-afficher-documents.component';
import { forkJoin, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { InfoJasonDTO } from 'src/app/modules/devis/DTOs/InfoJasonDTO';
import { PriseAppelService } from '../../services/prise-appel.service';
import { ONGLETSPRISEAPPEL } from '../../shared/ongletsPriseAppel';
import { Clipboard } from '@angular/cdk/clipboard';
import { ModalAddDocumentComponent } from '../document/modal-add-document/modal-add-document.component';
import { ModalHistoriqueDemandesComponent } from 'src/app/modules/shared/components/modals/modal-historique-demandes/modal-historique-demandes.component';
import { DemandeDTO } from '../../shared/dtos/demandeDTO';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';

export interface DialogData {
  demande: DemandeModel;
  tierId: number;
  result: boolean;
  viewActions: boolean;
  viewDocuments: boolean;
}

@Component({
  selector: 'app-modal-afficher-demande',
  templateUrl: './modal-afficher-demande.component.html',
  styleUrls: ['./demande.component.scss']
})
export class ModalAfficherDemandeComponent implements OnInit {
  envoiJason = EnvoiJason;
  tiersFacture = new TierModel();
  adresses: SiteGeoModel[] = [];
  disableAdresseFact = false;
  adresseFactChanged = false;
  adresseFact: SiteGeoModel;
  adressesFact: SiteGeoModel[] = [];
  listTiers: TierModel[] ;
  myControl = new UntypedFormControl();
  agence: AgenceModel;

  civilites: CiviliteModel[] = [];
  typeDemandes: TypeDemandeModel[];
  typeDemande: TypeDemandeModel;
  users: UserModel[];
  user: UserModel;
  interlocuteurs: InterlocuteurModel[] = [];
  interlocuteur: InterlocuteurModel;
  interlocuteurExists = false;
  commercialId: number;
  siteModels: SiteGeoModel[];
  siteModel: SiteGeoModel;
  prestations: ActiviteModel[];
  prestation: ActiviteModel;
  checkboxIds: string[];
  private modalsService: ModalsService;
  selectedAction: ActionModel;
  result: boolean;
  currentText = '';
  devisShared: DevisShared = new DevisShared();
  devisJasonDTO: DevisJasonDTO = new DevisJasonDTO();

  //Envoie JASON
  tierDODevis: TierModel;
  tierFactDevis: TierModel;
  siteGeoDevis: SiteGeoModel;
  interlocuteurDevis: InterlocuteurModel;
  isEnvoyeJason: boolean = false;
  devisExiste = false;

  /* ajout des formulaires */
  displayedColumnsFormulaire: string[] = ['libelle', 'modifications'];
  dataSourceFormulaire: FormulaireModel[] = [];
  lengthFormulaire: number;

  dataSourceAction: ActionModel[] = [];
  displayedColumnsAction: string[] = ['clore', 'objetAction', 'dateRealisation', 'dateCloture', 'realisateur', 'statut', 'modifications'];
  matDataSourceAction: MatTableDataSource<ActionModel>;
  sortStateAction: Sort = { active: 'dateReal', direction: 'asc' };
  lengthAction: number;
  pageIndexAction = 0;
  pageSizeAction = 9999;
  prestationIds: string;
  loading = false;
  interlocuteurId: number;
  // gestion des documents
  document: DocumentModel;
  documents: DocumentModel[] = [];
  siteModelId: number;
  adresse: SiteGeoModel;
  devis: string = "";
  devisDTO: DevisDTO;
  @Output() onUpdated = new EventEmitter<boolean>();
  commercial: UserModel;
  objetDisabled = true;
  timeTravel: string = "";
  distanceTravel: string = "";
  libelleTiers = "Tiers donneur d'ordre";
  libelleTiersFacture = "Tiers facturé";
  demandesLinked: DemandeModel[] = [];

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<DemandeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private pdfService: PdfService,
    private demandeService: DemandeService,
    private civiliteService: CiviliteService,
    private typeDemandeService: TypeDemandeService,
    private siteService: SiteGeoService,
    private agenceService: AgenceService,
    private toastr: IntercomToastrService,
    private comp: TrieTabService,
    private documentService: DocumentService,
    injector: Injector,
    private router: Router,
    private descriptionService: DescriptionActiviteService,
    private userService: UserService,
    private activiteService :ActiviteService,
    private tierService: TierService,
    private actionService: ActionService,
    private formulaireService: FormulaireService,
    private sessionService: SessionService,
    private interlocuteurService: InterlocuteurService,
    private priseAppelService : PriseAppelService,
    private devisService: DevisService,
    private clipboard: Clipboard) {
    this.modalsService = injector.get('ModalsService');
    this.data.result = false;
  }

  ngOnInit() {
    this.loadPageAction();
    // load prestations
    this.activiteService.getAll().subscribe((activites: ActiviteModel[]) => {
      this.prestations = activites;
    });
    if(this.data.demande) {
      var demandeDTO = new DemandeDTO();
      demandeDTO.numeroDemandeOrigine = this.data.demande.numDemande;
      const query = new SimpleQuery<DemandeDTO>();
      query.entry = demandeDTO;
      query.order = 'NumDemande';
      query.descending = true;
      query.pageSize = 10;
      
      this.demandeService.getFromObjet(query).subscribe(res =>  {
        if (res.objets) {
          this.demandesLinked = res.objets;
        }
      });
    }
    if(this.data.demande.tierFactureId) {
      this.tierService.get(this.data.demande.tierFactureId).subscribe((tiers: TierModel) => {
        this.tiersFacture = tiers;
        this.data.demande.tierFacture = tiers;
      });
      this.adresseFact = new SiteGeoModel();
      this.adresseFact.agenceId = this.data.demande.agenceId;
      this.adresseFact.libelle = "Facturation";
      this.adresseFact.adresse = this.data.demande.adresseFact;
      this.adresseFact.ville = this.data.demande.villeAdresseFact;
      this.adresseFact.codePostal = this.data.demande.codePostalAdresseFact;
      this.adresseFact.complementAdresse = this.data.demande.complementAdresseFact;
      this.adresseFact.commentaire = this.data.demande.commentaireAdresseFact;
    }
    
    this.devisShared.demande = this.data.demande;
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
    });

    this.agenceService.get(this.data.demande.agenceId).subscribe((agence: AgenceModel) => {
      let devisClone = Object.assign({}, this.devisShared);
      devisClone.agence = agence;
      this.agence = agence;
      this.devisShared = devisClone;
      // load prestations
      const ids = this.data.demande.prestationIds ? this.data.demande.prestationIds.split(',') : [];
      if (this.devisShared && this.devisShared.agence.id && ids && ids.length > 0) {
        this.descriptionService.getFromDevis(this.devisShared.agence.id, Number(ids[0]))
          .subscribe(description => {
            let devisClone = Object.assign({}, this.devisShared);
            devisClone.description = description;
            this.devisShared = devisClone;
          });
      }
    });
    

    this.siteModelId = this.data.demande.siteGeoId;
    // get the adress from demande object
    this.adresse = new SiteGeoModel();
    this.adresse.id = this.data.demande.siteGeoId;
    this.adresse.libelle = this.data.demande.libelleAdresse;
    this.adresse.adresse = this.data.demande.adresse;
    this.adresse.email = this.data.demande.emailAdresse;
    this.adresse.ville = this.data.demande.villeAdresse;
    this.adresse.codePostal = this.data.demande.codePostalAdresse;
    this.adresse.complementAdresse = this.data.demande.complementAdresse;
    this.adresse.commentaire = this.data.demande.commentaireAdresse;
    this.adresse.telephone = this.data.demande.telephoneAdresse;
    this.adresse.gps = this.data.demande.gpsAdresse;
    this.adresse.numSite = this.data.demande.numSite;
    this.getTime();

    // get interlocuteur from demande object
    this.interlocuteur = new InterlocuteurModel();
    this.interlocuteur.id = this.data.demande.interlocuteurId;
    this.interlocuteur.tierId = this.data.demande.tierId;
    this.interlocuteur.nom = this.data.demande.nomInterlocuteur;
    this.interlocuteur.prenom = this.data.demande.prenomInterlocuteur;
    this.interlocuteur.role = this.data.demande.roleInterlocuteur;
    this.interlocuteur.commentaire = this.data.demande.commentaireInterlocuteur;
    this.interlocuteur.telPrincipal = this.data.demande.telPrincipalInterlocuteur;
    this.interlocuteur.telSecondaire = this.data.demande.telSecondaireInterlocuteur;
    this.interlocuteur.email = this.data.demande.emailInterlocuteur;
    this.interlocuteur.idJason = this.data.demande.idJasonInterlocuteur;
    this.interlocuteur.civiliteId = this.data.demande.civiliteInterlocuteurId;
    let civ: CiviliteModel;
    if (this.data.demande.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.demande.civiliteInterlocuteur.toLowerCase()) ;
        this.interlocuteur.civilite = civ ? civ : null;
        this.interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      this.interlocuteur.civilite = null;
      this.interlocuteur.civiliteId = null;
    }
    this.devisShared.interlocuteur = this.interlocuteur;

    this.typeDemandeService.getAllActive().subscribe((ta: TypeDemandeModel[]) => {
      this.typeDemandes = ta.filter(t => t.id !== 1000)
    });
    this.siteService.getFromTier(this.data.demande.tierId).subscribe((site: SiteGeoModel[]) => this.siteModels = site);
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs
      this.userService.get(this.data.demande.commercialId).subscribe((usr: UserModel) => {
        this.commercial = usr;
        let devisClone = Object.assign({}, this.devisShared);
        devisClone.realisateur = usr;
        this.commercial = this.users.find(x => x.id === this.commercialId);
        this.devisShared = devisClone;
      });
    });
    
    this.data.viewActions = true;
    this.data.viewDocuments = false;
    this.commercialId = this.data.demande.commercialId;
    this.interlocuteurId = this.data.demande.interlocuteurId;

    // load formulaires
    this.formulaireService.getFromTier(this.data.demande.tierId).subscribe((forms: FormulaireModel[]) => {
      this.dataSourceFormulaire = forms;
      this.lengthFormulaire = forms.length;
    });
    // get interlocuteurs InterlocuteurModel by tierId
    this.interlocuteurService.getFromTier(this.data.demande.tierId).subscribe((interloc: InterlocuteurModel[]) => {
      this.interlocuteurs = interloc;
    });

    if (this.data.demande.prestationIds && this.data.demande.prestationIds.substring(this.data.demande.prestationIds.length - 1) !== ',') {
      this.prestationIds = this.data.demande.prestationIds + ',';
    } else {
      this.prestationIds = this.data.demande.prestationIds;
    }
    this.dialogRef.backdropClick().subscribe(event => this.onModalMinimize());
    if (this.data.demande.envoyeAJason == this.envoiJason.INTEGRE) {
      this.isEnvoyeJason = true;
    }
  }

  devisExist(devis: DevisDTO){
    this.devisExiste = true;
    this.devisDTO = devis;
    this.devis = devis.statut + ", " + devis.ht + "€ HT / " + devis.ttc + "€ TTC";
  }

  updateDechetsChilds() {
    if (!this.data.demande.dechets) {
      this.data.demande.bsa = false;
      this.data.demande.bsd = false;
    }
  }

  modifyObjet(): void{
    this.objetDisabled = false;
  }

  onTierOpen() {
    this.router.navigate(['/fiche-contact', this.data.demande.tierId]);
    this.dialogRef.close(false);
  }

  updateDechets() {
    if (this.data.demande.bsa || this.data.demande.bsd) {
      this.data.demande.dechets = true;
    }
  }

  async print(){
    const url =  this.agence.logoId ?  'api/Document/getDoc?idDoc=' + this.agence.logoId : null;
    const ids = this.data.demande.prestationIds ? this.data.demande.prestationIds.split(',') : [];
    const prestations = [];
    for (let i = 0; i < ids.length; i++) {
      for (let y = 0; y < this.prestations.length; y++) {
        if (this.prestations[y].id === Number(ids[i])) {
          prestations.push(this.prestations[y]);
        }
      }
    }
    this.pdfService.printDemande(this.data.demande, url, prestations, this.devis);
  }

  onUpdatePrestationsId(prestationIds: string) {
    this.prestationIds = prestationIds;
    this.data.demande.prestationIds = prestationIds;
  }

  onUpdateRealisateurId(realisateurId: number) {
    if (realisateurId === null) {
      this.data.demande.commercialId = -1;
      this.commercial = null;
    } else {
      this.data.demande.commercialId = realisateurId;
      this.data.demande.agenceId = this.users.find(x => x.id === realisateurId).agenceId;
      this.commercial = this.users.find(x => x.id === realisateurId);
    }
  }

  typChange() {
    this.typeDemande = Object.create(this.typeDemandes.find(ta => ta.id === this.data.demande.typeDemandeId));
    this.data.demande.typeDemandeId = this.typeDemande.id;
  }


  createurChange() {
    this.user = Object.create(this.users.find(usr => usr.id === this.data.demande.createurId));
  }

  getInterlocuteur() {
    if (this.interlocuteur) {
      this.interlocuteurExists = true;
    }
  }

  changePageAction(event?: PageEvent) {
    this.pageIndexAction = event.pageIndex;
    this.pageSizeAction = event.pageSize;
    this.loadPageAction();
  }

  trieTabAction(sort: Sort) {
    this.sortStateAction = sort;
    this.loadPageAction();
  }

  loadPageAction() {
    this.dataSourceAction = this.data.demande.actions;
    const act = this.dataSourceAction.find(a => a.statut === 'En cours' || a.statut === 'Clos');
    if (act && this.data.demande.statut === 'A traiter') {
      this.data.demande.statut = 'En cours';
      this.demandeService.put(this.data.demande).subscribe();
    }
    this.matDataSourceAction = this.comp.sortData(this.sortStateAction, this.dataSourceAction,
      this.matDataSourceAction, this.pageIndexAction, this.pageSizeAction);
    if (this.matDataSourceAction === undefined) {
      const data = this.dataSourceAction.slice(0, this.pageSizeAction);
      this.matDataSourceAction = new MatTableDataSource(data);
    }
  }

  onModalCancel(): void {
    this.dialogRef.close(this.data.result);
    this.data.demande.actions = this.dataSourceAction;
    this.modalsService.closeDemande(this.data.demande);
  }

  onModalMinimize(): void {
    this.dialogRef.close(this.data.result);
  }

  onModalListeAction(): void {
    this.data.viewActions = !this.data.viewActions;
    if (this.data.viewDocuments === true) {
      this.data.viewDocuments = !this.data.viewDocuments;
    } else {
      this.data.viewDocuments = this.data.viewDocuments;
    }
  }

  updateDemande(dialogRef) {
    try {
      this.demandeService.put(this.data.demande).subscribe((a: DemandeModel) => {
        if (!a) {
          dialogRef.close(true); //spinner
        }
        this.data.result = true;
        this.data.demande = a;
        this.dialogRef.close(a); //demande
        dialogRef.close(true); //spinner
        this.toastr.success('La demande : ' + a.objet + ' a bien été modifiée');
        this.modalsService.closeDemande(this.data.demande);
        if (this.router.url === '/') {
          this.router.navigate(['']);
        }
      }, error =>
      {
        dialogRef.close();
        if (error.error) {
          this.toastr.error("Erreur lors de l’enregistrement de la demande : " + error.error);
        }
        else {
          this.toastr.error("Erreur lors de l’enregistrement de la demande");
        }
      });
    } catch {
      dialogRef.close(true); //spinner
    }
  }

  // button enregistrer
  onModalConfirm() {
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    if (this.data.demande) {
      this.saveDemande(dialogRef);
    } else {
      dialogRef.close(false);
    }
  }

  onOpenAction(id: number): void {
    this.actionService.get(id).subscribe((a: ActionModel) => {
      this.modalsService.openAction(a).subscribe(result => {
        this.result = result;
        this.onUpdated.emit(true);
        this.data.result = result;
        if (this.result) {
          this.actionService.getFromDemande(this.data.demande.id).subscribe((actions: ActionModel[]) => {
            this.dataSourceAction = actions;
            this.data.demande.actions = actions;
            this.loadPageAction();
          });
        this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
          this.data.demande = dem;
        });
        }
      });
    });
  }

  onVoirAction(id: number): void {
    this.actionService.get(id).subscribe((action: ActionModel) => {
      const dialogRef = this.dialog.open(ModalClosedActionComponent, {
        data: {
          action: action,
          result: this.result
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.result = result;
        if (this.result) {
          this.actionService.getFromDemande(this.data.demande.id).subscribe((actions: ActionModel[]) => {
            this.dataSourceAction = actions;
            this.data.demande.actions = actions;
            this.loadPageAction();
          });
        } else {
        }
      });
    });
  }

  onAddAction(): void {
    const dialogRef = this.dialog.open(ModalAjoutActionComponent, {
      disableClose: true,
      data: {
        tierId: this.data.demande.tierId,
        realisateurId: this.sessionService.currentUserValue.id,
        action: new ActionModel(),
        demandeId: this.data.demande.id,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result[0];
      this.data.result = result[0];
      if (this.result) {
        this.data.demande.actions.push(result[1]);
        this.dataSourceAction = this.data.demande.actions;
        this.loadPageAction();
      }
    });
  }

  onAddRelance(): void {
    const dialogRef = this.dialog.open(ModalAjoutActionComponent, {
      disableClose: true,
      data: {
        tierId: this.data.demande.tierId,
        realisateurId: this.sessionService.currentUserValue.id,
        action: new ActionModel(),
        demandeId: this.data.demande.id,
        result: this.result,
        date: new Date(),
        typeTitre: "relance",
        typeAction: 1001,
        satut: "Clos"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result[0];
      this.data.result = result[0];
      if (this.result) {
        this.data.demande.actions.push(result[1]);
        this.dataSourceAction = this.data.demande.actions;
        this.loadPageAction();
      }
    });
  }


  parseResponse(response: any, libelle: string): string {
    let res = '________________________________________________ \n ';
    if (response !== null) {
      const array = response.response;
      const date = response.date;
      res = res + 'Formulaire : ' + libelle + '  ' + date + '\n';
      if (array.length !== 0 && array !== null) {
        for (let i = 0; i < array.length; i++) {
          const value = array[i].value.replace(/\"/gi, '');
          const question = array[i].questionName.replace(/\?/gi, '');
          if (value.length !== 0) {
            res = res + question + ' : ' + value + ' \n ';
          }
        }
        return res;
      } else { // pas de submit
        return '';
      }
    } else {
      this.toastr.warning('Erreur formulaire', 'Le lien du formulaire n\'est pas valide');
      return ' Collect des emails non activé pour le formulaire  \n' + libelle;

    }
  }

  getResponses(lien: string, libelle: string): void {
    this.loading = true;
    this.demandeService.getFormulaireFromUrl(lien).subscribe(response => {
      if (this.data.demande.reponseForm !== null && this.data.demande.reponseForm !== undefined) {
        this.data.demande.reponseForm = this.parseResponse(response, libelle) + this.data.demande.reponseForm;
      } else {
        this.data.demande.reponseForm = this.parseResponse(response, libelle);
      }
      this.loading = false;
    });
  }

  onEditInterlocuteur(interlocuteur: InterlocuteurModel) {
    if (interlocuteur) { // modification dun interlocuteur
      this.data.demande.interlocuteurId = interlocuteur.id;
      this.data.demande.nomInterlocuteur = interlocuteur.nom;
      this.data.demande.prenomInterlocuteur = interlocuteur.prenom;
      this.data.demande.roleInterlocuteur = interlocuteur.role;
      this.data.demande.emailInterlocuteur = interlocuteur.email;
      this.data.demande.telPrincipalInterlocuteur = interlocuteur.telPrincipal;
      this.data.demande.telSecondaireInterlocuteur = interlocuteur.telSecondaire;
      this.data.demande.commentaireInterlocuteur = interlocuteur.commentaire;
      this.data.demande.civiliteInterlocuteur = (interlocuteur.civilite ? interlocuteur.civilite.libelle : undefined);
      if (this.data.demande.nomInterlocuteur.trim().length === 0) {
        this.data.demande.nomInterlocuteur = undefined;
        this.data.demande.prenomInterlocuteur = undefined;
        this.data.demande.roleInterlocuteur = undefined;
        this.data.demande.emailInterlocuteur = undefined;
        this.data.demande.telPrincipalInterlocuteur = undefined;
        this.data.demande.telSecondaireInterlocuteur = undefined;
        this.data.demande.commentaireInterlocuteur = undefined;
        this.data.demande.civiliteInterlocuteur = undefined;
      }
    } else { // suppression d'un interlocuteur
      this.data.demande.interlocuteurId = undefined;
      this.data.demande.nomInterlocuteur = undefined;
      this.data.demande.prenomInterlocuteur = undefined;
      this.data.demande.roleInterlocuteur = undefined;
      this.data.demande.emailInterlocuteur = undefined;
      this.data.demande.telPrincipalInterlocuteur = undefined;
      this.data.demande.telSecondaireInterlocuteur = undefined;
      this.data.demande.commentaireInterlocuteur = undefined;
      this.data.demande.civiliteInterlocuteur = undefined;
    }
  }

  /* Fonctions pour la gestion des documents */

  getDocumentByID(document: DocumentModel) {
    this.document = document;
  }

  onShowDocuments(){
    const dialogRef = this.dialog.open(ModalAfficherDocumentsComponent, {
      disableClose: false,
      data: {
        demande: this.data.demande
      },
      panelClass: 'mat-dial-no-padd'
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  onModalListeDocument(): void {
    this.data.viewDocuments = !this.data.viewDocuments;

    if (this.data.viewActions === true) {
      this.data.viewActions = !this.data.viewActions;
    } else {
      this.data.viewActions = this.data.viewActions;
    }
  }

  onAddDocument(): void {
    const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
      data: {
        type: "DemandeS3",
        document: new DocumentModel(),
        typeId: this.data.demande.id,
        tierId: this.data.demande.tierId,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result ? true : false;
      this.data.result = result ? true : false;
      if (this.result) {
        this.demandeService.get(this.data.demande.id).subscribe((dem: DemandeModel) => {
          this.data.demande = dem;
        });
      }
    });
  }

  onChecked(checked: boolean, action: ActionModel) {
    if (checked) {
      this.data.demande.actions.find(a => a.id === action.id).statut = 'Clos';
    } else {
      this.data.demande.actions.find(a => a.id === action.id).statut = 'En cours';
    }
    this.actionService.put(this.data.demande.actions.find(a => a.id === action.id)).subscribe(
      (res: ActionModel) =>{
        this.data.demande.actions = this.data.demande.actions.filter(a => a.id !== action.id);
        this.data.demande.actions.push(res);
        this.loadPageAction();
      }
    );
  }

  createInterlocuteur(dialogRef) {
    const interlocuteur = new InterlocuteurModel();
    interlocuteur.id = undefined;
    interlocuteur.tierId = this.data.demande.tierId;
    interlocuteur.nom = this.data.demande.nomInterlocuteur;
    interlocuteur.prenom = this.data.demande.prenomInterlocuteur;
    interlocuteur.role = this.data.demande.roleInterlocuteur;
    interlocuteur.commentaire = this.data.demande.commentaireInterlocuteur;
    interlocuteur.telPrincipal = this.data.demande.telPrincipalInterlocuteur;
    interlocuteur.telSecondaire = this.data.demande.telSecondaireInterlocuteur;
    interlocuteur.email = this.data.demande.emailInterlocuteur;
    let civ: CiviliteModel;
    if (this.data.demande.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.demande.civiliteInterlocuteur.toLowerCase()) ;
        interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      interlocuteur.civiliteId = null;
    }
    interlocuteur.civilite = null;
    this.interlocuteurService.post(interlocuteur).subscribe((interloc: InterlocuteurModel) => {
      this.data.demande.interlocuteurId = interloc.id;
      this.updateDemande(dialogRef);
    }, error =>
    {
      dialogRef.close();
      if (error.error) {
        this.toastr.error("Erreur lors de l’enregistrement de l’interlocuteur de la demande : " + error.error);
      }
      else {
        this.toastr.error("Erreur lors de l’enregistrement de l’interlocuteur de la demande");
      }
    });
  }

  saveDemande(dialogRef) {
    // Si dans la demande il y a une adresse et pas d'interlocuteur, alors création de la demande.
    this.updateDemande(dialogRef);
    
  }

  onDupliquer(){
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    var demandeToCreate = Object.assign({}, this.data.demande);
    demandeToCreate.id = 0;
    demandeToCreate.tier = null;
    demandeToCreate.typeDemande = null;
    demandeToCreate.commercial = null;
    demandeToCreate.agence = null;
    demandeToCreate.createur = null;
    demandeToCreate.tierFactureId = demandeToCreate.tierFacture ? demandeToCreate.tierFacture.id : null;
    demandeToCreate.tierFacture = null;
    this.demandeService.duplicate(demandeToCreate).subscribe((dem: DemandeModel) => {
      this.demandeService.get(dem.id).subscribe(res => {
        dialogRef.close();
        this.toastr.success('La demande : ' + res.objet + ' a bien été dupliquée');
        this.toastr.info('Vous pouvez maintenant ajouter les tâches correspondantes à la nouvelle demande');
        this.modalsService.openDemande(res).subscribe(demMod => {
          this.onModalCancel();
        }, error =>  {
          this.toastr.error("Erreur durant l’ouverture de la demande dupliquée");
        });
      }, error =>  {
        this.toastr.error("Erreur durant la récupération de la demande dupliquée");
        dialogRef.close();
      });
    }, error =>  {
      this.toastr.error("Erreur durant la création de la demande dupliquée");
      dialogRef.close();
    });
  }

  onAddOrEditAddress(adresse: SiteGeoModel) {
    if (adresse) {
      this.data.demande.siteGeoId = adresse.id;
      this.data.demande.libelleAdresse = adresse.libelle;
      this.data.demande.complementAdresse = adresse.complementAdresse;
      this.data.demande.adresse = adresse.adresse;
      this.data.demande.villeAdresse = adresse.ville;
      this.data.demande.commentaireAdresse = adresse.commentaire;
      this.data.demande.codePostalAdresse = adresse.codePostal;
      this.data.demande.emailAdresse = adresse.email;
      this.data.demande.telephoneAdresse = adresse.telephone;
      this.data.demande.gpsAdresse = adresse.gps;
      this.data.demande.numSite = adresse.numSite;
      this.getTime();
    } else {
      this.data.demande.siteGeoId = undefined;
      this.data.demande.libelleAdresse = undefined;
      this.data.demande.commentaireAdresse = undefined;
      this.data.demande.adresse = undefined;
      this.data.demande.villeAdresse = undefined;
      this.data.demande.codePostalAdresse = undefined;
      this.data.demande.complementAdresse = undefined;
      this.data.demande.commentaireAdresse = undefined;
      this.data.demande.emailAdresse = undefined;
      this.data.demande.telephoneAdresse = undefined;
      this.data.demande.gpsAdresse = undefined;
      this.data.demande.numSite = undefined;
      this.timeTravel = "";
    }
  }
  
  copyclip(res: string, event: Event) {
    event.stopPropagation();
    this.clipboard.copy(res);
  }

  saveAddressSaveDemande(dialogRef) {
    if (!this.data.demande.siteGeoId) {
      const site = new SiteGeoModel();
      site.id = undefined;
      site.tierId = this.data.demande.tierId;
      site.libelle = this.data.demande.libelleAdresse;
      site.adresse = this.data.demande.adresse;
      site.email = this.data.demande.emailAdresse;
      site.ville = this.data.demande.villeAdresse;
      site.codePostal = this.data.demande.codePostalAdresse;
      site.agenceId = this.data.demande.agenceId;
      site.complementAdresse = this.data.demande.complementAdresse;
      site.commentaire = this.data.demande.commentaireAdresse;
      site.telephone = this.data.demande.telephoneAdresse;
      site.gps = this.data.demande.gpsAdresse;
      site.numSite = this.data.demande.numSite;
      this.siteService.post(site).subscribe((adresse: SiteGeoModel) => {
        this.data.demande.siteGeoId = adresse.id;
        this.saveDemande(dialogRef);
      }, error =>
      {
        dialogRef.close();
        if (error.error) {
          this.toastr.error("Erreur lors de l’enregistrement de l’adresse de la demande : " + error.error);
        }
        else {
          this.toastr.error("Erreur lors de l’enregistrement de l’adresse de la demande");
        }
      });
    } else {
      this.saveDemande(dialogRef);
    }
  }

  openHistorique(event: Event) {
    event.stopPropagation();
    this.dialog.open(ModalHistoriqueDemandesComponent, {
      data: {
        title: "Historique des demandes liées",
        demandes: this.demandesLinked,
      }
    });
  }
  onChangeCommentaire(value: string) {
    this.adresseFact.commentaire = value;
    this.data.demande.commentaireAdresseFact = value;
  }

  onTiersFactureChange(tiersFacture: TierModel) {
    if(tiersFacture){
      this.tiersFacture.id = tiersFacture.id;
      this.data.demande.tierFactureId = tiersFacture.id;
  
      this.adresseFact = new SiteGeoModel();
      this.adresseFact.agenceId = this.data.demande.tierId;
      this.adresseFact.libelle = "Facturation";
      this.adresseFact.adresse = tiersFacture.adresseFact;
      this.adresseFact.ville = tiersFacture.villeFact;
      this.adresseFact.codePostal = tiersFacture.codePostalFact;
      this.adresseFact.complementAdresse = tiersFacture.complementAdresseFact;
      this.data.demande.adresseFact = tiersFacture.adresseFact;
      this.data.demande.villeAdresseFact = tiersFacture.villeFact;
      this.data.demande.codePostalAdresseFact = tiersFacture.codePostalFact;
      this.data.demande.complementAdresseFact = tiersFacture.complementAdresseFact;
    } else {
      this.tiersFacture.id = null;
      this.data.demande.tierFactureId = null;
      this.adresseFact = new SiteGeoModel();
      this.adresseFact.libelle = "Facturation";
      
      this.data.demande.adresseFact = "";
      this.data.demande.villeAdresseFact = "";
      this.data.demande.codePostalAdresseFact = "";
      this.data.demande.complementAdresseFact = "";
    }
  }

  onEnvoisJason(): void {
    if (this.devisDTO.codeActiviteJason) {
      if (this.devisDTO.tierId == null || this.devisDTO.tierId == 0 || this.devisDTO.siteGeoId == null || this.devisDTO.siteGeoId == 0) {
        if (this.devisDTO.tierId == null || this.devisDTO.tierId == 0) {
          this.toastr.error("Erreur : pas de tier DO dans le devis");
        } if (this.devisDTO.siteGeoId == null || this.devisDTO.siteGeoId == 0) {
          this.toastr.error("Erreur : pas d’adresse d’intervention dans le devis");
        }
      } else {
        this.tierService.get(this.devisDTO.tierId).subscribe(tier => {
          if (tier) {
            if (tier.typeTier == "Particulier") {
              this.tierDODevis = tier as ParticulierModel;
  
            } else if (tier.typeTier == "Entreprise") {
              this.tierDODevis = tier as EntrepriseModel;
            } else {
              this.tierDODevis = tier;
            }
            this.siteService.get(this.devisDTO.siteGeoId).subscribe(site  => {
              if (site) {
                this.siteGeoDevis = site as SiteGeoModel;
                if (this.devisDTO.tierFactId != null && this.devisDTO.tierFactId != 0) {
                  this.tierService.get(this.devisDTO.tierFactId).subscribe(tierFact => {
                    if (tierFact) {
                      if (tierFact.typeTier == "Particulier") {
                        this.tierFactDevis = tierFact as ParticulierModel;
                      } else if (tierFact.typeTier == "Entreprise") {
                        this.tierFactDevis = tierFact as EntrepriseModel;
                      } else {
                        this.tierFactDevis = tierFact;
                      }
                    }
                    this.onEnvoisJasonEnd();

                  });
                } else {
                  this.onEnvoisJasonEnd();
                }   
              } else {
                this.toastr.error("Erreur lors du chargement de l’adresse du devis");
              }
            });
          } else {
            this.toastr.error("Erreur lors du chargement du tier DO du devis");
          }
        });
      }
    } else {
      this.toastr.error("Erreur l'activité n'est pas configuré pour l'envoi à Jason");
    }
  }

  onEnvoisJasonEnd() {
    if (this.devisDTO.nomInterlocuteur) {
      this.interlocuteurDevis = new InterlocuteurModel();
      this.interlocuteurDevis.id = this.devisDTO.interlocuteurId;
      this.interlocuteurDevis.tierId = this.devisDTO.tierId;
      this.interlocuteurDevis.telPrincipal = this.devisDTO.telInterlocuteur;
      this.interlocuteurDevis.telSecondaire = this.devisDTO.telSecondaire;
      this.interlocuteurDevis.nom = this.devisDTO.nomInterlocuteur;
      this.interlocuteurDevis.prenom = this.devisDTO.prenomInterlocuteur;
      this.interlocuteurDevis.civiliteId = this.devisDTO.civiliteId;
      this.interlocuteurDevis.email = this.devisDTO.emailInterlocuteur;
      this.interlocuteurDevis.role = this.devisDTO.roleInterlocuteur;
      this.interlocuteurDevis.commentaire = this.devisDTO.commentaireInterlocuteur;
      this.interlocuteurDevis.idJason = this.devisDTO.idJasonInterlocuteur;
    }
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      autoFocus: false,
      disableClose: true,
      maxHeight: '90vh',
      data: {
        title: 'Envoyer à jason',
        color: 'success',
        demande: this.data.demande,
        result: this.result,
        devisShared: this.devisShared,
        devisDTO: this.devisDTO,
        prestations: this.prestations,
        tierDODevis: this.tierDODevis,
        tierFactDevis: this.tierFactDevis,
        siteGeoDevis: this.siteGeoDevis,
        interlocuteurDevis: this.interlocuteurDevis
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.devisJasonDTO.demande = this.data.demande;
        this.devisJasonDTO.devis = this.devisDTO;

        let firstPrestaId = this.devisDTO.idActivites.split(',')[0];
        let i = 0;
        while (i < this.prestations.length) {
          if (this.prestations[i].id === Number(firstPrestaId)) {
            this.devisJasonDTO.codeActivite = this.prestations[i].code;
            i = this.prestations.length;
          }
          i++;
        }

        if (this.tierDODevis) {
          if (this.tierDODevis.typeTier == "Particulier") {
            this.devisJasonDTO.particulierDO = this.tierDODevis as ParticulierModel;
  
          } else if (this.tierDODevis.typeTier == "Entreprise") {
            this.devisJasonDTO.entrepriseDO = this.tierDODevis as EntrepriseModel;
          } else {
            this.devisJasonDTO.tiersDO = this.tierDODevis;
          }
        }

        if (this.tierFactDevis) {
          if (this.tierFactDevis.typeTier == "Particulier") {
            this.devisJasonDTO.particulierFacture = this.tierFactDevis as ParticulierModel;
          } else if (this.tierFactDevis.typeTier == "Entreprise") {
            this.devisJasonDTO.entrepriseFacture = this.tierFactDevis as EntrepriseModel;
          } else {
            this.devisJasonDTO.tiersFacture = this.tierFactDevis;
          }
        }

        if (this.siteGeoDevis) {
          this.devisJasonDTO.site = this.siteGeoDevis as SiteGeoModel;
        }

        if (this.interlocuteurDevis) {
          this.devisJasonDTO.interlocuteur = this.interlocuteurDevis as InterlocuteurModel;
        }

        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.demandeService.sendDevisToJason(this.devisJasonDTO).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error && error.error.reason) {
              return of(error.error)
            } else {
              throw (error);
            }
          }), switchMap((res: InfoJasonDTO) => {
          if (res) {
            let resDemande  = Object.assign({}, res);
            let resDevis  = Object.assign({}, res);
            if (res.envoyeAJason == EnvoiJason.INTEGRE){
              this.toastr.success('Devis envoyé à JASON');
              this.isEnvoyeJason = true;
            } else if (res.envoyeAJason == 1) {
              this.toastr.warning('Devis refusé par JASON', res.reason);
            } else if (res.envoyeAJason == EnvoiJason.NONENVOYE) {
              this.toastr.error('Information non envoyé a Jason', res.reason);
            }
            return forkJoin(this.devisService.UpdateInfoJason(resDevis, this.devisDTO.idDevis).pipe(catchError(() => {
              this.toastr.error("Erreur lors de la mise jour du devis Intercom"); 
              throw new Error("Devis non enregistré");
            })),this.demandeService.UpdateInfoJason(resDemande, this.devisDTO.demandeId).pipe(catchError(() => {
              this.toastr.error("Erreur lors de la mise jour de la demande Intercom"); 
              throw new Error("Statut de la demande non enregistré");
            })));
          } else {
            throw new Error("Devis refusé par Jason");
          }
        })).subscribe((res : [DevisDTO, DemandeModel]) => {
          dialogRef.close();
          this.devisDTO = res[0];
          // this.demande = res[1];
          this.devisShared.statut = this.devisDTO.statut;
          this.devisShared = Object.assign({}, this.devisShared);
          this.devis = this.devisDTO.statut + ", " + this.devisDTO.ht + "€ HT / " + this.devisDTO.ttc + "€ TTC";
        }, (error: HttpErrorResponse) => {
          this.isEnvoyeJason = true;
          dialogRef.close();
          this.toastr.error(error.error);
        });
      }
    });
  }

  openGMap() {
    let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;
    if (this.commercial && this.commercial.agenceId){
      this.agenceService.get(this.commercial.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {
          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else if (this.sessionService.currentUserValue?.agenceId) {    
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agenceUser: AgenceModel) => {
        if (agenceUser.adresse && agenceUser.codePostal && agenceUser.ville) {
          let src = agenceUser.adresse + ', ' + agenceUser.codePostal + ' ' + agenceUser.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      });
    } else {
      window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
    }
  }

  getTime() {
    if (!this.adresse?.adresse || !this.adresse.codePostal || !this.adresse.ville) {
      this.timeTravel = "Adresse d'intervention incomplète ou incorrecte";
    } else if (this.commercial) {
      this.agenceService.get(this.commercial.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            this.distanceTravel = results.routes[0].legs[0].distance.text;
            this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Adresse de l'agence du réalisateur incomplète ou incorrecte"; }
      });
    }  else if (this.sessionService.currentUserValue?.agenceId) {
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          let dst = this.adresse.adresse + ', ' + this.adresse.codePostal + ' ' + this.adresse.ville;

          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
          });
        } else { this.timeTravel = "Erreur : Adresse de votre agence est incomplète ou incorrecte"; }
      });
    } else { this.timeTravel = "Impossible de calculer l'itinéraire"; }
  }
  
  limitCommentTextArea(evt: KeyboardEvent) {

    let textarea = evt.target as any
    let maxRows: number = 24;

    let newContent: string = (textarea.value as string)
        .split('\n')
        .filter((val, row) => row < maxRows)
        .join('\n');

    textarea.value = newContent;
  }

}
