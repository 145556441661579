import { Injectable } from "@angular/core";
import { TierModel } from "../models/tier/tier.model";

@Injectable()
export class TierDTO {
  public clientNumber: string;
  public identifiantJason: string;
  public typeTier: string;
  public libelle: string;
  public natureId: number;
  public tva: string;
  public numCommOblig: boolean;
  public regleSurPlace: boolean;
  public adresseIdentique: boolean;
  
  public adresse?: string;
  public adresseFact?: string;

  public complementAdresse?: string;
  public complementAdresseFact?: string;

  public codePostal?: string;
  public codePostalFact?: string;

  public ville?: string;
  public villeFact?: string;

  public email?: string;
  public telephone?: string;

  public agenceId: number;
  public commercialId: number;

  public libelleAdresse?: string;

  public idtAdresseFact?: number;
}
