import { Router } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ZoneService } from '../../../services/zone.service';
import { ZoneInterventionModel } from '../../../shared/models/zone-intervention.model';
import { GestionZonesComponent } from '../gestion-zones.component';

export interface DialogData {
  zone: ZoneInterventionModel;
  tierId: number;
  agenceId: number;
  realisateurId: number;
  result: boolean;
}

@Component({
  selector: 'app-modal-ajout-zone',
  templateUrl: './modal-ajout-zone.component.html'
})
export class ModalAjoutZoneComponent implements OnInit {
  zonesAgence: ZoneInterventionModel[] = [];

  constructor(public dialog: MatDialog
    , public dialogRef: MatDialogRef<GestionZonesComponent>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private toastr: IntercomToastrService
    , private router: Router
    , private zoneService: ZoneService
    ) { }

    ngOnInit() {
      this.data.zone.agenceId = this.data.agenceId;
      // Récupération des zones de l'agence
      if (this.data.agenceId) {
        this.zoneService.getFromAgence(this.data.agenceId).subscribe((zones: ZoneInterventionModel[]) => {
          this.zonesAgence = zones;
        });
      }    
    }

    onModalCancel(): void {
      this.data.result = false;
      this.dialogRef.close(false);
    }

    onModalConfirm(): void {
      if (this.data.zone) {
        this.saveZone();
      }
   }

   saveZone() {
    this.data.result = true;
    this.data.zone.agence = null;
    if ( this.data.zone.libelle !== '' && this.data.zone.libelle ) {
      // tslint:disable-next-line: max-line-length
      if( this.data.zone.id){
        this.zoneService.put(this.data.zone).subscribe((a: ZoneInterventionModel) => {
          this.data.zone = a;
          this.toastr.success('', 'Modification de la zone réussie');
          this.dialogRef.close(true);
        });
      }else {
        if (this.zonesAgence.find(zone => zone.codePostal === this.data.zone.codePostal) === undefined) {
          this.zoneService.post(this.data.zone).subscribe((a: ZoneInterventionModel) => {
            this.data.zone = a;
            this.toastr.success('', 'Création de la zone réussie');
            this.dialogRef.close(true);
          });
        } else {
          this.toastr.warning('Impossible d\'enregistrer.', ' Le code postale est déja défini pour une autre zone');
        }
      }
      
    } else {
      this.toastr.warning('Veuillez remplir les champs obligatoire', 'Impossible d\'enregistrer la zone');
    }
   }
}


 