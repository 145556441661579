import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';


@Component({
  selector: 'app-autocomplete-users',
  templateUrl: './autocomplete-users.component.html'
})
export class AutocompleteUsersComponent implements OnChanges {

 
  @Input() users: UserModel[];
  @Input() user: UserModel;
  @Input() selectedusers: UserModel[];
  @Input() libelle: string;
  @Output() onUpdated = new EventEmitter<number>();
  @ViewChild('matInput') matInput: ElementRef<HTMLInputElement>;

  realisateursString: string[] = [];
  filteredRealisateur: Observable<string[]>;
  myControl = new UntypedFormControl();

  reLoadUsers() {
    this.realisateursString = [];
    if (this.users !== undefined) {
      for (let i = 0; i < this.users.length; i++) {
        this.realisateursString.push(this.users[i].nom + ' ' + (this.users[i].prenom ? this.users[i].prenom : ''));
      }
      this.realisateursString.sort();
      this.filteredRealisateur = this.myControl.valueChanges.pipe(
        startWith(null),
        map((usr: string | null) => usr ? this._filter(usr) : this.realisateursString.slice()));
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.users = changes.users.currentValue;
      this.reLoadUsers();
    }
  }

  /* selected event*/
  intervenantChange(event: MatAutocompleteSelectedEvent) {
    const value = event.option.viewValue;
    this.user = this.users.find(usr => (usr.nom + ' ' + usr.prenom).trim()  === value.trim());
    this.onUpdated.emit(this.user.id);
    this.reLoadUsers();
    this.myControl.setValue(null);
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.realisateursString.filter(realisateur => realisateur.toLowerCase().indexOf(filterValue) >= 0);
  }

}
