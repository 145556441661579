import { Component, Input, Output, OnChanges, SimpleChanges, ViewChild, Injector, EventEmitter } from '@angular/core';
import { SiteGeographiqueComponent } from '../site-geographique/site-geographique.component';
import { InterlocuteursComponent } from '../interlocuteurs/interlocuteurs.component';
import { ArrayDocumentComponent } from '../document/array-document/array-document.component';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { DocumentService } from '../../services/document.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { SiteGeoService } from '../../services/site-geo.service';
import { TierService } from '../../services/tier/tier.service';
import { DocumentModel } from '../../shared/models/document/document.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { InterlocuteurDTO } from '../../shared/dtos/interlocuteurDTO';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { ToastrLoadingService } from '../../services/toastrLoading.service';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { SiteGeoPgResult } from '../../shared/dtos/siteGeoPgResult';
import { InterlocuteurPgResult } from '../../shared/dtos/interlocuteurPgResult';

@Component({
  selector: 'app-tiers',
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.scss']
})

export class TiersComponent implements OnChanges {
  @ViewChild(SiteGeographiqueComponent) sitegeosComp: SiteGeographiqueComponent;
  @ViewChild(InterlocuteursComponent) interlocuteursComp: InterlocuteursComponent;
  @ViewChild(ArrayDocumentComponent) documentsComp: ArrayDocumentComponent;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated = new EventEmitter<boolean>();
  @Input() tier: TierModel = new TierModel();
  sites: SiteGeoModel[] = [];
  interlocuteurs: InterlocuteurModel[] = [];
  panelOpenStateSite = true;
  panelOpenStateInterlocuteur = true;
  panelOpenStateDocument = true;
  document: DocumentModel;
  documents: DocumentModel[] = [];
  result: boolean;
  simpleQueryInterlocuteur: SimpleQuery<InterlocuteurDTO> = new SimpleQuery<InterlocuteurDTO>();
  simpleQueryResponseInterlocuteur: SimpleQueryResponse<InterlocuteurPgResult>;
  simpleQuerySiteGeo: SimpleQuery<SiteGeoModel> = new SimpleQuery<SiteGeoModel>();
  simpleQueryResponseSiteGeo: SimpleQueryResponse<SiteGeoPgResult>;


  constructor(
    private tierService: TierService,
    private sitegeoService: SiteGeoService,
    private interlocService: InterlocuteurService,
    private documentService: DocumentService,
    private modalsService: ModalsService,
    private toastrLoadingService: ToastrLoadingService,
    private siteGeoService: SiteGeoService,
    injector: Injector) {
      this.modalsService = injector.get('ModalsService');
      this.result = false;
      this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
      this.simpleQueryInterlocuteur.entry.TierId = this.tier.id + "";
      this.simpleQuerySiteGeo.entry = new SiteGeoModel();
      this.simpleQuerySiteGeo.entry.tierId = this.tier.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tier = changes.tier.currentValue;
    this.onLoad();
  }

  onAddSite() {
    this.sitegeosComp.onAddSite();
    this.panelOpenStateSite = !this.panelOpenStateSite;
  }

  onAddInterlocuteur() {
    this.interlocuteursComp.onAddInter();
    this.panelOpenStateInterlocuteur = !this.panelOpenStateInterlocuteur;
  }

  onUpdateSite(sitegeos: SiteGeoModel[]) {
    this.sites = Object.assign([], sitegeos);
    this.tierService.get(this.tier.id).subscribe((tier: TierModel) => {
      this.tier = tier;
    });
  }

  onTiersUpdated() {
    this.tierService.get(this.tier.id).subscribe((tier: TierModel) => {
      this.tier = tier;
    });
  }

  onUpdateInterlocuteur(interloc: InterlocuteurModel[]) {
    this.interlocuteurs = Object.assign([], interloc);
    this.tierService.get(this.tier.id).subscribe((tier: TierModel) => {
      this.tier = tier;
    });
  }

  onLoad() {
    if (this.tier != null && this.tier !== undefined && this.tier.id !== 0 && this.tier.id !== undefined) {
      this.documentService.getFromTiers(this.tier.id).subscribe((documents: DocumentModel[]) => {
        this.documents = Object.assign([], documents);
        this.tier.documents = documents;
        this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
        this.simpleQueryInterlocuteur.entry.TierId = this.tier.id + "";
        this.simpleQuerySiteGeo.entry = new SiteGeoModel();
        this.simpleQuerySiteGeo.entry.tierId = this.tier.id;
      });
      /*this.sitegeoService.getFromTier(this.tier.id).subscribe((sitegeos: SiteGeoModel[]) => {
        this.sites = Object.assign([], sitegeos);
      });
      this.interlocService.getFromTier(this.tier.id).subscribe((interloc: InterlocuteurModel[]) => {
        this.interlocuteurs = Object.assign([], interloc);
      });
      this.getDocumentsByTiers();*/
    }
  }

  /* Gestion des documents pour le tiers */

  onAddDocument() {
    this.documentsComp.onAddDoc();
    this.panelOpenStateDocument = !this.panelOpenStateDocument;
  }

  onOpenDocument(document: DocumentModel): void {
    this.document = Object.assign({}, document);
    this.modalsService.openDocument(this.document).subscribe(result => {
      this.result = result;
      if (this.result) {
        this.tierService.get(this.tier.id).subscribe((tier: TierModel) => {
          this.tier = tier;
        });
        this.getDocumentsByTiers();
      }
    });
  }

  rechercheContact(){
    let idSearch = this.toastrLoadingService.newSearch();
    this.interlocService.GetFromObjetPgWithPagination(this.simpleQueryInterlocuteur).subscribe((queryResponse) => {
      this.simpleQueryResponseInterlocuteur = queryResponse;
      this.toastrLoadingService.endSearch(idSearch);
    }, error => {
      this.toastrLoadingService.endSearch(idSearch);
    });
  }

  rechercheAdresse(){
    let idSearch = this.toastrLoadingService.newSearch();
    this.siteGeoService.GetFromObjetPgWithPagination(this.simpleQuerySiteGeo).subscribe((queryResponse: SimpleQueryResponse<SiteGeoPgResult>) => {
      this.simpleQueryResponseSiteGeo = queryResponse;
      this.toastrLoadingService.endSearch(idSearch);
    }, error => {
      this.toastrLoadingService.endSearch(idSearch);
    });
  }

  getDocumentsByTiers() {
    this.documentService.getFromTiers(this.tier.id).subscribe((documents: DocumentModel[]) => {
      this.documents = documents;
      this.tier.documents = documents;
    });
  }

}
