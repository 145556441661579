import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-heure',
  templateUrl: './input-heure.component.html'
})
export class InputHeureComponent {
  @Input() value: string;
  @Input() libelle: string;
  @Input() required: boolean;
  @Output() change = new EventEmitter();
  @Output() valueChange = new EventEmitter<string>();


  _heureChange(event: string) {
    const heure1 = event.replace(/[^\d]/g, '');
    let heure2 = '';
    for (let i = 0; i < heure1.length; i = i + 2 ) {
      heure2 += heure1.substring(i, i + 2) + 'h';
    }
    this.value = heure2.substring(0, heure2.length - 1);
    if (this.value.length === 2) {
      this.value = this.value + 'h';
    }
    this.valueChange.emit(this.value);
  }
}
