import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MyBoolean } from 'src/app/modules/core/shared/models/myBoolean';
import { ParticulierModel } from 'src/app/modules/core/shared/models/tier/particulier.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';


@Component({
  selector: 'app-particulier',
  templateUrl: './particulier.component.html'
})

export class ParticulierComponent implements OnInit, OnChanges {
  @Input() tier: ParticulierModel;
  @Input() required = true;
  @Input() disabled = false;
  @Output() tierChange = new EventEmitter<ParticulierModel>();
  keyTitleArray = [
    {property: 'nom', libelle: 'nom'},
    {property: 'prenom', libelle: 'prenom'},
    {property: 'telephone', libelle: 'téléphone'},
    {property: 'proprietaire', libelle: 'propriétaire'},
    {property: 'clientNumber', libelle: 'numéro client'},
    {property: 'identifiantJason', libelle: 'identifiant Jason'}];
  lstProp: MyBoolean[];
  selProp: string;



  ngOnChanges(changes: SimpleChanges): void {
    this.tier = Object.assign({}, changes.tier.currentValue);
  }

  ngOnInit(): void {

    this.lstProp  = [
      { libelle: 'Oui', value: true },
      { libelle: 'Non', value: false }
    ];

    this.selProp = '';
  }

  changeEvent() {
    this.tier.rechercheTitle =  TierModel.getTitle(this.keyTitleArray, this.tier);
    this.tierChange.emit(this.tier);
  }
  proprietaireChange(event) {
    this.tier.proprietaire = event;
    this.changeEvent();
  }
}
