import { Component, OnInit, Inject, Input} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface DialogData {
    title: string;
    motif: string;
    list: string[];
}

@Component({
  selector: 'app-modal-refus-devis',
  templateUrl: './modal-refus-devis.component.html'
})
export class ModalRefusDevisComponent implements OnInit {
  public raison = '';

  constructor(
    public dialogRef: MatDialogRef<ModalRefusDevisComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data : DialogData
    ) { }

  ngOnInit(): void {
  }

  onModalCancel() {
    this.dialogRef.close(false);
  }

  onModalConfirm() {
    this.dialogRef.close(this.raison);
  }

}

