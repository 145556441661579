import { PropertiesInfo } from '../../modals-menu-info';
import { DemandeModel } from '../demande.model';
import { InterlocuteurModel } from '../interlocuteur.model';
import { SiteGeoModel } from '../site-geo.model';
import { ActionModel } from '../action.model';
import { BasicModel } from '../basic-models/basic.model';
import { NatureModel } from '../nature.model';
import { DocumentModel } from '../document/document.model';
import { TierDTO } from '../../dtos/tierDTO';
import { AgenceModel } from '../rsa/agence.model';
import { UserModel } from '../users/user.model';

export class TierModel extends BasicModel {
  public tierFactureId?: number;
  //public tierFacture?: TierModel = new TierModel();
  
  public clientNumber = '';
  public identifiantJason = '';
  public idTJason: number;
  public rechercheTitle?: string;
  public typeTier = '';
  public libelle: string;
  public natureId: number;
  public tva = '20%';
  public numCommOblig: boolean;
  public regleSurPlace: boolean;
  public adresseIdentique = true;

  public adresse?: string;
  public adresseFact?: string;

  public complementAdresse?: string;
  public complementAdresseFact?: string;

  public codePostal?: string;
  public codePostalFact?: string;

  public ville?: string;
  public villeFact?: string;

  public email?: string;
  public telephone?: string;

  public bloque: boolean = false;

  public agenceId?: number;
  public agence?: AgenceModel = new AgenceModel();
  public nature?: NatureModel = new NatureModel();
  public siteGeoP?: SiteGeoModel = new SiteGeoModel();
  public commercialId: number;
  public commercial?: UserModel;
  public actions?: ActionModel[];
  public demandes?: DemandeModel[];
  public interlocuteurs?: InterlocuteurModel[];
  public siteGeos?: SiteGeoModel[];
  public documents?: DocumentModel[];

  public idtAdresseFact?: number;


  public static getTitle(titleArray: PropertiesInfo[], tier: TierModel): string {
    let rechercheTitle = '';
    for (const key of titleArray) {
      if (tier.hasOwnProperty(key.property)) {
        if (tier[key.property] !== null && tier[key.property] !== undefined && tier[key.property].length !== 0)  {
          if (tier[key.property].length === undefined) {
            rechercheTitle += (tier[key.property] ? key.libelle : 'Non ' + key.libelle) + ', ';
          } else {
            rechercheTitle += key.libelle + ' : ' + tier[key.property] + ', ';
          }
        }
      }
    }
    if (rechercheTitle.length > 2) {
      rechercheTitle = rechercheTitle.substring(0, 1).toUpperCase() + rechercheTitle.substring(1, rechercheTitle.length - 2);
    }
    return rechercheTitle;
  }

  constructor(tiers?: TierModel) {
    super();
    if (tiers) {
      this.adresse = tiers.adresse;
      this.adresseFact = tiers.adresseFact;
      this.telephone = tiers.telephone;
      this.email = tiers.email;
      this.adresseIdentique = tiers.adresseIdentique;
      this.agenceId = tiers.agenceId;
      this.clientNumber = tiers.clientNumber;
      this.complementAdresse = tiers.complementAdresse;
      this.complementAdresseFact = tiers.complementAdresseFact;
      this.codePostal = tiers.codePostal;
      this.codePostalFact = tiers.codePostalFact;
      this.commercialId = tiers.commercialId;
      this.identifiantJason = tiers.identifiantJason;
      this.natureId = tiers.natureId;
      this.numCommOblig = tiers.numCommOblig;
      this.regleSurPlace = tiers.regleSurPlace;
      this.tva = tiers.tva;
      this.ville = tiers.ville;
      this.villeFact = tiers.villeFact;
      this.siteGeos = tiers.siteGeos;
      this.interlocuteurs = tiers.interlocuteurs;
      this.libelle = tiers.libelle;
      this.clientNumber = tiers.clientNumber;
      this.bloque = tiers.bloque;
    }
  }

}
