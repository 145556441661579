import { Injectable } from "@angular/core";


@Injectable()
export class InterlocuteurDTO {
    public TelPrincipal : string;
    public TelSecondaire : string;
    public Nom : string;
    public Prenom : string;
    public Email : string;
    public Commentaire : string;
    public Role : string;
    public CiviliteId: string;
    public TierId: string;
    public TierLibelle: string;
}