import { map, filter, switchMap, tap} from 'rxjs/operators';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient, HttpParams, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DemandeDTO } from './../shared/dtos/demandeDTO';
import { UserModel } from '../shared/models/users/user.model';
import { DemandeModel } from '../shared/models/demande.model';
import { TierBasicService } from './basic-service/tier-basic.service';
import { ActionModel } from '../shared/models/action.model';
import { ActionService } from './action.service';
import { VerifyFields } from '../shared/verifyFields';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { DashboardModel } from '../shared/models/dashboard.model';
import { SimpleQuery } from '../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { SessionService } from '../../shared/session.service';
import { DevisJasonDTO } from '../shared/dtos/devisJasonDTO';
import { FiltreDemandeDTO } from '../shared/dtos/filterDemandeDTO';
import { DashboardDTO } from '../shared/dtos/dashboardDTO';
import { FiltrePerimetreDTO } from '../shared/dtos/filterPerimetreDTO';
import { InfoJasonDTO } from '../../devis/DTOs/InfoJasonDTO';
import { DocumentService } from './document.service';
import { DocumentModel } from '../shared/models/document/document.model';

export class IntercomToPraxedoDTO
{
  demandeId : number
  idAgence : number
  codePrestaPxo: string
  document: File;
  constructor(dem: number, agence: number, codePrestaPxo: string, document: File){
    this.demandeId = dem;
    this.idAgence = agence;
    this.codePrestaPxo = codePrestaPxo;
    this.document = document;
  }
}

@Injectable()
export class DemandeService extends TierBasicService {

  closedDemande: DemandeModel[] = [];

  constructor(private actionService: ActionService,
              http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              private IntercomToastrService: IntercomToastrService,
              private documentService: DocumentService) {
          super(http, apiService, sessionService);
          this.serviceUrl = apiService.ROUTEDEMANDE;
        }

  load(demande: DemandeModel) {
    if (demande)
      demande.dateIntervention = new Date(demande.dateIntervention);
  }

  post(objet: DemandeModel): Observable<DemandeModel> {
    if (!this.beforeSave(objet)) {
      return throwError(new Error('sauvegarde impossible'));
    }
    if (!objet.agenceId) {
      objet.agenceId = this.sessionService.currentUserValue.agenceId;
    }
    return this.http.post<DemandeModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
  }

  duplicate(objet: DemandeModel): Observable<DemandeModel> {
    if (!this.beforeSave(objet)) {
      return throwError(new Error('sauvegarde impossible'));
    }
    if (!objet.agenceId) {
      objet.agenceId = this.sessionService.currentUserValue.agenceId;
    }
    return this.http.post<DemandeModel>(this.apiUrl + this.serviceUrl + 'Duplicate/', objet);
  }

  get(objet: number): Observable<DemandeModel> {
    return super.get(objet).pipe(tap((demande: DemandeModel) => {
      this.load(demande);
    }));
  }

  getFromTier(tierId: number): Observable<DemandeModel[]> {
    if (tierId !== undefined) {
       return super.getFromTier(tierId).pipe(tap((demandes: DemandeModel[]) => {
        for (const demande of demandes) {
          this.load(demande);
        }
      }));
    }
  }

  getFromObjet(objet: SimpleQuery<DemandeDTO>): Observable<SimpleQueryResponse<DemandeModel>> {
    // tslint:disable-next-line: max-line-length
    return <Observable<SimpleQueryResponse<DemandeModel>>> super.getFromObjet(objet).pipe(tap((simpleQuery: SimpleQueryResponse<DemandeModel>) => {
      for (const demande of simpleQuery.objets) {
        this.load(demande);
      }
    }));
  }


  sendDevisToJason(objet: DevisJasonDTO): Observable<InfoJasonDTO> {
    return this.http.post<InfoJasonDTO>(this.apiUrl + this.apiService.ROUTEDEVISJASON + 'SendDevisToJason/', objet);
  }

  sendDemandeToPraxedo(objet: FormData): Observable<string> {
    return this.http.post<string>(this.apiUrl + this.apiService.ROUTEDEMANDEPRAXEDO + 'sendToPraxedo/', objet);
  }

  updateToPraxedo(objet: FormData): Observable<DemandeModel> {
    return this.http.post<DemandeModel>(this.apiUrl + this.apiService.ROUTEDEMANDEPRAXEDO + 'updateToPraxedo/', objet);
  }

  cancelPraxedoIntervention(objet: IntercomToPraxedoDTO): Observable<string> {
    return this.http.post<string>(this.apiUrl + this.apiService.ROUTEDEMANDEPRAXEDO + 'cancelPraxedoIntervention/', objet);
  }
  
  getFiltredDemande(fitredto: FiltreDemandeDTO):  Observable<SimpleQueryResponse<DemandeModel>>{
    return this.http.post<SimpleQueryResponse<DemandeModel>>(this.apiUrl+ this.serviceUrl + 'getFiltredData/',fitredto);
  }

  getAll(): Observable<DemandeModel[]> {
    return super.getAll().pipe(tap((demandes: DemandeModel[]) => {
      for (const demande of demandes) {
        this.load(demande);
      }
    }));
  }

  getAllFiltred(value): Observable<DemandeModel[]> {
    let param = new HttpParams();
    param = param.set('value', value);   
    return  this.http.get<DemandeModel[]>(this.apiUrl+ this.serviceUrl + 'getAllFiltred', {params: param}).pipe(tap((demandes: DemandeModel[]) => {
      for (const demande of demandes) {
        this.load(demande);
      }
    }));
  }

  getDashboard(filterPerimetre:FiltrePerimetreDTO):  Observable<DashboardDTO>{
    return this.http.post<DashboardDTO>(this.apiUrl+ this.serviceUrl + 'getDashboard/',filterPerimetre);
  }
  



  getDemandeAndActionFromUsers(ids: number[]): Observable<DashboardModel> {
    return this.http.post<DashboardModel>(this.apiUrl + this.serviceUrl +
      'GetDemandeAndActionFromUsers/', ids).pipe(tap((dashboard: DashboardModel) => {
      for (const demande of dashboard.demandes) {
        this.load(demande);
      }
      for(const action of dashboard.actions) {
        this.actionService.loadAction(action);
      }
    }));
  }

  getFromUsers(ids: number[]): Observable<DemandeModel[]> {
    return this.http.post<DemandeModel[]>(this.apiUrl + this.serviceUrl + 'GetFromUsers/', ids).pipe(tap((demandes: DemandeModel[]) => {
      for (const demande of demandes) {
        this.load(demande);
      }
    }));
  }

  getDashboardAFaire(users: UserModel[]): Observable<DemandeModel[]> {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new DemandeDTO();
      objetDemande.nonClos = true;
      objetDemande.commercialId = user.id;
      //promiseArray.push(this.getFromObjet(objetDemande));
    }
    return forkJoin(promiseArray).pipe(map((listArray: DemandeModel[]) => {
      let demandesaTraiter: DemandeModel[] = [];
      for (const arrayDemandes of listArray) {
        demandesaTraiter = demandesaTraiter.concat(arrayDemandes);
      }
      return demandesaTraiter;
    }));
  }

  getDashboardNonLu(users: UserModel[]) {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new DemandeDTO();
      objetDemande.statut = 'A traiter';
      objetDemande.commercialId = user.id;
     // promiseArray.push(this.getFromObjet(objetDemande));
    }
    return forkJoin(promiseArray).pipe(map((listArray: DemandeModel[]) => {
      let demandesaTraiter: DemandeModel[] = [];
      for (const arrayDemandes of listArray) {
        demandesaTraiter = demandesaTraiter.concat(arrayDemandes);
      }
      return demandesaTraiter;
    }));
  }

  getDashboardRetard(users: UserModel[]) {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new DemandeDTO();
      objetDemande.nonClos = true;
      objetDemande.commercialId = user.id;
      objetDemande.DateBiggerInterventionString = new Date(Date.now());
      objetDemande.DateBiggerInterventionString.setHours(0);
      objetDemande.DateBiggerInterventionString.setMinutes(0);
      objetDemande.DateBiggerInterventionString.setMilliseconds(0);
      //promiseArray.push(this.getFromObjet(objetDemande));
    }
    return forkJoin(promiseArray).pipe(map((listArray: DemandeModel[]) => {
      let demandesaTraiter: DemandeModel[] = [];
      for (const arrayDemandes of listArray) {
        demandesaTraiter = demandesaTraiter.concat(arrayDemandes);
      }
      return demandesaTraiter;
    }));
  }

  getActions(demandeId: number): Observable<ActionModel[]> {
    return this.actionService.getFromDemande(demandeId);
  }

  getFromUsersActive(users: UserModel[]): Observable<DemandeModel[][]> {
    const obsDemandes: Observable<DemandeModel[]>[] = [];

    for (const user of users) {
      obsDemandes.push(this.http.get<DemandeModel[]>(this.apiUrl + this.serviceUrl + 'GetFromUser/' + user.id, {headers: this.header}));
    }
    return forkJoin(obsDemandes).pipe(tap((usersDemandes: DemandeModel[][]) => {
      for (const userDemande of usersDemandes) {
        for (const demande of userDemande) {
          this.load(demande);
        }
      }
    }));
  }



  // script start when the user open a form to submit
  getFormulaireFromUrl(urlForms: string): Observable<any> {
   // const url = 'https://script.google.com/a/veolia.com/macros/s/AKfycbwcQDHyIxerSVp_4uhuDKxKsD-VGuAsbTHLYbjn6J6zeQKehUNw/exec';
    const url = 'https://script.google.com/a/veolia.com/macros/s/AKfycbwNN79YHCTGT7WmVj5azt4IvaJarzf_zBWxKXCB6w8MDvnKaTai/exec';
    const params = '?email=' + this.sessionService.currentUserValue.email + '&url=' + urlForms;
    return this.http.jsonp(url + params, 'callback');

  }

  // script start when add / edit formulaire
  getPublishedUrlFromScript(urlForms: string): Observable<any> {
     const url = 'https://script.google.com/a/macros/veolia.com/s/AKfycbxShDKuwJaC-rf_31pEoChvkzL-oRWzHoWUzD1vGUX_zxq8Kt6mjep4/exec';
     const params = '?email=' + this.sessionService.currentUserValue.email + '&url=' + urlForms;
     return this.http.jsonp(url + params, 'callback');
  }

  beforeSave(objet: DemandeModel): boolean {
    let success = true;
    if (!VerifyFields.verifyStringExist(objet.objet)) {
      success = false;
    }
    if (!VerifyFields.verifyStringExist(objet.statut)) {
      success = false;
    }
    if (!VerifyFields.verifyDate(objet.dateIntervention)) {
      success = false;
    }
    if (!VerifyFields.verifyId(objet.commercialId)) {
      success = false;
    }
    if (objet.typeDemandeId==1000 ) { //If demande urgente
      if (!VerifyFields.verifyId(objet.gestionnaireId)) {//Verifier si gestionnaire rempli
        success = false;
      }
    }
    if (!VerifyFields.verifyId(objet.typeDemandeId)) {
      success = false;
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    if (!success) {
      if (objet.id) {
        this.IntercomToastrService.warning('L\'objet, la date d\'échéance, le type, le réalisateur'+ (objet.typeDemandeId==1000 ?', le gestionnaire':'')+' et le statut sont obligatoires', 'Impossible de modifier la demande');
      } else {
        this.IntercomToastrService.warning('L\'objet, la date d\'échéance, le type, le réalisateur'+ (objet.typeDemandeId==1000 ?', le gestionnaire':'')+' et le statut sont obligatoires', 'Impossible de créer la demande');
      }
    }
    if (objet.actions && !VerifyFields.verifyStatut(objet)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez clore les tâches de la demande avant.', 'Impossible de clôturer la demande');
    }
    return success;
  }
  
  UpdateInfoJason(infoJasonDTO: InfoJasonDTO, idDemande) : Observable<DemandeModel> {
    infoJasonDTO.id = idDemande;
    return this.http.put<DemandeModel>(this.apiUrl + this.serviceUrl + 'UpdateInfoJason/' + infoJasonDTO.id, infoJasonDTO);
  }

  exportDemandes(filtre: FiltreDemandeDTO) {
    return this.http.post(this.apiUrl + this.serviceUrl + 'exportDemandes', filtre, {responseType: 'text'});
  }

  sendDocument(filename: string, typeId: number, file: File): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.documentService.sendDoc(filename, typeId, "DemandeS3", file).pipe(filter((doc: HttpEvent<unknown>) => {
      return doc.type === HttpEventType.Response;
    }),
      switchMap((doc: HttpEvent<unknown>) => {
        const document = new DocumentModel();
        document.libelle = filename;
        document.typeId = typeId;
        document.type = "DemandeS3";
        return this.documentService.save(document);
      })).subscribe(sub => {
        avancement.next(sub.id);
      }, error => {
        avancement.next(null);
      });
    return avancement.asObservable();
  }

  getDocumentById(idDoc: number): Observable<Blob> {
    return this.documentService.getDoc(idDoc);
  }

  deleteDocument(idDoc: number): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.documentService.delete(idDoc).subscribe(sub => {
      avancement.next(sub.id);
    });
    return avancement.asObservable();
  }
}