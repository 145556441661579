import { TypeDroit } from './typeDroits';
export class StatutCorrespondance {
    public color: string;
    public libelle: string;
}

export class StatusList {

    public static ATRAITER = 'À faire';
    public static NONLU = 'Non lu';
    public static ENRETARD = 'En retard';
    public static ENCOURS = 'En cours';
    public static CLOS = 'Clos';
    public static status: StatutCorrespondance[] = [
        {libelle: 'A faire', color: '#d50000'},
        {libelle: 'Non lu', color: '#039be5'},
        {libelle: 'En retard', color: '#33b679'},
        {libelle: 'En cours', color: '#33b679'},
        {libelle: 'Clos', color: '#33b679'},
    ];
}
