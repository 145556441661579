
  <!-- Titre -->
  <div class="col-md-12">
    <h4>{{data.title}}</h4>    
</div>

<div >
 <!-- Séparateur -->
  <div class="col-md-12">
    <mat-divider></mat-divider>
  </div>

    <!-- Champs -->
  <div class="col-md-12" >
      <mat-form-field class="full-width">
        <mat-label>{{data.motif}}</mat-label>
        <mat-select [(ngModel)]="raison" required>           
          <mat-option *ngFor="let motif of data.list" value="{{motif}}">{{motif}}</mat-option>         
        </mat-select>
      </mat-form-field>
  </div>  
</div>
<!-- Boutons -->
<div class="col-sm-12 row" style="display: flex; justify-content: space-between;">
    <div class="col-sm-6 text-center" style="margin-top: 24px;">
      <button mat-flat-button color="warn" (click)="onModalConfirm()">Oui</button>
    </div>
    <div class="col-sm-6 text-center" style="margin-top: 24px;">
      <button mat-flat-button color="default" (click)="onModalCancel()">Non</button> 
    </div>
</div>
