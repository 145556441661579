<div>
  <div *ngIf="!error && !fatalError">
      <h4 class="card-title">Connexion en cours ...</h4>
  </div>
</div>
<br/>
<br/>

<div class="alert alert-danger" *ngIf="error">
  <strong>Connexion refusée. Vous n'avez pas de compte ou il a été désactivé.</strong>
  <div row>
    <button class="btn btn-danger" (click)="signOut()">Retour</button>
  </div>
</div>

<div class="alert alert-danger" *ngIf="fatalError">
    <p>
      <strong>Connexion impossible une erreur serveur est peut-être à l'origine de ce problème. 
      Contactez le service informatique pour la correction de cette erreur.
      </strong>
    </p>
    <div>
      <button class="btn btn-danger" (click)="signOut()">Retour</button>
    </div>
  </div>
