import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(required: boolean, validator: Validators) {
    this.disabled = required;
    this.validators = validator;
  }
}

export const PRESTATIONVALIDATORS = {
    CREATE: new Map<string, FormInfo>([
        ['id', new FormInfo(false, [])],
        ['libelle', new FormInfo(false, [Validators.required])],
        ['idActivite', new FormInfo(false, [Validators.required])],
        ['idAgence', new FormInfo(false, [Validators.required])]
    ]),
    EDIT: new Map<string, FormInfo>([
        ['id', new FormInfo(false, [Validators.required])],
        ['libelle', new FormInfo(false, [Validators.required])],
        ['idActivite', new FormInfo(false, [Validators.required])],
        ['idAgence', new FormInfo(false, [Validators.required])]
    ])
}