import { SessionService } from '../../../shared/session.service';
import { Component, Input, OnChanges, Output, EventEmitter, Injector } from '@angular/core';
import { ModalAjoutDemandeComponent } from './modal-ajout-demande.component';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DemandeModel } from '../../shared/models/demande.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { ModalsService } from 'src/app/modules/shared/modals.service';

export interface DialogData {

  result: boolean;
}

@Component({
  selector: 'app-demande',
  templateUrl: './demande.component.html',
  styleUrls: ['./demande.component.scss']
})
export class DemandeComponent implements OnChanges {
  @Output() onUpdated = new EventEmitter<boolean>();
  private standardWidth = '90%';
  private standardHeight = '99vh';

  demandes: DemandeModel[] = [];
  panelOpenState = false;
  panelOpenStates = false;

  @Input() tier: TierModel;
  result: boolean;
  demandeId: number;
  private modalsService: ModalsService;

  constructor(private router: Router,
    public dialog: MatDialog,
    private sessionService: SessionService,
    injector: Injector
  ) {
    this.modalsService = injector.get('ModalsService');
  }

  ngOnChanges() {
  }

  demandeUpdated(demandes: DemandeModel[]) {
    this.demandes = Object.assign([], demandes);
    this.onUpdated.emit(true);
  }

  onAddDemande(): void {
    let commercialId = this.tier.commercialId;
    if (commercialId == null) {
      commercialId = this.sessionService.currentUserValue.id;
    }
    const demande = new DemandeModel();
    demande.tier = Object.create(this.tier);
    const dialogRef = this.dialog.open(ModalAjoutDemandeComponent, {
      disableClose: true,
        width: this.standardWidth,
        maxHeight: this.standardHeight,
      data: {
        tierFactureId : this.tier.tierFactureId,
        tierId: this.tier.id,
        realisateurId: commercialId,
        result: this.result,
        demande: demande
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.result = data.result;
      this.onUpdated.emit(true);
      if (this.result) {
        this.modalsService.openDemande(data.demande).subscribe( result => {
          this.result = result;
          this.onUpdated.emit(true);
        });
      }
    });
  }
}

