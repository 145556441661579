<div class="col-sm-12">
  <div class="col-sm-12 text-center">
    <h2>Paramètres utilisateur</h2>
  </div>
</div>

<div [className]="displayForm ? 'col-sm-8' : 'col-sm-12'">
  <mat-card
    class="col-sm-6"
    class="example-card"
    style="margin: 20px; margin-top: 0px"
  >
    <div class="col-sm-12">
      <mat-card-content>
        <div class="col-sm-6">
          <h4>Thème</h4>

          <section class="example-section">
            <mat-slide-toggle
              class="example-margin"
              [color]="color"
              [checked]="light"
              (click)="toggleTheme()">
              Thème light activé
            </mat-slide-toggle>
          </section>
        </div>

        <div class="col-sm-3">
          <h4>Ring central</h4>
          <section class="example-section">
            <mat-slide-toggle class="example-margin" [color]="color" [checked]="checked" (change)="onChecked()"> Activé 
            </mat-slide-toggle>
          </section>
        </div>
        <div class="col-sm-3">
          <h4>Ring centralCX</h4>
          <section class="example-section">
            <mat-slide-toggle class="example-margin" [color]="color" [checked]="checkedCX" (change)="onCheckedCX()"> Activé 
            </mat-slide-toggle>
          </section>
        </div>
      </mat-card-content>
    </div>
    <div class="etiquetteDiv"> <!-- Laissé pour mise en forme-->
    </div>
  </mat-card>
</div>
<div *ngIf="displayForm" [className]="displayForm ? 'col-sm-4' : ''">
  <mat-card class="example-card" style="margin-top: 0px">
    <!-- Call forms to add etiquettes in list etiquettes array -->
    <form>
      <div class="col-sm-12" style="margin-bottom: 15px !important">
        <h5 class="titleSpacingLeft">Liste</h5>
        <div class="form-group col-sm-7">
          <input  [(ngModel)]="userEtiquette.libelle"   type="text" class="form-control"  name="nom" placeholder="Nom de la liste" required/>
        </div>
        <div class="form-group col-sm-3">
          <input type="color" class="form-control" name="color"  placeholder="Couleur de l'étiquette" [(ngModel)]="userEtiquette.color">
        </div>
        <div class="form-group col-sm-2">
          <button mat-flat-button color="warn" class="etiquetteDeleteButton" (click)="removeUserEtiquette()">  <mat-icon>delete</mat-icon> </button>
        </div>
        <div class="form-group col-sm-7">
          <mat-form-field appearance="fill">
            <mat-label>Activité</mat-label>
            <mat-select [(ngModel)]="userEtiquette.prestationId" [ngModelOptions]="{standalone: true}">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let prestation of prestations" [value]="prestation.id">
                {{prestation.libelle}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="etiquetteDivClearBoth"></div>
      <mat-divider></mat-divider>

      <div class="col-sm-12" style="margin-top: 25px !important">
        <h5 class="titleSpacingLeft">Étiquettes associées</h5>
      </div>  

      <div class="etiquettesAssoc" *ngFor="let etiquette of etiquettes; let i = index">
        <div class="form-group col-sm-7">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="etiquette.libelle"
            name="etiquette{{i}}"
            placeholder="Nom de l'étiquette"
          />
        </div>
        <div class="form-group col-sm-3" >
          <input type="color" class="form-control" name="etiquettecolor{{i}}"  placeholder="Couleur de l'étiquette" [(ngModel)]="etiquette.color ">
        </div>

         <div class="form-group col-sm-2">
          <button mat-flat-button color="warn" class="etiquetteDeleteButton" (click)="removeEtiquette(i)">  <mat-icon>delete</mat-icon> </button>
        </div>
        
      </div>

      <div class="etiquetteDivClearBoth"></div>

      <button mat-stroked-button color="success" class="etiquetteAddButton" (click)="addEtiquette()">  <mat-icon>add</mat-icon> </button>
      <div style="text-align: right !important">
       
        <button mat-flat-button class="etiquetteAddButton cancelButton" (click)="MasquerFormulaire()">
          Annuler
        </button>
        <button mat-flat-button color="success" class="etiquetteAddButton" (click)="CreerUserEtiquette()">
          Enregistrer
        </button>
      </div>
    </form>
  </mat-card>
</div>
