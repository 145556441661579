import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SessionService } from 'src/app/modules/shared/session.service';
import { DevisDTO } from '../../DTOs/devisDTO';
import { DocumentService } from '../../services/document.service';
import { PdfService } from '../../../shared/pdf-service';
import { DevisService } from '../../services/devis.service';


@Component({
  selector: 'app-pdf-devis',
  templateUrl: './pdf-devis.component.html',
  styleUrls: ['./pdf-devis.component.scss'],
  providers: [DatePipe]
})
export class PdfDevisComponent implements OnChanges {

  @Input() devis: DevisDTO;
  @Input() memorize: void;
  @Input() generate: boolean;
  @Output() generatedFile: File;

  emetteur: string;
  valideur: string;
  enabled = true;
  isDevisSigned = false;
  isTvaSigned = false;
  devisSaved = false;

  constructor(
    private datepipe: DatePipe,
    private sessionService: SessionService,
    private devisService: DevisService,
    private documentService: DocumentService,
    private pdfService: PdfService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.devis) {
      this.sessionService.currentUser.subscribe(user => {
        this.emetteur = user.nom + ' ' + user.prenom;
      });
      this.valideur = this.devis.nomInterlocuteur ? this.devis.prenomInterlocuteur ? this.devis.nomInterlocuteur + ' ' + this.devis.prenomInterlocuteur : this.devis.nomInterlocuteur : '';   
      if(this.devis.idDevis){
        this.documentService.getFromDevis(this.devis.idDevis).subscribe((res) => {
          if(res.length > 0){
            // create devis 
            for (let docs of res) {
              if (docs.libelle === "Devis Signé") {
                this.isDevisSigned = true;
              }
              if (docs.libelle === "TVA Signé") {
                this.isTvaSigned = true;
              }
              if (docs.libelle === "Devis") {
                this.devisSaved = true;
              }
            }
          }
        });
      }
    }
  }

  openDoc(filename: string) {
    this.documentService.getDocDevis(this.devis.idDevis, filename).subscribe(url => {
      window.open(url, filename + ' - ' + this.devis.code);
    });
  }

  private formatDate(date: Date, h?: boolean, now?: boolean): string {
    return now ? (h ? this.datepipe.transform(date, 'dd/MM/yyyy HH:mm') : this.datepipe.transform(date, 'dd/MM/yyyy')) : (h ? this.datepipe.transform(date, 'dd/MM/yyyy HH:mm', 'UTC') : this.datepipe.transform(date, 'dd/MM/yyyy', 'UTC'));
  }

  async generatepdf(){
    this.pdfService.printDevisRapide(this.devis);
  }

  /*page1(doc: jsPDF, logo: string){
    var activite = this.devis.url;
    var certif1 = this.devis.certification1;
    var certif2 = this.devis.certification2;
    var height = 0;
    logo ? doc.addImage(logo, 'JPEG', 0, 0, 100 , 35) : '';
    autoTable(doc, {
      head: [[
        {
          content: 'Affaire suivie par : ',
        }
      ]],
      body: [
        [
          {
            content: 
            ((this.devis.nomRealisateur && this.devis.prenomRealisateur) ? (this.devis.nomRealisateur + ' ' + this.devis.prenomRealisateur + '\n'): '\n' ) +
            (this.devis.emailRealisateur ? ('Email : ' + this.devis.emailRealisateur + '\n') : '\n' )+
            (this.devis.telRealisateur ? ('Tél : ' + this.devis.telRealisateur + '\n') : '\n'),
          }
        ],
      ],
      theme: 'plain',
      styles: {
        halign: 'right',
        fontSize: 8,
        cellPadding: 0
      },
      didDrawPage: (data) => {
        height += this.getHeight(data.table.allRows()); 
      }
    });

    autoTable(doc, {
      body: [[
        {
          content:
            'A l\'attention du ' + (this.devis.nomInterlocuteur ? this.devis.nomInterlocuteur + ' ' + (this.devis.prenomInterlocuteur ? this.devis.prenomInterlocuteur : '' ) + '\n' : '\n') +
            (this.devis.emailInterlocuteur ? this.devis.emailInterlocuteur + '\n' : '\n') +
            (this.devis.telInterlocuteur ? this.devis.telInterlocuteur + '\n' : '\n'),
        }
      ]],
      theme: "plain",
      styles: {
        halign: 'left',
        fontSize: 8,
        cellPadding: 0
      },
      margin:{
        top: 0,
        bottom: 0
      },
      didDrawPage: (data) => {
        height += this.getHeight(data.table.allRows());
      }
    });
   
    autoTable(doc, {
      head: [[
        {
          content: 'Adresse de l\'intervention ',
        },
        {
          content: 'Adresse de facturation ',
        }
      ]],
      body: [
        [
          {
            content: // tiers
            (this.devis.raisonSociale ? (this.devis.raisonSociale + '\n') : '\n' ) +
            (this.devis.adresseChantier ? (this.devis.adresseChantier + '\n'): '\n' )+
            (this.devis.codePostalChantier ? (this.devis.codePostalChantier + ' ') : '' )+
            (this.devis.villeChantier ? (this.devis.villeChantier + '\n'): '\n'),
          },
          {
            content: // tiers facturé
            (this.devis.raisonSociale ? (this.devis.raisonSociale + '\n') : '\n') +
            (this.devis.adresse ? (this.devis.adresse + '\n'): '\n') +
            (this.devis.codePostal ? (this.devis.codePostal + ' '): '')+
            (this.devis.ville ? (this.devis.ville + '\n'): '\n' ),
          }
        ],
      ],
      theme: 'plain',
      styles: {
        halign: 'left',
        fontSize: 8,
        cellPadding: 0
      },
      margin:{
        top: 0,
        bottom: 0
      },
      didDrawPage: (data) => {
        height += this.getHeight(data.table.allRows());
      }
    });

    autoTable(doc, {
      body: 
        [[
          {
            content: 'Devis N° ' + (this.devis.code ? this.devis.code : ''),
            styles: {
              halign: 'left'
            }
          },
          {
            content: 'Fait à  ' + (this.devis.agenceVille ? this.devis.agenceVille : '') +
            ', Le : ' + (this.devis.dModification ? this.formatDate(this.devis.dModification, false , true): this.formatDate(new Date, false , true)) ,
            styles: {
              halign: 'right'
            }
          }
        ]],
      theme: 'plain',
      styles: {
        fontSize: 8,
        cellPadding: 0
      },
      margin:{
        top: 0,
        bottom: 0
      },
      didDrawPage: (data) => {
        height += this.getHeight(data.table.allRows());
      }
    });


    autoTable(doc,{
      body: 
        [
          [
            {
              content: (this.devis.objet ? this.devis.objet : ''),
              styles: {
                fontSize: 10
              }
            },
          ],
          [
            {
              content: this.devis.introduction ? this.devis.introduction : '',
              styles: {
                fontSize: 8
              }
            }
          ]
        ],
      theme: "plain",
      styles: {
        halign: 'center',
        cellPadding: 0
      },
      margin:{
        top: 0,
        bottom: 0
      },
      didDrawPage: (data) => {
        height += this.getHeight(data.table.allRows());
        activite ? doc.addImage(activite, 'JPEG', 10, 2 * height, 190, 100): '';
      }
    });

    autoTable(doc, {
      body: [
        [
          {
            content: 
              (this.devis.agenceLibelle ? (this.devis.agenceLibelle + '\n') : '\n') +
              (this.devis.agenceAdresse ? (this.devis.agenceAdresse+ '\n'): '\n')+
              (this.devis.agenceCodePostal ? (this.devis.agenceCodePostal+ ' ') :'')+
              (this.devis.agenceVille ? (this.devis.agenceVille + '\n') : '\n')+
              ('Tél : ' + (this.devis.agenceTelephone ? (this.devis.agenceTelephone + '\n') : '\n')) +
              'RCS '+ (this.devis.rcs? this.devis.rcs: '') + ' ' + (this.devis.siretAgence? this.devis.siretAgence +'\n' : '\n') +
              'Email : ' +(this.devis.agenceEmail ? (this.devis.agenceEmail + '\n') : '\n'),
            styles: {
              fontSize: 8
            }
          }
        ],
        [
          {
            content: 
              this.devis.libelleSociete + ' - Siége social : ' +
              (this.devis.agenceAdresse ? (this.devis.agenceAdresse+ ' - '): '')+
              (this.devis.agenceCodePostal ? (this.devis.agenceCodePostal+ ' ') :'')+
              (this.devis.agenceVille ? (this.devis.agenceVille + ' - ') : '')+
              ('Tél : ' + (this.devis.agenceTelephone ? (this.devis.agenceTelephone + ' - ') : '')) +
              'RCS '+ (this.devis.rcs? this.devis.rcs: '') + ' ' + (this.devis.siretAgence? this.devis.siretAgence : '') +  ' - ' + // a ajouter dans agence
              'NAF ' +(this.devis.nafAgence ? (this.devis.nafAgence + '') : ''),
            styles: {
              fontSize: 6
            }
          }
        ]
      ],
      theme: "plain",
      styles: {
        halign: 'left',
        cellPadding: 0,
        valign: 'bottom',
        
      },
      margin:{
        top: 0,
        bottom: 0
      },
      startY: 260,
      didDrawPage: (data) => {
        certif1 ? doc.addImage(certif1, 'JPEG', 95, 260, 50, 20) : '';
        certif2 ? doc.addImage(certif2, 'PNG', 170, 260, 25, 20) : '';
      }
    });
  }

  numberLigne = 0;

  public page2(doc: jsPDF, logo: string) {
    var height = 0;
    doc.addPage();
    logo ? doc.addImage(logo, 'JPEG', 0, 0, 100 , 35) : '';
    autoTable(doc, {
      body: [
        [
          {
            content: 
              'Devis n° ' + this.devis.code + ' - ' +
              (this.devis.objet.length > 15 ? '\n' + this.devis.objet : this.devis.objet) + ' - \n'
              + (this.devis.dModification ? this.formatDate(this.devis.dModification, false , true): this.formatDate(new Date, false , true)) ,
          },
        ],
      ],
      theme: 'plain',
      styles: {
        halign: 'right',
        cellPadding: 0,
        fontSize: 8,
      },
      didDrawPage: (data) => {
        height += this.getHeight(data.table.allRows());
      }
    });

    if(doc.getCurrentPageInfo().pageNumber === 2) {
      if(this.devis.descriptionOuvrage) {

        autoTable(doc, {
          head: [['DESCRIPTIF DES OUVRAGES :']],
          body: [
            [
              {
                content: this.devis.descriptionOuvrage ? this.devis.descriptionOuvrage : '' ,
              }
            ],
          ],
          theme: 'plain',
          styles: {
            halign: 'left',
            cellPadding: 0,
            fontSize: 8,
          },
          margin:{
            top: 0,
            bottom: 0
          },
          didDrawPage: (data) => {
            height += this.getHeight(data.table.allRows());
          }
        });
      }

      autoTable(doc, {
        head: [['MODALITES D\'INTERVENTION :']],
        body: [
          [
            {
              content: this.devis.modalite ? this.devis.modalite : '',
            }
          ],
        ],
        theme: 'plain',
        styles: {
          halign: 'left',
          cellPadding: 0,
          fontSize: 8,
        },
        margin:{
          top: 0,
          bottom: 0
        },
        didDrawPage: (data) => {
          height += this.getHeight(data.table.allRows());
        }
      });
  
      autoTable(doc, {
        head:[['A LA CHARGE DU CLIENT :']],
        body: [
          [
            {
              content: this.devis.chargeClient ? this.devis.chargeClient : '',
            }
          ],
        ],
        theme: 'plain',
        styles: {
          halign: 'left',
          cellPadding: 0,
          fontSize: 8,
        },
        margin:{
          top: 0,
          bottom: 0
        },
        didDrawPage: (data) => {
          height += this.getHeight(data.table.allRows());
        }
      });

      if(this.devis.demandeClient) {
        autoTable(doc, {
          head:[['A LA DEMANDE DU CLIENT/CHANTIER :']],
          body: [
            [
              {
                content: this.devis.demandeClient ? this.devis.demandeClient : '',
              }
            ],
          ],
          theme: 'plain',
          styles: {
            halign: 'left',
            cellPadding: 0,
            fontSize: 8,
          },
          margin:{
            top: 0,
            bottom: 0
          },
          didDrawPage: (data) => {
            height += this.getHeight(data.table.allRows());
          }
        });
      }

      var nbLignes = (297 - (2 * height)) / 8;
    
      autoTable(doc, {
        head:[['COUT DES PRESTATIONS :', '', '', '', ''], 
        [
          {
            content: 'Libellé de l\'offre',
            styles: {
              halign:'left',
            }
          },
          {
            content: 'Prix',
            styles: {
              halign:'right',
            }
          },
          {
            content: 'Quantité',
            styles: {
              halign:'right',
            }
          } ,
          {
            content: 'Unité',
            styles: {
              halign:'right',
            }
          },
          {
            content: 'Prix total',
            styles: {
              halign:'right',
            }
          }]],
        body: (this.devis.prestationsClient && this.devis.prestationsClient.length > 0) ? this.transformsRow(this.numberLigne, this.devis.prestationsClient[0].lignes.length < nbLignes ? this.devis.prestationsClient[0].lignes.length : nbLignes) : [[]],
        theme: 'plain',
        styles: {
          halign: 'left',
          cellPadding: 0,
          fontSize: 8,
        },
        margin:{
          top: 0,
          bottom: 0
        }
      });
      this.numberLigne = this.devis.prestationsClient[0].lignes.length < nbLignes && this.numberLigne === 0 ? this.devis.prestationsClient[0].lignes.length : Math.floor(nbLignes);
    }

    if(this.devis.prestationsClient[0].lignes.length > nbLignes && doc.getCurrentPageInfo().pageNumber !== 2) {
      this.numberLigne = 6;
      autoTable(doc, {
        head:[['COUT DES PRESTATIONS :', '', '', '', ''], 
        [{
          content: 'Libellé de l\'offre',
          styles: {
            halign:'left',
          }
        },
        {
          content: 'Prix',
          styles: {
            halign:'right',
          }
        },
        {
          content: 'Quantité',
          styles: {
            halign:'right',
          }
        } ,
        {
          content: 'Unité',
          styles: {
            halign:'right',
          }
        },
        {
          content: 'Prix total',
          styles: {
            halign:'right',
          }
        }]],
        body: (this.devis.prestationsClient && this.devis.prestationsClient.length > 0) ? this.transformsRow(this.numberLigne,this.devis.prestationsClient[0].lignes.length ) : [[]],
        theme: 'plain',
        styles: {
          halign: 'left',
          cellPadding: 0,
          fontSize: 8,
        },
        margin:{
          top: 0,
          bottom: 0
        }
      });

      this.numberLigne = this.devis.prestationsClient[0].lignes.length;
    }

    if(this.devis.prestationsClient[0].lignes.length === Math.floor(this.numberLigne)) {
      autoTable(doc, {
        body: [
          [
            {
              content: 'MONTANT TOTAL H.T.',
              styles:{
                halign:'left',
                fillColor: '#4169E1',
                textColor : '#FFFFFF',
                fontSize: 8
              }
            },
            {
              content: (this.devis.prestationsClient ? this.devis.prestationsClient[0].ht : 0)  + '€',
              styles:{
                halign:'right',
                fillColor: '#4169E1',
                textColor : '#FFFFFF',
                fontSize: 8
              }
            },
          ],
          [
            {
              content: 'T.V.A. ' + (this.devis.prestationsClient ? this.devis.prestationsClient[0].tva : 20) + '%',
              styles:{
                halign:'left',
                fillColor: '#87CEFA',
                textColor: '#000000',
                fontSize: 8
              }
            },
            {
              content: (this.devis.prestationsClient ? this.devis.prestationsClient[0].ttc - this.devis.prestationsClient[0].ht : 0)  + '€',
              styles:{
                halign:'right',
                fillColor: '#87CEFA',
                textColor: '#000000',
                fontSize: 8
              }
            },
          ],
          [
            {
              content: 'MONTANT TOTAL T.T.C \n'+ 
                'Conditions de réglements : ' + (this.devis.prestationsClient && this.devis.prestationsClient[0].condRegl ? this.devis.prestationsClient[0].condRegl + '\n' : '\n') +
                'Validité de l\'offre : 2 mois',
              styles:{
                halign:'left',
                fillColor:'#191970',
                textColor : '#FFFFFF',
                fontSize: 8
              }
            },
            {
              content:  (this.devis.prestationsClient ? this.devis.prestationsClient[0].ttc : 0) + '€' ,
              styles:{
                halign:'right',
                fillColor: '#191970',
                textColor : '#FFFFFF',
                fontSize: 8
              }
            },
          ],
          [
            {
              content: 'Si vous remplissez les conditions exigés par les Services Fiscaux, il est impératif que vous nous retourniez l\'attestation jointe, laquelle nous permettra d\'appliquer un taux de TVA à 10%. A défaut le taux de TVA appliqué sera de 20%' ,
              styles:{
                halign:'left',
                fontSize: 6
              }
            },
          ]
        ],
        theme: 'striped',
        styles: {
          cellPadding: 0,
        },
        margin:{
          top: 0,
          bottom: 0
        }
      });
      
      
      var checkBox = new AcroFormCheckBox();
      checkBox.fieldName = "CheckBox1";
      checkBox.x = 25;
      checkBox.y = 235;
      checkBox.height = 5;
      checkBox.width = 5;
      doc.addField(checkBox);

      autoTable(doc, {
        body: [[
          {
            content: 'Je déclare avoir pris connaissance et accepté les termes et les conditions générales de vente figurant sur chaque face du document',
          },
  
        ]],
        theme: 'plain',
        styles: {
          halign: 'right',
          cellPadding: 0,
          fontSize: 8
        },
        margin:{
          top: 0,
          bottom: 0
        },
        startY: 235
      });
  
      autoTable(doc, {
        head:[[
          {
            content: 'Le Client,',
          },
          {
            content: 'Pour l\'entreprise,'
            +'\nChristophe BELLIN',
          },
          
        ]],
        body: [
          [
            {
              content:'Signature précédée de la mention'
              +'\n << Bon pour accord, Lu et approuvé >> \n\n\n',
            },
            {
              content:'Directeur d\'agence \n\n\n',
            }
          ],
        ],
        theme: 'plain',
        styles: {
          halign: 'center',
          cellPadding: 0,
          fontSize: 8
        },
        margin:{
          top: 0,
          bottom: 0
        },
        startY: 240
      });
    }


    autoTable(doc, {
      body: [
        [
          {
            content: 'Nos conditions générales de Vente sont consultable sur notre site www.sarp-assainissement.fr',
            styles: {
              fontSize: 8
            }
          },
        ],
        [
          {
            content: this.devis.sloganAgence ? this.devis.sloganAgence :'',
            styles: {
              fontSize: 16,
              fillColor: '#6495ED',
              textColor : '#FFFFFF'
            }
          },
        ],
      ],
      theme: "plain",
      styles: {
        halign: 'center',
        cellPadding: 0,
      },
      margin:{
        top: 0,
        bottom: 0
      },
      startY: 270
    });

    if(this.devis.prestationsClient[0].lignes.length !== Math.floor(this.numberLigne)) {
      return this.page2(doc, logo);
    }

  }

  public getHeight(rows: Row[]) {
    var height = 0;
    rows.forEach((row) => {
      height += row.height;
    });
    return height;
  }

  public transformsRow(start: number, end: number) {
    var table = [];
    var lignes = this.devis.prestationsClient[0].lignes;
    for(var i = start; i < end; i++) {
      var prestations = [];
      prestations.push({
        content: lignes[i].libelle,
        styles: {
          halign:'left',
        }
      });
      prestations.push({
        content: lignes[i].prix + '€',
        styles: {
          halign:'right',
        }
      });
      prestations.push({
        content: lignes[i].qte,
        styles: {
          halign:'right',
        }
      });
      prestations.push({
        content: lignes[i].unite ? lignes[i].unite : '',
        styles: {
          halign:'right',
        }
      });
      prestations.push({
        content: lignes[i]. prix * lignes[i].qte + '€',
        styles: {
          halign:'right',
        }
      });
      table.push(prestations)
    }
    return table;
  }*/
}
