import { TierModel } from './tier.model';
import { CiviliteModel } from '../civilite.model';

export class ParticulierModel extends TierModel {
  public proprietaire: boolean;
  public civiliteId = '';
  public civilite?: CiviliteModel;
  public nom = '';
  public prenom = '';
  public typeTier = 'Particulier';


  constructor(tiers?: TierModel) {
    super(tiers);
    if (tiers && tiers.libelle) {
      const libelleSplit = typeof(tiers.libelle) !== 'undefined' ? tiers.libelle.split(' ') : undefined;
      if (libelleSplit && libelleSplit.length > 0) {
        this.nom = libelleSplit[0];
        if ( libelleSplit.length > 1) {
          this.prenom = libelleSplit[1];
        }
      }
    }
  }
}
