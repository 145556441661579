import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

@Component({
  selector: 'app-resume-tier',
  templateUrl: './resume.tier.component.html',
  styleUrls: ['./resume.tier.component.scss']
})
export class ResumeTierComponent  {

  constructor(private router: Router) { }
  @Input() tier: TierModel;
  @Output() tierOpen = new EventEmitter();
  
  onOpenTier() {
    this.tierOpen.emit();
  }
}
