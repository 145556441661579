export class EtatUrgencePxo {
  public static ETATURGENCE:{id: number, libelle: string}[] = [
    {id: 0, libelle: 'À envoyer'},
    {id: 1, libelle: 'Envoyée'},
    {id: 2, libelle: 'Planifiée'},
    {id: 3, libelle: 'Sychronisée'},
    {id: 4, libelle: 'Démarrée'},
    {id: 5, libelle: 'Réalisée'},
    {id: 6, libelle: 'Validée'},
    {id: 1000, libelle: 'Annulée'}
];
  public static TOSEND = 0;
  public static SENT = 1;
  public static PLANIF = 2;
  public static SYNCHRO = 3;
  public static DEMARRE = 4;
  public static REALISE = 5;
  public static VALIDE = 6;
  public static ANNULEINDEX = 1000;
}