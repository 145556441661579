import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DemandeComponent } from 'src/app/modules/core/components/demande/demande.component';
import { ModalSelectionTiersFactureComponent } from 'src/app/modules/core/components/demande/modal-selection-tiers-facture.component';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tiers-card',
  templateUrl: './tiers-card.component.html'
})
export class TiersCardComponent implements OnChanges {

  @Input() tier: TierModel = new TierModel();
  @Input() libelle: string;
  @Input() modifiable: boolean = false;
  @Output() selectedTiers = new EventEmitter<TierModel>();

  result: boolean;

  constructor(public dialog: MatDialog,
              private router: Router,
              public dialogRef: MatDialogRef<DemandeComponent>) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.tier){
      this.tier = new TierModel();
    }
  }

  onEditTiersFacture(){
    const dialogRef = this.dialog.open(ModalSelectionTiersFactureComponent, {
      disableClose: true,
      panelClass: 'tiers-facture-dialog',
      height:"95%",
      data :{
        tier : this.tier,
        result : this.result,
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.result) {
        this.tier = data.tier;      
        this.selectedTiers.emit(this.tier);
      }
    });
  }

  onTierOpen() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/fiche-contact", this.tier.id])
    );
    window.open(url, '_blank'); 
  }

}
