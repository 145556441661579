import { BasicMock } from '../basic-mock/basic.mock';
import { TierModel } from '../../models/tier/tier.model';
import { Injectable } from "@angular/core";

@Injectable()
export class TierMock extends BasicMock  {
  constructor() {
    super();
    (<TierModel[]> this.objets) = [
      /*{ id: 1,
        clientNumber: 'C00191727', commercialId: 1, libelle: 'SARL BEST AUTOS 53',
        agenceId: 1, identifiantJason: '221809',
         natureId: 3, typeTier: 'Entreprise',
         numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      { id: 2,
        clientNumber: 'C00048758', commercialId: 2, libelle: 'SNC BRASSERIE LORRAINE LGE',
        agenceId: 1, identifiantJason: '21716',
        natureId: 2, typeTier: 'Entreprise',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      { id: 3,
        clientNumber: 'C00191725', commercialId: 1, libelle: 'LE BEAUGENCY',
        agenceId: 1, identifiantJason: '221808',
        natureId: 4, typeTier: 'Entreprise',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      { id: 4,
        clientNumber: 'C00191724', commercialId: 1, libelle: 'SCI BMB',
        agenceId: 1, identifiantJason: '221807',
       natureId: 3, typeTier: 'Entreprise',
       numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },

      {
        id: 5,
        clientNumber: 'C0015782004', commercialId: 1,
        agenceId: 1, identifiantJason: 'idJason4', libelle: 'ville4',
        natureId: 1, typeTier: 'Mairie',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 6,
        clientNumber: 'C0015782005', commercialId: 2,
        agenceId: 1, identifiantJason: 'idJason5',
        natureId: 1, typeTier: 'Mairie', libelle: 'Paris 5',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 7,
        clientNumber: 'C0015782006', commercialId: 1,
        agenceId: 1,  identifiantJason: 'idJason6',
        natureId: 3, typeTier: 'Mairie', libelle: 'ville6',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 8,
        clientNumber: 'C0015782007', commercialId: 1,
        agenceId: 1,  identifiantJason: 'idJason7',
        natureId: 1, typeTier: 'Mairie', libelle: 'ville7',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 9,
        clientNumber: 'C00191747', commercialId: 1,
        agenceId: 1, identifiantJason: '221827',
        natureId: 5, typeTier: 'Particulier', libelle: 'Mr STEFANI Cedric',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 10,
        clientNumber: 'C00191721', commercialId: 2,
        agenceId: 1, identifiantJason: '221806',
        natureId: 5, typeTier: 'Particulier', libelle: 'Mr BONICHON JEAN-JACQUES',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 11,
        clientNumber: 'C00191717', commercialId: 1,
        agenceId: 1, identifiantJason: '221803',
        natureId: 5, typeTier: 'Particulier', libelle: 'Mr ROBIN JEROME',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 12,
        clientNumber: 'C00191716', commercialId: 1,
        agenceId: 1, identifiantJason: '221802',
        natureId: 5, typeTier: 'Particulier', libelle: 'Mme MIERMONT CHRISTINE',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      }*/


    ];
  }

  getFromObjet(objRecherche: TierModel): TierModel[] {
    return Object.assign([], this.objets.filter((objTab: TierModel) => this.sameTier(objTab, objRecherche)));
  }

  sameTier(objTab: TierModel, objRecherche: TierModel): boolean {
    if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
        return false;
    } else if (objRecherche.typeTier !== undefined && objRecherche.typeTier.length !== 0 &&
      objTab.typeTier.toLowerCase().localeCompare(objRecherche.typeTier.toLowerCase()) !== 0) {
      return false;
    } else if (objRecherche.libelle !== undefined && objRecherche.libelle.length !== 0 &&
      objTab.libelle.toLowerCase().indexOf(objRecherche.libelle.toLowerCase()) === -1) {
      return false;
    } else if (objRecherche.agenceId !== undefined && objRecherche.agenceId !== 0 && objTab.agenceId === objRecherche.agenceId) {
        return false;
    } else if (objRecherche.commercialId !== undefined && objRecherche.commercialId !== 0 &&
      objTab.commercialId === objRecherche.commercialId) {
      return false;
    } else if (objRecherche.identifiantJason !== undefined  && objRecherche.identifiantJason.length !== 0 &&
      objTab.identifiantJason.toLowerCase().indexOf(objRecherche.identifiantJason.toLowerCase()) === -1) {
      return false;
    } else if (objRecherche.natureId !== undefined && objRecherche.natureId !== 0 && objTab.natureId === objRecherche.natureId) {
      return false;
    } else if (objRecherche.clientNumber !== undefined && objRecherche.clientNumber.length !== 0 &&
      objTab.clientNumber.toLowerCase().indexOf(objRecherche.clientNumber.toLowerCase()) === -1) {
      return false;
    }
    return true;
  }
}
