<div class="row">
    <div *ngIf="form" class="col-md-4" [formGroup]="form">
        <mat-card>
            <div class="text-center">
                <h3>Activité: {{ libelle }}</h3>
            </div>

            <div class="full-width">
                <mat-form-field class="full-width">
                    <mat-label>Libelle Contrat Jason</mat-label>
                    <input matInput formControlName="libelleContratJason">
                    <mat-error *ngIf="form.get('libelleContratJason').hasError('maxlength')">
                        Le libelle contrat jason est trop long
                      </mat-error>
                </mat-form-field>
            </div>
           

            <div class="full-width">
                <h4>Description</h4>
                <textarea class="full-width" [rows]="5" maxlength="1000" name="descriptionOuvrage" formControlName="descriptionOuvrage">
    
                </textarea>
            </div>

            <div class="full-width">
                <h4>Conditions d'intervention</h4>
                <textarea class="full-width" [rows]="5" name="modalite" formControlName="modalite">
                </textarea>
            </div>

            <div class="full-width">
                <h4>A la charge du client</h4>
                <textarea class="full-width" [rows]="5" name="chargeClient" formControlName="chargeClient">
                </textarea>
            </div>

            <div class="full-width">
                <h4>Information complémentaire</h4>
                <textarea class="full-width" [rows]="5" name="introduction" formControlName="introduction">
                </textarea>
            </div>

            <div class="full-width text-center" style="margin-top: 5vh">
                <button mat-flat-button class="full-width" color="primary" (click)="addImage()">
                    Image
                </button>
            </div>

            <div class="full-width text-center" style="margin-top: 5vh">
                <button mat-flat-button color="success" (click)="submit()">
                  Enregistrer
                </button>
            </div>
        </mat-card>
    </div>
    <div class="col-md-8">
        <app-parametre-prestation>
        </app-parametre-prestation>
    </div>
</div>