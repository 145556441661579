import { animate, state, style, transition, trigger } from '@angular/animations';
import { Injector, Input, SimpleChanges } from '@angular/core';
import { Component, OnChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ActionService } from '../../../services/action.service';
import { DemandeService } from '../../../services/demande.service';
import { DemandeDTO } from '../../../shared/dtos/demandeDTO';
import { DemandeModel } from '../../../shared/models/demande.model';
import { TypeDemandeModel } from '../../../shared/models/type-demande.model';
import { SimpleQuery } from '../../../shared/simple_query/simpleQuery';

@Component({
  selector: 'app-list-tiers-demande',
  templateUrl: './list-tiers-demande.component.html',
  styleUrls: ['./list-tiers-demande.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class ListTiersDemandeComponent implements OnChanges {
  @Input() demandes: DemandeModel[];
  @Input() activitemap: { [key: number]: string } = {};
  demande: DemandeModel;
  @Input() query: SimpleQuery<DemandeDTO> = new SimpleQuery<DemandeDTO>();
  tiers: TypeDemandeModel[];
  private modalsService: ModalsService;
  expandedDemande: DemandeModel | null;
  matDataSource: MatTableDataSource<DemandeModel>;
  pageSize = 50;
  pageIndex: number;
  displayedColumns: string[] = ['numDemande', 'objetDemande', 'typeDemande', 'statut', 'dateInterv', 'siteGeo','activite', 'commercialP', 'gestionnaire','dateCreation'];
  prestationLibellesMap: { [key: string]: string } = {};
  constructor(
    injector: Injector,
    private actionService: ActionService,
    private demandeService: DemandeService) {
      this.modalsService = injector.get('ModalsService');
  }


  convertPrestationsIdToLibelle(): void {
    this.demandes.forEach(demande => {
      if (demande.prestationIds !== null && demande.prestationIds !== undefined) {
        const ids = demande.prestationIds.split(',').filter(id => id.trim() !== '');
        
        this.prestationLibellesMap[demande.id] = ids
          .map(id => this.activitemap[Number(id.trim())] || '')
          .filter(libelle => libelle !== '')
          .join(', ');
      } else {
        this.prestationLibellesMap[demande.id] = '';
      }
    });
  }
  

  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line: max-line-length
    const sortedDemandes = this.demandes.slice();
    this.convertPrestationsIdToLibelle();
    this.matDataSource = new MatTableDataSource(sortedDemandes);
  }

  onOpenDemande(demande: DemandeModel): void {
    this.demande = Object.assign({}, demande);
    this.actionService.getFromDemande(demande.id).subscribe(res => {
      demande.actions = res;
      this.modalsService.openDemande(this.demande).subscribe(result => {
        this.demandeService.getFromObjet(this.query).subscribe(response => {
          // tslint:disable-next-line: max-line-length
          const sortedDemandes = response.objets.slice();
          this.matDataSource = new MatTableDataSource(sortedDemandes);
          this.expandedDemande = response.objets.find(item => item.id === this.demande.id);
        });
      });
    });
  }
}
