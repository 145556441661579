import { DroitLevels } from '../../../shared/droitsLevels';
import {Component, Inject, OnInit} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators} from '@angular/forms';
import { GestionUtilisateurComponent } from '../gestion-utilisateur.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { SessionService } from 'src/app/modules/shared/session.service';
import { AgenceService } from '../../../services/rsa/agence.service';
import { AssistantService } from '../../../services/user/assistant.service';
import { CommercialService } from '../../../services/user/commercial.service';
import { AgenceModel } from '../../../shared/models/rsa/agence.model';
import { AssistantModel } from '../../../shared/models/users/assistant.model';
import { CommercialModel } from '../../../shared/models/users/commercial.model';
import { UserModel } from '../../../shared/models/users/user.model';


export interface DialogData {
  email: string;
}

export interface UserType {
  id: number;
  libelle: string;
}

const USERTYPE: UserType[] = [
  {id: 1, libelle: 'Commercial(e)'},
  {id: 2, libelle: 'Assistant(e)'}
];

@Component({
  selector: 'app-modal-fiche-utilisateur',
  templateUrl: './modal-fiche-utilisateur.component.html',
  styleUrls: ['./modal-fiche-utilisateur.component.scss']
})

export class ModalFicheUtilisateurComponent implements OnInit {

  user: UserModel;
  agences: AgenceModel[] = [];
  types: UserType[] = USERTYPE;
  type = 1;
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  agence = new UntypedFormControl('', [Validators.required]);
  nom = new UntypedFormControl('', Validators.required);
  prenom = new UntypedFormControl('', Validators.required);
  tel = new UntypedFormControl('', Validators.required);

  constructor( public dialogRef: MatDialogRef<GestionUtilisateurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private agenceService: AgenceService,
    private assistantService: AssistantService,
    private sessionService: SessionService,
    private commercialService: CommercialService,
    private toastService: IntercomToastrService) { }

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Veuillez entrer un email';
    }
    return this.email.hasError('email') ? 'Email n\'est pas valide' : '';
  }

  getNomErrorMessage() {
    return 'Veuillez entrer un nom';
  }

  getPrenomErrorMessage() {
    return 'Veuillez entrer un prenom';
  }
  ngOnInit() {
    this.user = new UserModel();
    this.user.id = 0;
    this.agenceService.getAllManaged(DroitLevels.MANAGE).subscribe(agences => {
      this.agences = agences.sort((a, b) => a.libelle.localeCompare(b.libelle));
    });
  }

  closeDialog(val: number): void {
    this.dialogRef.close(val);
  }

  create_user() {
    this.user.actif = true;
    if (this.user.nom !== undefined && this.user.email !== undefined && this.user.agenceId !== undefined) {
      this.user.nom = this.user.nom.toUpperCase();
      this.user.prenom = this.user.prenom.charAt(0).toUpperCase() + this.user.prenom.slice(1);
      if (this.type === 1) {
        this.assistantService.post(this.user).subscribe((user: AssistantModel) => {
          if (user !== undefined) {
            this.toastService.success('Vous avez créé l\'utilisateur ' + user.email);
            this.closeDialog(user.id);
          }
        }, error => {
          if (error !== undefined) {
            this.toastService.error('L\'utilisateur existe déjà');
          }
        });
      } else {
        this.commercialService.post(this.user).subscribe((user: CommercialModel) => {
          if (user !== undefined) {
            this.toastService.success('Vous avez créé l\'utilisateur ' + user.email);
            this.closeDialog(user.id);
          }
        }, error => {
          if (error !== undefined) {
            this.toastService.error('L\'utilisateur existe déjà');
          }
        });
      }
    } else {
      this.toastService.error('Veuillez entrer un nom, un email et choisir une agence');
    }
  }

  selectedAgenceChanged(event: AgenceModel) {
    if (event !== undefined) {
      this.user.agenceId = event.id;
    }
  }

  onUserChange(event: MatSelectChange) {
    this.type = event.value;
  }
}

