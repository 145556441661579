import { BasicModel } from './basic-models/basic.model';
import { EntiteSARPModel } from './rsa/entite-sarp.model';
import { UserModel } from './users/user.model';

export class DroitModel extends BasicModel {

  public utilisateurId: number;
  public utilisateur?: UserModel;
  public rsaId: number;
  public rsa?: EntiteSARPModel;
  public type: number;

}