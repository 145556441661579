import { BasicMock } from '../basic-mock/basic.mock';
import { AssistantModel } from '../../models/users/assistant.model';
import { Injectable } from "@angular/core";

@Injectable()
export class AssistantMock extends BasicMock  {
  constructor() {
    super();
    (<AssistantModel[]> this.objets) = [];
  }
}
