import { Injectable } from '@angular/core';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
@Injectable()

export class ToastrLoadingService extends IntercomToastrService {

    currentSaves: number[]  = [];
    currentSearches: number[]  = [];
    toastSaveId: number;
    toastSearchId: number;
    currentId: number = 0;

    newSearch() {
      this.currentId++;
      this.currentSearches.push(this.currentId);
      if (this.currentSearches.length === 1) {
        let toast = this.info("Recherche en cours", null, {timeOut: 20000});
        this.toastSearchId = toast.toastId;
      }
      return this.currentId;
    }

    endSearch(SearchID: number) {
      this.currentSearches = this.currentSearches.filter(id => id !== SearchID);
      if (this.currentSearches.length === 0) {
        this.remove(this.toastSearchId);
      }
    }

    newSave() {
      this.currentId++;
      this.currentSaves.push(this.currentId);
      if (this.currentSaves.length === 1) {
        let toast = this.info("Enregistrement en cours", null, {timeOut: 20000});
        this.toastSaveId = toast.toastId;
      }
      return this.currentId;
    }
    
    endSave(SearchID: number) {
      this.currentSaves = this.currentSaves.filter(id => id !== SearchID);
      if (this.currentSaves.length === 0) {
        this.remove(this.toastSaveId);
      }
    }
}
