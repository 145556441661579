import { BasicModel } from './../basic-models/basic.model';

export class BasicEtiquetteModel extends BasicModel {
    libelle: string;
    color: string;

    constructor(libelle: string, color: string) {
        super();
        this.libelle = libelle;
        this.color = color;
    }
}
