import { Input, SimpleChanges} from '@angular/core';
import { Component, OnInit, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DevisDTO } from 'src/app/modules/devis/DTOs/devisDTO';
import { DevisInfo } from 'src/app/modules/shared/devisInfo';

@Component({
  selector: 'app-devis-rapide',
  templateUrl: './devis-rapide.component.html'
})

export class DevisRapideComponent implements OnInit, OnChanges {

  @Input() devisDTO: DevisDTO;
  devisInfo: DevisInfo;

  constructor( private router: Router,
    public dialog: MatDialog) { }

  @Input() tabIndex: number;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  devisChange(event) {
    this.devisInfo = event;
  }
}
