import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { TierService } from './../../../../services/tier/tier.service';
import { SessionService } from '../../../../../shared/session.service';
import { SimpleQueryTiersDTO } from './../../../../shared/dtos/simpleQueryTiersDTO';
import { TierModel } from './../../../../shared/models/tier/tier.model';
import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-autocomplete-tiers',
  templateUrl: './autocomplete-tiers.component.html'
})
export class AutocompleteTiersComponent implements OnInit, OnChanges {

  listTiers: TierModel[];
  tiers: TierModel = new TierModel() ;
  @Output() tiersChangeObject = new EventEmitter<TierModel>();
  @Output() tiersChange = new EventEmitter<string>();
  @Output() tiersFactureChange = new EventEmitter<TierModel>();
  @Input() required = false;
  @Input() tierId = 0;
  @Input() disabled;
  @Input() canBeDisabled;
  @Input() label;
  libelle: string;
  libellesString: string[] = [];
  filteredLibelle: Observable<string[]>;
  myControl = new UntypedFormControl();
  tiersExiste = true;
  loading = false;

  constructor(
    private tierService: TierService,
    private sessionService: SessionService) { }

  ngOnInit() {
    this.tiers.id = 0;
    this.myControl.valueChanges.pipe(
      filter((searchValue: string) => {
        if(searchValue && searchValue.length > 2){
          return true;
        }else{
          return false;
        } 
      }),
      debounceTime(500),
      map((emittedValue : string) => {
        return emittedValue.trim();
      }),
      distinctUntilChanged(),
    ).subscribe((inputValue : string) => {
      if (inputValue.trim().length > 0)
        this.rechercheTiers(inputValue);
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.tierId) {
      this.tierService.get(this.tierId).subscribe(tier => {
        if(tier){
          this.listTiers = [tier];
        }
        this.myControl.setValue(tier.id);
      });
    }
    if (this.canBeDisabled && this.disabled){
        this.myControl.disable();
    }else{
      this.myControl.enable();
    }
  }

  async rechercheTiers(value: string)  {
    const tier = new SimpleQueryTiersDTO();
    if (typeof(value) === 'string') {
      tier.query = value;
     } else {
        tier.query = this.getLibelleById(value);
     }
    this.tierService.getFastQuery(tier).subscribe((tiers: TierModel[]) => {
      this.loading = true;
      // supprimer les libelle null ou vide
      this.listTiers = tiers.filter(ltier => (ltier.libelle && ltier.libelle !== ''));
      this.loading = false;
    });
   }

   getLibelleById(id: number): string {
     if(this.listTiers){
      if (this.listTiers.find(tier => tier.id === id) !== undefined) {
        return this.listTiers.find(tier => tier.id === id).libelle;
      }
     }
    
      this.tiers.email = '';
      this.tiers.telephone = '';
      return '';
  }

  getLibelle(id: string) {
    const tiersId = Number(id);
    if (tiersId !== 0 && tiersId !== null && this.listTiers) {
      var tier = this.listTiers.find(tier => tier.id === tiersId);
      if (tier) {
        return tier.libelle;
      }
    }
    return '';
   }


  getTiersDescription(tiers: TierModel) {
    let desc = tiers.libelle;
    if (tiers.adresse && tiers.codePostal && tiers.ville) {
      desc += ' ' + tiers.adresse + ' ' + tiers.codePostal + ' ' + tiers.ville;
    }
    if (tiers.telephone) {
      desc += ' ' + tiers.telephone;
    }
    return desc;
  }

  selectedTiersChange(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    const id = Number(value);
    if (this.listTiers.find(tier => tier.id === id) !== undefined) {
      this.tiers = Object.create(this.listTiers.find(tier => tier.id === id));
      this.tiersExiste = true;
      this.tiersChange.emit(this.tiers.libelle);
      this.tiersChangeObject.emit(this.tiers);
      this.tiersFactureChange.emit(this.tiers);
    }
  }

  onChange(libelle : string){
    if (this.listTiers.find(tier => tier.libelle === libelle) === undefined) {
      this.tiersFactureChange.emit(null);
      this.tiersChangeObject.emit(null);
    }
    this.tiersChange.emit(libelle);
    // this.tiersChangeObject.emit(this.tiers);
  }

  reinit() {
    this.listTiers = []; //Vide liste de resultats de recherche
    this.myControl.setValue("    "); //remet le texte d’input à vide, espaces car dans ngOnInit searchValue.length > 2
  }
}
