import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { PrestationService } from 'src/app/modules/prestation/services/prestation.service';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { DocumentService } from '../../../services/document.service';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { ModalSpinnerComponent } from '../../divers/modals/modal-spinner/modal-spinner.component';

export interface DialogData {
  document: DocumentModel;
  result: boolean;
  typeId: number;
  typeLib: string;
  tierId: number;
  type: string;
}
@Component({
  selector: 'app-modal-ajout-document',
  templateUrl: './modal-ajout-document.component.html',
  styleUrls: ['./modal-ajout-document.component.scss']
})
export class ModalAjoutDocumentComponent implements OnInit {
  private modalsService: ModalsService;
  constructor(
    private prestationService: PrestationService,
    private toastr: IntercomToastrService,
    private documentService: DocumentService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalAjoutDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    injector: Injector
    ) {
      this.modalsService = injector.get('ModalsService');
    }

  ngOnInit(): void {
    // Récupérations du type de document et l'objet auquel il est rattaché
    this.data.document.typeId = this.data.typeId;
    this.data.document.type = this.data.type;
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(0);
  }

  createDocumentAgence() {
    const dialog = this.dialog.open(ModalSpinnerComponent);
    this.prestationService.import(this.data.document.url, '').subscribe((res : any) => {
      this.documentService.post(this.data.document).subscribe((doc: DocumentModel) => {
        this.data.document = doc;
        this.dialogRef.close(doc.id);
        dialog.close();
        this.toastr.success('Le document : ' + this.data.document.libelle + ' a bien été créé', 'Bonne nouvelle !');
      });
    }, error => {
      this.toastr.error('Une erreur est survenue lors de l\'importation', 'Assurez-vous d\'avoir partagé le document et d\'envoyer l\'url de partage',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
      dialog.close();
    });
  }

  createDocument() {
    const dialog = this.dialog.open(ModalSpinnerComponent);
    this.documentService.post(this.data.document).subscribe((doc: DocumentModel) => {
      this.data.document = doc;
      this.dialogRef.close(doc.id);
      dialog.close();
      this.toastr.success('Le document : ' + this.data.document.libelle + ' a bien été créé', 'Bonne nouvelle !');
    }, error => {
      dialog.close();
    });
  }
  onModalConfirm(): void {
    const titleErreur = 'Oups...Erreur';
    // Si tout est OK, création du document...
    if (this.data.document.libelle && this.data.document.type && this.data.document.url) {
          if (this.data.document.typeId !== -1) {
            if(this.data.document.type === 'Agence'){
              this.data.result = true;
            this.createDocumentAgence();
            }else{
              this.data.result = true;
              this.createDocument();
            }
            
          } else {
            this.toastr.warning('Le document doit être lié à une demande.', 'Erreur');
          }
    } else {
      // Si non on renvoie une erreurr...
      if (!this.data.document.libelle && !this.data.document.type && !this.data.document.url) {
        this.toastr.warning('Le libellé, l\'url sont obligatoires.', 'Impossible de créer le documennt');
      } else {
        if (this.data.document.libelle && !this.data.document.type && !this.data.document.url) {
          this.toastr.warning('L\'url, et le type sont obligatoires', titleErreur);
        } if (!this.data.document.libelle && !this.data.document.type && this.data.document.url) {
          this.toastr.warning('Le libellé, et le type sont obligatoires', titleErreur);
        } if (!this.data.document.libelle && this.data.document.type && !this.data.document.url) {
          this.toastr.warning('L\'url et le libellé sont obligatoires', titleErreur);
        } else {
          if (!this.data.document.libelle) {
            this.toastr.warning('Le libellé est obligatoire', titleErreur);
          } if (!this.data.document.type) {
            this.toastr.warning('Le type est obligatoire', titleErreur);
          } if (!this.data.document.url) {
            this.toastr.warning('L\'url est obligatoire.', titleErreur);
          }
        }
      }
    }
  }

}
