import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';

@Component({
  selector: 'app-autocomplete-with-num',
  templateUrl: './autocomplete-with-num.component.html'
})
export class AutocompleteWithNumComponent implements OnChanges {

  @Input() objets: DemandeModel[];
  @Input() objet: string;
  @Input() selectedObjet: DemandeModel[];
  @Input() required: boolean;
  @Input() parent: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() libelle: string;
  @Output() objetChange = new EventEmitter<string>();
  @Output() loadData =  new EventEmitter<string>();
  @ViewChild('matInput') matInput: ElementRef<HTMLInputElement>;

  objetsString: string[] = [];
  filteredObjets: Observable<string[]>;
  myControl = new UntypedFormControl();

  constructor(private detector: ChangeDetectorRef) { }

  reLoadObjets(): void {
    this.objetsString = [];
    if (this.objets) {
      this.objets = [...new Map(this.objets.map(item => [item['numDemande'], item])).values()];
      for (const objet of this.objets) {
        this.objetsString.push(objet.numDemande);
      }
      this.objetsString.sort();
      this.filteredObjets = this.myControl.valueChanges.pipe(
        startWith(null),
        map((obj: string | null) => obj ? this._filter(obj) : this.objetsString));
      if (this.required) {
        this.myControl.setValidators(forbiddenNamesValidator(this.objets));
      }
    } else {
      combineLatest([this.myControl.valueChanges])
      .subscribe(([value]) => {
        if (value.length > 1) {
          this.loadData.emit(value);
        } else {
          this.loadData.emit(null);
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.reLoadObjets();
    if (this.objet && changes.objet && (this.myControl.value !== changes.objet.currentValue)) {
      this.myControl.setValue(this.objet);
    }
    if(changes.objet && (changes.objet.currentValue === '' || changes.objet.currentValue === null)) {
        this.myControl.setValue('');
        this.detector.detectChanges();
    }
  }

  deleteIfEmptyOrNotFound(): void {
    this.myControl.setValue('');
    this.objet = '';
    this.objetChange.emit('');
  }

  /* selected event MatAutocompleteSelectedEvent*/
  onObjetChange(value: string): void {
    let objet: DemandeModel;
    if (value === '') {
      objet = null;
      this.objet = '';
    } else {
      objet = this.objets.find(obj => obj.numDemande === value);
      if (objet) {
        this.objet = objet.numDemande;
        this.myControl.setValue(objet.numDemande);
      } else {
        this.objet = value;
      }
    }
    this.objetChange.emit(this.objet);
    this.reLoadObjets();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.objetsString.filter(obj => obj.toLowerCase().indexOf(filterValue) >= 0).slice(0, 30);
  }
}

export function forbiddenNamesValidator(names: DemandeModel[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // below findIndex will check if control.value is equal to one of our options or not
    if (control.value) {
      const index = names.findIndex(name => {
        return (name.numDemande + '-' + name.objet).localeCompare(control.value) === 0;
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    }
    return null;
  };

}
