<div class="snackbar">
    <div> {{title}} </div>
    <div> {{body}}</div>
    <section>
        <div class="example-button-row">
            <button mat-raised-button color="warn" (click)="cancel()">Fermer </button>
            <button mat-stroked-button color="accent" (click)="Open()">Ouvrir {{type}} </button>
        </div>
    </section>
    
   
</div>