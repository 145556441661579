import { SimpleQueryUser } from './../shared/simple_query/simpleQueryUser';
import { SimpleQueryResponse } from './../shared/simple_query/simpleQueryResponse';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GoogleAuthService } from '../../shared/gapiServices/googleAuthService';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { Injectable } from '@angular/core';
import { TierBasicService } from './basic-service/tier-basic.service';
import { map, first, filter, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ProtocoleMock } from '../shared/mocks/protocole.mock';
import { VerifyFields } from '../shared/verifyFields';
import { GoogleApiService } from '../../shared/gapiServices/googleApiService';
import { SessionService } from '../../shared/session.service';
import { environment } from 'src/environments/environment';
import { ProtocoleModel } from '../shared/models/protocole.model';

@Injectable({
  providedIn: 'root'
})
export class ProtocoleService extends TierBasicService {

  public  APIURL = environment.URL_API_PROTOCOLE;


  constructor(private mock: ProtocoleMock,
    http: HttpClient,
    public dialog: MatDialog,
    apiService: ApiService,
    sessionService: SessionService,
    private gapiService: GoogleApiService,
    private gapiAuth: GoogleAuthService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEPROTOCOLE;
}

getFromTiers(tiersId: number): Observable<ProtocoleModel[]> {
  return this.http.get<ProtocoleModel[]>(this.apiUrl + this.serviceUrl + 'GetFromTiers/' +
  tiersId, {headers: this.header}).pipe(map((protocoles: ProtocoleModel[]) => protocoles));
}

getFromSimpleQuery(objet: SimpleQueryUser, tierId: number): Observable<SimpleQueryResponse<ProtocoleModel>> {
  let param = new HttpParams();
  param = param.append('tierId', tierId ? tierId.toString() : null);
  // tslint:disable-next-line: max-line-length
  return this.http.post<SimpleQueryResponse<ProtocoleModel>>(this.apiUrl + this.serviceUrl + 'GetFromSimpleQuery/', objet, {params: param});
}

getFromAgence(agenceId: number): Observable<ProtocoleModel[]> {
  return this.http.get<ProtocoleModel[]>(this.apiUrl + this.serviceUrl + 'GetFromAgence/' +
  agenceId, {headers: this.header}).pipe(map((protocoles: ProtocoleModel[]) => protocoles));
}

GetFromObjet(objet: any): Observable<ProtocoleModel[]> {
  return this.http.post<ProtocoleModel[]>(this.apiUrl + this.serviceUrl + 'GetFromObjet/', objet);
}

 // script start when the user export protocoles
/* export(protocoles: ProtocoleModel[]): Observable<any> {
   const url = 'https://script.google.com/a/veolia.com/macros/s/AKfycbxuwMv1GzujSGLuiy3VdbDEoYjRMYZYD5yyQlSO1A/exec';
   const params = '?protocoles=' + protocoles ;
   return this.http.jsonp(url + params, 'callback');
 }*/

post(objet: ProtocoleModel): Observable<ProtocoleModel> {
  if (!this.beforeSave(objet)) {
    return throwError(new Error('sauvegarde impossible'));
  }
  if (!objet.agenceId) {
    objet.agenceId = this.sessionService.currentUserValue.agenceId;
  }
  return this.http.post<ProtocoleModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
}

export(protocoles: ProtocoleModel[]): Observable<any> {
  const token = this.sessionService.getRefreshToken().access_token;
  const body = {
      function: 'exportProtocole',
      parameters: [
          protocoles
      ]
  };
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
           Authorization: 'Bearer ' + token
      })
  };
  this.sessionService.addScope('https://www.googleapis.com/auth/spreadsheets');
  return this.sessionService.userResponseSubject.pipe(filter(user => {
    return this.sessionService.containsAskedScope();
  }), first(), switchMap((user) => {
      return this.http.post<any>(this.APIURL, body, httpOptions).pipe(map(res => {
        if (res.error) {
          throw JSON.stringify(res.error.details[0].errorMessage);
          } else {
              return res.response.result;
          }
      }));
  }));
}


  // script start when the user import protocoles
  import(Sheeturl: string, tier: string): Observable<any> {
    if (Sheeturl.includes('edit')) {
      Sheeturl = Sheeturl.split('edit')[0] + 'edit';
    }
    const url = environment.URL_WEBAPP_PROTOCOLE;
    const params = '?url=' + Sheeturl + '&tier=' + tier;
    const res = this.http.jsonp(url + params, 'callback');
    return res;
  }


  beforeSave(objet: ProtocoleModel): boolean {
    let success = true;
    if (!VerifyFields.verifyStringExist(objet.protocole)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir un protocole.');
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    return success;
  }

  postAll(objets: ProtocoleModel[]): Observable<ProtocoleModel[]> {
    objets.forEach(objet => {
      objet.agenceId = this.sessionService.currentUserValue.agenceId;
      objet.tier = null;
      objet.prestation = null;
      objet.siteGeo = null;
      objet.realisateur = null;
    })
    return this.http.post<ProtocoleModel[]>(this.apiUrl + this.serviceUrl + 'PostAll/', objets);
  }

  deleteAll(objets: ProtocoleModel[]): Observable<ProtocoleModel[]> {
     objets.forEach(objet => {
      objet.agence = null;
      objet.tier = null;
      objet.prestation = null;
      objet.siteGeo = null;
      objet.realisateur = null;
    })
    return this.http.post<ProtocoleModel[]>(this.apiUrl + this.serviceUrl + 'DeleteAll/', objets);
  }

}
