<div>
  <a (click)="onModalCancel()" class="close-button"><mat-icon class="close-icon" >close</mat-icon></a>
  <a (click)="onModalMinimize()" class="close-button"><mat-icon class="close-icon" >remove</mat-icon></a>

  <div class="col-7">
    <h3>Modification d'un formulaire</h3>
  </div>

  <div class="col-7">
    <mat-divider></mat-divider>
  </div>

  <mat-card class="info">
    <mat-card-header>
      <mat-card-title>Consignes modification d'un formulaire</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li> Le lien editable doit permettre d'avoir les permissions. 
          <div> Exemple : https://docs.google.com/forms/d/XXXXXXXXXXX/edit  </div> 
        </li>
        <li>La collecte des emails doit etre activé sur le formulaire inseré.</li>
        <li>Les sections du formulaire doivent contenir au moins 1 question.</li>
      </ul>
    </mat-card-content>
    <mat-card-footer>   </mat-card-footer>
    <mat-card-actions>
    
    </mat-card-actions>
  </mat-card>

 
  <div class="row">
    <div class="col-7">
    </div>

    <div class="col-sm-4">
      <mat-form-field class="full-width">
        <mat-label>Libellé</mat-label>
        <input required matInput placeholder="" value="" [(ngModel)]="data.formulaire.libelle"
          [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-form-field class="full-width">
        <mat-label>Lien editable</mat-label>
        <input required matInput placeholder="" value="" [(ngModel)]="data.formulaire.lienEdit"
          [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </div>

    <div class="col-sm-12">
      <mat-form-field style="width: 100%;">
        <mat-label>Commentaire</mat-label>
        <textarea matInput placeholder="" style="height: 90px;" value=""
          [(ngModel)]="data.formulaire.commentaire"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="col-sm-12 text-center">
    <div class="col-sm-6 text-center" style="margin-top: 35px;">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>

    <div class="col-sm-6 text-center" style="margin-top: 35px;">
      <button mat-flat-button color="primary" (click)="onModalConfirm()">Enregistrer</button>
    </div>
  </div>

</div>
