import { PropertiesInfo } from './../modals-menu-info';
import { DatePipe } from '@angular/common';
import { TierLinkedDTO } from './tierLinkedDTO';
import { UserModel } from '../models/users/user.model';

export class ProtocoleDTO extends TierLinkedDTO {
    public agenceId: number = null;
    public siteGeoId: number;
    public prestationId?: number = null;
    public realisateurId: number;
    public ville: string = null;
    public codePostal = null;
    public protocole = '';

    public static getTitle(titleArray: PropertiesInfo[], protocole: ProtocoleDTO, selectedTier, user: UserModel): string {
        let rechercheTitle = '';
        if (selectedTier !== null && selectedTier !== undefined) {
          rechercheTitle += 'Tiers : ' + selectedTier;
        }
        if (user !== null && user !== undefined) {
          rechercheTitle += 'Réalisateur : ' + user.nom + ' ' + user.prenom + ', ';
        }
        const dateIndex = titleArray.findIndex(p => p.property === 'dateBiggerRealString');
        if (dateIndex !== -1) {
          if (protocole[titleArray[dateIndex].property] !== null) {
            const d = new DatePipe('fr-FR');
            rechercheTitle += titleArray[dateIndex].libelle + ' : ' +
            d.transform(<Date>protocole[titleArray[dateIndex].property], 'dd/MM/yyyy') + ', ';
          }
          titleArray.splice(dateIndex, 1);
        }
        for (const key of titleArray) {
          if (protocole.hasOwnProperty(key.property)) {
            if (protocole[key.property] !== null && protocole[key.property] !== undefined && protocole[key.property].length !== 0) {
              rechercheTitle += key.libelle + ' : ' + protocole[key.property] + ', ';
            }
          }
        }
        if (rechercheTitle.length > 2) {
          rechercheTitle = rechercheTitle.substring(0, 1).toUpperCase() + rechercheTitle.substring(1, rechercheTitle.length - 2);
        }
        return rechercheTitle;
      }
}
