import { CookieService } from 'ngx-cookie-service';
import { Component, HostListener, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { ActionService } from './modules/core/services/action.service';
import { DemandeService } from './modules/core/services/demande.service';
import { MessagingService } from './modules/core/services/messaging.service';
import { TierService } from './modules/core/services/tier/tier.service';
import { ModalsTierInfos } from './modules/core/shared/modals-menu-info';
import { ActionModel } from './modules/core/shared/models/action.model';
import { DemandeModel } from './modules/core/shared/models/demande.model';
import { TierModel } from './modules/core/shared/models/tier/tier.model';
import { UserModel } from './modules/core/shared/models/users/user.model';
import { SimpleQuery } from './modules/core/shared/simple_query/simpleQuery';
import { ModalsService } from './modules/shared/modals.service';
import { SessionService } from './modules/shared/session.service';
import { DocsSiteTheme, ThemeStorage } from './modules/shared/theme-storage.service';
import { TierDTO } from './modules/core/shared/dtos/tierDTO';
import { TransformatorTiersModel } from './modules/core/utils/transformator-tiers.model';
import { environment } from 'src/environments/environment';


export class Call {
  callNumber: string;
  callNumberTo: string;
  tiers: TierModel[] ;
  taked = false;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'Application de gestion de stock';
  showModeurPanel: boolean;
  showAdminPanel: boolean;
  user: UserModel;
  themeChangerButton = 'dark';
  night = false;
  theme: DocsSiteTheme;
  themes: DocsSiteTheme[] = [
    { name: 'light-theme' },
    { name: 'dark-theme' }
  ];
  calls: Call[] = [];
  isButtonVisible = false;
  currentTierCall: TierModel[] = [];
  inCall = false;
  ringing = false;
  tiers: TierModel[] = [];
  showCall = false;
  currentCall: Call ;
  mobileQuery: MediaQueryList;
  containers: any;
  message;
  levelDroit: number;
  isExpanded = false;
  isShowing = false;
  private modalsService: ModalsService;
  public envName = environment.envName;
  private _mobileQueryListener: () => void;
  public transformator: TransformatorTiersModel = new TransformatorTiersModel();

  @HostListener('window:message', ['$event'])
  onMessageUpdated(e) {
    if (e.type === 'message' && e.data !== undefined && e.data != null) {
      const call = new Call();
      switch (e.data.type) {
        case 'rc-active-call-notify': {
          switch (e.data.call.telephonyStatus) {
            case 'Ringing' : {
              this.ringing = true;
              this.isButtonVisible = true;
              this.showCall = true;
              call.callNumber = this.getParsedCallNumber(e.data.call.from.phoneNumber);
              call.callNumberTo = this.getParsedCallNumber(e.data.call.to.phoneNumber);
              // just to test *******
              this.currentCall = call;
              // ********************
              this.rechercheTiers(call.callNumber, call.callNumberTo);
              this.calls.push(call);
              break;
            }
            case 'CallConnected': {
              this.ringing = false;
              this.isButtonVisible = true;
              this.showCall = true;
              this.inCall = true;
              call.taked = true;
              call.callNumber = this.getParsedCallNumber(e.data.call.from.phoneNumber);
              call.callNumberTo = this.getParsedCallNumber(e.data.call.to.phoneNumber);
              this.currentCall = call;
              break;
            }
            case 'NoCall' : {
              this.ringing = false;
              this.showCall = false;
              this.inCall = false;
              this.calls.splice(0, 1);

              break;
            }
            default : {
              this.ringing = false;
              call.callNumber = undefined;
              call.callNumberTo = undefined;

              break;
            }
          }
          break;
        }
        default:
          break;
      }
    }
  }


  getParsedCallNumber(callNumber: string) {
    let parsedCallNumber = callNumber.replace('+33', '0');
    parsedCallNumber = parsedCallNumber.substr(0, 2) + '.' +  parsedCallNumber.substr(2, 2)
      + '.' +  parsedCallNumber.substr(4, 2) + '.' +  parsedCallNumber.substr(6, 2) + '.' +  parsedCallNumber.substr(8, 2);
      return parsedCallNumber;
  }

  constructor(
    private cookieService: CookieService,
    private overlay: OverlayContainer,
    private themeStorage: ThemeStorage,
    private http: HttpClient,
    private session: SessionService,
    public router: Router,
    private dialogRef: MatDialog,
    private serviceTier: TierService,
    private snackBar: MatSnackBar,
    private messagingService: MessagingService,
    private demandeService: DemandeService,
    private actionService: ActionService,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    injector: Injector ) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.session.currentUser.subscribe(user => {
    });
    this.router.events.subscribe(event => {
      const url = event['url'];
      if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
      }
      this.session.currentUser.subscribe(usr => {
        this.user = usr;
        if (this.user && this.user.droits) {
          this.levelDroit = this.user.droits[0].type;
          for (let i = 1; i < this.user.droits.length; i++) {
            if (this.levelDroit === 4) {
              break;
            }
            if (this.levelDroit < this.user.droits[i].type) {
              this.levelDroit = this.user.droits[i].type;
            }
          }
        }
      });
    });
    this.modalsService = injector.get('ModalsService');
    this.containers = this.modalsService.getContainers();


  }

  @HostListener('window:focus', ['$event'])
    onFocus(event: FocusEvent) {

      if (this.cookieService.check('DEMANDE_ID')) {
        this.demandeService.get(Number(this.cookieService.get('DEMANDE_ID')))
        .subscribe(demande => this.modalsService.openDemande(demande).subscribe());
      }
      if (this.cookieService.check('ACTION_ID')) {
        this.actionService.get(Number(this.cookieService.get('ACTION_ID')))
        .subscribe(action => this.modalsService.openAction(action).subscribe());
      }
      this.cookieService.deleteAll();
  }

  ngOnInit() {
    this.currentCall = new Call();
    this.calls = [];
    this.installTheme(this.themeStorage.getStoredThemeName());
    // notification call services
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    if (this.cookieService.check('DEMANDE_ID')) {
      this.demandeService.get(Number(this.cookieService.get('DEMANDE_ID')))
      .subscribe(demande => this.modalsService.openDemande(demande).subscribe());
    }
    if (this.cookieService.check('ACTION_ID')) {
      this.actionService.get(Number(this.cookieService.get('ACTION_ID')))
      .subscribe(action => this.modalsService.openAction(action).subscribe());
    }
    this.cookieService.deleteAll();
  }

  installTheme(themeName: string) {
    if (themeName === null) {
      themeName = 'light-theme';
    }
    // this.session.silentRefresh();

    this.theme = this.themes.find(theme => theme.name === themeName);

    if (this.theme.name === 'dark-theme') {
      this.overlay.getContainerElement().classList.remove('light-theme' || '');
      document.body.classList.remove('light-theme');
      this.overlay.getContainerElement().classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      this.themeChangerButton = 'Light';
      this.night = true;
    } else {
      this.overlay.getContainerElement().classList.remove('dark-theme' || '');
      document.body.classList.remove('dark-theme');
      this.overlay.getContainerElement().classList.add('light-theme');
      document.body.classList.add('light-theme');
      this.themeChangerButton = 'Dark';
      this.night = false;
    }

    if (this.theme) {
      this.themeStorage.storeTheme(this.theme);
    }
  }

  reloadChild() {
    this.router.navigate(['/']);
  }

  logout() {
    this.session.logout().subscribe();
    if (this.router.url !== '/login') {
      this.dialogRef.closeAll();
      this.containers = [];
    }
    localStorage.removeItem('critereSelected');
    this.user = undefined;
  }

  manager_user() {
    this.router.navigate(['manager-user']);
  }

  manager_agence() {
    this.router.navigate(['manager-prestation/80']);
  }
  parametres_user() {
    this.router.navigate(['parametres-utilisateur']);
  }
  parametres_agence() {
    this.router.navigate(['parametres-agence']);
  }

  toggleTheme() {
    this.themeStorage.clearStorage();
    if (this.overlay.getContainerElement().classList.contains('dark-theme')) {
      this.overlay.getContainerElement().classList.remove('dark-theme');
      this.overlay.getContainerElement().classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.night = false;
    } else if (this.overlay.getContainerElement().classList.contains('light-theme')) {
      this.overlay.getContainerElement().classList.remove('light-theme');
      this.overlay.getContainerElement().classList.add('dark-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'dark-theme'));
      this.themeChangerButton = 'Light';
      this.night = true;
    } else {
      this.overlay.getContainerElement().classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.night = false;
    }
    if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.night = false;
    } else if (document.body.classList.contains('light-theme')) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'dark-theme'));
      this.themeChangerButton = 'Light';
      this.night = true;
    } else {
      document.body.classList.add('light-theme');
      this.themeStorage.storeTheme(this.themes.find(theme => theme.name === 'light-theme'));
      this.themeChangerButton = 'Dark';
      this.night = false;
    }
  }

  openQuestionnaire()  {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSd4EBsc0dm-DKQAQTUkuppKwiWc1lHXXqtUOvJSavD2GjfGSA/viewform?usp=pp_url');
  }

  openDemande(demande: DemandeModel) {
    this.modalsService.openDemande(demande).subscribe(result => {
    });
  }

  openAction(action: ActionModel) {
    this.modalsService.openAction(action).subscribe(result => {
    });
  }

  openClosedAction(action: ActionModel) {
    this.modalsService.openClosedAction(action).subscribe(result => {
    });
  }

  closeDemande(demande: DemandeModel) {
    this.modalsService.closeDemande(demande);
  }

  closeAction(action: ActionModel) {
    this.modalsService.closeAction(action);
  }

  getTiers(tiers: TierModel[]) {
    this.currentTierCall = tiers;
  }

  getTierbyTel(tel: string) {
    // en sonnerie
    if (this.tiers !== undefined && this.tiers !== null && this.ringing ) {
      for (let i = 0; i < this.tiers.length; i++) {
        if (this.tiers[i].telephone === tel) {
          return this.tiers[i];
        }
      }
    } else { // en appel
      if (this.currentTierCall !== undefined && this.currentTierCall !== null ) {
        for (let i = 0; i < this.currentTierCall.length; i++) {
          if (this.currentTierCall[i].telephone === tel) {
            return this.currentTierCall[i];
          }
        }
      }
    }
    return null;
  }

  rechercheTiers(callNumber: string, callNumberTo: string) {
    const info = new ModalsTierInfos();
    info.tierFields = new TierModel();
    info.tierFields.typeTier = '';
    info.tierFields.telephone = callNumber + ';' + callNumberTo;
    const query = new SimpleQuery<TierDTO>();
    query.entry = this.transformator.convertModelToDto(info.tierFields, new TierDTO());
    this.serviceTier.GetFromObjetWithPagination(query).subscribe(response => {
      this.tiers = response.objets;
    });
  }

  openSnackBar() {
    const payload = JSON.parse(localStorage.getItem('payload'));
    this.snackBar.open(payload.notification.title, 'close', {
      duration: 10000,
    });
  }

  mouseenter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

}

