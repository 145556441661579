 
         <mat-form-field class="full-width">
          <mat-label> Activité </mat-label> 
          <mat-chip-list #chipList aria-label="Prestation selection" [disabled]="disabled" [required]="required">
            <mat-chip class="oneline"
              *ngFor="let prestation of prestationsString"
              [selectable]="selectable"
              [removable]="removable"
              [matTooltip]="prestation"
              (removed)="remove(prestation)">
              <div class="text-overflow">
                {{prestation}}
              </div>
              <mat-icon class="fixed-icon" matChipRemove *ngIf="removable">close</mat-icon>
            </mat-chip>
            <input
              #prestationInput
              [formControl]="prestationCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)"
              name="prestationInput">
          </mat-chip-list>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)" [panelWidth]="'auto'">
            <mat-option *ngFor="let prestation of filteredPrestations | async" [value]="prestation">
              {{prestation}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
 

 
 