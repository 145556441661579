<div class="col-md-12" style="display: flex; justify-content: space-between;">
    <h2 class="col-md-4"> Administration agence </h2>
    <div class="col-md-8">
    </div>
</div>
<div class="col-sm-12">
  <div class="col-sm-6">
    <mat-card>
        <mat-card-title class="col-sm-5">
          <h4>Agences :</h4>
        </mat-card-title>
        <div class="col-sm-5 text-center">
          <mat-form-field>
            <mat-label>Rechercher une agence</mat-label>
            <input matInput [(ngModel)]="searchedAgence" (ngModelChange)="searchedAgenceChanged()">
          </mat-form-field>
        </div>
        <div class="col-sm-12 agences">
          <mat-selection-list #agencesSelected [multiple]="false">
            <mat-list-option *ngFor="let agence of agences" [value]="agence" (click)="onChangeAgence(agencesSelected.selectedOptions.selected[0].value)">
              {{agence.libelle}}
            </mat-list-option>
          </mat-selection-list>
        </div>
    </mat-card>
  </div>
  <div class="col-sm-6" *ngIf="agence.code">
      <div class="col-sm-6 text-center">
          <a mat-stroked-button color="success" class="row" mat-button (click)="gestionProtocoleAgence()">
            <mat-icon>comment</mat-icon> 
            Gestion des protocoles
          </a>
      </div>
      <div class="col-sm-6 text-center">
          <a mat-stroked-button color="success" class="row" mat-button (click)="gestionPrestationAgence()">
            <mat-icon>view_list</mat-icon> 
            Gestion des activités 
          </a>
      </div>
      <div class="col-sm-12 text-center">
        <a mat-stroked-button color="success" class="row" mat-button (click)="gestionZonesAgence()">
          <mat-icon>location_city</mat-icon>
          Gestion des zones d'intervention
        </a>
      </div>
      <div class="col-sm-12 text-center">
          <mat-card class="fiche">
              <mat-card-header>
                  <h4>Fiche Agence</h4>
              </mat-card-header>
            <div class="col-sm-12 text-center">
              <div class="col-sm-12 nopadding">
                <mat-form-field class="col-sm-6">
                  <mat-label>Code de l'agence</mat-label>
                  <input matInput  [(ngModel)]="agence.code"  disabled>
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Code de l'agence Praxedo</mat-label>
                  <input matInput  [(ngModel)]="agence.codePraxedo"  disabled>
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Libelle de l'agence</mat-label>
                  <input matInput  [(ngModel)]="agence.libelle"  disabled>
                </mat-form-field>
              </div>
              <div class="col-sm-12 nopadding">
                  <mat-form-field class="col-sm-6">
                    <mat-label>Code de la société</mat-label>
                    <input matInput  [(ngModel)]="agence.societe.code"  disabled>
                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Libelle de la société</mat-label>
                    <input matInput  [(ngModel)]="agence.societe.libelle"  disabled>
                  </mat-form-field>
              </div>
              
              <div class="col-sm-6">
                <mat-form-field class="full-width">
                  <mat-label>WebHook Relance</mat-label>
                  <input matInput  [(ngModel)]="agence.lienWebHook" [disabled]="!agence.id">
                </mat-form-field>
              </div>

              <div class="col-sm-6">
                <a mat-flat-button color="default" class="row" mat-button target="_blank" href="https://docs.google.com/document/d/1NG9cw-mCX7A6u6KIfEdHKTdgu9OCY86cxsB1SQocGEg/edit?usp=sharing">Documentation WebHook</a>
              </div>

              <div class="col-sm-12 nopadding">
                <mat-form-field class="col-sm-6">
                  <mat-label>Adresse de la société</mat-label>
                  <input matInput  [(ngModel)]="agence.societe.adresse" [disabled]="!agence.id">
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Complement d'adresse de la société</mat-label>
                  <input matInput  [(ngModel)]="agence.societe.complementAdresse" [disabled]="!agence.id">
                </mat-form-field>
              </div>
              <div class="col-sm-12 nopadding">
                <mat-form-field class="col-sm-6">
                  <mat-label>Code postal de la société</mat-label>
                  <input matInput type="number" [(ngModel)]="agence.societe.codePostal" [disabled]="!agence.id">
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Ville de la société</mat-label>
                  <input matInput [(ngModel)]="agence.societe.ville" [disabled]="!agence.id">
                </mat-form-field>
              </div>
              <div class="col-sm-12 nopadding">
                <mat-form-field class="col-sm-6">
                  <mat-label>Siren de la société</mat-label>
                  <input matInput  [(ngModel)]="agence.societe.siren"  [disabled]="!agence.id">
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Région</mat-label>
                  <input matInput  [(ngModel)]="agence.societe.region.libelle"  disabled>
                </mat-form-field>
              </div>
              <div class="col-sm-12">
                  <mat-form-field class="full-width">
                    <mat-label>Slogan</mat-label>
                    <input matInput  [(ngModel)]="agence.slogan"  [disabled]="!agence.id">
                  </mat-form-field>
              </div>
              <div class="col-sm-12 nopadding">
                <mat-form-field class="col-sm-6">
                  <mat-label>Adresse</mat-label>
                  <input matInput  [(ngModel)]="agence.adresse" [disabled]="!agence.id">
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Complement d'adresse</mat-label>
                  <input matInput  [(ngModel)]="agence.complementAdresse" [disabled]="!agence.id">
                </mat-form-field>
              </div>
              <div class="col-sm-12 nopadding">
                <mat-form-field class="col-sm-6">
                  <mat-label>Code postal</mat-label>
                  <input matInput type="number" [(ngModel)]="agence.codePostal" [disabled]="!agence.id">
                </mat-form-field>
                <mat-form-field class="col-sm-6">
                  <mat-label>Ville</mat-label>
                  <input matInput [(ngModel)]="agence.ville" [disabled]="!agence.id">
                </mat-form-field>
              </div>
              <div class="col-sm-12 nopadding">
                <app-input-telephone class="col-sm-6" [libelle]="'Téléphone'" [phoneNumber]="agence.telephone" [childFieldStatus]="!agence.id" (phoneNumberChange)="changeTelephone($event)"></app-input-telephone>
                <mat-form-field class="col-sm-6">
                  <mat-label>Email</mat-label>
                  <input matInput type="email" [(ngModel)]="agence.email" [disabled]="!agence.id">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="col-sm-6">
                <mat-label>Siret</mat-label>
                <input matInput type="text" pattern="[0-9]{14}" [(ngModel)]="agence.siret" [disabled]="!agence.id">
              </mat-form-field>
              <mat-form-field class="col-sm-6">
                <mat-label>NAF</mat-label>
                <input matInput type="text" pattern="[0-9]{4}" [(ngModel)]="agence.naf" [disabled]="!agence.id">
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="col-sm-12">
                <mat-label>RCS</mat-label>
                <input matInput type="text" [(ngModel)]="agence.rcs" [disabled]="!agence.id">
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="col-sm-12">
                <mat-label>Destinataires </mat-label>
                <input matInput type="text" [formControl]="destinatairesControl" [(ngModel)]="agence.destinataires" [disabled]="!agence.id">
                <mat-error *ngIf="destinatairesControl.hasError('pattern')">
                  Veuillez saisir des adresses e-mail valides séparées par des points-virgules.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <button mat-flat-button color="primary" class="col-sm-6" (click)="addCertification(1)" [disabled]="!agence.id"> Certifiaction 1 </button>
              <button mat-flat-button color="primary" class="col-sm-6" (click)="addCertification(2)" [disabled]="!agence.id"> Certification 2 </button>
            </div>

            <div class="col-sm-12">
              <button mat-flat-button color="primary" class="col-sm-6" (click)="addLogo()" [disabled]="!agence.id">Logo</button>
              <button mat-flat-button color="success" class="row" (click)="submit()" >Enregistrer</button>
            </div>

          </mat-card>
      </div>
  </div>
</div>
  
    
    
     