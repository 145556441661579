import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { ZoneService } from 'src/app/modules/core/services/zone.service';
import { ZoneInterventionModel } from 'src/app/modules/core/shared/models/zone-intervention.model';
import { PrestationShared } from '../../../shared/prestationShared';
import { FormInfo, OFFREVALIDATORS, TVAVALIDATORS, ZONEVALIDATORS } from '../../constant/offre-validators';
import { PRESTATIONVALIDATORS } from '../../constant/prestation-validators';
import { OffreModel, TvaModel } from '../../models/offre.model';
import { PrestationModel } from '../../models/prestation.model';
import { PrestationService } from '../../services/prestation.service';
import { SiteGeoModel } from 'src/app/modules/core/shared/models/site-geo.model';
import { DevisInfo } from 'src/app/modules/shared/devisInfo';
import { DevisDTO } from 'src/app/modules/devis/DTOs/devisDTO';
import { ONGLETSPRISEAPPEL } from 'src/app/modules/core/shared/ongletsPriseAppel';

@Component({
  selector: 'app-critere-calcul-devis',
  templateUrl: './critere-calcul-devis.component.html',
  styleUrls: ['./critere-calcul-devis.component.scss']
})

export class CritereCalculDevisComponent implements OnChanges {
  @Input() site: SiteGeoModel;
  @Input() prestationId: number;
  @Input() agenceId: number;
  @Input() devisDTO: DevisDTO;
  @Input() tabIndex: number;

  @Output() selectedPrestations = new EventEmitter<PrestationShared[]>();
  @Output() maxPrestations = new EventEmitter<PrestationShared[]>();
  @Output() minPrestations = new EventEmitter<PrestationShared[]>();
  @Output() prestationNonParemetre = new EventEmitter<boolean>()
  
  @Output() isGroupValid = new EventEmitter<boolean>();
  @Output() critereUpdated = new EventEmitter<DevisInfo>();

  selectedZone = new ZoneInterventionModel();
  selectedTva = new TvaModel();
  listPrestations: PrestationModel[] = [];
  offre: OffreModel;
  listeOffres: OffreModel[]=[];
  agenceZones:ZoneInterventionModel[] = [];
  prestationsFormArray: UntypedFormArray = new UntypedFormArray([]);
  prestationShareds: PrestationShared[] = [];
  prestationMax: PrestationShared[] = [];
  prestationMin: PrestationShared[] = [];
  zoneMax: ZoneInterventionModel;
  zoneMin: ZoneInterventionModel;
  alreadyZoneError = false;
  
  myGroup = new UntypedFormGroup({
    prestationsFormArray: new UntypedFormArray([]),
    zone: this.getForm(this.selectedZone, ZONEVALIDATORS.CREATE),
    tva: this.getForm(this.selectedTva, TVAVALIDATORS.CREATE)
  });

  listPrestationsKeys = Object.keys;

  constructor(
    private prestationService: PrestationService,
    private IntercomToastrService: IntercomToastrService,
    private agenceService: AgenceService,
    private zoneService: ZoneService
    ) {}

  ngOnChanges(changes: SimpleChanges): void {   
    if(this.devisDTO){
      this.selectedTva = new TvaModel()
      this.selectedTva.libelle = this.devisDTO.tvaPercent + "%";
      this.selectedTva.taux = this.devisDTO.tvaPercent
    }
    if (this.agenceId &&  this.prestationId) {
      if((changes.agenceId     && changes.agenceId.previousValue     != changes.agenceId.currentValue) || 
         (changes.prestationId && changes.prestationId.previousValue != changes.prestationId.currentValue)) {
        this.prestationService.getFromActivite(this.agenceId, this.prestationId).subscribe((res: PrestationModel[]) => {
          if(res && res.length > 0){
            this.listPrestations = res;  
            this.prestationsFormArray = this.createPrestationsForm(res, this.devisDTO);
            this.prestationsFormArray.updateValueAndValidity();
            this.myGroup = new UntypedFormGroup({
              prestationsFormArray: this.prestationsFormArray,
              zone: this.getForm(this.selectedZone, ZONEVALIDATORS.CREATE),
              tva: this.getForm(this.selectedTva, TVAVALIDATORS.CREATE)
            });  
            this.calculer();
            this.prestationNonParemetre.emit(false);
          }else {
            this.IntercomToastrService.warning("Aucun critére de calcul pour cette activité/agence");
            this.prestationsFormArray = this.createPrestationsForm([], this.devisDTO);
            this.prestationShareds = [];
            this.selectedPrestations.emit(this.prestationShareds);
            this.prestationNonParemetre.emit(true);
          }
        }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des prestations")); 
      }
    } else {
      this.selectedTva = new TvaModel();
      if (!this.prestationsFormArray) {
        this.prestationsFormArray = this.createPrestationsForm([], this.devisDTO);
      }
      this.myGroup = new UntypedFormGroup({
        prestationsFormArray: this.prestationsFormArray,
        zone: this.getForm(this.selectedZone, ZONEVALIDATORS.CREATE),
        tva: this.getForm(this.selectedTva, TVAVALIDATORS.CREATE)
      });
      this.myGroup.get('zone').disable();
      this.prestationShareds = [];
      this.selectedPrestations.emit(this.prestationShareds);
    }
    if (changes.agenceId && changes.agenceId.previousValue != changes.agenceId.currentValue && this.agenceId) {
      this.zoneService.getFromAgence(this.agenceId).subscribe((zones : ZoneInterventionModel[]) => {
        this.agenceZones = zones;
        if (zones.length > 0){
          this.myGroup.get('zone').enable();
          if (this.site) {
            this.getZoneFromAdresse(this.site.codePostal);
          }
        }
      });
    }
    if (this.site) {
      this.getZoneFromAdresse(this.site.codePostal);
    }
  }


  private getForm(offre: any, validators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of validators.entries()) {
      const control =
        new UntypedFormControl({ value: offre[key], disabled: validators.get(key).disabled }, validators.get(key).validators);
      form.addControl(key, control);
    } 
    return form;
  }

  tvaChange() {
    this.myGroup.get('tva').get('libelle').setValue(Number(this.myGroup.get('tva').get('taux').value));
    this.calculer();
  }

  zoneChange(valueChange: MatSelectChange) {
    let indexzone = this.agenceZones.map(ag => ag.libelle).indexOf(valueChange.value);
    if (indexzone > -1) {
      this.myGroup.get('zone').get('majoration').setValue(this.agenceZones[indexzone].majoration);
      this.myGroup.get('zone').get('libelle').setValue(this.agenceZones[indexzone].libelle);
      this.calculer();
    }
  }

  private createPrestationsForm(prestations: PrestationModel[], devisDTO: DevisDTO): UntypedFormArray {

    const formArray = this.prestationService.getPrestationForm(prestations, devisDTO, PRESTATIONVALIDATORS.CREATE, OFFREVALIDATORS.CREATE)
    return formArray;

  }
  
  private createPrestationShared(): PrestationShared[] {
    var prestations = this.prestationsFormArray.getRawValue();
    var prestShareds = [];
    for (const prest of prestations) {
      var prestShared = new PrestationShared();
      prestShared.prestation = prest;
      prestShared.offre = prest.offres.find(offre => offre.id === prest.selected);
      prestShareds.push(prestShared);
    }
    return prestShareds;
  }

  private createPrestationMax(): PrestationShared[] {
    var prestMax = [];
    for (const prest of this.listPrestations) {
        var prestShared = new PrestationShared();
        prestShared.prestation = prest;
        prestShared.offre = this.getOffreMax(prest.offres);
        prestMax.push(prestShared);
    }
    return prestMax;
  }
  getOffreMax(offres: OffreModel[]): OffreModel{
    return offres.reduce(function(prev, current) {
      if(prev.type == 'Multiplicatif'){
        return (prev.coefficient > current.coefficient) ? prev : current  
      } else {
        return (prev.pu > current.pu) ? prev : current
      }
    });
  }

  private createPrestationMin(): PrestationShared[] {
    var prestMin = [];
    for (const prest of this.listPrestations) {
        var prestShared = new PrestationShared();
        prestShared.prestation = prest;
        prestShared.offre = this.getOffreMin(prest.offres);
        prestMin.push(prestShared);
    }
    return prestMin;
  }

  getOffreMin(offres: OffreModel[]): OffreModel{
    return offres.reduce(function(prev, current) {
      if(prev.type == 'Multiplicatif'){
        return (prev.coefficient < current.coefficient) ? prev : current  
      } else {
        return (prev.pu < current.pu) ? prev : current
      }
    });
  }

  getZoneMax(): ZoneInterventionModel {
    if (this.agenceZones) {
      this.agenceZones.map(x => this.agenceZones.filter(a => a.libelle == x.libelle && a.majoration == x.majoration).length > 0 ? null : this.agenceZones.push(x));
      if (this.agenceZones && this.agenceZones.length > 0) {
        return this.agenceZones.reduce(function(prev, current) {
          return (prev.majoration > current.majoration) ? prev : current
        });
      } else {
        return new ZoneInterventionModel();
      }
      
    }
  }
  getZoneMin(): ZoneInterventionModel {
    if (this.agenceZones) {
      this.agenceZones.map(x => this.agenceZones.filter(a => a.libelle == x.libelle && a.majoration == x.majoration).length > 0 ? null : this.agenceZones.push(x));
      if (this.agenceZones && this.agenceZones.length > 0) {
        return this.agenceZones.reduce(function(prev, current) {
          return (prev.majoration < current.majoration) ? prev : current
        });
      } else{
        return new ZoneInterventionModel();
      }
    }
  }

  modify(prestation: PrestationModel, offre: OffreModel) {    
      var present = false;
      this.prestationShareds.forEach(shared => {
        if(shared.prestation && shared.prestation.id === prestation.id) {
          shared.offre=offre;        
          present = true;
        }
      });
      if(!present) {
        var prestationShared = new PrestationShared();
        prestationShared.prestation = prestation;
        prestationShared.offre = offre;
        this.prestationShareds.push(prestationShared);
      }   
    this.calculer();
  }

  getZoneFromAdresse(codePostal: string) {
    if (this.agenceZones && this.agenceZones.length > 0) {
      this.myGroup.get('zone').enable();
      this.myGroup.get('zone').get('libelle').enable();
      let zoneCp = this.agenceZones.find(z => z.codePostal === codePostal);
      if (zoneCp) {
        this.selectedZone = zoneCp;
        this.myGroup.get('zone').get('libelle').setValue(zoneCp.libelle);
        this.myGroup.get('zone').get('majoration').setValue(zoneCp.majoration);
      }
      if(this.devisDTO && this.devisDTO.idDevis){
        this.selectedZone = new ZoneInterventionModel();
        this.selectedZone.libelle = this.devisDTO.zone;
        this.selectedZone.majoration = this.devisDTO.majoration;
        this.myGroup.get('zone').get('libelle').setValue(this.selectedZone.libelle);
        this.myGroup.get('zone').get('majoration').setValue(this.selectedZone.majoration);
        if (!this.alreadyZoneError && this.tabIndex == ONGLETSPRISEAPPEL.DEVIS && this.agenceZones.length == 0) {
          this.alreadyZoneError = true;
        }
      }
    } else {
      this.agenceZones = [];
      this.selectedZone = new ZoneInterventionModel();
      this.myGroup.get('zone').disable();
    }
  }

  calculer() {
    this.prestationShareds = this.createPrestationShared();
    this.critereUpdated.emit(this.getDevisInfo());
    if(this.myGroup.valid){
      this.isGroupValid.emit(true);
    } else {
      this.isGroupValid.emit(false);
    }
    this.myGroup.markAllAsTouched();
  }
  
  private getDevisInfo(): DevisInfo {
    var devisInfo = new DevisInfo();
    if (this.myGroup.get('tva')){
      devisInfo.tva = this.myGroup.get('tva').get('taux').value;
    }
    devisInfo.zone = this.myGroup.get('zone').value;
    devisInfo.zoneMax =  this.getZoneMax();
    devisInfo.zoneMin =  this.getZoneMin();
    devisInfo.prestationsMax = this.createPrestationMax();
    devisInfo.prestationsMin = this.createPrestationMin();
    devisInfo.prestations = this.prestationShareds;
    return devisInfo;
  }
  
}
