<div>
  <div>
    <div class="col-sm-4">
      <h3>Fiche document</h3>
    </div>
    <div class="col-sm-6" *ngIf="data.document.typeId !== null">
      <button
        *ngIf="data.document.type === 'Demande'"
        (click)="onOpenDemande()"
        matTooltip="Ouvrir la demande"
        mat-flat-button
        color="primary"
        style="transform: scale(0.85)"
      >
        {{ data.objectLibelle }} <mat-icon>work</mat-icon>
      </button>
      <button
        *ngIf="data.document.type === 'Action'"
        (click)="onOpenAction()"
        matTooltip="Ouvrir l'action"
        mat-flat-button
        color="primary"
        style="transform: scale(0.85)"
      >
        {{ data.objectLibelle }} <mat-icon>work</mat-icon>
      </button>
    </div>
    <div class="col-sm-2">
      <a (click)="onModalCancel()" class="close-button"
        ><mat-icon class="close-icon">close</mat-icon></a
      >
    </div>
  </div>

  <div class="col-sm-12">
    <mat-divider></mat-divider>
  </div>

  <div class="col-sm-12">
    <mat-card class="info">
      <mat-card-header>
        <mat-card-title>Consignes de modification d'un document</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul>
          <li>Saisir un libellé et un lien</li>
          <li>Le lien correspond à l'adresse web permettant d'accéder à votre document
            <div> Exemple 1 : https://docs.google.com/forms/d/XXXXXXXXXXX/edit</div>
            <div> Exemple 2 : https://docs.google.com/document/d/XXXXXXXXXXX</div>
          </li>
          <li>Pour autoriser l'accès à votre document, veuillez donner les droits aux personnes depuis Google </li>
        </ul>
      </mat-card-content>
      <mat-card-footer>   </mat-card-footer>
      <mat-card-actions>
      
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col-sm-6" style="margin-top: 15px;">
    <mat-form-field class="full-width">
      <mat-label>Libellé</mat-label>
      <input
        required
        matInput
        placeholder=""
        value=""
        [(ngModel)]="data.document.libelle"
        name="intercom-objet"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-6" style="margin-top: 15px;">
    <mat-form-field class="full-width">
      <mat-label>Type</mat-label>
      <input
        required
        disabled
        matInput
        placeholder=""
        value=""
        [(ngModel)]="data.document.type"
        name="intercom-objet"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-12">
    <mat-form-field class="full-width">
      <mat-label>Lien</mat-label>
      <input
        required
        matInput
        placeholder=""
        value=""
        [(ngModel)]="data.document.url"
        name="intercom-objet"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="col-sm-12">
    <mat-divider></mat-divider>
  </div>

  <div class="text-center">
    <div class="col-sm-12 flex-container">
      <div class="col-sm-4 subDivContainer">
        <button mat-flat-button color="default" (click)="onModalCancel()">
          Annuler
        </button>
      </div>
      <div class="col-sm-4 subDivContainer">
        <button mat-flat-button color="success" (click)="onModalConfirm()">
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</div>
