<div class="table_overflow_x">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="dateInterv" matSortDirection="desc" matSortDisableClear>
    <caption></caption>
    <ng-container  matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Modifier </th>
      <td mat-cell class="action-btns" *matCellDef="let row">
        <div class="btn-container" >
          <button  mat-mini-fab class="tableau-btn" color="primary"  (click)="onOpenDemande(row)">
            <mat-icon class="tableau-mat-icon" >create</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

  <ng-container matColumnDef="Urgence" >
    <th mat-header-cell *matHeaderCellDef style="  text-align: center;  "> <img src="https://www.gartner.com/pi/vendorimages/praxedo_1708007979971.png" alt="Icon" width="20" height="20"> </th>
    <td mat-cell *matCellDef="let element"  style="text-align: center;" class="text-center">
       <mat-icon class="v-align-middle" *ngIf="(element.typeDemandeId === 1000 && !element.interventionPxo) 
        || (element.interventionPxo && !element.interventionPxo.dEnvoiPxo)"
        style="color: #eef3d3;" matTooltip="À envoyer">hourglass_full</mat-icon>  <!-- À envoyer -->

      <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dEnvoiPxo 
        && !element.interventionPxo.dStartPlanifPxo && !element.interventionPxo.fAnnulePxo"
        style="color: #eef3d3;" matTooltip="Envoyée"> send </mat-icon> <!-- Envoyée, couleur synchro : #f8bb8c-->
        
      <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dStartPlanifPxo 
        && !element.interventionPxo.dDemarrePxo && !element.interventionPxo.fAnnulePxo"
        style="color: #fce793;" matTooltip="Planifiée"> event_available </mat-icon> <!-- Planifiée -->

      <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dDemarrePxo 
        && !element.interventionPxo.dRealPxo && !element.interventionPxo.fAnnulePxo"
        style="color: #86eaa4;" matTooltip="Démarrée"> timelapse </mat-icon> <!-- Démarrée -->

        <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dRealPxo 
        && !element.interventionPxo.dValidPxo
         && !element.interventionPxo.fAnnulePxo"
         style="color: #76bbed;" matTooltip="Réalisée"> mobile_friendly </mat-icon> <!-- Réalisée non à envoyer Jason ni annulé -->

      <mat-icon class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.dRealPxo 
        && element.interventionPxo.dValidPxo
        && !element.interventionPxo.fAnnulePxo"
        matTooltip="Validée"> verified_user </mat-icon> <!-- Validé à envoyer Jason-->

      <strong class="v-align-middle" *ngIf="element.interventionPxo && element.interventionPxo.fAnnulePxo" 
        matTooltip="Annulée"> <mat-icon>close</mat-icon> </strong> <!-- Annulée -->
  </td>
  </ng-container>
  
  <ng-container matColumnDef="NumDemande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Numéro </th>
    <td mat-cell *matCellDef="let element"> 
      <div style="white-space: nowrap;"> {{element.numDemande}} 
        <mat-icon (click)="copyclip(element.numDemande, $event)" inline="true" matTooltip="copier dans le presse-papiers">content_copy</mat-icon>
      </div> 
    </td>  
  </ng-container>
  <ng-container matColumnDef="DateCreation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de création </th>
    <td mat-cell *matCellDef="let element"> <div>{{element.dateCreation | date:'dd/MM/yyyy HH:mm'}} </div></td>
  </ng-container>

  <ng-container matColumnDef="DateIntervention">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date d'échéance </th>
    <td mat-cell *matCellDef="let element"> <div>{{element.dateIntervention | date:'dd/MM/yyyy'}} </div></td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="TypeDemande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
    <td mat-cell  *matCellDef="let element" [title]="element.typeDemande.libelle"> <div>{{element.typeDemande.libelle | truncate: 12}}</div> </td>
  </ng-container>
    
  <!-- Weight Column -->
  <ng-container matColumnDef="Statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
    <td mat-cell *matCellDef="let element" >
      <strong  *ngIf="element.statut === 'A traiter'" class="chip info-blue text-center"> {{element.statut}} </strong>
      <strong *ngIf="element.statut === 'En cours'" class="chip info-dark-blue text-center"> {{element.statut}} </strong>
      <strong *ngIf="element.statut === 'Annulé'" class="chip info-light-brown text-center"> {{element.statut}} </strong>
      <strong *ngIf="element.statut === 'Clos'" class="chip info-green text-center"> {{element.statut}} </strong>
  </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="Objet">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Objet </th>
    <td mat-cell *matCellDef="let element"> <div> {{element.objet}} </div></td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="Tier">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tier </th>
    <td mat-cell *matCellDef="let element"   [title]="element.tier.libelle"> 
      <div *ngIf="element.tier !== null" > {{element.tier.libelle  | truncate: 20 }} </div> 
    </td>  
  </ng-container>

  <ng-container matColumnDef="CodePostalAdresse">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> CP </th>
    <td mat-cell *matCellDef="let element">
      <div> {{ element.codePostalAdresse ? element.codePostalAdresse : '' }} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="VilleAdresse">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
    <td mat-cell *matCellDef="let element" [title]="(element.villeAdresse ? element.villeAdresse : '')">
      <div> {{ (element.villeAdresse ? element.villeAdresse : '') | truncate: 15 }} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Adresse">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Adresse </th>
    <td mat-cell *matCellDef="let element"   [title]="element.adresse">
      <div> {{ element.adresse | truncate: 25 }} </div>
    </td>
  </ng-container>

 

  <!-- Symbol Column -->
  <ng-container matColumnDef="Createur">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Créateur </th>
    <td mat-cell *matCellDef="let element" [title]="element.createur.prenom + ' '+ element.createur.nom">
      <div>{{ element.createur.prenom}} </div></td>
  </ng-container>

  <ng-container matColumnDef="Commercial">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Réalisateur </th>
    <td mat-cell *matCellDef="let element"><div>{{element.commercial.nom + ' ' + element.commercial.prenom}} </div></td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <mat-paginator #paginator class="paginator" [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10,30, 50]" (page)="changePage($event)">
  </mat-paginator>
</div>