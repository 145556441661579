import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ModalValidationManuelleComponent } from '../modal-validation-manuelle/modal-validation-manuelle.component';
import { DevisShared } from '../../../shared/DevisShared';
import { LigneChantier, SousLigneChantier, TablePrestationChantierModel } from '../../models/table-prestation-chantier.model';
import { LigneClient, TablePrestationClientModel } from '../../models/table-prestation-client.model';
import { PrestationShared } from 'src/app/modules/shared/prestationShared';
import { DevisDTO } from '../../DTOs/devisDTO';
import { DevisService } from '../../services/devis.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ModalSpinnerComponent } from 'src/app/modules/shared/components/modals/modal-spinner/modal-spinner.component';
import { PrestationModel } from 'src/app/modules/prestation/models/prestation.model';
import { ModalEnvoiDevisComponent } from '../modal-envoi-devis/modal-envoi-devis.component';
import { ModalImportDevisComponent } from '../modal-import-devis/modal-import-devis.component';
import { ModalRefusDevisComponent } from '../modal-refus-devis/modal-refus-devis.component';
import { DevisRaisons } from 'src/app/modules/shared/devisStatus';
import { GmailDTO } from '../../DTOs/gmailDTO';
import { switchMap, tap, catchError } from 'rxjs/operators'
import { of, forkJoin } from 'rxjs';
import { PdfService } from '../../../shared/pdf-service';
import { DatePipe } from '@angular/common';
import { ModalEnvoiDevisCoreComponent } from 'src/app/modules/core/components/divers/modals/modal-envoi-devis-core.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { SessionService } from 'src/app/modules/shared/session.service';
registerLocaleData(localeFr, 'fr');
import { DocumentService } from 'src/app/modules/devis/services/document.service';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { PriseAppelService } from 'src/app/modules/core/services/prise-appel.service';
import { ONGLETSPRISEAPPEL } from 'src/app/modules/core/shared/ongletsPriseAppel';

@Component({
  selector: 'app-resume-cycle-devis',
  templateUrl: './resume-cycle-devis.component.html',
  styleUrls: ['./resume-cycle-devis.component.scss']
})
export class ResumeCycleDevisComponent implements OnChanges {

  statutValue: string;

  @Input() devishared: DevisShared;
  @Input() devisDTO: DevisDTO;
  @Output() annule = new EventEmitter<boolean>();
  exist: boolean = false;
  @Output() updateDevis = new EventEmitter<DevisDTO>();
  @Output() closeDemande = new EventEmitter<boolean>();
  chemin = 'api/Document/getDoc?idDoc=';
  devis = new DevisDTO();
  @Input() agenceId: number;
  listPrestations: PrestationModel[] = [];
  public listRaisonRefus = DevisRaisons.listRaisonRefus;
  public listRaisonAnnulation = DevisRaisons.listRaisonAnnulation;
  public tva: number;
  constructor(private router: Router,
    private demandeService: DemandeService,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    private toastr: IntercomToastrService,
    private session: SessionService,
    private devisService: DevisService,
    private pdfService: PdfService,
    private documentService: DocumentService,
    private priseAppelService : PriseAppelService,
    private IntercomToastrService: IntercomToastrService,) {
     }


  ngOnChanges(changes: SimpleChanges): void {
    
    if(this.devisDTO) {
      this.devis = this.devisDTO;
      this.exist = true;
      this.tva = (this.devis.ht * this.devis.tvaPercent) / 100;
    } else {
      this.exist = false;
    }
    if (this.devishared && this.devishared.agence) {
      this.initImages();
    }
    this.statutValue = this.devis.statut;
  }

  initImages() {
    if (this.devishared.agence.logo) {
      this.devis.logoAgence = this.devishared.agence.logo.url;
      this.devis.urlLogo = this.chemin + this.devishared.agence.logo.id; 
    }else {
      this.devis.urlLogo = ""; 
    }
    if (this.devishared.agence.certification1) {
      this.devis.certification1 = this.devishared.agence.certification1.url;
      this.devis.urlCertification1 = this.chemin + this.devishared.agence.certification1.id;
    }else {
      this.devis.urlCertification1 = ""; 
    }
    if (this.devishared.agence.certification2) {
      this.devis.certification2 = this.devishared.agence.certification2.url;
      this.devis.urlCertification2 = this.chemin + this.devishared.agence.certification2.id;
    }else {
      this.devis.urlCertification2 = ""; 
    }
    if (this.devishared.description) {
      this.devis.introduction = this.devishared.description.introduction;
      this.devis.descriptionOuvrage = this.devishared.description.descriptionOuvrage;
      this.devis.modalite = this.devishared.description.modalite;
      this.devis.chargeClient = this.devishared.description.chargeClient;
      if (this.devishared.description.image) {
        // a verifier
        this.devis.url = this.devishared.description.image.url;
        this.devis.urlActivite = this.chemin + this.devishared.description.image.id;
      }
      else {
        this.devis.urlActivite = "";
      }
    }
    if (this.devishared.realisateur) {
      this.devis.signature = this.devishared.realisateur.signatureId ? (this.chemin + this.devishared.realisateur.signatureId) : "";
    } 
    if (this.devishared.description) {
      this.devis.introduction = this.devishared.description.introduction;
      this.devis.descriptionOuvrage = this.devishared.description.descriptionOuvrage;
      this.devis.modalite = this.devishared.description.modalite;
      this.devis.chargeClient = this.devishared.description.chargeClient;
      if (this.devishared.description.image) {
        // a verifier
        this.devis.url = this.devishared.description.image.url;
        this.devis.urlActivite = this.chemin + this.devishared.description.image.id;
      }
      else {
        this.devis.urlActivite = "";
      }
    }
  }

// convert devis shared -> devis model
createDevisFromShared() {
  this.devis.idRealisateur = this.devishared.idRealisateur;
  this.devis.tierId = this.devishared.tiersId;
  this.devis.agenceId = this.devishared.agenceId;
  this.devis.agenceId = this.devis.agenceId;
  this.devis.statut = this.devishared.statut;
  this.devis.dModification = this.devishared.dModification; 
  this.devis.demandeClient = this.devishared.demandeClient;
  this.devis.destinataire = this.devishared.destinataire;

  if(this.devishared.tiers) {
    this.devis.raisonSociale = this.devishared.tiers.libelle;
    this.devis.typeTiers = this.devishared.tiers.typeTier;
    this.devis.adresse = this.devishared.tiers.adresse;
    this.devis.codePostal = this.devishared.tiers.codePostal;
    this.devis.ville = this.devishared.tiers.ville;
  }

  if(this.devishared.demande) {
    this.devis.idActivites = this.devishared.demande.prestationIds;
    this.devis.objet = this.devishared.demande.objet;
    this.devis.adresseChantier = this.devishared.demande.adresse;
    this.devis.complementAdresseChantier = this.devishared.demande.complementAdresse
    this.devis.codePostalChantier = this.devishared.demande.codePostalAdresse;
    this.devis.villeChantier = this.devishared.demande.villeAdresse;
    this.devis.commentaireChantier = this.devishared.demande.commentaireAdresse;
  }

  if (this.devishared.agence) {
    this.devis.agenceLibelle = this.devishared.agence.libelle;
    this.devis.agenceAdresse = this.devishared.agence.adresse;
    this.devis.complementAdresseAgence = this.devishared.agence.complementAdresse;
    this.devis.agenceCodePostal = this.devishared.agence.codePostal;
    this.devis.agenceVille = this.devishared.agence.ville;
    this.devis.sloganAgence = this.devishared.agence.slogan;
    this.devis.siretAgence = this.devishared.agence.siret;
    this.devis.nafAgence = this.devishared.agence.naf;
    this.devis.rcs = this.devishared.agence.rcs;
    this.devis.agenceEmail = this.devishared.agence.email;
    this.devis.agenceTelephone = this.devishared.agence.telephone;
    if (this.devishared.agence.societe) {
      this.devis.libelleSociete = this.devishared.agence.societe.libelle;
      this.devis.adresseSociete = this.devishared.agence.societe.adresse;
      this.devis.complementAdresseSociete = this.devishared.agence.societe.complementAdresse;
      this.devis.codePostalSociete = this.devishared.agence.societe.codePostal;
      this.devis.villeSociete = this.devishared.agence.societe.ville;
      this.devis.sirenSociete = this.devishared.agence.societe.siren;
    }
    if (this.devishared.agence.logo) {
      this.devis.logoAgence = this.devishared.agence.logo.url;
      this.devis.urlLogo = this.chemin + this.devishared.agence.logo.id; 
    }else {
      this.devis.urlLogo = ""; 
    }
    if (this.devishared.agence.certification1) {
      this.devis.certification1 = this.devishared.agence.certification1.url;
      this.devis.urlCertification1 = this.chemin + this.devishared.agence.certification1.id;
    }else {
      this.devis.urlCertification1 = ""; 
    }
    if (this.devishared.agence.certification2) {
      this.devis.certification2 = this.devishared.agence.certification2.url;
      this.devis.urlCertification2 = this.chemin + this.devishared.agence.certification2.id;
    }else {
      this.devis.urlCertification2 = ""; 
    }
  }
  
  if (this.devishared.interlocuteur) {
    this.devis.roleInterlocuteur = this.devishared.interlocuteur.role;
    this.devis.nomInterlocuteur = this.devishared.interlocuteur.nom;
    this.devis.prenomInterlocuteur = this.devishared.interlocuteur.prenom;
    this.devis.emailInterlocuteur = this.devishared.interlocuteur.email;
    this.devis.telInterlocuteur = this.devishared.interlocuteur.telPrincipal;
    this.devis.interlocuteurId = this.devishared.interlocuteur.id;
    this.devis.idJasonInterlocuteur = this.devishared.interlocuteur.idJason;
    this.devis.commentaireInterlocuteur = this.devishared.interlocuteur.commentaire;
    this.devis.telSecondaire = this.devishared.interlocuteur.telSecondaire;
    this.devis.civilite = this.devishared.interlocuteur.civilite;
    this.devis.civiliteId = this.devishared.interlocuteur.civiliteId;
  }
    
  if (this.devishared.realisateur) {
    this.devis.fonctionRealisateur = this.devishared.realisateur.type;
    this.devis.idRealisateur = this.devishared.realisateur.id;
    this.devis.nomRealisateur = this.devishared.realisateur.nom;
    this.devis.prenomRealisateur = this.devishared.realisateur.prenom;
    this.devis.emailRealisateur = this.devishared.realisateur.email;
    this.devis.telRealisateur = this.devishared.realisateur.tel;
    this.devis.signature = this.devishared.realisateur.signatureId ? (this.chemin + this.devishared.realisateur.signatureId) : "";
  }

  if(this.devishared.zone) {
    this.devis.zone = this.devishared.zone.libelle;
    this.devis.majoration = this.devishared.zone.majoration;
  }
  
  if(this.devishared.tva) {
    this.devis.tvaPercent = this.devishared.tva.taux;
    this.devis.tvaAmount = this.devishared.tva.montant; 
  }

  this.calculTotaux(this.devishared.prestations);
  this.devis.prestationsChantier = this.getLignesChantierFromPrestations(this.devishared.prestations);
  this.devis.prestationsClient = [this.getPrestationClientFromPrestations(this.devishared.prestations)];
}
  // convert prestation offres -> lignes sous lignes  
  getLignesChantierFromPrestations(prestations: PrestationShared[]) : TablePrestationChantierModel{
    let prestationClient = new TablePrestationChantierModel();
    prestationClient.agenceId = this.devis.agenceId;
    let ht = 0;
    let tva = 0;
    for (let i = 0; i < prestations.length; i++) {
      let ligne= new LigneChantier();
      ligne.libelle = prestations[i].prestation.libelle;
      let offre = prestations[i].offre;
      let sousLigne= new SousLigneChantier();
      sousLigne.libelleActivite = offre.libelle;
      sousLigne.prix = offre.pu;
      sousLigne.qte = offre.qte;
      sousLigne.tva = offre.tva;
      ligne.orders.push(sousLigne);
      tva = offre.tva;
      ht += offre.pu * offre.qte; 
      prestationClient.lignes.push(ligne);
    }
    prestationClient.tauxTva = tva;
    prestationClient.ht = ht;
    prestationClient.ttc = ht + ((ht * tva) / 100);
    return prestationClient;
  }
  // convert prestation offres -> lignes sous lignes  
  getPrestationClientFromPrestations(prestations: PrestationShared[]) : TablePrestationClientModel{
    let prestationClient = new TablePrestationClientModel();
    prestationClient.agenceId = this.devis.agenceId;
    prestationClient.titre = "Cout client principal"
    let ht = 0;
    let tva = 0;
    let prixDeBase = 0;
    let prixHT =0;
    for (let i = 0; i < prestations.length; i++) {
      let offre = prestations[i].offre;
      let ligne = new LigneClient();
      ligne.libelle = offre.libelle;
      ligne.prix = offre.pu;
      ligne.qte = offre.qte;
      ligne.unite = offre.unite;
      prestationClient.lignes.push(ligne);
      tva = offre.tva;        
      prixDeBase += offre.pu;
      prixHT = prixDeBase;
      if(offre.type == 'Multiplicatif')
      {
        prixHT += prixDeBase + ((prixDeBase*offre.coefficient)-prixDeBase);
      }
      ht += prixHT;
    }
    prestationClient.tva = tva;
    prestationClient.ht = Math.round(ht * 100) / 100;
    prestationClient.ttc = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;

    return prestationClient;
  }

  calculTotaux(prestations: PrestationShared[]){
    let tva = 0;
    let ht = 0;
    var prixBase : number = 0;
    var  prixHT : number = 0;
    for (let i = 0; i < prestations.length; i++) {
      let offre = prestations[i].offre;      
      prixBase += offre.pu;
      prixHT = prixBase;        
      if(offre.type == 'Multiplicatif')
      {
        prixHT += prixBase+((prixBase * offre.coefficient)-prixBase);
      }
        ht += prixHT;        
    }
    this.devis.ht = Math.round(ht * 100) / 100;
    this.tva = (this.devis.ht * this.devis.tvaPercent) / 100
    this.devis.ttc = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;
  }
  
  /* Bouton [Validation manuelle] :
  > Ouvre un popup donnant des actions remplaçant les actions clients > permet de mettre à jour le statut du devis / demande immédiatement par téléphone
  > La demande ainsi que le devis sont enregistrés
  > Le devis est automatiquement à l’état “Transmis”
  > La page du devis créé s’ouvre
  */
  validationManuelle(){
    const dialogConfirmation = this.dialog.open(ModalValidationManuelleComponent, {
      data: {}
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if (res) {
        // create devis et change statut and sens email
      }
    });
  }

  /* Bouton [Annuler] :
  > L’encart Devis est remplacé par le bouton [Devis rapide]
  > Toutes les étiquettes tarifaires cochées sont supprimées
  > Ni le devis ni la demande ne sont enregistrées
  */
  annuler(){
    this.annule.emit(false);
  }
  

  /* Bouton [Envoyer] :
  > La demande ainsi que le devis sont enregistrés
  > Envoi un mail structuré avec les informations relative au devis, le devis en pdf ainsi qu’un système d’acceptation et de signature électronique
  > Le devis est automatiquement à l’état “Transmis”
  > La page du devis créé s’ouvre
  */
  opendialogrelance():void {
    this.dialog.open(ModalEnvoiDevisComponent, {
      disableClose: true,
      data:{
        devis: this.devisDTO
      }
    }).afterClosed().subscribe((sending: number) => {
      const dialogRef = this.dialog.open(ModalSpinnerComponent);
      if (sending !== 0 && sending !== 3) {
        const checkImgs = forkJoin({
          imgActivite: this.devis.urlActivite ? this.documentService.getDoc(this.devis.urlActivite).pipe(catchError(e => of(null))) : of(null),
          logo: this.devis.urlLogo ? this.documentService.getDoc(this.devis.urlLogo).pipe(catchError(e => of(null))) : of(null),
          certif1: this.devis.urlCertification1 ? this.documentService.getDoc(this.devis.urlCertification1).pipe(catchError(e => of(null))) : of(null),
          certif2: this.devis.urlCertification2 ? this.documentService.getDoc(this.devis.urlCertification2).pipe(catchError(e => of(null))) : of(null),
          signature: (this.devis.signature != null && this.devis.signature != "") ? this.documentService.getDoc(this.devis.signature).pipe(catchError(e => of(null))) : of(null)
        });

        if (sending === 2) {
          this.devis.sendAttestationTva = true;
          this.devis.sendDevis = true;
        } else if (sending === 1) {
          this.devis.sendDevis = true;
        }
        const draftGmail = new GmailDTO();
        draftGmail.idDevis = this.devis.idDevis;
        draftGmail.sendTva = this.devis.sendAttestationTva;
        if (this.devis.documents && this.devis.documents.length > 0) {
          this.genDraft(draftGmail, dialogRef);
        } else {
          let err = false;
          checkImgs.subscribe((valImgs) => {
            if (!this.devis.urlActivite) {
              this.IntercomToastrService.warning("Image d’activité non configurée, l’email ne peut pas être envoyé.", null, {timeOut: 10000});
              err = true;
            } else if (this.devis.urlActivite && !valImgs.imgActivite) {
              this.IntercomToastrService.error("Erreur dans la récupération de l’image d’activité, l’email ne peut pas être envoyé.", null, {timeOut: 10000});
              err = true;
            } if (this.devis.urlLogo && !valImgs.logo) {
              this.IntercomToastrService.error("Erreur dans la récupération du logo d’agence, l’email ne peut pas être envoyé.", null, {timeOut: 10000});
              err = true;
            } if (this.devis.urlCertification1 && !valImgs.certif1) {
              this.IntercomToastrService.error("Erreur dans la récupération de la première certification, l’email ne peut pas être envoyé.", null, {timeOut: 10000});
              err = true;
            } if (this.devis.urlCertification2 && !valImgs.certif2) {
              this.IntercomToastrService.error("Erreur dans la récupération de la seconde certification, l’email ne peut pas être envoyé.", null, {timeOut: 10000});
              err = true;
            } if (this.devis.signature && !valImgs.signature) {
              this.IntercomToastrService.error("Erreur dans la récupération de la signature, l’email ne peut pas être envoyé.", null, {timeOut: 10000});
              err = true;
            } if (!err) {
              this.pdfService.getFileDevisRapide(this.devis).subscribe(file => {
                if (file) {
                  this.devisService.sendDocument("Devis", this.devis.idDevis, file).subscribe(_ => {
                    this.genDraft(draftGmail, dialogRef);
                  }, error => {
                    dialogRef.close();
                    this.IntercomToastrService.error("Erreur dans l'enregistrement du Devis.", null, {timeOut: 10000});
                  });
                } else {
                  dialogRef.close();
                  this.IntercomToastrService.error("Erreur dans la génération du Devis.", null, {timeOut: 10000});
                }
              }, error => {
                dialogRef.close();
                this.IntercomToastrService.error("Erreur dans la génération du devis.", null, {timeOut: 10000});
              });
            } else {
              dialogRef.close();
            }
          });
        }  
      } else {
        dialogRef.close();
      }
    });
  }

  genDraft(draftGmail: GmailDTO, dialogRef: MatDialogRef<ModalSpinnerComponent, any>) {
    this.devisService.generateDraft(draftGmail).subscribe((draftId: string) => {
      dialogRef.close();
      const dialogRef2 = this.dialog.open(ModalEnvoiDevisCoreComponent, {
        disableClose: true
      });
      return dialogRef2.afterClosed().subscribe(res => {
        if(res) {
          this.session.currentUser.subscribe(user => {
            console.log("https://mail.google.com/mail/u/" + user.email + "/#drafts?compose=" + encodeURI(draftId));
            window.open("https://mail.google.com/mail/u/" + user.email + "/#drafts?compose=" + encodeURI(draftId));
          }, error => {
            this.IntercomToastrService.error("Erreur dans la récupération de l’utilisateur courant.", null, {timeOut: 10000});
          });
          
        }
        
        this.devisService.get(draftGmail.idDevis).subscribe((devis: DevisDTO) => {
          this.devis = devis;
          this.devisDTO = devis;
          this.updateDevis.emit(this.devisDTO);
        });
      });
    }, error => {
      dialogRef.close();
      this.IntercomToastrService.error("Erreur dans la génération du mail. Veuillez réessayer ultérieurement.", null, {timeOut: 10000});
    });
  }


  opendialogvalider():void {
    this.dialog.open(ModalImportDevisComponent, {disableClose: true}).afterClosed().subscribe((savedFiles: {devisFile: File, tvaFile: File, noDoc: boolean}) => {
      if (savedFiles && savedFiles.devisFile) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.devisService.sendDocument("Devis Signé", this.devis.idDevis, savedFiles.devisFile).pipe(switchMap((res) => {
          if (res) {
            this.toastr.success('Le fichier : Devis à bien été enregistré.');
          } else {
            this.toastr.warning('Le fichier : Devis n\'a pas pu être enregistré');
          }
          if (savedFiles.tvaFile) {
            return this.devisService.sendDocument("TVA Signé", this.devis.idDevis, savedFiles.tvaFile).pipe(tap((res) => {
              if (res) {
                this.toastr.success('Le fichier : TVA à bien été enregistré.');
              } else {
                this.toastr.warning('Le fichier : TVA n\'a pas pu être enregistré');
              }
            }));
          } else {
            return of(true);
          }
        })).subscribe(_ => {
          this.acceptDevis(dialogRef);
        }, _ => dialogRef.close());
      } else if (savedFiles && savedFiles.noDoc) {
        const dialogRef = this.dialog.open(ModalSpinnerComponent);
        this.acceptDevis(dialogRef);
      }
    });
  }

  acceptDevis(dialogRef: MatDialogRef<ModalSpinnerComponent, any>) {
    this.devis.statut = "Validé";
    this.statutValue = "Validé";
    this.devis.statutNum = 5;
    this.devisService.put(this.devis).subscribe((res: DevisDTO) => {
      this.devis = res;
      this.updateDevis.emit(this.devis);
      dialogRef.close();
    }, _ => dialogRef.close());
  }

  openDupliquer(): void {
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    this.closeDemande.emit(true);
    this.priseAppelService.clearService();
    this.priseAppelService.updateDevis(this.devisDTO);
    // redirect to prise d'appel
    dialogRef.close();
    this.router.navigate(['prise-appel'], { queryParams: { tabIndex: ONGLETSPRISEAPPEL.DEVIS} });  
  }

  openModifier(): void {
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    if(this.devis.statut === "Transmis" || this.devis.statut === "À envoyer"){
      // annulation du devis actuel
      this.devis.motif = "Nouvelle version du devis";
      this.statutValue = "Annulé";
      this.devis.statut = "Annulé";
      this.devis.statutNum = 4;
      this.devisService.put(this.devis).subscribe((res: DevisDTO) => {
        this.devis = res;
        this.devishared.demande.statut = "Annulé";
        // Annulation de la demande
        this.demandeService.put(this.devishared.demande).subscribe((res: DevisDTO) => {
          this.closeDemande.emit(true);
          this.priseAppelService.clearService();
          this.priseAppelService.updateDevis(this.devisDTO);
          // redirect to prise d'appel
          dialogRef.close();
          this.router.navigate(['prise-appel'], { queryParams: { tabIndex: ONGLETSPRISEAPPEL.DEVIS} });
        });
      });
    }else{
      this.closeDemande.emit(true);
      this.devishared.demande.statut = "Annulé";
      // Annulation de la demande
      this.demandeService.put(this.devishared.demande).subscribe((res: DevisDTO) => {
        this.closeDemande.emit(true);
        this.priseAppelService.clearService();
        this.priseAppelService.updateDevis(this.devisDTO);
        // redirect to prise d'appel
        dialogRef.close();
        this.router.navigate(['prise-appel'],{ queryParams: { tabIndex: ONGLETSPRISEAPPEL.DEVIS} });
      });
    }
  }

  openRefuser(): void {
    const dialogRef = this.dialog.open(ModalRefusDevisComponent, 
      {data: {title: 'Confirmez-vous le refus du devis rapide ?', motif: 'Motif de refus', list: this.listRaisonRefus}}
      );
    dialogRef.afterClosed().subscribe((result) => {
         if (result){
          const dialogRef = this.dialog.open(ModalSpinnerComponent);
           this.devis.motif = result;
           this.statutValue = "Refusé";
           this.devis.statut = "Refusé";
           this.devis.statutNum = 3;
           this.devisService.put(this.devis).subscribe((res: DevisDTO) => {
            this.devis = res;
            dialogRef.close();
          });
        }
    });
  }

  openAnnuler(): void {
    const dialogRef = this.dialog.open(ModalRefusDevisComponent, 
      {data: {title: 'Confirmez-vous l\'annulation du devis rapide ?', motif: 'Motif d\'annulation', list: this.listRaisonAnnulation}});
    dialogRef.afterClosed().subscribe((result) => {
          if (result){
            const dialogRef = this.dialog.open(ModalSpinnerComponent);
            this.devis.motif = result;
            this.statutValue = "Annulé";
            this.devis.statut = "Annulé";
            this.devis.statutNum = 4;
            this.devisService.put(this.devis).subscribe((res: DevisDTO) => {
              this.devis = res;
              dialogRef.close();
            });
          }
    });
  }
}
