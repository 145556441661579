import { DemandeDTO } from './dtos/demandeDTO';
import { ActionDTO } from './dtos/actionDTO';
import { TierModel } from './models/tier/tier.model';
import { Injectable } from "@angular/core";

export class ModalsMenuInfo {
    public didSearch: boolean;
    public modalName: string;
    public modalFields: any;
    public tierFields: TierModel;
    public fieldsToString: string;
    toString(): string {
        return 'Recherche ' + this.modalName + ' : ' + this.fieldsToString;
    }
}

@Injectable()
export class ModalsTierInfos extends ModalsMenuInfo {
    public selectedOption: string;
  }

export class ModalsActionInfos extends ModalsMenuInfo  {
    public modalFields: ActionDTO;
    public selectedOption: string;
    public selectedTierLibelle = 'Aucun';
    public actionId?: number = null;
}


export class ModalsDemandeInfos extends ModalsMenuInfo {
    public modalFields: DemandeDTO;
    public selectedTierLibelle = 'Aucun';
    public selectedOption: string;
    public demandeId?: number = null;

}

export class PropertiesInfo {
    public libelle: string;
    public property: string;
}
