<div class="col-sm-12">
  <h1>Modification utilisateur</h1>
</div>
<div class="col-sm-12">
  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Nom</mat-label>
      <input matInput placeholder="Ex: Dupont" [(ngModel)]="user.nom">
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Prénom</mat-label>
      <input matInput placeholder="Ex: Jean-Baptiste" [(ngModel)]="user.prenom">
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <app-input-telephone [(phoneNumber)]="user.tel" libelle="Téléphone"></app-input-telephone>
  </div>
</div>

<div class="col-sm-12">

  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Jean-Baptiste.Dupont@veolia.com" [(ngModel)]="user.email">
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field floatLabel="always" appearance="none" class="full-width">
      <mat-label>{{user.actif?"Utilisateur Activé": "Utilisateur désactivé"}}</mat-label>
      <mat-slide-toggle [(ngModel)]="user.actif">
        <span class="text-invisible">Override Speed</span>
      </mat-slide-toggle>
      <textarea matInput hidden></textarea>
    </mat-form-field>
  </div>
  
  <div class="col-sm-4">
    <app-autocomplete-agence [libelle]="'Agence de l\'utilisateur'" [agences]="agences" [agenceId]="user.agenceId" (agenceChange)="selectedAgenceChanged($event)" ></app-autocomplete-agence>
  </div>
</div>  
<div class="col-sm-12">
  <div class="col-sm-4">
    <button mat-flat-button  class="full-width" color="primary" (click)="addDocument('Photo')">Photo</button>
  </div>
  <div class="col-sm-4">
    <button mat-flat-button class="full-width" color="primary" (click)="addDocument('Signature')">Signature</button>
  </div>
  <div class="col-sm-4 text-center">
    <button mat-flat-button color="success" (click)="saveUser()">Enregistrer</button>
  </div>
</div>
<div class="col-sm-12">
  <h2>Affectation de droits</h2>
  <div class="col-sm-6">
    <mat-form-field class="full-width">
      <mat-label>Niveau de droit</mat-label>
      <mat-select [(ngModel)]=this.niveauDroitSelected (selectionChange)="onDroitSelectedChanged($event)"> 
        <mat-option [value]="type.niveau" *ngFor="let type of types">
            {{type.libelle}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4>Entités</h4>

    <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl" class="example-tree">
      <ul> <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node">
            <button mat-icon-button disabled></button>
            {{node.libelle}}
            <button mat-icon-button (click)="addDroit(node)"><mat-icon>add</mat-icon></button>
          </li>
        </mat-tree-node>
      </ul>
    
      <ul>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <li>
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.libelle">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.libelle}}
              <button mat-icon-button color="success" (click)="addDroit(node)"><mat-icon>add</mat-icon></button>
            </div>
    
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </ul>
    </mat-tree>
  </div>
  <div class="col-sm-6">
    <h4>Liste des droits utilisateur</h4>
    <table mat-table [dataSource]="dataSource">
      <caption></caption>

      <ng-container matColumnDef="RSA">
        <th mat-header-cell *matHeaderCellDef> Entité </th>
        <td mat-cell *matCellDef="let element"> {{element.RSA}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Code">
        <th mat-header-cell *matHeaderCellDef> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Type de Droit </th>
        <td mat-cell *matCellDef="let element"> {{element.niveau}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell class="action-btns" *matCellDef="let element">
          <div class="btn-container center">
            <button mat-mini-fab class="tableau-btn" (click)="deleteDroit(element)" color="warn">
              <mat-icon class="tableau-mat-icon">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
