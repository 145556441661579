
<div class="col-sm-12 body-class">
  <mat-form-field class="full-width">
    <mat-label>Contact</mat-label>
    <input
      type="text"
      placeholder="Sélectionner l'interlocuteur"
      aria-label="Interlocuteur"
      matInput
      [formControl]="interlocuteurControl"
      (change)="onFullAutoInterlocutor($event.target.value)"
      (keydown.Tab)="onFullAutoInterlocutor($event.target.value)"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="interlocuteurChange($event)">
      <mat-option
        *ngFor="let interloc of filteredInterlocuteur | async"
        [value]="interloc" [matTooltip]="interloc">
        {{ interloc }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>


<div *ngIf="interlocuteur" class="col-sm-6 element-left">
  <mat-form-field class="full-width">
    <mat-label>Civilité</mat-label>
    <input
      type="text"
      placeholder="Sélectionner la civilité"
      aria-label="Civilité"
      matInput
      [formControl]="civiliteControl"
      (change)="civiliteChange($event.target.value)"
      (keydown.Tab)="civiliteChange($event.target.value)"
      [matAutocomplete]="auto1"
      [(ngModel)]="civiliteDemande"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto1="matAutocomplete"
      (optionSelected)="civiliteChange($event.option.viewValue)">
      <mat-option
        *ngFor="let civilite of filteredCivilite | async"
        [value]="civilite">
        {{ civilite }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<div class="col-sm-6 element-right" *ngIf="printChips && interlocuteur">
  <mat-chip-list>
    <mat-chip *ngIf="libelleChips === 'Création'" style="background-color: rgb(51, 182, 121); color: white;" matTooltip="Vous êtes entrain de créer un interlocuteur">{{libelleChips}}</mat-chip>
    <mat-chip *ngIf="libelleChips === 'Modification'" style="background-color: #005ea8; color: white;" matTooltip="Vous êtes entrain de modifier un interlocuteur">{{libelleChips}}</mat-chip>
  </mat-chip-list>
</div>

<div *ngIf="interlocuteur" class="col-sm-12 body-class">
  <div class="col-sm-6 element-left">
    <mat-form-field class="full-width">
      <mat-label>Nom</mat-label>
      <input
        required
        matInput
        type="text"
        (change)="selectedInterlocuteur.emit(this.interlocuteur)"
        placeholder="Nom"
        [(ngModel)]="interlocuteur.nom"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-6 element-right">
    <mat-form-field class="full-width">
      <mat-label>Prénom</mat-label>
      <input
        matInput
        (change)="selectedInterlocuteur.emit(this.interlocuteur)"
        type="text"
        placeholder="Prénom"
        [(ngModel)]="interlocuteur.prenom"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-6 element-left">
    <mat-form-field class="full-width">
      <mat-label>E-mail</mat-label>
      <input
        matInput
        (change)="selectedInterlocuteur.emit(this.interlocuteur)"
        type="text"
        placeholder="E-mail"
        [(ngModel)]="interlocuteur.email"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-6 element-right">
    <mat-form-field class="full-width">
      <mat-label>Fonction</mat-label>
      <input
        required
        (change)="selectedInterlocuteur.emit(this.interlocuteur)"
        type="text"
        matInput
        placeholder="Fonction"
        [(ngModel)]="interlocuteur.role"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-6 element-left">
    <app-input-telephone
      [(phoneNumber)]="interlocuteur.telPrincipal"
      (change)="selectedInterlocuteur.emit(this.interlocuteur)"
      libelle="Téléphone principal"
    ></app-input-telephone>
  </div>
  <div class="col-sm-6 element-right">
    <app-input-telephone
      [(phoneNumber)]="interlocuteur.telSecondaire"
      (change)="selectedInterlocuteur.emit(this.interlocuteur)"
      libelle="Téléphone secondaire"
    ></app-input-telephone>
  </div>
</div>
