import { Injectable } from "@angular/core";
import { TierModel } from "../models/tier/tier.model";


@Injectable()
export class TierPgResult {
    public id : number;
    public similarity: number;
    public smlInter : number;
    public smlAd : number;
    public countAd : number;
    public countInter : number;
    public countPhoneAd : number;
    public countPhoneInter: number;
    public countDemande: number;
    public tier: TierModel;
    public typeTier: string;
    public libelle: string;
    public tva: string;
    public identifiantJason: string;
    public bloque: boolean;

}