import { BasicMock } from '../basic-mock/basic.mock';
import { CommercialModel } from '../../models/users/commercial.model';
import { Injectable } from "@angular/core";

@Injectable()
export class CommercialMock extends BasicMock  {
  constructor() {
    super();
    (<CommercialModel[]> this.objets) = [];
  }
}
