<div style="display: flex;  justify-content: space-around;">
  <h4>Affiché</h4>
  <div style="display: flex;  justify-content: center; align-items: center;" > 
    <h4>Caché</h4>
    <button mat-raised-button color="default"><mat-icon matTooltip="Réinitialiser les colonnes" (click)="resetColumns(); $event.stopPropagation()">settings_backup_restore</mat-icon></button>
  </div>
</div>
<div style="display: flex;">
  <div
      cdkDropList
      #libelleColumnsList="cdkDropList"
      [cdkDropListData]="libelleColumns"
      [cdkDropListConnectedTo]="[hiddenList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box selected" *ngFor="let item of libelleColumns" cdkDrag>{{item}}</div>
  </div>
  <div
    cdkDropList
    #hiddenList="cdkDropList"
    [cdkDropListData]="hiddenColumns"
    [cdkDropListConnectedTo]="[libelleColumnsList]"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of hiddenColumns" cdkDrag>{{item}}</div>
  </div>
</div>