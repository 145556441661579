import { TierModel } from './tier.model';

export class HlmModel extends TierModel {
  public raisonSociale = '';
  public siretSiren = '';
  public marqueEnseigne = '';
  public typeTier = 'Hlm';

  constructor(tiers?: TierModel) {
    super(tiers);
    if (tiers) {
      this.raisonSociale = tiers.libelle;
    }
  }
}
