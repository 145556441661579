import { BasicMock } from '../basic-mock/basic.mock';
import { ParticulierModel } from '../../models/tier/particulier.model';
import { Injectable } from "@angular/core";

@Injectable()
export class ParticulierMock extends BasicMock  {
  constructor() {
    super();
    (<ParticulierModel[]> this.objets) = [
      /*{
        id: 9,
        clientNumber: 'C00191747', nom: 'STEFANI', prenom: 'Cedric', civiliteId: '0', commercialId: 1,
        agenceId: 1, identifiantJason: '221827', proprietaire: true,
        natureId: 5, typeTier: 'Particulier', libelle: 'Mr STEFANI Cedric',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 10,
        clientNumber: 'C00191721', nom: 'BONICHON', prenom: 'JEAN-JACQUES', civiliteId: '0', commercialId: 2,
        agenceId: 1, identifiantJason: '221806', proprietaire: true,
        natureId: 5, typeTier: 'Particulier', libelle: 'Mr BONICHON JEAN-JACQUES',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 11,
        clientNumber: 'C00191717', nom: 'ROBIN', prenom: 'JEROME', civiliteId: '0', commercialId: 1,
        agenceId: 1, identifiantJason: '221803', proprietaire: true,
        natureId: 5, typeTier: 'Particulier', libelle: 'Mr ROBIN JEROME',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 12,
        clientNumber: 'C00191716', nom: 'MIERMONT', prenom: 'CHRISTINE', civiliteId: '1', commercialId: 1,
        agenceId: 1, identifiantJason: '221802', proprietaire: true,
        natureId: 5, typeTier: 'Particulier', libelle: 'Mme MIERMONT CHRISTINE',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      }*/
    ];
  }

  getFromObjet(objetRecherche: ParticulierModel): ParticulierModel[] {
    return Object.assign([], this.objets.filter((objTab: ParticulierModel) => this.sameTier(objTab, objetRecherche)));
  }

  post(obj: ParticulierModel): ParticulierModel {
    obj.libelle = obj.nom + ' ' + obj.prenom;
    return <ParticulierModel>super.post(obj);
  }

  put(id, obj: ParticulierModel): ParticulierModel {
    obj.libelle = obj.nom + ' ' + obj.prenom;
    return <ParticulierModel>super.put(id, obj);
  }

  sameTier(objTab: ParticulierModel, objRecherche: ParticulierModel): boolean {
    if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
      return false;
    } else if (objRecherche.typeTier !== undefined && objRecherche.typeTier.length !== 0 &&
       objTab.typeTier.localeCompare(objRecherche.typeTier) !== 0) {
      return false;
    } else if (objRecherche.agenceId !== undefined && objRecherche.agenceId !== 0 && objTab.agenceId === objRecherche.agenceId) {
        return false;
    } else if (objRecherche.commercialId !== undefined && objRecherche.commercialId !== 0 &&
       objTab.commercialId === objRecherche.commercialId) {
      return false;
    } else if (objRecherche.identifiantJason !== undefined  && objRecherche.identifiantJason.length !== 0 &&
       objTab.identifiantJason.toLowerCase().indexOf(objRecherche.identifiantJason.toLowerCase()) === -1) {
      return false;
    } else if (objRecherche.natureId !== undefined && objRecherche.natureId !== 0 && objTab.natureId === objRecherche.natureId) {
      return false;
    } else if (objRecherche.clientNumber !== undefined && objRecherche.clientNumber.length !== 0 &&
       objTab.clientNumber.toLowerCase().indexOf(objRecherche.clientNumber.toLowerCase()) === -1) {
      return false;
    }
    return this.sameParticulier(objTab, objRecherche);
  }

  sameParticulier(objTab: ParticulierModel, objRecherche: ParticulierModel): boolean {
    if (objRecherche.proprietaire !== undefined && !objTab.proprietaire === objRecherche.proprietaire) {
      return false;
    } else if (objRecherche.nom !== undefined && objRecherche.nom.length !== 0 &&
       objTab.nom.toLowerCase().indexOf(objRecherche.nom.toLowerCase()) === -1) {
      return false;
    } else if (objRecherche.prenom !== undefined && objRecherche.prenom.length !== 0 &&
       objTab.prenom.toLowerCase().indexOf(objRecherche.prenom.toLowerCase()) === -1) {
      return false;
    } else if (objRecherche.civiliteId !== undefined && objRecherche.civiliteId !== undefined &&
       objTab.civiliteId.indexOf(objRecherche.civiliteId) === -1) {
      return false;
    }
    return true;
  }

}
