import { SyndicDTO } from '../../shared/dtos/syndicDTO';
import { SyndicModel } from '../../shared/models/tier/syndic.model';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SiteGeoService } from '../site-geo.service';
import { UserService } from '../user/user.service';
import { AgenceService } from '../rsa/agence.service';
import { TierService } from './tier.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { VerifyFields } from '../../shared/verifyFields';
import { DocumentService } from '../document.service';

@Injectable()

export class SyndicService extends TierService {

  observer = new BehaviorSubject<SyndicModel>(null);
  public subscriber = this.observer.asObservable();
  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              siteGeoService: SiteGeoService,
              agenceService: AgenceService,
              userService: UserService,
              protected IntercomToastrService: IntercomToastrService,
              protected documentService: DocumentService) {
                super(http, apiService, sessionService, siteGeoService, agenceService, userService, IntercomToastrService, documentService);
                this.serviceUrl = apiService.ROUTESYNDIC;
  }

  get(objet: number): Observable<SyndicModel> {
    return <Observable<SyndicModel>> super.get(objet);
  }

  getAll(): Observable<SyndicModel[]> {
    return <Observable<SyndicModel[]>> super.getAll();
  }

  beforeSave(objet: SyndicModel): boolean {
    let success = true;
    if (!VerifyFields.verifyStringExist(objet.raisonSociale)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir une raison sociale.');
    }
    // if (!VerifyFields.verifyStringExist(objet.codePostal)) {
    //   success = false;
    //   this.IntercomToastrService.warning('Veuillez saisir un code postal.');
    // }
    if (!VerifyFields.verifyStringExist(objet.email) && !VerifyFields.verifyStringExist(objet.telephone)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir un email ou un numéro de téléphone.');
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    return success;
  }
  GetFromObjetWithPagination(objet: SimpleQuery<SyndicDTO>): Observable<SimpleQueryResponse<SyndicModel>> {
    return <Observable<SimpleQueryResponse<SyndicModel>>> super.GetFromObjetWithPagination(objet).pipe(tap((query: SimpleQueryResponse<SyndicModel>) => {
      for (const tier of query.objets) {
      }
    }));
  }
}
