<div>

  <div class="col-7">
    <h3>Fiche adresse</h3>
  </div>

  <div class="col-7">
    <mat-divider></mat-divider>
  </div>
  
  <div class="col-sm-12">
    <mat-form-field class="full-width">
      <mat-label>Adresse</mat-label>
      <input matInput required placeholder="" value="" name="intercom-adresse" [(ngModel)]="data.site.adresse" >
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Code postal</mat-label>
      <input required matInput placeholder="" value="" name="intercom-codepostal" [(ngModel)]="data.site.codePostal" >
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Ville</mat-label>
      <input required  matInput placeholder="" value="" name="intercom-ville" [(ngModel)]="data.site.ville" >
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Libellé</mat-label>
      <input required  matInput placeholder="Entrepôt, Retaurant, Cave ..." value="" name="intercom-libelle-adresse"  [(ngModel)]="data.site.libelle" >
    </mat-form-field>
  </div>

  
  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Complément d'adresse</mat-label>
      <input matInput placeholder="" value="" name="intercom-complement-adresse"  [(ngModel)]="data.site.complementAdresse" >
    </mat-form-field>
  </div>

  <div class="col-sm-4">
    <app-input-telephone [(phoneNumber)]="data.site.telephone" libelle="Téléphone Secondaire">
    </app-input-telephone>
  </div>
  
  <div class="col-sm-4">
    <mat-form-field class="full-width">
      <mat-label>Email</mat-label>
      <input matInput placeholder="" value="" [(ngModel)]="data.site.email" >
    </mat-form-field>
  </div>
  
  <div class="col-7">
    <mat-divider></mat-divider>
  </div>

  <div class="col-sm-6">
    <mat-form-field class="full-width">
      <mat-label>Commentaire</mat-label>
      <textarea matInput placeholder="" rows="4" style="height: 90px;" value="" [(ngModel)]="data.site.commentaire" ></textarea>
    </mat-form-field>
  </div>
  
  <div class="col-sm-3" style="margin-top: 35px;">
    <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
  </div>

  <div class="col-sm-3" style="margin-top: 35px;">
    <button mat-flat-button color="success" (click)="onModalConfirm()">Enregistrer</button>
  </div>
</div>