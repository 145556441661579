import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './modules/shared/session.service';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sessionService: SessionService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {        
        this.sessionService.setStateUrl(decodeURI(state.url));
        if (!this.sessionService.isLoggedIn()) {
            // not logged in
            this.router.navigate(['/login']);
            return false;
          }
          return true;
      }
}
