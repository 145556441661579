import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { InterlocuteursComponent } from 'src/app/modules/core/components/interlocuteurs/interlocuteurs.component';
import { InterlocuteurService } from 'src/app/modules/core/services/interlocuteur.service';
import { TierService } from 'src/app/modules/core/services/tier/tier.service';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { InterlocuteurDTO } from 'src/app/modules/core/shared/dtos/interlocuteurDTO';
import { InterlocuteurPgResult } from 'src/app/modules/core/shared/dtos/interlocuteurPgResult';
import { InterlocuteurModel } from 'src/app/modules/core/shared/models/interlocuteur.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';

export interface DialogData {
  tier: TierModel;
}

@Component({
  selector: 'app-modal-select-contact',
  templateUrl: './modal-select-contact.component.html',
  styleUrls: ['./modal-select-contact.component.scss']
})
export class ModalSelectContactComponent implements OnInit {
  simpleQueryInterlocuteur: SimpleQuery<InterlocuteurDTO> = new SimpleQuery<InterlocuteurDTO>();
  simpleQueryResponse: SimpleQueryResponse<InterlocuteurPgResult>;
  @ViewChild(InterlocuteursComponent) interlocuteursComp: InterlocuteursComponent;
  interlocuteurs: InterlocuteurModel[] = [];
  panelOpenStateInterlocuteur = true;
  tier: TierModel = new TierModel();
  constructor(private tierService: TierService,
    public dialogRef: MatDialogRef<any>,
    private interlocuteurService: InterlocuteurService,
    private toastrLoadingService: ToastrLoadingService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
      this.simpleQueryInterlocuteur.entry = new InterlocuteurDTO();
      this.simpleQueryInterlocuteur.entry.TierId = this.data.tier.id + "";
    }

  ngOnInit(): void {
    this.tier = this.data.tier;

  }

  onAddInterlocuteur() {
    this.interlocuteursComp.onAddInter();
    this.panelOpenStateInterlocuteur = !this.panelOpenStateInterlocuteur;
  }

  onUpdateInterlocuteur(interloc: InterlocuteurModel[]) {
    this.interlocuteurs = Object.assign([], interloc);
    this.tierService.get(this.tier.id).subscribe((tier: TierModel) => {
      this.tier = tier;
    });
  }

  onSelectContact(contact: InterlocuteurModel) {
    this.dialogRef.close(contact);
  }

  rechercheContact(){
    let idSearch = this.toastrLoadingService.newSearch();
    this.interlocuteurService.GetFromObjetPgWithPagination(this.simpleQueryInterlocuteur).subscribe((queryResponse) => {
      this.simpleQueryResponse = queryResponse;
      this.toastrLoadingService.endSearch(idSearch);
    }, error => {
      this.toastrLoadingService.endSearch(idSearch);
    });
  }
  onModalCancel(): void {
    this.dialogRef.close(false);
  }
}
