import { map } from 'rxjs/operators';
import { ActionDTO } from './../shared/dtos/actionDTO';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { ActionModel } from '../shared/models/action.model';
import { Injectable } from '@angular/core';
import { ActionMock } from '../shared/mocks/action.mock';
import { Observable, forkJoin, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserModel } from '../shared/models/users/user.model';
import { TierBasicService } from './basic-service/tier-basic.service';
import { VerifyFields } from '../shared/verifyFields';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SimpleQuery } from '../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { SessionService } from '../../shared/session.service';
import { FiltreActionDTO } from '../shared/dtos/filterActionDTO';
import { DashboardDTO } from '../shared/dtos/dashboardDTO';
import { FiltrePerimetreDTO } from '../shared/dtos/filterPerimetreDTO';

@Injectable()
export class ActionService extends TierBasicService {

  constructor(private mock: ActionMock,
              http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              protected IntercomToastrService: IntercomToastrService) {
                super(http, apiService, sessionService);
                this.serviceUrl = apiService.ROUTEACTION;
  }

  loadAction(action: ActionModel) {
    // heureDebut
    // tslint:disable-next-line: max-line-length
    if (action.dateFin != null && action.dateFin !== undefined && action.dateFin.toString().localeCompare('0001-01-01T00:00:00') !== 0 && action.dateFin !== action.dateRealisation) {
      action.dateFin = new Date(action.dateFin);
    } else {
      action.dateFin = null;
    }
    action.dateCreation = new Date(action.dateCreation);
    action.dateRealisation = new Date(action.dateRealisation);
    this.setHeureDuree(action);
  }

  public setHeureDuree(action: ActionModel) {
    if (action.dateRealisation.getHours() === 0 && action.dateRealisation.getMinutes() === 0) {
      action.heureDebut = null;
      action.duree = undefined;
    } else {
      action.heureDebut = action.dateRealisation.getHours() + 'h';
      action.duree = '1h';
      if (action.dateRealisation.getMinutes() !== 0) {
        action.heureDebut += this.addZero(action.dateRealisation.getMinutes());
      }
    }
    // Duree
    if (action.dateFin !== null && action.dateFin !== undefined && action.dateFin.toISOString() !== action.dateRealisation.toISOString()) {
      let diff: number;
      diff = (action.dateFin.getTime() - action.dateRealisation.getTime() ) / 60000;
      action.duree = this.addZero(Math.trunc(diff / 60)) + 'h' + this.addZero(diff % 60);
    }
  }

  getFiltredAction(fitredto: FiltreActionDTO):  Observable<SimpleQueryResponse<ActionModel>>{
    return this.http.post<SimpleQueryResponse<ActionModel>>(this.apiUrl+ this.serviceUrl + 'getFiltredData/',fitredto);
  }

  getDashboard(filterPerimetre:FiltrePerimetreDTO):  Observable<DashboardDTO>{
    return this.http.post<DashboardDTO>(this.apiUrl+ this.serviceUrl + 'getDashboard/',filterPerimetre);
  }
  
  post(objet: ActionModel): Observable<ActionModel> {
    if (!this.beforeSave(objet)) {
      return throwError(new Error('sauvegarde impossible'));
    }
    if (!objet.agenceId) {
      objet.agenceId = this.sessionService.currentUserValue.agenceId;
    }
    return this.http.post<ActionModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
  }

  get(objet: number): Observable<ActionModel> {
    return super.get(objet).pipe(tap((action: ActionModel) => {
      this.loadAction(action);
    }));
  }

  getFromTier(tierId: number): Observable<ActionModel[]> {
    return super.getFromTier(tierId).pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        this.loadAction(action);
      }
    }));
  }

  getFromDemande(demandeId: number): Observable<ActionModel[]> {
    return this.http.get<ActionModel[]>(this.apiUrl + this.serviceUrl + 'GetFromDemande/' +
    demandeId, {headers: this.header}).pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        if (action.demandeId === demandeId) {
          this.loadAction(action);
        }
      }
    }));
  }

  getFromUsers(ids: number[]): Observable<ActionModel[]> {
    return this.http.post<ActionModel[]>(this.apiUrl + this.serviceUrl + 'GetFromUsers/', ids).pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        this.loadAction(action);
      }
    }));
  }

  getDashboardAFaire(users: UserModel[]): Observable<ActionModel[]> {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new ActionDTO();
      objetDemande.nonClos = true;
      objetDemande.realisateurId = user.id;
      //promiseArray.push(this.getFromObjet(objetDemande));
    }
    return forkJoin(promiseArray).pipe(map((listArray: ActionModel[]) => {
      let traiterArray: ActionModel[] = [];
      for (const array of listArray) {
        traiterArray = traiterArray.concat(array);
      }
      return traiterArray;
    }));
  }

  getDashboardNonLu(users: UserModel[]) {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new ActionDTO();
      objetDemande.statut = 'A traiter';
      objetDemande.realisateurId = user.id;
      //promiseArray.push(this.getFromObjet(objetDemande));
    }
    return forkJoin(promiseArray).pipe(map((listArray: ActionModel[]) => {
      let nonLuArray: ActionModel[] = [];
      for (const array of listArray) {
        nonLuArray = nonLuArray.concat(array);
      }
      return nonLuArray;
    }));
  }

  beforeSave(objet: ActionModel): boolean {
    let success = true;
    if (!VerifyFields.verifyStringExist(objet.objetAction)) {
      success = false;
      this.IntercomToastrService.warning('Veuillez saisir un objet correct.');
    }
    if (!super.beforeSave(objet)) {
      success = false;
    }
    return success;
  }

  getDashboardRetard(users: UserModel[]) {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new ActionDTO();
      objetDemande.nonClos = true;
      objetDemande.realisateurId = user.id;
      objetDemande.dateSmallerRealString = new Date(Date.now());
      objetDemande.dateSmallerRealString.setHours(0);
      objetDemande.dateSmallerRealString.setMinutes(0);
      objetDemande.dateSmallerRealString.setMilliseconds(0);
      //promiseArray.push(this.getFromObjet(objetDemande));
    }
    return forkJoin(promiseArray).pipe(map((listArray: ActionModel[]) => {
      let retardArray: ActionModel[] = [];
      for (const array of listArray) {
        retardArray = retardArray.concat(array);
      }
      return retardArray;
    }));
  }

  getfromUsersAll(users: UserModel[]): Observable<ActionModel[][]> {
    const actions: ActionModel[][] = [];
    for (const user of users) {
      actions.push(this.mock.getfromUserAll(user.id));
    }
    const obs = new Observable(ob => {
      ob.next(actions);
    });
    return obs.pipe(tap((usersActions: ActionModel[][]) => {
      for (const userAction of usersActions) {
        for (const action of userAction) {
          this.loadAction(action);
        }
      }
    }));
  }

  getfromUsersActive(users: UserModel[]): Observable<ActionModel[][]> {
    const obsActions: Observable<ActionModel[]>[] = [];

    for (const user of users) {
      obsActions.push(this.http.get<ActionModel[]>(this.apiUrl + this.serviceUrl + 'GetFromUser/' + user.id, {headers: this.header}));
    }
    return forkJoin(obsActions).pipe(tap((usersActions: ActionModel[][]) => {
      for (const userAction of usersActions) {
        for (const action of userAction) {
          this.loadAction(action);
        }
      }
    }));
  }

  getAll(): Observable<ActionModel[]> {
    return super.getAll().pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        this.loadAction(action);
      }
    }));
  }

  getActiveFromTier(tierId: number): Observable<ActionModel[]> {
    return super.getFromTier(tierId).pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        if (action.statut === 'En cours') { this.loadAction(action); }
      }
    }));
  }

  getCloseFromTier(tierId: number): Observable<ActionModel[]> {
    return super.getFromTier(tierId).pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        if (action.statut === 'Clos') { this.loadAction(action); }
      }
    }));
  }

  getAllActive(): Observable<ActionModel[]> {
    return super.getAll().pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        if (action.statut === 'En cours') { this.loadAction(action); }
      }
    }));
  }

  getAllClose(): Observable<ActionModel[]> {
    return super.getAll().pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        if (action.statut === 'Clos') { this.loadAction(action); }
      }
    }));
  }

  getFromObjet(objet: SimpleQuery<ActionDTO>): Observable<SimpleQueryResponse<ActionModel>> {
    // tslint:disable-next-line: max-line-length
    return <Observable<SimpleQueryResponse<ActionModel>>> super.getFromObjet(objet).pipe(tap((response: SimpleQueryResponse<ActionModel>) => {
      for (const action of response.objets) {
        this.loadAction(action);
      }
    }));
  }
  private addZero(i: number) {
    return (i < 10 ? '0' + i : i + '');
  }

  exportActions(filtre: FiltreActionDTO) {
    return this.http.post(this.apiUrl + this.serviceUrl + 'exportActions', filtre, {responseType: 'text'});
  }
}
