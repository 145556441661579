import { CiviliteService } from './../../services/civilite.service';
import { TierService } from './../../services/tier/tier.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ActionComponent } from './action.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SessionService } from 'src/app/modules/shared/session.service';
import { ActionService } from '../../services/action.service';
import { DemandeService } from '../../services/demande.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { TypeActionService } from '../../services/type-action.service';
import { UserService } from '../../services/user/user.service';
import { ActionModel } from '../../shared/models/action.model';
import { CiviliteModel } from '../../shared/models/civilite.model';
import { DemandeModel } from '../../shared/models/demande.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { TypeActionModel } from '../../shared/models/type-action.model';
import { UserModel } from '../../shared/models/users/user.model';
import { GchatService } from 'src/app/modules/shared/gapiServices/gChatApi.service';
import { DatePipe } from '@angular/common';
import { InterventionPxoModel } from '../../shared/models/interventionPxo.model';
import { error } from 'protractor';
import { HttpErrorResponse } from '@angular/common/http';

export interface DialogData {
  action: ActionModel;
  tierId: number;
  realisateurId: number;
  date: Date;
  heure: string;
  duree: string;
  demandeId: number;
  result: boolean;
  typeTitre: string;
  typeAction: number;
  satut: string;
}
@Component({
  selector: 'app-modal-ajout-action',
  templateUrl: './modal-ajout-action.component.html',
  styleUrls: ['./modal-ajout-action.component.scss']
})


export class ModalAjoutActionComponent implements OnInit {
  typeActions: TypeActionModel[] = [];
  typeAction: TypeActionModel;
  users: UserModel[] = [];
  user: UserModel;
  tiers: TierModel;
  demandes: DemandeModel[] = [];
  demande: DemandeModel;
  objetAuto = true;
  hasTiers = false;

  interlocuteurs: InterlocuteurModel[] = [];
  interlocuteur: InterlocuteurModel;
  interlocuteurId: number;

  initialUser: UserModel;

  civilites: CiviliteModel[] = [];
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private actionService: ActionService,
    private typeActionService: TypeActionService,
    private userService: UserService,
    private civiliteService: CiviliteService,
    private sessionService: SessionService,
    private demandeService: DemandeService,
    private toastr: IntercomToastrService,
    private tiersService: TierService,
    private interlocuteurService: InterlocuteurService,
    private gchatService: GchatService,
    private datePipe: DatePipe
    ) { 
  }

  typeChange() {
    this.typeAction = Object.assign({}, this.typeActions.find(ta => ta.id === this.data.action.typeActionId));
    this.initTitleAction();
  }

  demandeChange() {
    // Récupération de la demande sélectionnée dans l'action à créer.
    this.demande = Object.assign({}, this.demandes.find(d => d.id === this.data.action.demandeId));
    this.initTitleAction();
  }

  onUpdateRealisateurId(realisateurId: number) {
    if (realisateurId === null) {
      this.data.action.realisateurId = -1;
      this.data.action.agenceId = null;
    } else {
      this.data.action.realisateurId = realisateurId;
      this.data.realisateurId = realisateurId;
      this.user = this.users.find(usr => usr.id === this.data.action.realisateurId);
      this.data.action.agenceId = this.user.agenceId;
    }
    this.initTitleAction();
  }

  ngOnInit() {
    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
    });

    this.data.action = new ActionModel();
    this.data.action.statut = (this.data.satut && this.data.satut.length > 0) ? this.data.satut : 'A traiter';
    this.data.action.interlocuteurId = undefined;
    this.typeActionService.getAll().subscribe((ta: TypeActionModel[]) => {
      this.data.action.typeActionId = this.data.typeAction != undefined ? this.data.typeAction : 3;
      this.typeActions = this.data.action.typeActionId < 1000 ? ta.filter(act => act.id < 1000) : ta;
      this.data.action.typeAction = this.typeActions.find(x => x.id === this.data.action.typeActionId);
    });
    if (this.data !== undefined) {
      this.hasTiers = true;
      this.userService.getAll().subscribe((usrs: UserModel[]) => {
        this.user = usrs.find(usr => usr.id === this.data.realisateurId);
        this.users = usrs;
        this.initialUser = this.users.find(usr => usr.id === this.data.action.realisateurId);
        if (this.data.tierId) {
          this.tiersService.get(this.data.tierId).subscribe((tiers: TierModel) => {
            this.tiers = tiers;
            this.initTitleAction();
          });
          this.demandeService.getFromTier(this.data.tierId).subscribe((dems: DemandeModel[]) => this.demandes = dems);
          this.interlocuteurService.getFromTier(this.data.tierId).subscribe((interloc: InterlocuteurModel[]) => {
            this.interlocuteurs = interloc;
          });
        } else {
          this.hasTiers = false;
          if (this.data.action.typeActionId < 1000)
            this.data.action.typeActionId = 6;
          this.initTitleAction();
        }
      });
      this.data.action.realisateurId = this.data.realisateurId;
      this.data.action.demandeId = this.data.demandeId;
      if (this.data.date !== undefined) {
        this.data.action.dateRealisation = this.data.date;
      if (this.data.date.getHours() !== 0) {
        this.data.action.heureDebut = (this.data.date.getHours() < 10 ? "0" + this.data.date.getHours() : this.data.date.getHours()) + 'h' + (this.data.date.getMinutes() < 10 ? "0" + this.data.date.getMinutes()  : this.data.date.getMinutes());
        this.data.duree = '1h00';
      } else {
        this.data.heure = null;
        this.data.duree = null;
        }
      }
      // Récupération de la demande associée automatiquement à l'action que l'on souhaite créer. (en passant par la demande)
      if (this.data.action.demandeId) {
        this.demandeService.get(this.data.action.demandeId).subscribe((dem: DemandeModel) => {
          this.demande = dem;
        });
      }
    } else {
      this.hasTiers = false;
    }
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close([false, null]);
  }

  initTitleAction() {
    if (this.objetAuto) {
      if (this.data.typeAction === 1001) {
        this.data.action.objetAction = this.data.action.typeAction.libelle.charAt(0).toUpperCase() 
          + this.data.action.typeAction.libelle.slice(1) + ' - ' + this.demande.objet;
      } else {
        this.data.action.objetAction = 'Création devis : ';
        if (this.typeAction) {
          this.data.action.objetAction = this.typeAction.libelle ? this.typeAction.libelle + ' : ' : 'Création devis : ';
        }
        this.data.action.objetAction +=  this.tiers ? this.tiers.libelle + ' ' : ' ';
        this.data.action.objetAction +=  this.user.prenom ?
                                        'à réaliser par ' + this.user.nom + ' ' + this.user.prenom :
                                        'à réaliser par ' + this.user.nom;
      }
    }
  }

  /*Update demande if demande.dateintervention < action.daterealisation*/
  onUpdateDemande() {
    if (this.demande) {
      if (this.data.action.dateRealisation > this.demande.dateIntervention) {
        this.demande.dateIntervention = this.data.action.dateRealisation;
        this.data.action.demande = this.demande;
      }
      if (this.data.action.statut === 'A traiter' && this.demande.statut === 'Clos') {
        this.demande.statut = 'En cours';
        this.data.action.demande = this.demande;
      }
      this.demandeService.put(this.demande).subscribe((dem: DemandeModel) => {
        this.data.action.demande = dem;
      });
    }
  }

  getStatusPraxedo(interPxo: InterventionPxoModel) {
    if(interPxo.dAnnulePxo) {
      return "Annulée";
    } else if (interPxo.dValidPxo) {
      return "Validée";
    } else if (interPxo.dRealPxo) {
      return "Réalisée";
    } else if (interPxo.dDemarrePxo) {
      return "Démarrée";
    } else if (interPxo.dSynchroPxo) {
      return "Validé";
    } else if (interPxo.dStartPlanifPxo) {
      return "Planifié";
    } else if (interPxo.dEnvoiPxo) {
      return "Envoyé";
    } else {
      return "À envoyer";
    }
  }


  createAction() {
    if (this.data.action.typeActionId === 1001) {
      let card = this.gchatService.makeCard("Relance Intercom");
      this.gchatService.addToCard(card, "<b>Information de la demande : <a href=\"" + window.location.origin + "/fiche-contact/" + this.demande.tier.id.toString() + "?demande=" + this.demande.id.toString() + " \"> "  + this.demande.numDemande + "</a></b>", "" + 
        ("<b>Tiers donneur d'ordre</b> : " + this.demande.tier.libelle + "\n ") + 
        (this.demande.adresse ? ("<b>Adresse</b> : \n <a href=\"http://maps.google.com/?q=" + this.demande.adresse + " " + this.demande.villeAdresse + " " +  this.demande.codePostalAdresse + "\">" +  this.demande.adresse + " " + this.demande.villeAdresse + " " +  this.demande.codePostalAdresse + "</a> \n ") : "") +
        ("<b>Commentaire demande</b> : \n" + this.demande.commentaire + "\n ")
      );
      this.gchatService.addToCard(card, "<b>Contact interne " + "<a href=\"mailto:" + this.initialUser.email +"\">" + this.initialUser.email + "</a> à " + this.data.action.heureDebut + " le " + this.datePipe.transform(this.data.action.dateRealisation, "dd/MM/yyyy")+"</b>", "" + 
      "<b>Commentaire</b> : " + this.data.action.commentaire + "\n" +
      (this.data.action.nomInterlocuteur ? ( "<b>Contact externe </b> : \n" +  this.data.action.prenomInterlocuteur + " " + this.data.action.nomInterlocuteur + " : " +  
          (this.data.action.telPrincipalInterlocuteur ? this.data.action.telPrincipalInterlocuteur + "\n" : "" ) +
          (this.data.action.telSecondaireInterlocuteur ? this.data.action.telSecondaireInterlocuteur + "\n" : "" ) +  
          (this.data.action.emailInterlocuteur ? "<a href=\"mailto:" + this.data.action.emailInterlocuteur + "\"> " + this.data.action.emailInterlocuteur + "</a>\n" : "" )
      ) : ""));
      this.demande.interventionPxo ? (this.gchatService.addToCard(card, "<b>Status Praxedo : " + this.getStatusPraxedo(this.demande.interventionPxo) + "</b>\n", "" +
            (this.demande.interventionPxo.dEnvoiPxo ? "Date d\'envoi à Praxedo : " + this.datePipe.transform(this.demande.interventionPxo.dEnvoiPxo, "HH:mm") + " le  " + this.datePipe.transform(this.demande.interventionPxo.dEnvoiPxo, "dd/MM/yyyy") : "") + 
            (this.demande.interventionPxo.dStartPlanifPxo ? ("\nDate de planification : " + this.datePipe.transform(this.demande.interventionPxo.dStartPlanifPxo, "HH:mm") + " à " + this.datePipe.transform(this.demande.interventionPxo.dEndPlanifPxo, "HH:mm") + " le  " + this.datePipe.transform(this.demande.interventionPxo.dEndPlanifPxo, "dd/MM/yyyy")) : "\n") + 
            (this.demande.interventionPxo.dRealPxo ? "\nDate de réalisation : " + this.datePipe.transform(this.demande.interventionPxo.dRealPxo, "HH:mm") + " le  " + this.datePipe.transform(this.demande.interventionPxo.dRealPxo, "dd/MM/yyyy") : "") +
            (this.demande.interventionPxo.dAnnulePxo ? "\nDate d\'annulation : " + this.datePipe.transform(this.demande.interventionPxo.dAnnulePxo, "HH:mm") + " le  " + this.datePipe.transform(this.demande.interventionPxo.dAnnulePxo, "dd/MM/yyyy") : "")
      )):"";
      this.gchatService.sendCards(this.demande.agence.lienWebHook, [card]).subscribe(res => {
        this.toastr.success('La relance : ' + this.data.action.objetAction + ' à bien été envoyée à l’agence', 'GoogleSpace');
        this.actionService.post(this.data.action).subscribe((a: ActionModel) => {
          this.toastr.success('', 'La ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' a bien été créée');
          this.actionService.get(a.id).subscribe(res => {
            this.data.action = res;
            this.dialogRef.close([true, res]);
          });
        },(err) => {
          this.toastr.error('', 'Erreur lors de la création de la ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' ' + err.message);
          this.actionService.post(this.data.action).subscribe((a: ActionModel) => {
            this.toastr.success('', 'La ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' a bien été créée');
            this.actionService.get(a.id).subscribe(res => {
              this.data.action = res;
              this.dialogRef.close([true, res]);
            });
          },(err) => {
            this.toastr.error('', 'Erreur lors de la création de la ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' ' + err.message);
          });
        });
      },(error: HttpErrorResponse) => {
        this.toastr.error("Erreur dans l’envoie de la relance " + this.data.action.objetAction + " à l’agence.");
        this.data.action.objetAction = "Erreur dans l’envoie de la relance";
        this.data.action.commentaire += "\n Erreur dans l’envoie de la relance \n" + error.message;
        this.actionService.post(this.data.action).subscribe((a: ActionModel) => {
          this.toastr.success('', 'La ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' a bien été créée');
          this.actionService.get(a.id).subscribe(res => {
            this.data.action = res;
            this.dialogRef.close([true, res]);
          });
        },(err) => {
          this.toastr.error('', 'Erreur lors de la création de la ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' ' + err.message);
        });
      });
    }
    else {
      this.actionService.post(this.data.action).subscribe((a: ActionModel) => {
        this.toastr.success('', 'La ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' a bien été créée');
        this.actionService.get(a.id).subscribe(res => {
          this.data.action = res;
          this.dialogRef.close([true, res]);
        });
      },(err) => {
        this.toastr.error('', 'Erreur lors de la création de la ' + (this.data.action.typeActionId === 1001 ? 'relance' : 'tâche') + ' : ' + this.data.action.objetAction + ' ' + err.message);
      });
    }
  }

  onModalConfirm(): void {
    this.data.result = true;
    this.data.action.tierId = this.data.tierId;
    this.data.action.createurId = this.sessionService.currentUserValue.id;
    if (this.data.action.dateRealisation !== null) {
      this.data.action.dateRealisation.setHours(0, 0, 0, 0);
      // Controle heure + durée
      const regExH = RegExp('^[0-9]{1,2}[h][0-9]{0,2}$');
      const regExD = RegExp('^[0-9]{1,2}[h][0-9]{0,2}$');
      if (this.data.action.heureDebut != null && this.data.action.heureDebut !== '' && this.data.action.heureDebut !== undefined) {
        const sep: number = this.data.action.heureDebut.indexOf('h');
        const min: number =  +this.data.action.heureDebut.substring(0, sep) * 60 +
        +this.data.action.heureDebut.substring(sep + 1, this.data.action.heureDebut.length);
        this.data.action.dateRealisation = new Date(this.data.action.dateRealisation.getTime() + min * 60000);
      }
      if (this.data.action.duree == null || this.data.action.duree === '' || this.data.action.duree === undefined) {
        this.data.action.dateFin = null;
      } else {
        const h: number = this.data.action.duree.indexOf('h');
        let min: number;
        min = +this.data.action.duree.substring(0, h) * 60;
        if (h !== this.data.action.duree.length) {
          min += +this.data.action.duree.substring(h + 1, this.data.action.duree.length);
        }
        this.data.action.dateFin = new Date(this.data.action.dateRealisation.getTime() + min * 60000);
      }
      // Si tout OK => modification
      if (this.data.action.typeActionId !== null && this.data.action.dateRealisation !== null
        && this.data.action.realisateurId !== null && this.data.action.objetAction.localeCompare('') !== 0
        && (this.data.action.dateFin === null || (this.data.action.heureDebut != null && regExH.test(this.data.action.heureDebut))
        || this.data.action.heureDebut === '')
        && ((this.data.action.duree === null || this.data.action.duree === undefined)
        || ((this.data.action.heureDebut !== null && this.data.action.heureDebut !== '') &&
          this.data.action.duree !== null && regExD.test(this.data.action.duree)) || this.data.action.duree === '')) {
            if (this.data.action.realisateurId !== -1) {

              // tslint:disable-next-line: max-line-length
              this.saveAction();

              this.onUpdateDemande();

          } else {
            this.toastr.warning('Erreur saisie', 'La valeur du réalisateur saisie est incorrecte. Veuillez selectionner un réalisateur existant !');
          }
      } else {
        this.toastr.warning('le type, la date, le réalisateur et l\'objet sont obligatoires.',
          'Impossible de créer la tâche');
      }
    } else {
      this.toastr.warning('le type, la date, le réalisateur et l\'objet sont obligatoires.',
        'Impossible de créer la tâche');
    }
  }

  onAddInterlocuteur(interlocuteur: InterlocuteurModel) {
 
    if (interlocuteur) {
      this.data.action.interlocuteurId = interlocuteur.id;
      this.data.action.nomInterlocuteur = interlocuteur.nom;
      this.data.action.prenomInterlocuteur = interlocuteur.prenom;
      this.data.action.roleInterlocuteur = interlocuteur.role;
      this.data.action.emailInterlocuteur = interlocuteur.email;
      this.data.action.telPrincipalInterlocuteur = interlocuteur.telPrincipal;
      this.data.action.telSecondaireInterlocuteur = interlocuteur.telSecondaire;
      this.data.action.commentaireInterlocuteur = interlocuteur.commentaire;
      this.data.action.civiliteInterlocuteur = interlocuteur.civilite ? interlocuteur.civilite.libelle : null;
      if (this.data.action.nomInterlocuteur.trim().length === 0) {
        this.data.action.interlocuteurId = undefined;
        this.data.action.nomInterlocuteur = undefined;
        this.data.action.prenomInterlocuteur = undefined;
        this.data.action.roleInterlocuteur = undefined;
        this.data.action.emailInterlocuteur = undefined;
        this.data.action.telPrincipalInterlocuteur = undefined;
        this.data.action.telSecondaireInterlocuteur = undefined;
        this.data.action.commentaireInterlocuteur = undefined;
        this.data.action.civiliteInterlocuteur = undefined;
      }
    } else {
      this.data.action.interlocuteurId = undefined;
    }
  }

  createInterlocuteur() {
    let interlocuteur = new InterlocuteurModel();
    interlocuteur.id = undefined;
    interlocuteur.tierId = this.data.action.tierId;
    interlocuteur.nom = this.data.action.nomInterlocuteur;
    interlocuteur.prenom = this.data.action.prenomInterlocuteur;
    interlocuteur.role = this.data.action.roleInterlocuteur;
    interlocuteur.commentaire = this.data.action.commentaireInterlocuteur;
    interlocuteur.telPrincipal = this.data.action.telPrincipalInterlocuteur;
    interlocuteur.telSecondaire = this.data.action.telSecondaireInterlocuteur;
    interlocuteur.email = this.data.action.emailInterlocuteur;
    let civ: CiviliteModel;
    if (this.data.action.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.action.civiliteInterlocuteur.toLowerCase()) ;
        interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      interlocuteur.civiliteId = null;
    }
    interlocuteur.civilite = null;
    this.interlocuteurService.post(interlocuteur).subscribe((interloc: InterlocuteurModel) => {
      this.data.action.interlocuteurId = interloc.id;
      this.createAction();
    });
  }

  updateInterlocuteur() {
    const interlocuteurAction = Object.assign({}, this.interlocuteurs.find(i => i.id === this.data.action.interlocuteurId));
    interlocuteurAction.nom = this.data.action.nomInterlocuteur;
    interlocuteurAction.prenom = this.data.action.prenomInterlocuteur;
    interlocuteurAction.email = this.data.action.emailInterlocuteur;
    interlocuteurAction.telPrincipal = this.data.action.telPrincipalInterlocuteur;
    interlocuteurAction.telSecondaire = this.data.action.telSecondaireInterlocuteur;
    interlocuteurAction.role = this.data.action.roleInterlocuteur;
    interlocuteurAction.civilite = null;
    this.interlocuteurService.put(interlocuteurAction).subscribe((interloc: InterlocuteurModel) => {
      this.createAction();
    });
  }

  saveAction() {
    if (this.data.action.nomInterlocuteur) {
      if (this.data.action.interlocuteurId) {
        this.updateInterlocuteur();
      } else {
        this.createInterlocuteur();
      }
    } else {
      // Si dans l'action il y a une adresse et pas d'interlocuteur, alors création de l'action.
      this.data.action.interlocuteurId = null;
      this.createAction();
    }
  }
}
