<div class="table_overflow_x">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="libelle" matSortDirection="asc">
    <caption></caption>

    <!-- Name Column -->
    <ng-container matColumnDef="libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Libellé </th>
      <td mat-cell *matCellDef="let element"> {{element?.siteGeo?.libelle}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="adresse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Adresse </th>
      <td mat-cell *matCellDef="let element"> {{element?.siteGeo?.adresse}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="codePostal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Code postal </th>
      <td mat-cell *matCellDef="let element"> {{element?.siteGeo?.codePostal}} </td>
    </ng-container>

    <ng-container matColumnDef="ville">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
      <td mat-cell *matCellDef="let element"> {{element?.siteGeo?.ville}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Modifier</th>
      <td mat-cell class="action-btns" *matCellDef="let element">

        <div class="btn-container" *ngIf="element.siteGeo?.agenceId">
          <button  mat-mini-fab class="tableau-btn" color="primary"  (click)="onOpenSite($event, element.siteGeo?.id)">
            <mat-icon class="tableau-mat-icon" >create</mat-icon>
          </button>
          <button  mat-mini-fab type="button" color="warn" class="tableau-btn"(click)="onDeleteSite($event, element);$event.stopPropagation();">
            <mat-icon class="tableau-mat-icon" >delete</mat-icon>
          </button>
        </div>
        <div class="btn-container" *ngIf="!element.siteGeo?.agenceId">
          <button  mat-mini-fab color="gray" disabled class="tableau-btn">
            <mat-icon matTooltip="Adresse non modifiable provenant de Jason" class="tableau-mat-icon" >create</mat-icon>
          </button>
          <button  mat-mini-fab color="gray" disabled class="tableau-btn">
            <mat-icon class="tableau-mat-icon" matTooltip="Adresse non modifiable provenant de Jason">delete</mat-icon>
          </button>
        </div>
        <div class="btn-container"*ngIf="element.histo">
          <button  mat-mini-fab class="tableau-btn" color="basic"  matTooltip="Historique des demandes" (click)="openHistoriqueDemandesAdresses(element?.siteGeo);$event.stopPropagation();" aria-label="Historique des demandes">
            <mat-icon class="tableau-mat-icon" >folder_open</mat-icon>
          </button>
        </div> 
      </td>
      
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="openEvent(element)"></tr>
  </table>

  <mat-paginator class="paginator" [length]="length" class="mat-paginator-sticky padding-left" [pageIndex]="pageIndex" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]" (page)="changePage($event)">
  </mat-paginator>
</div>