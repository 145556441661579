import { SimpleQueryResponse } from './../../shared/simple_query/simpleQueryResponse';
import { SimpleQueryUser } from './../../shared/simple_query/simpleQueryUser';
import { UserService } from './../../services/user/user.service';
import { Component, Input,  Output, EventEmitter, SimpleChanges, Injector, ViewChild } from '@angular/core';
import { TierModel } from './../../shared/models/tier/tier.model';
import { TrieTabService } from '../../services/trie-tab.service';
import { ModalsService } from '../../../shared/modals.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalInfoOkComponent } from '../divers/modals/modal-info-ok.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { ActiviteModel } from './../../shared/models/prestation.model';
import { UserModel } from './../../shared/models/users/user.model';
import { ZoneInterventionModel } from '../../shared/models/zone-intervention.model';
import { ZoneService } from '../../services/zone.service';
import { ModalAjoutZoneComponent } from './modal-ajout-zone/modal-ajout-zone.component';


export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-gestion-zones',
  templateUrl: './gestion-zones.component.html',
  styleUrls: ['./gestion-zones.component.scss']
})
export class GestionZonesComponent {

  URL_EXEMPLE = "https://docs.google.com/spreadsheets/d/1pAaCr6XG_uulZPybW1B8xzNgU5S8dSO928Fcv4cHuxI/edit?usp=sharing";
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated = new EventEmitter<boolean>();
  @Input() tier: TierModel;
  private standardWidth = '90%';
  private standardHeight = '99vh';
  objet: SimpleQueryUser = new SimpleQueryUser();
  agenceId: number;
  mySubscription: any;
  result: boolean;
  loading = false;
  loadingExport = false;
  selectedAction: ZoneInterventionModel;
  displayedColumns: string[] = ['libelle', 'codePostal', 'majoration', 'commentaire', 'modifications'];
  dataSource: ZoneInterventionModel[];
  matDataSource = new MatTableDataSource<ZoneInterventionModel>();
  pageEvent: PageEvent;
  sortState: Sort = {active: 'libelle', direction: 'desc'};
  length: number;
  pageIndex: number;
  pageSize = 10;
  prestationString: string[] = [];
  prestations: ActiviteModel[] = [];
  users: UserModel[] = [];
  zones: ZoneInterventionModel[] = [];
  oldZones: ZoneInterventionModel[] = [];
  dataSourceCopy: ZoneInterventionModel[] = [];
  nb = 0;
  avancement = 0;
  message = '';
  private modalsService: ModalsService;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

constructor( private router: Router,
  private comp: TrieTabService,
  injector: Injector,
  private route: ActivatedRoute,
  public dialog: MatDialog,
  private IntercomToastrService: IntercomToastrService,
  private userService: UserService,
  private zoneService: ZoneService) {
    this.route.params.subscribe((params: any) => {
      this.agenceId = params.id;
      this.objet.order = 'Libelle';
      this.objet.query = '';
      this.objet.pageNumber = 0;
      this.objet.pageSize = this.pageSize;
      this.matDataSource.paginator = this.paginator;
      this.loadZones();
    });
  }

  

  refresh(): void {
    if (this.agenceId) {
      this.pageIndex = 0;
      this.loadZones();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (this.tier !== undefined && this.tier.id !== undefined) {
        this.objet.query = '';
        this.objet.pageNumber = 0;
        this.objet.pageSize = this.pageSize;
        this.matDataSource.paginator = this.paginator;
        this.loadZones();
    }*/
  }

 onAddZone(): void {
    const zone = new ZoneInterventionModel();
    zone.agenceId = this.agenceId;
      const dialogRef = this.dialog.open(ModalAjoutZoneComponent, {
        disableClose: true,
        data: {
          agenceId: this.agenceId,
          zone: zone,
          result: this.result
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.onUpdated.emit(true);
        this.result = result;
        if (this.result) {
          this.loadZones();
        }
      });

  }

  onOpenZone(zone: ZoneInterventionModel): void {
    zone.agenceId = this.agenceId;
      const dialogRef = this.dialog.open(ModalAjoutZoneComponent, {
        disableClose: true,
        data: {
          agenceId: this.agenceId,
          zone: zone,
          result: this.result
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.onUpdated.emit(true);
        this.result = result;
        if (this.result) {
          this.loadZones();
        }
      });
  }

  changePage(event?: PageEvent) {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadZones();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadZones();
  }

  loadPage() {
    this.matDataSource =  this.comp.sortData(this.sortState, this.dataSource, this.matDataSource, this.pageIndex, this.pageSize);
    if (this.matDataSource === undefined) {
        const data = this.dataSource.slice();
        this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
    }
  }

  deleteZone(event, zone: ZoneInterventionModel): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: 'Suppression de la ' + zone.libelle,
        message: 'Êtes-vous sur de vouloir supprimer la zone ?',
        icone: 'warning_amber',
        iconColor: 'accent',
        color: 'accent'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.zoneService.delete(zone.id).subscribe((response: ZoneInterventionModel) => {
          this.IntercomToastrService.success('Le zone a bien été supprimé');
          this.loadZones();
        });
      }
    });
  }

  /*export(): void {
    this.loadingExport = true;
    this.zoneService.getFromTier(this.tier.id).subscribe((zones: ZoneInterventionModel[]) => {
      this.zoneService.export(zones).subscribe(url => {
        if (url && url !== '') {
          window.open(url, '_blank');
        } else {
          this.IntercomToastrService.error('Une erreur est survenue lors de l\'exportation', '',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        }
        this.loadingExport = false;
      });
    });
  }*/


  openModalUrl() {
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: 'Importation des zones',
        message: 'Veuillez partager le document au groupe Veolia. Puis, entrez l\'url de partage du document à importer',
        icone: 'info_amber',
        iconColor: 'primary',
        color: 'primary'
      }
    });
    dialogRef.afterClosed().subscribe(url => {
      if (url && url.trim() !== '') {
        this.objet.query = '';
        this.oldZones = [];
        this.loading = true;
        this.zoneService.import(url).subscribe(zones => {
          // afficher l'url ou louvrir
          if (zones) {
            // verification des champs
            zones = JSON.parse(zones);
            this.zones = this.parseReponse(zones);
            this.message = 'récupération des zones existants';
            this.zoneService.getFromAgence(this.agenceId).subscribe((allZones: ZoneInterventionModel[]) => {
              for (let i = 0; i < allZones.length; i++) {
                this.oldZones.push(allZones[i]);
              }
              try {
                this.saveZones(this.zones, this.oldZones);
              } catch (e: any) {
                this.IntercomToastrService.error((e as Error).message);
                this.loading = false;
              }
            });
          } else {
            this.IntercomToastrService.error('Une erreur est survenue lors de l\'importation', 'Assurez-vous d\'avoir partagé le document et d\'envoyer l\'url de partage',
            {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
            this.loading = false;
          }
        }, error => {
          this.loading = false;
          this.IntercomToastrService.error(error, '', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        });
      } else {
        if ( url !== false && url !== undefined) {
          this.IntercomToastrService.error('Une erreur est survenue lors de l\'importation', 'Assurez-vous d\'avoir partagé le document et d\'envoyer l\'url de partage', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        }
      }
    });
  }

  saveZones(zones: ZoneInterventionModel[], oldZones: ZoneInterventionModel[]) {
    this.avancement = 0;
    this.message = 'création des zones importées';
    this.zoneService.postAll(zones).subscribe(createdZones => {
      this.avancement = 50
      this.message = 'suppression des anciennes zones';
      this.zoneService.deleteAll(oldZones).subscribe(deletedZones => {
        this.avancement = 100;
        this.loading = false;
        this.refresh();
        this.IntercomToastrService.success('', 'Importation réussi');
      }, error => {
        this.loading = false;
        this.IntercomToastrService.error(error, '', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
      });    
    }, error => {
      this.loading = false;
      this.IntercomToastrService.error(error, '', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
    });    
  } 

  parseReponse(response: any): ZoneInterventionModel[] {
  const zones = [];
  if (response.length !== 0 && response !== null) {
    // les lignes
    for (let i = 0; i < response.length; i++) {
      const zone = new ZoneInterventionModel();
      // champ agence
      zone.agenceId = this.agenceId;
      // champ libelle
      if (response[i].libelle !== '' && response[i].libelle) {
        zone.libelle = response[i].libelle;
      } else {
        this.loading = false;
        this.IntercomToastrService.error('Le libelle est obligatoire', 'Format incorrect',
        {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        throw new Error('Format incorrect');
      }
      // champ majoration
      if (response[i].majoration !== '' && response[i].majoration) {
        zone.majoration = response[i].majoration;
      } else {
        zone.majoration = 0;
      }
        // champ code postale
      if (response[i].codepostal !== '' && response[i].codepostal) {
        zone.codePostal = response[i].codepostal;
      } else {
        this.loading = false;
        this.IntercomToastrService.error('Le code postale est obligatoire', 'Format incorrect',
        {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        throw new Error('Format incorrect');
      }
      // champ commentaire
      zone.commentaire = response[i].commentaire;
      zones.push(zone);
    }
    return zones;
  }
  return [];
  }

  search() {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadZones();
  }

  redirect() {
    window.open(this.URL_EXEMPLE, '_blank');
  }

  loadZones(): void {
    if (this.agenceId) {
      this.zoneService.getFromSimpleQuery(this.objet, this.agenceId).subscribe((query: SimpleQueryResponse<ZoneInterventionModel>) => {
        if (query.objets != null) {
          this.dataSource = query.objets;
        } else {
          this.dataSource = [];
        }
        this.length = query.count;
        this.matDataSource = new MatTableDataSource<ZoneInterventionModel>(this.dataSource);
        this.paginator.length = this.length;
      });
    }
  }


}


