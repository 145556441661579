import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {addRingcentral} from 'src/assets/script.js';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

if (environment.production) {
  enableProdMode();
}

addRingcentral();

datadogRum.init(environment.datadog_env as RumInitConfiguration);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    datadogRum.addError(err);
    console.error(err);
  });
