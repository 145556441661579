import { BasicModel } from './basic-models/basic.model';
import { Injectable } from '@angular/core';
@Injectable()
export class ActiviteModel extends BasicModel {
    id: number;
    libelle: string;
    commentaire?: string;
    agenceId?: number;
    code: string;
    codePraxedo: string;
}
