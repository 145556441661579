
<div class="col-md-2">
  <mat-form-field  class="full-width">
    <mat-label>Nom</mat-label>
    <input matInput  placeholder="" [required]="required" maxlength="255"  name="intercom-fName" (change)="changeEvent()"  [(ngModel)]="tier.nom" [disabled]="disabled">
  </mat-form-field>
</div>

<div class="col-md-2"> 
  <mat-form-field  class="full-width">
    <mat-label>Prénom</mat-label>
    <input matInput placeholder="" [required]="required" maxlength="255" name="intercom-lName" (change)="changeEvent()"  [(ngModel)]="tier.prenom" [disabled]="disabled">
  </mat-form-field>
</div>

<div class="col-md-2">          
  <mat-form-field class="full-width"> 
    <mat-label>Propriétaire</mat-label>
    <mat-select [(ngModel)]="tier.proprietaire" name="cbbprop" (valueChange)="proprietaireChange($event)" [disabled]="disabled">
      <mat-option [value]="">Tous</mat-option>
      <mat-option *ngFor="let prop of lstProp" [value]="prop.value">{{ prop.libelle }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
