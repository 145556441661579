import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, switchMap, filter } from 'rxjs/operators';
import {tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '../core/shared/models/users/user.model';
import { UserResponse } from '../core/shared/models/userResponse';
import { JwtHelper } from './jwtHelper';
import { Router } from '@angular/router';
import { HostnameService } from './hostname.service';
import { datadogRum } from '@datadog/browser-rum';
import { HttpErrorResponse } from '@angular/common/http';
import { TokenResponse } from 'angular-oauth2-oidc';
import { GoogleAuthService, UserInfo } from './gapiServices/googleAuthService';


class BasicUser {
  IdToken: string;
  scope: string;
  FcmToken: string;
  TypeToken: string;
  isDeleg: boolean;
}

@Injectable({ providedIn: 'root' })
export class SessionService {
  public static ADMIN = 4;
  public static DELETER = 3;
  public static MODEUR = 2;
  public static OBS = 1;

  public currentUser: Observable<UserModel>;
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentJwt: Observable<string>;
  public currentJwtSubject: BehaviorSubject<string>;
  private tokenSubject = new BehaviorSubject<TokenResponse>(null);
  public userResponseSubject = new BehaviorSubject<UserResponse>(null);
  public token: Observable<string>;
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly DEMANDE_COLUMN = 'DEMANDE_COLUMNS';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN_GOOGLE';
  private readonly STATE_URL = 'STATE_URL';
  private readonly CURRENT_USER = 'CURRENT_USER';
  private isLoggedInIntercom = false;
  private returnUrl: string;
  public errorSubject =  new BehaviorSubject<HttpErrorResponse>(null);

  constructor(private http: HttpClient,
              private authService: GoogleAuthService,
              private hostnameService: HostnameService,
              private router: Router
              ) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem(this.CURRENT_USER)));
    this.currentUser = this.currentUserSubject;
    this.currentJwtSubject = new BehaviorSubject<string>("");
    this.currentJwt = this.currentJwtSubject;
    this.errorSubject = new BehaviorSubject<HttpErrorResponse>(null);
    this.loginUser();
  }

  isLoggedIn() {
    return (this.authService.isLoggedIn() && this.isLoggedInIntercom);
  }

  silentRefresh() {
    this.authService.refreshAuth();
  }
  
  loginUser(): void {
    this.authService.userProfileSubject.pipe(tap(user => console.log()), filter(user => user !== null)).subscribe((userInfo: UserInfo) => {
     if (userInfo) {
       const header = new HttpHeaders({
         'Content-Type': 'application/json'
       });
       var refreshToken = this.authService.getTokens();
       this.tokenSubject.next(refreshToken);
       this.setRefreshToken(refreshToken);
       const apiUser = new BasicUser();
       apiUser.IdToken = refreshToken.id_token;
       apiUser.scope = refreshToken.scope;
       const firebaseToken = localStorage.getItem('FCM_TOKEN');
       if (firebaseToken) {
         apiUser.FcmToken = firebaseToken;
         apiUser.TypeToken = 'web';
       }
       this.http.post<UserResponse>(this.hostnameService.getUrl() + 'api/Account/Google/', apiUser, {headers: header})
        .subscribe((res) => {
         // login successful if there's a jwt token in the response
         if (res !== null && res.token !== null && res.user !== null) {
           this.doLoginUser(refreshToken, res.token, res.user);
           this.isLoggedInIntercom = true;
         }
         this.returnUrl = this.getStateUrl();
         if (this.returnUrl !== 'login'){
           this.router.navigateByUrl(encodeURI(this.returnUrl));
         }
         this.userResponseSubject.next(res);
       },
       (error => {
         this.isLoggedInIntercom = false;
         this.errorSubject.next(error);
       }));
     }
   });
 }

  logout(): Observable<TokenResponse> {
    // remove user from local storage to log user out/*
    localStorage.removeItem(this.CURRENT_USER);
    this.removeTokens();
    this.isLoggedInIntercom = false;
    datadogRum.removeUser();
    this.authService.signOut();
    return of(null);
  }

  logoutForScope(): Observable<TokenResponse> {
    // remove user from local storage to log user out/*
    localStorage.removeItem(this.CURRENT_USER);
    this.removeTokens();
    this.isLoggedInIntercom = false;
    datadogRum.removeUser();
    this.authService.signOut();
    return of(null);
  }

  public get currentUserValue(): UserModel {
    if (localStorage.getItem(this.CURRENT_USER) != null) {
      return JSON.parse(localStorage.getItem(this.CURRENT_USER));
    }
    return null;
  }

  public get tokenValue(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }
  getScope(): string {
    return this.authService.getScope();
  }
  private refreshJwtToken(refreshToken: TokenResponse): Observable<UserResponse> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const apiUser = new BasicUser();
    apiUser.IdToken = refreshToken.id_token;
    apiUser.scope = refreshToken.scope;
    const firebaseToken = localStorage.getItem('FCM_TOKEN');
    if (firebaseToken) {
      apiUser.FcmToken = firebaseToken;
      apiUser.TypeToken = 'web';
    }
    return this.http.post<UserResponse>(this.hostnameService.getUrl() + 'api/Account/Google/', apiUser,
      {headers: header}).pipe(tap((res: UserResponse) => {
      this.storeJwtToken(res.token);
    }));
  }

  getJwtToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  // rafraichi l'auth google si elle vas expirer puis return le token JWT une fois les deux disponible. 
  // throw une erreur si non => logout + route to page Login
  public refreshTokens(): Observable<string> {
    if (this.authService.isLoggedIn()) {
      const refreshToken = this.getRefreshToken();
      if (refreshToken && refreshToken.id_token) {
        const needRefresh = JwtHelper.expiresInLessThan(
          refreshToken.id_token, environment.refresh_google_when_expire_in * 60 * 1000);
        if (needRefresh) {
          return this.authService.refreshAuth().pipe(
            catchError(error => {
              console.log("ERROR OIDC Token !! ");
              this.isLoggedInIntercom = false;
              throw error;
            }),
            tap((res: TokenResponse) => {
                this.setRefreshToken(res)
            }), switchMap((token: TokenResponse) => {
              return this.refreshJWTToken(token);
            }));
          } else {
            return this.refreshJWTToken(refreshToken);
          }
      } else {
        this.isLoggedInIntercom = false;
        throw "No Refresh Token on First Call";
        
      }
    } else {
      console.log("Redirect Login");
      this.isLoggedInIntercom = false;
      throw "No Refresh Token on First Call";  
    }
   // Create and send `XMLHttpRequest`
  }


  // rafraichi l'auth jwt api si elle vas expirer
  private refreshJWTToken(token: TokenResponse): Observable<string>  {
    if (this.getJwtToken()) {
      const needRefresh = JwtHelper.expiresInLessThan(this.getJwtToken(), environment.refresh_jwt_when_expire_in * 60 * 1000);
      if (needRefresh) {
        return this.refreshJwtToken(token).pipe(map(user => user.token));
      } else {
        return of(this.getJwtToken());
      }
    } else {
      throw "ERROR refresh JWT Token";
    }
  }

  private doLoginUser(refreshToken: TokenResponse, jwtToken: string, user: UserModel): void{
    this.currentUserSubject.next(user);
    this.currentJwtSubject.next(jwtToken);
    this.storeJwtToken(jwtToken);
    datadogRum.setUser({
      id: user.id.toString(),
      name: user.nom + ' ' + user.prenom,
      email: user.email
    });
    localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
    this.storeTokens(jwtToken, refreshToken);
  }

  private storeJwtToken(jwtToken: string): void {
    localStorage.setItem(this.JWT_TOKEN, jwtToken);
  }

  private storeTokens(jwt: string, refreshToken: TokenResponse): void {
    localStorage.setItem(this.JWT_TOKEN, jwt);
    localStorage.setItem(this.REFRESH_TOKEN, JSON.stringify(refreshToken));
  }
  public addScope(scope: string): void {
    if (this.authService.getScope().indexOf(scope) === -1) {
      this.authService.addScope(scope);
    }
  }

  public getRefreshToken(): TokenResponse {
    return this.authService.getTokens();
  }

  public containsAskedScope(): boolean {
    return this.authService.containsAskedScope();
  }

  public setRefreshToken(socialUser: TokenResponse): void {
    localStorage.setItem(this.REFRESH_TOKEN, JSON.stringify(socialUser));
  }

  public setUser(user: UserModel): void {
    this.currentUserSubject.next(user);
    localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
  }

  public getStateUrl(): string {
    return localStorage.getItem(this.STATE_URL);
  }

  public setStateUrl(stateUrl: string): void{
    localStorage.setItem(this.STATE_URL, stateUrl);
  }

  public removeStateUrl(): void {
    localStorage.removeItem(this.STATE_URL);
  }


  public removeJwtToken(): void {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  private removeTokens(): void {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
