import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { DescriptionActiviteService } from "../../services/description-activite.service";
import { DESCRIPTIONACTIVITEVALIDATORS, FormInfo } from "../../shared/constant/description-activite-validators";
import { DescriptionActiviteModel } from "../../shared/models/description-activite.model";
import { IntercomToastrService } from "src/app/modules/shared/IntercomToastr.service";

@Injectable({
    providedIn: 'root'
})

export class ActiviteComponentService {
  
    constructor(
      public dialog: MatDialog,
      protected router: Router,
      protected toastr: IntercomToastrService,
      protected descriptionactiviteservice: DescriptionActiviteService) 
    { }
    
    public getDescriptionActiviteForm(description: DescriptionActiviteModel, descriptionValidators: Map<string, FormInfo>): UntypedFormGroup {
        const form = new UntypedFormGroup({});
        for (const [key, value] of descriptionValidators.entries()) {
            const control = new UntypedFormControl({ value: description[key], disabled: descriptionValidators.get(key).disabled }, descriptionValidators.get(key).validators);
            form.addControl(key, control);
        }
        return form;
    }

    getCreateDescriptionActiviteFormGroup(description: DescriptionActiviteModel): UntypedFormGroup {
        const form = this.getDescriptionActiviteForm(description, DESCRIPTIONACTIVITEVALIDATORS.CREATE);
        return form;
    }
    getEditDescriptionActiviteFormGroup(description: DescriptionActiviteModel): UntypedFormGroup {
        const form = this.getDescriptionActiviteForm(description, DESCRIPTIONACTIVITEVALIDATORS.EDIT);
        return form;
    }
}