<div class="maintenance-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h1>Site en maintenance</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-icon class="maintenance-icon">build</mat-icon>
        <p>Nous effectuons actuellement des travaux de maintenance pour améliorer votre expérience.</p>
        <p>Nous serons de retour dans environ {{ estimatedTime }}.</p>
        <p>Merci de votre patience et de votre compréhension.</p>
      </mat-card-content>
    </mat-card>
  </div>
  