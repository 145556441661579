<mat-drawer-container class="container-position">
  <mat-drawer #sideNav mode="side" opened="false" position="end" class="drawer" style="Overflow-y:scroll;">
    <div>
      <br />
      <div class="row">
        <div class="col-sm-4 margin_bm_15">
          <h4>Filtres</h4>
        </div>
        <div class="col-sm-8 margin_bm_15">
          <button mat-flat-button color="default" (click)="clearFiltre()">
            <mat-icon>settings_backup_restore</mat-icon> Réinitialiser
          </button>
        </div>
      </div>
    </div>
    <br />
    <div class="data-class" *ngIf="filtreData" style="padding-bottom: 10px;">
      <div class="row">
        <div class="col-sm-12 margin_bm_15">
          <app-autocomplete-with-num (objetChange)="onChange($event)" libelle="Numéro de la demande"
            [objets]="numDemandes" [(objet)]="filtreData.numeroDemande" name="numeroDemande" [required]="false"
            (loadData)="loadData($event)">
          </app-autocomplete-with-num>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 margin_bm_15">
          <mat-form-field class="full-width">
            <mat-label>Date de création</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [(ngModel)]="this.filtreData.startDCreationDemande" (ngModelChange)="onChange($event)"
                name="DCDemande">
              <input matEndDate [(ngModel)]="filtreData.endDCreationDemande" (ngModelChange)="onChange($event)"
                name="DCDemande">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">date non valide</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">date non valide</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 margin_bm_15">
          <mat-form-field class="full-width">
            <mat-label>Date d'échéance</mat-label>
            <mat-date-range-input [rangePicker]="picker2">
              <input matStartDate [(ngModel)]="this.filtreData.startDInterventionDemande"
                (ngModelChange)="onChange($event)" name="DIDemande">
              <input matEndDate [(ngModel)]="this.filtreData.endDInterventionDemande" (ngModelChange)="onChange($event)"
                name="DIDemande">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">date non valide</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">date non valide</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" style="margin-top: 5px; margin-bottom: 5px;">
          <app-custom-select-multiple-option (selected)="onSelectOptionChange($event, 'Type')"
            [(reset)]="resetSelect" [InputvaluesList]="listTypesDemandesString" [InputSelectedValues]="listTypesDemandesUpdated"
            [title]="'Type'">
          </app-custom-select-multiple-option>
        </div>
        <div class="col-sm-12" style="margin-top: 5px; margin-bottom: 5px;">
          <app-custom-select-multiple-option (selected)="onSelectOptionChange($event, 'Statut')"
          [(reset)]="resetSelect" [InputvaluesList]="listStatus" [InputSelectedValues]="listStatusUpdated" [title]="'Statut'">
          </app-custom-select-multiple-option>
        </div>
          <div class="col-sm-12" style="margin-top: 5px; margin-bottom: 5px;">
            <app-custom-select-multiple-option (selected)="onSelectOptionChange($event, 'EtatUrgence')"
              [(reset)]="resetSelect" [InputvaluesList]="listTypesUrgencesString" [InputSelectedValues]="listTypesUrgencesUpdated"
              [title]="'Avancement demande urgente'">
            </app-custom-select-multiple-option>
          </div>
      </div>
      
      <div class="row">
        <div class="col-sm-6 margin_bm_15">
          <app-autocomplete-tiers #autocompleteTiers [tierId]="filtreData.tierId" [label]="labelTiers" [canBeDisabled]="false"
            (tiersChangeObject)="onTiersChanged($event, 'Tier')"></app-autocomplete-tiers>
        </div>
        <div class="col-sm-6 margin_bm_15">
          <app-autocomplete-tiers #autocompleteTiersFacture [tierId]="filtreData.tierFactureId" [label]="labelTiersFacture" [canBeDisabled]="false"
            (tiersChangeObject)="onTiersChanged($event, 'TierFacture')"></app-autocomplete-tiers>
        </div>
      </div>
    
    <div class="row">
      <div class="col-sm-12 margin_bm_15">
        <mat-form-field class="full-width">
          <mat-label>Adresse</mat-label>
          <input [(ngModel)]="filtreData.adresse" matInput (ngModelChange)="onChange($event)" name="Adresse"
            style="font-size: small;" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
      <div class="col-sm-6 margin_bm_15">
        <mat-form-field class="full-width">
          <mat-label>Code Postal</mat-label>
          <input [(ngModel)]="filtreData.codePostal" matInput (ngModelChange)="onChange($event)" name="Code Postal"
            style="font-size: small;" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
      <div class="col-sm-6 margin_bm_15">
        <mat-form-field class="full-width">
          <mat-label>Ville</mat-label>
          <input [(ngModel)]="filtreData.ville" matInput (ngModelChange)="onChange($event)" name="Ville"
            style="font-size: small;" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 margin_bm_15">
        <app-autocomplete-societe-multiple-select [eventsSubjectSocieteArray]="eventsSubjectSocieteArray" (updated)="societeChange($event)">
        </app-autocomplete-societe-multiple-select>
      </div>
      <div class="col-sm-12 margin_bm_15">
        <app-autocomplete-agence-multiple-select [eventsSubjectAgenceArray]="eventsSubjectAgenceArray" [agencesIdsArray]="selectedAgenceIdsArray" 
          [inputSocietesString]="selectedSocietesString" (updated)="agenceChange($event)">
        </app-autocomplete-agence-multiple-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 margin_bm_15">
        <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectRealisateurArray" (updated)="realisateurChange($event)"
        [title]="'réalisateur'">
        </app-autocomplete-realisateur-multiple-selection>
      </div>
      
      <div class="col-sm-12 margin_bm_15">
        <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectCreateurArray" (updated)="createurChange($event)"
        [title]="'créateur'">
        </app-autocomplete-realisateur-multiple-selection>
      </div>
      
      <div class="col-sm-12 margin_bm_15">
        <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectGestionnaireArray" (updated)="gestionnaireChange($event)"
        [title]="'gestionnaire'">
        </app-autocomplete-realisateur-multiple-selection>
      </div>

      <div class="col-sm-12 margin_bm_15">
        <mat-form-field class="full-width">
          <mat-label>Commentaire</mat-label>
          <input [(ngModel)]="filtreData.commentaire" matInput (ngModelChange)="onChange($event)" name="Commentaire"
            style="font-size: small;" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </div>
    </div>

  </div>
  </mat-drawer>
  <mat-drawer-content>
    <mat-card style="margin:20px">
      <mat-card-header>
        <mat-card-title style="display: flex; justify-content: space-between; align-items: center;">
          <h3>Liste des demandes</h3>
        </mat-card-title>
        <div class="filter-button">
            <button color="default" mat-flat-button type="button" matTooltip="Modifier les colonnes" [matMenuTriggerFor]="menu">
              <mat-icon>settings</mat-icon>
            </button>
            <mat-menu class="menu" #menu="matMenu">
              <order-column-menu 
                [(displayedColumns)]="displayedColumns" 
                [columnType]="columnService.DEMANDE_COLUMN" ></order-column-menu>
            </mat-menu>
            <button color="success" mat-flat-button type="button" (click)="onExport()">
              <mat-icon>import_export</mat-icon>Export
            </button>
            <button color="default" mat-flat-button type="button" (click)="sideNav.toggle(); this.load()">
              <mat-icon id="icon">{{
                this.nbFilterApplied == 0 ? "filter_list" :
                this.nbFilterApplied == 1 ? "filter_1" : 
                this.nbFilterApplied == 2 ? "filter_2" : 
                this.nbFilterApplied == 3 ? "filter_3" : 
                this.nbFilterApplied == 4 ? "filter_4" : 
                this.nbFilterApplied == 5 ? "filter_5" : 
                this.nbFilterApplied == 6 ? "filter_6" : 
                this.nbFilterApplied == 7 ? "filter_7" : 
                this.nbFilterApplied == 8 ? "filter_8" : 
                this.nbFilterApplied == 9 ? "filter_9" : "filter_9_plus"
                  }}</mat-icon>Filtres
            </button>
          </div>
      </mat-card-header>
      <mat-card-content>
        <div class="tabResponsives">
          <app-type-demande class="mat-tablee table-inner-scroll-container"
            [datasource]="demandeList" 
            [displayedColumns]="displayedColumns" 
            [simpleQueryResponse]="simpleQueryResponse1" 
            (demandeOpen)="onOpenDemande($event)"
            (pageChange)="pageChange($event)"></app-type-demande>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-drawer-content>
</mat-drawer-container>