import { TierBasicMock } from './basic-mock/tier-basic.mock';
import { ProtocoleModel } from '../models/protocole.model';
import { Injectable } from '@angular/core';

@Injectable()
export class ProtocoleMock extends TierBasicMock {

    constructor() {
        super();
    }

    getFromObjet(objetRecherche: ProtocoleModel): ProtocoleModel[] {
        return Object.assign([], this.objets.filter((objTab: ProtocoleModel) => this.sameProtocole(objTab, objetRecherche)));
      }
    
    
      sameProtocole(objTab: ProtocoleModel, objRecherche: ProtocoleModel): boolean{
        let same: boolean = undefined;
        let dr: Date;
        let dt: Date;
        
        
        if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
          return false;
        }
     /*   else if (objRecherche.adresse !== undefined && objRecherche.adresse.length !== 0 && objTab.adresse.toLowerCase().indexOf(objRecherche.adresse.toLowerCase()) === -1) {
            return false;
        }
        else if (objRecherche.prestation !== undefined && objRecherche.prestation.length !== 0 && objTab.prestation.toLowerCase().indexOf(objRecherche.prestation.toLowerCase()) === -1) {
          return false;
        }
        else if (objRecherche.commentaire !== undefined && objRecherche.commentaire.length !== 0 && objTab.commentaire.toLowerCase().indexOf(objRecherche.commentaire.toLowerCase()) === -1) {
          return false;
        } */
    
        return true;
      }
}
