import { SessionService } from '../../shared/session.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { BasicService } from './basic-service/basic.service';
import { UserEtiquetteModel } from './../shared/models/etiquette/userEtiquette.model';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class EtiquetteService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEASSISTANT;
    }

  getUserEtiquette(): UserEtiquetteModel[] {
    const userEtiquettes = JSON.parse(localStorage.getItem('USER_ETIQUETTES'));
    return userEtiquettes !== null &&  userEtiquettes !== undefined ? userEtiquettes : [];
  }

  setUserEtiquette(userEtiquettes: UserEtiquetteModel[]) {
    localStorage.setItem('USER_ETIQUETTES', JSON.stringify(userEtiquettes));
  }
}
