
<div class="col-md-3">
  <mat-form-field  class="full-width">
    <mat-label>Raison sociale</mat-label>
    <input matInput placeholder="" maxlength="255" [required]="required" name="intercom-raison-soc" (change)="changeEvent()" [(ngModel)]="tier.raisonSociale" [disabled]="disabled">
  </mat-form-field>
</div>

<div class="col-md-2">
  <mat-form-field  class="full-width">
    <mat-label>Marque</mat-label>
    <input matInput placeholder="" maxlength="255" name="intercom-marque" (change)="changeEvent()" [(ngModel)]="tier.marqueEnseigne" [disabled]="disabled">
  </mat-form-field>
</div>

<div class="col-md-1">
  <mat-form-field  class="full-width">
    <mat-label>Code APE</mat-label>
    <input matInput placeholder="" maxlength="20" name="intercom-code-ape" (change)="changeEvent()" [(ngModel)]="tier.codeNaf" [disabled]="disabled">
  </mat-form-field>
</div>

<div class="col-md-2">
  <mat-form-field  class="full-width">
    <mat-label>SIREN/SIRET</mat-label>
    <input matInput placeholder="" maxlength="20" name="intercom-siret" (change)="changeEvent()" [(ngModel)]="tier.siretSiren" [disabled]="disabled">
  </mat-form-field>
</div>