import { BasicMock } from './basic-mock/basic.mock';
import { CiviliteModel } from '../models/civilite.model';
import { Injectable } from "@angular/core";

@Injectable()
export class CiviliteMock extends BasicMock  {
  constructor() {
    super();
    (<CiviliteModel[]> this.objets) = [
      {
        id: 1, abreviation: 'M', libelle: 'Monsieur', code: '01'
      },
      {
        id: 2, abreviation: 'Mme', libelle: 'Madame', code: '02'
      },
      {
        id: 3, abreviation: 'Mlle', libelle: 'Mademoiselle', code: '03'
      },
      {
        id: 4, abreviation: 'Mmes', libelle: 'Mesdames', code: '04'
      },
    ];
  }
}
