<div>
  <div class="test" style="text-align: center">
    <div class="col-sm-12">
      <h3>{{title}}</h3>
    </div>
    <div *ngIf="data.title == 'Envoyer à jason'" style="text-align: left">
      <app-modal-envoyer-jason [data]="data"></app-modal-envoyer-jason>
    </div>
    <div class="col-sm-12" *ngIf="icone === ''">
      <h5>{{message}}</h5>
      <mat-form-field  class="full-width">
        <input *ngIf="import"
            required
            matInput
            type="url"
            placeholder=""
            value=""
            name="url"
            [(ngModel)]="this.url"
            [ngModelOptions]="{ standalone: true }"
          />
      </mat-form-field>
    </div>
    <div class="col-sm-12" *ngIf="icone !== ''">
      <mat-icon class="col-sm-1" style="bottom: auto;padding: 0;" [color]="icon_color">{{icone}}</mat-icon>
      <div class="col-sm-10">
        <h5>{{message}}</h5>
        <mat-form-field  class="full-width">
          <input *ngIf="import"
            required
            matInput
            type="url"
            placeholder=""
            value=""
            name="url"
            [(ngModel)]="this.url"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm-12">
      <mat-divider></mat-divider>
    </div>    
    <div class="col-sm-6" style="margin-top: 40px;">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>
    <div class="col-sm-6" style="margin-top: 40px;">
      <button mat-flat-button [color]="color" (click)="onModalConfirm()" >Confirmer</button>
    </div>

  </div>
</div>