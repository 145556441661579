import { BasicEtiquetteModel } from './basicEtiquette.model';
import { EtiquetteModel } from './etiquette.model';

export class UserEtiquetteModel extends BasicEtiquetteModel {

    public prestationId?: number;

    public etiquettes?: EtiquetteModel[];

    constructor(libelle: string, color: string) {
        super(libelle, color);
        this.etiquettes = [];
    }
}
