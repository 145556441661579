import { map } from 'rxjs/operators';
import { ActionDTO } from '../shared/dtos/actionDTO';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { ActionModel } from '../shared/models/action.model';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserModel } from '../shared/models/users/user.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { BasicService } from './basic-service/basic.service';
import { ActionDemandeDTO, DashBoardInfoResult, SimpleQueryDashBoard } from '../shared/DashboardClasses';
import { DemandeModel } from '../shared/models/demande.model';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { SessionService } from '../../shared/session.service';
import { FiltrePerimetreDTO } from '../shared/dtos/filterPerimetreDTO';

@Injectable()
export class DashboardService extends BasicService {

  constructor(http: HttpClient,
              apiService: ApiService,
              sessionService: SessionService,
              protected IntercomToastrService: IntercomToastrService) {
                super(http, apiService, sessionService);
                this.serviceUrl = apiService.ROUTEDASHBOARD;
  }


  GetDashboardInfos(ids: number[]) {
    return this.http.post<DashBoardInfoResult>(this.apiUrl + this.serviceUrl + 'GetDashboardInfos/', ids);
  }

  GetDemandeFromUsers(query: SimpleQueryDashBoard) {
    return this.http.post<SimpleQueryResponse<DemandeModel>>
    (this.apiUrl + this.serviceUrl + 'GetDemandeFromUsers/', query).pipe(tap((result) => {
      for (const demande of result.objets) {
        this.loadDemande(demande);
      }
    }));
  }

  GetActionFromUsers(query: SimpleQueryDashBoard) {
    return this.http.post<SimpleQueryResponse<ActionModel>>
    (this.apiUrl + this.serviceUrl + 'GetActionFromUsers/', query).pipe(tap((result) => {
      for (const action of result.objets) {
        this.loadAction(action);
      }
    }));
  }

  GetDemandeAndActionFromUsers(query: SimpleQueryDashBoard) {
    return this.http.post<SimpleQueryResponse<ActionDemandeDTO>>
    (this.apiUrl + this.serviceUrl + 'GetDemandeAndActionFromUsers/', query);
  }

  loadDemande(demande: DemandeModel) {
    demande.dateIntervention = new Date(demande.dateIntervention);
   }

  loadAction(action: ActionModel) {
    // heureDebut
    if (action.dateFin != null && action.dateFin !== undefined &&
      action.dateFin.toString().localeCompare('0001-01-01T00:00:00') !== 0 && action.dateFin !== action.dateRealisation) {
      action.dateFin = new Date(action.dateFin);
    } else {
      action.dateFin = null;
    }
    action.dateCreation = new Date(action.dateCreation);
    action.dateRealisation = new Date(action.dateRealisation);
    this.setHeureDuree(action);
  }

  public setHeureDuree(action: ActionModel) {
    if (action.dateRealisation.getHours() === 0 && action.dateRealisation.getMinutes() === 0) {
      action.heureDebut = null;
      action.duree = undefined;
    } else {
      action.heureDebut = action.dateRealisation.getHours() + 'h';
      action.duree = '1h';
      if (action.dateRealisation.getMinutes() !== 0) {
        action.heureDebut += this.addZero(action.dateRealisation.getMinutes());
      }
    }
    // Duree
    if (action.dateFin !== null && action.dateFin !== undefined && action.dateFin.toISOString() !== action.dateRealisation.toISOString()) {
      let diff: number;
      diff = (action.dateFin.getTime() - action.dateRealisation.getTime() ) / 60000;
      action.duree = this.addZero(Math.trunc(diff / 60)) + 'h' + this.addZero(diff % 60);
    }
  }

  getFromUsers(ids: number[]): Observable<ActionModel[]> {
    return this.http.post<ActionModel[]>(this.apiUrl + this.serviceUrl + 'GetFromUsers/', ids).pipe(tap((actions: ActionModel[]) => {
      for (const action of actions) {
        this.loadAction(action);
      }
    }));
  }

  getDashboardAFaire(users: UserModel[]): Observable<ActionModel[]> {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new ActionDTO();
      objetDemande.nonClos = true;
      objetDemande.realisateurId = user.id;
    }
    return forkJoin(promiseArray).pipe(map((listArray: ActionModel[]) => {
      let traiterArray: ActionModel[] = [];
      for (const array of listArray) {
        traiterArray = traiterArray.concat(array);
      }
      return traiterArray;
    }));
  }

  getDashboardNonLu(users: UserModel[]) {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new ActionDTO();
      objetDemande.statut = 'A traiter';
      objetDemande.realisateurId = user.id;
    }
    return forkJoin(promiseArray).pipe(map((listArray: ActionModel[]) => {
      let nonLuArray: ActionModel[] = [];
      for (const array of listArray) {
        nonLuArray = nonLuArray.concat(array);
      }
      return nonLuArray;
    }));
  }

  getDashboardRetard(users: UserModel[]) {
    const promiseArray = [];
    for (const user of users) {
      const objetDemande = new ActionDTO();
      objetDemande.nonClos = true;
      objetDemande.realisateurId = user.id;
      objetDemande.dateSmallerRealString = new Date(Date.now());
      objetDemande.dateSmallerRealString.setHours(0);
      objetDemande.dateSmallerRealString.setMinutes(0);
      objetDemande.dateSmallerRealString.setMilliseconds(0);
    }
    return forkJoin(promiseArray).pipe(map((listArray: ActionModel[]) => {
      let retardArray: ActionModel[] = [];
      for (const array of listArray) {
        retardArray = retardArray.concat(array);
      }
      return retardArray;
    }));
  }

  getFiltres(){
    return this.http.get<FiltrePerimetreDTO[]>(this.apiUrl + this.serviceUrl + 'getFiltres/');

  }

  private addZero(i: number) {
    return (i < 10 ? '0' + i : i + '');
  }
}
