import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DocumentService } from '../../../services/document.service';
import { TierService } from '../../../services/tier/tier.service';
import { TrieTabService } from '../../../services/trie-tab.service';
import { DocumentModel } from '../../../shared/models/document/document.model';
import { TierModel } from '../../../shared/models/tier/tier.model';
import { ModalAddDocumentComponent } from '../modal-add-document/modal-add-document.component';
import { AgenceService } from '../../../services/rsa/agence.service';
import { UserService } from '../../../services/user/user.service';
import { DescriptionActiviteService } from '../../../services/description-activite.service';
import { DemandeService } from '../../../services/demande.service';
import { ToastrService } from 'ngx-toastr';
import { DemandeModel } from '../../../shared/models/demande.model';
import { ModalConfirmationComponent } from 'src/app/utils/modals/modal-confirmation/modal-confirmation.component';
import { DatePipe } from '@angular/common';
import { DocumentDTO } from '../../../shared/dtos/documentDTO';

export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-array-document',
  templateUrl: './array-document.component.html',
  styleUrls: ['./array-document.component.scss']
})
export class ArrayDocumentComponent implements OnInit, OnChanges {
  @Input() tier: TierModel;
  @Input() demandeSource: DemandeModel;
  @Input() datasourceDocuments: DocumentModel[];
  @Input() disabledModify: boolean;
  @Output() documentOpen = new EventEmitter<DocumentModel>();
  @Output() onUpdated = new EventEmitter<boolean>();
  @Output() selectedDocument = new EventEmitter<DocumentModel>();


  displayedColumnsDocument: string[] = ['libelle', 'dateCrea', 'actions'];
  matDataSourceDocument: MatTableDataSource<DocumentModel>;
  lengthDocument: number;
  pageIndexDocument = 0;
  pageSizeDocument = 15;
  pageEvent: PageEvent;
  length: number;
  sortStateDocument: Sort = { active: 'dateCrea', direction: 'desc' };
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  result: boolean;
  documentDTO : DocumentDTO = new DocumentDTO();
  dataSourceDocumentsBackup : DocumentModel[];

  constructor(public dialog: MatDialog,
    private comp: TrieTabService,
    private documentService: DocumentService,
    private tiersService: TierService,
    private agenceService: AgenceService,
    private demandeService: DemandeService,
    private descriptionActiviteService: DescriptionActiviteService,
    private userService: UserService,
    private toastr: ToastrService,
    private datepipe: DatePipe
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.lengthDocument = this.datasourceDocuments.length;
    this.loadPageDocument();
  }

  ngOnInit(): void {
    this.lengthDocument = this.datasourceDocuments.length;
    this.loadPageDocument();
  }

  changePageDocument(event?: PageEvent) {
    this.pageIndexDocument = event.pageIndex;
    this.pageSizeDocument = event.pageSize;
    this.loadPageDocument();
  }
  trieTabDocument(sort: Sort) {
    this.sortStateDocument = sort;
    this.loadPageDocument();
  }

  loadPageDocument() {
    this.matDataSourceDocument = this.comp.sortData(this.sortStateDocument, this.datasourceDocuments,
    this.matDataSourceDocument, this.pageIndexDocument, this.pageSizeDocument);
    if (this.matDataSourceDocument === undefined) {
      const data = this.datasourceDocuments.slice(0, this.pageSizeDocument);
      this.matDataSourceDocument = new MatTableDataSource(data);
    }
  }

  getSelectedDocument(document: DocumentModel) {
    this.selectedDocument.emit(document);
  }

  onAddDoc(): void {
    if (this.demandeSource)
    {
      this.onAddDocDemande();
    } else {
      this.onAddDocTier();
    }
  }

  /* Tiers */
  onAddDocTier(): void {
    const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
      data: {
        typeId: this.tier.id,
        tierId: this.tier.id,
        result: this.result,
        type: 'TiersS3',
        document: new DocumentModel(),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result ? true : false;
      if (this.result) {
        this.tiersService.get(this.tier.id).subscribe((tier: TierModel) => {
          this.tier = tier;
        });
        this.documentService.getFromTiers(this.tier.id).subscribe((documents: DocumentModel[]) => {
          if (documents) {
            this.datasourceDocuments = documents;
            this.updateArrayAfterAdd();
          }
        });
      }
    });
  }

  visualiseDoc(doc: DocumentModel): void {
    switch(doc.type) { 
      case "Agence": { 
        this.agenceService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        }); 
        break; 
      } 
      case "Utilisateur": { 
        this.userService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        });  
         break; 
      } 
      case "Activite": { 
        this.descriptionActiviteService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        });  
         break; 
      }
      case "Demande": { 
        window.open(doc.url, '_blank');
        break;
      }
      case "Tiers": { 
        window.open(doc.url, '_blank');
        break;
      }
      case "DemandeS3": { 
        this.demandeService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        });  
         break;
      }
      case "TiersS3": { 
        this.tiersService.getDocumentById(doc.id).subscribe((res: Blob) => {
          const url = URL.createObjectURL(res);
          window.open(url, '_blank');
        });
         break;
      }
    }
  }

  updateArrayAfterDel(doc: DocumentModel) {
    this.dataSourceDocumentsBackup = Object.assign([],this.datasourceDocuments);
    this.datasourceDocuments.splice(this.datasourceDocuments.findIndex(x => x.id == doc.id), 1);
    this.lengthDocument = this.datasourceDocuments.length;
    if (this.pageIndexDocument * this.pageSizeDocument >= this.lengthDocument && this.pageIndexDocument > 0)
      this.pageIndexDocument--;
    this.loadPageDocument();
  }

  updateArrayAfterAdd() {
    this.dataSourceDocumentsBackup = Object.assign([],this.datasourceDocuments) ;
    this.lengthDocument = this.datasourceDocuments.length;
    if ((this.pageIndexDocument + 1) * this.pageSizeDocument < this.lengthDocument)
      this.pageIndexDocument++;
    this.loadPageDocument();
  }

  onDeleteDoc(doc: DocumentModel): void {
    const dialogConf = this.dialog.open(ModalConfirmationComponent, {
      data: {
        title: 'Suppression du document : ' + doc.libelle,
        message: 'Êtes-vous sûr de vouloir supprimer ce document crée le ' + this.datepipe.transform(doc.dateCreation, 'medium') + ' ?'
      }
    });
    dialogConf.afterClosed().subscribe(res => {
      if (res) {
        switch(doc.type) { 
          case "Agence": {
            this.agenceService.deleteDocument(doc.id).subscribe(res => {
              if(res){
                this.updateArrayAfterDel(doc);
              }
            }); 
            break; 
          } 
          case "Utilisateur": { 
            this.userService.deleteDocument(doc.id).subscribe(res => {
              if(res){
                this.updateArrayAfterDel(doc);
              }
            });
            break; 
          } 
          case "Activite": { 
            this.descriptionActiviteService.deleteDocument(doc.id).subscribe(res => {
              if(res){
                this.updateArrayAfterDel(doc);
              }
            });
            break; 
          }
          case "Demande":
          case "DemandeS3": {         
            this.demandeService.deleteDocument(doc.id).subscribe(res => {
              if(res){
                this.updateArrayAfterDel(doc);
              }
            });
            break; 
          }
          case "Tiers":
          case "TiersS3": { 
            this.tiersService.deleteDocument(doc.id).subscribe(res => {
              if(res){
                this.updateArrayAfterDel(doc);
              }
            });
            break; 
          }
        } 
      }
    });
  }

  //Demande
  onAddDocDemande(): void {
    const dialogRef = this.dialog.open(ModalAddDocumentComponent, {disableClose: true,
      data: {
        type: "DemandeS3",
        document: new DocumentModel(),
        typeId: this.demandeSource.id,
        tierId: this.demandeSource.tierId,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result ? true : false;
      if (this.result) {
        this.demandeService.get(this.demandeSource.id).subscribe((dem: DemandeModel) => {
          if (dem)
            this.demandeSource = dem;
        });
        this.documentService.getFromDemande(this.demandeSource.id).subscribe((documents: DocumentModel[]) => {
          if (documents) {
            this.datasourceDocuments = documents;
            this.demandeSource.documents = documents;
            this.updateArrayAfterAdd();
          }
        });
      }
    });
  }

  rechercheDocument()
  {
    if(this.documentDTO.SearchString != null && this.documentDTO.SearchString != ""){
      // if (this.demandeSource && this.documentDTO)
      // {
      //   this.documentDTO.Type = "Demande";
      //   this.documentDTO.TypeId = this.demandeSource.id;
      // } else if (this.tier)
      // {
      //   this.documentDTO.Type = "Tier";
      //   this.documentDTO.TypeId = this.tier.id;
      // }  
      if(this.dataSourceDocumentsBackup == undefined){
        this.dataSourceDocumentsBackup = Object.assign([],this.datasourceDocuments) ;
      }
      if(this.datasourceDocuments.length != 0){
        this.documentDTO.Type = this.datasourceDocuments[0].type;
        this.documentDTO.TypeId = this.datasourceDocuments[0].typeId;
        this.documentService.filterDocuments(this.documentDTO).subscribe(documentList => {
        this.datasourceDocuments = Object.assign([],documentList);
  
          this.lengthDocument = this.datasourceDocuments.length;
          if (this.pageIndexDocument * this.pageSizeDocument >= this.lengthDocument && this.pageIndexDocument > 0)
            this.pageIndexDocument--;
          this.loadPageDocument();
          },(error=>{
            console.log(error);
          }))
      }
    }
    else
    {
      if(this.dataSourceDocumentsBackup != undefined){
        this.documentDTO = new DocumentDTO();
        this.datasourceDocuments = Object.assign([],this.dataSourceDocumentsBackup);
        this.updateArrayAfterAdd();
        this.loadPageDocument();
      }
    }
  }
    

}
