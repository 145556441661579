export class gChatDTO {
    constructor(texte?: string) {
        this.text = texte;
    }
    public text: string = "";
    public cardsV2: gCardObj[] = [];
}

export class gChatDTOresponse {
    public name: string = "";
    public text: string = "";
    public thread: objGoog = new objGoog();
    public space: objGoog = new objGoog();
}

export class objGoog {
    public name: string = "";
}

export class gCardObj {
    constructor(headTitle: string) {
        this.card.header.title = headTitle;
    }
    public card: gCard = new gCard();
}

export class gCard {
    constructor(headTitle?: string) {
        this.header.title = headTitle;
    }
    header: gHeader = new gHeader();
    sections: gSection[] = [];
}

export class gHeader {
    constructor(title?: string) {
        this.title = title;
    }
    public title: string = "";
    public imageUrl: string = "";
}

export class gSection {
    constructor(headStr?: string, sectionTxt?: string, collapsBool?: boolean) {
        this.header = headStr;
        if (collapsBool)
            this.collapsible = collapsBool;
        this.widgets[0].textParagraph.text = sectionTxt;
    }
    public header: string = "";
    public collapsible: boolean = false;
    public widgets: gTextParagraphObj[] = [new gTextParagraphObj()];
}

export class gTextParagraphObj {
    constructor(texte?: string) {
        this.textParagraph.text = texte;
    }
    public textParagraph: gTextParagraph = new gTextParagraph();
}

export class gTextParagraph {
    constructor(texte?: string) {
        this.text = texte;
    }
    public text: string = "";
}