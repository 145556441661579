import { DevisApiService } from './devis-api.service';
import { DevisBasicService } from './devis-basic.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TablePrestationClientService extends DevisBasicService {

  constructor(
    http: HttpClient,
    apiService: DevisApiService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, apiService);
      this.serviceUrl = apiService.ROUTETABLEPRESTATIONCLIENT;
  }
}
