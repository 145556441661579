import { HostnameService } from './hostname.service';
import { CacheService } from './cache.service';
import { ApiService } from '../core/services/api.service';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { SessionService } from './session.service';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  helper: JwtHelperService;
  private maxRetry = 1;
  private counterRetry = 0;

  constructor(private inj: Injector,
              private IntercomToastrService: IntercomToastrService,
              private srvApi: ApiService,
              private hostnameService: HostnameService,
              private cacheService: CacheService
  ) {
    this.helper = new JwtHelperService();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url !== this.hostnameService.getUrl() + this.srvApi.ROUTEGOOGLELOGIN && request.url.startsWith(this.hostnameService.getUrl())) {
      const httpResponse = this.handleRequests(request, next);
      if (!httpResponse) {
        return next.handle(request);
      } else {
        return httpResponse.pipe(
          catchError(error => {
          throw error;
        }));
      }
    } else {
      return next.handle(request);
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (request.url.search('https://script.googleapis.com')) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      return request;
    }
  }

  private catchErrors(request: HttpRequest<any>, next: HttpHandler, error): Observable<any> {
    if (error instanceof HttpErrorResponse && error.status === 401 && this.counterRetry < this.maxRetry) {
      this.counterRetry++;
      return this.handleRequests(request, next);
    } else if (error instanceof HttpErrorResponse && error.status === 403) {
      this.counterRetry = 0;
      this.IntercomToastrService.error('Vous n\'avez pas les droits pour réaliser cette action.', 'Action Interdite');
      return throwError(error);
    } else if (error instanceof HttpErrorResponse && error.status === 400) {
      this.counterRetry = 0;
      return throwError(error);
    } else if (error instanceof HttpErrorResponse && error.status === 408) {
      this.counterRetry = 0;
      this.IntercomToastrService.warning('Nous n\'arrivons pas à envoyer les informations aux serveur.',
        'Délais d\'attente maximum atteint');
      return throwError(error);
    } else {
      this.counterRetry = 0;
      return throwError(error);
    }
  }

  private handleRequests(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let auth = this.inj.get(SessionService);
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      try {
        return auth.refreshTokens().pipe(
          switchMap((token: string) => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next(token);
              return next.handle(this.addToken(request, token));
          }),
          catchError((error) => {
            console.log("catchError TokenInterceptor");
            console.log(error);
            return this.catchErrors(request, next, error);
            })
          );
        } catch {
          this.isRefreshing = false;
          auth.logout().subscribe();
        }
      } else {
        return this.refreshTokenSubject.pipe(
          catchError(error => {
            this.isRefreshing = false;
            console.log(error);
            throw "refreshTokenSubject Error"; 
          }),
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
              return next.handle(this.addToken(request, jwt));
          }), catchError(error => this.catchErrors(request, next, error)));
        }
  }
}
