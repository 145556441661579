<div class="col-md-12 center">
  <h3>
    {{data.title}}
  </h3>
</div>
<div class="col-md-12 center">
  {{data.message}}
</div>
<div class="row">
  <div class="col-md-12 flex-container">
    <div class=" col-md-6 subDivContainer">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>
    <div class="col-md-subDivContainer">
      <button mat-flat-button color="success" class="button-white" (click)="onModalConfirm()">Confirmer</button>
    </div>
  </div>
</div>
