import { BasicMock } from '../basic-mock/basic.mock';
import { TypeActionModel } from '../../models/type-action.model';
import { MairieModel } from '../../models/tier/mairie.model';
import { Injectable } from "@angular/core";

@Injectable()
export class MairieMock extends BasicMock  {
  constructor() {
    super();
    (<MairieModel[]> this.objets) = [
      /*{
        id: 5,
        clientNumber: 'C0015782004', commune: 'ville4', commercialId: 1,
        agenceId: 1, identifiantJason: 'idJason4', libelle: 'ville4',
        natureId: 1, typeTier: 'Mairie',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 6,
        clientNumber: 'C0015782005', commune: 'ville5', commercialId: 2,
        agenceId: 1, identifiantJason: 'idJason5',
        natureId: 1, typeTier: 'Mairie', libelle: 'ville5',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 7,
        clientNumber: 'C0015782006', commune: 'ville6', commercialId: 1,
        agenceId: 1,  identifiantJason: 'idJason6',
        natureId: 3, typeTier: 'Mairie', libelle: 'ville6',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      },
      {
        id: 8,
        clientNumber: 'C0015782007', commune: 'ville7', commercialId: 1,
        agenceId: 1,  identifiantJason: 'idJason7',
        natureId: 1, typeTier: 'Mairie', libelle: 'ville7',
        numCommOblig: false, adresseIdentique: false, regleSurPlace: false, tva: null
      }*/
    ];
  }

  getFromObjet(objRecherche: MairieModel): MairieModel[] {
    return Object.assign([], this.objets.filter((objTab: MairieModel) => this.sameTier(objTab, objRecherche)));
  }

  post(obj: MairieModel): MairieModel {
    obj.libelle = obj.ville;
    return <MairieModel>super.post(obj);
  }

  put(id, obj: MairieModel): MairieModel {
    obj.libelle = obj.ville;
    return <MairieModel>super.put(id, obj);
  }
  sameTier(objTab: MairieModel, objRecherche: MairieModel): boolean{
    if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
        return false;
    }
    else if (objRecherche.agenceId !== undefined && objRecherche.agenceId !== 0 && objTab.agenceId === objRecherche.agenceId) {
        return false;
    }
    else if (objRecherche.commercialId !== undefined && objRecherche.commercialId !== 0 && objTab.commercialId === objRecherche.commercialId) {
      return false;
    }
    else if (objRecherche.identifiantJason !== undefined  && objRecherche.identifiantJason.length !== 0 && objTab.identifiantJason.indexOf(objRecherche.identifiantJason) === -1) {
      return false;
    }
    else if (objRecherche.natureId !== undefined && objRecherche.natureId !== 0 && objTab.natureId === objRecherche.natureId) {
      return false;
    }
    else if (objRecherche.clientNumber !== undefined && objRecherche.clientNumber.length !== 0 && objTab.clientNumber.toLowerCase().indexOf(objRecherche.clientNumber.toLowerCase()) === -1) {
      return false;
    }
    return this.sameMairie(objTab, objRecherche);
  }

  sameMairie(objTab: MairieModel, objRecherche: MairieModel): boolean{
    if (objRecherche.ville !== undefined  && objTab.ville.toLowerCase().indexOf(objRecherche.ville.toLowerCase()) === -1) {
      return false;
    }
    return true;
  }
}
