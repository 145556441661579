import { TierModel } from './tier.model';

export class SyndicModel extends TierModel {
  public raisonSociale = '';
  public siretSiren = '';
  public marqueEnseigne: string;
  public typeTier = 'Syndic';

  constructor(tiers?: TierModel) {
    super(tiers);
    if (tiers) {
      this.raisonSociale = tiers.libelle;
    }
  }

}

