<mat-drawer-container class="container-position">
  <mat-drawer #sideNav mode="side" opened="false" position="end" class="drawer" style="Overflow-y:scroll;">
    <div>
      <br />
      <div class="row">
        <div class="col-sm-4 margin_bm_20">
          <h4>Filtres</h4>
        </div>
        <div class="col-sm-6 margin_bm_20">
          <button mat-flat-button color="default" (click)="clearFiltre()">
            <mat-icon>settings_backup_restore</mat-icon> Réinitialiser
          </button>
        </div>
      </div>
    </div>
    <br />
    <div class="data-class" *ngIf="filtreData" style="padding-bottom: 10px;">
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-tiers #autocompleteTiers [tierId]="filtreData.tierId" [label]="labelTiers" [canBeDisabled]="false"
            (tiersChangeObject)="onTiersChanged($event, 'Tier')"></app-autocomplete-tiers>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <mat-form-field class="full-width">
            <mat-label>Objet</mat-label>
            <input [(ngModel)]="filtreData.objet" matInput (ngModelChange)="onChange($event)" name="Objet"
              style="font-size: small;" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" style="margin-top: 5px; margin-bottom: 5px;">
          <app-custom-select-multiple-option (selected)="onSelectOptionChange($event, 'Type')" [(reset)]="resetSelect"
            [InputvaluesList]="listTypesActionsString" [InputSelectedValues]="listTypesActionsUpdated" [title]="'Type'">
          </app-custom-select-multiple-option>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" style="margin-top: 5px; margin-bottom: 5px;">
          <app-custom-select-multiple-option (selected)="onSelectOptionChange($event, 'Statut')" [(reset)]="resetSelect"
            [InputvaluesList]="listStatus" [InputSelectedValues]="listStatusUpdated" [title]="'Statut'">
          </app-custom-select-multiple-option>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <mat-form-field class="full-width">
            <mat-label>Date de création</mat-label>
            <mat-date-range-input [rangePicker]="picker3">
              <input matStartDate [(ngModel)]="this.filtreData.startDCreationAction" (ngModelChange)="onChange($event)"
                name="DCAction">
              <input matEndDate [(ngModel)]="filtreData.endDCreationAction" (ngModelChange)="onChange($event)"
                name="DCAction">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">date non valide</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">date non valide</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <mat-form-field class="full-width">
            <mat-label>Date d'échéance</mat-label>
            <mat-date-range-input [rangePicker]="picker4">
              <input matStartDate [(ngModel)]="this.filtreData.startDRealisationAction"
                (ngModelChange)="onChange($event)" name="DIAction">
              <input matEndDate [(ngModel)]="this.filtreData.endDRealisationAction" (ngModelChange)="onChange($event)"
                name="DIAction">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">date non valide</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">date non valide</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-with-num (objetChange)="onChange($event)" libelle="Numéro de la demande"
            [objets]="numDemandes" [(objet)]="filtreData.numDemande" name="numeroDemande" [required]="false"
            (loadData)="loadData($event)">
          </app-autocomplete-with-num>
        </div>
      </div>
      <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-societe-multiple-select [eventsSubjectSocieteArray]="eventsSubjectSocieteArray" (updated)="societeChange($event)">
          </app-autocomplete-societe-multiple-select>
        </div>
      </div>
      <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-agence-multiple-select [eventsSubjectAgenceArray]="eventsSubjectAgenceArray" [agencesIdsArray]="selectedAgenceIdsArray" 
          [inputSocietesString]="selectedSocietesString"
            (updated)="agenceChange($event)">
          </app-autocomplete-agence-multiple-select>
        </div>
      </div>

      <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectRealisateurArray" (updated)="realisateurChange($event)"
          [title]="'réalisateur'">
          </app-autocomplete-realisateur-multiple-selection>
        </div>
      </div>
  
      <div class="row ">
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-realisateur-multiple-selection [eventsSubjectUserArray]="eventsSubjectCreateurArray" (updated)="createurChange($event)"
          [title]="'créateur'">
          </app-autocomplete-realisateur-multiple-selection>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <mat-card style="margin:20px">
      <mat-card-header>
        <mat-card-title style="display: flex; justify-content: space-between; align-items: center;">
          <h3>Liste des tâches</h3>
        </mat-card-title>
        <span class="filter-button">
          <mat-card-title>
            <button mat-flat-button color="success" type="button" (click)="onExport()">
              <mat-icon>import_export</mat-icon>Export
            </button>
            <button mat-flat-button color="default" (click)="sideNav.toggle(); this.load()" >
              <mat-icon id="icon">{{
                this.nbFilterApplied == 0 ? "filter_list" :
                this.nbFilterApplied == 1 ? "filter_1" : 
                this.nbFilterApplied == 2 ? "filter_2" : 
                this.nbFilterApplied == 3 ? "filter_3" : 
                this.nbFilterApplied == 4 ? "filter_4" : 
                this.nbFilterApplied == 5 ? "filter_5" : 
                this.nbFilterApplied == 6 ? "filter_6" : 
                this.nbFilterApplied == 7 ? "filter_7" : 
                this.nbFilterApplied == 8 ? "filter_8" : 
                this.nbFilterApplied == 9 ? "filter_9" : "filter_9_plus"
                  }}</mat-icon>Filtres
            </button>
          </mat-card-title>
        </span>
      </mat-card-header>
      <mat-card-content>
        <div class="tabResponsives">
          <app-array-action class="mat-tablee table-inner-scroll-container" 
            [datasource]="actionList"
            (actionOpen)="onOpenAction($event)"
            [simpleQueryResponse]="simpleQueryResponse1"
            (pageChange)="pageChange($event)"
          ></app-array-action>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-drawer-content>
</mat-drawer-container>