import { SimpleQueryResponse } from './../../shared/simple_query/simpleQueryResponse';
import { SimpleQueryUser } from './../../shared/simple_query/simpleQueryUser';
import { BasicModel } from './../../shared/models/basic-models/basic.model';
import { of, Observable, from } from 'rxjs';
import { SiteGeoService } from './../../services/site-geo.service';
import { UserService } from './../../services/user/user.service';
import { ActiviteService } from './../../services/prestation.service';
import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, Injector, ViewChild } from '@angular/core';
import { TierModel } from './../../shared/models/tier/tier.model';
import { TrieTabService } from '../../services/trie-tab.service';
import { ModalsService } from '../../../shared/modals.service';
import { ModalAjoutProtocoleComponent } from './modal-ajout-protocole/modal-ajout-protocole.component';
import { Router, NavigationEnd } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalInfoOkComponent } from '../divers/modals/modal-info-ok.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SiteGeoModel } from './../../shared/models/site-geo.model';
import { ActiviteModel } from './../../shared/models/prestation.model';
import { UserModel } from './../../shared/models/users/user.model';
import { concatMap, defaultIfEmpty, reduce, switchMap, tap } from 'rxjs/operators';
import { ProtocoleService } from '../../services/protocole.service';
import { ProtocoleModel } from '../../shared/models/protocole.model';

export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-protocole',
  templateUrl: './protocole.component.html',
  styleUrls: ['./protocole.component.scss']
})

export class ProtocoleComponent implements OnChanges {

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated = new EventEmitter<boolean>();
  @Input() tier: TierModel;
  private standardWidth = '90%';
  private standardHeight = '99vh';
  objet: SimpleQueryUser = new SimpleQueryUser();

  mySubscription: any;
  result: boolean;
  loading = false;
  loadingExport = false;
  selectedAction: ProtocoleModel;
  displayedColumns: string[] = ['adresse', 'prestation', 'realisateur', 'protocole', 'modifications'];
  dataSource: ProtocoleModel[];
  matDataSource = new MatTableDataSource<ProtocoleModel>();
  pageEvent: PageEvent;
  sortState: Sort = {active: 'adresse', direction: 'desc'};
  length: number;
  pageIndex: number;
  pageSize = 10;
  prestationString: string[] = [];
  prestations: ActiviteModel[] = [];
  users: UserModel[] = [];
  protocoles: ProtocoleModel[] = [];
  oldProtocoles: ProtocoleModel[] = [];
  dataSourceCopy: ProtocoleModel[] = [];
  nb = 0;
  avancement = 0;
  message = '';
  private modalsService: ModalsService;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

constructor( private router: Router,
  public dialog: MatDialog,
  private comp: TrieTabService,
  injector: Injector,
  private toastr: IntercomToastrService,
  private siteGeoService: SiteGeoService,
  private IntercomToastrService: IntercomToastrService,
  private prestationService: ActiviteService,
  private userService: UserService,
  private protocoleService: ProtocoleService) {
    this.userService.getAll().subscribe((users: UserModel[]) => this.users = users);
    this.prestationService.getAll().subscribe((prestations: ActiviteModel[]) => {
      for (let i = 0; i < prestations.length; i++) {
        this.prestationString.push(prestations[i].libelle);
        this.prestations = prestations;
      }
    });
    this.modalsService = injector.get('ModalsService');
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  

  refresh(): void {
    if (this.tier !== undefined && this.tier.id !== undefined) {
      this.pageIndex = 0;
      this.loadProtocoles();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tier !== undefined && this.tier.id !== undefined) {
        this.objet.query = '';
        this.objet.pageNumber = 0;
        this.objet.pageSize = this.pageSize;
        this.matDataSource.paginator = this.paginator;
        this.loadProtocoles();
    }
  }

  onAddProtocole(): void {
    const protocole = new ProtocoleModel();
    protocole.tier = Object.create(this.tier);
      const dialogRef = this.dialog.open(ModalAjoutProtocoleComponent, {
        disableClose: true,
        width: this.standardWidth,
        maxHeight: this.standardHeight,
        data: {
          tierId: this.tier.id,
          protocole: protocole,
          result: this.result
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.onUpdated.emit(true);
        this.result = result;
        if (this.result) {
          this.loadProtocoles();
        }
      });

  }

  onOpenProtocole(protocole: ProtocoleModel): void {
    this.protocoleService.get(protocole.id).subscribe((protocole: ProtocoleModel ) => {
      this.modalsService.openProtocole(protocole).subscribe(result => {
        if  (result) {
          this.loadProtocoles();
        }
      });
    });
  }

  changePage(event?: PageEvent) {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadProtocoles();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadProtocoles();
  }

  loadPage() {
    this.matDataSource =  this.comp.sortData(this.sortState, this.dataSource, this.matDataSource, this.pageIndex, this.pageSize);
    if (this.matDataSource === undefined) {
        const data = this.dataSource.slice();
        this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
    }
  }

  deleteProtocole(protocole: ProtocoleModel): void {
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: 'Suppression du protocole',
        message: 'Êtes-vous sur de vouloir supprimer le protocole ?',
        icone: 'warning_amber',
        iconColor: 'accent',
        color: 'accent'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.protocoleService.delete(protocole.id).subscribe((response: ProtocoleModel) => {
          this.IntercomToastrService.success('Le protocole a bien été supprimé');
          this.dataSource.forEach((element, index) => {
            if (element.id === response.id) {
              this.dataSource.splice(index, 1);
              this.matDataSource = new MatTableDataSource(this.dataSource.slice(0, this.pageSize));
              this.length = this.dataSource.length;
            }
          });
        });
      }
    });
  }

  export(): void {
    this.loadingExport = true;
    this.protocoleService.getFromTier(this.tier.id).subscribe((protocoles: ProtocoleModel[]) => {
      this.protocoleService.export(protocoles).subscribe(url => {
        if (url && url !== '') {
          window.open(url, '_blank');
        } else {
          this.IntercomToastrService.error('Une erreur est survenue lors de l\'exportation', '',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        }
        this.loadingExport = false;
      }, error => {
        this.IntercomToastrService.error('Une erreur est survenue lors de l\'exportation', '',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
          this.loadingExport = false;
      });
    });
  }


  openModalUrl() {
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: 'Importation des protocoles',
        message: 'Veuillez partager le document au groupe Veolia. Puis, entrez l\'url de partage du document à importer',
        icone: 'info_amber',
        color: 'primary'
      }
    });
    dialogRef.afterClosed().subscribe(url => {
      if (url && url.trim() !== '') {
        this.objet.query = '';
        this.oldProtocoles = [];
        this.loading = true;
        this.protocoleService.import(url, this.tier.libelle).subscribe(protocoles => {
          // afficher l'url ou louvrir
          if (protocoles) {
            // verification des champs
            protocoles = JSON.parse(protocoles);
            this.protocoles = this.parseReponse(protocoles);
            this.message = 'récupération des protocoles existants';
            this.protocoleService.getFromTier(this.tier.id).subscribe((allProtocoles: ProtocoleModel[]) => {
              for (let i = 0; i < allProtocoles.length; i++) {
                this.oldProtocoles.push(allProtocoles[i]);
              }
              try {
                this.dataSourceCopy = [];
                this.setAdresses(this.protocoles, this.oldProtocoles);
              } catch (e: any) {
                this.IntercomToastrService.error((e as Error).message);
                this.loading = false;
              }
            });
          } else {
            if (protocoles === null) {
              this.IntercomToastrService.error('Le tier du fichier d\'importation ne correspond pas au tier selectionné', 'Erreur d\'import',
              {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
            } else {
              this.IntercomToastrService.error('Une erreur est survenue lors de l\'importation', 'Assurez-vous d\'avoir partagé le document et d\'envoyer l\'url de partage',
              {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
            }
            this.loading = false;
          }
        }, error => {
          if (error.error.message == "JSONP injected script did not invoke callback.") {
            this.IntercomToastrService.error('Une erreur est survenue lors de l\'importation', 'Assurez-vous d\'avoir partagé le document au groupe', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
          } else {
            this.IntercomToastrService.error(error, '', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
          }
          this.loading = false;
        });
      } else {
        if ( url !== false && url !== undefined) {
          this.IntercomToastrService.error('Une erreur est survenue lors de l\'importation', 'Assurez-vous d\'avoir partagé le document et d\'envoyer l\'url de partage', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        }
      }
    });
  }

  saveProtocoles(protocole: ProtocoleModel, oldProtocoles: number[], protocoles: ProtocoleModel[]) {
        this.nb = 0;
        protocole.tier = null;
        protocole.prestation = null;
        protocole.siteGeo = null;
        protocole.realisateur = null;
        if ( protocole.protocole !== '' && protocole.protocole ) {
            this.protocoleService.post(protocole).subscribe((p: ProtocoleModel) => {
              this.dataSourceCopy.push(p);
              if (this.dataSourceCopy.length === protocoles.length) {
                if (oldProtocoles.length === 0) {
                  this.refresh();
                  this.toastr.success('', 'Importation réussi');
                  this.loading = false;
                  this.nb = 0;
                  this.dataSourceCopy = [];
                }
                for (let i = 0; i < oldProtocoles.length; i++) {
                  setTimeout(() =>  {
                    this.protocoleService.delete(oldProtocoles[i]).subscribe(() => {
                      this.nb ++;
                      if (oldProtocoles.length === this.nb) {
                        this.refresh();
                        this.toastr.success('', 'Importation réussi', {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
                        this.loading = false;
                        this.nb = 0;
                        this.dataSourceCopy = [];
                      }
                    });
                  }, 1000 * (i / 100));
                }
              }
            });
        } else {
            this.toastr.warning('Le champ Protocole est obligatoire.', 'Impossible d\'enregistrer le protocole',
            {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
            this.loading = false;
        }
  }

  updateAdresse(adresseTiers: SiteGeoModel, adresseFichier: SiteGeoModel, i: number) {
    try {
      if ( adresseTiers.email.toLowerCase() !== adresseFichier.email.toLowerCase() ||
      adresseTiers.telephone !== adresseFichier.telephone.toLowerCase() ||
      adresseTiers.commentaire.toLowerCase() !== adresseFichier.commentaire.toLowerCase() ||
      adresseTiers.complementAdresse.toLowerCase() !== adresseFichier.complementAdresse.toLowerCase() ||
      adresseTiers.libelle.toLowerCase() !== adresseFichier.libelle.toLowerCase() ) {
        adresseFichier.id = adresseTiers.id;
       this.siteGeoService.put(adresseFichier).subscribe((site: SiteGeoModel) => {
         adresseFichier = site;
         this.toastr.success('', 'Mise à jour de l\'adresse ' + adresseFichier.libelle );
       });
    }
    } catch (e) {
      this.toastr.error('', 'Les informations du site du protocole ' + (i + 1) + ' sont incorrectes',
      {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
    }
  }

  setAdresses(protocoles: ProtocoleModel[], oldProtocoles: ProtocoleModel[]) {
    let sites = [];
    const sitesToAdd = [];
    const sitesToUpdate = [];
    this.avancement = 0;
    this.message = 'récupération des adresses existantes';
    this.siteGeoService.getFromTier(protocoles[0].tierId).subscribe((adresses: SiteGeoModel[]) => {
      this.avancement = 1 / 5;
      sites = adresses;
      try {
        this.message = 'vérification des adresses à créer';
        for (let i = 0; i < protocoles.length; i++) {
          this.avancement = 1 / 3 * (1 - (protocoles.length - i) / protocoles.length);
          if (!this.protocoleService.beforeSave(protocoles[i])) {
            this.toastr.error('Le message du protocole ' + (i + 1) + ' est incorrecte', '',
            {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
            this.loading = false;
            throw new Error('Le message du protocole ' + (i + 1) + ' est incorrecte');
          }
          if (protocoles[i].siteGeo) {
            if (!this.siteGeoService.beforeSave(protocoles[i].siteGeo)) {
              this.toastr.error('L\'adresse du protocole ' + (i + 1) + ' est incorrecte', '',
              {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
              this.loading = false;
              throw new Error('L\'adresse du protocole ' + (i + 1) + ' est incorrecte');
            }
            protocoles[i].siteGeo.adresse = protocoles[i].siteGeo.adresse.toLowerCase().trim();
            protocoles[i].siteGeo.codePostal = protocoles[i].siteGeo.codePostal.trim();
            protocoles[i].siteGeo.ville = (protocoles[i].siteGeo.ville.trim().slice(0, 1).toUpperCase() + protocoles[i].siteGeo.ville.trim().slice(1).toLowerCase());
            sites.forEach(site => {
              site.adresse = site.adresse.toLowerCase().trim();
              site.codePostal = site.codePostal.trim();
              site.ville = (site.ville.trim().slice(0, 1).toUpperCase() + site.ville.trim().slice(1).toLowerCase());
            });
          }
          if (protocoles[i].prestation) {
            if (!this.prestationService.beforeSave(protocoles[i].prestation)) {
              this.toastr.error('La prestation du protocole ' + (i + 1) + ' est incorrecte', '',
              {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
              this.loading = false;
              throw new Error('La prestation du protocole ' + (i + 1) + ' est incorrecte');
            }
          }

          
        }

        this.avancement = 2 / 5;
        for (let i = 0; i < protocoles.length; i++) {

          if (protocoles[i].siteGeo) {
            if (!sitesToUpdate.find(site =>
                  site.adresse === protocoles[i].siteGeo.adresse &&
                  site.ville === protocoles[i].siteGeo.ville &&
                  site.codePostal === protocoles[i].siteGeo.codePostal) &&
               !sitesToAdd.find(site =>
                  site.adresse === protocoles[i].siteGeo.adresse &&
                  site.ville === protocoles[i].siteGeo.ville &&
                  site.codePostal === protocoles[i].siteGeo.codePostal)) {

              const exist = sites.find(site =>
                site.adresse === protocoles[i].siteGeo.adresse &&
                site.ville === protocoles[i].siteGeo.ville &&
                site.codePostal === protocoles[i].siteGeo.codePostal);

              if (exist) {
                protocoles[i].siteGeo.id = exist.id;
                protocoles[i].siteGeoId = exist.id;
                protocoles[i].siteGeo.agenceId = exist.agenceId;
              } else {
                sitesToAdd.push(protocoles[i].siteGeo);
              }
            } else {
            }
          }
        }

        this.avancement = 3 / 6;
        const maxSize = 1000;
        /*for (let i = 0; i < arraySitesAdd.length; i++) {
          arraySitesAdd[i] = of([]).pipe(delay(delaytime * i),
          switchMap(_ => {
            return this.siteGeoService.postAllFromProtocole(sitesToAdd.slice(i * maxSize, (i + 1) * maxSize)).pipe(tap(res => {
              for (const protocole of protocoles) {
                if (!protocole.siteGeoId && protocole.siteGeo) {
                  protocole.siteGeo = res.find(site =>
                    site.adresse === protocole.siteGeo.adresse &&
                    site.ville === protocole.siteGeo.ville &&
                    site.codePostal === protocole.siteGeo.codePostal);
                    if (protocole.siteGeo) {
                      protocole.siteGeoId = protocole.siteGeo.id;
                    }
                }
              }
            }));
          }));
        }*/
        // change the post all sites geo to sequential method  
        let arraySitesAdd: Observable<SiteGeoModel[]>;
        let paquetsSite = new Array(Math.ceil(sitesToAdd.length / maxSize));
        for (let i = 0; i < paquetsSite.length; i++) {
          paquetsSite[i] = sitesToAdd.slice(i * maxSize, (i + 1) * maxSize)
        } 
        if(paquetsSite.length > 0){
          arraySitesAdd = from(paquetsSite).pipe(
            concatMap((paquet, index) =>{
              return this.siteGeoService.postAllFromProtocole(paquet).pipe(tap(res => {
                for (const protocole of protocoles) {
                  if (!protocole.siteGeoId && protocole.siteGeo) {
                    protocole.siteGeo = res.find(site =>
                      site.adresse === protocole.siteGeo.adresse &&
                      site.ville === protocole.siteGeo.ville &&
                      site.codePostal === protocole.siteGeo.codePostal);
                      if (protocole.siteGeo) {
                        protocole.siteGeoId = protocole.siteGeo.id;
                      }
                  }
                }
              }));
            }),
            reduce((acc, value) => value)
          );
        }else {
          arraySitesAdd = of([]);
        }
        

        /*const arrayProtocoleAdd: Observable<ProtocoleModel[]>[] = new Array(Math.ceil(protocoles.length / maxSize));
        for (let i = 0; i < arrayProtocoleAdd.length; i++) {
          arrayProtocoleAdd[i] = of([]).pipe(
          switchMap(_ => {
            return this.protocoleService.postAll(protocoles.slice(i * maxSize, (i + 1) * maxSize));
          }));
        }*/
        // change the post all protocoles to sequential method
        let arrayProtocoleAdd: Observable<ProtocoleModel[]>;
        let paquets = new Array(Math.ceil(protocoles.length / maxSize));
        for (let i = 0; i < paquets.length; i++) {
          paquets[i] = protocoles.slice(i * maxSize, (i + 1) * maxSize)
        } 

        arrayProtocoleAdd = from(paquets).pipe(
          concatMap((paquet, index) =>{
            return this.protocoleService.postAll(paquet)
          }),
          reduce((acc, value) => value)
        );
        
        /*const arrayProtocoleDelete: Observable<ProtocoleModel[]>[] = new Array(Math.ceil(oldProtocoles.length / maxSize));
        for (let i = 0; i < arrayProtocoleDelete.length; i++) {
          arrayProtocoleDelete[i] = of([]).pipe(delay(delaytime * (i+1)),
          switchMap(_ => {
            return this.protocoleService.deleteAll(oldProtocoles.slice(i * maxSize, (i + 1) * maxSize));
          }));
        }*/
        // change the delete all protocoles to sequential method
        let arrayProtocoleDelete: Observable<ProtocoleModel[]>; 
        let paquetsOld = new Array(Math.ceil(oldProtocoles.length / maxSize));
        for (let i = 0; i < paquetsOld.length; i++) {
          paquetsOld[i] = oldProtocoles.slice(i * maxSize, (i + 1) * maxSize)
        } 

        arrayProtocoleDelete = from(paquetsOld).pipe(
          concatMap((paquetOld, index) =>{
            return this.protocoleService.deleteAll(paquetOld)
          }),
          reduce((acc, value) => value)
        );
        
        let obsSitesAdd = arraySitesAdd;
        let obsProtocolesAdd = arrayProtocoleAdd;
        const obsProtocolesDelete = arrayProtocoleDelete;

        this.message = 'création des nouvelles adresses';
        obsSitesAdd.pipe(
          switchMap((site) => {
            this.avancement = 4 / 6;
            this.message = 'création des nouveaux protocoles';
            return obsProtocolesAdd;
          }),
          defaultIfEmpty([]),
          switchMap((protocolesAdd) => {
            this.avancement = 5 / 6;
            this.message = 'suppression des anciens protocoles';
            return obsProtocolesDelete;
          }),
          defaultIfEmpty([]),
        ).subscribe(_ => {
          this.avancement = 6 / 6;
          this.refresh();
          this.loading = false;
          obsProtocolesAdd = undefined;
          obsSitesAdd = undefined;
        });
      } catch (e) {
        this.loading = false;
        this.toastr.error('', 'Les informations des protocole sont erroné '+ e);
        throw e;
      }
    });
  }

  createAdresse(adresse: SiteGeoModel, i: number): Observable<BasicModel> {
    if (adresse.id) {
      return of(adresse);
    }
    try {
      const val = this.siteGeoService.post(adresse);
      this.toastr.success('', 'création de l\'adresse ' + adresse.libelle );
      return val;
    } catch (e) {
      this.toastr.error('', 'Les informations du site du protocole ' + (i + 1) + ' sont incorrectes',
      {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
      throw new Error(e);
    }
  }



  parseReponse(response: any): ProtocoleModel[] {
  const protocoles = [];
  if (response.length !== 0 && response !== null) {
    // les lignes
    for (let i = 0; i < response.length; i++) {
      const protocole = new ProtocoleModel();
      // champ tiers
      if (response[i].tier !== '' && response[i].tier) {
        if (this.tier.libelle.toLowerCase() !== response[i].tier.toLowerCase()) {
          this.loading = false;
          this.toastr.error('Le tiers ' + response[i].tier + ' ne corresponds pas au tiers ' + this.tier.libelle, 'Format incorrect',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
          throw new Error('Format incorrect');
        }
      }
      protocole.tier = this.tier;
      protocole.tierId = protocole.tier ? protocole.tier.id : null;
      // champ site geo
      let site = new SiteGeoModel();
      if (response[i].libelle === '' || response[i].libelle === 'Tous'
            || response[i].ville === '' || response[i].codepostal === '' ) {
        site = null;
      } else {
        site.libelle = response[i].libelle;
        site.ville = response[i].ville ;
        site.codePostal = response[i].codepostal ; // return int
        site.codePostal = site.codePostal.toString(); // cast to string
        site.adresse = response[i].adresse;
        site.complementAdresse = response[i].complementadresse;
        site.telephone = response[i].telephone;
        site.commentaire = response[i].commentaire;
        site.email = response[i].email;
        site.tierId = this.tier.id;
      }
      protocole.siteGeo = site;
      protocole.siteGeoId = null;

      // champ realisateur
      if (response[i].realisateur !== '' && response[i].realisateur) {
        const realisateur = this.users.find(usr =>
          (usr.nom + ' ' + usr.prenom).toLowerCase().trim() === response[i].realisateur.toLowerCase().trim());
        if (realisateur) {
          protocole.realisateur = realisateur;
          protocole.realisateurId =  realisateur.id;
        } else {
          this.loading = false;
          this.toastr.error('Le réalisateur ' + response[i].realisateur + ' non trouvé', 'Format incorrect',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
          throw new Error('Format incorrect');
        }
      } else {
        protocole.realisateur = null;
        protocole.realisateurId =  null;
      }

      // champ prestation
      if (response[i].prestation !== '' && response[i].prestation &&
          response[i].prestation.toLowerCase() !== 'toutes' &&
          response[i].prestation.toLowerCase() !== 'tous') {
        const prestation = this.prestations.find(pres =>
          pres.libelle.toLowerCase().trim() === response[i].prestation.toLowerCase().trim());
        if (prestation) {
        protocole.prestation =  prestation;
        protocole.prestationId = prestation.id ;
        } else {
          this.loading = false;
          this.toastr.error('La prestation ' + response[i].prestation + ' non trouvée', 'Format incorrect',
          {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
          throw new Error('Format incorrect');
        }
      } else {
        protocole.prestation =  null;
        protocole.prestationId = null ;
      }

        // champ protocol
      if (response[i].protocol !== '' && response[i].protocol) {
        protocole.protocole = response[i].protocol;
      } else {
        this.loading = false;
        this.toastr.error('Le protocole est obligatoire', 'Format incorrect',
        {tapToDismiss: true, timeOut: 0, extendedTimeOut: 0});
        throw new Error('Format incorrect');
      }

      protocoles.push(protocole);
    }
    return protocoles;
  }
  return [];
  }

  search() {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadProtocoles();
  }

  loadProtocoles(): void {
    if (this.tier !== undefined && this.tier.id !== undefined) {
      this.protocoleService.getFromSimpleQuery(this.objet, this.tier.id).subscribe((query: SimpleQueryResponse<ProtocoleModel>) => {
        if (query.objets != null) {
          this.dataSource = query.objets;
        } else {
          this.dataSource = [];
        }
        this.length = query.count;
        this.matDataSource = new MatTableDataSource<ProtocoleModel>(this.dataSource);
        this.paginator.length = this.length;
      });
    }
  }


}
