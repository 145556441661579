import { DemandeModel } from "../core/shared/models/demande.model";
import { DescriptionActiviteModel } from "../core/shared/models/description-activite.model";
import { InterlocuteurModel } from "../core/shared/models/interlocuteur.model";
import { AgenceModel } from "../core/shared/models/rsa/agence.model";
import { TierModel } from "../core/shared/models/tier/tier.model";
import { UserModel } from "../core/shared/models/users/user.model";
import { PrestationShared } from "./prestationShared";

export class DevisShared {
    public tiersId: number;
    public agenceId: number;
    public demandeId: number;
    public dCreation: Date;
    public dModification: Date;
    public statut: string;
    public ht: number;
    public ttc: number;
    public nature: string;
    public idRealisateur: number;
    public id: number;
    public idActivite: number;
    public url: string;
    public demandeClient: string;
    public destinataire: string;
    public description: DescriptionActiviteModel = new DescriptionActiviteModel();
    public agence: AgenceModel;
    public tiers: TierModel = new TierModel();
    public demande: DemandeModel = new DemandeModel();
    public interlocuteur: InterlocuteurModel;
    public realisateur: UserModel;
    public prestations: PrestationShared[] = [];
    public zone : {libelle: string, majoration: number};
    public tva : {libelle: string, taux: number, montant: number};
}