import { Injectable } from "@angular/core";

@Injectable()
export class SimpleQueryTiersDTO {
  public query: string;
  public typeTier: "";
  public descending= false;
  public pageNumber = 0;
  public pageSize = 0;
  public order = "";


  public toModel(): SimpleQueryTiersDTO {
    const objet = new SimpleQueryTiersDTO();
    this.getModelValue(objet);
    return objet;
  }

  public getModelValue(objet: SimpleQueryTiersDTO): SimpleQueryTiersDTO {
    //super.getModelValue(objet);
    objet.query = this.query;
    objet.typeTier = this.typeTier;
    objet.descending = this.descending;
    objet.pageNumber = this.pageNumber;
    objet.pageSize = this.pageSize;
    objet.order = this.order;
    return objet;
  }
}
