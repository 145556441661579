import { BasicMock } from './basic-mock/basic.mock';
import { TypeActionModel } from '../models/type-action.model';
import { Injectable } from "@angular/core";

@Injectable()
export class TypeActionMock extends BasicMock  {
  constructor() {
    super();
    (<TypeActionModel[]> this.objets) = [
      {id: 1, libelle: 'Appel'},
      {id: 2, libelle: 'Prise Rdv'},
      {id: 3, libelle: 'Création devis'},
      {id: 4, libelle: 'Visite chantier'},
      {id: 5, libelle: 'Etude Chantier'},
      {id: 6, libelle: 'Note'},
    ];
  }
}
