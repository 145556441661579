import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BasicModel } from '../shared/models/basic-models/basic.model';

@Injectable()
export class TrieTabService {

  sortData(sort: Sort, dataSource, matDataSource, pageIndex, pageSize) {
    if (!sort.active || sort.direction === '') {
      const data = this.loadPage(dataSource, pageIndex, pageSize);
      matDataSource = new MatTableDataSource(data);
      return matDataSource;
    }
    const data = dataSource.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'adresse':
          return compareString(a.adresse, b.adresse, isAsc);
        case 'adresseSiteGeo': {
          if (a.siteGeo !== null && a.siteGeo !== undefined && b.siteGeo !== null && b.siteGeo !== undefined) {
            return compareString(a.siteGeo.ville + ' ' + a.siteGeo.adresse, b.siteGeo.ville + ' ' + b.siteGeo.adresse, isAsc);
          } else {
            return (a.siteGeo === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
          }
        }
        case 'agenceP':
          return a.agence !== null && b.agence !== null ? compare(a.agence.libelle, b.agence.libelle, isAsc)
          : (a.agence === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
        case 'commercialP':
          return a.commercial !== null && b.commercial !== null ? compare(a.commercial.nom, b.commercial.nom, isAsc)
          : (a.commercial === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
        case 'commune':
          return compareString(a.commune, b.commune, isAsc);
        case 'commentaire':
          return compareString(a.commentaire, b.commentaire, isAsc);
        case 'codePostal':
          return compareString(a.codePostal, b.codePostal, isAsc);
        case 'dateInterv':
          return compare(a.dateIntervention, b.dateIntervention, isAsc);
        case 'dateReal':
          return compare(a.dateRealisation, b.dateRealisation, isAsc);
        case 'duree':
          return compare(a.duree, b.duree, isAsc);

        case 'etat':
          return compareString(a.etat, b.etat, isAsc);
        case 'famille':
          return compareString(a.agence.libelle, b.agence.libelle, isAsc);
        case 'fonction':
          return compareString(a.role, b.role, isAsc);

        case 'heure':
          return compare(a.heureDebut, b.heureDebut, isAsc);

        case 'libelle':
          return compareString(a.libelle, b.libelle, isAsc);

        case 'marque':
          return compareString(a.marqueEnseigne, b.marqueEnseigne, isAsc);
        case 'mobile':
          return compareString(a.telSecondaire, b.telSecondaire, isAsc);
        case 'mail':
          return compareString(a.email, b.email, isAsc);

        case 'nom':
          return compareString(a.nom, b.nom, isAsc);
        case 'objetAction':
          return compareString(a.objetAction, b.objetAction, isAsc);
        case 'objetDemande':
          return compareString(a.objet, b.objet, isAsc);
        case 'prenom':
          return compareString(a.prenom, b.prenom, isAsc);
        case 'realisateur':
          return a.realisateur !== null && b.realisateur !== null ?
            compareString(a.realisateur.prenom, b.realisateur.prenom, isAsc)
            : (a.realisateur === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
        case 'raisonSociale':
          return compareString(a.raisonSociale, b.raisonSociale, isAsc);
        case 'siteGeo':
          return a.siteGeoId !== null && b.siteGeoId !== null ? compareString(a.libelleAdresse, b.libelleAdresse, isAsc)
          : (a.siteGeo === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
        case 'statut':
          return compare(a.statut, b.statut, isAsc);
        case 'saisie':
          return a.createur !== null && b.createur !== null ? compareString(a.createur.nom, b.createur.nom, isAsc)
          : (a.createur === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
        case 'type':
          return compareString(a.typeAction.libelle, b.typeAction.libelle, isAsc);
        case 'typeDemAct':
          return compareString(a.typeDemAct, b.typeDemAct, isAsc);
        case 'NumDemande':
          return compareString(a.numDemande, b.numDemande, isAsc);
        case 'typeTous':
          return compareString(a.type, b.type, isAsc);
        case 'typeDemande':
          return compareString(a.typeDemande.libelle, b.typeDemande.libelle, isAsc);
        case 'telPrincipal':
          return compareString(a.telPrincipal, b.telPrincipal, isAsc);
        case 'telInter': {
          if (a.interlocuteur !== null && b.interlocuteur !== null) {
            const libelleTela = a.interlocuteur.telPrincipal + a.interlocuteur.telPrincipal &&
            a.interlocuteur.telSecondaire ? ('/' + a.interlocuteur.telSecondaire ? a.interlocuteur.telSecondaire : '') : '';
          const libelleTelb = b.interlocuteur.telPrincipal + b.interlocuteur.telPrincipal &&
            a.interlocuteur.telSecondaire ? ('/' + b.interlocuteur.telSecondaire ? b.interlocuteur.telSecondaire : '') : '';
            return compareString(libelleTela, libelleTelb, isAsc);
          } else {
            return (a.interlocuteur !== null && (a.interlocuteur.telPrincipal || a.interlocuteur.telSecondaire) ?
            isAsc ? 1 : -1 : isAsc ? -1 : 1);
          }
        }
        case 'telephone':
          return compareString(a.telephone, b.telephone, isAsc);
        case 'typeTier':
          return compareString(a.typeTier, b.typeTier, isAsc);
        case 'ville':
          return compareString(a.ville, b.ville, isAsc);
        case 'tier':
          return a.tier !== null && b.tier !== null ? compareString(a.tier.libelle.trim(), b.tier.libelle.trim(), isAsc)
          : (a.tier === null ? isAsc ? -1 : 1 : isAsc ? 1 : -1);
        default:
          return 0;
      }
    });
    matDataSource = new MatTableDataSource<BasicModel>(this.loadPage(data, pageIndex, pageSize));
    return matDataSource;
  }


  loadPage(dataSource: any[], pageIndex, pageSize): any[] {
    return dataSource.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize);
  }
}

function compareString(a: string, b: string, isAsc: boolean) {
  if (a !== undefined && a !== null) {
    return  a.localeCompare(b) * (isAsc ? 1 : -1);
  }
  return 0;
}

function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

