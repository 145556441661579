import { Component, OnInit,Inject } from '@angular/core';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { ListTiersDemandeComponent } from 'src/app/modules/core/components/prise-appel/liste-tiers-demande/list-tiers-demande.component';
import { ActiviteService } from 'src/app/modules/core/services/prestation.service';
import { ActiviteModel } from 'src/app/modules/core/shared/models/prestation.model';
import { DemandeDTO } from 'src/app/modules/core/shared/dtos/demandeDTO';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';

export interface DialogData {
  result: boolean;
  title: string;
  demandes: DemandeModel[];
  query: SimpleQuery<DemandeDTO>;
}
@Component({
  selector: 'app-modal-historique-demandes',
  templateUrl: './modal-historique-demandes.component.html'
})
export class ModalHistoriqueDemandesComponent implements OnInit {

  title: string;
  activiteMap: { [key: number]: string } = {};
  prestations: ActiviteModel[];
  demandes: DemandeModel[];
  query: SimpleQuery<DemandeDTO>;
  constructor(public dialogRef: MatDialogRef<ListTiersDemandeComponent>,
    private activiteService : ActiviteService,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.demandes = this.data.demandes;
    this.query = this.data.query;
    this.activiteService.getAll().subscribe((activites: ActiviteModel[]) => {
      this.prestations = activites;
      this.activiteMap = activites.reduce((acc, activite) => {
        acc[activite.id] = activite.libelle;
        return acc;
      }, {});
    });
  } 

  onModalCancel(): void {
    this.dialogRef.close(this.data.result);
  }
}
