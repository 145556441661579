<div class=row>
  <div class="col-md-4">
    <h4>
      Documents :
    </h4>
  </div> 
  <div class="col-md-4">
    <mat-form-field *ngIf="this.demandeSource || this.tier">
      <input matInput placeholder="Rechercher un document" [(ngModel)]="this.documentDTO.SearchString" 
          (change)="rechercheDocument()">
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button
      tabindex="-1"
      mat-stroked-button
            color="success"
      (click)="onAddDoc()"
      onclick="this.blur()"
      matTooltip="Ajouter un document"
      matTooltipPosition="below">
      <mat-icon>add</mat-icon> Ajouter un document
    </button>
  </div>
</div>
<div class="table_overflow_x">
  <table mat-table (matSortChange)="trieTabDocument($event)" matSort [dataSource]="matDataSourceDocument" matSortActive="libelle"  matSortDirection="asc" matSortDisableClear>
    <caption></caption>

    <!-- Name Column -->
    <ng-container matColumnDef="libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Libellé</th>
      <td mat-cell *matCellDef="let element">{{ element.libelle }}</td>
    </ng-container>

    <ng-container matColumnDef="dateCrea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d’ajout</th>
      <td mat-cell *matCellDef="let element">{{ element.dateCreation | date:'dd/MM/yyyy HH:mm' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell class="action-btns" *matCellDef="let element">
        <div class="btn-container">
          <button  mat-mini-fab class="tableau-btn" color="basic"   (click)="visualiseDoc(element)">
            <mat-icon  class="tableau-mat-icon">remove_red_eye</mat-icon>
          </button>
        </div>
        <div class="btn-container">
          <button  mat-mini-fab class="tableau-btn"  color="warn" (click)="onDeleteDoc(element)">
            <mat-icon class="tableau-mat-icon">delete_forever</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDocument"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDocument"></tr>
  </table>

  <mat-paginator class="paginator" [length]="lengthDocument" class="mat-paginator-sticky" [pageIndex]="pageIndexDocument" [pageSize]="pageSizeDocument" [pageSizeOptions]="[5, 10, 25, 50]" (page)="changePageDocument($event)">
  </mat-paginator>
</div>