import { ApiService } from '../api.service';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../basic-service/basic.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from 'src/app/modules/shared/session.service';
import { UserModel } from '../../shared/models/users/user.model';
import { SimpleQueryUser } from '../../shared/simple_query/simpleQueryUser';
import { SimpleQueryUserResponse } from '../../shared/simple_query/simpleQueryUserResponse';
import { VerifyFields } from '../../shared/verifyFields';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { DocumentModel } from '../../shared/models/document/document.model';
import { DocumentService } from '../document.service';
@Injectable()

export class UserService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService,
    private documentService: DocumentService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEUSER;
    }

    getFromSimpleQuery(objet: SimpleQueryUser): Observable<SimpleQueryUserResponse> {
      return this.http.post<SimpleQueryUserResponse>(this.apiUrl + this.serviceUrl + 'GetFromSimpleQuery/', objet, {headers: this.header});
    }

    getAllFromModified(): Observable<UserModel[]> {
      return this.http.get<UserModel[]>(this.apiUrl + this.serviceUrl + 'GetAllFromModified/').pipe(catchError( err => this.requestCatchError(err, 'Chargement des utilisateurs')));
    }

    getAllPxo(): Observable<UserModel[]> {
      return this.http.get<UserModel[]>(this.apiUrl + this.serviceUrl + 'GetAllPxo/');
    }

    beforeSave(objet: UserModel): boolean {
      let success = true;
      if (!VerifyFields.verifyName(objet.prenom)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un prenom correct.');
      }
      if (!VerifyFields.verifyName(objet.nom)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un nom correct.');
      }
      if (!VerifyFields.verifyEmail(objet.email)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un email correct.');
      }
      if (!VerifyFields.verifyStringExist(objet.nom)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un nom.');
      }
      if (!VerifyFields.verifyStringExist(objet.email)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un email.');
      }
      if (!super.beforeSave(objet)) {
        success = false;
      }
      return success;
    }

    requestCatchError(error, origineError): Observable<any> {
      let errorMessage = '';
      if (error.status === 400) {
        this.IntercomToastrService.error(error.error, 'Erreur de chargment, veuillez recharger la page');
        errorMessage = `Error: ${error.error.message}`;
        return throwError(errorMessage);
      }
      if (error.error instanceof ErrorEvent) {
          // client-side error
          this.IntercomToastrService.error(origineError + '  a échoué.', 'Erreur de chargment, veuillez recharger la page');
          errorMessage = `Error: ${error.error.message}`;
      } else {
          // server-side error
          this.IntercomToastrService.error(origineError + '  a échoué.', 'Erreur de chargment, veuillez recharger la page');
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(errorMessage);
    }

    deleteDocument(idDoc: number): Observable<number> {
      const avancement = new BehaviorSubject<number>(0);
      this.documentService.delete(idDoc).subscribe(sub => {
        avancement.next(sub.id);
      });
      return avancement.asObservable();
    }
  
    getDocumentById(idDoc: number): Observable<Blob> {
      return this.documentService.getDoc(idDoc);
    }
  
    sendDocument(filename: string, typeId: number, file: File): Observable<number> {
      const avancement = new BehaviorSubject<number>(0);
      this.documentService.sendDoc(filename, typeId, "Utilisateur", file).pipe(filter((doc: HttpEvent<unknown>) => {
        return doc.type === HttpEventType.Response;
      }),
        switchMap((doc: HttpEvent<unknown>) => {
          const document = new DocumentModel();
          document.libelle = filename;
          document.typeId = typeId;
          document.type = "Utilisateur";
          return this.documentService.save(document);
        })).subscribe(sub => {
          avancement.next(sub.id);
        }, error => {
          avancement.next(null);
        });
      return avancement.asObservable();
    }
  
}
