import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()

export class DevisApiService {

  public URLAPI = environment.API_END_POINT_URL;
  public ROUTEDEVIS = 'IntercomDevisAPI/Devis/';
  public ROUTEDOCUMENT = 'IntercomDevisAPI/Document/';
  public ROUTECYCLEVALIDATION = 'IntercomDevisAPI/CycleValidation/';
  public ROUTETABLEPRESTATIONCHANTIER = 'IntercomDevisAPI/TablePrestationChantier/';
  public ROUTETABLEPRESTATIONCLIENT = 'IntercomDevisAPI/TablePrestationClient/';

  public httpOptions;

  constructor () {
  }

}
