import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()

export class ApiService {

  public URLAPI = environment.API_END_POINT_URL;
  public ROUTEAGENCE = 'api/Agence/';
  public ROUTESOCIETE = 'api/Societe/';
  public ROUTEREGION = 'api/Region/';
  public ROUTETIER = 'api/Tier/';
  public ROUTEENTREPRISE = 'api/Entreprise/';
  public ROUTEDROIT = 'api/Droit/';
  public ROUTEMAIRIE = 'api/Mairie/';
  public ROUTEHLM = 'api/Hlm/';
  public ROUTESYNDIC = 'api/Syndic/';
  public ROUTEPARTICULIER = 'api/Particulier/';
  public ROUTEUSER = 'api/Utilisateur/';
  public ROUTEASSISTANT = 'api/Assistant/';
  public ROUTECOMMERCIAL = 'api/Commercial/';
  public ROUTEACTION = 'api/Action/';
  public ROUTECIVILITE = 'api/Civilite/';
  public ROUTEDEMANDE = 'api/Demande/';
  public ROUTEINTERLOCUTEUR = 'api/Interlocuteur/';
  public ROUTENATURE = 'api/Nature/';
  public ROUTEACTIVITE = 'api/Prestation/';
  public ROUTESITEGEO = 'api/SiteGeo/';
  public ROUTETYPEACTION = 'api/TypeAction/';
  public ROUTETYPEDEMANDE = 'api/TypeDemande/';
  public ROUTEGOOGLELOGIN = 'api/Account/Google/';
  public ROUTEFORMULAIRE = 'api/Formulaire/';
  public ROUTEPROTOCOLE = 'api/Protocole/';
  public ROUTEPARAMETREUSER = 'api/Parametre/';
  public ROUTEDOCUMENT = 'api/Document/';
  public ROUTEDASHBOARD = 'api/Dashboard/';
  public ROUTEDESCRIPTIONACTIVITE = 'api/DescriptionActivite/';
  public ROUTEDEVISJASON = 'IntercomJasonAPI/Devis/';
  public ROUTECONTACTJASON = 'IntercomJasonAPI/Interlocuteur/';
  public ROUTEZONE = 'api/ZoneIntervention/';
  public ROUTEFILTREPERIMETRE = 'api/FiltrePerimetre/';
  public ROUTEDEMANDEPRAXEDO = 'IntercomJasonAPI/PraxedoBusinessEvent/';
  public ROUTEGEOGOUV = 'https://geo.api.gouv.fr/';



  public httpOptions;

  constructor () {
  }

}
