import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BasicService } from './basic-service/basic.service';
import { ActiviteModel } from '../shared/models/prestation.model';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { Observable } from 'rxjs';
import { PrestationsDTO } from '../shared/dtos/prestationDTO';
import { SessionService } from '../../shared/session.service';
@Injectable()
export class ActiviteService extends BasicService {
    constructor(http: HttpClient,
                apiService: ApiService,
                sessionService: SessionService) {
          super(http, apiService, sessionService);
          this.serviceUrl = apiService.ROUTEACTIVITE;
      }

    getFromSimpleQuery(objet: PrestationsDTO): Observable<SimpleQueryResponse<ActiviteModel>> {
      // tslint:disable-next-line: max-line-length
      return this.http.post<SimpleQueryResponse<ActiviteModel>>(this.apiUrl + this.serviceUrl + 'GetFromSimpleQuery/', objet, {headers: this.header});
    }

    getAllPxo(): Observable<ActiviteModel[]> {
      return this.http.get<ActiviteModel[]>(this.apiUrl + this.serviceUrl + 'GetAllPxo/');
    }

}
