import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OffreModel } from '../../models/offre.model';
import { ParametrePrestationComponent } from '../parametre-prestations/parametre-prestation.component';

export interface DialogData {
  idAgence: number;
  form: UntypedFormGroup;
  result: boolean;
}

@Component({
  selector: 'app-offre-modal',
  templateUrl: './offre-modal.component.html'
})
export class OffreModalComponent implements OnInit {

  offre: OffreModel;
  form: UntypedFormGroup;
  visible : boolean =false;  
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ParametrePrestationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.form = this.data.form;
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(null);
  }

  onModalConfirm(): void {
    this.form.get('idAgence').setValue(this.data.idAgence)
    if(this.form.valid) {
      this.data.result = true;
      this.dialogRef.close(this.form);
    } else {
      this.displayErrors();
    }
  }

  displayErrors(): void {
    for (const index in this.form.controls) {
      if (index) {
        this.form.controls[index].markAsTouched();
      }
    }
  }

}
