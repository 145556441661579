import { PrestationApiService } from './prestation-api.service';
import { PrestationBasicService } from './prestation-basic.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { PrestationModel } from '../models/prestation.model';
import { SessionService } from '../../shared/session.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormInfo } from '../constant/offre-validators';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OffreModel } from '../models/offre.model';
import { DevisDTO } from '../../devis/DTOs/devisDTO';

@Injectable()
export class PrestationService extends PrestationBasicService {

    constructor(
      http: HttpClient,
      apiService: PrestationApiService,
      protected sessionService: SessionService,
      protected IntercomToastrService: IntercomToastrService) {
        super(http, apiService, sessionService);
        this.serviceUrl = apiService.ROUTEPRESTATION;
    }

    getFromActivite(idAgence: number, idActivite: number): Observable<PrestationModel[]> {
      return this.http.get<PrestationModel[]>(this.apiUrl + this.serviceUrl + 
          'getFromActivite?idAgence=' + idAgence + '&idActivite='  + idActivite);
    }

    deletePrestation(id: number, idAgence: number): Observable<PrestationModel> {
      return this.http.delete<PrestationModel>(this.apiUrl + this.serviceUrl + 'delete?id=' + id + '&idAgence=' + idAgence);
    }

    // script start when the user import zone from code postal
    import(Sheeturl: string, codePostal: string): Observable<any> {
      if (Sheeturl.includes('edit')) {
        Sheeturl = Sheeturl.split('edit')[0] + 'edit';
      }
      const url = environment.URL_WEBAPP_ZONES;
      const params = '?url=' + Sheeturl + '&cp=' + codePostal;
      const res = this.http.jsonp(url + params, 'callback');
      return res;
    }

    public getPrestationForm(prestations: PrestationModel[], devisDTO: DevisDTO, prestationValidators: Map<string, FormInfo>, offreValidators: Map<string, FormInfo>): UntypedFormArray {
      const prestationFormArray = new UntypedFormArray([]);
      for (const prestation of prestations) {
        const form = new UntypedFormGroup({});
        for (const [key, value] of prestationValidators.entries()) {
          if (key !== 'offres' ) {
            const control = new UntypedFormControl({ value: prestation[key], disabled: prestationValidators.get(key).disabled }, prestationValidators.get(key).validators);
            form.addControl(key, control);
          }
        }
        const offreFormArray = new UntypedFormArray([]);
        if (prestation.offres && prestation.offres.length > 0) {
          for (const offre of prestation.offres) {
            offreFormArray.push(this.getOffreForm(offre, offreValidators));
          }
        }
        offreFormArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        form.addControl('offres', offreFormArray);
        if(devisDTO && devisDTO.idDevis && devisDTO.prestationsChantier){
          let ligne = devisDTO.prestationsChantier.lignes.find(ligne => ligne.libelle === prestation.libelle);
          if(ligne){
            let offre = prestation.offres.find(offre => offre.libelle === (ligne.orders[0].libelleActivite));
            let control = new UntypedFormControl({ value: offre.id, disabled: false }, [Validators.required]);
            form.addControl('selected', control);
          }else{
            form.addControl('selected', new UntypedFormControl());
            form.get('selected').addValidators(Validators.required);
          }
        }else{
          form.addControl('selected', new UntypedFormControl());
          form.get('selected').addValidators(Validators.required);
        }
       
        prestationFormArray.push(form);
      }
      return prestationFormArray;
    }

    private getOffreForm(offre: OffreModel, offreValidators: Map<string, FormInfo>): UntypedFormGroup {
      const form = new UntypedFormGroup({});
      for (const [key, _] of offreValidators.entries()) {
        const control =
          new UntypedFormControl({ value: offre[key], disabled: offreValidators.get(key).disabled }, offreValidators.get(key).validators);
        form.addControl(key, control);
      } 
      return form;
    }
  
  
    

    setPrestations(prestations: PrestationModel[]) {
      return null;
    }
}
