import { ChangeDetectorRef, Component, Injector, Input, NgZone, OnDestroy, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { SessionService } from 'src/app/modules/shared/session.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { filter } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';
import * as _ from 'lodash';    
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { DemandeService } from '../../services/demande.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { ActiviteService } from '../../services/prestation.service';
import { PriseAppelService, sharedDemande } from '../../services/prise-appel.service';
import { ProtocoleService } from '../../services/protocole.service';
import { AgenceService } from '../../services/rsa/agence.service';
import { SiteGeoService } from '../../services/site-geo.service';
import { TierService } from '../../services/tier/tier.service';
import { ToastrLoadingService } from '../../services/toastrLoading.service';
import { TypeDemandeService } from '../../services/type-demande.service';
import { UserService } from '../../services/user/user.service';
import { filterTierDTO } from '../../shared/dtos/filterTierDTO';
import { ProtocoleDTO } from '../../shared/dtos/protocoleDTO';
import { tierPgQueryResponse } from '../../shared/dtos/tierPgQueryResponse';
import { DemandeModel } from '../../shared/models/demande.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { ActiviteModel } from '../../shared/models/prestation.model';
import { ProtocoleModel } from '../../shared/models/protocole.model';
import { AgenceModel } from '../../shared/models/rsa/agence.model';
import { SiteGeoModel } from '../../shared/models/site-geo.model';
import { TierModel } from '../../shared/models/tier/tier.model';
import { TypeDemandeModel } from '../../shared/models/type-demande.model';
import { UserModel } from '../../shared/models/users/user.model';
import { SimpleQuery } from '../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';
import { ModalAjoutDemandeComponent } from '../demande/modal-ajout-demande.component';
import { ModalSelectionTiersFactureComponent } from '../demande/modal-selection-tiers-facture.component';
import { ModalSpinnerComponent } from '../divers/modals/modal-spinner/modal-spinner.component';
import { ModalSelectTier } from '../menu/modalSelectTier/modal-select-tier';
import { AutocompleteVilleComponent } from 'src/app/modules/shared/components/autocomplete-ville/autocomplete-ville.component';
import { AutocompleteCodePostalComponent } from 'src/app/modules/shared/components/autocomplete-code-postal/autocomplete-code-postal.component';
import { CityData } from '../../shared/models/city-data.model';
import { GmapsService } from 'src/app/modules/shared/gapiServices/gmaps.service';   
import { BasicTierArrayComponent } from 'src/app/modules/shared/components/array/basic.tier.array.component';

export class DemandePrestation {
  prestation: ActiviteModel;
  value: boolean;
}

@Component({
  selector: 'app-prise-appel-urgence',
  templateUrl: './prise-appel-urgence.component.html'
})
export class PriseAppelUrgenceComponent  implements OnInit, OnDestroy {
  @Input() demandeDuplique: DemandeModel = new DemandeModel();
  demande: DemandeModel = new DemandeModel();

  typeDemandes: TypeDemandeModel[] = [];
  typeDemande: TypeDemandeModel;
  selectedTypeDemande = 5;
  objetAuto = true;
  prestations: ActiviteModel[] = [];
  prestation: ActiviteModel;
  demandePrestations = [];
  prestationIds: string;
  usersPxo: UserModel[] = [];
  userRealisateur: UserModel;
  users : UserModel[] = [];
  userGestionnaire: UserModel;
  gestionnaireId: number;
  gestionnaire : UserModel;
  realisateur: UserModel;
  commercialId: number;
  isFormDemandeValid = true;
  showTiersSearch = false;
  protocoleLoading = false;
  protocole: ProtocoleModel;
  IsWait: boolean = false;
  toolTipAdresses: string = "";

  pgQueryResponse = new tierPgQueryResponse();

  isFormEmpty = true;

  dataSource = [];
  length: number;
  pageSize = 10;
  pageIndex: number;


  result: any;
  panelOpenState: boolean;
  loading: boolean;
  selectedLink: string;

  selectedTiersDuplique: TierModel = new TierModel();
  simpleQueryResponse = new SimpleQueryResponse<TierModel>();
  simpleQuerySiteGeo = new SimpleQuery<SiteGeoModel>();

  @ViewChild("addresstext") addresstext: any;
  @ViewChild(AutocompleteVilleComponent) autocVille: AutocompleteVilleComponent;
  @ViewChild(AutocompleteCodePostalComponent) autocCode: AutocompleteCodePostalComponent;

  @ViewChild(BasicTierArrayComponent) tableTier: BasicTierArrayComponent;

  selectedCity: CityData = new CityData();

  tiersFilter = new filterTierDTO();


  selectedTiersDemande : TierModel = new TierModel();
  selectedInterlocDemande : InterlocuteurModel = new InterlocuteurModel ();
  selectedSiteGeoDemande : SiteGeoModel = new SiteGeoModel();

  searchedInterlocDemande : InterlocuteurModel = new InterlocuteurModel ();
  searchedSiteGeoDemande : SiteGeoModel = new SiteGeoModel();

  addedTiers : TierModel = new TierModel();

  tierFacture : TierModel = new TierModel();

  isInterlocSelectedNull : boolean = true
  isSiteGeoSelectedNull : boolean = true

  timeTravel: string = "";
  distanceTravel: string = "";

  subscriptionTierRecherche: Subscription;
  subscriptionSearchOrigin: Subscription;

  currentRoute : any;

  villeBounds;
  autocompleteAdresse: google.maps.places.Autocomplete;
  gmapsSubscriber: Subscription;
  subscriptionTiersResult: Subscription;
  tierRechercheForm : UntypedFormGroup;  
  ajoutDemandeForm : UntypedFormGroup;
  subscriptionSharedDemande: Subscription;
  sharedDemande : sharedDemande = new sharedDemande();


  constructor(
    public dialog: MatDialog,
    private modalsService: ModalsService,
    private fb: UntypedFormBuilder,
    private zone: NgZone,
    private agenceService: AgenceService,
    private demandeService: DemandeService,
    private sessionService: SessionService,
    private IntercomToastrService: IntercomToastrService,
    private userService: UserService,
    private typeDemandeService : TypeDemandeService,
    private protocoleService: ProtocoleService,
    private prestationService: ActiviteService,
    private cdRef:ChangeDetectorRef,
    private priseAppelService : PriseAppelService,
    public tiersService: TierService,
    private siteGeoService: SiteGeoService,
    private interlocuteurService: InterlocuteurService,
    @Optional() public dialogReftiers: MatDialogRef<ModalSelectTier>,
    injector: Injector,
    private router: Router,
    private toastrLoadingService: ToastrLoadingService,
    private gmapsService: GmapsService)
    {     
      this.modalsService = injector.get('ModalsService');
      this.currentRoute = this.router.url;

      this.tierRechercheForm  = this.fb.group({
        raisonSocial: [null],
        idJason: [null],
        address: [null],
        ville: [null],
        libelle: [null],
        codePostal: [null, Validators.compose([Validators.maxLength(5)])],
        nomInterlocuteur: [null],
        prenomInterlocuteur: [null],
        telInterlocuteur: [null],
      });

      this.ajoutDemandeForm = this.fb.group({
        objet: ["",Validators.required],
        type: [{value : 1000, disabled: true}, Validators.required],
        dateEcheance: [new Date(), Validators.required],
        statut: ["A traiter",Validators.required],
        commentaire: [""],
        commercial:[null,Validators.required],
        gestionnaire:[null,Validators.required]
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // initialisation de la demande dans le cas d'une duplication
    if(this.demandeDuplique){
      this.demande = Object.assign({}, this.demandeDuplique);
      this.demande.id = 0;
      this.demande.numDemande = null;
      this.demande.dateCloture = null;
      this.demande.envoyeAJason = 0;
      this.demande.dAccepteJason = null
      this.demande.dDernierEnvoiJason = null
      this.ajoutDemandeForm = this.fb.group({
        objet: [this.demandeDuplique.objet, Validators.required],
        type: [{value : 1000, disabled: true}, Validators.required],
        dateEcheance: [new Date(), Validators.required],
        statut: ["A traiter",Validators.required],
        commentaire: [this.demandeDuplique.commentaire],
        commercial:[this.demande.commercialId,Validators.required],
        gestionnaire:[this.demande.gestionnaireId,Validators.required]
      });
      
      this.typeChange(1000);
      this.demande.typeDemandeId = 1000;
      this.prestationIds = this.demandeDuplique.prestationIds;
      this.commercialId = this.demandeDuplique.commercialId;
      this.tiersFilter = new filterTierDTO();
      this.subscribeAjoutDemandeForm();
      this.subscribeTierResult();
      this.subscribeTierRecherche();
      this.subscribeSharedDemande();
      if (this.demandeDuplique.typeDemande && this.demandeDuplique.typeDemande.id !== 1000) {//Non demande urgente 
        this.demande.prestationIds = "";
        this.demande.prestations = null;
        this.demande.commercialId = null;
        this.demande.commercial = null;
        this.demande.gestionnaireId = null;
        this.demande.gestionnaire = null;
        this.demande.typeDemande = Object.assign({}, this.typeDemandes.find(type => type.id === 1000));
        this.demande.typeDemandeId = 1000;
        this.commercialId = null;
        this.prestationIds = "";
        this.typeDemande = this.demande.typeDemande;
        this.selectedTypeDemande = 1000;
        this.ajoutDemandeForm.controls['type'].setValue(1000);
        this.ajoutDemandeForm.controls['commercial'].setValue(null);
        this.ajoutDemandeForm.controls['gestionnaire'].setValue(null);

      }
      const dialog = this.dialog.open(ModalSpinnerComponent);

      // selected tiers : 
      if (this.demandeDuplique.tierId) {
        this.tiersService.get(this.demandeDuplique.tierId).subscribe(tiers => {
          this.selectedTiersDuplique = tiers;
          this.selectedTiersDemande = tiers;
          this.tiersFilter.tiers.libelle = tiers.libelle;
          this.cdRef.detectChanges();
          // selected site geo : 
          if(this.demandeDuplique.siteGeoId){
            this.siteGeoService.get(this.demandeDuplique.siteGeoId).subscribe((site: SiteGeoModel) => {
              this.selectedSiteGeoDemande = site;
              this.tiersFilter.siteGeo.Adresse = site.adresse;
              this.tiersFilter.siteGeo.CodePostal = site.codePostal;
              this.tiersFilter.siteGeo.Ville = site.ville;
              this.isSiteGeoSelectedNull = false
              this.cdRef.detectChanges();
              // selected contact :
              if(this.demandeDuplique.interlocuteurId){
                this.interlocuteurService.get(this.demandeDuplique.interlocuteurId).subscribe((contact: InterlocuteurModel) => {
                  this.selectedInterlocDemande = contact;
                  this.tiersFilter.interlocuteur.Nom = contact.nom;
                  this.tiersFilter.interlocuteur.Prenom = contact.prenom;
                  this.isInterlocSelectedNull = false
                  this.cdRef.detectChanges();
                  // selected tiers facturé : 
                  if(this.demandeDuplique.tierFactureId){
                    this.tiersService.get(this.demandeDuplique.tierFactureId).subscribe(tiers => {
                      this.tierFacture = tiers;
                      this.cdRef.detectChanges();
                    });
                  }
                  dialog.close();
                  this.onSubmitForm2();
                });
              }else{
                dialog.close();
                this.onSubmitForm2();
              }
            });
          }else{
            dialog.close();
            this.onSubmitForm2();
          }
        });
      } else{
        dialog.close();
      }
    }
  }

  formatPhoneNumper(input: string){
    let tel = null;
    if (input) {
      input = input.slice(0, 14);
      const tel1 = input.replace(/[^\d]/g, '');
      let tel2 = '';
      for (let i = 0; i < tel1.length; i = i + 2 ) {
        tel2 += tel1.substring(i, i + 2) + '.';
      }
      tel = tel2.substring(0, tel2.length - 1);
    }
    return(tel);
  }

  ngOnInit(): void {
    this.initTitleDemande();
    let oldpageSize = 10;
    let oldpageOrder = "Similarity";
    let oldPageDesc = true;

    this.tiersFilter = new filterTierDTO();
    this.tiersFilter.pageNumber = 0;

    this.tiersFilter.pageSize = oldpageSize;
    this.tiersFilter.order = oldpageOrder;
    this.tiersFilter.descending = oldPageDesc;
    this.demande.createurId = this.sessionService.currentUserValue.id;

    this.typeDemandeService.getAllActive().subscribe((ta: TypeDemandeModel[]) => {
      this.typeDemandes = ta;
      this.ajoutDemandeForm.controls['type'].setValue(ta.find(type =>  type.libelle === 'Urgence').id);

    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos demandes")
    );

    this.prestationService.getAllPxo().subscribe((prestas: ActiviteModel[]) => {
      this.prestations = prestas;
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos demandes")
    );

    this.userService.getAllPxo().subscribe((usrs: UserModel[]) => {
      this.userRealisateur = usrs.find(usr => usr.id === this.demande.commercialId);
      this.usersPxo = usrs;
      this.commercialId = this.demande.commercialId;
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos demandes")
    );

    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.userGestionnaire = usrs.find(usr => usr.id === this.demande.gestionnaireId); //TO-DO remplacer commercialId par gestionnaireId
      this.users = usrs;
      this.gestionnaireId = this.demande.gestionnaireId;
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des infos demandes")
    );

    this.demande.prestations = [];
    this.prestationIds = this.demandeDuplique ? this.demandeDuplique.prestationIds : '';
    if (this.prestationIds && !this.prestationIds.endsWith(',')) 
      {
      this.prestationIds = this.prestationIds + ',';
    }
    this.subscribeAjoutDemandeForm();

    this.simpleQuerySiteGeo.entry = new SiteGeoModel();

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    this.tierRechercheForm.valueChanges.subscribe(formTierValue => {

      this.tierRechercheForm.get("telInterlocuteur").patchValue(this.formatPhoneNumper(formTierValue.telInterlocuteur), { emitEvent: false });
      formTierValue.telInterlocuteur = this.tierRechercheForm.value.telInterlocuteur;

      let siteGeo = new SiteGeoModel()
      siteGeo.libelle = formTierValue.libelle
      siteGeo.adresse = formTierValue.address
      siteGeo.ville = formTierValue.ville
      siteGeo.codePostal = formTierValue.codePostal

      let interloc = new InterlocuteurModel()
      interloc.nom = formTierValue.nomInterlocuteur
      interloc.prenom = formTierValue.prenomInterlocuteur
      interloc.telPrincipal = formTierValue.telInterlocuteur
      // lors des changements du formulaire, nous préparons ici la mise a jour des filtres
      this.tiersFilter = Object.assign({}, this.tiersFilter);
      this.tiersFilter.siteGeo.Adresse = this.tierRechercheForm.value.address;
      this.tiersFilter.siteGeo.CodePostal = this.tierRechercheForm.value.codePostal;
      this.tiersFilter.siteGeo.Ville = this.tierRechercheForm.value.ville;
      this.tiersFilter.siteGeo.Libelle = this.tierRechercheForm.value.libelle;
      this.tiersFilter.interlocuteur.Nom = this.tierRechercheForm.value.nomInterlocuteur;
      this.tiersFilter.interlocuteur.Prenom = this.tierRechercheForm.value.prenomInterlocuteur;
      this.tiersFilter.interlocuteur.TelPrincipal = this.tierRechercheForm.value.telInterlocuteur;
      
      this.tiersFilter.tiers.libelle = this.tierRechercheForm.value.raisonSocial;
      this.tiersFilter.tiers.identifiantJason = this.tierRechercheForm.value.idJason;

      this.isSiteGeoSelectedNull = Object.values(this.selectedSiteGeoDemande).every(x => x === null || x === '')
      this.isInterlocSelectedNull = Object.values(this.selectedInterlocDemande).every(x => x === null || x === '')
  
      this.initTitleDemande();
      this.cdRef.detectChanges();
    })
    this.subscribeTierResult();
    this.subscribeTierRecherche();
    this.subscribeSharedDemande();
  }

  subscribeAjoutDemandeForm() {
    this.ajoutDemandeForm.valueChanges.subscribe(value => {
      this.demande.objet = this.ajoutDemandeForm.value.objet; 
      this.demande.dateIntervention = this.ajoutDemandeForm.value.dateEcheance; 
      this.demande.statut = this.ajoutDemandeForm.value.statut; 
      this.demande.commentaire = this.ajoutDemandeForm.value.commentaire; 

      if (this.ajoutDemandeForm.valid && this.realisateur){
        this.isFormDemandeValid = false;
      }else{
        this.isFormDemandeValid = true;
        this.showTiersSearch = false;
      }

      let matchingDemande = this.sharedDemande.commentaire == this.ajoutDemandeForm.value.commentaire && 
        ((this.sharedDemande.commercial && !this.usersPxo.find(x => x.id === this.sharedDemande.commercial)) ||
          this.sharedDemande.commercial == this.ajoutDemandeForm.value.commercial) && 
        this.sharedDemande.statut == this.ajoutDemandeForm.value.statut;
      if (!matchingDemande)
      {
        this.sharedDemande.commentaire = this.ajoutDemandeForm.value.commentaire;
        this.sharedDemande.commercial = this.ajoutDemandeForm.value.commercial;
        this.sharedDemande.statut = this.ajoutDemandeForm.value.statut;
        this.priseAppelService.updateSharedDemande(this.sharedDemande);
      }
    });
  }
  
  subscribeTierRecherche() {
    this.subscriptionTierRecherche = this.priseAppelService.currentTierRechercheForm.subscribe(sharedTierForm => {
      if( this.router.url === '/prise-appel'&& !_.isEqual(this.tierRechercheForm.value, sharedTierForm.value)) {
        this.tierRechercheForm.patchValue(sharedTierForm.value);
        let cp =this.tierRechercheForm.get('codePostal').value;
        let ville = this.tierRechercheForm.get('ville').value;
        let data = new CityData();
        data.codesPostaux = [cp];
        data.nom = ville;
        this.selectedCity = data;
        // this.onSubmitForm(false);
        const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
        const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
        const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');
        this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo
      }
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des tiers")
    );
  }

  subscribeTierResult() {
    this.subscriptionTiersResult = this.priseAppelService.currentTiersResult.subscribe(result => {
      this.pgQueryResponse = result;
      this.length = result.count;
      this.loading = false;
      if (this.length === 0 || this.length == undefined) {
        const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
        const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
        const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');
    
        this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo
        if(this.length != undefined){
          this.IntercomToastrService.warning(
            "Oups : " + "Aucun résultat pour votre recherche"
          );
        }
      } else {
        this.isFormEmpty = false;
      }
    }, error => // ne sera jamais activé ou le sera en doublons
        this.IntercomToastrService.error("Erreur durant le chargement des tiers")
    );
  }

  subscribeSharedDemande() {
    this.subscriptionSharedDemande = this.priseAppelService.currentSharedDemande.subscribe(sharedDemande => {
      if( this.router.url === '/prise-appel'  && !_.isEqual(this.sharedDemande, sharedDemande)) {
        this.sharedDemande = Object.assign({},sharedDemande);
        if (this.usersPxo.find(commercial => commercial.id === sharedDemande.commercial)) {
          this.ajoutDemandeForm.patchValue({
            commercial : sharedDemande.commercial,
            commentaire : sharedDemande.commentaire,
            statut : sharedDemande.statut
          });
          if(sharedDemande.commercial != this.commercialId){
            this.commercialId = sharedDemande.commercial;
            this.onUpdateRealisateurId(sharedDemande.commercial)
            this.gestionnaireId = sharedDemande.commercial;
            this.onUpdateGestionnaireId(this.gestionnaireId);
          }
          if(sharedDemande.activite != this.prestationIds){
            this.prestationIds = sharedDemande.activite;
            this.onUpdatePrestationsId(sharedDemande.activite);
          }
        } else {
          this.ajoutDemandeForm.patchValue({
            commentaire : sharedDemande.commentaire,
            statut : sharedDemande.statut
          });
          if(sharedDemande.activite != this.prestationIds){
            this.prestationIds = sharedDemande.activite;
            this.onUpdatePrestationsId(sharedDemande.activite);
          }
        }
      }
    }, error =>  
      this.IntercomToastrService.error("Erreur durant le chargement des tiers")
    );
  }
  
  ngAfterViewInit() {
    //After page load, initialize google autocomplete
    this.getPlaceAutocomplete();
    // après le chargement des Gmaps, ajout du nettoyage en cas de suppression du champs par l'utilisateurs
    this.gmapsSubscriber = combineLatest([
      this.tierRechercheForm.get('ville').valueChanges,
      this.tierRechercheForm.get('codePostal').valueChanges])
    .pipe(filter(([a,b]) => !a && !b))
    .subscribe(_ => {
      this.villeBounds = undefined;
      this.autocompleteAdresse.setBounds(this.villeBounds);
    });
  }

  ngOnDestroy() {
    if(this.currentRoute == '/prise-appel'){
      this.gmapsSubscriber.unsubscribe();
      this.subscriptionTiersResult.unsubscribe();
      this.priseAppelService.updateStartSearch("");
      this.subscriptionTierRecherche.unsubscribe();
      this.subscriptionSharedDemande.unsubscribe();
    }
  }

  initTitleDemande() {
    this.getDemandeWithPrestations(this.demande);
    this.demande.objet = 'Demande urgente : ';
  
    if (this.demande.typeDemande) {
      this.demande.objet = this.demande.typeDemande.libelle 
        ? this.demande.typeDemande.libelle + ' : ' 
        : 'Demande urgente : ';
    }
  
    this.demande.objet += this.demande.tier?.libelle ? this.demande.tier.libelle + ' ' : '';
  
    // Extract the first nested ternary
    if (this.demande.tier) {
      let additionalInfo = '';
  
      if (this.demande.tier.libelle !== this.demande.nomInterlocuteur &&
          this.demande.tier.libelle !== this.demande.nomInterlocuteur + ' ' + this.demande.prenomInterlocuteur && 
          this.demande.nomInterlocuteur !== null) {
  
        if (this.demande.prenomInterlocuteur !== null) {
          additionalInfo = '- ' + this.demande.nomInterlocuteur + ' ' + this.demande.prenomInterlocuteur;
        } else {
          additionalInfo = '- ' + this.demande.nomInterlocuteur;
        }
      }
  
      this.demande.objet += additionalInfo;
    } else {
      // Extract the second nested ternary
      let additionalInfo = '';
  
      if (this.demande.nomInterlocuteur !== null) {
        if (this.demande.prenomInterlocuteur !== null) {
          additionalInfo = '- ' + this.demande.nomInterlocuteur + ' ' + this.demande.prenomInterlocuteur;
        } else {
          additionalInfo = '- ' + this.demande.nomInterlocuteur;
        }
      }
  
      this.demande.objet += additionalInfo;
    }
  
    /// prestations
    if (this.demande?.prestations && this.demande.prestations.length > 0) {
      this.demande.objet += ' (';
      for (const presta of this.demande.prestations) {
        this.demande.objet += presta.libelle + ', ';
      }
      this.demande.objet = this.demande.objet.substring(0, this.demande.objet.length - 2);
      this.demande.objet += ')';
    }
  
    this.ajoutDemandeForm.controls['objet'].setValue(this.demande.objet);
  }

  getDemandeWithPrestations(demande: DemandeModel): DemandeModel {
    if (demande?.prestationIds && demande.prestationIds.length > 0) {
      demande.prestations = [];
      const arrayPrestas = demande.prestationIds.split(',');
      for (const prestaDemande of arrayPrestas) {
        const foundPresta = this.prestations.find(presta => presta.id === Number.parseInt(prestaDemande, 10));
        if (foundPresta) {
          demande.prestations.push(foundPresta);
        }
      }
    }
    if (demande?.typeDemandeId) {
      const typeDemande = this.typeDemandes.find(ltypeDemande => ltypeDemande.id === demande.typeDemandeId);
      if (typeDemande) {
        demande.typeDemande = typeDemande;
      }
    }
    return demande;
  }

  onUpdateRealisateurId(realisateurId: number) {
    if (realisateurId === null) {
      this.demande.commercialId = -1;
      this.commercialId = null;
      this.realisateur = null;
      this.ajoutDemandeForm.patchValue({
        commercial:null
      });
      this.sharedDemande.commercial = null;
      this.priseAppelService.updateSharedDemande(this.sharedDemande);
    } else {
      this.demande.commercialId = realisateurId;
      this.realisateur = this.usersPxo.find(x => x.id === realisateurId);
      this.ajoutDemandeForm.patchValue({
          commercial:realisateurId
        });
      this.gestionnaireId = realisateurId;
      this.onUpdateGestionnaireId(this.gestionnaireId);
      this.sharedDemande.commercial = realisateurId;
      this.priseAppelService.updateSharedDemande(this.sharedDemande);
    }
    this.getTime();
    this.onProtocolCalled();
  }

  onUpdateGestionnaireId(gestionnaireId: number) {
    if (gestionnaireId === null) {
      this.demande.gestionnaireId = -1;
      this.gestionnaireId = null;
      this.gestionnaire = null;
      this.ajoutDemandeForm.patchValue({
        gestionnaire:null
      });
    } else {
      this.demande.gestionnaireId = gestionnaireId;
      this.gestionnaire = this.users.find(x => x.id === gestionnaireId);
      this.ajoutDemandeForm.patchValue({
          gestionnaire:gestionnaireId
      });
    }
    this.getTime();
    this.onProtocolCalled();
  }

  onUpdatePrestationsId(prestationIds: string) {
    const ids = prestationIds ? prestationIds.split(",").filter(Boolean) : [];
    const filteredIds = ids.filter(id => this.prestations.some(obj => obj.id === parseInt(id, 10))).join(',');
    this.demande.prestationIds = filteredIds;
    if (prestationIds !== '' && prestationIds) {
      this.demandePrestations = filteredIds.split(',');
      this.sharedDemande.activite = prestationIds;
      this.priseAppelService.updateSharedDemande(this.sharedDemande);
    } else {
      this.demandePrestations = [];
      this.sharedDemande.activite = '';
      this.priseAppelService.updateSharedDemande(this.sharedDemande);
    }
    if (this.objetAuto) {
      this.initTitleDemande();
    }
    this.onProtocolCalled();
  }

  typeChange(input) {
    if(input){
      this.typeDemande = Object.assign({}, this.typeDemandes.find(type => type.id === input));
      if (this.objetAuto) {
        this.initTitleDemande();
      } 
      switch (this.typeDemande.id) {
        case 3 : {
          this.ajoutDemandeForm.controls['dateEcheance'].setValue(new Date());
          break;
        }
        case 1000 : {
          this.ajoutDemandeForm.controls['dateEcheance'].setValue(new Date());
          break;
        }
        default : {
          this.ajoutDemandeForm.controls['dateEcheance'].setValue(new Date(new Date().getTime() + (1000 * 60 * 60 * 48)));
          break;
        }

      }
    }
  }

  onEditTiersFacture(){
    const dialogRef = this.dialog.open(ModalSelectionTiersFactureComponent, {
      disableClose: true,
      panelClass: 'tiers-facture-dialog',
      height:"95%",
      data :{
        tier : this.tierFacture,
        result : this.result,
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.result) {
        this.tierFacture = data.tier;      
        this.priseAppelService.updateTierselectedTiersFacture(this.tierFacture)    
      }
    });
  }

  selectedTiers(event){
    this.demande.tierId = event.id;
    this.demande.tier = event;
    this.selectedTiersDemande = event;
    this.demande.tierFactureId = event.tierFactureId;
    this.demande.siteGeoId = null;
    this.selectedSiteGeoDemande = new SiteGeoModel();
    this.demande.siteGeoId = null;
    this.selectedInterlocDemande = new InterlocuteurModel();

    let interloc = new InterlocuteurModel()
    interloc.nom = this.tierRechercheForm.value.nomInterlocuteur
    interloc.prenom = this.tierRechercheForm.value.prenomInterlocuteur
    interloc.telPrincipal = this.tierRechercheForm.value.telInterlocuteur

    let siteGeo = new SiteGeoModel()
    siteGeo.libelle = this.tierRechercheForm.value.libelle
    siteGeo.adresse = this.tierRechercheForm.value.address
    siteGeo.ville = this.tierRechercheForm.value.ville
    siteGeo.codePostal = this.tierRechercheForm.value.codePostal

    this.isSiteGeoSelectedNull = Object.values(this.selectedSiteGeoDemande).every(x => x === null || x === '')
    this.isInterlocSelectedNull = Object.values(this.selectedInterlocDemande).every(x => x === null || x === '')
    /*
    ne plus enregistrer les interlocuteurs ou contacts dans la demande / devis si ils ne sont pas sélectionné
    if(this.isInterlocSelectedNull){
        this.demande.nomInterlocuteur = this.tierRechercheForm.value.nomInterlocuteur
        this.demande.prenomInterlocuteur = this.tierRechercheForm.value.prenomInterlocuteur
        this.demande.telPrincipalInterlocuteur = this.tierRechercheForm.value.telInterlocuteur
        this.searchedInterlocDemande = interloc
    }

    if(this.isSiteGeoSelectedNull){
      this.demande.libelleAdresse = this.tierRechercheForm.value.libelle
      this.demande.adresse = this.tierRechercheForm.value.address
      this.demande.villeAdresse = this.tierRechercheForm.value.ville
      this.demande.codePostalAdresse = this.tierRechercheForm.value.codePostal
      this.searchedSiteGeoDemande = siteGeo
    }
    */
    this.tierFacture = new TierModel();
    if (event.tierFactureId) {
      this.tiersService.get(event.tierFactureId).subscribe((tierFactRes: TierModel) => {
        this.tierFacture = tierFactRes;
        if (this.tierFacture) {
          this.demande.libelleAdresseFact = "Facturation"
          this.demande.adresseFact = this.tierFacture.adresseFact
          this.demande.villeAdresseFact = this.tierFacture.villeFact
          this.demande.codePostalAdresseFact = this.tierFacture.codePostalFact
        }
        this.searchedInterlocDemande = interloc
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement du tiers facturé")
      );
    }
    this.getTime();
    this.initTitleDemande();
    this.onProtocolCalled();
    this.cdRef.detectChanges();
  }

  selectedAdress(event){
    if(!Object.values(event).every(x => x === null || x === '')){
    this.demande.siteGeoId = event.id
    this.selectedSiteGeoDemande = event
    this.isSiteGeoSelectedNull = false

    this.demande.libelleAdresse = event.libelle;
    this.demande.adresse = event.adresse;
    this.demande.villeAdresse = event.ville;
    this.demande.codePostalAdresse = event.codePostal;
    this.demande.numSite = event.numSite;
    this.demande.emailAdresse = event.email;
    this.demande.commentaireAdresse = event.commentaire;
    this.demande.complementAdresse = event.complementAdresse;
    this.demande.gpsAdresse = event.gps;
    this.demande.telephoneAdresse = event.telephone;

    } else {
      this.demande.siteGeoId = null
      this.selectedSiteGeoDemande = new SiteGeoModel()
      this.isSiteGeoSelectedNull = true

      let siteGeo = new SiteGeoModel()
      siteGeo.libelle = this.tierRechercheForm.value.libelle
      siteGeo.adresse = this.tierRechercheForm.value.address
      siteGeo.ville = this.tierRechercheForm.value.ville
      siteGeo.codePostal = this.tierRechercheForm.value.codePostal

      this.demande.libelleAdresse = this.tierRechercheForm.value.libelle
      this.demande.adresse = this.tierRechercheForm.value.address
      this.demande.villeAdresse = this.tierRechercheForm.value.ville
      this.demande.codePostalAdresse = this.tierRechercheForm.value.codePostal
      this.searchedSiteGeoDemande = siteGeo
    }
    this.getTime();
    this.onProtocolCalled();
    this.cdRef.detectChanges();
  }

  selectedInterloc(event: InterlocuteurModel){
    if(!Object.values(event).every(x => x === null || x === '')){
      this.demande.interlocuteurId = event.id
      this.selectedInterlocDemande = event
      this.isInterlocSelectedNull = false
      this.demande.nomInterlocuteur = event.nom
      this.demande.prenomInterlocuteur = event.prenom
      this.demande.emailInterlocuteur = event.email
      this.demande.roleInterlocuteur = event.role
      this.demande.civiliteInterlocuteurId = event.civiliteId
      if (event.civilite) {
        this.demande.civiliteInterlocuteur = event.civilite.libelle
      }
      this.demande.commentaireInterlocuteur = event.commentaire
      this.demande.telSecondaireInterlocuteur = event.telSecondaire
      this.demande.idJasonInterlocuteur = event.idJason
      this.demande.telPrincipalInterlocuteur = event.telPrincipal
    }else{
      this.demande.siteGeoId = null
      this.selectedInterlocDemande = new InterlocuteurModel()
      this.isInterlocSelectedNull = true
        
      let interloc = new InterlocuteurModel()
      interloc.nom = this.tierRechercheForm.value.nomInterlocuteur
      interloc.prenom = this.tierRechercheForm.value.prenomInterlocuteur
      interloc.telPrincipal = this.tierRechercheForm.value.telInterlocuteur
      this.demande.nomInterlocuteur = this.tierRechercheForm.value.nomInterlocuteur
      this.demande.prenomInterlocuteur = this.tierRechercheForm.value.prenomInterlocuteur
      this.demande.telPrincipalInterlocuteur = this.tierRechercheForm.value.telInterlocuteur
      this.searchedInterlocDemande = new InterlocuteurModel()
      this.searchedInterlocDemande = interloc
    }
    this.initTitleDemande();
    this.getTime();
    this.onProtocolCalled();
    this.cdRef.detectChanges();
  }

  clearForm(value) {
    switch (value) {
      case "Interlocuteur":
        this.tierRechercheForm.patchValue({
          nomInterlocuteur: null,
          prenomInterlocuteur: null,
          telInterlocuteur: null,
        });
        break;
      case "Adresse":
         // Clear autocomplete Bounds
         this.villeBounds = null;
         this.autocompleteAdresse.setBounds(this.villeBounds);
         this.selectedCity = new CityData();
         this.selectedCity.codesPostaux = [null];
         this.selectedCity = Object.assign({}, this.selectedCity);
         this.autocCode.codePostal = null;
         
        this.tierRechercheForm.patchValue({
          address: null,
          ville: null,
          libelle: null,
          codePostal: null,
        });
        break;
      case "Tiers":
        this.tierRechercheForm.patchValue({
          raisonSocial: null,
          idJason: null,
        });
        break;
      default:
        break;
    }
    this.onSubmitForm();
  }

  scrollTo(className: string):void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    if(element){
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  pageChange(pageEvent: SimpleQuery<any>) {
    let idSearch: number;
    this.tiersFilter.pageNumber = pageEvent.pageNumber;
    this.tiersFilter.pageSize = pageEvent.pageSize;
    if (pageEvent.order) {
      this.tiersFilter.order = pageEvent.order;
    }
    if (pageEvent?.descending !== undefined && pageEvent?.descending !== null) {
      this.tiersFilter.descending = pageEvent.descending;
    }

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    if (!isEmptySiteGeo||!isEmptyTier||!isEmptyInterloc) {
      this.priseAppelService.changeFilter(this.tiersFilter);
    }
  }

  onSubmitForm2() {
    this.pgQueryResponse = new tierPgQueryResponse();
    this.tiersFilter.pageNumber = 0;
    this.priseAppelService.changeFilter(this.tiersFilter);
    this.priseAppelService.updateTierRechercheForm(this.tierRechercheForm);
  }

  clearTrieAndSubmit() {
    if (this.tableTier?.sort) {
      this.tiersFilter.pageNumber = 0;
      this.tiersFilter.order = 'Similarity';
      this.tiersFilter.descending = true;
      this.tableTier.sort.active = 'Similarity';
      this.tableTier.sort.direction = 'desc';
      this.tableTier.simpleQuery.order = 'Similarity';
      this.tableTier.simpleQuery.descending = true;
      this.tableTier.sort.sort({ id: 'Similarity', start: 'desc', disableClear: false });
    }
    this.onSubmitForm();
  }

  onSubmitForm(): void {
    this.pgQueryResponse = new tierPgQueryResponse();
    this.tiersFilter.pageNumber = 0;

    const isEmptySiteGeo = Object.values(this.tiersFilter.siteGeo).every(x => x === null || x === '');
    const isEmptyTier = Object.values(this.tiersFilter.tiers).every(x => x === null || x === '');
    const isEmptyInterloc = Object.values(this.tiersFilter.interlocuteur).every(x => x === null || x === '');

    this.isFormEmpty = isEmptyInterloc && isEmptyTier &&  isEmptySiteGeo

    if (!isEmptySiteGeo||!isEmptyTier||!isEmptyInterloc) {
      this.priseAppelService.changeFilter(this.tiersFilter);
    }
    this.priseAppelService.updateTierRechercheForm(this.tierRechercheForm);
  }

  onTierOpen(tiers: TierModel) {
    if (!window.location.href.includes("prise-appel")) {
      this.router.navigate(["/fiche-contact", tiers.id]);
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(["/fiche-contact", tiers.id])
      );
      window.open(url, '_blank');    
    }

  }

  onAddDemandeOpen(tiers: TierModel): void {
    const dialogRef = this.dialog.open(ModalAjoutDemandeComponent, {
      disableClose: true,
      width: "900px",
      data: {
        tierFactureId: tiers.tierFactureId,
        tierId: tiers.id,
        result: this.result,
        demande: new DemandeModel(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.result = result;
      if (this.result) {
        this.demandeService
          .getFromTier(tiers.id)
          .subscribe((demande: DemandeModel[]) => {
            this.dataSource = demande;
          });
      }
    });
  }

  onProtocolCalled() {
    let idSearch: number = this.toastrLoadingService.newSearch();
    this.loading = true;
    const protocoleDTO = new ProtocoleDTO();
    protocoleDTO.tierId = this.demande.tierId;
    protocoleDTO.agenceId = this.sessionService.currentUserValue.agenceId;
    protocoleDTO.siteGeoId = this.demande.siteGeoId;
    protocoleDTO.ville = this.demande.villeAdresse;
    protocoleDTO.codePostal = this.demande.codePostalAdresse;
    if (this.demandePrestations.length > 0) {
        protocoleDTO.prestationId = Number(this.demandePrestations[0]);
    } else {
      protocoleDTO.prestationId = null;
    }
    this.protocoleLoading = true;
    this.protocoleService.GetFromObjet(protocoleDTO).subscribe((response: ProtocoleModel[]) => {
      this.toastrLoadingService.endSearch(idSearch);
      this.protocoleLoading = false;
      if (response.length > 0 && response[0]) {
        if (this.protocole) {
          if (response[0].id != this.protocole.id)
            this.IntercomToastrService.success('Nouveau protocole trouvé.');
        } else {
          this.IntercomToastrService.success('Un protocole trouvé.');
        }
        this.protocole = response[0];
      } else {
        if (this.protocole) {
          this.IntercomToastrService.warning('Pas de protocole trouvé.');
        }
        this.protocole = undefined;
      }
    }, error => {
      this.toastrLoadingService.endSearch(idSearch);
      this.protocoleLoading = false;
    });
  }

  onProtocolClicked() {
      if (this.protocole) {
        // mettre a jour le realisateur
        if(this.protocole.realisateurId){
          this.demande.commercialId = this.protocole.realisateurId;
          this.commercialId = this.protocole.realisateurId;
          this.ajoutDemandeForm.patchValue({
            commercial: this.protocole.realisateurId
          });
        }
        // mettre a jour le commentaire
        if (this.demande.commentaire) {
          this.ajoutDemandeForm.patchValue({
            commentaire: this.demande.commentaire + '\n' + this.protocole.protocole
          })
        } else {
          this.ajoutDemandeForm.patchValue({
            commentaire: this.protocole.protocole
          })
        }
        this.IntercomToastrService.success('Demande mise à jour.');
      } else {
        this.IntercomToastrService.warning('Aucun protocole trouvé.');
      }
  }

  displayErrors(): void {
    this.IntercomToastrService.warning("Un réalisateur et un gestionnaire sont obligatoires pour les demandes d'urgence")
    for (const index in this.ajoutDemandeForm.controls) {
      if (index) {
        this.ajoutDemandeForm.controls[index].markAsTouched();
      }
    }
  }

  createDemande(){
    if(this.ajoutDemandeForm.valid){
      if(!this.isSiteGeoSelectedNull && !this.isInterlocSelectedNull ){
        if(this.demande.prestationIds !== null && this.demande.prestationIds !== ""){
          const demandeToCreate = Object.assign({}, this.demande)
          demandeToCreate.tier = null;
          demandeToCreate.typeDemande = null;
          demandeToCreate.commercial = null;
          demandeToCreate.agence = null;
          demandeToCreate.createur = null;
          demandeToCreate.tierFacture = null;
          demandeToCreate.typeDemandeId = 1000;
          demandeToCreate.tierFactureId = this.tierFacture.id;
          this.demandeDuplique = null;
          const dialogRef = this.dialog.open(ModalSpinnerComponent);
          this.demandeService.post(demandeToCreate).subscribe((dem: DemandeModel) => {
            this.demandeService.get(dem.id).subscribe(res => {
              this.IntercomToastrService.success('La demande : ' + res.objet + ' a bien été créée');
              this.IntercomToastrService.info('Vous pouvez maintenant ajouter les tâches correspondantes à la demande');
              dialogRef.close();
              this.modalsService.openDemande(res).subscribe( result => {
                  this.result = result;
                    if(result){
                      location.reload();
                    }
              }, error => {
                this.IntercomToastrService.error("Erreur durant l'ouverture de la demande")
              });
            }, error => {
              dialogRef.close();
              this.IntercomToastrService.error("Erreur durant la récupérartion de la demande")
            });
          }, error => {
            dialogRef.close();
            this.IntercomToastrService.error("Erreur durant la création de la demande")
          });
        } else{
          this.IntercomToastrService.warning("L'activité est obligatoire pour les demandes d'urgence")
        }
      }else{
        this.IntercomToastrService.warning("L'adresse et le contact sont obligatoires pour les demandes d'urgence")
        document.getElementById('list-interlocuteurs').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }else{
      this.displayErrors();
    }
    
  }

  //GMAPS
  openGMap() {
    let dst = !this.isSiteGeoSelectedNull? (this.selectedSiteGeoDemande.adresse + ', ' + this.selectedSiteGeoDemande.ville + ' ' + this.selectedSiteGeoDemande.codePostal) : 
    (this.searchedSiteGeoDemande.adresse + ', ' + this.searchedSiteGeoDemande.ville + ' ' + this.searchedSiteGeoDemande.codePostal);
   if (this.realisateur?.agenceId){
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {
          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
      );
    } else if (this.sessionService.currentUserValue?.agenceId) {    
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agenceUser: AgenceModel) => {
        if (agenceUser.adresse && agenceUser.codePostal && agenceUser.ville) {
          let src = agenceUser.adresse + ', ' + agenceUser.codePostal + ' ' + agenceUser.ville;
          window.open("https://www.google.com/maps/dir/?api=1&origin=" + encodeURI(src + "&destination=" + dst + "&travelmode=driving"));
        } else {
          window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
        }
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
      );
      } else {
      window.open("https://www.google.com/maps/search/?api=1&query=" + encodeURI(dst));
    }
  }
  
  getTime() {
    let dst = !this.isSiteGeoSelectedNull? (this.selectedSiteGeoDemande.adresse + ', ' + this.selectedSiteGeoDemande.ville + ' ' + this.selectedSiteGeoDemande.codePostal) : "";
      //(this.searchedSiteGeoDemande.adresse + ', ' + this.searchedSiteGeoDemande.ville + ' ' + this.searchedSiteGeoDemande.codePostal);
    if (dst.length < 4) {
      this.timeTravel = "Adresse d'intervention incomplète ou incorrecte";
    } else if (this.realisateur) {
      this.agenceService.get(this.realisateur.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {
          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            if (results?.routes[0]) {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
            }
          }).catch(e => console.log(e));
        } else { this.timeTravel = "Adresse de l'agence du réalisateur incomplète ou incorrecte"; }
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
    );
    }  else if (this.sessionService.currentUserValue?.agenceId) {
      this.agenceService.get(this.sessionService.currentUserValue.agenceId).subscribe((agence: AgenceModel) => {
        if (agence.adresse && agence.codePostal && agence.ville) {

          let src = agence.adresse + ', ' + agence.codePostal + ' ' + agence.ville;
          new google.maps.DirectionsService().route({
            'origin': src,
            'destination': dst,
            'travelMode': google.maps.TravelMode.DRIVING,
            'provideRouteAlternatives': false,
            drivingOptions: {
              'departureTime': new Date,
              'trafficModel': google.maps.TrafficModel.BEST_GUESS
            }
          }, (results: any) => {
            if (results?.routes[0]) {
              this.distanceTravel = results.routes[0].legs[0].distance.text;
              this.timeTravel = this.distanceTravel + ', ' + results.routes[0].legs[0].duration_in_traffic.text + ' estimé en fonction du trafic actuel';
            }
          })
        } else { this.timeTravel = "Erreur : Adresse de votre agence est incomplète ou incorrecte"; }
      }, error =>  
        this.IntercomToastrService.error("Erreur durant le chargement des infos agences")
    );
    } else { this.timeTravel = "Impossible de calculer l'itinéraire"; }
  }
  

  private getPlaceAutocomplete() {
    this.autocompleteAdresse = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        bounds: this.villeBounds,
        strictBounds: false,
        componentRestrictions: { country: "FR" },
        types: ["address"], // 'establishment' / 'address' / 'geocode'
      }
    );
   google.maps.event.addListener(this.autocompleteAdresse, "place_changed", () => {
      const place = this.autocompleteAdresse.getPlace();
      if (place) {
        //Add street number to adress form if user choses
        let streetNum = this.getStreetNumber(place);
        let street = this.getStreet(place);
        let city = this.getville(place);
        let cp = this.getPostCode(place);
        if (this.getStreetNumber(place) && street) {
          this.tierRechercheForm.patchValue({
            address: streetNum + " " + street
          });
        } else if (street) {
          this.tierRechercheForm.patchValue({
            address: street
          });
        } 
        if (city) {
          this.tierRechercheForm.patchValue({
            ville: city
          });
          this.selectedCity.nom = city;
        } 
        if (cp) {
          this.tierRechercheForm.patchValue({
            codePostal: cp
          });
          this.selectedCity.codesPostaux = [cp];
        }
        if (city || cp) {
          this.selectedCity = Object.assign({}, this.selectedCity);
        }
        this.zone.run(() => this.onSubmitForm()); // run inside angular zone  
        this.addresstext.nativeElement.blur();
      }
    });
  }

  onCodePostalChange(selectedCity: CityData) {
    if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      this.tierRechercheForm.get('codePostal').setValue(selectedCity.codesPostaux[0]);
      this.tierRechercheForm.get('ville').setValue(selectedCity.nom);
      this.zone.run(() => this.onSubmitForm()); // run inside angular zone  
      if (!selectedCity.codesPostaux[0] || selectedCity.codesPostaux[0] === "") {
        this.autocompleteAdresse.setBounds(null);
      } else {
        this.gmapsService.getGeoCodeResultBoundsByCodePostal(selectedCity.codesPostaux[0]).subscribe((gmapGeo: google.maps.GeocoderGeometry[]) => {
          if (gmapGeo && gmapGeo.length >= 1) {
            this.autocompleteAdresse.setBounds(gmapGeo[0].bounds);
          } else {
            this.autocompleteAdresse.setBounds(null);
          }
        });
      }
    } else if (this.selectedCity?.nom !== selectedCity.nom) {
      this.onCityChange(selectedCity);
    }
  }

  onCityChange(selectedCity: CityData) {
    if (this.selectedCity?.nom !== selectedCity.nom) {
      this.selectedCity = selectedCity;
      this.autocCode.codePostal = selectedCity.codesPostaux[0];
      this.autocVille.villetext = selectedCity.nom;
      this.tierRechercheForm.get('codePostal').setValue(selectedCity.codesPostaux[0]);
      this.tierRechercheForm.get('ville').setValue(this.autocVille.villetext);
      this.zone.run(() => this.onSubmitForm()); // run inside angular zone  
      if (!selectedCity.codesPostaux[0] || selectedCity.codesPostaux[0] === "") {
        this.autocompleteAdresse.setBounds(null);
      } else {
        this.gmapsService.getGeoCodeResultBoundsByCodePostal(selectedCity.codesPostaux[0]).subscribe((gmapGeo: google.maps.GeocoderGeometry[]) => {
          if (gmapGeo && gmapGeo.length >= 1) {
            this.autocompleteAdresse.setBounds(gmapGeo[0].bounds);
          } else {
            this.autocompleteAdresse.setBounds(null);
          }
        });
      }
    } else if (this.selectedCity?.codesPostaux[0] !== selectedCity.codesPostaux[0]) {
      this.onCodePostalChange(selectedCity);
    }
  }

  
  //----------Helpers methods for google maps autocomplete--------------
  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: "long_name" },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: "long_name" },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getville(place) {
    const COMPONENT_TEMPLATE = { locality: "long_name" },
      ville = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return ville;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: "short_name" },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getAddrComponent(place, componentTemplate) {
    let result;
    if (place.address_components) {
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (componentTemplate[addressType]) {
          result = place.address_components[i][componentTemplate[addressType]];
          return result;
        }
      }
    }
  }
}
