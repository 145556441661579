import { DevisAgenceBasicModel } from './devis-agence-basic.model';

export class TablePrestationChantierModel extends DevisAgenceBasicModel {

    public idDevis: number;
    public ht: number = 0;
    public ttc: number = 0;
    public tauxTva: number = 0;
    
    public majoration: number;
    public coeficient: number;
    public lignes: LigneChantier[] = [];
}

export class LigneChantier {
    libelle: string;
    qte: number;
    prix: number
    orders: SousLigneChantier[] = [];
  }
  
  export class SousLigneChantier {
    libelleActivite: string;
    moyens: string;
    qte: number;
    prix: number
    tva: number;
  }
