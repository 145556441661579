import { OffreModel } from './offre.model';
import { PrestationAgenceBasicModel } from './prestation-agence-basic.model';

export class PrestationModel extends PrestationAgenceBasicModel {
    public libelle: string;
    public idActivite: string;
    public offres: OffreModel[] = [];
    public defaultValue: string;
    public required: boolean = true;
}
