import { ActiviteModel } from '../../../shared/models/prestation.model';
import { ActiviteService } from '../../../services/prestation.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, OnInit, ElementRef, ViewChild, Input, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocomplete as MatAutocomplete} from '@angular/material/legacy-autocomplete';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';



export class DemandePrestation {
  prestation: ActiviteModel;
  value: boolean;
}

@Component({
  selector: 'app-select-prestation',
  templateUrl: './select-prestation.component.html',
  styleUrls: ['./select-prestation.component.scss']
})

export class SelectPrestationComponent implements OnInit, OnChanges {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  // prestation: PrestationModel;
  prestationCtrl = new UntypedFormControl();
  filteredPrestations: Observable<string[]>;


// Input / Output
// toute les prestations
  allPrestations: string[] = [];
  prestationsString: string[] = [];
  
  @Input() prestationsList: ActiviteModel[];
  @Input() prestationIds: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() updated = new EventEmitter<string>();


  @ViewChild('prestationInput', { static: true }) prestationInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(  private prestationService: ActiviteService) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allPrestations  = [];
    this.prestationsString = [];
    if(this.prestationsList){
      this.loadPrestations(this.prestationsList);
    } if (this.disabled) {
      this.prestationCtrl.disable();
    } else {
      this.prestationCtrl.enable();
    }
  }

  /* Charger les prestations  */
  loadPrestations(prestas: ActiviteModel[]) {
    let tabSplit = [];
    const prestaCopy = [];
    if (this.prestationIds !== null && this.prestationIds !== undefined) {
   /* Get  prestations liés a la demande */

      tabSplit = this.prestationIds.split(',');

      for (let i = 0; i < tabSplit.length; i++) {
        for (let y = 0; y < prestas.length; y++) {
          if (prestas[y].id.toString() === tabSplit[i]) {
            prestaCopy.push({ name: prestas[y].libelle, index: prestas[y].id });
            if (!this.prestationsString.find(libelle => libelle === prestas[y].libelle)) {
              this.prestationsString.push(prestas[y].libelle);
            }
          }
        }
      }

      let b: boolean;
      /* Get All prestations without demande prestations  */
      for (let i = 0; i < prestas.length; i++) {
        b = false;
        for (let y = 0; y < tabSplit.length; y++) {
          if (prestas[i].id.toString() === tabSplit[y]) {
            b = true;
          }
        } if (b === false) {
          if (!this.allPrestations.find(libelle => libelle === prestas[i].libelle)) {
            this.allPrestations.push(prestas[i].libelle);
          }
        }
      }
    } else {
      for (let i = 0; i < prestas.length; i++) {
        if (!this.allPrestations.find(libelle => libelle === prestas[i].libelle)) {
          this.allPrestations.push(prestas[i].libelle);
        }
      }
    }
    this.allPrestations.sort();
    this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
      startWith(null),
      map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));


}


/* Ajout d'une prestation avec Entrer */
 add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    const index = this.allPrestations.indexOf(value);

    this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
      let exist = false;
      for (let i = 0; i < prestas.length; i++) {
        if (value === prestas[i].libelle) {
              exist = true;
        }
      }
      // Add prestation
      if ((value || '').trim() && exist) {
        this.prestationsString.push(value.trim());
        this.allPrestations.splice(index, 1);
        this.allPrestations.sort();
        for (let i = 0; i < prestas.length; i++) {
          if (value === prestas[i].libelle) {
            if (this.prestationIds === undefined || this.prestationIds === null) {
              this.prestationIds =  prestas[i].id + ',' ;
            } else if (this.prestationIds.length > 0 && this.prestationIds[this.prestationIds.length - 1] !== ',' ) {
              this.prestationIds +=  ',' + prestas[i].id + ',' ;
            } else {
              this.prestationIds +=  prestas[i].id + ',' ;
            }
          }
        }
      }
   // Reset the input value
    if (input) {
      input.value = '';
    }
    this.prestationCtrl.setValue(null);
    this.updated.emit(this.prestationIds);
    });

    this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
      startWith(null),
    map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
    this.updated.emit(this.prestationIds);
    }

/* Supression d'une prestation*/
  remove(prestation: string): void {
    let id;
    const index = this.prestationsString.indexOf(prestation);
    if (index >= 0) {
      this.allPrestations.push(this.prestationsString[index]);
      this.allPrestations.sort();
      this.prestationsString.splice(index, 1);
    }

    this.prestationService.getAll().subscribe((prestas: ActiviteModel[]) => {
      for (let i = 0; i < prestas.length; i++) {
          if (prestas[i].libelle === prestation) {
             id = prestas[i].id;
          }
      }
      var idsArray = this.prestationIds.replace(' ', '').split(",");
      for(let i = 0 ; i < idsArray.length ; i++) {
        if (idsArray[i] === id.toString()) {
          idsArray.splice(i, 1);
          i--;
        }
      }
      this.prestationIds = idsArray.join(',');
      this.updated.emit(this.prestationIds);
    });

    this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
      startWith(null),
      map((myPrestation: string | null) => myPrestation ? this._filter(myPrestation) : this.allPrestations.slice()));

  }

/* Ajout d'une prestation avec selection */
selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    const index = this.allPrestations.indexOf(value);
    this.prestationsString.push(value);

    this.allPrestations.splice(index, 1);
    this.allPrestations.sort();

    this.prestationInput.nativeElement.value = '';
    this.prestationCtrl.setValue(null);

    for (let i = 0; i < this.prestationsList.length; i++) {
      if (value === this.prestationsList[i].libelle) {
        if (this.prestationIds === undefined || this.prestationIds === null) {
            this.prestationIds = this.prestationsList[i].id + ',' ;
        } else if (this.prestationIds.length > 0 && this.prestationIds[this.prestationIds.length - 1] !== ',' ) {
          this.prestationIds +=  ',' + this.prestationsList[i].id + ',' ;
        } else {
          this.prestationIds +=  this.prestationsList[i].id + ',' ;
        }
      }
    }
    this.updated.emit(this.prestationIds);
    this.filteredPrestations = this.prestationCtrl.valueChanges.pipe(
      startWith(null),
      map((prestation: string | null) => prestation ? this._filter(prestation) : this.allPrestations.slice()));
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return this.allPrestations.filter(prestation => prestation.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
  }

}
