
<div class="col-sm-12">
  <div class="col-sm-8">
      <h3> Gestion des protocoles de l'agence : <div style="display: inline;" *ngIf="agence">{{agence.libelle}}</div></h3>
  </div>
  <div class="col-sm-4 text-center">
    <a mat-stroked-button color="success" class="row" mat-button (click)="onAddProtocole()"><mat-icon>add</mat-icon> Ajouter un protocole</a>
  </div>
</div>
<div class="col-sm-12 table-inner-scroll-container">
  <table mat-table (matSortChange)="trieTab($event)" matSort [dataSource]="matDataSource" matSortActive="libelle" matSortDirection="asc" matSortDisableClear>
    <caption></caption>

      <ng-container matColumnDef="modifications">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell class="action-btns" *matCellDef="let element">
          <div class="btn-container center">
            <button mat-mini-fab class="tableau-btn" (click)="deleteProtocole(element); $event.stopPropagation()" color="warn">
              <mat-icon class="tableau-mat-icon">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="adresse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville </th>
        <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.ville !== null"> {{element.ville}} </div>
            <div *ngIf="element.ville === null || element.ville.trim() === '' "> Toutes </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="codePostal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code postal </th>
        <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.codePostal !== null"> {{element.codePostal}} </div>
            <div *ngIf="element.codePostal === null || element.codePostal.trim() === '' "> Toutes </div>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="prestation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Préstation </th>
        <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.prestation !== null"> {{element.prestation.libelle}} </div>
            <div *ngIf="element.prestation === null"> Toutes </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="realisateur">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Réalisateur </th>
        <td mat-cell *matCellDef="let element"> 
        <div *ngIf="element.realisateur !== null">   {{element.realisateur.nom}} {{element.realisateur.prenom}} </div>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="protocole">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Protocole </th>
        <td mat-cell *matCellDef="let element">  {{element.protocole}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onOpenProtocole(row)"></tr>
  </table>
</div>
<mat-paginator
    class="paginator"
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]"
    (page)="changePage($event)">
</mat-paginator>