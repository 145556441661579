import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { InterlocuteurModel } from '../../../core/shared/models/interlocuteur.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { SimpleQuery } from '../../../core/shared/simple_query/simpleQuery';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { InterlocuteurService } from '../../../core/services/interlocuteur.service';
import { TierModel } from '../../../core/shared/models/tier/tier.model';
import { InterlocuteurDTO } from '../../../core/shared/dtos/interlocuteurDTO';
import { filterTierDTO } from '../../../core/shared/dtos/filterTierDTO';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalAjoutInterlocuteurComponent } from '../../../core/components/interlocuteurs/modal-ajout-interlocuteur.component';
import { ModalAfficherInterlocuteurComponent } from 'src/app/modules/core/components/interlocuteurs/modal-afficher-interlocuteur.component';
import { PriseAppelService } from 'src/app/modules/core/services/prise-appel.service';
import { Router } from '@angular/router';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import * as _ from 'lodash';    
import { Subscription } from 'rxjs';
import { InterlocuteurPgResult } from 'src/app/modules/core/shared/dtos/interlocuteurPgResult';
import { DemandeDTO } from 'src/app/modules/core/shared/dtos/demandeDTO';
import { ModalHistoriqueDemandesComponent } from '../modals/modal-historique-demandes/modal-historique-demandes.component';
import { DemandeService } from 'src/app/modules/core/services/demande.service';

@Component({
  selector: 'app-list-interlocuteurs',
  templateUrl: './list-interlocuteurs.component.html',
  styleUrls: ['./list-interlocuteurs.component.scss']
})


export class ListInterlocuteursComponent implements OnInit, OnDestroy {

  displayedColumnsInterlocuteurs: string[] = ['role','nom', 'prenom', 'telPrincipal',  'telSecondaire','email', 'Actions'];
  interlocuteurs: InterlocuteurModel[] = [];  
  matDataSource: MatTableDataSource<InterlocuteurPgResult>;

  length: number;
  pageIndex: number;
  pageSize = 10;
  sortState: Sort = {active: 'nom', direction: 'desc'};

  @Input() contactRequired =false
  @Input() selectedInterlocDuplique;
  @Input() simpleQueryInterlocuteur: SimpleQuery<InterlocuteurDTO>;
  @Input() selectInterlocuteurTier : TierModel; //Utilisé pour trigger ngOnChanges dans tier-adresses-component
  @Input() tierFilter: filterTierDTO;
  @Input() updateInterloc = false;
  @Input() isInCreateTiersModal : boolean;
  @Output() selectedInterlocTiers = new EventEmitter<InterlocuteurModel>();
  subscriptionInterlocuteur: Subscription;
  selectedInterlocuteur:InterlocuteurModel = new InterlocuteurModel();
  oldTierFiltre : filterTierDTO = new filterTierDTO();
  
  constructor(private interlocuteurService : InterlocuteurService, 
    private priseAppelService : PriseAppelService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private demandeService: DemandeService,
    public router : Router,
    private toastrLoadingService: ToastrLoadingService) { }

  ngOnInit(): void {
    this.simpleQueryInterlocuteur.pageSize = this.pageSize;

    this.subscriptionInterlocuteur = this.priseAppelService.currentselectedInterlocuteur.subscribe(selectedInterloc => {
      if(!_.isEqual(this.selectedInterlocuteur,selectedInterloc)  && this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
        let interloc = selectedInterloc
        if(this.selectedInterlocuteur != undefined){
          if(this.selectedInterlocuteur.id != interloc.id && this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
            this.selectedInterlocuteur = interloc
            this.changeInterlocuteur();
            this.selectedInterlocTiers.emit(this.selectedInterlocuteur)
            this.ref.detectChanges();
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if(changes.selectInterlocuteurTier && changes.selectInterlocuteurTier.currentValue && changes.selectInterlocuteurTier.currentValue != changes.selectInterlocuteurTier.previousValue){
      this.simpleQueryInterlocuteur.pageNumber = 0;
      this.pageIndex = 0;
      if(Object.keys(this.tierFilter.interlocuteur).length > 0){
        this.oldTierFiltre = Object.assign({},this.tierFilter);
        this.simpleQueryInterlocuteur.entry.Nom = this.tierFilter.interlocuteur.Nom ? this.tierFilter.interlocuteur.Nom + " " : "" + this.tierFilter.interlocuteur.Prenom ? this.tierFilter.interlocuteur.Prenom : "";
        this.simpleQueryInterlocuteur.entry.TelPrincipal = this.tierFilter.interlocuteur.TelPrincipal;
      }
      this.changeInterlocuteur();
    }
  }


  ngOnDestroy(): void {
    this.subscriptionInterlocuteur.unsubscribe();
  }

  changePage(event?: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.simpleQueryInterlocuteur.pageNumber = event.pageIndex;
    this.simpleQueryInterlocuteur.pageSize = event.pageSize;
    
    this.changeInterlocuteur();
  }

  changePhone(value) {
    this.simpleQueryInterlocuteur.entry.TelPrincipal = this.formatPhoneNumper(value);
  }

  formatPhoneNumper(input: string){
    let tel = null;
    if (input) {
      input = input.slice(0, 14);
      const tel1 = input.replace(/[^\d]/g, '');
      let tel2 = '';
      for (let i = 0; i < tel1.length; i = i + 2 ) {
        tel2 += tel1.substring(i, i + 2) + '.';
      }
      tel = tel2.substring(0, tel2.length - 1);
    }
    return(tel);
  }

  onAddInterlocuteur(): void {
    if(this.selectInterlocuteurTier) {
      const dialogRef = this.dialog.open(ModalAjoutInterlocuteurComponent, {disableClose: true,
        width: '700px',
        data: {
          filter: this.tierFilter,
          tierEntreprise: this.selectInterlocuteurTier.typeTier !== 'Particulier',
          tierId: this.selectInterlocuteurTier.id,
          libelleTier : this.selectInterlocuteurTier.libelle,
          tierIdTJason: this.selectInterlocuteurTier.idTJason,
          tierIdentifiantJason: this.selectInterlocuteurTier.identifiantJason
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data && data.result) {
          const currentdata = this.matDataSource.data
          currentdata.unshift(data.interloc);
          this.matDataSource = new MatTableDataSource(currentdata);
          this.matDataSource._updateChangeSubscription();
          this.selectedInterlocTiers.emit(data.interloc);
          this.selectedInterlocuteur=data.interloc;
          if(this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
            this.priseAppelService.updateselectedInterlocuteur(this.selectedInterlocuteur);
          }
        }
      });
    }
  }

  onEditInterloc(interloc): void {
    if(this.selectInterlocuteurTier) {
      const dialogRef = this.dialog.open(ModalAfficherInterlocuteurComponent, {disableClose: true,
        width: '700px',
        data: {
          interloc: Object.assign({}, interloc),
          tierId: this.selectInterlocuteurTier.id,
          tierIdTJason: this.selectInterlocuteurTier.idTJason,
          tierEntreprise: this.selectInterlocuteurTier.typeTier !== 'Particulier', 
          tierIdentifiantJason: this.selectInterlocuteurTier.identifiantJason,
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data && data.result) {
          let currentdata = this.matDataSource.data;
          currentdata.splice(currentdata.findIndex(i => interloc.id == i.interlocuteur.id), 1);
          currentdata.unshift(data.interloc);
          this.matDataSource = new MatTableDataSource(currentdata);
          this.matDataSource._updateChangeSubscription();
          this.selectedInterlocTiers.emit(data.interloc);
          this.selectedInterlocuteur=data.interloc;
          if(this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
            this.priseAppelService.updateselectedInterlocuteur(this.selectedInterlocuteur);
          }
        }
      });
    }
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.simpleQueryInterlocuteur.order = sort.active;
    this.simpleQueryInterlocuteur.descending = sort.direction === 'desc';
    this.changeInterlocuteur();
  }

  changeInterlocuteur() {
    let idSearch: number;
    if (this.simpleQueryInterlocuteur.entry.TierId) {
      idSearch = this.toastrLoadingService.newSearch();
      this.interlocuteurService.GetFromObjetPgWithPagination(this.simpleQueryInterlocuteur).subscribe((queryResponse) => {
        this.interlocuteurs = queryResponse.objets.map(x=>x.interlocuteur);
        this.matDataSource = new MatTableDataSource(queryResponse.objets);
        this.length = queryResponse.count;
        this.pageIndex = queryResponse.pageNumber;
        // selected interlocuteur if duplication
        if(this.selectedInterlocDuplique){
          this.selectedInterlocuteur = this.selectedInterlocDuplique;
          this.selectedInterlocTiers.emit(this.selectedInterlocuteur)
        }
        this.toastrLoadingService.endSearch(idSearch);
      }, error => {
        this.toastrLoadingService.endSearch(idSearch);
      });
    }
  }

  selectInterlocuteur(event,row){
    if(this.selectedInterlocuteur && this.selectedInterlocuteur.id == row.id){
        this.selectedInterlocuteur = new InterlocuteurModel()
    } else {
      this.selectedInterlocuteur=row;
    }
    this.selectedInterlocTiers.emit(this.selectedInterlocuteur)
    if(this.router.url === '/prise-appel' && (this.isInCreateTiersModal == false || this.isInCreateTiersModal == undefined)){
      this.priseAppelService.updateselectedInterlocuteur(this.selectedInterlocuteur);
    }
  }

  openHistoriqueDemandesInterlocuteur(interlocuteur: InterlocuteurModel) {
    var demandeDTO = new DemandeDTO();
    demandeDTO.interlocuteurId = interlocuteur.id;
    demandeDTO.tierId = interlocuteur.tierId;
    const query = new SimpleQuery<DemandeDTO>();
    query.entry = demandeDTO;
    query.order = 'DateCreation';
    query.descending = true;
    query.pageSize = 10;
    this.demandeService.getFromObjet(query).subscribe(res =>  {
      const dialogRef = this.dialog.open(ModalHistoriqueDemandesComponent, {
        data: {
          title: "Historique des demandes du tiers",
          demandes: res.objets,
          query: query
        }
      });
    })
  }

}
