import { Component, EventEmitter, Injector, OnInit, Output, ChangeDetectorRef, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DemandeService } from 'src/app/modules/core/services/demande.service';
import { FiltreService } from 'src/app/modules/core/services/filter.service';
import { TypeDemandeService } from 'src/app/modules/core/services/type-demande.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { DemandeDTO } from 'src/app/modules/core/shared/dtos/demandeDTO';
import { FiltreDemandeDTO } from 'src/app/modules/core/shared/dtos/filterDemandeDTO';
import { ModalsDemandeInfos } from 'src/app/modules/core/shared/modals-menu-info';
import { DemandeModel } from 'src/app/modules/core/shared/models/demande.model';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';
import { TypeActionModel } from 'src/app/modules/core/shared/models/type-action.model';
import { TypeDemandeModel } from 'src/app/modules/core/shared/models/type-demande.model';
import { UserModel } from 'src/app/modules/core/shared/models/users/user.model';
import { SimpleQuery } from 'src/app/modules/core/shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from 'src/app/modules/core/shared/simple_query/simpleQueryResponse';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ModalSpinnerComponent } from '../../../divers/modals/modal-spinner/modal-spinner.component';
import { saveAs } from 'file-saver';
import { SocieteModel } from 'src/app/modules/core/shared/models/rsa/societe.model';
import { AgenceModel } from 'src/app/modules/core/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/modules/core/services/rsa/agence.service';
import { SocieteService } from 'src/app/modules/core/services/rsa/societe.service';
import * as _ from 'lodash';
import { CommercialModel } from 'src/app/modules/core/shared/models/users/commercial.model';
import { ToastrLoadingService } from 'src/app/modules/core/services/toastrLoading.service';
import { EtatUrgencePxo } from 'src/app/modules/core/shared/etatUrgencePxo';
import { ColumnService } from 'src/app/modules/shared/column.service';
import { ActivatedRoute } from '@angular/router';
import { AutocompleteTiersComponent } from '../../../divers/autocompletes/autocomplete-tiers/autocomplete-tiers.component';

export interface DialogData {
  info: ModalsDemandeInfos;
}

@Component({
  selector: "app-search-demande",
  templateUrl: "./search-demande.component.html",
  styleUrls: ["./search-demande.component.scss"],
})
export class SearchDemandeComponent implements OnInit {


  @Output() tierOpen = new EventEmitter<TierModel>();
  users: UserModel[] = [];
  realisateur: UserModel;
  createur: UserModel;
  demandeDatasource: DemandeModel[] = [];
  demande = new DemandeDTO();
  typeDemandes: TypeDemandeModel[] = [];
  simpleQueryResponse = new SimpleQueryResponse<DemandeModel>();
  selectedDemande: DemandeModel;
  panelOpenState = true;
  result: any;
  loading = false;
  pageSize = 50;
  pageIndex = 0;
  length: number;
  public displayedColumns: string[] = [];
  public totalColumns: string[];

  private modalsService: ModalsService;
  public dateSubject: Subject<Date>;
  statuts = ["A traiter", "En cours", "Annulé", "Clos"];
  keyTitleArray = [
    { property: "objet", libelle: "objet" },
    { property: "DateSmallerInterventionString", libelle: "date inter" },
    { property: "statut", libelle: "statut" },
  ];
  typeActions: TypeActionModel[] = [];
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  filtreData: FiltreDemandeDTO = new FiltreDemandeDTO();
  demandeList: DemandeModel[] = [];
  simpleQueryResponse1 = new SimpleQueryResponse<DemandeModel>();
  private filteredSubscribe: Subscription;
  labelTiers = "Tiers";
  labelTiersFacture = "Tiers Facturé";
  numDemandes: DemandeModel[];

  listStatus = ["A traiter", "En cours", "Annulé", "Clos"];
  listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);

  listTypesDemandes: TypeDemandeModel[];
  listTypesDemandesString: string[] = [];
  listTypesDemandesUpdated = new BehaviorSubject<string[]>(this.listTypesDemandesString);

  listTypesUrgences: TypeDemandeModel[];
  listTypesUrgencesString: string[] = [];
  listTypesUrgencesUpdated = new BehaviorSubject<string[]>(this.listTypesUrgencesString);

  selectedSocieteIdsArray = new Set<number>();
  selectedAgenceIdsArray = new Set<number>();
  societes: SocieteModel[];
  agences: AgenceModel[];
  selectedSocietesString : string = "";

  eventsSubjectAgenceArray: Subject<Set<number>> = new Subject();
  eventsSubjectSocieteArray: Subject<Set<number>> = new Subject();

  selectedRealisateurIdsArray = new Set<number>();
  eventsSubjectRealisateurArray: Subject<Set<number>> = new Subject();
  selectedCreateurIdsArray = new Set<number>();
  eventsSubjectCreateurArray: Subject<Set<number>> = new Subject();


  selectedGestionnaireIdsArray = new Set<number>();
  eventsSubjectGestionnaireArray: Subject<Set<number>> = new Subject();

  resetSelect = true;

  nbFilterApplied: number = 0;

  @ViewChild('autocompleteTiers') autocompleteTiers: AutocompleteTiersComponent;
  @ViewChild('autocompleteTiersFacture') autocompleteTiersFacture: AutocompleteTiersComponent;
  
  constructor(
    public dialog: MatDialog,
    private demandeService: DemandeService,
    private userService: UserService,
    private typeDemandeService: TypeDemandeService,
    private IntercomToastrService: IntercomToastrService,
    private filtreService: FiltreService,
    private changeDetector: ChangeDetectorRef,
    private agenceService: AgenceService,
    private societeService: SocieteService,
    injector: Injector,
    private cdr:ChangeDetectorRef,
    private toastrLoadingService: ToastrLoadingService,
    public columnService: ColumnService,
    private route: ActivatedRoute
  ) {
    this.modalsService = injector.get("ModalsService");
  }

  resetSelects() {
    this.listTypesDemandesString = [];
    this.typeDemandeService
      .getAllActive()
      .subscribe((types: TypeDemandeModel[]) => {
        this.typeDemandes = types;
        this.listTypesDemandes = types;
        types.forEach((x) => this.listTypesDemandesString.push(x.libelle));
      });
    this.listTypesDemandesUpdated = new BehaviorSubject<string[]>(
      this.listTypesDemandesString
    );

    this.listTypesUrgencesString = [];
    this.listTypesUrgences = EtatUrgencePxo.ETATURGENCE;
    for(let i = 0 ; i < this.listTypesUrgences.length ; i++) {
      this.listTypesUrgencesString.push(this.listTypesUrgences[i].libelle)
    }
    this.listTypesUrgencesUpdated = new BehaviorSubject<string[]>(
      this.listTypesUrgencesString
    );

    this.listStatus = ["A traiter", "En cours", "Annulé", "Clos"];
    this.listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);

    this.resetSelect = true;
  }

  clearFiltre() {
    this.resetSelect = false;
    this.filtreData = new FiltreDemandeDTO();
    this.filtreData.pageNumber = 0;
    this.filtreData.agences = [];
    this.filtreData.societes = [];
    this.filtreData.realisateurs = [];
    this.filtreData.createurs = [];

    this.filtreData.FAnnulePxoSelected = false;

    this.autocompleteTiers.reinit();
    this.autocompleteTiersFacture.reinit();
    
    this.selectedAgenceIdsArray.clear();
    this.selectedSocieteIdsArray.clear();
    this.selectedRealisateurIdsArray.clear();
    this.selectedCreateurIdsArray.clear();
    this.selectedGestionnaireIdsArray.clear();
    this.selectedAgenceIdsArray = new Set();
    this.selectedSocieteIdsArray = new Set();
    this.selectedRealisateurIdsArray = new Set();
    this.selectedCreateurIdsArray = new Set();
    this.selectedGestionnaireIdsArray = new Set();
    this.selectedSocietesString  = "";
    this.onChange(this.filtreData);
    this.resetSelects();

  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.filteredSubscribe.unsubscribe();
  }

  ngOnInit(): void {

    this.route.params.subscribe((params: any) => {
      if (params.id) {
        let toast = this.toastrLoadingService.info("Chargement de la demande en cours");
        this.demandeService.get(params.id).subscribe(dem => {
          toast.toastRef.close();
          if (dem) {
            this.toastrLoadingService.success("Chargement de la demande réussit");
            this.onOpenDemande(dem);
          }
          else
          {
            this.toastrLoadingService.error("Demande introuvable");
          }
        }, (error) => {
          this.toastrLoadingService.error("Erreur dans le chargement de la demande : " + error.message);
        });
      }
    });

    this.pageIndex = 0;
    this.typeDemandeService
      .getAllActive()
      .subscribe((types: TypeDemandeModel[]) => {
        this.typeDemandes = types;
        this.listTypesDemandes = types;
        types.forEach((x) => this.listTypesDemandesString.push(x.libelle));
      });

      this.listTypesUrgences = EtatUrgencePxo.ETATURGENCE;
      for(let i = 0 ; i < this.listTypesUrgences.length ; i++) {
        this.listTypesUrgencesString.push(this.listTypesUrgences[i].libelle)
      }

    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
    });

    if (this.demande.rechercheTitle !== undefined) {
      this.rechercheDemande(new SimpleQuery<DemandeDTO>(this.demande));
    }

    this.societeService.getAllManaged(1).subscribe((societes) => {
      this.societes = (societes as SocieteModel[]).sort((a, b) => this.sociteteName(a).localeCompare(this.sociteteName(b)));
    });

    this.agenceService.getAllManaged(1).subscribe((agences) => {
      this.agences = (agences as AgenceModel[]).sort((a, b) => this.agenceName(a).localeCompare(this.agenceName(b)));
    });

    this.dateSubject = new Subject<Date>();
    this.dateSubject
      .pipe(debounceTime(100))
      .subscribe((_) => this.onClickSearchDemande());

    this.filteredSubscribe = this.filtreService.filtreDemande
      .pipe(debounceTime(100))
      .subscribe((result) => {
        let idSearch: number;
        idSearch = this.toastrLoadingService.newSearch();
        this.filtreData = result;

        this.selectedSocieteIdsArray.clear();
        this.selectedSocietesString  = "";
        if (this.filtreData.societes && this.filtreData.societes.length > 0) {
          this.filtreData.societes.forEach((societe) => {
            this.selectedSocieteIdsArray.add(societe.id);
            this.selectedSocietesString += societe.id + ',';
          });
        }
        this.eventsSubjectSocieteArray.next(this.selectedSocieteIdsArray);
        this.selectedAgenceIdsArray.clear();
        this.filtreData.agences.forEach((agence) => (this.selectedAgenceIdsArray.add(agence.id)));
        this.eventsSubjectAgenceArray.next(this.selectedAgenceIdsArray);

        this.selectedRealisateurIdsArray.clear();
        this.filtreData.realisateurs.forEach((real) => (this.selectedRealisateurIdsArray.add(real.id)));
        this.eventsSubjectRealisateurArray.next(this.selectedRealisateurIdsArray);

        this.selectedCreateurIdsArray.clear();
        this.filtreData.createurs.forEach((crea) => (this.selectedCreateurIdsArray.add(crea.id)));
        this.eventsSubjectCreateurArray.next(this.selectedCreateurIdsArray);
        
        this.selectedGestionnaireIdsArray.clear();
        this.filtreData.gestionnaires.forEach((gest) => (this.selectedGestionnaireIdsArray.add(gest.id)));
        this.eventsSubjectGestionnaireArray.next(this.selectedGestionnaireIdsArray);

        
        if (result.status) {
          this.listStatusUpdated.next(result.status);
        }

        
        var typesDemandeList: string[] = [];
        if (result.typeDemandeId) {
          result.typeDemandeId.forEach((TypDemId) => {
            var typeFound =this.typeDemandes.find((TypDem) => TypDem.id == TypDemId);
            if (typeFound)
              typesDemandeList.push(typeFound.libelle)
          }
          );
          this.listTypesDemandesUpdated.next(typesDemandeList);
        }

        var typesUrgenceList: string[] = [];
        if (result.etatUrgence) {
          result.etatUrgence.forEach((TypUrgId) =>
          typesUrgenceList.push(this.listTypesUrgences.find((TypUrg) => TypUrg.id == TypUrgId).libelle)
          );
          this.listTypesUrgencesUpdated.next(typesUrgenceList);
        }

        this.nbFilterApplied = this.countFilterApplied();
        this.demandeService
          .getFiltredDemande(this.filtreData)
          .pipe(debounceTime(700))
          .subscribe((x) => {
            this.toastrLoadingService.endSearch(idSearch);
            this.demandeList = x.objets;
            this.simpleQueryResponse1 = x;
            this.length = x.count;
            this.loading = false;
            this.searchResult(x.objets);
          }, error => {
            this.toastrLoadingService.endSearch(idSearch);
          });
      });
  }

  load(): void {
    this.userService.getAll().subscribe((usrs: UserModel[]) => {
      this.users = usrs;
    });
  }

  loadData(value: string) {
    if (value) {
      this.demandeService.getAllFiltred(value).subscribe((x) => {
        this.numDemandes = x;
      });
    } else {
      this.numDemandes = [];
    }
  }

  onTiersChanged(event, state) {
    switch (state) {
      case "Tier":
        if (event) {
          this.filtreData.tierId = event.id;
        } else {
          this.filtreData.tierId = null;
        }
        break;
      case "TierFacture":
        if (event) {
          this.filtreData.tierFactureId = event.id;
        } else {
          this.filtreData.tierFactureId = null;
        }
        break;
    }
    this.onChange(event);
  }

  onSelectOptionChange(event, state) {
    switch (state) {
      case "Statut":
        this.filtreData.status = event;
        break;
      case "Type":
        let tempLibelleType = this.listTypesDemandes.filter((x) =>
          event.includes(x.libelle)
        );
        let tempIdType = [];
        tempLibelleType.forEach((x) => tempIdType.push(x.id));
        this.filtreData.typeDemandeId = tempIdType;
        break;
      case "EtatUrgence":
        if (event.includes(this.listTypesUrgences.filter(x => x.id === EtatUrgencePxo.ANNULEINDEX)[0].libelle)) {
          this.filtreData.FAnnulePxoSelected = true;
        } else {
          this.filtreData.FAnnulePxoSelected = false;
        }
        let tempLibelleUrgence = this.listTypesUrgences.filter((x) =>
          event.includes(x.libelle)
        );
        if (tempLibelleUrgence.length == this.listTypesUrgences.length) {
          this.filtreData.etatUrgence = null;
        } else {
          let tempIdUrgence = [];
          tempLibelleUrgence.forEach((x) => tempIdUrgence.push(x.id));
          this.filtreData.etatUrgence = tempIdUrgence;
        }
        break;
    }
    this.onChange(event);
  }

  onChange(event): void {
    this.filtreData.pageNumber = 0;
    this.selectedSocietesString  = "";
    this.filtreData.societes.forEach((societe) => {
      this.selectedSocieteIdsArray.add(societe.id);
      this.selectedSocietesString += societe.id + ',';
    });
    this.filtreData.agences.forEach((agence) => (this.selectedAgenceIdsArray.add(agence.id)));
    this.filtreData.realisateurs.forEach((real) => (this.selectedRealisateurIdsArray.add(real.id)));
    this.filtreData.createurs.forEach((crea) => (this.selectedCreateurIdsArray.add(crea.id)));
    this.filtreData.gestionnaires.forEach((real) => (this.selectedGestionnaireIdsArray.add(real.id)));
    this.filtreService.changeFiltreDemande(this.filtreData);
    this.nbFilterApplied = this.countFilterApplied();
  }

  onTiersChange(value: string) {
    this.demande.tierLibelle = value;
    this.rechercheDemande(new SimpleQuery<DemandeDTO>(this.demande));
  }

  rechercheDemande(query: SimpleQuery<DemandeDTO>) {
    this.loading = true;
    if (this.demande.typeDemandeId === 0) {
      this.demande.typeDemandeId = null;
    }
    query.entry = this.demande;
    this.demandeService
      .getFromObjet(query)
      .subscribe((response: SimpleQueryResponse<DemandeModel>) => {
        this.demandeDatasource = response.objets;
        this.simpleQueryResponse = response;
        this.length = response.count;
        this.loading = false;
        this.searchResult(response.objets);
      });
  }

  changePageSize(num: number) {
    this.pageSize = num;
    this.rechercheDemande(new SimpleQuery<DemandeDTO>(this.demande));
  }

  changePageNumber(num: number) {
    this.pageIndex = num;
    this.rechercheDemande(new SimpleQuery<DemandeDTO>(this.demande));
  }

  onTierOpen(tier: TierModel) {
    this.tierOpen.emit(tier);
  }

  onClickSearchDemande() {
    this.rechercheDemande(new SimpleQuery<DemandeDTO>(this.demande));
  }

  onOpenDemande(demande: DemandeModel): void {
    this.demandeService.get(demande.id).subscribe((demande) => {
      this.modalsService.openDemande(demande).subscribe((result) => {
        if (result) {
          var demModifIndex = this.demandeList.findIndex(x => x.id == result.id)
          this.demandeList[demModifIndex] = result;
          this.demandeList = Object.assign([], this.demandeList);
        }
      });
    });
  }

  setradio(id: number): void {
    this.demande.typeDemandeId = id;
    this.rechercheDemande(new SimpleQuery<DemandeDTO>(this.demande));
  }

  searchResult(searchWord: any) {
    if (searchWord.length === 0) {
      this.IntercomToastrService.warning(
        "Oups : " + "Aucun résultat pour votre recherche"
      );
    }
  }

  pageChange(pageEvent: SimpleQuery<any>) {
    const demandeFilter = new SimpleQuery(new FiltreDemandeDTO());
    if (pageEvent.order) {
      this.filtreData.order = pageEvent.order;
    }
    if (pageEvent) {
      this.filtreData.descending = pageEvent.descending;
    }
    this.filtreData.pageNumber = pageEvent.pageNumber;
    this.filtreData.pageSize = pageEvent.pageSize;
    this.filtreService.changeFiltreDemande(this.filtreData);
  }

  onExport(): void {
    const dialogRef = this.dialog.open(ModalSpinnerComponent);
    this.demandeService.exportDemandes(this.filtreData).subscribe(
      (res: BlobPart) => {
        const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "Export_Demandes_" + new Date().toISOString() + ".csv");
        dialogRef.close();
      },
      (error) => dialogRef.close()
    );
  }

    societeChange(event) {
    if (event != undefined && event.length != 0) {
      let societes: SocieteModel[] = [];
      societes = this.societes.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.societes = [];
      this.filtreData.societes = societes;
      let agences : AgenceModel[] = [];
      agences = this.filtreData.agences.filter(item => event.includes(item.societeId));
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.societes = [];
      this.onChange(this.filtreData);
    }
  }

  agenceChange(event) {
    if (event != undefined && event.length != 0) {
      let agences: AgenceModel[] = [];
      agences = this.agences.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.agences = [];
      this.onChange(this.filtreData);
    }
  }

  agenceName(agence: AgenceModel) {
    return (agence.societe.libelle + " / " + agence.code + "-" + agence.libelle);
  }

  sociteteName(soc: SocieteModel) {
    return (soc.region.libelle + ' / ' + soc.code + '-' + soc.libelle);
  }

  realisateurChange(event) {
    if (event != undefined && event.length != 0) {
      let realisateurs: CommercialModel[] = [];
      realisateurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.realisateurs = [];
      this.filtreData.realisateurs = realisateurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.realisateurs = [];
      this.onChange(this.filtreData);
    }
  }

  createurChange(event) {
    if (event != undefined && event.length != 0) {
      let createurs: CommercialModel[] = [];
      createurs = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.createurs = [];
      this.filtreData.createurs = createurs;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.createurs = [];
      this.onChange(this.filtreData);
    }
  }
  gestionnaireChange(event) {
    if (event != undefined && event.length != 0) {
      let gestionnaires: CommercialModel[] = [];
      gestionnaires = this.users.filter((item) => event.indexOf(item.id) !== -1);
      this.filtreData.gestionnaires = [];
      this.filtreData.gestionnaires = gestionnaires;
      this.onChange(this.filtreData);
    } else {
      this.filtreData.gestionnaires = [];
      this.onChange(this.filtreData);
    }
  }

  countFilterApplied(): number {
    let nbFilter = 0;
    nbFilter += this.filtreData.adresse?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.codePostal?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.commentaire?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.commentaireApprox?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.agences?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.createurs?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.startDCreationDemande !== null || this.filtreData.endDCreationDemande !== null ? 1 : 0;
    nbFilter += this.filtreData.startDInterventionDemande !== null || this.filtreData.endDInterventionDemande !== null ? 1 : 0;
    nbFilter += this.filtreData.etatUrgence?.length > 0 && this.filtreData.etatUrgence?.length < this.listTypesUrgencesString.length ? 1 : 0;
    nbFilter += this.filtreData.gestionnaires?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.numeroDemande?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.objet?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.realisateurs?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.societes?.length > 0 ? 1 : 0;
    nbFilter += this.filtreData.tierFactureId !== null ? 1 : 0;
    nbFilter += this.filtreData.tierId !== null ? 1 : 0;
    nbFilter += this.filtreData.typeDemandeId?.length > 0  && this.filtreData.typeDemandeId?.length < this.typeDemandes?.length ? 1 : 0;
    nbFilter += this.filtreData.ville?.trim().length > 0 ? 1 : 0;
    nbFilter += this.filtreData.status?.length > 0 && this.filtreData.status?.length < this.statuts?.length ? 1 : 0;
    return nbFilter;
  }
  
}
