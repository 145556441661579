import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GmapsSiteGeoModel } from '../../core/shared/models/gmapsSiteGeo.model';

@Injectable()
export class GmapsService {
  private autoCompleteService: google.maps.places.AutocompleteService;
  private geocodeService: google.maps.Geocoder;
  constructor(private http: HttpClient) {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.geocodeService = new google.maps.Geocoder();
  }

  getAutoCompleteResults(query: string): Observable<GmapsSiteGeoModel[]> {
    const request: google.maps.places.AutocompletionRequest = { input: query, componentRestrictions: {country: 'FR'}, types: ['address'] };
    const observable = new Subject<google.maps.places.AutocompletePrediction[]>();
    this.autoCompleteService.getPlacePredictions(request,
      function(result: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          observable.next(result);
        }
    });
    const sentObservable: Observable<GmapsSiteGeoModel[]> = observable.pipe(map((response: google.maps.places.AutocompletePrediction[]) => {
        const results: GmapsSiteGeoModel[] = [];
        let descriptions;
        for (const prediction of response) {
          const gmapsSite = new GmapsSiteGeoModel();
          descriptions = prediction.description.split(', ');
          gmapsSite.placeId = prediction.place_id;
          if (descriptions.length === 3) {
            gmapsSite.adresse = descriptions[0];
            gmapsSite.ville = descriptions[1];
            results.push(gmapsSite);
          }
        }
        return results;
      }));
    return sentObservable;
  }

  getGeoCodeResultBoundsByCodePostal(query: string): Observable<google.maps.GeocoderGeometry[]> {
    const request: google.maps.GeocoderRequest = { address: query };
    const observable = new Subject<google.maps.GeocoderResult[]>();
    this.geocodeService.geocode(request,
      function(result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
        if (status === google.maps.GeocoderStatus.OK) {
          observable.next(result);
        }
    });
    const sentObservable: Observable<google.maps.GeocoderGeometry[]> = observable.pipe(map((response: google.maps.GeocoderResult[]) => {
      const results: google.maps.GeocoderGeometry[] = [];
      if (response && response.length > 0) {
        const geometry = response[0].geometry;
        if (geometry !== undefined) {
          results.push(geometry);
        } else {
          results.push(undefined);
        }
      }
      return results;
    }));
  return sentObservable;
  }

  getGeoCodeResults(query: string): Observable<GmapsSiteGeoModel[]> {
    const request: google.maps.GeocoderRequest = { placeId: query };
    const observable = new Subject<google.maps.GeocoderResult[]>();
    this.geocodeService.geocode(request,
      function(result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
        if (status === google.maps.GeocoderStatus.OK) {
          observable.next(result);
        }
    });
    const sentObservable: Observable<GmapsSiteGeoModel[]> = observable.pipe(map((response: google.maps.GeocoderResult[]) => {
        const results: GmapsSiteGeoModel[] = [];
        if (response && response.length > 0) {
          const adresseComponents = response[0].address_components;
          if (adresseComponents !== undefined) {
            const gmapsSite = new GmapsSiteGeoModel();
            const objet_street_number = adresseComponents.find(comp => comp.types.find(type => type === 'street_number'));
            const objet_route = adresseComponents.find(comp => comp.types.find(type => type === 'route'));
            const objet_locality = adresseComponents.find(comp => comp.types.find(type => type === 'locality'));
            const objet_postal_code = adresseComponents.find(comp => comp.types.find(type => type === 'postal_code'));
            let number: string, route: string , locality: string, postal_code: string;
            number = objet_street_number !== undefined ? objet_street_number.long_name : '';
            route = objet_route !== undefined ? objet_route.long_name : '';
            locality = objet_locality !== undefined ? objet_locality.long_name : '';
            postal_code = objet_postal_code !== undefined ? objet_postal_code.long_name : '';
            gmapsSite.adresse = number + ' ' + route;
            gmapsSite.ville = locality;
            gmapsSite.codePostal = postal_code;
            gmapsSite.placeId = response[0].place_id;
            results.push(gmapsSite);
          } else {
            results.push(undefined);
          }
        }
        return results;
      }));
    return sentObservable;
  }


  getAutoCompleteVilleResults(query: string): Observable<GmapsSiteGeoModel[]> {
    const request: google.maps.places.AutocompletionRequest = { input: query, componentRestrictions: {country: 'FR'}, types: ['(cities)']};
    const observable = new Subject<google.maps.places.AutocompletePrediction[]>();
    this.autoCompleteService.getPlacePredictions(request,
      function(result: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          observable.next(result);
        }
    });
    const sentObservable: Observable<GmapsSiteGeoModel[]> = observable.pipe(map((response: google.maps.places.AutocompletePrediction[]) => {
        const results: GmapsSiteGeoModel[] = [];
        let descriptions;
        for (const prediction of response) {
          const gmapsSite = new GmapsSiteGeoModel();
          descriptions = prediction.description.split(', ');
          gmapsSite.placeId = prediction.place_id;
          if (descriptions.length === 2 || descriptions.length === 3) {
            gmapsSite.ville = descriptions[0];
            results.push(gmapsSite);
          }
        }
        return results;
      }));
    return sentObservable;
  }

}
