import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { FormInfo, OFFREVALIDATORS } from "../constant/offre-validators";
import { PRESTATIONVALIDATORS } from "../constant/prestation-validators";
import { OffreModel } from "../models/offre.model";
import { PrestationModel } from "../models/prestation.model";

@Injectable()
export class ParametrePrestationComponentService {

  constructor() {}

  getForm(entity: any, validators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, value] of validators.entries()) {
      // tslint:disable-next-line: max-line-length
      const control = new UntypedFormControl({ value: entity[key], disabled: validators.get(key).disabled }, validators.get(key).validators);
      form.addControl(key, control);
    }
    return form;
  }

  getPrestationForm(entity: PrestationModel, validators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, value] of validators.entries()) {
      // tslint:disable-next-line: max-line-length
      const control = new UntypedFormControl({ value: entity[key], disabled: validators.get(key).disabled }, validators.get(key).validators);
      form.addControl(key, control);
    }
    const offres = new UntypedFormArray([]);
    if(entity.offres.length > 0) {
      entity.offres.forEach(offre => {
        const offreForm = this.getEditOffreFormGroup(offre);
        offres.push(offreForm);
      })
    }
    form.addControl('offres', offres);
    return form;
  }

  getCreateOffreFormGroup(): UntypedFormGroup {
    const offre = new OffreModel('', '#ffffff');
    const form = this.getForm(offre, OFFREVALIDATORS.CREATE);
    return form;
  }

  getEditOffreFormGroup(offre: OffreModel): UntypedFormGroup {
    const form = this.getForm(offre, OFFREVALIDATORS.EDIT);
    return form;
  }

  getCreatePrestationFormGroup(): UntypedFormGroup {
    const prestation = new PrestationModel();
    const form = this.getPrestationForm(prestation, PRESTATIONVALIDATORS.CREATE);
    return form;
  }

  getEditPrestationFormGroup(prestation: PrestationModel): UntypedFormGroup {
    const form = this.getPrestationForm(prestation, PRESTATIONVALIDATORS.EDIT);
    return form;
  }

    
}