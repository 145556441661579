import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TierBasicService } from './basic-service/tier-basic.service';
import { Injectable } from '@angular/core';
import { VerifyFields } from '../shared/verifyFields';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SiteGeoModel } from '../shared/models/site-geo.model';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionService } from '../../shared/session.service';
import { SimpleQuery } from '../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { SiteGeoPgResult } from '../shared/dtos/siteGeoPgResult';
@Injectable()

export class SiteGeoService extends TierBasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService,
    private IntercomToastrService: IntercomToastrService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTESITEGEO;
    }

    post(objet: SiteGeoModel): Observable<SiteGeoModel> {
      if (!this.beforeSave(objet)) {
        return throwError(new Error('sauvegarde impossible'));
      }
      objet.agenceId = this.sessionService.currentUserValue.agenceId;
      return this.http.post<SiteGeoModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
    }

    post2(objet: SiteGeoModel): Observable<SiteGeoModel> {
      if (!this.beforeSave(objet)) {
        return throwError(new Error('sauvegarde impossible'));
      }
      return this.http.post<SiteGeoModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
    }

    postAllFromProtocole(objets: SiteGeoModel[]): Observable<SiteGeoModel[]> {
      objets.forEach(objet => {
        objet.agenceId = this.sessionService.currentUserValue.agenceId;
      });
      return this.http.post<SiteGeoModel[]>(this.apiUrl + this.serviceUrl + 'PostAllFromProtocole/', objets);
    }

    updateAllFromProtocole(objets: SiteGeoModel[]): Observable<SiteGeoModel[]> {
      objets.forEach(objet => {
        if (!objet.agenceId) {
          objet.agenceId = this.sessionService.currentUserValue.agenceId;
        }
      });
      return this.http.put<SiteGeoModel[]>(this.apiUrl + this.serviceUrl + 'UpdateAllFromProtocole/', objets);
    }

    addOrUpdated(objet: SiteGeoModel): Observable<SiteGeoModel> {
      if (!this.beforeSave(objet)) {
        return throwError(new Error('sauvegarde impossible'));
      }
      objet.agenceId = this.sessionService.currentUserValue.agenceId;
      return this.http.post<SiteGeoModel>(this.apiUrl + this.serviceUrl + 'AddOrUpdated/', objet);
    }


    beforeSave(objet: SiteGeoModel): boolean {
      let success = true;
      if (!VerifyFields.verifyEmail(objet.email)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un email correct.');
      }
      /*if (!VerifyFields.verifyStringExist(objet.adresse)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir une adresse correcte.');
      }*/
      if (!VerifyFields.verifyCodePostal(objet.codePostal)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un code postal correct.');
      }
      if (!VerifyFields.verifyVille(objet.ville)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir une ville correcte.');
      }
      if (!super.beforeSave(objet)) {
        success = false;
      }
      return success;
    }

    getFromObjetWithPagination(objet: SimpleQuery<SiteGeoModel>): Observable<SimpleQueryResponse<SiteGeoModel>> {
      return this.http.post<SimpleQueryResponse<SiteGeoModel>>(this.apiUrl + this.serviceUrl + 'GetFromObjetWithPagination/', objet).pipe(tap((query: SimpleQueryResponse<SiteGeoModel>) => {
        for (const tier of query.objets) {
        }
      }));
    }

    GetFromObjetPgWithPagination(objet: SimpleQuery<SiteGeoModel>): Observable<SimpleQueryResponse<SiteGeoPgResult>> {
      return this.http.post<SimpleQueryResponse<SiteGeoPgResult>>(this.apiUrl + this.serviceUrl + 'GetFromObjetPgWithPagination/', objet).pipe(tap((query: SimpleQueryResponse<SiteGeoPgResult>) => {
        for (const tier of query.objets) {
        }
      }));
    }
}
