import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TierBasicService } from './basic-service/tier-basic.service';
import { Injectable } from '@angular/core';
import { InterlocuteurModel } from '../shared/models/interlocuteur.model';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { VerifyFields } from '../shared/verifyFields';
import { Observable, throwError } from 'rxjs';
import { SessionService } from '../../shared/session.service';
import { SimpleQueryResponse } from '../shared/simple_query/simpleQueryResponse';
import { SimpleQuery } from '../shared/simple_query/simpleQuery';
import { tap } from 'rxjs/operators';
import { InterlocuteurDTO } from '../shared/dtos/interlocuteurDTO';
import { ContactJasonDTO } from '../shared/dtos/contactJasonDTO';
import { InterlocuteurPgResult } from '../shared/dtos/interlocuteurPgResult';
@Injectable()

export class InterlocuteurService extends TierBasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService,
    private IntercomToastrService: IntercomToastrService
    ) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEINTERLOCUTEUR;
    }

    sendContactToJason(objet: ContactJasonDTO): Observable<any> {
      return this.http.post<any>(this.apiUrl + this.apiService.ROUTECONTACTJASON + 'SendContactToJason/', objet);
    }

    post(objet: InterlocuteurModel): Observable<InterlocuteurModel> {
      if (!this.beforeSave(objet)) {
        return throwError(new Error('sauvegarde impossible'));
      }
      objet.agenceId = this.sessionService.currentUserValue.agenceId
      return this.http.post<InterlocuteurModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
    }

    post2(objet: InterlocuteurModel): Observable<InterlocuteurModel> {
      if (!this.beforeSave(objet)) {
        return throwError(new Error('sauvegarde impossible'));
      }
      return this.http.post<InterlocuteurModel>(this.apiUrl + this.serviceUrl + 'Post/', objet);
    }

    beforeSave(objet: InterlocuteurModel): boolean {
      let success = true;
      if (!VerifyFields.verifyStringExist(objet.email) && !VerifyFields.verifyStringExist(objet.telPrincipal)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un email ou un numéro de téléphone.');
      }
      if (!VerifyFields.verifyName(objet.prenom)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un prenom correct.');
      }
      if (!VerifyFields.verifyName(objet.nom)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un nom correct.');
      }
      if (!VerifyFields.verifyEmail(objet.email)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un email correct.');
      }
      if (!VerifyFields.verifyStringExist(objet.nom)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un nom.');
      }
      if (!VerifyFields.verifyStringExist(objet.role)) {
        success = false;
        this.IntercomToastrService.warning('Veuillez saisir un rôle.');
      }
      if (!super.beforeSave(objet)) {
        success = false;
      }
      return success;
    }

    GetFromObjetPgWithPagination(objet: SimpleQuery<InterlocuteurDTO>): Observable<SimpleQueryResponse<InterlocuteurPgResult>> {
      return this.http.post<SimpleQueryResponse<InterlocuteurPgResult>>(this.apiUrl + this.serviceUrl + 'GetFromObjetPgWithPagination/', objet).pipe(tap((query: SimpleQueryResponse<InterlocuteurPgResult>) => {
        for (const tier of query.objets) {
        }
      }));
    }
  }
