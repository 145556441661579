import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from './basic-service/basic.service';
import { SessionService } from '../../shared/session.service';
@Injectable()

export class CiviliteService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTECIVILITE;
    }
}
