import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(required: boolean, validator: Validators) {
    this.disabled = required;
    this.validators = validator;
  }
}


export const OFFREVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
      ['id', new FormInfo(false, [])],
      ['libelle', new FormInfo(false, [Validators.required])],
      ['pu', new FormInfo(false, [Validators.required])],     
      ['idPrestation', new FormInfo(false, [])],     
      ['color', new FormInfo(false, [])],
      ['idAgence', new FormInfo(false, [Validators.required])],
      ['type',new FormInfo(false, [])],
      ['coefficient',new FormInfo(false, [Validators.required])]
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['libelle', new FormInfo(false, [Validators.required])],
    ['pu', new FormInfo(false, [Validators.required])],     
    ['idPrestation', new FormInfo(false, [])],     
    ['color', new FormInfo(false, [])],
    ['idAgence', new FormInfo(false, [Validators.required])],
    ['type',new FormInfo(false, [])],
    ['coefficient',new FormInfo(false, [Validators.required])]
  ])
}

export const ZONEVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
      ['libelle', new FormInfo(false, [Validators.required])],
      ['majoration', new FormInfo(false, [Validators.required])],     
  ])
}

export const TVAVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
      ['libelle', new FormInfo(false, [Validators.required])],
      ['taux', new FormInfo(false, [Validators.required])],     
  ])
}