import { Component, OnChanges, Output, EventEmitter, Input, Injector  } from '@angular/core';
import { DemandeService } from '../../../services/demande.service';
import { Router } from '@angular/router';
import { TrieTabService } from '../../../services/trie-tab.service';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { TierService } from '../../../services/tier/tier.service';
import { DemandeModel } from '../../../shared/models/demande.model';
import { TierModel } from '../../../shared/models/tier/tier.model';
import { SimpleQuery } from '../../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../../shared/simple_query/simpleQueryResponse';
import { StatusList } from '../../../shared/statusList';
import { Clipboard } from '@angular/cdk/clipboard';


export interface DialogData {

  result: boolean;
}
@Component({
  selector: 'app-clos-demande',
  templateUrl: './clos-demande.component.html',
  styleUrls: ['./clos-demande.component.scss']
})
export class ClosDemandeComponent implements OnChanges {
  @Output() onUpdated = new EventEmitter<boolean>();
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize = 10;
  length: number;
  selectedDemande: DemandeModel;
  demandes: DemandeModel[] = [];
  matDataSource: MatTableDataSource<DemandeModel>;
  @Input() tier: TierModel;
  result: boolean;
  displayedColumns: string[] = ['Urgence', 'NumDemande', 'DateCreation','DateIntervention', 'TypeDemande', 'Statut', 'Tier', 'Adresse', 'CodePostalAdresse', 'VilleAdresse', 'Commercial', 'Createur','actions'];
  dataSource: DemandeModel[] = [];
  sortState: Sort = {active: 'dateIntervention', direction: 'desc'};
  private modalsService: ModalsService;

  constructor(private router: Router
    , public dialog: MatDialog
    , private demandeService: DemandeService,
    private comp: TrieTabService,
    injector: Injector,
    private clipboard: Clipboard,
    private tierService: TierService) {
      this.modalsService = injector.get('ModalsService');
    }

  ngOnChanges() {
    if (this.tier?.id) {
      this.loadPage();
    }
  }  
  
  copyclip(res: string, event: Event) {
    event.stopPropagation();
    this.clipboard.copy(res);
  }


  onOpenDemande(demande: DemandeModel): void {
    this.demandeService.get(demande.id).subscribe(demandeResult => {
      this.modalsService.openDemande(demandeResult).subscribe(result => {
        if (result) {
          this.ngOnChanges();
          this.onUpdated.emit(true);
        }
      });
    });
  }

  changePage(event?: PageEvent) {
    this.pageIndex =  event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadPage();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.loadPage();
  }

  loadPage() {
    const query = new SimpleQuery<DemandeModel>();
    query.entry = new DemandeModel();
    query.entry.statut = StatusList.CLOS;
    query.entry.objet = 'Clos';
    query.pageNumber = this.pageIndex;
    query.pageSize = this.pageSize;
    query.order = this.sortState.active.charAt(0).toUpperCase() + this.sortState.active.slice(1);
    query.descending = this.sortState.direction === 'desc' ? false : true;
    this.demandeService.getFromTierQuery(this.tier.id, query).subscribe((response: SimpleQueryResponse<DemandeModel>) => {
      this.dataSource = response.objets;
      const data = this.dataSource.slice();
      this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
      this.length = this.dataSource.length;
      this.length = response.count;
      this.pageIndex = response.pageNumber;
      this.pageSize = response.pageSize;
    });
  }
}
