import { BasicMock } from './basic-mock/basic.mock';
import { TypeActionModel } from '../models/type-action.model';
import { TierModel } from '../models/tier/tier.model';
import { UserModel } from '../models/users/user.model';
import { ActionModel } from '../models/action.model';
import { TierBasicMock } from './basic-mock/tier-basic.mock';
import { Injectable } from '@angular/core';
@Injectable()
export class ActionMock extends TierBasicMock {

  constructor() {
    super();
    (<ActionModel[]> this.objets) = [
         {
            id: 1, objetAction: 'Demande d\'info séparateurs',
            commentaire: '', typeAction: null, typeActionId: 1,
            dateCreation: new Date('2020, 04, 27'), dateRealisation: new Date('2020, 04, 27'), dateFin: null,
            realisateur: null, realisateurId: 1, tierId: 1, heureDebut: null, duree: null,
            createurId: 1, createur: null, statut: 'Clos', demandeId: null, demande: null,
            interlocuteurId: null, nomInterlocuteur: '', prenomInterlocuteur: '' , roleInterlocuteur: '',
            civiliteInterlocuteur : '', emailInterlocuteur : '', telSecondaireInterlocuteur: '', telPrincipalInterlocuteur : '',
            dateCloture: null,
            commentaireInterlocuteur : ''
          }
    ];
  }


  getFromObjet(objetRecherche: ActionModel): ActionModel[] {
    return Object.assign([], this.objets.filter((objTab: ActionModel) => this.sameAction(objTab, objetRecherche)));
  }

  getfromUserAll(userId: number): ActionModel[] {
    return Object.assign([], this.objets.filter((value: ActionModel) => value.realisateurId === userId));
  }

  getfromUserActive(userId: number): ActionModel[] {
    return Object.assign([], this.objets.filter((value: ActionModel) => value.realisateurId === userId  && value.statut == 'En cours'));
  }

  sameAction(objTab: ActionModel, objRecherche: ActionModel): boolean{
    let same: boolean;
    let dr: Date;
    let dt: Date;
    if (objRecherche.dateRealisation !== undefined && objRecherche.dateRealisation !== null) {
      dr = objRecherche.dateRealisation;
      dr.setHours(0, 0, 0, 0);
      dt = objTab.dateRealisation;
      dt.setHours(0, 0, 0, 0);
    }
    if (objRecherche.id !== undefined && objRecherche.id !== 0 && objTab.id !== objRecherche.id) {
      return false;
    }
    else if (objRecherche.statut !== undefined &&
      objRecherche.statut.length !== 0 && !(objTab.statut.localeCompare(objRecherche.statut) === 0)) {
      return false;
    }
    else if (objRecherche.realisateurId !== undefined &&
      objRecherche.realisateurId !== 0 && objTab.realisateurId === objRecherche.realisateurId) {
      return false;
    }
    else if (objRecherche.dateRealisation !== undefined &&
       objRecherche.dateRealisation !== null && dt < dr) {
      return false;
    }
    else if (objRecherche.typeActionId !== undefined &&
       objRecherche.typeActionId !== 0 && objTab.typeActionId === objRecherche.typeActionId) {
      return false;
    }
    else if (objRecherche.objetAction !== undefined &&
       objRecherche.objetAction.length !== 0 && objTab.objetAction.toLowerCase().indexOf(objRecherche.objetAction.toLowerCase()) === -1) {
      return false;
    }
    else if (objRecherche.commentaire !== undefined &&
       objRecherche.commentaire.length !== 0 && objTab.commentaire.toLowerCase().indexOf(objRecherche.commentaire.toLowerCase()) === -1) {
      return false;
    }
    else if (objRecherche.statut !== undefined &&
       objRecherche.statut.length !== 0 && objTab.statut.toLowerCase().indexOf(objRecherche.statut.toLowerCase()) === -1) {
      return false;
    }
    return true;
  }
}
