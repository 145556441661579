<mat-form-field class="full-width">
    <mat-label>{{libelle}}</mat-label>
      <input type= "text"
      matInput
      #codePostal
      maxlength="5"
      [required]="required"
      placeholder="Selectionner le code postal"
      (change)="add($event.target.value)"
      [formControl]="myControl"
      [matAutocomplete]="auto"
      >
    <mat-autocomplete 
    autoActiveFirstOption 
    #auto="matAutocomplete" 
    (optionSelected)="cityChange($event)"
    [displayWith]="displayOnlyCode"
    >
      <mat-option *ngFor="let city of filteredCities | async" [value]="city" [matTooltip]="city" (mousedown)="$event.preventDefault()"
      matTooltipPosition="after"> {{city}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
