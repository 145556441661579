import { Component, OnChanges, Output, EventEmitter, Input, Injector  } from '@angular/core';
import { StatusList } from '../../../shared/statusList';
import { DemandeService } from '../../../services/demande.service';
import { Router } from '@angular/router';
import { TrieTabService } from '../../../services/trie-tab.service';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DemandeModel } from '../../../shared/models/demande.model';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { DemandeDTO } from '../../../shared/dtos/demandeDTO';
import { TierModel } from '../../../shared/models/tier/tier.model';
import { SimpleQuery } from '../../../shared/simple_query/simpleQuery';
import { SimpleQueryResponse } from '../../../shared/simple_query/simpleQueryResponse';
import { Clipboard } from '@angular/cdk/clipboard';


export interface DialogData {
  result: boolean;
}

@Component({
  selector: 'app-demande-array',
  templateUrl: './demande-array.component.html',
  styleUrls: ['./demande-array.component.scss']
})
export class DemandeArrayComponent implements OnChanges {
  @Output() onUpdated = new EventEmitter<boolean>();
  pageEvent: PageEvent;
  pageIndex = 0;
  pageSize = 10;
  length: number;
  selectedDemande: DemandeModel;
  demandes: DemandeModel[] = [];
  matDataSource: MatTableDataSource<DemandeModel>;
  @Input() tier: TierModel;
  result: boolean;
  displayedColumns: string[] = ['Urgence', 'NumDemande', 'DateCreation','DateIntervention', 'TypeDemande', 'Statut', 'Tier', 'Adresse', 'CodePostalAdresse', 'VilleAdresse', 'Commercial', 'Createur','actions'];
  dataSource: DemandeModel[] = [];
  sortState: Sort = {active: 'dateInterv', direction: 'desc'};
  private modalsService: ModalsService;
  demandeDTO: DemandeDTO = new DemandeDTO();

  statusList = StatusList.status;

  constructor(private router: Router,
    public dialog: MatDialog,
    private demandeService: DemandeService,
    private comp: TrieTabService,
    private clipboard: Clipboard,
    injector: Injector) {
      this.modalsService = injector.get('ModalsService');
    }

  ngOnChanges() {
    if (this.tier?.id) {
      this.demandeDTO.tierId = this.tier.id;
      this.demandeDTO.nonClos = true;
      this.onLoadDemandes();
    }
  }

  onLoadDemandes() {
    const query = new SimpleQuery<DemandeDTO>();
    query.entry = this.demandeDTO;
    query.pageNumber = this.pageIndex;
    query.pageSize = this.pageSize;
    query.order = this.sortState.active.charAt(0).toUpperCase() + this.sortState.active.slice(1);
    query.descending = this.sortState.direction === 'desc' ? false : true;
    this.demandeService.getFromObjet(query).subscribe((response: SimpleQueryResponse<DemandeModel>) => {
      this.dataSource = response.objets;
      const data = this.dataSource.slice();
      this.matDataSource = new MatTableDataSource(data.slice(0, this.pageSize));
      this.length = response.count;
      this.pageIndex = response.pageNumber;
      this.pageSize = response.pageSize;
    });
  }
  copyclip(res: string, event: Event) {
    event.stopPropagation();
    this.clipboard.copy(res);
  }

  onOpenDemande(demande: DemandeModel): void {
    // tslint:disable-next-line: no-shadowed-variable
    this.demandeService.get(demande.id).subscribe(demande => {
      this.modalsService.openDemande(demande).subscribe(result => {
        if (result) {
          this.ngOnChanges();
          this.onUpdated.emit(true);
        }
      });
    });
  }

  changePage(event?: PageEvent) {
    this.pageIndex =  event.pageIndex;
    this.pageSize = event.pageSize;
    this.onLoadDemandes();
  }

  trieTab(sort: Sort) {
    this.sortState = sort;
    this.onLoadDemandes();
  }
}
