import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AgenceModel } from '../../../shared/models/rsa/agence.model';
import { ActionModel } from '../../../shared/models/action.model';

export interface DialogData {
  icone: string;
  title: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  result: boolean;
  
  agence: AgenceModel;
  prestation: ActionModel;
}

@Component({
  selector: 'app-modal-info-ok',
  templateUrl: './modal-info-ok.component.html'
})
export class ModalInfoOkComponent implements OnInit {

  icone: string;
  title: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  import = false;
  url = '';
  constructor(public dialog: MatDialog,
    private toastr: IntercomToastrService,
    public router: Router,
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    if (this.data.title && this.data.title.includes('Importation')) {
      this.import = true;
    }
    this.icone = this.data.icone !== undefined ? this.data.icone : '';
    this.title = this.data.title !== undefined ? this.data.title : '';
    this.icon_color = this.data.icon_color !== undefined ? this.data.icon_color : '';
    this.dialogWidth = this.data.dialogWidth !== undefined ? this.data.dialogWidth : '';
    this.dialogHeight = this.data.dialogHeight !== undefined ? this.data.dialogHeight : '';
    this.message = this.data.message;
    this.color = this.data.color;
    if (this.dialogWidth !== '' && this.dialogHeight !== '') {
      this.dialogRef.updateSize(this.dialogWidth, this.dialogHeight);
    }
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
    if (this.import) {
      if (this.url && this.url !== '') {
        this.data.result = true;
        this.dialogRef.close(this.url);
      } else {
        this.toastr.warning('Veuillez saisir une URL correcte');
      }
    } else {
      this.data.result = true;
      this.dialogRef.close(true);
    }
  }
}
