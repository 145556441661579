
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AgendaComponent } from './modules/core/components/agenda/agenda.component';
import { AuthentificationComponent } from './modules/core/components/authentification/authentification.component';
import { FicheContactComponent } from './modules/core/components/fiche-contact/fiche-contact.component';
import { FourOhFourComponent } from './modules/core/components/four-oh-four/four-oh-four.component';
import { GestionPrestationComponent } from './modules/core/components/gestion-prestation/gestion-prestation.component';
import { RecordActiviteComponent } from './modules/core/components/gestion-prestation/record-activite/record-activite.component';
import { FicheUtilisateurDroitComponent } from './modules/core/components/gestion-utilisateur/fiche-utilisateur-droit/fiche-utilisateur-droit.component';
import { GestionUtilisateurComponent } from './modules/core/components/gestion-utilisateur/gestion-utilisateur.component';
import { SearchActionComponent } from './modules/core/components/menu/search/search-action/search-action.component';
import { SearchDemandeComponent } from './modules/core/components/menu/search/search-demande/search-demande.component';
import { SearchTiersComponent } from './modules/core/components/menu/search/search-tiers/search-tiers.component';
import { ParametreAgenceComponent } from './modules/core/components/parametre-agence/parametre-agence.component';
import { ParametreUtilisateurComponent } from './modules/core/components/parametre-utilisateur/parametre-utilisateur.component';
import { ParametreComponent } from './modules/core/components/parametre/parametre.component';
import { PrincipalComponent } from './modules/core/components/principal/principal.component';
import { PriseAppelComponent } from './modules/core/components/prise-appel/prise-appel.component';
import { ProtocoleAgenceComponent } from './modules/core/components/protocole-agence/protocole-agence.component';
import { SearchDevisComponent } from './modules/core/components/menu/search/search-devis/search-devis.component';
import { PageNoDroitComponent } from './utils/pages/page-no-droit/page-no-droit.component';
import { GestionZonesComponent } from './modules/core/components/gestion-zones/gestion-zones.component';
import { MaintenanceComponent } from './modules/core/components/maintenance/maintenance.component';


const routes: Routes = [
  { path: '', component: PrincipalComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'},
  { path: 'fiche-contact/:id', component: FicheContactComponent, canActivate: [AuthGuard] },
  { path: 'parametre', component: ParametreComponent, canActivate: [AuthGuard]},
  { path: 'parametres-utilisateur', component: ParametreUtilisateurComponent, canActivate: [AuthGuard]},
  { path: 'protocole-agence/:id', component: ProtocoleAgenceComponent, canActivate: [AuthGuard]},
  { path: 'parametres-agence', component: ParametreAgenceComponent, canActivate: [AuthGuard]},
  { path: 'not-found', component: FourOhFourComponent},
  { path: 'login', component: AuthentificationComponent},
  { path: 'manager-user', component: GestionUtilisateurComponent, canActivate: [AuthGuard]},
  { path: 'fiche-user-droit/:id', component: FicheUtilisateurDroitComponent, canActivate: [AuthGuard]},
  { path: 'prise-appel', component: PriseAppelComponent, canActivate: [AuthGuard]},
  { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard]},
  { path: 'tiers', component: SearchTiersComponent, canActivate: [AuthGuard]},
  { path: 'demande', component: SearchDemandeComponent, canActivate: [AuthGuard]},
  { path: 'demande/:id', component: SearchDemandeComponent, canActivate: [AuthGuard]},
  { path: 'action', component: SearchActionComponent, canActivate: [AuthGuard]},
  { path: 'activite-agence/:id', component: GestionPrestationComponent, canActivate: [AuthGuard]},
  { path: 'prestation-agence', component: RecordActiviteComponent, canActivate: [AuthGuard]},
  { path: 'zones-agence/:id', component: GestionZonesComponent, canActivate: [AuthGuard]},
  { path: 'devis', component: SearchDevisComponent, canActivate: [AuthGuard]},
  { path: 'page-error', component: PageNoDroitComponent},
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '**', redirectTo: 'not-found' }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
