
import { EntiteSARPNode } from '../../shared/models/rsa/entite-sarp.model';
import { RegionModel } from '../../shared/models/rsa/region.model';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from '../basic-service/basic.service';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/modules/shared/session.service';

@Injectable()

export class RegionService extends BasicService {

  constructor(http: HttpClient,
    apiService: ApiService,
    sessionService: SessionService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEREGION;
    }

  getAllManaged(niveau: number): Observable<RegionModel[]> {
    return this.http.get<RegionModel[]>(this.apiUrl + this.serviceUrl + 'GetAllManaged/' + niveau, {headers: this.header});
  }

  constructEntitySarpTree(regions: RegionModel[]): EntiteSARPNode[] {
    const tree: EntiteSARPNode[] = [];
    let nodeRegion: EntiteSARPNode = null;
    let nodeSociete: EntiteSARPNode = null;
    let nodeAgence: EntiteSARPNode = null;
    for (const region of regions) {
      nodeRegion = new EntiteSARPNode();
      nodeRegion.libelle = region.libelle;
      nodeRegion.id = region.id;
      nodeRegion.childrens = [];
      for (const societe of region.societes) {
        nodeSociete = new EntiteSARPNode();
        nodeSociete.libelle = societe.libelle;
        nodeSociete.id = societe.id;
        nodeSociete.childrens = [];
        for (const agence of societe.agences) {
          nodeAgence = new EntiteSARPNode();
          nodeAgence.libelle = agence.libelle;
          nodeAgence.id = agence.id;
          nodeSociete.childrens.push(nodeAgence);
        }
        nodeRegion.childrens.push(nodeSociete);
      }
      tree.push(nodeRegion);
    }
    return tree;
  }
  constructSmallRegionTree(regions: RegionModel[]): EntiteSARPNode[] {
    const tree: EntiteSARPNode[] = [];
    let nodeRegion: EntiteSARPNode = null;
    for (const region of regions) {
      nodeRegion = new EntiteSARPNode();
      nodeRegion.libelle = region.libelle;
      nodeRegion.id = region.id;
      nodeRegion.childrens = [];
      tree.push(nodeRegion);
    }
    return tree;
  }
}
