<div class="col-sm-12">
  <h3>Création d'un nouveau utilisateur</h3>
</div>
<div class="row">
  <div class="col-sm-12 text-center">
    <div class="col-sm-6">
      <mat-form-field class="full-width">
        <mat-label>Nom</mat-label>
        <input matInput placeholder="Ex: Dupont" [(ngModel)]="user.nom" [formControl]="nom" required>
        <mat-error *ngIf="nom.invalid">{{getNomErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field class="full-width">
        <mat-label>Prenom</mat-label>
        <input matInput placeholder="Ex: Dupont" [(ngModel)]="user.prenom" [formControl]="nom" required>
        <mat-error *ngIf="nom.invalid">{{getPrenomErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6">
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Ex: Fred.dupont@veolia.com" [(ngModel)]="user.email" [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    
    <div class="col-sm-6">
        <app-input-telephone [(phoneNumber)]="user.tel" libelle="Téléphone"></app-input-telephone>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="col-sm-6">
      <app-autocomplete-agence [required]="true" [libelle]="'Agence de l\'utilisateur'" [agences]="agences" [agenceId]="user.agenceId" (agenceChange)="selectedAgenceChanged($event)" ></app-autocomplete-agence>
    </div>
    <div class="col-sm-6">
      <mat-form-field class="full-width">
        <mat-label>Type d'utilisateur</mat-label>
        <mat-select
          (selectionChange)="onUserChange($event)"
          [ngModel]="type">
          <mat-option
            *ngFor="let type of types"
            [value]="type.id">
              {{type.libelle}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="col-sm-6 text-center">
      <button mat-flat-button color="default" (click)="closeDialog(0)">Annuler</button>
    </div>
    <div class="col-sm-6 text-center">
      <button mat-flat-button color="success" (click)="create_user()">Enregistrer</button>
    </div>
  </div>
</div>
