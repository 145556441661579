import { BasicMock } from '../basic-mock/basic.mock';
import { RegionModel } from '../../models/rsa/region.model';
import { Injectable } from "@angular/core";

@Injectable()
export class RegionMock extends BasicMock  {
  constructor() {
    super();
    (<RegionModel[]> this.objets) = [];
  }
}
