import { TierModel } from './tier.model';

export class EntrepriseModel extends TierModel {
  public raisonSociale = '';
  public siretSiren = '';
  public marqueEnseigne = '';
  public famille = '';
  public codeNaf = '';
  public typeTier = 'Entreprise';

  constructor(tiers?: TierModel) {
    super(tiers);
    if (tiers) {
      this.raisonSociale = tiers.libelle;
    }
  }
}

