import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LigneClient } from '../../models/table-prestation-client.model';
import { DevisInfo } from 'src/app/modules/shared/devisInfo';

@Component({
  selector: 'app-estimation-devis',
  templateUrl: './estimation-devis.component.html',
  styleUrls: ['./estimation-devis.component.scss']
})
export class EstimationDevisComponent implements OnInit, OnChanges {
  @Input() devisInfo: DevisInfo;
  ttcMax: number = 0;
  htMax: number = 0;
  ttcMin: number = 0;
  htMin: number = 0;
  prixMoyen: number = 0;
  calcule = false;


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.devisInfo){
      this.calculPrixMax();
      this.calculPrixMin();
      this.calculPrixMoyen();
      this.calcule = true;
    }

  }

  calculPrixMax(): void {
    let devis = this.devisInfo;
    let prestations = this.devisInfo.prestations;
    let majoration = 1;
    let prix = 0;
    let coefficient = 1;
    let tva = (devis.tva || devis.tva === 0) ? devis.tva : 20;

    if(devis.zone) {
      majoration = devis.zone.majoration ? devis.zone.majoration : devis.zoneMax.majoration;
    }else {
      majoration = devis.zoneMax ? devis.zoneMax.majoration : 1;
    }

    for (let i = 0; i < prestations.length; i++) {
      const offre = prestations[i].offre ? prestations[i].offre : (devis.prestationsMax[i].offre);
      const ligne = new LigneClient();
      ligne.libelle = prestations[i].prestation.libelle + ': ' + offre.libelle;
      ligne.prix = offre.type === 'Multiplicatif' ? offre.coefficient : offre.pu;
      ligne.unite = offre.type;
      prix += offre.type !== 'Multiplicatif'? offre.pu : 0;
      coefficient *= offre.type === 'Multiplicatif' ? offre.coefficient: 1;
    }
    let multiplicatif = coefficient * majoration;
    let ht = prix * multiplicatif;
    this.htMax = Math.round(ht * 100) / 100;
    this.ttcMax = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;
  }

  calculPrixMin(): void {
    let devis = this.devisInfo;
    let prestations = this.devisInfo.prestations;
    let majoration = 1;
    let prix = 0;
    let coefficient = 1;
    let tva = (devis.tva || devis.tva === 0) ? devis.tva : 0;


    if(devis.zone) {
      majoration = devis.zone.majoration ? devis.zone.majoration : devis.zoneMin.majoration;
    }else {
      majoration = devis.zoneMin ? devis.zoneMin.majoration : 1;
    }

    for (let i = 0; i < prestations.length; i++) {
      const offre = prestations[i].offre ? prestations[i].offre : (devis.prestationsMin[i].offre);
      const ligne = new LigneClient();
      ligne.libelle = prestations[i].prestation.libelle + ': ' + offre.libelle;
      ligne.prix = offre.type === 'Multiplicatif' ? offre.coefficient : offre.pu;
      ligne.unite = offre.type;
      prix += offre.type !== 'Multiplicatif'? offre.pu : 0;
      coefficient *= offre.type === 'Multiplicatif' ? offre.coefficient: 1;
    }
    let multiplicatif = coefficient * majoration;
    let ht = prix * multiplicatif;
    this.htMin = Math.round(ht * 100) / 100;
    this.ttcMin = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;
  }

  calculPrixMoyen(): void {
    let devis = this.devisInfo;
    let prestations = this.devisInfo.prestations;
    let majoration = 1;
    let prix = 0;
    let coefficient = 1;
    let tva = (devis.tva || devis.tva === 0) ? devis.tva : 20;


    if(devis.zone) {
      majoration = devis.zone.majoration ;
    }else{
      majoration = devis.zoneMax && devis.zoneMin ? ((devis.zoneMax.majoration + devis.zoneMin.majoration) / 2) : 1;
    }

    for (let i = 0; i < prestations.length; i++) {
      if(prestations[i].offre){
        const offre = prestations[i].offre;
        const ligne = new LigneClient();
        ligne.libelle = prestations[i].prestation.libelle + ': ' + offre.libelle;
        ligne.prix = offre.type === 'Multiplicatif' ? offre.coefficient : offre.pu;
        ligne.unite = offre.type;
        prix += offre.type !== 'Multiplicatif'? offre.pu : 0;
        coefficient *= offre.type === 'Multiplicatif' ? offre.coefficient: 1;
      }else{
        prix += devis.prestationsMin[i].offre.pu + devis.prestationsMax[i].offre.pu / 2
      }
    }
    let multiplicatif = coefficient * majoration;
    let ht = prix * multiplicatif;
    ht = Math.round(ht * 100) / 100;
    this.prixMoyen = Math.round((ht + ((ht * tva) / 100)) * 100) / 100;
  }

}
