import { ApiService } from './../api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TierBasicModel } from './../../shared/models/basic-models/tier-basic.model';
import { BasicService } from './basic.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/modules/shared/session.service';
import { SimpleQueryResponse } from '../../shared/simple_query/simpleQueryResponse';

@Injectable()
export class TierBasicService extends BasicService {

  constructor(protected http: HttpClient,
    protected apiService: ApiService,
    protected sessionService: SessionService) {
      super(http, apiService, sessionService);

      this.apiUrl = apiService.URLAPI;
      this.header = new HttpHeaders({
        'Authorization': 'Bearer ' + this.sessionService.tokenValue
      });
  }
  /****************************************************************************************************************/
  /*                                  SERVICE                                                                    */
  /****************************************************************************************************************/

  getFromTier(tierId: number): Observable<TierBasicModel[]> {
    return this.http.get<TierBasicModel[]>(this.apiUrl + this.serviceUrl + 'GetFromTier/' + tierId);
  }

  getFromTierQuery(tierId: number, query): Observable<SimpleQueryResponse<TierBasicModel>> {
    return this.http.post<SimpleQueryResponse<TierBasicModel>>(this.apiUrl + this.serviceUrl + 'GetFromTierQuery/' + tierId, query);
  }
}
