import { Component, OnInit, Inject, Output, EventEmitter, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ActionComponent } from './action.component';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModalAjoutDocumentComponent } from '../document/modal-ajout-document/modal-ajout-document.component';
import { ModalsService } from 'src/app/modules/shared/modals.service';
import { ActionService } from '../../services/action.service';
import { CiviliteService } from '../../services/civilite.service';
import { DemandeService } from '../../services/demande.service';
import { DocumentService } from '../../services/document.service';
import { InterlocuteurService } from '../../services/interlocuteur.service';
import { TypeActionService } from '../../services/type-action.service';
import { UserService } from '../../services/user/user.service';
import { ActionModel } from '../../shared/models/action.model';
import { CiviliteModel } from '../../shared/models/civilite.model';
import { DemandeModel } from '../../shared/models/demande.model';
import { DocumentModel } from '../../shared/models/document/document.model';
import { InterlocuteurModel } from '../../shared/models/interlocuteur.model';
import { TypeActionModel } from '../../shared/models/type-action.model';
import { UserModel } from '../../shared/models/users/user.model';


export interface DialogData {
  action: ActionModel;
  result: boolean;
  demandeLib: string;
  // gestion des documents
  viewDocuments: boolean;
  tierId: number;
}

@Component({
  selector: 'app-modal-resume-action',
  templateUrl: './modal-resume-action.component.html',
  styleUrls: ['./modal-resume-action.component.scss']
})

export class ModalResumeActionComponent implements OnInit {
  @Output() onUpdated = new EventEmitter<boolean>();
  typeActions: TypeActionModel[] = [];
  typeAction: TypeActionModel;
  users: UserModel[]  = [];
  user: UserModel;
  demandes: DemandeModel[] = [];
  demande: DemandeModel;
  result: boolean;
  commercialId: number;
  hasHistorique = false;
  hasTiers = false;
  civilites: CiviliteModel[] = [];
  // documents
  documents: DocumentModel[] = [];
  document: DocumentModel;

  interlocuteurs: InterlocuteurModel[] = [];
  interlocuteur: InterlocuteurModel;
  interlocuteurId: number;

  private modalsService: ModalsService;

  constructor(private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private actionService: ActionService,
    private civiliteService: CiviliteService,
    private typeActionService: TypeActionService,
    private userService: UserService,
    private demandeService: DemandeService,
    private toastr: IntercomToastrService,
    injector: Injector,
    private documentService: DocumentService,
    private interlocuteurService: InterlocuteurService
    ) {
      this.modalsService = injector.get('ModalsService');
    }

  ngOnInit() {
    this.data.viewDocuments = true;
    this.data.result = false;
    this.typeActionService.getAll().subscribe((ta: TypeActionModel[]) => this.typeActions = ta);
    this.userService.getAll().subscribe((usrs: UserModel[]) => this.users = usrs);
    this.commercialId = this.data.action.realisateurId;
    this.user = this.data.action.realisateur;

    this.civiliteService.getAll().subscribe((civs: CiviliteModel[]) => {
      this.civilites = civs;
    });

    // get interlocuteur from action object
    if (this.data.action.interlocuteurId && this.data.action.nomInterlocuteur) {
      this.interlocuteur = new InterlocuteurModel();
      this.interlocuteur.id = this.data.action.interlocuteurId;
      this.interlocuteur.tierId = this.data.action.tierId;
      this.interlocuteur.nom = this.data.action.nomInterlocuteur;
      this.interlocuteur.prenom = this.data.action.prenomInterlocuteur;
      this.interlocuteur.role = this.data.action.roleInterlocuteur;
      this.interlocuteur.commentaire = this.data.action.commentaireInterlocuteur;
      this.interlocuteur.telPrincipal = this.data.action.telPrincipalInterlocuteur;
      this.interlocuteur.telSecondaire = this.data.action.telSecondaireInterlocuteur;
      this.interlocuteur.email = this.data.action.emailInterlocuteur;
      let civ: CiviliteModel;
      if (this.data.action.civiliteInterlocuteur) {
        civ = this.civilites.find(civilite =>
          civilite.libelle.toLowerCase() === this.data.action.civiliteInterlocuteur.toLowerCase()) ;
          this.interlocuteur.civilite = civ ? civ : null;
          this.interlocuteur.civiliteId = civ ? civ.id : null;
      } else {
        this.interlocuteur.civilite = null;
        this.interlocuteur.civiliteId = null;
      }
    } else {
      this.interlocuteur = undefined;
    }
    if (this.data.action.tierId) {
      this.interlocuteurService.getFromTier(this.data.action.tierId).subscribe((interloc: InterlocuteurModel[]) => {
        this.interlocuteurs = interloc;
      });
      this.hasTiers = true;
    }

    if (this.data.action.demandeId == null) {
      this.data.demandeLib = 'Aucun';
    } else {
      this.demandeService.get(this.data.action.demandeId).subscribe(dem => this.data.demandeLib = dem.objet);
    }
    if (this.data.action.statut === 'A traiter' && this.data.action.realisateurId === JSON.parse(localStorage.getItem('CURRENT_USER')).id) {
      this.data.action.statut = 'En cours';
      this.actionService.put(this.data.action).subscribe();
      this.data.result = true;
      this.actionService.put(this.data.action).subscribe();
      this.onUpdated.emit(true);
    }

    // Récupération de la demande associée automatiquement à l'action que l'on souhaite créer. (en passant par la demande)
    if (this.data.action.demandeId) {
      this.demandeService.get(this.data.action.demandeId).subscribe((dem: DemandeModel) => {
        this.demande = dem;
      });
    }
    this.dialogRef.backdropClick().subscribe(event => this.onModalMinimize());
    this.getDocumentsByAction();
  }

  typChange() {
    this.typeAction = Object.create(this.typeActions.find(ta => ta.id === this.typeAction.id));
    this.data.action.typeActionId = this.typeAction.id;
  }

  onUpdateRealisateurId(realisateurId: number) {
    if (realisateurId === null) {
      this.data.action.realisateurId = -1;
    } else {
      this.data.action.realisateurId = realisateurId;
      this.user = this.users.find(usr => usr.id === this.data.action.realisateurId);
      this.data.action.agenceId = this.user.agenceId;
    }
  }

  createurChange() {
    this.user = Object.create(this.users.find(usr => usr.id === this.user.id));
    this.data.action.createurId = this.user.id;
  }

  onModalCancel(): void {
    this.modalsService.closeAction(this.data.action);
    this.dialogRef.close(this.data.result);
  }

  hasTier() {
    return (this.data.action.tierId !== null && this.data.action.tierId !== undefined && this.data.action.tierId !== 0);
  }

  onTierOpen() {
    this.router.navigate(['/fiche-contact', this.data.action.tierId]);
    this.dialogRef.close(false);
  }

  /* Update demande if demande.dateintervention < action.daterealisation*/
  onUpdateDemande() {
    if (this.demande) {
      if (this.data.action.dateRealisation > this.demande.dateIntervention) {
        this.demande.dateIntervention = this.data.action.dateRealisation;
        this.data.action.demande = this.demande;
      }
      this.demandeService.put(this.demande).subscribe((dem: DemandeModel) => {
        this.data.action.demande = dem;
      });
    }
  }

  onUpdateAction() {
    this.actionService.put(this.data.action).subscribe((a: ActionModel) => {
      this.data.action = a;
      this.modalsService.closeAction(this.data.action);
      this.toastr.success('', 'La tâche : ' + this.data.action.objetAction + ' a bien été modifiée');
      this.dialogRef.close(a);
      if (this.router.url === '/') {
        this.router.navigate(['']);
      }
    });
  }

  onModalConfirm(): void {
    this.data.result = true;
    if (this.data.action.dateRealisation !== null) {
      this.data.action.dateRealisation.setHours(0, 0, 0, 0);
      // Controle heure + durée
      const regExH = RegExp('^[0-9]{1,2}[h][0-9]{0,2}$');
      const regExD = RegExp('^[0-9]{1,2}[h][0-9]{0,2}$');
      if (this.data.action.heureDebut != null && this.data.action.heureDebut !== '' && this.data.action.heureDebut !== undefined) {
        const sep: number = this.data.action.heureDebut.indexOf('h');
        const min: number =  +this.data.action.heureDebut.substring(0, sep) * 60 +
        +this.data.action.heureDebut.substring(sep + 1, this.data.action.heureDebut.length);
        this.data.action.dateRealisation = new Date(this.data.action.dateRealisation.getTime() + min * 60000);
      }
      if (this.data.action.duree == null || this.data.action.duree === '' || this.data.action.duree === undefined) {
        this.data.action.dateFin = null;
      } else {
        const h: number = this.data.action.duree.indexOf('h');
        let min: number;
        min = +this.data.action.duree.substring(0, h) * 60;
        if (h !== this.data.action.duree.length) {
          min += +this.data.action.duree.substring(h + 1, this.data.action.duree.length);
        }
        this.data.action.dateFin = new Date(this.data.action.dateRealisation.getTime() + min * 60000);
      }
      
      // Si tout OK => modification
      if (this.data.action.typeActionId !== null && this.data.action.dateRealisation !== null
        && this.data.action.realisateurId !== null && this.data.action.objetAction.localeCompare('') !== 0
        && (this.data.action.dateFin === null || (this.data.action.heureDebut != null && regExH.test(this.data.action.heureDebut))
        || this.data.action.heureDebut === '')
        && ((this.data.action.duree === null || this.data.action.duree === undefined)
        || ((this.data.action.heureDebut !== null && this.data.action.heureDebut !== '') &&
          this.data.action.duree !== null && regExD.test(this.data.action.duree)) || this.data.action.duree === '')) {
            if (this.data.action.realisateurId !== -1) {

              // tslint:disable-next-line: max-line-length
              this.saveAction();

              this.onUpdateDemande();

          } else {
            this.toastr.warning('Erreur saisie', 'La valeur du réalisateur saisie est incorrecte. Veuillez selectionner un réalisateur existant !');
          }
      } else {
        this.toastr.warning('le type, la date, le réalisateur et l\'objet sont obligatoires.',
          'Impossible de modifier la tâche');
      }
    } else {
      this.toastr.warning('le type, la date, le réalisateur et l\'objet sont obligatoires.',
      'Impossible de modifier la tâche');
    }
  }

  onOpenDemande(): void {
    this.demandeService.get(this.data.action.demandeId).subscribe((demande: DemandeModel) => {
      this.modalsService.openDemande(demande).subscribe(result => {
        this.result = result;
        this.onUpdated.emit(true);
      });
    });
  }

  onModalMinimize(): void {
    this.dialogRef.close(this.data.result);
  }

  /* Documents */

  getDocumentByID(document: DocumentModel) {
    this.document = document;
  }


  onOpenDocument(document: DocumentModel): void {

    this.document = Object.assign({}, document);

    this.modalsService.openDocument(this.document).subscribe(result => {
      this.result = result;
      this.onUpdated.emit(true);
      this.data.result = result;
      if (this.result) {
        this.actionService.get(this.data.action.id).subscribe((a: ActionModel) => {
          this.data.action = a;
        });
        this.getDocumentsByAction();
      }
    });
  }

  getDocumentsByAction() {
    this.documentService.getFromAction(this.data.action.id).subscribe((documents: DocumentModel[]) => {
      this.documents = documents;
      this.data.action.documents = documents;
    });
  }

  onModalListeDocument(): void {
    this.data.viewDocuments = !this.data.viewDocuments;
  }

  onAddDocument(): void {
    const dialogRef = this.dialog.open(ModalAjoutDocumentComponent, {
      disableClose: true,
      data: {
        type: 'Action',
        document: new DocumentModel(),
        typeId: this.data.action.id,
        tierId: this.data.action.tierId,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.onUpdated.emit(true);
      this.result = result ? true : false;
      this.data.result = result ? true : false;
      if (this.result) {
        this.getDocumentsByAction();
        this.actionService.get(this.data.action.id).subscribe((a: ActionModel) => {
          this.data.action = a;
        });
      }
    });
  }

  onEditInterlocuteur(interlocuteur: InterlocuteurModel) {
    if (interlocuteur) {
      this.data.action.interlocuteurId = interlocuteur.id;
      this.data.action.nomInterlocuteur = interlocuteur.nom;
      this.data.action.prenomInterlocuteur = interlocuteur.prenom;
      this.data.action.roleInterlocuteur = interlocuteur.role;
      this.data.action.emailInterlocuteur = interlocuteur.email;
      this.data.action.telPrincipalInterlocuteur = interlocuteur.telPrincipal;
      this.data.action.telSecondaireInterlocuteur = interlocuteur.telSecondaire;
      this.data.action.commentaireInterlocuteur = interlocuteur.commentaire;
      this.data.action.civiliteInterlocuteur = interlocuteur.civilite ? interlocuteur.civilite.libelle : '' ;
      if (this.data.action.nomInterlocuteur.trim().length === 0) {
        this.data.action.interlocuteurId = undefined;
        this.data.action.nomInterlocuteur = undefined;
        this.data.action.prenomInterlocuteur = undefined;
        this.data.action.roleInterlocuteur = undefined;
        this.data.action.emailInterlocuteur = undefined;
        this.data.action.telPrincipalInterlocuteur = undefined;
        this.data.action.telSecondaireInterlocuteur = undefined;
        this.data.action.commentaireInterlocuteur = undefined;
        this.data.action.civiliteInterlocuteur = undefined;
      }
    } else {
      this.data.action.interlocuteurId = undefined;
      this.data.action.nomInterlocuteur = undefined;
      this.data.action.prenomInterlocuteur = undefined;
      this.data.action.roleInterlocuteur = undefined;
      this.data.action.emailInterlocuteur = undefined;
      this.data.action.telPrincipalInterlocuteur = undefined;
      this.data.action.telSecondaireInterlocuteur = undefined;
      this.data.action.commentaireInterlocuteur = undefined;
      this.data.action.civiliteInterlocuteur = undefined;
    }
  }

  createInterlocuteur() {
    let interlocuteur = new InterlocuteurModel();
    interlocuteur.id = undefined;
    interlocuteur.tierId = this.data.action.tierId;
    interlocuteur.nom = this.data.action.nomInterlocuteur;
    interlocuteur.prenom = this.data.action.prenomInterlocuteur;
    interlocuteur.role = this.data.action.roleInterlocuteur;
    interlocuteur.commentaire = this.data.action.commentaireInterlocuteur;
    interlocuteur.telPrincipal = this.data.action.telPrincipalInterlocuteur;
    interlocuteur.telSecondaire = this.data.action.telSecondaireInterlocuteur;
    interlocuteur.email = this.data.action.emailInterlocuteur;
    let civ: CiviliteModel;
    if (this.data.action.civiliteInterlocuteur) {
      civ = this.civilites.find(civilite =>
        civilite.libelle.toLowerCase() === this.data.action.civiliteInterlocuteur.toLowerCase()) ;
        interlocuteur.civilite = civ ? civ : null;
        interlocuteur.civiliteId = civ ? civ.id : null;
    } else {
      interlocuteur.civilite = null;
      interlocuteur.civiliteId = null;
    }
    interlocuteur.civilite = null;
    this.interlocuteurService.post(interlocuteur).subscribe((interloc: InterlocuteurModel) => {
      this.data.action.interlocuteurId = interloc.id;
      this.onUpdateAction();
    });
  }

  updateInterlocuteur() {
    if (this.interlocuteurs.find(i => i.id === this.data.action.interlocuteurId)) {
      const interlocuteurAction = Object.assign({}, this.interlocuteurs.find(i => i.id === this.data.action.interlocuteurId));
      interlocuteurAction.nom = this.data.action.nomInterlocuteur;
      interlocuteurAction.prenom = this.data.action.prenomInterlocuteur;
      interlocuteurAction.email = this.data.action.emailInterlocuteur;
      interlocuteurAction.telPrincipal = this.data.action.telPrincipalInterlocuteur;
      interlocuteurAction.telSecondaire = this.data.action.telSecondaireInterlocuteur;
      interlocuteurAction.role = this.data.action.roleInterlocuteur;
      interlocuteurAction.civilite = null;
      this.interlocuteurService.put(interlocuteurAction).subscribe((interloc: InterlocuteurModel) => {
        this.onUpdateAction();
      });
    } else {
      this.onUpdateAction();
    }
  }

  saveAction() {
    if (this.data.action.nomInterlocuteur) {
      if (this.data.action.interlocuteurId) {
        this.updateInterlocuteur();
      } else {
        this.createInterlocuteur();
      }
    } else {
      // Si dans l'action il y a une adresse et pas d'interlocuteur, alors mise à jour de l'action.
      this.data.action.interlocuteurId = null;
      this.onUpdateAction();
    }
  }

}
