import { BasicModel } from "./basic-models/basic.model";
import { DocumentModel } from "./document/document.model";

export class DescriptionActiviteModel extends BasicModel { 
    public dateCreation: Date = new Date();
    public createurId: number;
    public dateModification: Date = new Date();
    public modifieurId: number;
    public dateDesactivation: Date = new Date();
    public desactivateurId: number;
    public agenceId : number;
    public activiteId : number;
    public libelleContratJason: string;
    public introduction : string;
    public descriptionOuvrage: string;
    public modalite : string;
    public chargeClient : string;
    public imageId? : number;
    public image? : DocumentModel;

}