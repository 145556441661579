<div *ngIf="this.data.form">
    <!-- Bouton d'annulation (x) -->
    <a (click)="onModalCancel()">
        <mat-icon class="close-icon">
            close
        </mat-icon>
    </a>

    <!-- Titre -->
    <div class="col-md-11">
        <h3 *ngIf="!this.data.form.get('id').value">Ajouter une offre</h3>
        <h3 *ngIf="this.data.form.get('id').value">Modifier une offre</h3>
    </div>

    <!-- Séparateur -->
    <div class="col-md-12">
        <mat-divider></mat-divider>
    </div>

    <!-- Champs -->
    <form focusInvalidInput class="row" [formGroup]="form">
        <div class="col-md-12">
            <mat-form-field class="full-width">
                <mat-label>Libelle</mat-label>
                <input matInput type="text" formControlName="libelle" required>
                <mat-error *ngIf="form.get('libelle').hasError('required')">
                    Veuillez saisir un libelle
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="full-width">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type">           
                <mat-option value="Additionnel">Additionnel</mat-option>
                <mat-option value="Multiplicatif">Multiplicatif</mat-option>             
              </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="this.form.get('type').value==='Additionnel'" class="col-md-3">
            <mat-form-field class="full-width">
                <mat-label>PU</mat-label>
                <input matInput type="number" formControlName="pu" required>
                <mat-error *ngIf="form.get('pu').hasError('required')">
                    Veuillez saisir un prix unitaire
                </mat-error>
            </mat-form-field>
        </div>                        
        <div *ngIf="this.form.get('type').value==='Multiplicatif'"  class="col-md-3">
            <mat-form-field class="full-width">
                <mat-label>Coefficient</mat-label>
                <input matInput type="number" formControlName="coefficient" required> 
                <mat-error *ngIf="form.get('coefficient').hasError('required')">
                    Veuillez saisir un coefficient
                </mat-error>
            </mat-form-field>
        </div> 
    </form>
</div>

<!-- Boutons -->
<div class="col-sm-12 row" style="display: flex; justify-content: space-between;">
    <div class="col-sm-6 text-center" style="margin-top: 24px;">
      <button mat-flat-button color="default" (click)="onModalCancel()">Annuler</button>
    </div>
  
    <div class="col-sm-6 text-center" style="margin-top: 24px;">
      <button *ngIf="this.data.form.get('id').value" mat-flat-button color="success" (click)="onModalConfirm()">Modifier</button>
      <button *ngIf="!this.data.form.get('id').value" mat-flat-button color="success" (click)="onModalConfirm()">Ajouter</button>
    </div>
</div>








