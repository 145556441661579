import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { SessionService } from '../../shared/session.service';
import { PrestationApiService } from './prestation-api.service';
import { PrestationBasicService } from './prestation-basic.service';

@Injectable()
export class OffreService extends PrestationBasicService {

  constructor(
    http: HttpClient,
    apiService: PrestationApiService,
    protected sessionService: SessionService,
    protected IntercomToastrService: IntercomToastrService) {
      super(http, apiService, sessionService);
      this.serviceUrl = apiService.ROUTEOFFRE;
  }
}
