import { SyndicModel } from '../../../../shared/models/tier/syndic.model';
import { EventEmitter, Component, Input, Output } from '@angular/core';
import {  } from 'protractor';
import { TierModel } from 'src/app/modules/core/shared/models/tier/tier.model';

@Component({
  selector: 'app-syndic',
  templateUrl: './syndic.component.html'
})
export class SyndicComponent {
  @Input() tier: SyndicModel;
  @Input() required = true;
  @Input() disabled = false;
  @Output() tierChange = new EventEmitter<SyndicModel>();

  keyTitleArray = [
    {property: 'raisonSociale', libelle: 'raison sociale'},
    {property: 'marqueEnseigne', libelle: 'marque'},
    {property: 'telephone', libelle: 'téléphone'},
    {property: 'clientNumber', libelle: 'numéro client'},
    {property: 'identifiantJason', libelle: 'identifiant Jason'},
    {property: 'siretSiren', libelle: 'siret/siren'}];
  

  changeEvent() {
    this.tier.rechercheTitle =  TierModel.getTitle(this.keyTitleArray, this.tier);
    this.tierChange.emit(this.tier);
  }
}
