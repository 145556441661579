import { IntercomToastrService } from 'src/app/modules/shared/IntercomToastr.service';
import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface DialogData {
  icone: string;
  title: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  result: boolean;
}

@Component({
  selector: 'app-modal-envoi-devis-core',
  templateUrl: './modal-envoi-devis-core.component.html'
})
export class ModalEnvoiDevisCoreComponent {

  constructor(public dialog: MatDialog,
    private toastr: IntercomToastrService,
    public router: Router,
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  

  onModalCancel(): void {
    this.dialogRef.close(false);
  }

  onModalConfirm(): void {
      this.dialogRef.close(true);
  }
}
