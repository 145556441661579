
<div class="col-sm-12">
<mat-divider></mat-divider>
  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Demandes
        </mat-panel-title>
        <mat-panel-description>
          {{ panelOpenState ? 'Masquer toutes les demandes' : 'Afficher toutes les demandes'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-demande-array (onUpdated)="demandeUpdated($event)" [tier]=tier></app-demande-array>
    </mat-expansion-panel>
    
    <mat-expansion-panel style="margin-top: 10px;" [expanded]="true" (opened)="true" (closed)="panelOpenStates = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Demandes closes
        </mat-panel-title>
        <mat-panel-description>
          {{ panelOpenStates ? 'Masquer les demandes closes' : 'Afficher les demandes closes'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-clos-demande (onUpdated)="demandeUpdated($event)" [tier]=tier></app-clos-demande>
    </mat-expansion-panel>
  </mat-accordion>
</div>
